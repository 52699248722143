import { Box, Tooltip, Typography } from "@mui/material";
import React from "react";

import { isTooDark } from "../../../../../utils/helpers";
import Highlight from "../../../../Highlight";
import {Skill} from "../../../../workers/types";

interface ColumnGroupJobHeaderProps {
    skill: Skill;
    searchText: string;
}

const ColumnGroupJobHeader: React.FC<ColumnGroupJobHeaderProps> = ({ skill, searchText }) => {
    const code = skill.jobColor || "ebebeb";

    const backgroundColor = `#${code}`;
    const textColor = isTooDark(backgroundColor) ? "#FFFFFF" : "rgba(0, 0, 0, 0.87)";

    return (
        <Tooltip title={skill.jobName}>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    backgroundColor,
                    height: 60,
                    px: 2,
                    width: "100%",
                }}
            >
                <Typography
                    noWrap
                    sx={{
                        color: textColor,
                        textOverflow: "ellipsis",
                        fontFamily: "Roboto",
                        fontSize: "14px",
                        lineHeight: "20px",
                    }}
                >
                    <Highlight value={skill.jobName} searchText={searchText} />
                </Typography>
            </Box>
        </Tooltip>
    );
};

export default ColumnGroupJobHeader;
