import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import jwt_decode from "jwt-decode";

import {
  lastUserActivity,
  startUserActivityListener,
  stopUserActivityListener,
} from "./user-activity-listener";

// Function to log out the user
const logout = () => {
  stopUserActivityListener(); // Stop listening for user activity
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("token");
  localStorage.removeItem("appliedFilters-flex-scheduler");
  window.location.replace(`${window.location.origin}/login`);
};

let refreshTimeout: NodeJS.Timeout | null = null;

const scheduleTokenRefresh = () => {
  const refreshTime = 3 * 60 * 1000; // Schedule refresh 3 minutes after login

  if (refreshTimeout) {
    clearTimeout(refreshTimeout);
  }

  refreshTimeout = setTimeout(() => {
    const timeSinceLastActivity = Date.now() - lastUserActivity;
    if (timeSinceLastActivity < 5 * 60 * 1000) {
      // User is active within the last 5 minutes
      refreshToken()
          .then(() => {
            /* Do nothing */
          })
          .catch((error) => {/* Do nothing */});
    }
  }, refreshTime);
};

const refreshToken = async () => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_END_POINT}/Users/Refresh`,
      {
        refreshToken: localStorage.getItem("refreshToken"),
        token: localStorage.getItem("token"),
      }
    );

    if (res.status === 200) {
      localStorage.setItem("refreshToken", res.data.refreshToken);
      localStorage.setItem("token", res.data.token);

      // Update the authorization header with the new token
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${res.data.token}`;

      // Schedule the next token refresh
      scheduleTokenRefresh();

      return res.data.token;
    } else {
      throw new Error("Failed to refresh token");
    }
  } catch (error) {
    logout();
    return Promise.reject(error);
  }
};

// Request interceptor to add authorization headers
axios.interceptors.request.use((config: AxiosRequestConfig) => {
  if (localStorage.token) {
    config.headers.Authorization = `Bearer ${localStorage.token}`;
  }
  return config;
});

// Response interceptor to handle token refresh logic
axios.interceptors.response.use(
  (response: AxiosResponse) => {
    // Intercept successful login response
    if (
      response.config.url?.endsWith("/authenticate") ||
      response.config.url?.endsWith("/authenticateSSO")
    ) {
      scheduleTokenRefresh();
      startUserActivityListener(); // Start listening for user activity
    }
    return response;
  },
  function (error) {
    const originalRequest = error.config;

      // If the refresh token request itself failed, log the user out
      if (
          error.response.status === 401 &&
          originalRequest.url ===
          `${process.env.REACT_APP_API_END_POINT}/Users/Refresh`
      ) {
        logout();
        return Promise.reject(error);
      }

      // If the original request failed due to 401, try to refresh the token
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        return axios
            .post(`${process.env.REACT_APP_API_END_POINT}/Users/Refresh`, {
              refreshToken: localStorage.getItem("refreshToken"),
              token: localStorage.getItem("token"),
            })
            .then((res) => {
              if (res.status === 200) {
                localStorage.setItem("refreshToken", res.data.refreshToken);
                localStorage.setItem("token", res.data.token);

                axios.defaults.headers.common["Authorization"] =
                    "Bearer " + localStorage.getItem("token");

                return axios(originalRequest);
              }
            })
            .catch((error) => {
              try {
                if (
                    localStorage.getItem("refreshToken") &&
                    localStorage.getItem("token")
                ) {
                  const token = localStorage.getItem("token");
                  const jwt: { exp: number } = jwt_decode(token ?? "") ?? {
                    exp: 0,
                  };
                  const exp = jwt ? jwt.exp ?? 0 : 0;

                  if (Date.now() / 1000 < exp) {
                    axios.defaults.headers.common["Authorization"] =
                        "Bearer " + token;
                    return axios(originalRequest);
                  } else {
                    logout();
                    return Promise.reject(error);
                  }
                } else {
                  logout();
                  return Promise.reject(error);
                }
              } catch (e) {
                logout();
                return Promise.reject(error);
              }
            });
      }

      return Promise.reject(error);
    }
);

export default axios;