import { Box, TextField, Typography } from "@mui/material";
import React from "react";

interface MaxSignupIn24HoursProps {
  newLimitControl: any;
  setNewLimitControl: (value: any) => void;
}
const MaxSignupIn24Hours = ({
  newLimitControl,
  setNewLimitControl,
}: MaxSignupIn24HoursProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "260px",
      }}
    >
      <Typography
        sx={{
          fontFamily: "Roboto",
          fontWeight: 400,
          fontSize: "16px",
          color: "rgba(0, 0, 0, 0.87)",
        }}
      >
        Maximum signup hours per day
      </Typography>
      <Typography
        sx={{
          fontFamily: "Roboto",
          fontWeight: 400,
          fontSize: "12px",
          color: "rgba(0, 0, 0, 0.6)",
          paddingBottom: "8px",
        }}
      >
        Shift hours that a worker can work with or without breaks in any 24 hour
        period.
      </Typography>
      <TextField
        id="max-signup-in-24-hours"
        variant="filled"
        label=""
        type="number"
        onFocus={(event) => {
          event.target.select();
        }}
        onChange={(e) => {
            const value = parseInt(e.target.value);
            if (value >= 1 && value <= 24) {
                setNewLimitControl({
                    ...newLimitControl,
                    maxWorkHoursInADay: parseInt(e.target.value),
                });
            }
        }}
        value={newLimitControl.maxWorkHoursInADay ?? "16"}
        helperText="Hours"
      />
    </Box>
  );
};

export default MaxSignupIn24Hours;
