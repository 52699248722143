import AddIcon from "@mui/icons-material/Add";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import React from "react";
import { useDispatch } from "react-redux";

import {
	closeEditShiftScheduleDialog,
	resetDeleteShiftSchedule,
	resetEditShiftScheduleDialog,
} from "../../../../../../export/gat-admin";
import BoxWrapper from "../../../box-wrapper";
import useShiftScheduleRulesController from "./controllers/use-shift-schedule-rules-controller";
import NoSchedule from "./ui/no-schedule";
import ShiftScheduleDialog from "./ui/shift-schedule-dialog";
import ScheduleTable from "./ui/table/schedule-table";

interface ShiftScheduleRulesProps {
	locationData: any; // Replace 'any' with the actual type
}

const ShiftScheduleRules: React.FC<ShiftScheduleRulesProps> = ({
	locationData,
}) => {
	const [
		{
			isScheduleRulesDialogOpen,
			getAllShiftTypesStatus,
			getAllShiftTypesData,
			getAllShiftTypesError,
			createNewScheduleStatus,
			createNewScheduleError,
			editShiftSchedule,
			deleteShiftScheduleStatus,
			deleteShiftScheduleError,
		},
		{
			openScheduleRulesDialog,
			closeScheduleRulesDialog,
			resetCreateNewSchedule,

			getDuration,
		},
	] = useShiftScheduleRulesController(locationData);

	const dispatch = useDispatch();

	return (
		<>
			<BoxWrapper
				title='Shift Schedules'
				subtitle='Setup Schedules that could be used across different Shift Patterns
						in this Location'
				openDialog={openScheduleRulesDialog}
				disabled
			>
				{getAllShiftTypesStatus === "pending" ? (
					<Box
						display='flex'
						justifyContent='center'
						alignItems='center'
						mt={4}
					>
						<CircularProgress />
					</Box>
				) : null}
				{getAllShiftTypesStatus === "error" ? (
					<Box
						display='flex'
						justifyContent='center'
						alignItems='center'
						mt={4}
					>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 400,
								fontSize: "16px",
								color: "rgba(0, 0, 0, 0.6)",
							}}
						>
							{getAllShiftTypesError}
						</Typography>
					</Box>
				) : null}
				{getAllShiftTypesStatus === "fulfilled" &&
				getAllShiftTypesData.length === 0 ? (
					<NoSchedule />
				) : null}
				{getAllShiftTypesStatus === "fulfilled" &&
				getAllShiftTypesData.length > 0 ? (
					<ScheduleTable
						data={getAllShiftTypesData}
						getDuration={getDuration}
					/>
				) : null}
			</BoxWrapper>
			{isScheduleRulesDialogOpen ? (
				<ShiftScheduleDialog
					locationData={locationData}
					isScheduleRulesDialogOpen={isScheduleRulesDialogOpen}
					closeScheduleRulesDialog={closeScheduleRulesDialog}
					error={createNewScheduleError}
					status={createNewScheduleStatus}
					getDuration={getDuration}
					isEdit={false}
					shiftScheduleData={{}}
				/>
			) : null}

			{editShiftSchedule.isDialogOpen ? (
				<ShiftScheduleDialog
					locationData={locationData}
					isScheduleRulesDialogOpen={editShiftSchedule.isDialogOpen}
					closeScheduleRulesDialog={() => {
						dispatch(resetEditShiftScheduleDialog());
						dispatch(closeEditShiftScheduleDialog());
					}}
					error={editShiftSchedule.errorMessage}
					status={editShiftSchedule.status}
					getDuration={getDuration}
					isEdit={true}
					shiftScheduleData={editShiftSchedule.data}
				/>
			) : null}

			{createNewScheduleStatus === "fulfilled" ? (
				<Snackbar
					open={createNewScheduleStatus === "fulfilled"}
					autoHideDuration={3000}
					onClose={() => {
						resetCreateNewSchedule && dispatch(resetCreateNewSchedule());
						closeScheduleRulesDialog && closeScheduleRulesDialog();
					}}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
				>
					<MuiAlert elevation={6} variant='filled' severity='success'>
						New Schedule Created Successfully
					</MuiAlert>
				</Snackbar>
			) : null}

			{editShiftSchedule.status === "fulfilled" ? (
				<Snackbar
					open={editShiftSchedule.status === "fulfilled"}
					autoHideDuration={3000}
					onClose={() => dispatch(resetEditShiftScheduleDialog())}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
				>
					<MuiAlert elevation={6} variant='filled' severity='success'>
						Shift Schedule Updated Successfully
					</MuiAlert>
				</Snackbar>
			) : null}

			{deleteShiftScheduleStatus === "fulfilled" ? (
				<Snackbar
					open={deleteShiftScheduleStatus === "fulfilled"}
					autoHideDuration={3000}
					onClose={() => dispatch(resetDeleteShiftSchedule())}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
				>
					<MuiAlert elevation={6} variant='filled' severity='success'>
						Shift Schedule Deleted Successfully
					</MuiAlert>
				</Snackbar>
			) : null}

			{deleteShiftScheduleStatus === "error" ? (
				<Snackbar
					open={deleteShiftScheduleStatus === "error"}
					autoHideDuration={3000}
					onClose={() => dispatch(resetDeleteShiftSchedule())}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
				>
					<MuiAlert elevation={6} variant='filled' severity='error'>
						{deleteShiftScheduleError}
					</MuiAlert>
				</Snackbar>
			) : null}
		</>
	);
};

export default ShiftScheduleRules;
