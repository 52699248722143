import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { getLocations } from "../../../../../../export/gat-admin";
import useLocationController from "./controllers/use-location-controller";
import useLocationData from "./controllers/use-location-data";
import LocationHeader from "./location-header";
import LocationTable from "./location-table";

interface LocationProps {
	companyData: any;
	openLocationForm: any;
}

const Location: React.FC<LocationProps> = ({ companyData, openLocationForm }) => {
	const dispatch = useDispatch();
	const [searchText, setSearchText] = useState("");

	const {
		locations,
		getLocationsErrorMessage,
		companySettingsObj,
		getLocationsStatus,
	} = useLocationData();

	const {filteredLocations} = useLocationController({searchText});

	const MAXLOCATIONSALLOWED =
		companySettingsObj["clientApp.maxLocationsByContract"]?.value ?? 0;
	useEffect(() => {
		if (companyData.id) {
			dispatch(getLocations(companyData.id));
		}
	}, [companyData.id, dispatch]);

	return (
		<>
			<LocationHeader
				companyId={companyData.id}
				setSearch={setSearchText}
				searchText={searchText}
				MAXLOCATIONSALLOWED={MAXLOCATIONSALLOWED}
				numberofLocations={locations.length}
				openLocationForm={openLocationForm}
			/>
			<LocationTable
				locations={filteredLocations}
				getLocationsStatus={getLocationsStatus}
				getLocationsErrorMessage={getLocationsErrorMessage}
				companyId={companyData.id}
				openLocationForm={openLocationForm}
				searchText={searchText}
			/>
		</>
	);
};

export default Location;
