// TODO - Type 'any' needs to be fixed.
/* eslint-disable simple-import-sort/imports */
import { Box, Chip, CircularProgress, Typography } from "@mui/material";
import React from "react";
import { GridColDef } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import { isFuture } from "date-fns";
import { useDispatch } from "react-redux";
import { downloadWorkerCertification } from "../../../../../../../../../export/workers";
import useWorkersData from "../../../../../../controllers/use-workers-data";
import CircularProgressWithLabel from "../../../../../../../../design/circular-progress-label";
import dayjs from "dayjs";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { truncateFileName } from "../../../../../helper"

// interface GridColumn {
// 	field: string;
// 	width: number | string;
// 	renderHeader?: (params: any) => JSX.Element;
// 	renderCell?: (params: any) => JSX.Element;
// }

const gracePeriodUnit = {
	y: "Year(s)",
	m: "Month(s)",
	d: "Day(s)",
};

const useRequiredCertificationsColumns = () => {
	const dispatch = useDispatch();
	const { downloadProgress } = useWorkersData();

	const getStatus = (validFrom: string, validTo: string) => {
		if (!validFrom || isFuture(new Date(validFrom))) {
			return "-";
		} else if (!validTo || isFuture(new Date(validTo))) {
			return "Active";
		} else {
			return "Expired";
		}
	};

	const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))({
		[`& .${tooltipClasses.tooltip}`]: {
			maxWidth: 200,
		},
	});

	const columns: GridColDef[] = [
		{
			field: "certName",
			width: 185,
			renderHeader: () => {
				return (
					<Box>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 500,
								fontSize: "14px",
								color: "#000000",
								lineHeight: "24px",
								letterSpacing: "0.17px",
								ml: 1,
							}}
						>
							Name
						</Typography>
					</Box>
				);
			},
			renderCell: (params: any) => {
				return (
					<Box>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 400,
								fontSize: "14px",
								color: "#000000",
								lineHeight: "20px",
								letterSpacing: "0.17px",
								my: 3,
								ml: 1,
							}}
						>
							{params.row.certName}
						</Typography>
					</Box>
				);
			},
		},
		{
			field: "status",
			minWidth: 100,
			renderHeader: (params: any) => {
				return (
					<Box>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 500,
								fontSize: "14px",
								color: "#000000",
								lineHeight: "24px",
								letterSpacing: "0.17px",
								ml: 0.5,
							}}
						>
							Status
						</Typography>
					</Box>
				);
			},
			renderCell: (params: any) => {
				const label = getStatus(params.row.validFrom, params.row.validTo);
				if (label === "-")
					return (
						<Typography
							sx={{
								ml: 1,
							}}
						>
							-
						</Typography>
					);
				return (
					<Box>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 400,
								fontSize: "14px",
								color: "#000000",
								lineHeight: "20px",
								letterSpacing: "0.17px",
								// ml: 1,
							}}
						>
							<Chip
								label={label}
								color={label === "Active" ? "success" : "error"}
								variant='outlined'
								size='small'
								sx={{
									padding: "5px 10px",
								}}
							/>
						</Typography>
					</Box>
				);
			},
		},
		{
			field: "validity",
			width: 250,
			renderHeader: (params: any) => {
				return (
					<Box>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 500,
								fontSize: "14px",
								color: "#000000",
								lineHeight: "24px",
								letterSpacing: "0.17px",
								ml: 1,
							}}
						>
							Validity
						</Typography>
					</Box>
				);
			},
			renderCell: (params: any) => {
				if (params.row.validFrom === null && params.row.validTo === null)
					return (
						<Typography
							sx={{
								ml: 1.5,
							}}
						>
							{"-"}
						</Typography>
					);
				const validFrom = dayjs(params.row?.validFrom).format("MM/DD/YYYY");
				const validTo = dayjs(params.row?.validTo).format("MM/DD/YYYY");
				return (
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 400,
							fontSize: "14px",
							color: "#000000",
							lineHeight: "20px",
							letterSpacing: "0.17px",
							marginLeft: 1,
						}}
					>
						{validFrom} -{" "}
						{params?.row?.validTo === null ? "Never expires" : validTo}
					</Typography>
				);
			},
		},
		{
			field: "gracePeriod",
			minWidth: 110,
			renderHeader: (params: any) => {
				return (
					<Box>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 500,
								fontSize: "14px",
								color: "#000000",
								lineHeight: "24px",
								letterSpacing: "0.17px",
							}}
						>
							Grace period
						</Typography>
					</Box>
				);
			},
			renderCell: (params: any) => {
				return (
					<Box>
						{params.row.gracePeriod && params.row.gracePeriodUnit ? (
							<Typography
								sx={{
									fontFamily: "Roboto",
									fontWeight: 400,
									fontSize: "14px",
									color: "#000000",
									lineHeight: "20px",
									letterSpacing: "0.17px",
									ml: 1,
								}}
							>
								{`${params.row.gracePeriod} ${
									gracePeriodUnit[params.row.gracePeriodUnit as "y" | "m" | "d"]
								}`}
							</Typography>
						) : (
							<Typography
								sx={{
									ml: 1,
								}}
							>
								{"-"}
							</Typography>
						)}
					</Box>
				);
			},
		},
		{
			field: "attachment",
			minWidth: 190,
			renderHeader: (params: any) => {
				return (
					<Box>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 500,
								fontSize: "14px",
								color: "#000000",
								lineHeight: "24px",
								letterSpacing: "0.17px",
							}}
						>
							Attachment
						</Typography>
					</Box>
				);
			},
			renderCell: (params: any) => {
				const workerCertFiles = params.row.workerCertFiles;
				return (
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: 1,
							my: 1,
						}}
					>
						{workerCertFiles
							? workerCertFiles?.map((file: any) => {
									return (
										<Box
											key={file.workerCertFileId}
											sx={{
												display: "flex",
												gap: 1,
												alignItems: "center",
											}}
										>
											<CustomWidthTooltip title={file.fileName} placement='bottom-start'>
												<Typography
													sx={{
														// fontFamily: "Roboto",
														// fontWeight: 400,
														// fontSize: "14px",
														// color: "#2F4D8B",
														// lineHeight: "20px",
														// letterSpacing: "0.17px",
														// cursor: "pointer",
														// textDecoration: "underline",
														// textOverflow: "ellipsis",
														// maxWidth: "180px",
														// whiteSpace: "nowrap",
														// overflow: "hidden",
														fontFamily: "Roboto",
														fontWeight: 400,
														fontSize: "10px",
														lineHeight: "10px",
														color: "#2F4D8B",
														letterSpacing: "0.17px",
														cursor: "pointer",
														textDecoration: "underline",
													}}
													onClick={(e) => {
														e.preventDefault();
														dispatch(
															downloadWorkerCertification({
																workerCertFileId: file.workerCertFileId,
																fileName: file.fileName,
															})
														);
													}}
												>
													{truncateFileName(file.fileName,20)}
												</Typography>
											</CustomWidthTooltip>
											{downloadProgress[file.workerCertFileId] !==
												undefined && (
												<CircularProgress
													value={downloadProgress[file.workerCertFileId]}
													size={10}
													variant="determinate"
												/>
											)}
										</Box>
									);
								})
							: "-"}
					</Box>
				);
			},
		},
	];

	return columns;
};

export default useRequiredCertificationsColumns;
