import { Box, Button, Typography } from "@mui/material";
import React from "react";

import Highlight from "../../../../../components/Highlight";

interface MyChipProps {
	userWorkCenters: any;
	onClickViewMoreWorkCenters: (id: number) => void;
	expandWorkcenters: number[];
	rowId: number;
	searchText: string
}

const MyChip: React.FC<MyChipProps> = ({
	userWorkCenters,
	onClickViewMoreWorkCenters,
	rowId,
	expandWorkcenters,
	searchText
}) => {
	// Check if any of the user's work centers match the search text
	const workcentersAccess = userWorkCenters.some((workcenter: any) =>
		workcenter.name.toLowerCase().includes(searchText.toLowerCase())
	);

	return (
		<Box>
			{(expandWorkcenters.includes(rowId) || (workcentersAccess && searchText)) ? (
				<Box display='flex' marginTop={1} flexWrap='wrap' alignItems='center' >
					{userWorkCenters.map((wc: any, index: number) => (
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontSize: "14px",
								color: "rgba(0, 0, 0, 0.87)",
							}}
							key={`location-work-center-tag-${wc.id}-${rowId}`}
						>
							<Highlight value={wc.name} searchText={searchText} />
							{index !== userWorkCenters.length - 1 && ",\xa0"}
						</Typography>
					))}
					{(searchText.length === 0 || !workcentersAccess)&& (
						<Button
							onClick={() => onClickViewMoreWorkCenters(rowId)}
							size="small"
							sx={{marginLeft: "4px"}}
						>
							{"Show Less"}
						</Button>
					)}
				</Box>
			) : (
				<Box display="flex" flexWrap="wrap" alignItems="center" my={1}>
					<Typography
						mr={0.5}
						sx={{
							fontFamily: "Roboto",
							fontSize: "14px",
							color: "rgba(0, 0, 0, 0.87)",
						}}
					>
						{userWorkCenters.length > 0 ? <Highlight value={userWorkCenters[0].name} searchText={searchText} /> : ""}
					</Typography>
					{userWorkCenters.length > 1 && <Button
						onClick={() => onClickViewMoreWorkCenters(rowId)}
						size='small'
					>
						{"Show More"}
					</Button>}
				</Box>
			)}
		</Box>
	);
};

export default MyChip;
