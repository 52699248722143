import { Box, Tooltip, Typography } from "@mui/material";
import React from "react";

const ColumnNoSkillHeader: React.FC = () => {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                height: "100%",
                width: "100%",
                paddingTop: "8px",
                paddingBottom: "8px",
            }}
        >
            <Tooltip title="No mapped skill">
                <Typography
                    sx={{ wordBreak: "break-word" }}
                    whiteSpace="normal"
                    style={{
                        width: "100px",
                        fontSize: "12px",
                        fontWeight: "bold",
                        padding: "4px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: "2",
                        WebkitBoxOrient: "vertical",
                        marginLeft: "10px",
                    }}
                >
                    No mapped skills
                </Typography>
            </Tooltip>
        </Box>
    );
};

export default ColumnNoSkillHeader;
