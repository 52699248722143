import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

//import axios from "axios"
import axios from "../../utils/api-client";
import { closeAddJobDialogReducer } from "./Reducers/closeAddJobDialogReducer";
import { closeCreateSkillDialogReducer } from "./Reducers/closeCreateSkillDialogReducer";
import { closeEditJobDialogReducer } from "./Reducers/closeEditJobDialogReducer";
import { openAddJobDialogReducer } from "./Reducers/openAddJobDialogReducer";
import { openCreateSkillDialogReducer } from "./Reducers/openCreateSkillDialogReducer";
import { resetCreateNewJobReducer } from "./Reducers/resetCreateNewJobReducer";
import { resetEditJobReducer } from "./Reducers/resetEditJobReducer";

//url=https://gatstag.azurewebsites.net/api/Jobs/GetJobsByUser?userid=3

export const createNewJob = createAsyncThunk(
	"manageJobs/createNewJob",
	async (payload, { fulfillWithValue, rejectWithValue, getState }) => {
		let { name, color, skills, workcenters } = payload;

		// console.log("Skills : ", skills,payload);

		let workcenterIds = workcenters.reduce((acc, key) => {
			acc.push(key.id);
			return acc;
		}, []);

		let skillIds = skills.reduce((acc, key) => {
			acc.push(key.skillId);
			return acc;
		}, []);

		if (workcenterIds.length === 0)
			return rejectWithValue("Please select at least one workcenter.");

		if (color === "")
			return rejectWithValue("Please select a color for the job role.");
		// if (skillIds.length === 0)
		//     return rejectWithValue("Please select at least one skill.");

		workcenterIds = workcenterIds.toString();
		skillIds = skillIds.toString();

		const user = getState().user.userData.id;
		const userId = Number.parseInt(user);

		const jobData = {
			name: name,
			hexColor: color,
			locationId: 1,
			workCenterIds: workcenterIds,
			skillIds,
			createdBy: userId,
		};

		try {
			const res = await axios.post(
				`${process.env.REACT_APP_API_END_POINT}/Jobs/Create`,
				jobData
			);
			return fulfillWithValue(res.data);
		} catch (error) {
			if (error.response.status === 404) {
				return rejectWithValue(error.response.data.message);
			}
			return rejectWithValue(error.response.data.message);
		}
	}
);

export const editJob = createAsyncThunk(
	"manageJobs/editJob",
	async (payload, { fulfillWithValue, rejectWithValue, getState }) => {
		let { id, name, color, skills, workcenters } = payload;
		const jobId = id;
		const allJobs = getState().manageJobs.allJobs;
		let oldJobData = allJobs.filter((job) => job.id === jobId);
		let workcenterIds = workcenters.reduce((acc, key) => {
			acc.push(key.id);
			return acc;
		}, []);
		let skillIds = skills.reduce((acc, key) => {
			acc.push(key.skillId);
			return acc;
		}, []);
		if (workcenterIds.length === 0)
			return rejectWithValue("Please select at least one workcenter.");
		skillIds = skillIds.toString();
		workcenterIds = workcenterIds.toString();
		const user = getState().user.userData.id;
		const userId = Number.parseInt(user);
		const jobData = {
			id,
			name,
			hexColor: color,
			skillIds,
			locationId: 1,
			userId,
			createdBy: userId,
			workCenterIds: workcenterIds,
			skills,
		};
		oldJobData = oldJobData[0];
		let requestPayload = {
			...oldJobData,
			...jobData,
		};
		delete requestPayload.workcenterIds;
		delete requestPayload.dispatch;

		try {
			const editedJob = await axios.put(
				`${process.env.REACT_APP_API_END_POINT}/Jobs/Put`,
				requestPayload
			);

			if (editedJob.status === 204)
				return fulfillWithValue({ jobId, data: requestPayload });
		} catch (error) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

export const fetchJobsByUserId = createAsyncThunk(
	"manageJobs/fetchJobsByUserId",
	async (payload, { fulfillWithValue, rejectWithValue, getState }) => {
		let userId = getState().user.userData.id;
		try {
			const jobsResponse = axios.get(
				`${process.env.REACT_APP_API_END_POINT}/Jobs/GetJobsByUser?userid=${userId}`
			);

			const skillsResponse = axios.get(
				`${process.env.REACT_APP_API_END_POINT}/Skill/GetSkillsByUser`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: localStorage.getItem("token"),
					},
				}
			);

			const [jobs, skills] = await Promise.allSettled([
				jobsResponse,
				skillsResponse,
			]);

			let jobsList = [];
			let skillsList = [];
			if (jobs.status === "fulfilled" && skills.status === "fulfilled") {
				jobsList = jobs.value.data;
				skillsList = skills.value.data;
			}
			return fulfillWithValue({
				jobs: jobsList,
				skills: skillsList,
			});
		} catch (error) {
			//console.log(error)
			try {
				return rejectWithValue(error.response.data.message);
			} catch (e) {
				return rejectWithValue("Error fetching jobs and skills");
			}
		}
	}
);

export const editSkill = createAsyncThunk(
	"manageJob/editSkill",
	async (payload, { fulfillWithValue, rejectWithValue }) => {
		try {
			const res = await axios.put(
				`${process.env.REACT_APP_API_END_POINT}/Skill/Update`,
				payload
			);
			return fulfillWithValue(res.data);
		} catch (error) {
			if (error.response.status === 404) {
				return rejectWithValue(error.response.data.message);
			}
			return rejectWithValue(error.response.data.message);
		}
	}
);

export const createNewSkill = createAsyncThunk(
	"manageJobs/createNewSkill",
	async (payload, { fulfillWithValue, rejectWithValue }) => {
		try {
			const res = await axios.post(
				`${process.env.REACT_APP_API_END_POINT}/Skill/Create`,
				{ name: payload }
			);
			return fulfillWithValue(res.data);
		} catch (error) {
			if (error.response.status === 404) {
				return rejectWithValue(error.response.data.message);
			}
			return rejectWithValue(error.response.data.message);
		}
	}
);

export const fetchMaxWorkersByLocationJob = createAsyncThunk(
	"scheduler/fetchMaxWorkersByLocationJob",
	/**  @param payload {locationId: string; jobId: string; startDateTime: string; endDateTime: string, shiftSkills  } */
	async (payload, thunkAPI) => {
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_API_END_POINT}/Shifts/GetMaxWorkersForShift`,
				payload
			);
			if (response.status === 200) {
				return thunkAPI.fulfillWithValue(response.data);
			}
			return thunkAPI.rejectWithValue(response.error);
		} catch (error) {
			// console.error(error);
			return thunkAPI.rejectWithValue(error);
		}
	}
);

export const ManageJobsSlice = createSlice({
	name: "manageJobs",
	initialState: {
		addJobDialog: {
			isOpen: false,
		},

		editJobDialog: {
			isOpen: false,
			job: undefined,
		},

		editSkillDialog: {
			isOpen: false,
			skill: undefined,
		},

		createSkillDialog: {
			isOpen: false,
		},

		allJobs: [],
		allSkills: [],
		obsoleteSkills: [],
		allSkillsObj: {},

		fetchJobsByUserId: {
			status: "idle",
			errorMessage: "",
		},

		createNewJob: {
			status: "idle",
			errorMessage: "",
		},
		editJob: {
			status: "idle",
			errorMessage: "",
		},
		editSkill: {
			status: "idle",
			errorMessage: "",
		},
		createNewSkill: {
			status: "idle",
			errorMessage: "",
		},
		fetchMaxWorkersByLocationJob: {
			status: "idle",
			errorMessage: "",
			data: {},
		},
	},

	reducers: {
		openAddJobDialog: openAddJobDialogReducer,
		closeAddJobDialog: closeAddJobDialogReducer,
		openEditJobDialog: (state, action) => {
			state.editJobDialog.isOpen = true;
			state.editJobDialog.job = action.payload;
			state.editJob.status = "idle";
			state.editJob.errorMessage = "";
		},
		openEditSkillDialog: (state, action) => {
			state.editSkillDialog.isOpen = true;
			state.editSkillDialog.skill = action.payload;
			state.editSkill.status = "idle";
			state.editSkill.errorMessage = "";
		},
		closeEditJobDialog: closeEditJobDialogReducer,
		closeEditSkillDialog: (state) => {
			state.editSkillDialog.isOpen = false;
			state.editSkillDialog.skill = undefined;
		},
		openCreateSkillDialog: openCreateSkillDialogReducer,
		closeCreateSkillDialog: closeCreateSkillDialogReducer,
		resetCreateNewJob: resetCreateNewJobReducer,
		resetCreateNewSkill: (state) => {
			state.createNewSkill.status = "idle";
			state.createNewSkill.errorMessage = "";
		},
		resetEditJob: resetEditJobReducer,
		resetEditSkill: (state) => {
			state.editSkill.status = "idle";
			state.editSkill.errorMessage = "";
			state.editSkillDialog.isOpen = false;
			state.editSkillDialog.skill = undefined;
		},
		resetMaxWorkersByLocationJob: (state) => {
			state.fetchMaxWorkersByLocationJob.status = "idle";
			state.fetchMaxWorkersByLocationJob.errorMessage = "";
			state.fetchMaxWorkersByLocationJob.data = {};
		},
		resetFetchJobsByUserId: (state) => {
			state.fetchJobsByUserId.status = "idle";
			state.fetchJobsByUserId.errorMessage = "";
			state.allJobs = [];
			state.allSkills = [];
		},
	},

	extraReducers: {
		[createNewJob.fulfilled]: (state, action) => {
			state.allJobs = [...state.allJobs, action.payload];
			state.addJobDialog.isOpen = false;
			state.createNewJob.status = "fulfilled";
		},
		[createNewJob.pending]: (state) => {
			state.createNewJob.status = "pending";
		},
		[createNewJob.rejected]: (state, action) => {
			state.createNewJob.status = "error";
			state.createNewJob.errorMessage = action.payload ?? "An error occurred";
		},
		[fetchJobsByUserId.fulfilled]: (state, action) => {
			state.allJobs = action.payload.jobs;
			state.allSkills =
				action.payload.skills.map((skill) => ({
					...skill,
					isActive: !skill.isObsolete,
				})) ?? [];
			state.obsoleteSkills =
				action.payload.skills
					.filter((skill) => skill.isObsolete)
					.map((skill) => skill.id) ?? [];
			const allSkillsObj = action.payload.skills.reduce((acc, skill) => {
				acc[skill.id] = { ...skill, isActive: !skill.isObsolete };
				return acc;
			}, {});
			state.allSkillsObj = allSkillsObj;
			state.fetchJobsByUserId.status = "fulfilled";
		},
		[fetchJobsByUserId.pending]: (state) => {
			state.fetchJobsByUserId.status = "pending";
		},
		[fetchJobsByUserId.rejected]: (state, action) => {
			state.fetchJobsByUserId.status = "error";
			state.fetchJobsByUserId.errorMessage =
				action.payload ?? "An error occurred";
		},

		[editJob.fulfilled]: (state, action) => {
			const jobId = action.payload?.jobId;
			const newWorkerData = action.payload?.data;
			let index = state.allJobs.findIndex((el) => el.id === jobId);
			state.allJobs[index] = newWorkerData;
			state.editJobDialog.isOpen = false;
			state.editJob.status = "fulfilled";
		},
		[editJob.pending]: (state) => {
			state.editJob.status = "pending";
		},
		[editJob.rejected]: (state, action) => {
			state.editJob.status = "error";
			state.editJob.errorMessage = action.payload ?? "An error occurred";
		},

		[createNewSkill.fulfilled]: (state, action) => {
			state.allSkills = [...state.allSkills, action.payload];
			state.createSkillDialog.isOpen = false;
			state.createNewSkill.status = "fulfilled";
		},
		[createNewSkill.pending]: (state) => {
			state.createNewSkill.status = "pending";
		},
		[createNewSkill.rejected]: (state, action) => {
			state.createNewSkill.status = "error";
			state.createNewSkill.errorMessage = action.payload ?? "An error occurred";
		},

		[editSkill.fulfilled]: (state) => {
			state.editJobDialog.isOpen = false;
			state.editSkill.status = "fulfilled";
		},
		[editSkill.pending]: (state) => {
			state.editSkill.status = "pending";
		},
		[editSkill.rejected]: (state, action) => {
			state.editSkill.status = "error";
			state.editSkill.errorMessage = action.payload ?? "An error occurred";
		},

		[fetchMaxWorkersByLocationJob.fulfilled]: (state, action) => {
			state.fetchMaxWorkersByLocationJob.status = "fulfilled";
			state.fetchMaxWorkersByLocationJob.data = action.payload;
		},
		[fetchMaxWorkersByLocationJob.pending]: (state) => {
			state.fetchMaxWorkersByLocationJob.status = "pending";
		},
		[fetchMaxWorkersByLocationJob.rejected]: (state, action) => {
			state.fetchMaxWorkersByLocationJob.status = "error";
			state.fetchMaxWorkersByLocationJob.errorMessage =
				action.payload ?? "An error occurred";
		},
	},
});

export const {
	openAddJobDialog,
	closeAddJobDialog,
	openEditSkillDialog,
	openEditJobDialog,
	closeEditJobDialog,
	closeEditSkillDialog,
	openCreateSkillDialog,
	closeCreateSkillDialog,
	resetEditJob,
	resetCreateNewJob,
	resetCreateNewSkill,
	resetEditSkill,
	resetMaxWorkersByLocationJob,
	resetFetchJobsByUserId,
} = ManageJobsSlice.actions;

export default ManageJobsSlice.reducer;
