import {
	AccessTimeFilled,
	AccessTimeOutlined,
	FactoryOutlined,
	PersonOutlined,
} from "@mui/icons-material";
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";
import { Alert, Box, CircularProgress, Typography } from "@mui/material";
import React from "react";

import { SkillRepresentationCell } from "../../../../../../export/cross-skilling";
import { getRepLevel, getRepType } from "../../../../../../export/helpers";
import Loading from "../../../../Loading";
import useSchedulerData from "../../../controllers/use-scheduler-data";

const PublishDraftContent = ({
	shift,
	allSkillsObj,
	formatedShiftDate,
	workCenter,
	workerCountByShift,
	workerCountByShiftStatus,
}: {
	shift: any;
	allSkillsObj: any;
	formatedShiftDate?: () => string;
	workCenter: any;
	workerCountByShift: number;
	workerCountByShiftStatus: string;
}) => {
	const { allJobsObj } = useSchedulerData();
	const isJobShift = shift.jobId !== 0;

	if (workerCountByShiftStatus === "pending") {
		return (
			<Box
				width={600}
				height={180}
				display='flex'
				justifyContent='center'
				alignItems='center'
				mt={2}
			>
				<Box display='flex' flexDirection='column' alignItems='center'>
					<CircularProgress />
					<Typography sx={{ mt: 2 }}>Loading...</Typography>
				</Box>
			</Box>
		);
	}
	return (
		<Box width={600}>
			<Box
				display='flex'
				justifyContent='flex-start'
				alignItems='center'
				gap={1}
				mb={1}
			>
				<Box>
					{isJobShift ? (
						<WorkHistoryOutlinedIcon
							sx={{ color: "#0000008F", fontSize: 20 }}
						/>
					) : (
						<BuildOutlinedIcon sx={{ color: "#0000008F", fontSize: 20 }} />
					)}
				</Box>
				<Box ml={1}>
					{isJobShift ? (
						<Typography
							variant='body2'
							sx={{
								fontFamily: "Roboto",
								fontWeight: 400,
								fontSize: 14,
								color: "#1C1B1F",
								lineHeight: "20px",
								letterSpacing: "0.20px",
							}}
						>
							{allJobsObj[shift.jobId]}
						</Typography>
					) : (
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								flexWrap: "wrap",
								gap: 2,
							}}
						>
							{allSkillsObj?.map((skillLevel: any) => (
								<Box
									key={skillLevel.skillName}
									sx={{
										display: "flex",
										alignItems: "center",
										gap: "8px",
									}}
								>
									<SkillRepresentationCell
										level={skillLevel.level}
										backgroundColor={skillLevel.backColorCode}
										foregroundColor={skillLevel.foreColorCode}
										value={skillLevel.value}
										representationType={getRepType(skillLevel.repCode)}
										representationLevel={getRepLevel(
											skillLevel.repCode,
											skillLevel.level
										)}
										border={1}
										height={18}
										width={18}
										backgroundHeight={
											skillLevel.backColorCode ? "28px" : "20px"
										}
										backgroundWidth={skillLevel.backColorCode ? "28px" : "20px"}
									/>
									<Typography>{skillLevel.skillName}</Typography>
								</Box>
							))}
						</Box>
					)}
				</Box>
			</Box>

			<Box
				display='flex'
				justifyContent='flex-start'
				alignItems='center'
				gap={1}
				mb={1}
			>
				<Box>
					{<AccessTimeOutlined sx={{ color: "#0000008F", fontSize: 20 }} />}
				</Box>
				<Box ml={1}>
					<Typography
						variant='body2'
						sx={{
							fontFamily: "Roboto",
							fontWeight: 400,
							fontSize: 14,
							color: "#1C1B1F",
							lineHeight: "20px",
							letterSpacing: "0.20px",
						}}
					>
						{formatedShiftDate && formatedShiftDate()}
					</Typography>
				</Box>
			</Box>
			<Box
				display='flex'
				justifyContent='flex-start'
				alignItems='center'
				gap={1}
				mb={1}
			>
				<Box>
					{<PersonOutlined sx={{ color: "#0000008F", fontSize: 20 }} />}
				</Box>
				<Box ml={1}>
					<Typography
						variant='body2'
						sx={{
							fontFamily: "Roboto",
							fontWeight: 400,
							fontSize: 14,
							color: "#1C1B1F",
							lineHeight: "20px",
							letterSpacing: "0.20px",
						}}
					>
						{`${shift.slotsFilled || 0} / ${
							shift.numberOfWorkersNeeded
						} Workers`}
					</Typography>
				</Box>
			</Box>
			<Box
				display='flex'
				justifyContent='flex-start'
				alignItems='center'
				gap={1}
				mb={1}
			>
				<Box>
					{<FactoryOutlined sx={{ color: "#0000008F", fontSize: 20 }} />}
				</Box>
				<Box ml={1}>
					<Typography
						variant='body2'
						sx={{
							fontFamily: "Roboto",
							fontWeight: 400,
							fontSize: 14,
							color: "#1C1B1F",
							lineHeight: "20px",
							letterSpacing: "0.20px",
						}}
					>
						{workCenter?.nameWithLocation}
					</Typography>
				</Box>
			</Box>
			{workerCountByShift === 0 ? (
				<Alert severity='error' sx={{ mt: 2 }}>
					{
						"Unable to publish the shift as there are no eligible workers. Please check."
					}
				</Alert>
			) : null}
		</Box>
	);
};

export default PublishDraftContent;
