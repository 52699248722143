import { useSelector } from "react-redux";

const useSupervisorsData = () => {
	const createCompanyUserStatus = useSelector(
		(state: any) => state.gatAdminConfiguration.createCompanyUser.status
	);
	const createCompanyUserErrorMessage = useSelector(
		(state: any) => state.gatAdminConfiguration.createCompanyUser.errorMessage
	);
	const updateCompanyUserStatus = useSelector(
		(state: any) => state.gatAdminConfiguration.updateCompanyUser.status
	);
	const updateCompanyUserErrorMessage = useSelector(
		(state: any) => state.gatAdminConfiguration.updateCompanyUser.errorMessage
	);
	const companyUsers = useSelector(
		(state: any) => state.gatAdminConfiguration.companyUsers
	);
	const getCompanyUsersStatus = useSelector(
		(state: any) => state.gatAdminConfiguration.getCompanyUsers.status
	);
	const getCompanyUsersErrorMessage = useSelector(
		(state: any) => state.gatAdminConfiguration.getCompanyUsers.errorMessage
	);

	const isUserDeactivateDialogOpen = useSelector(
		(state: any) => state.gatAdminConfiguration.isDeactivateDialog.open
	);

	const userDeactivateData = useSelector(
		(state: any) => state.gatAdminConfiguration.isDeactivateDialog.data
	);

	const updateUserStatus = useSelector(
		(state: any) => state.gatAdminConfiguration.changeUserStatus.status
	);

	const changeUserStatusErrorMessage = useSelector(
		(state: any) => state.gatAdminConfiguration.changeUserStatus.errorMessage
	);

	return {
		createCompanyUserStatus,
		createCompanyUserErrorMessage,
		updateCompanyUserStatus,
		updateCompanyUserErrorMessage,
		companyUsers,
		getCompanyUsersStatus,
		getCompanyUsersErrorMessage,
		isUserDeactivateDialogOpen,
		userDeactivateData,
		updateUserStatus,
		changeUserStatusErrorMessage,
	};
};

export default useSupervisorsData;
