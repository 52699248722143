import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import addDays from "date-fns/addDays";
import React, { useEffect } from "react";

import {
	createArrayUpToNumber,
	getDuration,
} from "../../../../../../../utils/helpers";
import { ShiftType } from "../../../../../../gat-admin/store/gat-admin-slice-types";
import {
	MAXIMUM_SHIFT_PATTERN_DAYS,
	MAXIMUM_SHIFT_PATTERN_PER_LINE,
	MINIMUM_SHIFT_PATTERN_DAYS,
} from "../../../../../constants";
import useWorkersData from "../../../../../controllers/use-workers-data";
import { Schedule, ShiftPatternProps } from "../../../../../types";
import ShiftPatternDayCell from "./shift-pattern-day-cell";

const ShiftPatternDayCellGroup = (props: ShiftPatternProps) => {
	const [numberOfLines, setNumberOfLines] = React.useState<number>(1);
	const [shiftPatternArray, setShiftPatternArray] = React.useState<Schedule[]>(
		props.values.schedule
	);
	const [initialized, setInitialized] = React.useState<boolean>(false);

	const { allShiftSchedules } = useWorkersData();

	useEffect(() => {
		// Update cells based on number of days
		const numberOfDays =
			Number(props.values["numberOfDays"]) >= MINIMUM_SHIFT_PATTERN_DAYS
				? Number(props.values["numberOfDays"]) <= MAXIMUM_SHIFT_PATTERN_DAYS
					? Number(props.values["numberOfDays"])
					: MAXIMUM_SHIFT_PATTERN_DAYS
				: MINIMUM_SHIFT_PATTERN_DAYS;
		setNumberOfLines(Math.ceil(numberOfDays / MAXIMUM_SHIFT_PATTERN_PER_LINE));
		const newShiftPatternArray = [...props.values.schedule];
		if (!initialized) {
			setInitialized(false);
			const allDaysArray = createArrayUpToNumber(props.values.numberOfDays);
			const newFormattedSchedules: Schedule[] = [];
			allDaysArray.forEach((n: number) => {
				const foundSchedule: Schedule | undefined = props.values.schedule.find(
					(s: Schedule) => s.dayNo === n
				);
				if (foundSchedule) {
					newFormattedSchedules.push(foundSchedule);
				} else {
					newFormattedSchedules.push({
						dayNo: n,
						shiftTypeId: null,
						colorCode: "#E0E0E0",
					});
				}
			});
			setShiftPatternArray(newFormattedSchedules);
		} else if (props.values.schedule.length > numberOfDays) {
			const numberOfDaysToDelete = newShiftPatternArray.length - numberOfDays;
			newShiftPatternArray.splice(-1, numberOfDaysToDelete);
			setShiftPatternArray(newShiftPatternArray);
		} else if (numberOfDays > props.values.schedule.length) {
			let lastDayInShiftPatternArray = newShiftPatternArray.length;
			do {
				lastDayInShiftPatternArray += 1;
				const newDay = {
					dayNo: lastDayInShiftPatternArray,
					shiftTypeId: null,
					colorCode: undefined,
				};
				newShiftPatternArray.push(newDay);
			} while (lastDayInShiftPatternArray < numberOfDays);
			setShiftPatternArray(newShiftPatternArray);
		}
	}, [props, initialized]);

	const getTotalShiftScheduleHours = () => {
		let returnValue = "0 Hours";
		let totalHours = 0;
		try {
			const copyOfSchedule = [...props.values.schedule];
			const allActiveShiftSchedules = copyOfSchedule.filter(
				(s: Schedule) => s.shiftTypeId !== null
			);
			allActiveShiftSchedules.forEach((activeSchedule: Schedule) => {
				const fullShiftDetail = allShiftSchedules.find(
					(shiftSchedule: ShiftType) =>
						shiftSchedule.id === activeSchedule.shiftTypeId
				);
				if (fullShiftDetail) {
					totalHours += Number(
						getDuration(
							fullShiftDetail.startTime,
							fullShiftDetail.endTime,
							fullShiftDetail.unpaidBreakMins
						)
					);
				}
			});
			const res = Math.round(totalHours * 100) / 100;
			returnValue = `${res} Hours`;
			return returnValue;
		} catch (e) {
			// Do nothing
			return returnValue;
		}
	};

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				alignItems: "flex-end",
				justifyContent: "center",
				width: "800px",
				flex: 1,
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					width: "748px",
					flex: 1,
				}}
			>
				{createArrayUpToNumber(numberOfLines).map((lineNumber) => {
					return (
						<Box
							key={`shift-pattern-group-${lineNumber}`}
							sx={{
								display: "flex",
								flexDirection: "column",
								height: "79px",
								width: "748px",
								marginBottom: "16px",
							}}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									height: "23px",
									alignItems: "center",
									width: "748px",
								}}
							>
								{shiftPatternArray.map((value, index) => {
									if (
										index >=
											MAXIMUM_SHIFT_PATTERN_PER_LINE * (lineNumber - 1) &&
										index < MAXIMUM_SHIFT_PATTERN_PER_LINE * lineNumber
									) {
										if (props.values.startDate) {
											let endDay =
												value.dayNo + (shiftPatternArray.length - index - 1);
											endDay =
												endDay > MAXIMUM_SHIFT_PATTERN_PER_LINE * lineNumber
													? MAXIMUM_SHIFT_PATTERN_PER_LINE * lineNumber
													: endDay;
											endDay =
												shiftPatternArray.length < endDay
													? shiftPatternArray.length
													: endDay;
											const daysHeaderTextValue = `Day ${value.dayNo} - ${endDay}`;

											if (
												index ===
												MAXIMUM_SHIFT_PATTERN_PER_LINE * (lineNumber - 1)
											) {
												return (
													<Box
														key={`days-header-${index}`}
														sx={{
															flex: 1,
															display: "flex",
															justifyContent: "center",
															alignItems: "center",
														}}
													>
														<Typography
															sx={{
																fontSize: "12px",
																color: "rgba(0,0,0,0.38)",
																lineHeight: "20px",
																fontFamily: "Roboto",
																fontWeight: "400",
															}}
															noWrap
														>
															{daysHeaderTextValue}
														</Typography>
													</Box>
												);
											} else {
												return null;
											}
										} else {
											return null;
										}
									} else {
										return null;
									}
								})}
							</Box>
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									height: "56px",
									width: "748px",
								}}
							>
								{shiftPatternArray.map((value, index) => {
									if (
										index >=
											MAXIMUM_SHIFT_PATTERN_PER_LINE * (lineNumber - 1) &&
										index < MAXIMUM_SHIFT_PATTERN_PER_LINE * lineNumber
									) {
										return (
											<ShiftPatternDayCell
												key={`shift-pattern-day-cell-${value.dayNo}`}
												dayNo={value.dayNo}
												colorCode={value.colorCode}
												date={
													props.values.startDate
														? new Date(
																addDays(
																	new Date(props.values.startDate),
																	value.dayNo - 1
																)
																// eslint-disable-next-line no-mixed-spaces-and-tabs
														  )
														: undefined
												}
											/>
										);
									} else {
										return null;
									}
								})}
							</Box>
						</Box>
					);
				})}
			</Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					width: "80px",
					height: "100%",
					alignItems: "flex-start",
					justifyContent: "flex-end",
					marginBottom: "14px",
					marginLeft: "8px",
				}}
			>
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontSize: "12px",
						fontWeight: "400",
						lineHeight: "20px",
						letterSpacing: "0.4px",
					}}
				>
					Total
				</Typography>
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontSize: "14px",
						fontWeight: "400",
						lineHeight: "20px",
						color: "rgba(0, 0, 0, 0.87)",
						letterSpacing: "0.17px",
					}}
				>
					{getTotalShiftScheduleHours()}
				</Typography>
			</Box>
		</Box>
	);
};

export default ShiftPatternDayCellGroup;
