import { Box, Typography } from "@mui/material";
import React from "react";

interface ColumnHeaderProps {
    text: string;
}

const ColumnHeader: React.FC<ColumnHeaderProps> = ({ text }) => {
    return (
        <Box
            sx={{
                marginTop: "8px",
                marginLeft: "8px",
            }}
        >
            <Typography
                sx={{ wordBreak: "break-word" }}
                whiteSpace="normal"
                style={{
                    width: "100px",
                    fontSize: "14px",
                    fontWeight: 500,
                    padding: "4px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "2",
                    WebkitBoxOrient: "vertical",
                    marginLeft: "10px",
                }}
            >
                {text}
            </Typography>
        </Box>
    );
};

export default ColumnHeader;
