import React from "react";

interface JobColorProps {
	color: string;
}

const JobColor: React.FC<JobColorProps> = ({ color }) => {
	return (
		<div
			style={{
				minHeight: "16px",
				minWidth: "16px",
				backgroundColor: color,
				borderRadius: "50%",
				border: "1px solid gray",
			}}
		></div>
	);
};

export default JobColor;
