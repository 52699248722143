import CachedIcon from "@mui/icons-material/Cached";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import { isToday as isTodayDate } from "date-fns";
import format from "date-fns/format";
import isSunday from "date-fns/isSunday";
import subDays from "date-fns/subDays";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
	allowFlexibleWorkersForShifts,
	assignWorker,
	fetchAllWorkers,
	fetchAvailability,
	fetchJobs,
	fetchShifts,
	fetchShiftsRequirement,
	fetchUnallocatedWorkers,
	fetchWorkCenters,
	generateSchedule,
	lastAvailabilityChanged,
	resetAllowFlexibleWorkersForShifts,
	resetGenerateSchedule,
	resetRenotifyWorkers,
	resetUpdateAvailability,
	resetUpdateShiftsRequirement,
	setAnyDateOfSelectedWeek,
	setAvailability,
	setSelectedWorker,
	setShiftRequirement,
	updateAvailability,
	updateShiftsRequirement,
	updateWeeklyDraftShift,
} from "../store/weekly-schedule-slice";
const useScheduleController = () => {
	const dispatch = useDispatch();

	const allWorkers = useSelector((state) => state.weeklySchedule.allWorkers);
	const allJobRoles = useSelector((state) => state.weeklySchedule.jobs);
	const weekShiftsData = useSelector((state) => state.weeklySchedule.shifts);
	const fetchShiftsForThisWeekStatus = useSelector(
		(state) => state.weeklySchedule.fetchShifts.status
	);
	const workCenters = useSelector((state) => state.weeklySchedule.workCenters);
	const fetchWorkCentersStatus = useSelector(
		(state) => state.weeklySchedule.fetchWorkCenters.status
	);
	const fetchJobsStatus = useSelector(
		(state) => state.weeklySchedule.fetchJobs.status
	);
	const totalRequirementHours = useSelector(
		(state) => state.weeklySchedule.totalRequirementHours
	);
	const totalAvailabilityHours = useSelector(
		(state) => state.weeklySchedule.totalAvailabilityHours
	);
	const anyDateOfSelectedWeek =
		new Date(
			useSelector((state) => state.weeklySchedule.anyDateOfSelectedWeek)
		) ?? new Date();
	const shiftsRequirement = useSelector(
		(state) => state.weeklySchedule.shiftsRequirement
	);
	const fetchShiftsRequirementStatus = useSelector(
		(state) => state.weeklySchedule.fetchShiftsRequirement.status
	);
	const updateShiftsRequirementStatus = useSelector(
		(state) => state.weeklySchedule.updateShiftsRequirement.status
	);
	const updateShiftsRequirementError = useSelector(
		(state) => state.weeklySchedule.updateShiftsRequirement.errorMessage
	);
	const fetchAvailabilityTypesStatus = useSelector(
		(state) => state.weeklySchedule.fetchAvailabilityTypes.status
	);
	const updateAvailabilityStatus = useSelector(
		(state) => state.weeklySchedule.updateAvailability.status
	);
	const availabilityTypes = useSelector(
		(state) => state.weeklySchedule.availabilityTypes
	);
	const availability = useSelector(
		(state) => state.weeklySchedule.availability
	);
	const generatedSchedule = useSelector(
		(state) => state.weeklySchedule.generatedSchedule
	);
	const generateScheduleStatus = useSelector(
		(state) => state.weeklySchedule.generateSchedule.status
	);
	const fetchAvailabilityStatus = useSelector(
		(state) => state.weeklySchedule.fetchAvailability.status
	);
	const allowFlexibleWorkersForShiftsStatus = useSelector(
		(state) => state.weeklySchedule.allowFlexibleWorkersForShifts.status
	);
	const allowFlexibleWorkersForShiftsErrorMessage = useSelector(
		(state) => state.weeklySchedule.allowFlexibleWorkersForShifts.errorMessage
	);
	const weeklyDraftShifts = useSelector(
		(state) => state.weeklySchedule.weeklyDraftShifts
	);
	const fetchAllWorkersStatus = useSelector(
		(state) => state.weeklySchedule.fetchAllWorkers.status
	);
	const fetchUnallocatedWorkersStatus = useSelector(
		(state) => state.weeklySchedule.fetchUnallocatedWorkers.status
	);
	const unallocatedWorkers = useSelector(
		(state) => state.weeklySchedule.unallocatedWorkers
	);
	const selectedWorker = useSelector(
		(state) => state.weeklySchedule.selectedWorker
	);
	const assignWorkerStatus = useSelector(
		(state) => state.weeklySchedule.assignWorker.status
	);
	const fetchShiftsError = useSelector(
		(state) => state.weeklySchedule.fetchShifts.errorMessage
	);
	const fetchJobsError = useSelector(
		(state) => state.weeklySchedule.fetchJobs.errorMessage
	);
	const fetchAllWorkersError = useSelector(
		(state) => state.weeklySchedule.fetchAllWorkers.errorMessage
	);
	const fetchAvailabilityError = useSelector(
		(state) => state.weeklySchedule.fetchAvailability.errorMessage
	);
	const fetchShiftsRequirementError = useSelector(
		(state) => state.weeklySchedule.fetchShiftsRequirement.errorMessage
	);
	const fetchUnallocatedWorkersError = useSelector(
		(state) => state.weeklySchedule.fetchUnallocatedWorkers.errorMessage
	);
	const fetchWorkCentersError = useSelector(
		(state) => state.weeklySchedule.fetchWorkCenters.errorMessage
	);
	const assignWorkerError = useSelector(
		(state) => state.weeklySchedule.assignWorker.errorMessage
	);
	const activeAvailability = useSelector(
		(state) => state.weeklySchedule.activeAvailability
	);
	const renotifyWorkersStatus = useSelector(
		(state) => state.weeklySchedule.renotifyWorkers.status
	);
	const renotifyWorkersErrorMessage = useSelector(
		(state) => state.weeklySchedule.renotifyWorkers.errorMessage
	);
	const changeDateOfSelectedWeek = (date) => {
		dispatch(setAnyDateOfSelectedWeek(date.toISOString()));
	};
	const changeSelectedWorker = (worker, selectedOnDate) => {
		dispatch(setSelectedWorker({ worker, selectedOnDate }));
	};
	const selectWeek = (date) => {
		try {
			let returnValue = Array(8)
				.fill(new Date(date))
				.map(
					(el, idx) => new Date(el.setDate(el.getDate() - el.getDay() + idx))
				);
			if (isSunday(new Date(date))) {
				returnValue = Array(8)
					.fill(subDays(new Date(date), 7))
					.map(
						(el, idx) => new Date(el.setDate(el.getDate() - el.getDay() + idx))
					);
			}
			return returnValue.slice(1, 8);
		} catch (e) {
			return [];
		}
	};

	const isToday = (date) => {
		//let today = new Date();
		// This will only work with double equals
		//return today.toDateString() == date.toDateString();
		// using isToday from date-fns
		return isTodayDate(date);
	};

	const isPreviousDate = (date) => {
		return new Date().setHours(0, 0, 0, 0) > date.setHours(0, 0, 0, 0);
	};

	const getShiftsByDate = (date, shifts) => {
		let returnValue = [];
		returnValue = shifts.filter(
			(shift) =>
				new Date(
					new Date(shift.startDateTime).setHours(0, 0, 0, 0)
				).getTime() === new Date(date.setHours(0, 0, 0, 0)).getTime()
		);
		return returnValue;
	};

	const isWeekend = (date) => {
		let returnValue;
		returnValue = date.getDay() === 6 || date.getDay() === 0;
		return returnValue;
	};

	const updateAvailabilityForWorkerAtDate = (dayAvailability) => {
		try {
			let newAvailability = [];
			let updated = false;
			availability.forEach((oldAvailability) => {
				if (
					oldAvailability.availabilityDate ===
						dayAvailability.availabilityDate &&
					oldAvailability.workerId === dayAvailability.workerId
				) {
					newAvailability.push(dayAvailability);
					updated = true;
				} else {
					newAvailability.push(oldAvailability);
				}
			});
			if (!updated) {
				newAvailability.push(dayAvailability);
			}
			dispatch(lastAvailabilityChanged(dayAvailability));
			dispatch(setAvailability(newAvailability));
		} catch (e) {
			// Do nothing
		}
	};

	const updateShiftsRequirementForThisWeek = (newShiftsRequirement) => {
		dispatch(updateShiftsRequirement(newShiftsRequirement));
	};

	const updateAvailabilityForThisWeek = () => {
		dispatch(updateAvailability(availability));
	};

	const fetchShiftsForThisWeek = (dateRangeObject) => {
		dispatch(fetchShifts(dateRangeObject));
	};

	const fetchAllWorkCenters = () => {
		dispatch(fetchWorkCenters());
	};

	const fetchAllJobs = () => {
		dispatch(fetchJobs());
	};

	const fetchWorkers = () => {
		dispatch(fetchAllWorkers());
	};

	const assignWorkerToShift = (obj) => {
		dispatch(assignWorker(obj));
	};

	const changeShiftRequirements = (newShiftRequirements) => {
		dispatch(setShiftRequirement(newShiftRequirements));
	};

	const callAlgorithm = (dateRangeObject) => {
		const today = new Date();
		const fromDate = dateRangeObject.fromDate;
		const toDate = dateRangeObject.toDate;
		let newFromDate = format(fromDate, "yyyy-MM-dd");
		let newToDate = format(toDate, "yyyy-MM-dd");
		if (isPreviousDate(fromDate)) {
			newFromDate = format(today, "yyyy-MM-dd");
		}
		if (isPreviousDate(toDate)) {
			// Do not call algorithm
			return null;
		} else {
			dispatch(
				generateSchedule({
					fromDate: newFromDate,
					toDate: newToDate,
				})
			);
		}
	};

	const fetchUnallocatedWorkersForDateRange = (dateRangeObject) => {
		const today = new Date();
		const fromDate = dateRangeObject.fromDate;
		const toDate = dateRangeObject.toDate;
		let newFromDate = format(fromDate, "yyyy-MM-dd");
		let newToDate = format(toDate, "yyyy-MM-dd");
		if (isPreviousDate(fromDate)) {
			newFromDate = format(today, "yyyy-MM-dd");
		}
		if (isPreviousDate(toDate)) {
			// Do not call algorithm
			return null;
		} else {
			dispatch(
				fetchUnallocatedWorkers({
					fromDate: newFromDate,
					toDate: newToDate,
				})
			);
		}
	};

	const fetchAvailabilityForThisWeek = (dateRangeObject) => {
		let obj = {
			...dateRangeObject,
			workers: allWorkers,
		};
		dispatch(fetchAvailability(obj));
	};

	const fetchShiftsRequirementForThisWeek = (dateRangeObject) => {
		dispatch(fetchShiftsRequirement(dateRangeObject));
	};

	const getDefaultRequirementForJobInDay = (jobId, dateString) => {
		try {
			let returnValue = 0;
			shiftsRequirement.forEach((requirement) => {
				if (requirement.jobId === jobId && requirement.reqDate === dateString) {
					returnValue = requirement.workerCount;
				}
			});
			return returnValue;
		} catch (e) {
			// Do nothing
			return 0;
		}
	};

	const getDefaultAvailabilityForWorkerInDay = (workerId, dateString) => {
		try {
			let returnValue = isWeekend(new Date(dateString)) ? 2 : 1;
			availability.forEach((workerAvailability) => {
				if (
					workerAvailability.workerId.toLowerCase() ===
						workerId.toLowerCase() &&
					workerAvailability.availabilityDate === dateString
				) {
					returnValue = workerAvailability.typeId;
				}
			});
			return returnValue;
		} catch (e) {
			// Do nothing
			return 1;
		}
	};

	const getRequirementForDate = (dateString, allShifts) => {
		let returnValue = 0;
		try {
			const dayOutput =
				allShifts.filter((s) => {
					return format(new Date(s.startDateTime), "yyyy-MM-dd") === dateString;
				}) ?? [];
			return dayOutput.length;
		} catch (e) {
			return returnValue;
		}
	};

	const getUnallocatedWorkersForDate = (dateString) => {
		const getNameOfWorkerById = (id) => {
			let returnValue = "";
			allWorkers.forEach((worker) => {
				if (worker.id === id) {
					returnValue = `${worker.firstName} ${worker.lastName}`;
				}
			});
			return returnValue;
		};
		try {
			const dayOutput = unallocatedWorkers.filter((s) => {
				return format(new Date(s.day), "yyyy-MM-dd") === dateString;
			});
			const filteredUnallocatedWorkers = [];
			dayOutput[0]["unassignedWorkers"].forEach((worker) => {
				filteredUnallocatedWorkers.push({
					...worker,
					name: getNameOfWorkerById(worker.workerid),
				});
			});
			return filteredUnallocatedWorkers;
		} catch (e) {
			return [];
		}
	};

	const getDateRangeText = (date, hideYear = false) => {
		try {
			let returnValue = "";
			const thisWeekDates = selectWeek(date);
			const firstDate = thisWeekDates[0];
			const lastDate = thisWeekDates[thisWeekDates.length - 1];
			const firstDateMonth = format(firstDate, "LLL");
			const lastDateMonth = format(lastDate, "LLL");
			const firstDateDate = format(firstDate, "dd");
			const lastDateDate = format(lastDate, "dd");
			const firstDateYear = format(firstDate, "yyyy");
			const lastDateYear = format(lastDate, "yyyy");
			let firstDateString = `${firstDateMonth.toUpperCase()} ${firstDateDate}${hideYear ? "":`, ${firstDateYear}`}`;
			let lastDateString = `${lastDateMonth.toUpperCase()} ${lastDateDate}${hideYear ? "":`, ${lastDateYear}`}`;
			if (firstDateMonth === lastDateMonth) {
				if (firstDateYear === lastDateYear) {
					firstDateString = `${firstDateMonth.toUpperCase()} ${firstDateDate}`;
					lastDateString = `${lastDateMonth.toUpperCase()} ${lastDateDate}${hideYear ? "":`, ${lastDateYear}`}`;
				} else {
					firstDateString = `${firstDateMonth.toUpperCase()} ${firstDateDate}${hideYear ? "":`, ${firstDateYear}`}`;
					lastDateString = `${lastDateMonth.toUpperCase()} ${lastDateDate}${hideYear ? "":`, ${lastDateYear}`}`;
				}
			} else if (firstDateYear === lastDateYear) {
				firstDateString = `${firstDateMonth.toUpperCase()} ${firstDateDate}`;
				lastDateString = `${lastDateMonth.toUpperCase()} ${lastDateDate}${hideYear ? "":`, ${lastDateYear}`}`;
			}
			returnValue = `${firstDateString} - ${lastDateString}`;
			return returnValue;
		} catch (e) {
			return "";
		}
	};

	const getShiftByJobId = (jobId, shifts) => {
		try {
			let returnValue = [];
			if (shifts) {
				returnValue = shifts.filter((shift) => shift.jobId === jobId);
			}
			return returnValue;
		} catch (e) {
			// Do nothing
			return [];
		}
	};

	const getAvailabilityIcon = (id) => {
		let icon = (
			<CheckCircleOutlineIcon
				sx={{
					color: "#689F38",
				}}
			/>
		);
		// 1 - Available
		// 2 - Absent
		// 3 - In Training
		switch (id) {
			case 2:
				icon = (
					<CloseIcon
						sx={{
							color: "#E46962",
						}}
					/>
				);
				break;
			case 3:
				icon = (
					<CachedIcon
						sx={{
							color: "#F9A825",
						}}
					/>
				);
				break;
			default:
				// Do nothing
				break;
		}
		return icon;
	};

	const createFlexDraftShift = (shiftsIds) => {
		try {
			let shiftsWithNewId = [];
			let updatedWeeklyDraftShift = [];
			weeklyDraftShifts.forEach((shift) => {
				if (shiftsIds.includes(shift.id)) {
					shiftsWithNewId.push({
						...shift,
						ftShiftId: shift.id,
					});
				} else {
					updatedWeeklyDraftShift.push(shift);
				}
			});
			dispatch(updateWeeklyDraftShift(updatedWeeklyDraftShift));
			dispatch(allowFlexibleWorkersForShifts(shiftsWithNewId));
		} catch (e) {
			// Do nothing
		}
	};

	const getTotalRequirementHours = (startDate) => {
		try {
			let returnValue = 0;
			const findRange = totalRequirementHours.filter(
				(req) => req.startDate === startDate
			);
			if (findRange) {
				returnValue = findRange[0].requirementHours;
			}
			return returnValue;
		} catch (e) {
			return 0;
		}
	};

	const getTotalAvailabilityHours = (startDate) => {
		try {
			let returnValue = 0;
			const findRange = totalAvailabilityHours.filter(
				(req) => req.startDate === startDate
			);
			if (findRange) {
				returnValue = findRange[0].availabilityHours;
			}
			return returnValue;
		} catch (e) {
			return 0;
		}
	};

	return [
		{
			allWorkers,
			allJobRoles,
			weekShiftsData,
			workCenters,
			anyDateOfSelectedWeek,
			shiftsRequirement,
			fetchShiftsRequirementStatus,
			fetchAvailabilityTypesStatus,
			fetchAvailabilityStatus,
			fetchShiftsForThisWeekStatus,
			fetchWorkCentersStatus,
			fetchJobsStatus,
			updateShiftsRequirementStatus,
			totalRequirementHours,
			availabilityTypes,
			updateAvailabilityStatus,
			totalAvailabilityHours,
			generatedSchedule,
			generateScheduleStatus,
			allowFlexibleWorkersForShiftsStatus,
			allowFlexibleWorkersForShiftsErrorMessage,
			weeklyDraftShifts,
			availability,
			fetchAllWorkersStatus,
			fetchUnallocatedWorkersStatus,
			unallocatedWorkers,
			selectedWorker,
			assignWorkerStatus,
			fetchShiftsError,
			fetchUnallocatedWorkersError,
			fetchShiftsRequirementError,
			fetchJobsError,
			fetchAllWorkersError,
			fetchAvailabilityError,
			fetchWorkCentersError,
			assignWorkerError,
			updateShiftsRequirementError,
			activeAvailability,
			renotifyWorkersStatus,
			renotifyWorkersErrorMessage,
		},
		{
			selectWeek,
			getDateRangeText,
			isWeekend,
			isToday,
			getShiftsByDate,
			getShiftByJobId,
			changeDateOfSelectedWeek,
			updateAvailabilityForWorkerAtDate,
			updateShiftsRequirementForThisWeek,
			updateAvailabilityForThisWeek,
			fetchShiftsRequirementForThisWeek,
			fetchAvailabilityForThisWeek,
			fetchAllJobs,
			fetchWorkers,
			fetchAllWorkCenters,
			getDefaultRequirementForJobInDay,
			isPreviousDate,
			getAvailabilityIcon,
			getDefaultAvailabilityForWorkerInDay,
			fetchShiftsForThisWeek,
			resetUpdateAvailability,
			resetUpdateShiftsRequirement,
			resetGenerateSchedule,
			getUnallocatedWorkersForDate,
			getRequirementForDate,
			callAlgorithm,
			createFlexDraftShift,
			resetAllowFlexibleWorkersForShifts,
			getTotalRequirementHours,
			getTotalAvailabilityHours,
			fetchUnallocatedWorkersForDateRange,
			changeSelectedWorker,
			assignWorkerToShift,
			changeShiftRequirements,
			resetRenotifyWorkers,
		},
	];
};

export default useScheduleController;
