import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import debounce from "lodash.debounce";
import React, { ChangeEvent, useMemo, useState } from "react";

import { Filter } from "./types";

const BACKGROUND_COLOR = "#fff";
const BORDER_COLOR = "rgba(0,0,0,0.12)";

interface CustomTextFieldProps {
  searchedValue: string;
  setSearchedValue: (value: string) => void;
  handleSearch: (text: string, filterId: number) => void;
  filter: Filter;
}

const FilterSearch: React.FC<CustomTextFieldProps> = ({
  searchedValue,
  setSearchedValue,
  handleSearch,
  filter,
}) => {
  // Create a debounced version of the handleSearch function
  const debouncedHandleSearch = useMemo(
    () => debounce((text) => handleSearch(text, filter.id), 300),
    [handleSearch, filter.id]
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    setSearchedValue(text);
    debouncedHandleSearch(text);
  };

  return (
    <TextField
      hiddenLabel
      value={searchedValue}
      onChange={handleChange}
      placeholder={"SEARCH"}
      size="small"
      InputProps={{
        startAdornment: (
          <SearchIcon
            style={{
              height: "24px",
              width: "24px",
              color: "rgba(0,0,0,0.56)",
            }}
          />
        ),
        endAdornment:
          searchedValue !== "" ? (
            <ClearIcon
              onClick={() => {
                setSearchedValue("");
                handleSearch("", filter.id);
              }}
              style={{
                height: "24px",
                width: "24px",
                color: "rgba(0,0,0,0.56)",
                cursor: "pointer",
              }}
            />
          ) : (
            <></>
          ),
      }}
      sx={{
        "& .MuiOutlinedInput-root": {
          "&:hover fieldset": {
            borderColor: BORDER_COLOR,
          },
          "&.Mui-focused fieldset": {
            borderColor: BORDER_COLOR,
          },
        },
        width: "100%",
        border: 0,
        borderRadius: "4px",
        input: {
          paddingLeft: "8px",
          color: "rgba(0,0,0,0.87)",
          fontFamily: "Roboto",
          fontSize: "12px",
          fontWeight: "400",
          lineHeight: "20px",
          letterSpacing: "0.4px",
          backgroundColor: BACKGROUND_COLOR,
          "&:hover": {
            backgroundColor: BACKGROUND_COLOR,
          },
        },
      }}
    />
  );
};

export default FilterSearch;
