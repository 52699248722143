// TODO - Type 'any' needs to be fixed.
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
	getCompanyData,
	getLocations,
	setCurrentCompanyData,
	updateCompanyData,
	updateCompanySettings,
} from "../../../../../../../export/gat-admin";
import useGatAdminConfigData from "../../../../../../../ts/components/gat-admin/controllers/use-gat-admin-config-data";
import { findDifferentKeys } from "../../../../utils";

const initialCompany = {
	name: "",
	hqFullAddress: "",
};

const initialCoreState = {
	maxLocationsByContract: 0,
};

const useCoreController = (companyData:any) => {
	const dispatch = useDispatch();
	const {
		creatingNewCompany,
		companySettingsObj,
		createNewCompanyStatus,
		updateCompanyDataStatus,
		updateCompanySettingsStatus,
		getCompanySettingsStatus,
		getCompanyDataStatus,
		newCompanyId,
		companyDataValue,
		currentCompanyData,
		locations,
	} = useGatAdminConfigData();

	const [loading, setLoading] = useState(false);

	const [company, setCompany] = useState(initialCompany);
	const [newCompany, setNewCompany] = useState<any>(initialCompany);

	const [coreSettings, setCoreSettings] = useState(initialCoreState);
	const [newCoreSettings, setNewCoreSettings] = useState<any>(initialCoreState);

	useEffect(() => {
		if (Object.keys(currentCompanyData).length > 0) {
			const obj = {
				id: currentCompanyData.id,
				name: currentCompanyData.name,
				hqFullAddress: currentCompanyData.hqFullAddress,
			};
			setCompany(obj);
			setNewCompany(obj);
		} else {
			if (companyData.id) {
				dispatch(getCompanyData(companyData.id));
			}
		}
	}, [currentCompanyData, companyData, dispatch]);

	useEffect(() => {
		if (Object.keys(companySettingsObj).length > 0) {
			const obj = {
				maxLocationsByContract:
					companySettingsObj["clientApp.maxLocationsByContract"].value,
				shareWorkersAcrossLocations:
					companySettingsObj["clientApp.shareWorkersAcrossLocations"].value,
			};
			setCoreSettings(obj);
			setNewCoreSettings(obj);
		}
	}, [companySettingsObj]);

	useEffect(() => {
		if (updateCompanyDataStatus === "fulfilled") {
			setCompany(newCompany);
			dispatch(setCurrentCompanyData(newCompany));
			setLoading(false);
		}
	}, [updateCompanyDataStatus, dispatch, newCompany]);

	useEffect(() => {
		if (getCompanyDataStatus === "fulfilled") {
			setCompany(companyDataValue);
			dispatch(setCurrentCompanyData(companyDataValue));
			setLoading(false);
		}
	}, [getCompanyDataStatus, dispatch, companyDataValue]);

	useEffect(() => {
		if (updateCompanySettingsStatus === "fulfilled") {
			setCoreSettings(newCoreSettings);
			setLoading(false);
		}
	}, [updateCompanySettingsStatus, newCoreSettings]);

	useEffect(() => {
		if (companyData.id) {
			dispatch(getLocations(companyData.id));
		}
	}, [companyData.id, dispatch]);

	const handleSaveCompany = () => {
		setLoading(true);
		const changedCompany :any= findDifferentKeys(company, newCompany);
		if (changedCompany.length > 0) {
			setCompany(newCompany);
			if (!("id" in newCompany) && newCompanyId) newCompany.id = newCompanyId;
			dispatch(updateCompanyData(newCompany));
		}
		const changedSettings :any= findDifferentKeys(coreSettings, newCoreSettings);
		if (changedSettings.length > 0) {
			const changedValues:any = changedSettings.map((key:any) => {
				const item = "clientApp." + key;
				return {
					...companySettingsObj[item],
					value: newCoreSettings[key],
				};
			});
			setCoreSettings(newCoreSettings);
			dispatch(updateCompanySettings(changedValues));
		}
	};

	return [
		{
			company,
			newCompany,
			coreSettings,
			newCoreSettings,
			creatingNewCompany,
			createNewCompanyStatus,
			updateCompanyDataStatus,
			updateCompanySettingsStatus,
			getCompanySettingsStatus,
			loading,
			locations,
		},
		{
			setCompany,
			setNewCompany,
			setCoreSettings,
			setNewCoreSettings,
			handleSaveCompany,
		},
	];
};

export default useCoreController;
