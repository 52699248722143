import { RefObject, useEffect, useMemo, useState } from "react";

export default function useOnView(ref: RefObject<HTMLDivElement>) {
	const [isIntersecting, setIntersecting] = useState(false);

	const observer = useMemo(
		() =>
			new IntersectionObserver(([entry]) =>
				setIntersecting(entry.isIntersecting)
			),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[ref]
	);

	useEffect(() => {
		if (!ref.current) return;
		observer.observe(ref.current);
		return () => observer.disconnect();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return isIntersecting;
}
