// TODO - Type 'any' needs to be fixed.
import { Box, Button, Typography } from "@mui/material";
import React from "react";

import Highlight from "../../../../../Highlight";

type JobFiltersProps = {
	jobs: any[];
	searchText: string;
};

const JobColumn = ({ jobs, searchText }: JobFiltersProps) => {
	const [jobExpand, setJobExpand] = React.useState<boolean>(false);

	const totalChars =
		jobs.length > 2 && jobs[0].jobName.length + jobs[1].jobName.length;

	const limit = totalChars > 16 ? 1 : 2;

	if (jobs.length === 0) return null;
	return (
		<Box display='flex' flexWrap='wrap' mt={1} mb={1} alignItems='center'>
			{jobs.length > 0 ? (
				jobs
					.slice(0, jobExpand || searchText ? undefined : limit)
					.map((role: { jobName: string; id: number }, index: number) => (
						<Box key={role.id} mr={0.8}>
							<Highlight
								value={`${role.jobName} ${
									index === jobs.length - 1 ? " " : ","
								}`}
								searchText={searchText}
							/>
						</Box>
					))
			) : (
				<></>
			)}
			{jobs.length > 2 && !searchText ? (
				<Button onClick={(e) => setJobExpand(!jobExpand)} size='small'>
					{jobExpand ? "Show Less" : "Show More"}
				</Button>
			) : null}
		</Box>
	);
};

export default JobColumn;
