import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import isPast from "date-fns/isPast";
import { useEffect, useState } from "react";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import ShiftDetailsTable from "../../../../../features/ShiftScheduler/ShiftDetails/ShiftDetailsTable";
import ShiftMetaData from "../../../../../features/ShiftScheduler/ShiftDetails/ShiftMetaData";
import {
  getShiftDetails,
  renotifyWorkers,
} from "../../../store/weekly-schedule-slice";

const useStyles = makeStyles((theme) => ({
  actionBar: {
    marginTop: 8,
    marginBottom: 8,
    marginRight: 16,
  },

  createdMetaData: {
    fontSize: "0.8em",
    color: "#ccc",
  },
}));

export default function ScheduledShiftsViewDetails({
  open,
  handleClose,
  shiftId,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userRoles = useSelector((state) => state.user.userData.userRoles);

  const [workersSelected, setWorkersSelected] = useState([]);
  const [rows, setRows] = useState([]);
  const [shiftMetadata, setShiftMetadata] = useState({});
  const [jobRoleMetadata, setJobRoleMetadata] = useState({});
  const [workCenterMetadata, setWorkCenterMetadata] = useState({});
  const [columns, setColumns] = useState([
    // { field: "id", headerName: "id", width: 260, hide: "true" },
    {
      field: "workerName",
      headerName: "Worker",
      width: 260,
      justifyContent: "flex-start !important",
      paddingleft: "10px",
    },
    {
      field: "confirmed",
      headerName: "Confirmed",
      width: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "declined",
      headerName: "Declined",
      width: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "viewed",
      headerName: "Viewed",
      width: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "notified",
      headerName: "Notified",
      width: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "none",
      headerName: "None",
      width: 140,
      align: "center",
      headerAlign: "center",
    },
  ]);
  const shiftData = useSelector((state) => state.weeklySchedule.shiftDetails);

  const allShiftsMetadata = useSelector((state) => state.weeklySchedule.shifts);

  const allJobRoles = useSelector((state) => state.weeklySchedule.jobs);

  const allWorkCenters = useSelector(
    (state) => state.supervisor?.masterData?.allWorkcenters
  );

  const status = useSelector(
    (state) => state.weeklySchedule.getShiftDetails.status
  );

  const renotifyWorkersStatus = useSelector(
    (state) => state.weeklySchedule.renotifyWorkers.status
  );

  useEffect(() => {
    // fetch the data
    if (shiftId) {
      dispatch(getShiftDetails({ shiftid: shiftId }));
    }
  }, [dispatch, shiftId]);

  useEffect(() => {
    if (shiftData) {
      let rowData = [];

      let { accepted, declined, viewed, notified, none } = shiftData;

      if (accepted) {
        accepted.forEach((worker) => {
          let workerRow = {};

          workerRow.id = worker.workerId;
          workerRow.workerName = worker.workerName;
          workerRow.confirmed = "✅";

          rowData.push(workerRow);
        });
      }

      if (declined) {
        declined.forEach((worker) => {
          let workerRow = {};

          workerRow.id = worker.workerId;
          workerRow.workerName = worker.workerName;
          workerRow.declined = "⛔";

          rowData.push(workerRow);
        });
      }

      if (viewed) {
        viewed.forEach((worker) => {
          let workerRow = {};

          workerRow.id = worker.workerId;
          workerRow.workerName = worker.workerName;
          workerRow.viewed = true;
          rowData.push(workerRow);
        });
      }

      if (notified) {
        let tempNotified = [...notified];
        if (accepted) {
          accepted?.forEach((acceptedWorker) => {
            const index = tempNotified?.findIndex(
              (el) => el.workerId === acceptedWorker.workerId
            );
            if (index !== -1) {
              tempNotified.splice(index, 1);
            }
          });
        }
        if (declined) {
          declined?.forEach((declinedWorker) => {
            const index = tempNotified?.findIndex(
              (el) => el.workerId === declinedWorker.workerId
            );

            if (index !== -1) tempNotified?.splice(index, 1);
          });
        }

        if (viewed) {
          viewed?.forEach((viewedWorker) => {
            const index = tempNotified?.findIndex(
              (el) => el.workerId === viewedWorker.workerId
            );
            if (index !== -1) tempNotified?.splice(index, 1);
          });
        }

        tempNotified.forEach((worker) => {
          let workerRow = {};

          workerRow.id = worker.workerId;
          workerRow.workerName = worker.workerName;
          workerRow.notified = "🔔";

          rowData.push(workerRow);
        });
      }

      if (none) {
        none.forEach((worker) => {
          let workerRow = {};

          workerRow.id = worker.workerId;
          workerRow.workerName = worker.workerName;
          workerRow.none = "➖";

          rowData.push(workerRow);
        });
      }

      setRows(rowData);
    }
  }, [shiftData]);

  useEffect(() => {
    if (allShiftsMetadata) {
      const currentShiftData = allShiftsMetadata.filter(
        (shift) => shift.flex === shiftId
      );
      let tempShiftMetadata = {
        ...currentShiftData[0],
        startDate: currentShiftData[0].startDateTime,
        endDate: currentShiftData[0].endDateTime,
      };
      setShiftMetadata(tempShiftMetadata);
    }
  }, [allShiftsMetadata, shiftId]);

  useEffect(() => {
    if (allJobRoles) {
      const currentJobRole = allJobRoles.find(
        (jobRole) => jobRole.id === shiftMetadata.jobId
      );
      if (currentJobRole) {
        setJobRoleMetadata(currentJobRole);
      }
    }
  }, [allJobRoles, shiftMetadata.jobId]);

  useEffect(() => {
    if (allWorkCenters) {
      const currentWorkCenter = allWorkCenters.find(
        (workCenter) => workCenter.id === shiftMetadata.workCenterId
      );
      if (currentWorkCenter) {
        setWorkCenterMetadata(currentWorkCenter);
      }
    }
  }, [allWorkCenters, shiftMetadata.workCenterId]);

  useEffect(() => {
    if (shiftData) {
      setColumns((prevState) => {
        return [
          // { field: "id", headerName: "id", width: 260, hide: "true" },
          {
            field: "workerName",
            headerName: "Worker",
            flex: 1.6,
            justifyContent: "flex-start !important",
            paddingleft: "10px",
          },
          {
            field: "confirmed",
            headerName: `Confirmed (${
              shiftData?.accepted
                ? `${Object.keys(shiftData.accepted).length}`
                : "0"
            })`,
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderHeader: () => (
              <span
                style={{
                  padding: "0px 12px",
                  fontWeight: "bold",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                {`Confirmed (${
                  shiftData?.accepted
                    ? `${Object.keys(shiftData.accepted).length}`
                    : "0"
                })`}
              </span>
            ),
          },
          {
            field: "declined",
            headerName: `Declined (${
              shiftData.declined === null ? 0 : shiftData.declined?.length
            })`,
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderHeader: () => (
              <span
                style={{
                  padding: "0px 12px",
                  fontWeight: "bold",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                {`Declined (${
                  shiftData.declined === null ? 0 : shiftData.declined?.length
                })`}
              </span>
            ),
          },
          {
            field: "viewed",
            headerName: `Viewed (${
              shiftData.viewed === null ? 0 : shiftData.viewed?.length
            })`,
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
              if (params.row?.viewed)
                return <VisibilityIcon sx={{ color: "gray" }} />;
            },
            renderHeader: () => (
              <span
                style={{
                  padding: "0px 12px",
                  fontWeight: "bold",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                {`Viewed (${
                  shiftData.viewed === null ? 0 : shiftData.viewed?.length
                })`}
              </span>
            ),
          },
          {
            field: "notified",
            headerName: `Notified (${
              shiftData.notified === null ? 0 : shiftData.notified?.length
            })`,
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderHeader: () => (
              <span
                style={{
                  padding: "0px 12px",
                  fontWeight: "bold",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                {`Notified (${
                  shiftData.notified === null ? 0 : shiftData.notified?.length
                })`}
              </span>
            ),
          },
          {
            field: "none",
            headerName: `None (${
              shiftData.none === null ? 0 : shiftData.none?.length
            })`,
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderHeader: () => (
              <span
                style={{
                  padding: "0px 12px",
                  fontWeight: "bold",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                {`None (${
                  shiftData.none === null ? 0 : shiftData.none?.length
                })`}
              </span>
            ),
          },
        ];
      });
    }
  }, [shiftData]);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="add-new-shift"
      open={open}
      maxWidth={false}
    >
      {status === "pending" ? (
        <DialogContent dividers>
          <Box
            width={1100}
            height={500}
            display="flex"
            alignItems="center"
            justifyContent="center"
            mr="auto"
            ml="auto"
            flexDirection="column"
          >
            <CircularProgress />
            <Typography>Loading Shift Details</Typography>
          </Box>
        </DialogContent>
      ) : (
        <>
          <DialogTitle
            id="customized-dialog-title"
            //   onClose={handleClose}
          >
            <ShiftMetaData
              shiftMetadata={shiftMetadata}
              handleClose={handleClose}
              jobRoleMetadata={jobRoleMetadata}
              workCenterMetadata={workCenterMetadata}
            />
          </DialogTitle>
          <DialogContent dividers>
            <ShiftDetailsTable
              rows={rows}
              columns={columns}
              setWorkersSelected={setWorkersSelected}
              isShiftPast={isPast(new Date(shiftMetadata.endDateTime))}
            />
          </DialogContent>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mt={2}
            mb={2}
            ml={3}
            mr={3}
          >
            <Box style={{ marginRight: "24px" }}>
              <Typography className={classes.createdMetaData}>
                Created by {shiftMetadata?.createdByName} on{" "}
                {new Date(
                  new Date(shiftMetadata?.createdDate).getTime() -
                    new Date().getTimezoneOffset() * 60 * 1000
                ).toString()}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button variant="text" onClick={() => handleClose()}>
                Cancel
              </Button>
              <Button
                autoFocus
                color="primary"
                variant="contained"
                sx={{
                  marginLeft: "8px",
                }}
                disabled={
                  (Array.isArray(userRoles)
                    ? userRoles.includes("ReadOnly")
                    : false) || isPast(new Date(shiftMetadata.startDateTime))
                    ? true
                    : workersSelected.length === 0 ||
                      renotifyWorkersStatus === "pending" ||
                      renotifyWorkersStatus === "error"
                }
                onClick={() =>
                  dispatch(
                    renotifyWorkers({ workers: workersSelected, shiftId })
                  )
                }
              >
                {renotifyWorkersStatus === "pending" ? (
                  <Box position="relative">
                    <CircularProgress
                      size={25}
                      style={{ position: "absolute", left: "50%" }}
                    />
                    Renotifying
                  </Box>
                ) : (
                  "Renotify Workers"
                )}
              </Button>
            </Box>
          </Box>
        </>
      )}
    </Dialog>
  );
}
