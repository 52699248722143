import { Appointments } from "@devexpress/dx-react-scheduler-material-ui";
//import { Appointments } from '@devexpress/dx-react-scheduler';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
// import EngineeringIcon from '@mui/icons-material/Engineering';
import React from "react";

export default function AppointmentContent({ children, style, ...restProps }:any) {
	//  const classes = useStyles();
	const data = restProps.data;

	return (
		<Appointments.AppointmentContent {...restProps}>
			<Box display='flex' flexDirection='column' alignItems='center' width={1}>
				<Box mx='auto' mt={1} width={0.9} display='block'>
					<Box display='flex' justifyContent='center' alignItems='center'>
						{/* <EngineeringIcon fontSize="small" style={{ whiteSpace: "normal",color:"black" }} /> */}
						<Box ml={0.4}>
							<Typography
								// align="center"
								display='block'
								style={{ whiteSpace: "normal", color: "black" }}
							>
								{data.numberOfWorkers}
							</Typography>
						</Box>
					</Box>
				</Box>
			</Box>
		</Appointments.AppointmentContent>
	);
}
