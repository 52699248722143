import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import DirectionsIcon from "@mui/icons-material/Directions";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import { Box } from "@mui/material";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import * as React from "react";
import { useDebounce } from "use-debounce";

interface SearchbarProps {
	startIcon?: React.ReactNode;
	width?: string;
	placeholder?: string;
	variant?: "filled" | "outlined" | "standard";
	value: string | undefined;
	setSearch: undefined | ((value: string) => void);
	debounce?: boolean;
	debTime?: number;
	label?: boolean;
	hiddenLabel?: boolean;
}

const SectionSearch: React.FC<SearchbarProps> = ({
	startIcon,
	width,
	placeholder = "Search",
	variant = "filled",
	value,
	setSearch,
	debounce = false,
	debTime = 500,
	label = true,
	hiddenLabel = false,
	...props
}) => {
	const [displayText, setDisplayText] = React.useState(value ?? "");
	const [debouncedText] = useDebounce(displayText, debounce ? debTime : 0);

	React.useEffect(() => {
		setSearch && setSearch(debouncedText);
	}, [debouncedText, setSearch]);

	return (
		<Box
			//component='form'
			sx={{
				p: "2px 4px",
				display: "flex",
				alignItems: "center",
				width: 400,
				height: 40,
				backgroundColor: "#f4f4f4",
				borderRadius: "4px",
			}}
		>
			<IconButton sx={{ p: "10px" }} aria-label='menu'>
				<SearchIcon />
			</IconButton>
			<InputBase
				sx={{ flex: 1 }}
				value={displayText}
				placeholder={placeholder}
				inputProps={{ "aria-label": "search google maps" }}
				onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
					setDisplayText(e.target.value);
				}}
			/>
			{displayText !== undefined && displayText.length > 0 && (
				<IconButton
					type='button'
					sx={{ p: "10px" }}
					aria-label='search'
					onClick={() => setDisplayText("")}
				>
					<CancelOutlinedIcon sx={{ cursor: "pointer", color: "#92918f" }} />
				</IconButton>
			)}
		</Box>
	);
};

export default SectionSearch;
