import { useSelector } from "react-redux";

// import { GATAdminState } from "../store/gat-admin-slice-types";

const useGatAdminConfigData = () => {
	const companyDataValue = useSelector(
		(state: any) => state.gatAdminConfiguration.companyData
	);
	const companyLocationClustersValue = useSelector(
		(state: any) => state.gatAdminConfiguration.companyLocationClusters
	);
	const companySettingsValue = useSelector(
		(state: any) => state.gatAdminConfiguration.companySettings
	);
	const getCompanyLocationClustersStatus = useSelector(
		(state: any) =>
			state.gatAdminConfiguration.getCompanyLocationClusters.status
	);
	const locations = useSelector(
		(state: any) => state.gatAdminConfiguration.locations
	);

	const companySettingsObj = useSelector(
		(state: any) => state.gatAdminConfiguration.companySettings
	);

	const getCompanySettingsStatus = useSelector(
		(state: any) => state.gatAdminConfiguration.getCompanySettings.status
	);
	const getCompanySettingsErrorMessage = useSelector(
		(state: any) => state.gatAdminConfiguration.getCompanySettings.errorMessage
	);

	const getCompanyDataStatus = useSelector(
		(state: any) => state.gatAdminConfiguration.getCompanyData.status
	);
	const getCompanyDataErrorMessage = useSelector(
		(state: any) => state.gatAdminConfiguration.getCompanyData.errorMessage
	);

	const updateCompanyDataStatus = useSelector(
		(state: any) => state.gatAdminConfiguration.updateCompanyData.status
	);
	const updateCompanyDataErrorMessage = useSelector(
		(state: any) => state.gatAdminConfiguration.updateCompanyData.errorMessage
	);

	const updateCompanySettingsStatus = useSelector(
		(state: any) => state.gatAdminConfiguration.updateCompanySettings.status
	);
	const updateCompanySettingsErrorMessage = useSelector(
		(state: any) =>
			state.gatAdminConfiguration.updateCompanySettings.errorMessage
	);

	const updateCompanySettingsLevelStatus = useSelector(
		(state: any) =>
			state.gatAdminConfiguration.updateCompanySettingsLevel.status
	);
	const updateCompanySettingsLevelErrorMessage = useSelector(
		(state: any) =>
			state.gatAdminConfiguration.updateCompanySettingsLevel.errorMessage
	);

	const createNewCompanyStatus = useSelector(
		(state: any) => state.gatAdminConfiguration.createNewCompany.status
	);
	const createNewCompanyErrorMessage = useSelector(
		(state: any) => state.gatAdminConfiguration.createNewCompany.errorMessage
	);

	const createCompanyUserStatus = useSelector(
		(state: any) => state.gatAdminConfiguration.createCompanyUser.status
	);
	const createCompanyUserErrorMessage = useSelector(
		(state: any) => state.gatAdminConfiguration.createCompanyUser.errorMessage
	);
	const updateCompanyUserStatus = useSelector(
		(state: any) => state.gatAdminConfiguration.updateCompanyUser.status
	);
	const updateCompanyUserErrorMessage = useSelector(
		(state: any) => state.gatAdminConfiguration.updateCompanyUser.errorMessage
	);

	const updateLocationStatus = useSelector(
		(state: any) => state.gatAdminConfiguration.updateLocationData.status
	);
	const updateLocationErrorMessage = useSelector(
		(state: any) => state.gatAdminConfiguration.updateLocationData.errorMessage
	);

	const createNewLocationStatus = useSelector(
		(state: any) => state.gatAdminConfiguration.createNewLocation.status
	);
	const createNewLocationErrorMessage = useSelector(
		(state: any) => state.gatAdminConfiguration.createNewLocation.errorMessage
	);

	const newLocationId = useSelector(
		(state: any) => state.gatAdminConfiguration.createNewLocation.locationId
	);

	const createNewWorkCenterStatus = useSelector(
		(state: any) => state.gatAdminConfiguration.createNewWorkCenter.status
	);
	const updateWorkCenterStatus = useSelector(
		(state: any) => state.gatAdminConfiguration.updateWorkCenterData.status
	);
	const createNewWorkCenterErrorMessage = useSelector(
		(state: any) => state.gatAdminConfiguration.createNewWorkCenter.errorMessage
	);
	const updateWorkCenterErrorMessage = useSelector(
		(state: any) =>
			state.gatAdminConfiguration.updateWorkCenterData.errorMessage
	);

	const updateLocationDataStatus = useSelector(
		(state: any) => state.gatAdminConfiguration.updateLocationData.status
	);
	const openLocationDialog = useSelector(
		(state: any) => state.gatAdminConfiguration.openLocationDialog
	);

	const allocationTypes = useSelector(
		(state: any) =>
			state.gatAdminConfiguration.getAllShiftAllocationRules.allocationTypes
	);

	const locationSettingsObj = useSelector(
		(state: any) => state.gatAdminConfiguration.locationSettings
	);

	const getLocationsStatus = useSelector(
		(state: any) => state.gatAdminConfiguration.getLocations.status
	);
	const getLocationsErrorMessage = useSelector(
		(state: any) => state.gatAdminConfiguration.getLocations.errorMessage
	);

	const creatingNewCompany = useSelector(
		(state: any) => state.gatAdminConfiguration.creatingNewCompany
	);

	const creatingNewLocation = useSelector(
		(state: any) => state.gatAdminConfiguration.creatingNewLocation
	);

	const newCompanyId = useSelector(
		(state: any) => state.gatAdminConfiguration.createNewCompany.newCompanyId
	);

	const timeZones = useSelector(
		(state: any) => state.gatAdminConfiguration.timeZones
	);

	const currentLocationData = useSelector(
		(state: any) => state.gatAdminConfiguration.currentLocationData
	);

	const currentCompanyData = useSelector(
		(state: any) => state.gatAdminConfiguration.currentCompanyData
	);

	const allSettings = useSelector(
		(state: any) => state.gatAdminConfiguration.allSettingTypes
	);

	const shiftAllocationTypes = useSelector(
		(state: any) => state.gatAdminConfiguration.getAllShiftAllocationRules
	);

	const locationsShiftRules = useSelector(
		(state: any) => state.gatAdminConfiguration.locations
	);

	const allJobsByLocation = useSelector(
		(state: any) => state.gatAdminConfiguration.getAllJobsByLocation.jobs
	);

	const allJobsByLocationObject = useSelector(
		(state: any) => state.gatAdminConfiguration.getAllJobsByLocation.jobsObject
	);

	const createNewCompanyUserStatus = useSelector(
		(state: any) => state.gatAdminConfiguration.createCompanyUser.status
	);

	const openCompanyUserDialog = useSelector(
		(state: any) => state.gatAdminConfiguration.openCompanyUserDialog
	);
	const allCompanyUserRoles = useSelector(
		(state: any) => state.gatAdminConfiguration.allCompanyUserRoles
	);
	const allCompanyWorkCenters = useSelector(
		(state: any) => state.gatAdminConfiguration.allCompanyWorkCenters
	);

	const workerNotifyCats = useSelector(
		(state: any) => state.gatAdminConfiguration.getWorkerNotificationTypes.data
	);

	const getLocationSettingsStatus = useSelector(
		(state: any) => state.gatAdminConfiguration.getLocationSettings.status
	);

	const locationId = useSelector(
		(state: any) => state.gatAdminConfiguration.currentLocationData.id
	);

	const getLeaveTypesStatus = useSelector(
		(state: any) => state.gatAdminConfiguration.getLeaveTypes.status
	);

	const getLeaveTypesErrorMessage = useSelector(
		(state: any) => state.gatAdminConfiguration.getLeaveTypes.errorMessage
	);

	const getLeaveTypesData = useSelector(
		(state: any) => state.gatAdminConfiguration.getLeaveTypes.data
	);

	const createTimeOffTypeStatus = useSelector(
		(state: any) => state.gatAdminConfiguration.createTimeOffType.status
	);

	const createTimeOffTypeErrorMessage = useSelector(
		(state: any) => state.gatAdminConfiguration.createTimeOffType.errorMessage
	);

	const editTimeOffTypeStatus = useSelector(
		(state: any) => state.gatAdminConfiguration.editTimeOffType.status
	);

	const editTimeOffTypeErrorMessage = useSelector(
		(state: any) => state.gatAdminConfiguration.editTimeOffType.errorMessage
	);

	const saveSkillPrioritiesStatus = useSelector(
		(state: any) => state.gatAdminConfiguration.saveSkillPriorities.status
	);

	const saveSkillPrioritiesErrorMessage = useSelector(
		(state: any) => state.gatAdminConfiguration.saveSkillPriorities.errorMessage
	);

	const deleteTimeOffTypeStatus = useSelector(
		(state: any) => state.gatAdminConfiguration.deleteTimeOffType.status
	);

	const deleteTimeOffTypeErrorMessage = useSelector(
		(state: any) => state.gatAdminConfiguration.deleteTimeOffType.errorMessage
	);

	const companyNotAssignedSkillLevel = useSelector(
		(state: any) => state.gatAdminConfiguration.companyNotAssignedSkillLevel
	);

	return {
		locationId,
		getLocationSettingsStatus,
		shiftAllocationTypes,
		locationsShiftRules,
		allJobsByLocation,
		allJobsByLocationObject,
		allSettings,
		creatingNewCompany,
		creatingNewLocation,
		companySettingsObj,
		companyDataValue,
		companyLocationClustersValue,
		companySettingsValue,
		locations,
		allCompanyWorkCenters,
		getCompanySettingsStatus,
		getCompanySettingsErrorMessage,
		getCompanyDataStatus,
		getCompanyDataErrorMessage,
		updateCompanyDataStatus,
		updateCompanyDataErrorMessage,
		updateCompanySettingsStatus,
		updateCompanySettingsErrorMessage,
		createNewCompanyStatus,
		createNewCompanyErrorMessage,
		createCompanyUserStatus,
		createCompanyUserErrorMessage,
		updateCompanyUserStatus,
		updateCompanyUserErrorMessage,
		updateLocationStatus,
		updateLocationErrorMessage,
		createNewLocationStatus,
		createNewLocationErrorMessage,
		createNewWorkCenterStatus,
		createNewWorkCenterErrorMessage,
		updateWorkCenterStatus,
		updateWorkCenterErrorMessage,
		updateLocationDataStatus,
		openLocationDialog,
		allocationTypes,
		locationSettingsObj,
		getLocationsStatus,
		getLocationsErrorMessage,
		updateCompanySettingsLevelStatus,
		updateCompanySettingsLevelErrorMessage,
		timeZones,
		newLocationId,
		newCompanyId,
		currentLocationData,
		getCompanyLocationClustersStatus,
		currentCompanyData,
		workerNotifyCats,
		createNewCompanyUserStatus,
		openCompanyUserDialog,
		allCompanyUserRoles,
		getLeaveTypesStatus,
		getLeaveTypesErrorMessage,
		getLeaveTypesData,
		createTimeOffTypeStatus,
		createTimeOffTypeErrorMessage,
		editTimeOffTypeStatus,
		editTimeOffTypeErrorMessage,
		saveSkillPrioritiesStatus,
		saveSkillPrioritiesErrorMessage,
		deleteTimeOffTypeStatus,
		deleteTimeOffTypeErrorMessage,
		companyNotAssignedSkillLevel,
	};
};

export default useGatAdminConfigData;
