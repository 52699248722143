import { Add, Remove } from "@mui/icons-material";
import { Alert, Box, Stack } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro-6";
import { AdapterDayjs } from "@mui/x-date-pickers-pro-6/AdapterDayjs";
import { addHours } from "date-fns";
import addDays from "date-fns/addDays";
import format from "date-fns/format";
import subDays from "date-fns/subDays";
import dayjs from "dayjs";
import { Field } from "formik";
import { isNumber } from "lodash";
import React, { useEffect } from "react";

import {
	MAXIMUM_SHIFT_PATTERN_DAYS,
	MINIMUM_SHIFT_PATTERN_DAYS,
} from "../../../teams/constants";
import useTeamsData from "../../controllers/use-teams-data";
import { Schedule, ShiftPatternProps } from "../../types";
import LegendsContainer from "../legends-container";
import ShiftPatternDayCellGroup from "./shift-pattern-day-cell-group";
const ShiftPattern = (props: ShiftPatternProps) => {
	const { allShiftSchedules } = useTeamsData();

	const { values, setFieldValue, errors, setScheduleEnabledAndEmpty, isEdit, touched, setFieldTouched } =
		props;

	useEffect(() => {
		try {
			if (values.isShiftPattern) {
				if (values.schedule.length > 0) {
					const isNotEmpty = values.schedule.find(
						(s: Schedule) => s.shiftTypeId !== null
					);
					if (isNotEmpty) {
						setScheduleEnabledAndEmpty(false);
					} else {
						setScheduleEnabledAndEmpty(true);
					}
				}
			} else {
				setScheduleEnabledAndEmpty(false);
			}
		} catch (e) {
			console.log("Error", e);
			// Do nothing
		}
	}, [setScheduleEnabledAndEmpty, values]);
	return (
		<Box>
			{allShiftSchedules.length === 0 && (
				<Box width={856} mb={2} mt={-1}>
					<Alert severity='error'>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontSize: "14px",
								fontWeight: 400,
								lineHeight: "20px",
								letterSpacing: "0.25px",
								color: "#541313",
							}}
						>
							Shift pattern cannot be created as no shift schedule is created.
							Create one shift schedule (under shift settings) and continue
							ahead from here.
						</Typography>
					</Alert>
				</Box>
			)}

			<>
				<Box display='flex'>
					<Box width={420} mr={2}>
						<Field
							component={TextField}
							name='name'
							label='Shift pattern name'
							type='text'
							variant='filled'
							fullWidth
							value={values.name}
							onChange={(e: { target: { value: string } }) =>
								setFieldValue("name", e.target.value)
							}
							onBlur={() => setFieldTouched("name", true)}
							error={!!errors.name && touched.name}
							helperText={touched.name && errors.name}
							
						/>
					</Box>
					<Box width={420}>
						<Field
							component={TextField}
							name='numberOfDays'
							id='number-of-days'
							label='Number of days'
							type='number'
							value={values.numberOfDays}
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							disabled={values.isWorkerAssigned}
							InputProps={{
								endAdornment: (
									<Stack direction='row' alignItems='center'>
										<IconButton
											onClick={() => {
												const newValue = parseInt(values.numberOfDays) - 1;
												if (
													newValue <= MAXIMUM_SHIFT_PATTERN_DAYS &&
													newValue >= MINIMUM_SHIFT_PATTERN_DAYS
												) {
													setFieldValue("numberOfDays", newValue);
												}
											}}
											title='Clear'
											disabled={values.isWorkerAssigned}
										>
											<Remove />
										</IconButton>
										<Typography
											sx={{
												color: "rgba(0,0,0,0.2)",
												fontSize: "24px",
												fontWeight: "200",
											}}
										>
											|
										</Typography>
										<IconButton
											onClick={() => {
												const newValue = parseInt(values.numberOfDays) + 1;
												if (
													newValue <= MAXIMUM_SHIFT_PATTERN_DAYS &&
													newValue >= MINIMUM_SHIFT_PATTERN_DAYS
												) {
													setFieldValue("numberOfDays", newValue);
												}
											}}
											title='Clear'
											disabled={values.isWorkerAssigned}
										>
											<Add />
										</IconButton>
									</Stack>
								),
							}}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								if (e.target.value) {
									if (
										parseInt(e.target.value) <= MAXIMUM_SHIFT_PATTERN_DAYS &&
										parseInt(e.target.value) >= MINIMUM_SHIFT_PATTERN_DAYS
									) {
										setFieldValue("numberOfDays", parseInt(e.target.value));
									}
								}
							}}
							onFocus={(event: { target: { select: () => void } }) => {
								// This is required as user won't be able to edit single digit
								event.target.select();
							}}
							variant='filled'
							error={!!errors.numberOfDays}
							helperText={errors.numberOfDays}
						/>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontSize: "12px",
								fontWeight: 400,
								color: "rgba(0, 0, 0, 0.6)",
							}}
						>
							Number of days after which the pattern repeats again.
						</Typography>
					</Box>
				</Box>
				<Box width={860} mb={4} mt={2} display='flex'>
					<Box width={420} mr={2}>
						{/* <Field
							variant='filled'
							component={TextField}
							name='startDate'
							id='startDate'
							type='date'
							label='Start Date'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							value={
								values.startDate
									? format(new Date(values.startDate), "yyyy-MM-dd")
									: null
							}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								const date = e.target.value + "T00:00:00";
								if (e.target.value === "") {
									setFieldValue("startDate", null);
								} else if (
									new Date(date).getTime() >=
									new Date(addDays(new Date(), 1)).getTime()
								) {
									setFieldValue("startDate", new Date(date));
									if (values.endDate) {
										if (new Date(date).getTime() >= values.endDate.getTime()) {
											setFieldValue(
												"endDate",
												new Date(addDays(new Date(date), values.numberOfDays))
											);
										}
									}
								} else {
									setFieldValue("startDate", new Date(date));
								}
							}}
							error={!!errors.startDate}
							helperText={errors.startDate}
						/> */}
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DatePicker
								value={values.startDate ? dayjs(values.startDate) : null}
								onChange={(newValue) => {
									if (newValue === null) {
										setFieldValue("startDate", null);
										return;
									}
									if (!dayjs(newValue).isValid()) {
										return;
									}
									const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
									const date = formattedDate + "T00:00:00";
									if (
										new Date(date).getTime() >=
										new Date(addDays(new Date(), 1)).getTime()
									) {
										setFieldValue("startDate", new Date(date));
										if (values.endDate) {
											if (
												new Date(date).getTime() >= values.endDate.getTime()
											) {
												setFieldValue(
													"endDate",
													new Date(addDays(new Date(date), values.numberOfDays))
												);
											}
										}
									} else {
										setFieldValue("startDate", new Date(date));
									}
								}}
								name='startDate'
								slotProps={{
									textField: {
										variant: "filled",
										error: !!errors.startDate,
										helperText: errors.startDate ? errors.startDate : "",
										InputLabelProps: {
											shrink: true,
										},
									},
								}}
								format={"MM/DD/YYYY"}
								sx={{ width: "100%" }}
								label='Start Date'
								disabled={values.isWorkerAssigned}
							/>
						</LocalizationProvider>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontSize: "12px",
								fontWeight: 400,
								color: "rgba(0, 0, 0, 0.6)",
							}}
						>
							Start date for the repeating shift pattern.
						</Typography>
					</Box>
					<Box width={420}>
						{
							/* <Field
							variant='filled'
							component={TextField}
							disabled={!isNumber(values.numberOfDays)}
							name='endDate'
							id='endDate'
							type='date'
							label='End Date (optional)'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							value={
								values.endDate && values.endDate !== ""
									? format(values.endDate, "yyyy-MM-dd")
									: null
							}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								const date = e.target.value + "T00:00:00";
								if (e.target.value !== "") {
									if (
										new Date(
											subDays(new Date(date), values.numberOfDays)
										).getTime() > values.startDate.getTime()
									) {
										setFieldValue("endDate", new Date(date));
									} else {
										if (values.startDate && values.numberOfDays > 0) {
											setFieldValue(
												"endDate",
												new Date(addDays(values.startDate, values.numberOfDays))
											);
										} else {
											setFieldValue("endDate", null);
										}
									}
								} else {
									setFieldValue("endDate", null);
								}
							}}
							error={!!errors.endDate}
							helperText={errors.endDate}
						/> */
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
									value={
										values.endDate && values.endDate !== ""
											? dayjs(values.endDate)
											: null
									}
									onChange={(newValue) => {
										if (newValue === null) {
											setFieldValue("endDate", null);
											return;
										}
										if (!dayjs(newValue).isValid()) {
											return;
										}
										const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
										const date = formattedDate + "T00:00:00";
										if (formattedDate !== "") {
											setFieldValue("endDate", new Date(date));
											// if (
											// 	new Date(
											// 		subDays(new Date(date), values.numberOfDays)
											// 	).getTime() > values.startDate.getTime()
											// ) {
											// 	setFieldValue("endDate", new Date(date));
											// } else {
											// 	if (values.startDate && values.numberOfDays > 0) {
											// 		setFieldValue(
											// 			"endDate",
											// 			new Date(addDays(values.startDate, values.numberOfDays))
											// 		);
											// 	} else {
											// 		setFieldValue("endDate", null);
											// 	}
											// }
										} else {
											setFieldValue("endDate", null);
										}
									}}
									name='endDate'
									slotProps={{
										textField: {
											variant: "filled",
											error: !!errors.endDate,
											helperText: errors.endDate ? errors.endDate : "",
											InputLabelProps: {
												shrink: true,
											},
										},
									}}
									format={"MM/DD/YYYY"}
									sx={{ width: "100%" }}
									label='End Date (optional)'
									disabled={!isNumber(values.numberOfDays)}
								/>
							</LocalizationProvider>
						}
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontSize: "12px",
								fontWeight: 400,
								color: "rgba(0, 0, 0, 0.6)",
							}}
						>
							End date for the repeating shift pattern.
						</Typography>
					</Box>
				</Box>
				<ShiftPatternDayCellGroup
					values={values}
					setFieldValue={setFieldValue}
					errors={errors}
					setScheduleEnabledAndEmpty={setScheduleEnabledAndEmpty}
				/>
				<LegendsContainer values={values} />
			</>
		</Box>
	);
};

export default ShiftPattern;
