import { useEffect, useState } from "react";

import useLocationData from "./use-location-data";

interface useLocationControllerProps {
    searchText: string;
}

const useLocationController = ({ searchText }: useLocationControllerProps) => {
    const { locations } = useLocationData();
    const [filteredLocations, setFilteredLocations] = useState(locations ?? []);

    useEffect(() => {
        setFilteredLocations(locations);
        if (searchText) {
            const filteredLocations = locations?.filter((location: any) => {

                const workcentersAccess = location.workCenters.some((workcenter: any) =>
                    workcenter.name.toLowerCase().includes(searchText.toLowerCase())
                );
                const status = location.isActive ? "active".includes(searchText.toLowerCase()) : "inactive".includes(searchText.toLowerCase())
                const noOfWorkers = location.numOfWorkers.toString().includes(searchText.toLowerCase())
                const contactForWorkers = location.contactForWorkers?.includes(searchText.toLowerCase())
                const address = location.address1?.toLowerCase().includes(searchText.toLowerCase()) ||
                    location.address2?.toLowerCase().includes(searchText.toLowerCase()) ||
                    location.city?.toLowerCase().includes(searchText.toLowerCase()) ||
                    location.state?.toLowerCase().includes(searchText.toLowerCase()) ||
                    location.zip?.toLowerCase().includes(searchText.toLowerCase()) ||
                    location.country?.toLowerCase().includes(searchText.toLowerCase())

                return location?.name?.toLowerCase().includes(searchText.toLowerCase()) ||
                    status ||
                    noOfWorkers ||
                    contactForWorkers ||
                    workcentersAccess ||
                    address;
            });
            setFilteredLocations(filteredLocations);
        }
    }, [locations, searchText])


    return {
        filteredLocations,
    }
}

export default useLocationController;
