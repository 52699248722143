// TODO - Type 'any' needs to be fixed.
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { fetchWorkerProfile } from "../../../../../../export/workers";
import useWorkersData from "../../../controllers/use-workers-data";

const useAddSkillDialog = (closeDialog: (() => void) | undefined) => {
	const dispatch = useDispatch();
	const {
		allSkillLevels,
		allJobsObj,
		workerJobs,
		workerOtherSkills,
		workerId,
		addWorkerSkillStatus,
		obsoleteSkills,
	} = useWorkersData();

	const [newAssignedSkills, setNewAssignedSkills] = React.useState<any[]>([]);

	const handleNewAssignedSkills = (skillId: number, level: number | string) => {
		const newAssignedSkillsCopy = [...newAssignedSkills];
		const index = newAssignedSkillsCopy.findIndex(
			(skill) => skill.skillId === skillId
		);

		if (level === "") {
			if (index !== -1) {
				newAssignedSkillsCopy.splice(index, 1);
				setNewAssignedSkills(newAssignedSkillsCopy);
			}
		} else {
			const newSkill = { skillId, levelId: level, workerId: workerId };

			if (index !== -1) {
				newAssignedSkillsCopy[index].levelId = level;
				setNewAssignedSkills(newAssignedSkillsCopy);
			} else {
				setNewAssignedSkills([...newAssignedSkills, newSkill]);
			}
		}
	};

	const removeMultipleSkills = (skillIds: number[]) => {
		const newAssignedSkillsCopy = newAssignedSkills.filter(
			(skill) => !skillIds.includes(skill.skillId)
		);
		setNewAssignedSkills(newAssignedSkillsCopy);
	};

	const workerAssignedSkills = workerJobs.reduce((acc: any, job: any) => {
		job.skills.forEach((skill: any) => {
			if (acc.includes(skill.skillId)) {
				return;
			}
			acc.push(skill.skillId);
		});
		workerOtherSkills.forEach((skill: any) => {
			if (acc.includes(skill.skillId)) {
				return;
			}
			acc.push(skill.skillId);
		});
		return acc;
	}, []);

	const skillsMappedWithJob = workerJobs.reduce((acc: any, job: any) => {
		const jobSkills = allJobsObj[job.jobId]?.skills || [];
		if (
			jobSkills.length === 0 ||
			(job.skills.length > 0 && job.skills.length === jobSkills.length)
		) {
			return acc;
		}
		allJobsObj[job.jobId].skills.forEach((skill: any) => {
			if (!acc.includes(skill.skillId)) {
				const filteredSkills = jobSkills
					.filter(
						(jobSkill: any) =>
							!workerAssignedSkills.includes(jobSkill.skillId) &&
							!acc.includes(jobSkill.skillId)
					)
					.map((jobSkill: any) => jobSkill.skillId);
				acc.push(...filteredSkills);
			}
		});
		return acc;
	}, []);

	const workerJobWithUnassignedSkills = workerJobs.filter((job: any) => {
		if (
			allJobsObj[job.jobId].skills.length === 0 ||
			job.skills.length === allJobsObj[job.jobId].skills.length
		) {
			return false;
		}
		return true;
	});

	useEffect(() => {
		if (addWorkerSkillStatus === "fulfilled" && closeDialog && workerId) {
			dispatch(fetchWorkerProfile(workerId));
			closeDialog();
		}
	}, [addWorkerSkillStatus, closeDialog, dispatch, workerId]);

	return [
		{
			allSkillLevels,
			allJobsObj,
			workerJobs,
			workerOtherSkills,
			workerAssignedSkills,
			newAssignedSkills,
			addWorkerSkillStatus,
			skillsMappedWithJob,
			obsoleteSkills,
			workerJobWithUnassignedSkills,
		},
		{ handleNewAssignedSkills, removeMultipleSkills },
	];
};

export default useAddSkillDialog;
