// TODO - Type 'any' needs to be fixed.
import { Box, Switch, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro-6";
import { AdapterDayjs } from "@mui/x-date-pickers-pro-6/AdapterDayjs";
import { format, isFuture } from "date-fns";
import dayjs from "dayjs";
import React from "react";

import FileUpload from "./file-upload";

type ValidityPeriodUnit = "d" | "m" | "y";

const validityPeriodUnits: Record<ValidityPeriodUnit, any> = {
	d: "day",
	m: "month",
	y: "year",
};

const formatDate = (value: any) => {
	return format(new Date(value.$d), "yyyy-MM-dd");
};

const EditCertificationForm = ({
	values,
	setFieldValue,
	files,
	setFiles,
	setFilesToDelete,
	fileUploadErrors,
	errors,
	validateForm,
}: any) => {
	const handleValidFromChange = async(newValue: any) => {
		if(!newValue) {
			setFieldValue("validFrom", null);
			if(values.validityPeriod && values.validityPeriodUnit) {
				setFieldValue("validTo", null);
				await validateForm();
			}
			return;
		}

		if (!dayjs(newValue).isValid()) {
			return;
		}

		const formattedDate = formatDate(newValue);
		setFieldValue("validFrom", formattedDate);

		if (values.validityPeriod && values.validityPeriodUnit) {
			const unit = values.validityPeriodUnit as ValidityPeriodUnit;
			if (unit in validityPeriodUnits) {
				const validToDate = dayjs(newValue).add(
					values.validityPeriod,
					validityPeriodUnits[unit]
				);
				setFieldValue("validTo", formatDate(validToDate));
			}
			await validateForm();
		}
	};

	const handleValidToChange = (newValue: any) => {
		if(!newValue) {
			setFieldValue("validTo", null);
			return;
		}
		if (!dayjs(newValue).isValid()) {
			return;
		}
		const formattedDate = format(new Date(newValue.$d), "yyyy-MM-dd");
		setFieldValue("validTo", formattedDate);
	};

	const getStatus = () => {
		if (!values.validFrom || isFuture(new Date(values.validFrom))) {
			return "-";
		} else if (!values.validTo || isFuture(new Date(values.validTo))) {
			return "Active";
		} else {
			return "Expired";
		}
	};

	return (
		<Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					gap: 1,
					mb: 1,
				}}
			>
				<Box>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontSize: "12px",
							fontWeight: 400,
							lineHeight: "12px",
							letterSpacing: "0.15px",
							color: "#00000099",
						}}
					>
						Certification Name
					</Typography>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontSize: "16px",
							fontWeight: 400,
							lineHeight: "28px",
							letterSpacing: "0.15px",
							pl: 0.2,
						}}
					>
						{values.certName}
					</Typography>
				</Box>
				<Box>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontSize: "12px",
							fontWeight: 400,
							lineHeight: "12px",
							letterSpacing: "0.15px",
							color: "#00000099",
						}}
					>
						Status
					</Typography>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontSize: "16px",
							fontWeight: 400,
							lineHeight: "28px",
							letterSpacing: "0.15px",
							pl: 0.2,
						}}
					>
						{getStatus()}
					</Typography>
				</Box>
				<Box>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontSize: "12px",
							fontWeight: 400,
							lineHeight: "12px",
							letterSpacing: "0.15px",
							color: "#00000099",
						}}
					>
						Validity Period
					</Typography>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontSize: "16px",
							fontWeight: 400,
							lineHeight: "28px",
							letterSpacing: "0.15px",
							pl: 0.2,
						}}
					>
						{values.validityPeriod && values.validityPeriodUnit
							? `valid for ${values.validityPeriod} ${
									validityPeriodUnits[
										values.validityPeriodUnit as ValidityPeriodUnit
									]
								}(s)`
							: "-"}
					</Typography>
				</Box>
			</Box>
			<Box
				sx={{
					display: "grid",
					gridTemplateColumns: "repeat(2, 1fr)",
					gap: 2,
					mb: 3,
				}}
			>
				<Box>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker
							value={
								values.validFrom ? dayjs(values.validFrom, "YYYY-MM-DD") : null
							}
							onChange={(newValue) => {
								handleValidFromChange(newValue);
							}}
							label='Valid From'
							slotProps={{
								field: { clearable: true },
								textField: {
									placeholder: "Select Date",
									variant: "filled",
									helperText: errors.validFrom,
									error: !!errors.validFrom,
								},
							}}
							format={"MM/DD/YYYY"}
							sx={{ width: "247px" }}
						/>
					</LocalizationProvider>
				</Box>
				<Box>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker
							value={
								values.validTo ? dayjs(values.validTo, "YYYY-MM-DD") : null
							}
							onChange={(newValue) => {
								handleValidToChange(newValue);
							}}
							label='Valid Until'
							slotProps={{
								field: { clearable: true },
								textField: {
									placeholder: "Select Date",
									variant: "filled",
									helperText: errors.validTo,
									error: !!errors.validTo,
								},
							}}
							format={"MM/DD/YYYY"}
							sx={{ width: "247px" }}
							disabled={
								values.validityPeriod && values.validityPeriodUnit
									? true
									: false
							}
						/>
					</LocalizationProvider>
				</Box>
			</Box>
			{/* <Box sx={{mb: 4}}>
				<Typography>Status: {getStatus()}</Typography>
			</Box> */}
			<Box>
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontSize: "14px",
						fontWeight: 400,
						lineHeight: "20.02px",
						letterSpacing: "0.17px",
						textAlign: "left",
						mb: "14px",
					}}
				>
					Attachments (Maximum 3 Files)
				</Typography>
				<FileUpload
					workerCertFiles={values.workerCertFiles}
					files={files}
					setFiles={setFiles}
					setFilesToDelete={setFilesToDelete}
					fileUploadErrors={fileUploadErrors}
					setFieldValue={setFieldValue}
				/>
			</Box>
		</Box>
	);
};

export default EditCertificationForm;
