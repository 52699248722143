import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import StoreBadge from "./StoreBadge";

function DeepLink() {
	const { type, id }: any = useParams();

	const [showBadge, setShowBadge] = useState(false);

	useEffect(() => {
		const path = type ? type : "Welcome";
		const pathId = id ? id : "";
		const urlPath = `wilya://${path}/${pathId}`;
		setTimeout(function () {
			setShowBadge(true);
		}, 25);
		window.location.href = urlPath;
		// window.open(urlPath, '_blank', 'noopener,noreferrer');
		// window.location.href = urlPath;
	}, [id, type]);

	return (
		<>
			{showBadge && (
				<div
					style={{
						padding: 24,
						justifyContent: "center",
						alignItems: "center",
						flex: 1,
					}}
				>
					<p>Please download Wilya app here:</p>
					<StoreBadge
						name="Wilya"
						googlePlayUrl="https://play.google.com/store/apps/details?id=com.gigandtake.workerapp"
						appStoreUrl="https://apps.apple.com/us/app/gig-and-take/id1612768820"
					/>
				</div>
			)}
		</>
	);
}

export default DeepLink;
