import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import React from "react";

interface ValidationAlertProps {
    numberOfInvalidShifts: number;
    minDurationHrs: number;
    maxDurationHrs: number;
}

const ValidationAlert: React.FC<ValidationAlertProps> = ({
	numberOfInvalidShifts,
	minDurationHrs,
	maxDurationHrs,
}) => {
	return (
		<Alert severity='warning'>
			<AlertTitle>{numberOfInvalidShifts} shifts cannnot be copied</AlertTitle>
			Your company admin has configured shift duration to a minimum of{" "}
			{minDurationHrs} hour and maximum of {maxDurationHrs} hours. Only shifts
			that comply with these rules can be copied into future weeks.
		</Alert>
	);
};

export default ValidationAlert;
