import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import React from "react";
import { useDispatch } from "react-redux";

import {
	resetCurrentLocationData,
	setCurrentLocationData,
	updateCreateNewLocation,
} from "../../../../../../export/gat-admin";
import Button from "../../../../../design/button";
import Searchbar from "../../../../../design/search-bar";

interface LocationHeaderProps {
  searchText: string;
  setSearch: any;
  companyId: any;
  MAXLOCATIONSALLOWED: any;
  numberofLocations: any;
  openLocationForm: any;
}

const LocationHeader: React.FC<LocationHeaderProps> = ({
	searchText,
	setSearch,
	companyId,
	MAXLOCATIONSALLOWED,
	numberofLocations,
	openLocationForm,
}) => {
	const dispatch = useDispatch();

	return (
		<>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					flexDirection: "row",
					alignItems: "center",
					height: "75px",
				}}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Searchbar
						variant={"filled"}
						width={"35ch"}
						setSearch={setSearch}
						value={searchText}
					/>
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Box ml={2} mr={5}>
						<Button
							iconBefore={<AddIcon />}
							disabled={numberofLocations >= MAXLOCATIONSALLOWED}
							onClick={() => {
								dispatch(updateCreateNewLocation(true));
								dispatch(resetCurrentLocationData());
								dispatch(setCurrentLocationData({ companyId: companyId }));
								openLocationForm();
							}}
						>
							Location
						</Button>
					</Box>
				</Box>
			</Box>
		</>
	);
};

export default LocationHeader;
