import { useEffect } from "react";
import React from "react";
import { Redirect, Route } from "react-router-dom";

const AuthRoute = ({
    component: Component,
    workerApp,
    authenticated,
    ...rest
}: any) => {
    // const history = useHistory()
    // console.log('IN AuthRoute: ', Component, ' workerAPp; ', workerApp, ' authenticated: ', authenticated)
    useEffect(() => {
        return () => {
            if (localStorage.getItem("login-redirect")) {
                // console.log('removing login-redirect')
                localStorage.removeItem("login-redirect");
            }
        };
    }, []);

    const loginRedirect = localStorage.getItem("login-redirect") || "/";
    return (
        <Route
            {...rest}
            render={(props) =>
                workerApp ? (
                    authenticated ? (
                        <Redirect to='/w' />
                    ) : (
                        <Component {...props} />
                    )
                ) : authenticated === true ? (
                    <Redirect to={loginRedirect} />
                ) : (
                    <Component {...props} />
                )
            }
        />
    );
};

export default AuthRoute;
