import { useSelector } from "react-redux";

const useCrossSkillData = () => {
  const allWorkers = useSelector((state) => state.manageWorkers.allWorkers);
  const allOrganizations = useSelector(
    (state) => state.manageWorkers.skillMatrixFiltersOrganizations
  );
  const allOrganizationTypes = useSelector(
    (state) => state.manageWorkers.skillMatrixFiltersOrgTypes
  );
  const allManagers = useSelector(
    (state) => state.manageWorkers.skillMatrixFiltersManagers
  );
  const skillPriorities = useSelector(
    (state) => state.manageWorkers.skillMatrixFiltersSkillPriorities
  );
  const isSkillPriorityEnabled = useSelector(
    (state) => state.user.userData.skillPriorityEnabled
  );
  const fetchAllManagersStatus = useSelector(
    (state) => state.manageWorkers.fetchAllManagers.status
  );
  const workerTypes = useSelector(
    (state) => state.manageWorkers.skillMatrixFiltersWorkerTypes
  );
  const fetchAllOrganizationsStatus = useSelector(
    (state) => state.manageWorkers.fetchAllOrganizations.status
  );
  const allJobRoles = useSelector(
    (state) => state.manageWorkers.skillMatrixFiltersJobRoles
  );
  const fetchManageWorkersDataStatus = useSelector(
    (state) => state.manageWorkers.fetchManageWorkersData.status
  );
  const allSkills = useSelector(
    (state) => state.manageWorkers.skillMatrixFiltersSkills
  );
  const fetchJobsByUserIdStatus = useSelector(
    (state) => state.manageJobs.fetchJobsByUserId.status
  );
  const allSkillLevels = useSelector(
    (state) => state.manageWorkers.skillMatrixFiltersSkillLevels
  );
  const fetchSkillLevelsStatus = useSelector(
    (state) => state.manageWorkers.fetchSkillLevels.status
  );
  const allJobs = useSelector(
    (state) => state.manageWorkers.skillMatrixFiltersAllJobs
  );
  const skills = useSelector((state) => state.manageJobs.allSkills);
  const addSkillInterestStatus = useSelector(
    (state) => state.manageWorkers.addSkillInterested.status
  );
  const allLocations = useSelector(
    (state) => state.manageWorkers.skillMatrixFiltersLocations
  );
  const fetchAllLocationsStatus = useSelector(
    (state) => state.supervisor.fetchAllLocations.status
  );
  const companyId = useSelector((state) => state.user.userData.companyId);
  const rejectSkillInterestStatus = useSelector(
    (state) => state.manageWorkers.rejectSkillInterested.status
  );
  const allWorkCenters = useSelector(
    (state) => state.manageWorkers.skillMatrixFiltersWorkCenters
  );
  const showCrossSkilling = useSelector(
    (state) => state.user.userData.showCrossSkilling
  );

  const updateWorkerSkillLevelStatus = useSelector(
    (state) => state.manageWorkers.updateSkillLevel.status
  );

  const updateWorkerSkillLevelError = useSelector(
    (state) => state.manageWorkers.updateSkillLevel.error
  );

  const fullTimeSchedule = useSelector(
    (state) => state.user.userData.fullTimeSchedule
  );

  const skillMatrixStatus = useSelector(
    (state) => state.manageWorkers.fetchSkillMatrix.status
  );

  const skillMatrixErrorMessage = useSelector(
    (state) => state.manageWorkers.fetchSkillMatrix.errorMessage
  );

  const skillMatrixData = useSelector(
    (state) => state.manageWorkers.skillMatrixData
  );

  const skillMatrixFiltersStatus = useSelector(
    (state) => state.manageWorkers.fetchSkillMatrixFilters.status
  );

  const skillMatrixFiltersErrorMessage = useSelector(
    (state) => state.manageWorkers.fetchSkillMatrixFilters.errorMessage
  );

  const skillMatrixDefaultFiltersStatus = useSelector(
    (state) => state.manageWorkers.fetchSkillMatrixDefaultFilters.status
  );

  const skillMatrixDefaultFiltersErrorMessage = useSelector(
    (state) => state.manageWorkers.fetchSkillMatrixDefaultFilters.errorMessage
  );

  const skillMatrixAppliedFilters = useSelector(
    (state) => state.manageWorkers.skillMatrixAppliedFilters
  );

  const fetchSkillMatrixStatus = useSelector(
    (state) => state.manageWorkers.fetchSkillMatrix.status
  );

  const skillInsightsEnabled = useSelector(
    (state) => state.user.userData.skillInsightsEnabled
  );
  const userRolesWithPermission = useSelector(
    (state) => state.user.userData.userRolesWithPermissions
  );
  const notAssignedSkillLevel = useSelector(
    (state) =>
      state.manageWorkers.fetchNotAssignedSkillLevel.NotAssignedSkillLevel
  );

  return {
    allWorkers,
    allOrganizations,
    allOrganizationTypes,
    allManagers,
    workerTypes,
    fetchAllOrganizationsStatus,
    allJobRoles,
    fetchManageWorkersDataStatus,
    fetchJobsByUserIdStatus,
    allSkills,
    allSkillLevels,
    fetchSkillLevelsStatus,
    addSkillInterestStatus,
    rejectSkillInterestStatus,
    allWorkCenters,
    showCrossSkilling,
    updateWorkerSkillLevelError,
    updateWorkerSkillLevelStatus,
    fetchAllManagersStatus,
    allJobs,
    notAssignedSkillLevel,
    skills,
    fullTimeSchedule,
    allLocations,
    fetchAllLocationsStatus,
    companyId,
    skillMatrixStatus,
    skillMatrixErrorMessage,
    skillMatrixData,
    skillMatrixFiltersStatus,
    skillMatrixFiltersErrorMessage,
    skillMatrixAppliedFilters,
    skillMatrixDefaultFiltersStatus,
    skillMatrixDefaultFiltersErrorMessage,
    fetchSkillMatrixStatus,
    skillInsightsEnabled,
    isSkillPriorityEnabled,
    skillPriorities,
    userRolesWithPermission,
  };
};

export default useCrossSkillData;
