/* eslint-disable no-mixed-spaces-and-tabs */
import Tabs from "@gigandtake/tabs";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { useState } from "react";
import React from "react";
import { useDispatch } from "react-redux";

import { fetchBroadcastHistory } from "../../../../../../export/scheduler";
import DialogWrapper from "../../../../../design/dialog-wrapper";
import ActionButtons from "./action-buttons";
import BMessageForm from "./bmessage-form";
import BMessageHistory from "./bmessage-history";
import useBmessageDialog from "./controllers/use-bmessage-dialog";

export const dialogTitle = "Broadcast Message";
export const dialogSubtitle =
	"Notify your team via text and in-app notifications.";
export const SEND_MESSAGE = "Send Message";
export const HISTORY = "History";

interface BroadcastMessageDialogProps {
	handleClose: () => void;
	open: boolean;
}

const BroadcastMessageDialog: React.FC<BroadcastMessageDialogProps> = ({
	handleClose,
	open,
}) => {
	const dispatch = useDispatch();
	const [
		{ jobs, message, options, filteredJobs, filteredWorkcenters },
		{
			setMessage,
			handleClick,
			handleSelectAll,
			setOptions,
			setFilteredWorkcenters,
			setFilteredJobs,
			setChangedBy,
		},
	] = useBmessageDialog();
	const [selectedTab, setSelectedTab] = useState(SEND_MESSAGE);

	const listOfTabs = [
		<BMessageForm
			key='bMessageForm'
			jobs={jobs ?? {}}
			message={message ?? ""}
			setMessage={
				setMessage
					? setMessage
					: () => {
							// Do nothing
					  }
			}
			handleClick={
				handleClick
					? handleClick
					: () => {
						// Do nothing
					  }
			}
			handleSelectAll={
				handleSelectAll
					? handleSelectAll
					: () => {
						// Do nothing
					  }
			}
			options={options ?? []}
			workcenters={filteredWorkcenters ?? []}
			setWorkcenters={
				setFilteredWorkcenters
					? setFilteredWorkcenters
					: () => {
						// Do nothing
					  }
			}
			setOptions={
				setOptions
					? setOptions
					: () => {
						// Do nothing
					  }
			}
			filteredJobs={filteredJobs ?? []}
			setFilteredJobs={
				setFilteredJobs
					? setFilteredJobs
					: () => {
						// Do nothing
					  }
			}
			setChangedBy={
				setChangedBy
					? setChangedBy
					: () => {
						// Do nothing
					  }
			}
		/>,
		<BMessageHistory key='bMessageHistory' />,
	];
	return (
		<Dialog
			onClose={(event, reason) => {
				if (reason !== "backdropClick" && handleClose) {
					handleClose();
				}
			}}
			aria-labelledby='add-new-shift'
			open={open}
			fullWidth
			PaperProps={{ sx: { maxWidth: "1200px" } }}
		>
			<DialogWrapper
				dialogTitle={dialogTitle}
				dialogSubTitle={dialogSubtitle}
				dialogContent={
					<Tabs
						tabPanelList={listOfTabs}
						tabPanelLabel={[SEND_MESSAGE, HISTORY]}
						onClickHandlers={{
							"Send Message": () => {
								setSelectedTab(SEND_MESSAGE);
							},
							History: () => {
								setSelectedTab(HISTORY);
								dispatch(fetchBroadcastHistory());
							},
						}}
					/>
				}
				actionButtons={
					selectedTab === SEND_MESSAGE ? (
						<ActionButtons
							handleClose={handleClose}
							jobs={
								filteredJobs ? filteredJobs.length > 0
									? filteredJobs
											?.filter((job: { checked: boolean }) => job.checked)
											?.map((job: { id: number }) => job.id)
											?.join(",") ?? ""
									: "" : ""
							}
							message={message ?? ""}
							locationIds={
								options
									?.filter((option) => option.checked)
									?.map((option) => option.id)
									?.join(",") ?? ""
							}
							workCenterIds={
								filteredWorkcenters
									?.filter((workcenter) => workcenter.checked)
									?.map((workcenter) => workcenter.id)
									?.join(",") ?? ""
							}
						/>
					) : (
						<Button variant='text' color='primary' onClick={handleClose}>
							Close
						</Button>
					)
				}
			/>
		</Dialog>
	);
};

export default BroadcastMessageDialog;
