// TODO - Type 'any' needs to be fixed.
/* eslint-disable no-mixed-spaces-and-tabs */
import { Switch, Typography } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import { ConfirmProvider } from "material-ui-confirm";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    createLocationPool,
    deleteLocationPool,
    editLocationPool,
    getCompanyLocationClusters,
    resetCreateLocationPool,
    resetDeleteLocationPool,
    resetEditLocationPool,
    setLocationClusterDialog,
} from "../../../../../../export/gat-admin";
import Loading from "../../../../Loading";
import { CompanyLocation, CoreSettings, OneCompany } from "../../../store/gat-admin-slice-types";
import LocationClusterDialog from "./ui/location-cluster-dialog";
import LocationClusterErrorDialog from "./ui/location-cluster-error-dialog";
import LocationClusterPlaceholder from "./ui/location-cluster-placeholder";
import LocationClusterTable from "./ui/location-cluster-table";

const defaultLocationClusterInfo = {
    companyId: 0,
    name: "",
    locationIds: "",
    isActive: true,
    statusChanged: false,
};

const LocationCluster = ({
    coreSettings,
    newCoreSettings,
    setNewCoreSettings,
    getCompanyLocationClustersStatus,
    companyLocationClusters,
    companyId,
    locations,
    getCompanySettingsStatus,
}: any) => {
    const dispatch = useDispatch();
    const [locationClusterData, setLocationClusterData] = useState<any>({});
    const [creatingNewLocationCluster, setCreatingNewLocationCluster] =
        useState(true);
    const [newLocationClusterInfo, setNewLocationClusterInfo] = useState(
        defaultLocationClusterInfo
    );
    // Required to update to avoid allowing same location in different location pool
    const [availableLocations, setAvailableLocations] = useState(locations);
    const [locationsForLocationDialog, setLocationsForLocationDialog] =
        useState(locations);

    const [locationClusterErrorDialog, setLocationClusterErrorDialog] =
        useState(false);

    const createLocationPoolStatus = useSelector(
        (state: any) => state.gatAdminConfiguration.createLocationPool.status
    );
    const createLocationPoolErrorMessage = useSelector(
        (state: any) => state.gatAdminConfiguration.createLocationPool.errorMessage
    );

    const editLocationPoolStatus = useSelector(
        (state: any) => state.gatAdminConfiguration.editLocationPool.status
    );
    const editLocationPoolErrorMessage = useSelector(
        (state: any) => state.gatAdminConfiguration.editLocationPool.errorMessage
    );

    const deleteLocationPoolStatus = useSelector(
        (state: any) => state.gatAdminConfiguration.deleteLocationPool.status
    );
    const deleteLocationPoolErrorMessage = useSelector(
        (state: any) => state.gatAdminConfiguration.deleteLocationPool.errorMessage
    );

    const openLocationClusterDialog = (open: any) => {
        if (availableLocations.length > 1) {
            setCreatingNewLocationCluster(true);
            dispatch(setLocationClusterDialog(open));
        } else {
            setLocationClusterErrorDialog(true);
        }
    };

    const closeLocationClusterDialog = () => {
        dispatch(setLocationClusterDialog(false));
        setNewLocationClusterInfo(defaultLocationClusterInfo);
        setLocationClusterData({});
        // To handle UI glitch of showing Create button on cancel
        setTimeout(() => {
            setCreatingNewLocationCluster(false);
        }, 500);
    };

    useEffect(() => {
        if (companyId) {
            dispatch(getCompanyLocationClusters(companyId));
        }
        return () => {
            // Close location cluster dialog on switching page
            dispatch(setLocationClusterDialog(false));
            setNewLocationClusterInfo(defaultLocationClusterInfo);
            setLocationClusterData({});
            setCreatingNewLocationCluster(false);
        };
    }, [dispatch, companyId]);

    useEffect(() => {
        if (companyLocationClusters && locations) {
            if (companyLocationClusters.length > 0 && locations.length > 0) {
                let consumedLocations: any = [];
                companyLocationClusters.forEach((locationCluster: any) => {
                    if (
                        Object.prototype.hasOwnProperty.call(locationCluster, "locationIds")
                    ) {
                        const locationIdsArray = locationCluster.locationIds
                            .split(",")
                            .map(Number);
                        consumedLocations = consumedLocations.concat(locationIdsArray);
                    }
                });
                // Removing consumed locations from overall locations
                let newAvailableLocations = [...locations];
                newAvailableLocations = newAvailableLocations.filter(
                    (x) => !consumedLocations.includes((x.id))
                );
                setAvailableLocations(newAvailableLocations);
            } else if (locations.length > 0) {
                setAvailableLocations(locations);
            }
        }
    }, [companyLocationClusters, locations]);

    useEffect(() => {
        try {
            if (creatingNewLocationCluster) {
                if (availableLocations) {
                    if (availableLocations.length > 0) {
                        const tempAvailableLocations = [...availableLocations];
                        const newLocationsForLocationDialog = tempAvailableLocations
                            ? tempAvailableLocations.sort((a, b) =>
                                a.name.localeCompare(b.name)
                            )
                            : [];
                        setLocationsForLocationDialog(newLocationsForLocationDialog);
                    }
                }
            } else {
                // While editing append existing locations
                if (JSON.stringify(locationClusterData) !== "{}") {
                    if (Object.prototype.hasOwnProperty.call(locationClusterData, "id")) {
                        if (companyLocationClusters) {
                            if (companyLocationClusters.length > 0) {
                                // Selecting from company main data as location cluster data changes on edit
                                const selectCompanyLocationCluster: any =
                                    companyLocationClusters.find(
                                        (x: any) => parseInt(x.id) === parseInt(locationClusterData.id)
                                    );
                                if (selectCompanyLocationCluster) {
                                    const existingLocationIds =
                                        selectCompanyLocationCluster.locationIds
                                            .split(",")
                                            .map(Number);
                                    const existingLocations = locations.filter((x: any) =>
                                        existingLocationIds.includes(parseInt(x.id))
                                    );
                                    const combinedLocations = [
                                        ...availableLocations.concat(existingLocations),
                                    ];
                                    if (combinedLocations) {
                                        if (combinedLocations.length > 0) {
                                            setLocationsForLocationDialog(
                                                combinedLocations.sort((a, b) =>
                                                    a.name.localeCompare(b.name)
                                                )
                                            );
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        } catch (e) {
            // Do nothing
        }
    }, [
        creatingNewLocationCluster,
        availableLocations,
        locationClusterData,
        locations,
        companyLocationClusters,
    ]);

    return (
        <Box
            sx={{
                backgroundColor: "#F5F5F5",
                borderRadius: "8px",
                marginTop: "32px",
                marginBottom: "16px",
                padding: "16px",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: "Roboto",
                            fontWeight: 400,
                            fontSize: "16px",
                            color: "rgba(0, 0, 0, 0.87)",
                        }}
                    >
                        Location cluster allowed
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: "Roboto",
                            fontWeight: 400,
                            fontSize: "12px",
                            color: "rgba(0, 0, 0, 0.6)",
                        }}
                    >
                        Create clusters of geographically colocated locations to allow for
                        workers to be shared across all those locations.
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Switch
                        disabled={getCompanySettingsStatus === "pending"}
                        onChange={(e) =>
                            setNewCoreSettings({
                                ...newCoreSettings,
                                shareWorkersAcrossLocations: e.target.checked,
                            })
                        }
                        checked={newCoreSettings.shareWorkersAcrossLocations ?? false}
                    />
                </Box>
            </Box>
            <>
                {newCoreSettings.shareWorkersAcrossLocations &&
                    getCompanyLocationClustersStatus === "pending" && (
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "100%",
                                marginTop: "20px",
                                marginBottom: "8px",
                                height: "72px",
                            }}
                        >
                            <Loading />
                        </Box>
                    )}
                {newCoreSettings.shareWorkersAcrossLocations &&
                    coreSettings.shareWorkersAcrossLocations &&
                    getCompanyLocationClustersStatus === "fulfilled" &&
                    companyLocationClusters.length === 0 && (
                        <LocationClusterPlaceholder
                            openLocationClusterDialog={openLocationClusterDialog}
                        />
                    )}
                {newCoreSettings.shareWorkersAcrossLocations &&
                    coreSettings.shareWorkersAcrossLocations &&
                    getCompanyLocationClustersStatus === "fulfilled" &&
                    companyLocationClusters.length > 0 && (
                        <ConfirmProvider>
                            <LocationClusterTable
                                companyLocationClusters={companyLocationClusters}
                                handleClickAdd={() => {
                                    openLocationClusterDialog(true);
                                }}
                                handleClickEdit={(row: any) => {
                                    setCreatingNewLocationCluster(false);
                                    setLocationClusterData({
                                        id: row.id,
                                        companyId: companyId,
                                        name: row.name,
                                        locationIds: row.locationIds,
                                        isActive: row.isActive,
                                        statusChanged: false,
                                    });
                                    dispatch(setLocationClusterDialog(true));
                                }}
                                deleteLocationCluster={(id: number) => dispatch(deleteLocationPool(id))}
                                getLocationName={(id: any) => {
                                    let returnValue = "[Error]";
                                    const locationObj = locations.find(
                                        (x: any) => parseInt(x.id) === id
                                    );
                                    if (locationObj) {
                                        returnValue = locationObj.name;
                                    }
                                    return returnValue;
                                }}
                            />
                        </ConfirmProvider>
                    )}
            </>
            {/*No Location Found Error Dialog*/}
            <LocationClusterErrorDialog
                onClose={() => setLocationClusterErrorDialog(false)}
                open={locationClusterErrorDialog}
            />
            {/*Location Cluster Dialog*/}
            <LocationClusterDialog
                creatingNewLocationCluster={creatingNewLocationCluster}
                locationClusterData={
                    creatingNewLocationCluster
                        ? {
                            ...newLocationClusterInfo,
                            companyId: companyId,
                        }
                        : {
                            ...locationClusterData,
                            companyId: companyId,
                        }
                }
                setLocationClusterData={(newValue: any) => {
                    creatingNewLocationCluster
                        ? setNewLocationClusterInfo(newValue)
                        : setLocationClusterData(newValue);
                }}
                onClose={() => {
                    closeLocationClusterDialog();
                }}
                locations={locationsForLocationDialog}
                saveLocationCluster={() => {
                    if (creatingNewLocationCluster) {
                        dispatch(createLocationPool(newLocationClusterInfo));
                    } else {
                        dispatch(
                            editLocationPool({ ...locationClusterData, companyId: companyId })
                        );
                    }
                }}
            />

            {/* Location pool error and success */}
            <Snackbar
                open={deleteLocationPoolStatus === "error"}
                autoHideDuration={2000}
                onClose={() => {
                    dispatch(resetDeleteLocationPool());
                }}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <MuiAlert elevation={6} variant='filled' severity='error'>
                    {deleteLocationPoolErrorMessage ??
                        "An Error Occurred. Please try again."}
                </MuiAlert>
            </Snackbar>
            <Snackbar
                open={deleteLocationPoolStatus === "fulfilled"}
                autoHideDuration={1000}
                onClose={() => {
                    setCreatingNewLocationCluster(false);
                    setNewLocationClusterInfo(defaultLocationClusterInfo);
                    dispatch(resetDeleteLocationPool());
                    dispatch(setLocationClusterDialog(false));
                    dispatch(getCompanyLocationClusters(companyId));
                }}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <MuiAlert elevation={6} variant='filled' severity='success'>
                    {"Location Pool deleted"}
                </MuiAlert>
            </Snackbar>
            <Snackbar
                open={editLocationPoolStatus === "error"}
                autoHideDuration={2000}
                onClose={() => {
                    dispatch(resetEditLocationPool());
                }}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <MuiAlert elevation={6} variant='filled' severity='error'>
                    {editLocationPoolErrorMessage ??
                        "An Error Occurred. Please try again."}
                </MuiAlert>
            </Snackbar>
            <Snackbar
                open={editLocationPoolStatus === "fulfilled"}
                autoHideDuration={1000}
                onClose={() => {
                    setCreatingNewLocationCluster(false);
                    setNewLocationClusterInfo(defaultLocationClusterInfo);
                    dispatch(resetEditLocationPool());
                    dispatch(setLocationClusterDialog(false));
                    dispatch(getCompanyLocationClusters(companyId));
                }}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <MuiAlert elevation={6} variant='filled' severity='success'>
                    {"Location Pool updated"}
                </MuiAlert>
            </Snackbar>
            <Snackbar
                open={createLocationPoolStatus === "error"}
                autoHideDuration={2000}
                onClose={() => {
                    dispatch(resetCreateLocationPool());
                }}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <MuiAlert elevation={6} variant='filled' severity='error'>
                    {createLocationPoolErrorMessage ??
                        "An Error Occurred. Please try again."}
                </MuiAlert>
            </Snackbar>
            <Snackbar
                open={createLocationPoolStatus === "fulfilled"}
                autoHideDuration={1000}
                onClose={() => {
                    setCreatingNewLocationCluster(false);
                    setNewLocationClusterInfo(defaultLocationClusterInfo);
                    dispatch(resetCreateLocationPool());
                    dispatch(setLocationClusterDialog(false));
                    dispatch(getCompanyLocationClusters(companyId));
                }}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <MuiAlert elevation={6} variant='filled' severity='success'>
                    {"New Location Pool added!"}
                </MuiAlert>
            </Snackbar>
        </Box>
    );
};

export default LocationCluster;
