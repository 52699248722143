import { Box, Grid, Switch, TextField, Typography } from "@mui/material";
import React from "react";

import { newShiftSettings } from "../../../../../../../ts/components/gat-admin/store/gat-admin-slice-types";

interface IShiftCancellationProps {
	newShiftSettings: newShiftSettings
	setNewShiftSettings: React.Dispatch<React.SetStateAction<newShiftSettings>>
}

const ShiftCancellation: React.FC<IShiftCancellationProps> = ({ newShiftSettings, setNewShiftSettings }) => {
	if (Object.keys(newShiftSettings).length === 0) return null;
	return (
		<Box
			sx={{
				backgroundColor: "#F5F5F5",
				borderRadius: "8px",
				marginTop: "32px",
				padding: "16px",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 400,
							fontSize: "16px",
							color: "rgba(0, 0, 0, 0.87)",
						}}
					>
						Shift cancellation allowed
					</Typography>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 400,
							fontSize: "12px",
							color: "rgba(0, 0, 0, 0.6)",
						}}
					>
						Can worker cancel a shift in the red zone.
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Switch
						checked={newShiftSettings.cancelEnabled ?? false}
						onChange={(e) =>
							setNewShiftSettings((prevSettings: any) => ({
								...prevSettings,
								cancelEnabled: e.target.checked,
							}))
						}
					/>
				</Box>
			</Box>
			{newShiftSettings.cancelEnabled && (
				<Box
					sx={{
						marginTop: "32px",
					}}
				>
					<Grid container>
						<Grid item xs={6}>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									width: "300px",
								}}
							>
								<Typography
									sx={{
										fontFamily: "Roboto",
										fontWeight: 400,
										fontSize: "16px",
										color: "rgba(0, 0, 0, 0.87)",
									}}
								>
									Allowed shift cancellation window - before the shift starts
								</Typography>
								<Typography
									sx={{
										fontFamily: "Roboto",
										fontWeight: 400,
										fontSize: "12px",
										color: "rgba(0, 0, 0, 0.6)",
										paddingBottom: "8px",
									}}
								>
									Worker is allowed to cancel a shift only N days before the
									shift start time.
								</Typography>
								<TextField
									id='shift-cancellation-window-pre'
									variant='filled'
									type='number'
									sx={{
										"& .MuiInputBase-input": {
											background: "#FFF",
										},
									}}
									label=''
									onFocus={(event) => {
										event.target.select();
									}}
									onChange={(e) => {
										setNewShiftSettings((prevSettings: any) => ({
											...prevSettings,
											cancelWindowBeforeStartDays: parseInt(e.target.value),
										}));
									}}
									value={newShiftSettings.cancelWindowBeforeStartDays ?? 0}
									helperText='Days'
								/>
							</Box>
						</Grid>
						<Grid item xs={6}>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									width: "300px",
								}}
							>
								<Typography
									sx={{
										fontFamily: "Roboto",
										fontWeight: 400,
										fontSize: "16px",
										color: "rgba(0, 0, 0, 0.87)",
									}}
								>
									Allowed shift cancellation window - post acceptance
								</Typography>
								<Typography
									sx={{
										fontFamily: "Roboto",
										fontWeight: 400,
										fontSize: "12px",
										color: "rgba(0, 0, 0, 0.6)",
										paddingBottom: "8px",
									}}
								>
									Worker is not allowed to cancel the shift N hours after
									acceptance.
								</Typography>
								<TextField
									id='shift-cancellation-window-post'
									variant='filled'
									type='number'
									sx={{
										"& .MuiInputBase-input": {
											background: "#FFF",
										},
									}}
									label=''
									onFocus={(event) => {
										event.target.select();
									}}
									onChange={(e) => {
										setNewShiftSettings((prevSettings: any) => ({
											...prevSettings,
											cancelWindowAfterAcceptHrs: parseInt(e.target.value),
										}));
									}}
									value={newShiftSettings.cancelWindowAfterAcceptHrs ?? "0"}
									helperText='Hours'
								/>
							</Box>
						</Grid>
					</Grid>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "space-between",
							marginTop: "32px",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
							}}
						>
							<Typography
								sx={{
									fontFamily: "Roboto",
									fontWeight: 400,
									fontSize: "16px",
									color: "rgba(0, 0, 0, 0.87)",
								}}
							>
								Allow shift force cancel
							</Typography>
							<Typography
								sx={{
									fontFamily: "Roboto",
									fontWeight: 400,
									fontSize: "12px",
									color: "rgba(0, 0, 0, 0.6)",
								}}
							>
								Option to cancel a future shift after acceptance from a worker
							</Typography>
						</Box>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<Switch
								onChange={(e) => {
									setNewShiftSettings((prevSettings: any) => ({
										...prevSettings,
										forceCancelEnabled: e.target.checked,
									}));
								}}
								checked={newShiftSettings.forceCancelEnabled ?? false}
							/>
						</Box>
					</Box>
				</Box>
			)}
		</Box>
	);
};

export default ShiftCancellation;
