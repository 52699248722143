export const factories = [
    {
        createdBy:"johndoe@boeing.com",
        createdOn:"2023-06-06T13:31:24.77",
        id:1,
        isActive:true,
        name:"Commercial Airplanes",
        updatedBy:"johndoe@boeing.com",
        updatedOn:"2022-10-10T05:25:30.99",
        userCount:40,
        workerCount:2200
    },
    {
        createdBy:"sylivia.tang@boeing.com",
        createdOn:"2023-06-06T13:31:23.77",
        id:2,
        isActive:true,
        name:"Defense, Space & Security",
        updatedBy:"sylivia.tang@boeing.com",
        updatedOn:"2022-10-10T05:25:30.99",
        userCount:45,
        workerCount:2000
    }
];
