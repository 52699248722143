import React from "react";

import SkillRepresentationCell from "../skill-representaion-cell/skill-representaion-cell";
import { getLevelObj } from "./helper";

interface SkillValueProps {
	value: number;
	skillLevels: any;
	background?: boolean;
	size?: "sm" | "lg";
}

const SkillValue: React.FC<SkillValueProps> = ({
	value,
	skillLevels,
	background = true,
	size = "lg",
}) => {
	if (value >= 0) {
		const { levelValue, repLevel, backgroundColor, foregroundColor, repType } =
			getLevelObj(String(value), skillLevels);
		return (
			<SkillRepresentationCell
				level={value}
				value={levelValue}
				representationType={repType}
				representationLevel={repLevel}
				backgroundColor={background ? backgroundColor : ""}
				foregroundColor={foregroundColor}
				border={1}
				height={size === "lg" ? 20 : 10}
				width={size === "lg" ? 20 : 10}
				backgroundWidth={size === "lg" ? "35px" : "28px"}
				backgroundHeight={size === "lg" ? "35px" : "28px"}
			/>
		);
	} else {
		return <></>;
	}
};

export default SkillValue;
