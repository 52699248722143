import { Box, Button } from "@mui/material";
import * as React from "react";
import { useDispatch } from "react-redux";

import {
	requestShiftAction,
	resetShiftFormData,
	resetWorkerRequestMetaData,
	setShiftFormData,
	setWorkerRequestMetaData,
} from "../../store/worker-requests-slice";

interface formDataTypes {
	requestId: number;
	ftShiftId: number;
	comment: string;
}
interface ActionsProps {
	openCreateNewShiftDialog: (date: string) => void;
	date: string;
	formData: formDataTypes;
	requestData: {
		name: string;
		requestType: string;
		leaveType: string;
		startDateTime: string;
		endDateTime: string;
	};
}

export default function Actions({
	openCreateNewShiftDialog,
	date,
	formData,
	requestData,
}: ActionsProps) {
	const dispatch = useDispatch();
	const handleActionClick = (option: string) => {
		if (option === "reject") {
			dispatch(
				requestShiftAction({
					...formData,
					decisionCode: "r",
					flexShiftDetails: null,
				})
			);
		} else {
			dispatch(resetWorkerRequestMetaData());
			dispatch(setShiftFormData({ ...formData, decisionCode: "a" }));
			dispatch(setWorkerRequestMetaData(requestData));
			openCreateNewShiftDialog(date);
		}
	};

	return (
		<Box display='flex' justifyContent='space-between'>
			<Box mr={1}>
				<Button
					variant='outlined'
					color='primary'
					onClick={() => handleActionClick("approve")}
					sx={{ width: "80px" }}
				>
					Approve
				</Button>
			</Box>
			<Box width='80px'>
				<Button
					variant='outlined'
					color='error'
					onClick={() => handleActionClick("reject")}
					sx={{ width: "80px" }}
				>
					Reject
				</Button>
			</Box>
		</Box>
	);
}
