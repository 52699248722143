import escapeRegExp from "lodash/escapeRegExp";
import React from "react";

const Highlight = ({ value, searchText }) => {
	value = value ? value.toString() : "";
	searchText = searchText ? escapeRegExp(searchText.toString()) : "";
	if (!searchText || searchText.trim() === "" || searchText === "+") {
		return <>{value}</>;
	}

	const regex = new RegExp(`(${searchText})`, "gi");
	const parts = value.split(regex);

	return (
		<span>
			{parts.map((part, index) =>
				regex.test(part) ? (
					<span key={index} style={{ backgroundColor: "yellow" }}>
						{part}
					</span>
				) : (
					part
				)
			)}
		</span>
	);
};

export default Highlight;
