// TODO - Type 'any' needs to be fixed.
import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Typography } from "@mui/material";
import React from "react";

import { colors } from "../../../../../../../ts/config/colors";

const LocationClusterPlaceholder = ({ openLocationClusterDialog }:any) => {
	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				flexDirection: "row",
				marginTop: "20px",
				marginBottom: "8px",
				borderRadius: "3px",
				border: 1,
				borderWidth: "1px",
				borderColor: colors.adminPlaceholderBorder,
				backgroundColor: colors.adminPlaceholderBackground,
				padding: "17px",
			}}
		>
			<Typography
				sx={{
					color: "rgba(0, 0, 0, 0.60)",
					textAlign: "center",
					fontFamily: "Roboto",
					fontSize: "14px",
					fontWeight: "400",
				}}
			>
				No location cluster added.
			</Typography>
			<Button
				startIcon={<AddIcon />}
				sx={{
					marginLeft: "12px",
				}}
				onClick={() => openLocationClusterDialog(true)}
			>
				Location Cluster
			</Button>
		</Box>
	);
};

export default LocationClusterPlaceholder;
