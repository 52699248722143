// TODO - Type 'any' needs to be fixed.
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, Typography } from "@mui/material";
import Popover from "@mui/material/Popover";
import { format } from "date-fns";
import { useState } from "react";
import React from "react";

import { useScheduleController } from "../../../../export/scheduler";
import DayDatePicker from "./day-date-picker";
import WeekDatePicker from "./week-date-picker";

interface IWeekNavigatorProps {
	handleBack: () => void;
	handleForward: () => void;
	date: any;
	handleCalendarClick: (e: any) => void;
	isWeekView: boolean;
}

const WeekNavigator: React.FC<IWeekNavigatorProps> = ({
	handleBack,
	handleForward,
	date,
	handleCalendarClick,
	isWeekView,
}) => {
	const [, { getDateRangeText }]: any = useScheduleController();
	const dateRangeText = getDateRangeText(date, true);

	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "flex-start",
					justifyContent: "center",
					marginLeft: "16px",
					marginRight: "8px",
				}}
			>
				<Box
					onClick={handleBack}
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						padding: "4px",
						cursor: "pointer",
						height: "36px",
						width: "36px",
						borderRadius: "4px",
						"&:hover": {
							backgroundColor: "#2F4D8B0A"
						}
					}}
				>
					<ArrowBackIcon
						sx={{
							height: "24px",
							color: "rgba(0,0,0,0.56)",
						}}
					/>
				</Box>
			</Box>
			<Box>
				<Box
					sx={{
						minWidth: "186px",
						minHeight: "31px",
						border: "1px solid rgba(0,0,0,0.08)",
						borderColor: "rgba(0,0,0,0.08) !important",
						padding: "5px 4px",
						borderRadius: "4px",
						cursor: "pointer",
						height: "36px",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						"&:hover": {
							backgroundColor: "#2F4D8B0A"
						}
					}}
					onClick={handleClick}
				>
					<Typography
						sx={{
							textAlign: "center",
							fontSize: "14px",
							color: "rgba(0,0,0,0.6)",
							fontWeight: 500,
							textTransform: "uppercase",
						}}
					>
						{isWeekView ? dateRangeText : format(new Date(date), "MMM dd")}
					</Typography>
				</Box>
				<Popover
					id={id}
					open={open}
					anchorEl={anchorEl}
					onClose={handleClose}
					transformOrigin={{
						horizontal: "center",
						vertical: "top",
					}}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "center",
					}}
				>
					{isWeekView ? (
						<WeekDatePicker
							anyDateOfSelectedWeek={new Date(date)}
							changeDateOfSelectedWeek={handleCalendarClick}
						/>
					) : (
						<DayDatePicker
							currentDate={new Date(date)}
							handleCalendarClick={handleCalendarClick}
						/>
					)}
				</Popover>
			</Box>
			<Box
				onClick={handleForward}
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "flex-start",
					justifyContent: "center",
					marginLeft: "8px",
					cursor: "pointer",
					height: "36px",
					width: "36px",
					borderRadius: "4px",
					"&:hover": {
						backgroundColor: "#2F4D8B0A"
					}
				}}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						padding: "4.5px",
					}}
				>
					<ArrowForwardIcon
						sx={{
							height: "24px",
							color: "rgba(0,0,0,0.56)",
						}}
					/>
				</Box>
			</Box>
		</Box>
	);
};

export default WeekNavigator;
