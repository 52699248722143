/* eslint-disable no-mixed-spaces-and-tabs */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addHours, addMinutes, addSeconds } from "date-fns";
import add from "date-fns/add";
import addDays from "date-fns/addDays";
import addWeeks from "date-fns/addWeeks";
import differenceInMinutes from "date-fns/differenceInMinutes";
import format from "date-fns/format";
import isSunday from "date-fns/isSunday";
import startOfWeek from "date-fns/startOfWeek";

import { JOB } from "../../assets/constants";
import axios from "../../utils/api-client";
import {
	addSkillsToShifts,
	addStartDateEndDate,
	addStartDateEndDateToAShift,
	generateDaysOfTheWeek,
	generateDaysOfTheWeekSerialized,
} from "../../utils/helpers";
import { editDraftShiftDialogCloseReducer } from "./reducers/editDraftShiftDialogCloseReducer";
import { editDraftShiftDialogOpenReducer } from "./reducers/editDraftShiftDialogOpenReducer";
import { resetBroadcastMessageStateReducer } from "./reducers/resetBroadcastMessageStateReducer";
import { resetCreateDraftShiftAsyncStateReducer } from "./reducers/resetCreateDraftShiftAsyncStateReducer";
import { resetDeleteDraftShiftAsyncReducer } from "./reducers/resetDeleteDraftShiftAsyncReducer";
import { resetEditDraftShiftReducer } from "./reducers/resetEditDraftShiftReducer";
import { resetMarkAttendanceReducer } from "./reducers/resetMarkAttendanceReducer";
import { resetPublishDraftShiftsStateReducer } from "./reducers/resetPublishDraftShiftsStateReducer";
import { resetRenotifyWorkersReducer } from "./reducers/resetRenotifyWorkersReducer";

export const fetchInitialData = createAsyncThunk(
	"scheduler/fetchInitialData",
	async (payload, thunkAPI) => {
		let userId = thunkAPI.getState().user.userData.id;
		let datesOfTheWeek = generateDaysOfTheWeek(new Date());

		try {
			const startDateTime = new Date(datesOfTheWeek.monday);
			// Adding 24 hours minus a sec to the end date to cover that last day
			const endDateTime = new Date(
				addSeconds(new Date(datesOfTheWeek.sunday), 24 * 60 * 60 - 1)
			);

			let startDate = startDateTime;
			let endDate = endDateTime;

			const getWorkcenters = axios.get(
				`${process.env.REACT_APP_API_END_POINT}/WorkCenters/GetWorkcentersByUser/`
			);

			const getShiftsByUser = axios.get(
				`${process.env.REACT_APP_API_END_POINT}/Shifts/User/`,
				{
					params: {
						userId,
						startDate,
						endDate,
					},
				}
			);

			const getJobRoles = axios.get(
				`${process.env.REACT_APP_API_END_POINT}/Jobs/GetJobsByUser`,
				{
					params: {
						userid: userId,
					},
				}
			);

			const getSkills = axios.get(
				`${process.env.REACT_APP_API_END_POINT}/Skill/GetSkillsByUser`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: localStorage.getItem("token"),
					},
				}
			);

			const getSkillLevels = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/Skill/Levels`
			);

			let allAPICalls = [
				getWorkcenters,
				getShiftsByUser,
				getJobRoles,
				getSkills,
				getSkillLevels,
			];

			const promisedValues = await Promise.allSettled(allAPICalls);

			const [workcenters, weekShift, jobRoles, skills, skillLevels] =
				promisedValues;

			let weekShiftData = {},
				shiftsData;

			if (weekShift.status === "rejected") {
				shiftsData = [];
				weekShiftData["jobs"] = [];
				weekShiftData["workcenters"] = [];
			} else {
				weekShiftData = weekShift?.value?.data;
				// console.log(weekShiftData);
				shiftsData = [];
				if (weekShiftData.signupTracking) {
					const shiftsWithSkills = addSkillsToShifts(weekShiftData);
					shiftsData = addStartDateEndDate(shiftsWithSkills);
				}
			}

			// And getting Workcenters here.
			let workcentersData;
			if (workcenters.status === "fulfilled") {
				workcentersData = workcenters?.value?.data;
			} else {
				workcentersData = [];
			}

			//getting locations here
			// let locationsData;
			// if (locations.status === "fulfilled") {
			// 	locationsData = locations?.value?.data;
			// } else {
			// 	locationsData = [];
			// }

			// Getting job roles here.
			let jobRolesData;
			if (jobRoles.status === "fulfilled") {
				jobRolesData = jobRoles?.value?.data;
			}
			// let jobRolesData = jobRoles?.data;

			// console.log(workcentersData);

			// Consolidating the data in one place
			const allDataForTheWeek = {
				shiftsData,
				jobs: weekShiftData.jobs,
				workcenters: weekShiftData.workcenters,
			};

			// console.log(allDataForTheWeek);

			// Generating days of the week for going forward and backward in the scheduler
			let datesOfTheWeekSerialized = Object.keys(datesOfTheWeek).reduce(
				(acc, key) => {
					acc[key] = datesOfTheWeek[key].toISOString();
					return acc;
				},
				{}
			);
			// console.log(datesOfTheWeekSerialized);
			let skillsData;
			if (skills.status === "fulfilled") {
				skillsData = skills?.value?.data;
			} else {
				skillsData = [];
			}

			let skillLevelsData;
			if (skillLevels.status === "fulfilled") {
				skillLevelsData = skillLevels?.value?.data;
			} else {
				skillLevelsData = [];
			}

			// console.log("workcentersData", workcentersData);
			return thunkAPI.fulfillWithValue({
				allDataForTheWeek,
				workcentersData,
				jobRolesData,
				// locationsData,
				datesOfTheWeekSerialized,
				currentDateTime: new Date().toISOString(),
				dataMonday: startDate.toISOString(),
				skillsData,
				skillLevelsData,
			});
		} catch (e) {
			// console.log(e);
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export const fetchWeekShifts = createAsyncThunk(
	"scheduler/fetchWeekShifts",
	/**  @param payload {startDate: Date, endDate: Date} */
	async (payload, thunkAPI) => {
		let userId = thunkAPI.getState().user.userData.id;
		const { startDate, endDate } = payload;

		try {
			const getShiftsByUser = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/Shifts/User/`,
				{
					params: {
						userId,
						startDate,
						endDate,
					},
				}
			);
			if (getShiftsByUser.data.signupTracking) {
				const shiftsWithSkills = addSkillsToShifts(getShiftsByUser.data);
				return thunkAPI.fulfillWithValue(shiftsWithSkills);
			} else {
				return thunkAPI.fulfillWithValue([]);
			}
		} catch (error) {
			// console.log(error);
			return thunkAPI.rejectWithValue("An error occurred");
		}
	}
);

export const resetDemo = createAsyncThunk(
	"scheduler/resetDemo",
	async (payload, thunkAPI) => {
		try {
			let response = await axios.post(
				`${process.env.REACT_APP_API_END_POINT}/Companies/Reset`
			);
			if (response.status === 200) {
				return thunkAPI.fulfillWithValue(payload);
			}
			// return thunkAPI.rejectWithValue(response.error);
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data.message);
		}
	}
);

export const fetchAllWorkcenters = createAsyncThunk(
	"scheduler/fetchAllWorkcenters",
	async (payload, thunkAPI) => {
		try {
			const allWorkcenters = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/WorkCenters/GetWorkcentersByUser`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: localStorage.getItem("token"),
					},
				}
			);
			const data = allWorkcenters.data;
			return thunkAPI.fulfillWithValue(data);
		} catch (error) {
			// console.log("eeeorrr", error);
			return thunkAPI.rejectWithValue(error.message);
		}
	}
);

export const fetchAllLocations = createAsyncThunk(
	"scheduler/fetchAllLocations",
	/** @param payload {companyId: number} */
	async (payload, thunkAPI) => {
		try {
			const allLocations = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/companies/${payload}/locations`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: localStorage.getItem("token"),
					},
				}
			);
			const data = allLocations.data;
			return thunkAPI.fulfillWithValue(data);
		} catch (error) {
			// console.log("eeeorrr", error);
			return thunkAPI.rejectWithValue(error.message);
		}
	}
);

export const fetchAllJobRoles = createAsyncThunk(
	"scheduler/fetchAllJobRoles",
	async (payload, thunkAPI) => {
		let userId = thunkAPI.getState().user.userData.id;

		try {
			const allJobRoles = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/Jobs/GetJobsByUser`,
				{
					params: {
						userid: userId,
					},
				}
			);
			const data = allJobRoles.data;
			return thunkAPI.fulfillWithValue(data);
		} catch (error) {
			//console.error(error);
			return thunkAPI.rejectWithValue(error.message);
		}
	}
);

export const changeWeek = createAsyncThunk(
	"scheduler/changeWeek",
	async (payload, { fulfillWithValue, getState }) => {
		let userId = getState().user.userData.id;

		const currentDate = new Date(
			getState().supervisor.schedulerData.currentDate
		);

		// console.log(currentDate);

		if (payload === "forward") {
			const oneWeekForward = addDays(currentDate, 7);

			const { monday: nextWeekMonday, sunday: nextWeekSunday } =
				generateDaysOfTheWeek(oneWeekForward);

			const nextWeekMondayFormatted = format(nextWeekMonday, "yyyy-MM-dd");
			//const nextWeekSundayFormatted = new Date(nextWeekSunday);

			if (
				!getState().supervisor.masterData.shiftsData[nextWeekMondayFormatted]
			) {
				// get next week's data
				// get the start and end date

				const shiftsDataForNextWeek = await axios.get(
					`${process.env.REACT_APP_API_END_POINT}/Shifts/User/`,
					{
						params: {
							userId,
							startDate: nextWeekMonday,
							endDate: nextWeekSunday,
						},
					}
				);

				// console.log(shiftsDataForNextWeek);

				const { jobs, workcenters, signupTracking } =
					shiftsDataForNextWeek.data;
				// console.log("signupTracking-8-", signupTracking);
				if (!jobs || !workcenters || !signupTracking) {
					return fulfillWithValue({
						status: "noShiftsFound",
						monday: nextWeekMondayFormatted,
						currentDateTime: oneWeekForward.toISOString(),
						datesOfTheWeekSerialized:
							generateDaysOfTheWeekSerialized(oneWeekForward),
					});
				}

				const shiftsWithSkills = addSkillsToShifts(shiftsDataForNextWeek.data);
				const shiftsWithDates = addStartDateEndDate(shiftsWithSkills);
				const shiftData = { jobs, workcenters, shiftsData: shiftsWithDates };

				return fulfillWithValue({
					status: "addDataToState",
					data: shiftData,
					monday: nextWeekMondayFormatted,
					currentDateTime: oneWeekForward.toISOString(),
					datesOfTheWeekSerialized:
						generateDaysOfTheWeekSerialized(oneWeekForward),
				});

				// store it to master data
				// store it in the current week
				// change the current week date as well
			} else {
				// take data from the master data and
				// store it in the current week data
				// change the current week data as well
				return fulfillWithValue({
					status: "replaceDataFromMaster",
					monday: nextWeekMondayFormatted,
					currentDateTime: oneWeekForward.toISOString(),
					datesOfTheWeekSerialized:
						generateDaysOfTheWeekSerialized(oneWeekForward),
				});
			}
		} else if (payload === "back") {
			const oneWeekBack = addDays(currentDate, -7);

			const { monday: lastWeekMonday, sunday: lastWeekSunday } =
				generateDaysOfTheWeek(oneWeekBack);

			const lastWeekMondayFormatted = format(lastWeekMonday, "yyyy-MM-dd");
			//const lastWeekSundayFormatted = format(lastWeekSunday, "yyyy-MM-dd");

			// console.log(getState());

			if (
				!getState().supervisor.masterData.shiftsData[lastWeekMondayFormatted]
			) {
				// get next week's data
				// get the start and end date

				const shiftsDataForLastWeek = await axios.get(
					`${process.env.REACT_APP_API_END_POINT}/Shifts/User/`,
					{
						params: {
							userId,
							startDate: lastWeekMonday,
							endDate: lastWeekSunday,
						},
					}
				);

				// console.log(shiftsDataForLastWeek);

				const { jobs, workcenters, signupTracking } =
					shiftsDataForLastWeek.data;
				// console.log("signupTracking-7-", signupTracking);
				if (!jobs || !workcenters || !signupTracking) {
					// console.log(shiftsDataForLastWeek.data);
					return fulfillWithValue({
						status: "noShiftsFound",
						monday: lastWeekMondayFormatted,
						currentDateTime: oneWeekBack.toISOString(),
						datesOfTheWeekSerialized:
							generateDaysOfTheWeekSerialized(oneWeekBack),
					});
				}

				const shiftsWithSkills = addSkillsToShifts(shiftsDataForLastWeek.data);
				const shiftsWithDates = addStartDateEndDate(shiftsWithSkills);
				const shiftData = { jobs, workcenters, shiftsData: shiftsWithDates };

				return fulfillWithValue({
					status: "addDataToState",
					data: shiftData,
					monday: lastWeekMondayFormatted,
					currentDateTime: oneWeekBack.toISOString(),
					datesOfTheWeekSerialized:
						generateDaysOfTheWeekSerialized(oneWeekBack),
				});

				// store it to master data
				// store it in the current week
				// change the current week date as well
			} else {
				// take data from the master data and
				// store it in the current week data
				// change the current week data as well
				return fulfillWithValue({
					status: "replaceDataFromMaster",
					monday: lastWeekMondayFormatted,
					currentDateTime: oneWeekBack.toISOString(),
					datesOfTheWeekSerialized:
						generateDaysOfTheWeekSerialized(oneWeekBack),
				});

				// console.log("data already present prev");
			}
		} else if (payload === "today") {
			const today = new Date();
			const { monday: thisWeekMonday, sunday: thisWeekSunday } =
				generateDaysOfTheWeek(today);

			const thisWeekMondayFormatted = format(thisWeekMonday, "yyyy-MM-dd");

			if (
				!getState().supervisor.masterData.shiftsData[thisWeekMondayFormatted]
			) {
				const shiftDataForThisWeek = await axios.get(
					`${process.env.REACT_APP_API_END_POINT}/Shifts/User/`,
					{
						params: {
							userId,
							startDate: thisWeekMonday,
							endDate: thisWeekSunday,
						},
					}
				);

				// console.log(shiftsDataForNextWeek);

				const { jobs, workcenters } = shiftDataForThisWeek.data;

				const shiftsWithSkills = addSkillsToShifts(shiftDataForThisWeek.data);
				const shiftsWithDates = addStartDateEndDate(shiftsWithSkills);
				const shiftData = { jobs, workcenters, shiftsData: shiftsWithDates };
				// console.log("signupTracking-6-", signupTracking);
				return fulfillWithValue({
					status: "addDataToState",
					data: shiftData,
					monday: thisWeekMondayFormatted,
					currentDateTime: today.toISOString(),
					datesOfTheWeekSerialized: generateDaysOfTheWeekSerialized(today),
				});
			} else {
				return fulfillWithValue({
					status: "replaceDataFromMaster",
					monday: thisWeekMondayFormatted,
					currentDateTime: today.toISOString(),
					datesOfTheWeekSerialized: generateDaysOfTheWeekSerialized(today),
				});
			}
		}
	}
);

export const getHourlyWorkerCount = createAsyncThunk(
	"scheduler/getHourlyWorkerCount",
	async (payload, thunkAPI) => {
		try {
			let datesOfTheWeek = generateDaysOfTheWeek(new Date(payload.date));
			const startDateTime = new Date(datesOfTheWeek.monday);
			// Adding 24 hours minus a sec to the end date to cover that last day
			const endDateTime = new Date(
				addSeconds(new Date(datesOfTheWeek.sunday), 24 * 60 * 60 - 1)
			);

			const getAvailability = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/Reports/GetHourlyWorkerCount`,
				{
					params: {
						dateOfReportStart: startDateTime,
						dateOfReportEnd: endDateTime,
						groupBy: payload.type,
					},
				}
			);
			return thunkAPI.fulfillWithValue({
				type: payload.type,
				data: getAvailability.data,
			});
		} catch (e) {
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export const changeWeeklyAvailability = createAsyncThunk(
	"scheduler/changeWeeklyAvailability",
	async (payload, thunkAPI) => {
		const currentDate = new Date(
			thunkAPI.getState().supervisor.schedulerData.currentDate
		);
		const today = new Date();
		const oneWeekForward = addDays(currentDate, 7);
		const oneWeekBack = addDays(currentDate, -7);

		let params = {};

		let weekFormatted;

		const selection = payload.selection;
		const shiftBySkills = payload.shiftBySkills;
		const type = payload.type;

		let currentDateTime;
		let datesOfTheWeekSerialized;

		try {
			if (selection === "forward") {
				const { monday: nextWeekMonday, sunday: nextWeekSunday } =
					generateDaysOfTheWeek(oneWeekForward);
				weekFormatted = format(nextWeekMonday, "yyyy-MM-dd");
				params = {
					dateOfReportStart: nextWeekMonday,
					dateOfReportEnd: new Date(
						addSeconds(new Date(nextWeekSunday), 24 * 60 * 60 - 1)
					),
				};
				currentDateTime = oneWeekForward.toISOString();
				datesOfTheWeekSerialized =
					generateDaysOfTheWeekSerialized(oneWeekForward);
			} else if (selection === "back") {
				const { monday: lastWeekMonday, sunday: lastWeekSunday } =
					generateDaysOfTheWeek(oneWeekBack);
				weekFormatted = format(lastWeekMonday, "yyyy-MM-dd");
				params = {
					dateOfReportStart: lastWeekMonday,
					dateOfReportEnd: new Date(
						addSeconds(new Date(lastWeekSunday), 24 * 60 * 60 - 1)
					),
				};
				currentDateTime = oneWeekBack.toISOString();
				datesOfTheWeekSerialized = generateDaysOfTheWeekSerialized(oneWeekBack);
			} else if (selection === "today") {
				const { monday: thisWeekMonday, sunday: thisWeekSunday } =
					generateDaysOfTheWeek(today);
				weekFormatted = format(thisWeekMonday, "yyyy-MM-dd");
				params = {
					dateOfReportStart: thisWeekMonday,
					dateOfReportEnd: new Date(
						addSeconds(new Date(thisWeekSunday), 24 * 60 * 60 - 1)
					),
				};
				currentDateTime = today.toISOString();
				datesOfTheWeekSerialized = generateDaysOfTheWeekSerialized(today);
			} else {
				const { monday: thisWeekMonday, sunday: thisWeekSunday } =
					generateDaysOfTheWeek(selection);
				weekFormatted = format(thisWeekMonday, "yyyy-MM-dd");
				params = {
					dateOfReportStart: thisWeekMonday,
					dateOfReportEnd: new Date(
						addSeconds(new Date(thisWeekSunday), 24 * 60 * 60 - 1)
					),
				};
				const date = payload.selection;
				currentDateTime = date.toISOString();
				datesOfTheWeekSerialized = generateDaysOfTheWeekSerialized(date);
			}

			try {
				const getAvailabilityByJob = axios.get(
					`${process.env.REACT_APP_API_END_POINT}/Reports/GetHourlyWorkerCount`,
					{
						params: params,
					}
				);
				const getAvailabilityBySkill = axios.get(
					`${process.env.REACT_APP_API_END_POINT}/Reports/GetHourlyWorkerCount`,
					{
						params: params,
					}
				);
				let allAPICalls = [];
				if (shiftBySkills && type === "skill") {
					params = { ...params, groupBy: "skill" };
					allAPICalls.push(getAvailabilityBySkill);
				} else {
					allAPICalls.push(getAvailabilityByJob);
				}

				const promisedValues = await Promise.allSettled(allAPICalls);
				const [workerAvailabilityByJob, workerAvailabilityBySkill] =
					promisedValues;

				let availabilityDataByJob;
				if (workerAvailabilityByJob.status === "fulfilled") {
					availabilityDataByJob = workerAvailabilityByJob?.value?.data;
				} else {
					availabilityDataByJob = [];
				}

				let availabilityDataBySkill;
				if (workerAvailabilityBySkill.status === "fulfilled") {
					availabilityDataBySkill = workerAvailabilityBySkill?.value?.data;
				} else {
					availabilityDataBySkill = [];
				}

				return thunkAPI.fulfillWithValue({
					availabilityDataByJob: availabilityDataByJob,
					availabilityDataBySkill: availabilityDataBySkill,
					monday: weekFormatted,
					currentDateTime: currentDateTime,
					datesOfTheWeekSerialized: datesOfTheWeekSerialized,
				});
			} catch (error) {
				// console.log(error.message);
				return thunkAPI.rejectWithValue(error.message);
			}
		} catch (e) {
			// Do nothing
		}
	}
);

export const changeWeekNoCache = createAsyncThunk(
	"scheduler/changeWeekNoCache",
	async (payload, { getState, fulfillWithValue, rejectWithValue }) => {
		let userId = getState().user.userData.id;

		const currentDate = new Date(
			getState().supervisor.schedulerData.currentDate
		);

		if (payload === "forward") {
			const oneWeekForward = addDays(currentDate, 7);

			const { monday: nextWeekMonday, sunday: nextWeekSunday } =
				generateDaysOfTheWeek(oneWeekForward);

			const nextWeekMondayFormatted = format(nextWeekMonday, "yyyy-MM-dd");
			//const nextWeekSundayFormatted = format(nextWeekSunday, "yyyy-MM-dd");

			try {
				const shiftsDataForNextWeek = await axios.get(
					`${process.env.REACT_APP_API_END_POINT}/Shifts/User/`,
					{
						params: {
							userId,
							startDate: nextWeekMonday,
							endDate: nextWeekSunday,
						},
					}
				);

				// console.log("res-forward", shiftsDataForNextWeek);

				const { jobs, workcenters, signupTracking } =
					shiftsDataForNextWeek.data;

				if (!jobs || !workcenters || !signupTracking) {
					return fulfillWithValue({
						status: "noShiftsFound",
						monday: nextWeekMondayFormatted,
						currentDateTime: oneWeekForward.toISOString(),
						datesOfTheWeekSerialized:
							generateDaysOfTheWeekSerialized(oneWeekForward),
					});
				}

				const shiftsWithSkills = addSkillsToShifts(shiftsDataForNextWeek.data);
				const shiftsWithDates = addStartDateEndDate(shiftsWithSkills);
				const shiftData = { jobs, workcenters, shiftsData: shiftsWithDates };

				return fulfillWithValue({
					status: "addDataToState",
					data: shiftData,
					monday: nextWeekMondayFormatted,
					currentDateTime: oneWeekForward.toISOString(),
					datesOfTheWeekSerialized:
						generateDaysOfTheWeekSerialized(oneWeekForward),
				});
			} catch (error) {
				return rejectWithValue(error.message);
			}
		} else if (payload === "back") {
			const oneWeekBack = addDays(currentDate, -7);

			const { monday: lastWeekMonday, sunday: lastWeekSunday } =
				generateDaysOfTheWeek(oneWeekBack);

			const lastWeekMondayFormatted = format(lastWeekMonday, "yyyy-MM-dd");
			//const lastWeekSundayFormatted = format(lastWeekSunday, "yyyy-MM-dd");
			// console.log(lastWeekMonday,lastWeekSunday);
			try {
				const shiftsDataForLastWeek = await axios.get(
					`${process.env.REACT_APP_API_END_POINT}/Shifts/User/`,
					{
						params: {
							userId,
							startDate: lastWeekMonday,
							endDate: lastWeekSunday,
						},
					}
				);

				//console.log("res--back",shiftsDataForLastWeek);

				const { jobs, workcenters, signupTracking } =
					shiftsDataForLastWeek.data;
				// console.log("signupTracking-4-", signupTracking);
				if (!jobs || !workcenters || !signupTracking) {
					// console.log(shiftsDataForLastWeek.data);
					return fulfillWithValue({
						status: "noShiftsFound",
						monday: lastWeekMondayFormatted,
						currentDateTime: oneWeekBack.toISOString(),
						datesOfTheWeekSerialized:
							generateDaysOfTheWeekSerialized(oneWeekBack),
					});
				}

				const shiftsWithSkills = addSkillsToShifts(shiftsDataForLastWeek.data);
				const shiftsWithDates = addStartDateEndDate(shiftsWithSkills);
				const shiftData = { jobs, workcenters, shiftsData: shiftsWithDates };

				return fulfillWithValue({
					status: "addDataToState",
					data: shiftData,
					monday: lastWeekMondayFormatted,
					currentDateTime: oneWeekBack.toISOString(),
					datesOfTheWeekSerialized:
						generateDaysOfTheWeekSerialized(oneWeekBack),
				});
			} catch (error) {
				return rejectWithValue(error.message);
			}
		} else if (payload === "today") {
			const today = new Date();
			const { monday: thisWeekMonday, sunday: thisWeekSunday } =
				generateDaysOfTheWeek(today);

			const thisWeekMondayFormatted = format(thisWeekMonday, "yyyy-MM-dd");

			try {
				const shiftDataForThisWeek = await axios.get(
					`${process.env.REACT_APP_API_END_POINT}/Shifts/User/`,
					{
						params: {
							userId,
							startDate: thisWeekMonday,
							endDate: thisWeekSunday,
						},
					}
				);
				const { jobs, workcenters, signupTracking } = shiftDataForThisWeek.data;
				// console.log("signupTracking-3-", signupTracking);
				if (!jobs || !workcenters || !signupTracking) {
					// console.log(shiftsDataForLastWeek.data);
					return fulfillWithValue({
						status: "noShiftsFound",
						monday: thisWeekMondayFormatted,
						currentDateTime: today.toISOString(),
						datesOfTheWeekSerialized: generateDaysOfTheWeekSerialized(today),
					});
				}

				const shiftsWithSkills = addSkillsToShifts(shiftDataForThisWeek.data);
				const shiftsWithDates = addStartDateEndDate(shiftsWithSkills);
				const shiftData = { jobs, workcenters, shiftsData: shiftsWithDates };
				//  console.log("respomse", userId,{startDate:thisWeekMonday},{endDate:thisWeekSunday},shiftDataForThisWeek);

				return fulfillWithValue({
					status: "addDataToState",
					data: shiftData,
					monday: thisWeekMondayFormatted,
					currentDateTime: today.toISOString(),
					datesOfTheWeekSerialized: generateDaysOfTheWeekSerialized(today),
				});
			} catch (error) {
				// console.log(error.message);
				return rejectWithValue(error.message);
			}
		} else {
			const today = payload;
			const { monday: thisWeekMonday, sunday: thisWeekSunday } =
				generateDaysOfTheWeek(today);

			const thisWeekMondayFormatted = format(thisWeekMonday, "yyyy-MM-dd");

			try {
				const shiftDataForThisWeek = await axios.get(
					`${process.env.REACT_APP_API_END_POINT}/Shifts/User/`,
					{
						params: {
							userId,
							startDate: thisWeekMonday,
							endDate: thisWeekSunday,
						},
					}
				);
				const { jobs, workcenters, signupTracking } = shiftDataForThisWeek.data;
				// console.log("signupTracking-3-", signupTracking);
				if (!jobs || !workcenters || !signupTracking) {
					// console.log(shiftsDataForLastWeek.data);
					return fulfillWithValue({
						status: "noShiftsFound",
						monday: thisWeekMondayFormatted,
						currentDateTime: today.toISOString(),
						datesOfTheWeekSerialized: generateDaysOfTheWeekSerialized(today),
					});
				}
				const shiftsWithSkills = addSkillsToShifts(shiftDataForThisWeek.data);
				const shiftsWithDates = addStartDateEndDate(shiftsWithSkills);
				const shiftData = { jobs, workcenters, shiftsData: shiftsWithDates };

				return fulfillWithValue({
					status: "addDataToState",
					data: shiftData,
					monday: thisWeekMondayFormatted,
					currentDateTime: today.toISOString(),
					datesOfTheWeekSerialized: generateDaysOfTheWeekSerialized(today),
				});
			} catch (error) {
				// console.log(error.message);
				return rejectWithValue(error.message);
			}
		}
	}
);

export const changeDayNoCache = createAsyncThunk(
	"scheduler/changeDayNoCache",
	async (payload, { getState, fulfillWithValue, rejectWithValue }) => {
		let userId = getState().user.userData.id;

		const currentDate = new Date(
			getState().supervisor.schedulerData.currentDate
		);

		if (payload === "forward") {
			const oneDayForward = addHours(currentDate, 24);
			const { monday: nextWeekMonday } = generateDaysOfTheWeek(oneDayForward);

			const nextWeekMondayFormatted = format(nextWeekMonday, "yyyy-MM-dd");

			try {
				const shiftsDataForNextWeek = await axios.get(
					`${process.env.REACT_APP_API_END_POINT}/Shifts/User/`,
					{
						params: {
							userId,
							startDate: currentDate,
							endDate: oneDayForward,
						},
					}
				);

				const { jobs, workcenters, signupTracking } =
					shiftsDataForNextWeek.data;
				if (!jobs || !workcenters || !signupTracking) {
					return fulfillWithValue({
						status: "noShiftsFound",
						monday: nextWeekMondayFormatted,
						currentDateTime: oneDayForward.toISOString(),
						datesOfTheWeekSerialized:
							generateDaysOfTheWeekSerialized(oneDayForward),
					});
				}

				const shiftsWithSkills = addSkillsToShifts(shiftsDataForNextWeek.data);
				const shiftsWithDates = addStartDateEndDate(shiftsWithSkills);
				const shiftData = { jobs, workcenters, shiftsData: shiftsWithDates };

				return fulfillWithValue({
					status: "addDataToState",
					data: shiftData,
					monday: nextWeekMondayFormatted,
					currentDateTime: oneDayForward.toISOString(),
					datesOfTheWeekSerialized:
						generateDaysOfTheWeekSerialized(oneDayForward),
				});
			} catch (error) {
				return rejectWithValue(error.message);
			}
		} else if (payload === "back") {
			const oneDayBack = addDays(currentDate, -1);
			const twoDayBack = addDays(oneDayBack, -1);

			const { monday: lastWeekMonday } = generateDaysOfTheWeek(oneDayBack);

			const lastWeekMondayFormatted = format(lastWeekMonday, "yyyy-MM-dd");
			//const lastWeekSundayFormatted = format(lastWeekSunday, "yyyy-MM-dd");
			// console.log(lastWeekMonday,lastWeekSunday);
			// console.log(currentDate,oneDayBack);
			try {
				const shiftsDataForLastWeek = await axios.get(
					`${process.env.REACT_APP_API_END_POINT}/Shifts/User/`,
					{
						params: {
							userId,
							startDate: twoDayBack,
							endDate: oneDayBack,
						},
					}
				);

				// console.log("res--back",shiftsDataForLastWeek);

				const { jobs, workcenters, signupTracking } =
					shiftsDataForLastWeek.data;
				// console.log("signupTracking-1-", signupTracking);
				if (!jobs || !workcenters || !signupTracking) {
					// console.log(shiftsDataForLastWeek.data);
					return fulfillWithValue({
						status: "noShiftsFound",
						monday: lastWeekMondayFormatted,
						currentDateTime: oneDayBack.toISOString(),
						datesOfTheWeekSerialized:
							generateDaysOfTheWeekSerialized(oneDayBack),
					});
				}

				const shiftsWithSkills = addSkillsToShifts(shiftsDataForLastWeek.data);
				const shiftsWithDates = addStartDateEndDate(shiftsWithSkills);
				const shiftData = { jobs, workcenters, shiftsData: shiftsWithDates };
				// console.log("shiftsWithDates",shiftsWithDates,shiftData);

				return fulfillWithValue({
					status: "addDataToState",
					data: shiftData,
					monday: lastWeekMondayFormatted,
					currentDateTime: oneDayBack.toISOString(),
					datesOfTheWeekSerialized: generateDaysOfTheWeekSerialized(oneDayBack),
				});
			} catch (error) {
				return rejectWithValue(error.message);
			}
		}
	}
);

export const getShiftDetails = createAsyncThunk(
	"scheduler/getShiftDetails",
	/**  @param payload {shiftId: number} */
	async (payload, thunkAPI) => {
		const shiftid = payload.shiftid;
		try {
			let response = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/Shifts/Workers`,
				{
					params: {
						shiftid,
					},
				}
			);
			// console.log("Shift Details: ", response);

			let data = response.data;

			if (response.status === 200) {
				return thunkAPI.fulfillWithValue({ data, shiftid });
			} else {
				return thunkAPI.rejectWithValue(response.error);
			}
		} catch (error) {
			// console.error(error.message);
			// TODO: Ask Junaid if 404 is still being returned
			// if it is not found then we are fulfilling the request
			if (error.message === "Request failed with status code 404")
				return thunkAPI.fulfillWithValue({ error: "No data found", shiftid });
			else return thunkAPI.rejectWithValue({ error: error.message });
		}
	}
);

export const getShiftAttendanceDetails = createAsyncThunk(
	"scheduler/getShiftAttendanceDetails",
	/**  @param payload {shiftId: number} */
	async (payload, thunkAPI) => {
		// console.log(payload);
		const shiftid = payload.shiftid;
		try {
			let response = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/Shifts/Attendance`,
				{
					params: {
						shiftid,
					},
				}
			);

			let data = response.data;

			if (response.status === 200) {
				return thunkAPI.fulfillWithValue({ data, shiftid });
			} else {
				return thunkAPI.rejectWithValue(response.error);
			}
		} catch (error) {
			console.error(error);
			return thunkAPI.rejectWithValue(error);
		}
	}
);

export const publishDraftShiftsAsync = createAsyncThunk(
	"scheduler/publishDraftShiftsAsync",
	/**  @param payload {state: number[]} */
	async (payload, thunkAPI) => {
		// console.log(payload);
		const shiftsToPublish = payload.toString();
		// console.log(typeof shiftsToPublish);

		if (payload.length > 0) {
			try {
				// let dataToSend = JSON.stringify(shiftsToPublish);
				// console.log(dataToSend);

				let response = await axios({
					method: "post",
					url: `${process.env.REACT_APP_API_END_POINT}/Shifts/Publish`,
					data: JSON.stringify(shiftsToPublish),
					// data: JSON.stringify("379"),
					headers: {
						"Content-Type": "application/json",
					},
				});

				// let response = await axios.post(
				//   "https://gigtest.azure-api.net/v2/api/Shifts/Publish",
				//   376,
				//   { headers: { "Content-Type": "application/json" } }
				// );

				// console.log(response);
				// let data = response.data;

				if (response.status === 204 || response.status === 200) {
					return thunkAPI.fulfillWithValue({
						shifts: payload,
						data: response.data,
					});
				} else {
					// console.log(response);
					return thunkAPI.rejectWithValue(response);
				}
			} catch (e) {
				// console.error("Error", e.response.data);
				return thunkAPI.rejectWithValue(e.response.data);
			}
		}
	}
);

export const checkAllowedToPublish = createAsyncThunk(
	"scheduler/checkAllowedToPublish",
	/**  @param payload {state: number[]} */
	async (payload, thunkAPI) => {
		const shiftsToPublish = payload.toString();

		if (payload.length > 0) {
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_API_END_POINT}/Shifts/CheckAllowedToPublish`,
					{
						params: {
							shiftIds: shiftsToPublish,
						},
					}
				);
				// console.log("CheckAllowedToPublish", response);
				// let data = response.data;

				if (response.status === 200) {
					return thunkAPI.fulfillWithValue(response.data);
				} else {
					// console.log(response);
					return thunkAPI.rejectWithValue(response);
				}
			} catch (e) {
				console.error("Error", e.response.data);
				return thunkAPI.rejectWithValue(e.response.data.message);
			}
		}
	}
);

export const createDraftShiftAsync = createAsyncThunk(
	"scheduler/createDraftShiftAsync",
	/** @param payload {copiedShiftData:any, copiedOn:boolean} */
	async (payload, thunkAPI) => {
		let shifts = [];

		const {
			repeatShift,
			repeatOn,
			copiedOn,
			copiedShiftData,
			startTime,
			endTime,
			jobId,
			shiftSkills,
			...otherfields
		} = payload;

		// console.log("payload", payload);

		otherfields.isPublished = false;
		const user = thunkAPI.getState().user.userData;
		otherfields.createdBy = user.id;

		let startDateTime = new Date(startTime);
		let endDateTime = new Date(endTime);
		otherfields.startDateTime = startDateTime;
		otherfields.endDateTime = endDateTime;

		// shifts.push(otherfields);

		if (repeatShift) {
			let startDateTime = otherfields?.startDateTime;
			let endDateTime = otherfields?.endDateTime;
			// ignore today, if true in repeated

			// for each just generate new shiftDate

			// Extracting days to repeat
			// daysToRepeat = ["0", "1"]
			let daysToRepeat = Object.entries(repeatOn).reduce((arr, entry) => {
				if (
					entry[1] === true
					// && entry[0] !== new Date(shiftDate).getDay().toString() // remvoing the current day from the repeat
				) {
					arr.push(entry[0]);
				}
				return arr;
			}, []);

			// console.log(daysToRepeat);

			const daysToRepeatInt = [];

			// Fixing Sunday bug
			if (daysToRepeat.includes("0")) {
				// console.log("contains sunday");
				daysToRepeat[0] = "7";
			}
			if (new Date(startDateTime).getDay() === 0) {
				// console.log("main day sunday");
				daysToRepeat.forEach((el) =>
					daysToRepeatInt.push(Number.parseInt(el) - 7)
				);
			} else {
				// console.log("main day not sunday");
				daysToRepeat.forEach((el) =>
					daysToRepeatInt.push(
						Number.parseInt(el) + 1 - (new Date(startDateTime).getDay() + 1)
					)
				);
			}
			// console.log(daysToRepeat);
			// console.log(daysToRepeatInt);

			daysToRepeatInt.forEach((el) => {
				let newShiftStartDate = add(new Date(startDateTime), {
					days: el,
				});
				let newShiftEndDate = add(new Date(endDateTime), {
					days: el,
				});
				const newShift = {
					...otherfields,
					// startDateTime: `${format(newShiftStartDate, "yyyy-MM-dd'T'HH:mm")}`,
					// endDateTime: `${format(newShiftEndDate, "yyyy-MM-dd'T'HH:mm")}`,
					createdBy: user.id,
					startDateTime: newShiftStartDate,
					endDateTime: newShiftEndDate,
					jobId: jobId === "" ? null : jobId,
					shiftSkills: shiftSkills,
				};

				shifts.push(newShift);
			});

			// console.log(shifts);
		} else if (copiedOn) {
			copiedShiftData.forEach((shift) => {
				const minuteDifference = differenceInMinutes(
					new Date(shift.startDateTime),
					new Date(startOfWeek(new Date(shift.startDateTime)))
				);

				const shiftMinuteDifference = differenceInMinutes(
					new Date(shift.endDateTime),
					new Date(shift.startDateTime)
				);
				let newShiftStartDateTime = addMinutes(
					new Date(startOfWeek(new Date(shift.pasteDateStart))),
					minuteDifference
				);
				newShiftStartDateTime = isSunday(new Date(shift.startDateTime))
					? addWeeks(new Date(newShiftStartDateTime), 1)
					: newShiftStartDateTime;

				let newShiftEndDateTime = addMinutes(
					new Date(newShiftStartDateTime),
					shiftMinuteDifference
				);
				const newShift = {
					...shift,
					// startDateTime: `${format(newShiftStartDate, "yyyy-MM-dd'T'HH:mm")}`,
					// endDateTime: `${format(newShiftEndDate, "yyyy-MM-dd'T'HH:mm")}`,
					shiftNote: "",
					startDateTime: newShiftStartDateTime,
					endDateTime: newShiftEndDateTime,
					createdBy: user.id,
					isPublished: false,
				};

				shifts.push(newShift);
			});
		} else {
			shifts.push({
				...otherfields,
				jobId: jobId === "" ? null : jobId,
				shiftSkills: shiftSkills,
			});
		}

		// Pushing draft shifts to the server here

		try {
			const response = await axios.post(
				`${process.env.REACT_APP_API_END_POINT}/Shifts/Create`,
				shifts
			);

			let data = response.data;
			// console.log("data", response);
			if (response.status === 201) {
				const shifts = [];
				data.forEach((shift) => {
					let shiftWithDateTime = addStartDateEndDateToAShift(shift);
					shiftWithDateTime = {
						...shiftWithDateTime,
						createdByName: user.firstName + " " + user.lastName,
						skills: shift.shiftSkills,
					};
					shifts.push(shiftWithDateTime);
				});

				return thunkAPI.fulfillWithValue(shifts);
			} else {
				return thunkAPI.rejectWithValue({ error: response.data });
			}
		} catch (e) {
			const err = e.response.data.message;
			const { setSubmitting } = payload;
			if (setSubmitting) {
				setSubmitting(false);
			}
			return thunkAPI.rejectWithValue({
				error: err,
			});
		}
	}
);

export const deleteDraftShiftAsync = createAsyncThunk(
	"scheduler/deleteDraftShiftAsync",
	async (payload, thunkAPI) => {
		// console.log(payload);
		try {
			const response = await axios.delete(
				`${process.env.REACT_APP_API_END_POINT}/Shifts/Delete`,
				{
					params: {
						shiftId: payload,
					},
				}
			);
			// console.log(response);

			if (response.status === 204 || response.status === 200)
				return thunkAPI.fulfillWithValue(payload);
			else return thunkAPI.rejectWithValue({ error: response.data });
		} catch (e) {
			// console.log(e.response.data);
			return thunkAPI.rejectWithValue({ error: e.response.data });
		}
	}
);

export const renotifyWorkers = createAsyncThunk(
	"scheduler/renotifyWorkers",
	/**  @param payload {workers: any, shiftId: number} */
	async ({ workers, shiftId }, thunkAPI) => {
		// let r = JSON.stringify({ workerids: workers, shiftId })
		// console.log(r);

		workers = workers.toString();
		shiftId = shiftId.toString();

		try {
			let response = await axios({
				method: "post",
				url: `${process.env.REACT_APP_API_END_POINT}/Workers/Renotify`,
				data: JSON.stringify({ workerids: workers, shiftId }),
				headers: {
					"Content-Type": "application/json",
				},
			});
			// const response = await axios.post(
			//   "https://gatstag.azure-api.net/stag/api/Workers/Renotify",
			//   payload
			// );
			// console.log(response);

			if (response.status === 200) return thunkAPI.fulfillWithValue(workers);
			else return thunkAPI.rejectWithValue(response.message);
		} catch (error) {
			console.error(error);
			return thunkAPI.rejectWithValue(error.message);
		}
	}
);

export const assignWorkers = createAsyncThunk(
	"scheduler/assignWorkers",
	/**  @param payload {workers: string[], shiftId: number} */
	async ({ workers, shiftId }, thunkAPI) => {
		try {
			let response = await axios({
				method: "post",
				url: `${process.env.REACT_APP_API_END_POINT}/Shifts/AssignWorkers`,
				data: JSON.stringify({ workerIds: workers, shiftId: shiftId }),
				headers: {
					"Content-Type": "application/json",
				},
			});

			if (response.status === 200)
				return thunkAPI.fulfillWithValue(response.data);
			else return thunkAPI.rejectWithValue(response.message);
		} catch (error) {
			console.error(error);
			return thunkAPI.rejectWithValue(error.message);
		}
	}
);

export const markAttendance = createAsyncThunk(
	"scheduler/markAttendance",
	/**  @param payload {shiftId: number, workerId: number, status: string} */
	async (payload, thunkAPI) => {
		// console.log("payload",payload)
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_API_END_POINT}/Workers/Attend`,
				payload
			);
			//			console.log("pay", response);
			if (response.status === 200) return thunkAPI.fulfillWithValue(payload);
			return thunkAPI.rejectWithValue(response.data.message);
		} catch (error) {
			// console.log("err", error, error.response);
			return thunkAPI.rejectWithValue(error.response.data.message);
		}
	}
);

export const editDraftShift = createAsyncThunk(
	"scheduler/editDraftShift",
	async (payload, { fulfillWithValue, rejectWithValue, getState }) => {
		const { shiftId, values, shiftPublished, currentShift } = payload;
		const {
			startTime,
			endTime,
			jobId,
			numberOfWorkersNeeded,
			workCenterId,
			shiftPremium,
			shiftSkills,
			shiftNote,
		} = values;

		const { shiftViews, slotsFilled } = currentShift;

		const startDateTime = new Date(startTime);
		const endDateTime = new Date(endTime);

		const user = getState().user.userData.id;

		const newShift = {
			startDateTime,
			endDateTime,
			id: shiftId,
			jobId: jobId === "" ? null : jobId,
			workCenterId: Number.parseInt(workCenterId),
			numberOfWorkersNeeded,
			shiftViews,
			slotsFilled,
			shiftPremium,
			isPublished: shiftPublished,
			isActive: true,
			updatedBy: user,
			shiftSkills,
			shiftNote,
		};

		try {
			const response = await axios.put(
				`${process.env.REACT_APP_API_END_POINT}/Shifts/Put`,
				newShift
			);

			if (response.status === 204) {
				const res = {
					...newShift,
					startDate: newShift.startDateTime,
					endDate: newShift.endDateTime,
				};
				return fulfillWithValue(res);
			} else {
				return rejectWithValue(response);
			}
		} catch (error) {
			let { setSubmitting } = payload;
			setSubmitting(false);
			// console.log(error.response);
			return rejectWithValue(error.response.data.message);
		}
	}
);

export const editPublishedShift = createAsyncThunk(
	"scheduler/editPublishedShift",
	() => {
		// console.log("editPublishedShift");
	}
);

export const sendBroadcastMessage = createAsyncThunk(
	"scheduler/broadcastMessage",
	/**  @param payload {message: string, jobIds: string, locationIds: string,workCenterIds:string, reqType: string} */
	async (payload, { fulfillWithValue, rejectWithValue }) => {
		const { message, jobs, locationIds, workCenterIds, reqType } = payload;
		// let jobIds = Object.keys(jobs).reduce((acc, key) => {
		// 	if (jobs[key] === true) acc.push(key);
		// 	return acc;
		// }, []);

		try {
			const response = await axios.post(
				`${process.env.REACT_APP_API_END_POINT}/Workers/Broadcast`,
				{
					message: message ?? "",
					jobIds: jobs,
					locationIds: locationIds,
					workCenterIds: workCenterIds,
					reqType: reqType,
				}
			);
			if (response.status === 200) {
				return fulfillWithValue({ workers: response.data, reqType });
			} else {
				return rejectWithValue({ error: response.data, reqType });
			}
		} catch (error) {
			return rejectWithValue({ error: error.response.data, reqType });
		}
	}
);

export const fetchBroadcastHistory = createAsyncThunk(
	"scheduler/fetchBroadcastHistory",
	async (payload, thunkAPI) => {
		try {
			let response = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/companies/broadcasts`
			);

			if (response.status === 200) {
				return thunkAPI.fulfillWithValue(response.data);
			}
			return thunkAPI.rejectWithValue(response.error);
		} catch (error) {
			console.error(error);
			return thunkAPI.rejectWithValue(error);
		}
	}
);

export const fetchSkillLevels = createAsyncThunk(
	"manageWorkers/skillLevels",
	async (payload, { fulfillWithValue, rejectWithValue }) => {
		try {
			const getSkillLevels = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/Skill/Levels`
			);
			return fulfillWithValue(getSkillLevels.data);
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

// API to get all Managers
export const getCompanyManagers = createAsyncThunk(
	"manageWorkers/getCompanyManagers",
	async (payload, { fulfillWithValue, rejectWithValue }) => {
		try {
			const organizations = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/companies/${payload}/users`
			);
			return fulfillWithValue(organizations.data);
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

// API to get Locations with Cluster/Pool respected to user
export const locationsWithClusterAsync = createAsyncThunk(
	"scheduler/locationsWithClusterAsync",
	async (payload, { fulfillWithValue, rejectWithValue, getState }) => {
		const userId = getState().user.userData.id;
		try {
			const locations = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/Locations/GetLocationsWithPools?userid=${userId}`
			);
			return fulfillWithValue(locations.data);
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const ShiftSchedulerSlice = createSlice({
	name: "supervisor",
	initialState: {
		// contains all the scheduler data
		schedulerData: {
			// contains shifts data
			shiftsData: [],
			jobRoles: [],
			workcenters: [],
			locations: [],
			status: "idle",
			errorMessage: "",
			filteredShiftsData: [],
			weekShiftsData: [],
			datesOfTheWeek: {},
			weeklyJobsTimeSlots: {},
			allManagers: [],
		},
		masterData: {
			allJobRoles: [],
			allLocations: [],
			allWorkcenters: [],
			shiftsData: {},
			availabilityDataByJob: [],
			availabilityDataBySkill: [],
			allSkills: [],
			allSkillLevels: [],
			allManagers: [],
		},
		// contains detailed data (accepted, rejected, viewed) for selected shifts
		shiftDetails: {
			data: {},
			status: "idle",
			errorMessage: "",
		},

		shiftAttendanceDetails: {
			data: {},
			status: "idle",
			errorMessage: "",
		},
		shiftDetailsDialog: {
			shiftid: null,
		},
		createDraftShiftAsync: {
			status: "idle",
			errorMessage: "",
		},
		publishDraftShiftsAsync: {
			status: "idle",
			errorMessage: "",
		},
		checkAllowedToPublish: {
			status: "idle",
			errorMessage: "",
			data: [],
		},
		deleteDraftShiftAsync: {
			status: "idle",
			errorMessage: "",
		},
		fetchInitialData: {
			status: "idle",
			errorMessage: "",
		},
		broadcastMessage: {
			status: "idle",
			errorMessage: "",
		},
		broadcastWorkers: {
			workers: 0,
			status: "idle",
			errorMessage: "",
		},
		broadcastHistory: {
			data: [],
			status: "idle",
			errorMessage: "",
		},
		changeWeek: {
			status: "idle",
			errorMessage: "",
		},
		changeWeekNoCache: {
			status: "idle",
			errorMessage: "",
		},
		changeWeeklyAvailability: {
			status: "idle",
			errorMessage: "",
		},
		changeDayNoCache: {
			status: "idle",
			errorMessage: "",
		},
		fetchAllJobRoles: {
			status: "idle",
			errorMessage: "",
		},
		fetchAllWorkcenters: {
			status: "idle",
			errorMessage: "",
		},
		fetchAllLocations: {
			status: "idle",
			errorMessage: "",
		},
		renotifyWorkers: {
			status: "idle",
			errorMessage: "",
		},
		assignWorkers: {
			status: "idle",
			errorMessage: "",
			response: [],
		},
		markAttendance: {
			status: "idle",
			errorMessage: "",
		},
		editDraftShiftDialog: {
			isOpen: false,
			shiftId: null,
		},
		editDraftShift: {
			status: "idle",
			errorMessage: "",
		},
		resetDemo: {
			status: "idle",
			errorMessage: "",
		},
		weekShifts: {
			status: "idle",
			errorMessage: "",
		},
		getCompanyManagers: {
			status: "idle",
			errorMessage: "",
		},
		locationsWithClusterAsync: {
			status: "idle",
			errorMessage: "",
		},
		getHourlyWorkerCount: {
			status: "idle",
			errorMessage: "",
		},
		locationsWithCluster: [],
		weekView: true,
		shiftsByFilter: JOB,
	},
	reducers: {
		// createDraftShift(state, action) {
		//   state.shiftsData.push(action.payload);
		// },
		createDraftShift: {
			reducer: (state, action) => {
				state.schedulerData.shiftsData.push(action.payload);
			},
			prepare: (shiftsData) => {
				let startDate = `${shiftsData.shiftDate}T${shiftsData.startTime}:00.000Z`;
				let endDate = `${shiftsData.shiftDate}T${shiftsData.endTime}:00.000Z`;
				// console.log(startDate, endDate);
				return {
					payload: {
						...shiftsData,
						startDate,
						endDate,
						title: "New Shift",
						id: 99,
						color: "b5b5b5",
					},
				};
			},
		},
		setFilteredShifts: (state, action) => {
			state.schedulerData.filteredShiftsData = action.payload;
		},
		setShiftsByFilter: (state, action) => {
			state.shiftsByFilter = action.payload;
		},
		setFilteredWeekShifts: (state, action) => {
			state.schedulerData.filteredShiftsWeekData = action.payload;
		},
		// @ts-expect-error TS2554: Expected 1 arguments, but got 0
		resetCreateDraftShiftAsyncState: resetCreateDraftShiftAsyncStateReducer, // Suppress TS2554
		resetPublishDraftShiftsState: resetPublishDraftShiftsStateReducer,
		resetBroadcastMessageState: resetBroadcastMessageStateReducer,
		resetDeleteDraftShiftAsync: resetDeleteDraftShiftAsyncReducer,
		resetRenotifyWorkers: resetRenotifyWorkersReducer,
		resetAssignWorkers: (state) => {
			state.assignWorkers.status = "idle";
			state.assignWorkers.errorMessage = "";
		},
		resetAssignWorkersResponse: (state) => {
			state.assignWorkers.response = [];
		},
		resetEditDraftShift: resetEditDraftShiftReducer,
		editDraftShiftDialogOpen: editDraftShiftDialogOpenReducer,
		editDraftShiftDialogClose: editDraftShiftDialogCloseReducer,
		resetMarkAttendance: resetMarkAttendanceReducer,
		switchCalendarView: (state, action) => {
			state.weekView = action.payload;
		},
		resetBroadcastWorkerCount: (state, action) => {
			state.broadcastWorkers.workers = 0;
			state.broadcastWorkers.status = "idle";
			state.broadcastWorkers.errorMessage = "";
		},
		// goToNextWeek: goToNextWeekReducer,
		// goToPreviousWeek: goToPreviousWeekReducer,
		// goToThisWeek: goToThisWeekReducer,
		// setShiftDetailsDialogId: (state, action) => {
		//   state.shiftDetailsDialog.shiftid = action.payload;
		// },
	},
	extraReducers: {
		[publishDraftShiftsAsync.fulfilled]: (state, action) => {
			let shiftsToPublish = action.payload.shifts;
			const data = action.payload.data;
			shiftsToPublish.forEach((shiftId) => {
				const shiftData = data.find(
					(shift) => Number(shift.shiftId) === shiftId
				);
				// find the object in the array
				let index = state.schedulerData.shiftsData.findIndex(
					(el) => el.id === shiftId
				);
				// Replacing existing shiftsData to new array to trigger re-rendering
				let newShiftsData = [...state.schedulerData.shiftsData];
				newShiftsData[index].isPublished = true;
				newShiftsData[index].slotsFilled = 0;
				newShiftsData[index].shiftAllocationType =
					shiftData.shiftAllocationType;
				newShiftsData[index].windowClosingTime =
					shiftData.windowClosingTime ?? null;
				state.schedulerData.shiftsData = newShiftsData;
			});
			state.publishDraftShiftsAsync.status = "fulfilled";
		},
		[publishDraftShiftsAsync.pending]: (state) => {
			// console.log("shift creation initiated");
			state.publishDraftShiftsAsync.status = "pending";
		},
		[publishDraftShiftsAsync.rejected]: (state, action) => {
			state.publishDraftShiftsAsync.status = "error";
			state.publishDraftShiftsAsync.errorMessage =
				action.payload?.error ?? "An error occurred. Try again.";
		},
		[checkAllowedToPublish.fulfilled]: (state, action) => {
			const temp = action.payload?.reduce((acc, el) => {
				acc[el.shiftId] = el;
				return acc;
			}, {});
			state.checkAllowedToPublish.data = temp ?? {};
			state.checkAllowedToPublish.status = "fulfilled";
		},
		[checkAllowedToPublish.pending]: (state) => {
			// console.log("shift creation initiated");
			state.checkAllowedToPublish.status = "pending";
		},
		[checkAllowedToPublish.rejected]: (state, action) => {
			state.checkAllowedToPublish.status = "error";
			state.checkAllowedToPublish.errorMessage =
				action.payload?.error ?? "An error occurred. Try again.";
		},
		[getShiftDetails.fulfilled]: (state, action) => {
			// console.log(action.payload);
			const shiftid = action.payload.shiftid;
			const error = action.payload?.error;
			const data = action.payload?.data;

			if (error) {
				// for 404 error states we add empty brackets to the shiftdetails
				state.shiftDetails.data[shiftid] = {};
			} else {
				state.shiftDetails.data[shiftid] = data;
			}
			state.shiftDetails.status = "fulfilled";
		},
		[getShiftDetails.pending]: (state) => {
			// set status to pending
			state.shiftDetails.status = "pending";
		},
		[getShiftDetails.rejected]: (state) => {
			// set status to error
			state.shiftDetails.status = "error";
			// TODO: add error message state
		},
		//Attendance
		[getShiftAttendanceDetails.fulfilled]: (state, action) => {
			// console.log(action.payload);
			const shiftid = action.payload.shiftid;
			const error = action.payload?.error;
			const data = action.payload?.data;

			if (error) {
				// for 404 error states we add empty brackets to the shiftdetails
				state.shiftAttendanceDetails.data[shiftid] = {};
			} else {
				state.shiftAttendanceDetails.data[shiftid] = data;
			}
			state.shiftAttendanceDetails.status = "fulfilled";
		},
		[getShiftAttendanceDetails.pending]: (state) => {
			// set status to pending
			state.shiftAttendanceDetails.status = "pending";
		},
		[getShiftAttendanceDetails.rejected]: (state) => {
			// set status to error
			state.shiftAttendanceDetails.status = "error";
			// TODO: add error message state
		},
		//week shifts
		[fetchWeekShifts.fulfilled]: (state, action) => {
			state.schedulerData.weekShiftsData = action.payload;
			state.weekShifts.status = "fulfilled";
		},
		[fetchWeekShifts.pending]: (state, action) => {
			state.weekShifts.status = "pending";
		},
		[fetchWeekShifts.rejected]: (state, action) => {
			state.weekShifts.errorMessage = "An error occurred. Try again.";
		},
		[createDraftShiftAsync.fulfilled]: (state, action) => {
			// add new draft shift returned from the server to redux state
			const shifts = action.payload;
			let newShiftsData = [...state.schedulerData.shiftsData];
			newShiftsData = newShiftsData.concat(shifts);
			state.schedulerData.shiftsData = newShiftsData;
			state.createDraftShiftAsync.status = "fulfilled";
			// state.createDraftShiftAsync.status = "idle";
		},
		[createDraftShiftAsync.pending]: (state) => {
			// add loading state to redux
			state.createDraftShiftAsync.status = "pending";
		},
		[createDraftShiftAsync.rejected]: (state, action) => {
			// display and handle error states
			//console.log("action", action.payload);
			state.createDraftShiftAsync.status = "error";
			state.createDraftShiftAsync.errorMessage =
				action.payload?.error ?? "An error occurred";
		},
		[deleteDraftShiftAsync.fulfilled]: (state, action) => {
			const shiftid = action.payload;
			// Copying the shiftsData
			let shiftsData = state.schedulerData.shiftsData;
			// Deleting the shift
			shiftsData = shiftsData.filter((shift) => shift.id !== shiftid);
			// Putting the shiftsData back to the state
			state.schedulerData.shiftsData = shiftsData;
			state.deleteDraftShiftAsync.status = "fulfilled";
		},
		[deleteDraftShiftAsync.pending]: (state) => {
			state.deleteDraftShiftAsync.status = "pending";
		},
		[deleteDraftShiftAsync.rejected]: (state, action) => {
			state.deleteDraftShiftAsync.status = "error";
			state.deleteDraftShiftAsync.errorMessage =
				action.payload?.error?.message ?? "An error occurred";
		},
		[fetchInitialData.pending]: (state) => {
			state.fetchInitialData.status = "pending";
		},
		[fetchInitialData.fulfilled]: (state, action) => {
			// console.log(action);
			let {
				allDataForTheWeek,
				workcentersData,
				jobRolesData,
				//locationsData,
				datesOfTheWeekSerialized,
				currentDateTime,
				dataMonday,
				skillsData,
				skillLevelsData,
			} = action.payload;
			// console.log(allDataForTheWeek, workcentersData);
			state.schedulerData.shiftsData = allDataForTheWeek.shiftsData;
			state.schedulerData.jobRoles = allDataForTheWeek.jobs;
			state.schedulerData.workcenters = allDataForTheWeek.workcenters;
			//state.schedulerData.locations = locationsData;

			state.schedulerData.datesOfTheWeek = datesOfTheWeekSerialized;
			state.schedulerData.currentDate = currentDateTime;

			// Caching the data
			state.masterData.shiftsData[dataMonday] = {
				shiftsData: allDataForTheWeek.shiftsData,
				jobs: allDataForTheWeek.jobs,
				workcenters: allDataForTheWeek.workcenters,
			};
			state.masterData.allWorkcenters = workcentersData ?
				workcentersData.map(
					el => ({
						...el,
						nameWithLocation: `${el.location.name} - ${el.name}`,
					})
				) : [];
			state.masterData.allJobRoles = jobRolesData;

			//state.masterData.allLocations = locationsData;
			state.masterData.allSkills = skillsData
				.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
				.map((el) => ({ ...el, isActive: !el.isObsolete }));
			state.masterData.allSkillLevels = skillLevelsData;

			state.schedulerData.weeklyJobsTimeSlots =
				allDataForTheWeek.shiftsData.reduce((acc, shift) => {
					const job = shift.jobId;
					const minutes = differenceInMinutes(
						new Date(shift.endDateTime),
						new Date(shift.startDateTime)
					);
					acc[job] = acc[job] ? acc[job] + minutes : minutes;
					return acc;
				}, {});

			state.fetchInitialData.status = "fulfilled";
		},
		[fetchInitialData.rejected]: (state, action) => {
			state.fetchInitialData.status = "error";
			state.fetchInitialData.errorMessage = action.payload;
		},
		[changeWeek.pending]: (state) => {
			// set to loading
			state.changeWeek.status = "pending";
		},
		[changeWeek.fulfilled]: (state, action) => {
			const {
				status,
				data,
				monday,
				currentDateTime,
				datesOfTheWeekSerialized,
			} = action.payload;
			if (status === "addDataToState") {
				// console.log("addDataToState");
				state.masterData.shiftsData[monday] = data;

				state.schedulerData.shiftsData = data.shiftsData;
				state.schedulerData.jobRoles = data.jobs;
				state.schedulerData.workcenters = data.workcenters;
				state.schedulerData.datesOfTheWeek = datesOfTheWeekSerialized;
				state.schedulerData.currentDate = currentDateTime;

				state.changeWeek.status = "fulfilled";
			}

			if (status === "replaceDataFromMaster") {
				// console.log("replaceDataFromMaster");
				state.schedulerData.shiftsData =
					state.masterData.shiftsData[monday]["shiftsData"];
				state.schedulerData.jobRoles =
					state.masterData.shiftsData[monday]["jobs"];
				state.schedulerData.workcenters =
					state.masterData.shiftsData[monday]["workcenters"];

				state.schedulerData.datesOfTheWeek = datesOfTheWeekSerialized;
				state.schedulerData.currentDate = currentDateTime;

				state.changeWeek.status = "fulfilled";
			}

			if (status === "noShiftsFound") {
				// console.log("noShiftsFound");
				state.filteredShiftsData = [];
				state.schedulerData.shiftsData = [];
				state.schedulerData.jobRoles = [];
				state.schedulerData.workcenters = [];
				state.schedulerData.datesOfTheWeek = datesOfTheWeekSerialized;
				state.schedulerData.currentDate = currentDateTime;

				state.masterData.shiftsData[monday] = {};
				state.masterData.shiftsData[monday]["jobs"] = [];
				state.masterData.shiftsData[monday]["workcenters"] = [];
				state.masterData.shiftsData[monday]["shiftsData"] = [];

				state.changeWeek.status = "fulfilled";
			}
		},
		[changeWeek.rejected]: (state, action) => {
			state.changeWeek.status = "error";
			state.changeWeek.errorMessage = action.payload ?? "An error occurred";
		},
		[changeWeekNoCache.pending]: (state) => {
			state.changeWeekNoCache.status = "pending";
		},
		[changeWeekNoCache.fulfilled]: (state, action) => {
			// console.log("test",action.payload)
			const { status, data, currentDateTime, datesOfTheWeekSerialized } =
				action.payload;
			if (status === "addDataToState") {
				// console.log("addDataToState");
				state.schedulerData.shiftsData = data.shiftsData;
				state.schedulerData.jobRoles = data.jobs;
				state.schedulerData.workcenters = data.workcenters;
				state.schedulerData.datesOfTheWeek = datesOfTheWeekSerialized;
				state.schedulerData.currentDate = currentDateTime;

				state.schedulerData.weeklyJobsTimeSlots = data.shiftsData.reduce(
					(acc, shift) => {
						const job = shift.jobId;
						const minutes = differenceInMinutes(
							new Date(shift.endDateTime),
							new Date(shift.startDateTime)
						);
						acc[job] = acc[job] ? acc[job] + minutes : minutes;
						return acc;
					},
					{}
				);

				state.changeWeekNoCache.status = "fulfilled";
			}

			if (status === "noShiftsFound") {
				// console.log("noShiftsFound");
				state.filteredShiftsData = [];

				state.schedulerData.shiftsData = [];
				state.schedulerData.jobRoles = [];
				state.schedulerData.workcenters = [];
				state.schedulerData.datesOfTheWeek = datesOfTheWeekSerialized;
				state.schedulerData.currentDate = currentDateTime;

				state.schedulerData.weeklyJobsTimeSlots = {};

				state.changeWeekNoCache.status = "fulfilled";
			}
		},
		[changeWeekNoCache.rejected]: (state, action) => {
			state.changeWeekNoCache.status = "error";
			state.changeWeekNoCache.errorMessage =
				action.payload ?? "An error occurred";
		},
		//change day
		[changeDayNoCache.pending]: (state) => {
			state.changeDayNoCache.status = "pending";
		},
		[changeDayNoCache.fulfilled]: (state, action) => {
			//console.log("test",action.payload)
			const { status, data, currentDateTime, datesOfTheWeekSerialized } =
				action.payload;
			if (status === "addDataToState") {
				// console.log("addDataToState");
				state.schedulerData.shiftsData = data.shiftsData;
				state.schedulerData.jobRoles = data.jobs;
				state.schedulerData.workcenters = data.workcenters;
				state.schedulerData.datesOfTheDay = datesOfTheWeekSerialized;
				state.schedulerData.currentDate = currentDateTime;
				state.schedulerData.datesOfTheWeek = datesOfTheWeekSerialized;

				state.changeDayNoCache.status = "fulfilled";
			}

			if (status === "noShiftsFound") {
				//  console.log("noShiftsFound");
				state.filteredShiftsData = [];

				state.schedulerData.shiftsData = [];
				state.schedulerData.jobRoles = [];
				state.schedulerData.workcenters = [];
				state.schedulerData.datesOfTheDay = datesOfTheWeekSerialized;
				state.schedulerData.currentDate = currentDateTime;

				state.changeDayNoCache.status = "fulfilled";
			}
		},
		[changeDayNoCache.rejected]: (state, action) => {
			state.changeDayNoCache.status = "error";
			state.changeDayNoCache.errorMessage =
				action.payload ?? "An error occurred";
		},
		// fetch availability
		[changeWeeklyAvailability.fulfilled]: (state, action) => {
			const { currentDateTime, datesOfTheWeekSerialized } = action.payload;
			if (action.payload.availabilityDataBySkill) {
				state.masterData.availabilityDataBySkill =
					action.payload.availabilityDataBySkill;
			}
			if (action.payload.availabilityDataByJob) {
				state.masterData.availabilityDataByJob =
					action.payload.availabilityDataByJob;
			}

			state.schedulerData.datesOfTheWeek = datesOfTheWeekSerialized;
			state.schedulerData.currentDate = currentDateTime;

			state.changeWeeklyAvailability.status = "fulfilled";
		},
		[changeWeeklyAvailability.pending]: (state) => {
			state.changeWeeklyAvailability.status = "pending";
		},
		[changeWeeklyAvailability.rejected]: (state, action) => {
			state.changeWeeklyAvailability.status = "error";
			state.changeWeeklyAvailability.errorMessage =
				action.payload ?? "An error occurred";
		},
		[fetchAllJobRoles.pending]: (state) => {
			state.fetchAllJobRoles.status = "pending";
		},
		[fetchAllJobRoles.rejected]: (state, action) => {
			state.fetchAllJobRoles.status = "error";
			state.fetchAllJobRoles.errorMessage =
				action.payload ?? "An error occurred";
		},
		//---workcenters
		[fetchAllWorkcenters.fulfilled]: (state, action) => {
			state.fetchAllWorkcenters.status = "fulfilled";
			state.masterData.allWorkcenters = action.payload ? action.payload.map(
				el => ({
					...el,
					nameWithLocation: `${el.location.name} - ${el.name}`,
				})
			) : [];
		},
		[fetchAllWorkcenters.pending]: (state) => {
			state.fetchAllWorkcenters.status = "pending";
		},
		[fetchAllWorkcenters.rejected]: (state, action) => {
			state.fetchAllWorkcenters.status = "error";
			state.fetchAllWorkcenters.errorMessage =
				action.payload ?? "An error occurred";
		},

		//---locations
		[fetchAllLocations.fulfilled]: (state, action) => {
			state.fetchAllLocations.status = "fulfilled";
			state.masterData.allLocations = action.payload;
		},
		[fetchAllLocations.pending]: (state) => {
			state.fetchAllLocations.status = "pending";
		},
		[fetchAllLocations.rejected]: (state, action) => {
			state.fetchAllLocations.status = "error";
			state.fetchAllLocations.errorMessage =
				action.payload ?? "An error occurred";
		},

		[fetchAllJobRoles.fulfilled]: (state, action) => {
			state.fetchAllJobRoles.status = "fulfilled";
			state.masterData.allJobRoles = action.payload;
		},

		[renotifyWorkers.pending]: (state) => {
			state.renotifyWorkers.status = "pending";
		},
		[renotifyWorkers.rejected]: (state, action) => {
			state.renotifyWorkers.status = "error";
			state.renotifyWorkers.errorMessage = action.payload ?? "An error occurred";
		},
		[renotifyWorkers.fulfilled]: (state) => {
			state.renotifyWorkers.status = "fulfilled";
		},
		[assignWorkers.pending]: (state) => {
			state.assignWorkers.status = "pending";
		},
		[assignWorkers.rejected]: (state, action) => {
			state.assignWorkers.status = "error";
			state.assignWorkers.errorMessage = action.payload ?? "An error occurred";
			state.assignWorkers.response = [];
		},
		[assignWorkers.fulfilled]: (state, action) => {
			state.assignWorkers.status = "fulfilled";
			state.assignWorkers.errorMessage = "";
			state.assignWorkers.response = action.payload ?? [];
		},
		//attendance
		[markAttendance.pending]: (state) => {
			state.markAttendance.status = "pending";
		},
		[markAttendance.rejected]: (state, action) => {
			state.markAttendance.status = "error";
			state.markAttendance.errorMessage = action.payload ?? "An error occurred";
		},
		[markAttendance.fulfilled]: (state) => {
			state.markAttendance.status = "fulfilled";
		},
		[editDraftShift.pending]: (state) => {
			state.editDraftShift.status = "pending";
		},
		[editDraftShift.rejected]: (state, action) => {
			state.editDraftShift.status = "error";
			state.editDraftShift.errorMessage = action.payload ?? "An error occurred";
		},
		[editDraftShift.fulfilled]: (state, action) => {
			const shiftId = action.payload?.id;

			let index = state.schedulerData.shiftsData.findIndex(
				(el) => el.id === shiftId
			);

			// Copying the shiftsData
			let shiftsData = state.schedulerData.shiftsData;
			// get createdByName
			const createdByName = shiftsData[index].createdByName;
			const shiftAllocationType = shiftsData[index].shiftAllocationType;
			const windowClosingTime = shiftsData[index].windowClosingTime;
			// Deleting the shift
			shiftsData.splice(index, 1);
			// Adding new shift data
			const newShiftData = {
				...action.payload,
				skills: action.payload.shiftSkills,
				jobId: action.payload.jobId ? action.payload.jobId : 0,
				createdByName,
				shiftAllocationType,
				windowClosingTime,
			};
			shiftsData.push(newShiftData);
			// Putting the shiftsData back to the state
			state.schedulerData.shiftsData = shiftsData;
			state.editDraftShiftDialog.isOpen = false;
			state.editDraftShift.status = "fulfilled";
		},
		//broadcast message
		[sendBroadcastMessage.pending]: (state, action) => {
			if (action.meta.arg.reqType === "workercount") {
				state.broadcastWorkers.status = "pending";
			} else {
				state.broadcastMessage.status = "pending";
			}
		},
		[sendBroadcastMessage.rejected]: (state, action) => {
			state.broadcastMessage.status = "error";
			state.broadcastMessage.errorMessage =
				action.payload ?? "An error occurred";
		},
		[sendBroadcastMessage.fulfilled]: (state, action) => {
			if (action.payload.reqType === "workercount") {
				state.broadcastWorkers.workers = action.payload.workers;
				state.broadcastWorkers.status = "fulfilled";
			} else {
				state.broadcastMessage.status = "fulfilled";
			}
		},

		//broadcast history

		[fetchBroadcastHistory.pending]: (state) => {
			state.broadcastHistory.status = "pending";
		},
		[fetchBroadcastHistory.rejected]: (state, action) => {
			state.broadcastHistory.status = "error";
			state.broadcastHistory.errorMessage =
				action.payload ?? "An error occurred";
		},
		[fetchBroadcastHistory.fulfilled]: (state, action) => {
			state.broadcastHistory.data = action.payload;
			state.broadcastHistory.status = "fulfilled";
		},
		// reset demo
		[resetDemo.fulfilled]: (state) => {
			state.resetDemo.status = "fulfilled";
		},
		[resetDemo.pending]: (state) => {
			state.resetDemo.status = "pending";
		},
		[resetDemo.rejected]: (state, action) => {
			state.resetDemo.status = "error";
			state.resetDemo.errorMessage = action.payload ?? "An error occurred";
		},
		//getCompanyManagers
		[getCompanyManagers.pending]: (state) => {
			state.getCompanyManagers.status = "pending";
			state.getCompanyManagers.errorMessage = "";
		},
		[getCompanyManagers.rejected]: (state, action) => {
			state.getCompanyManagers.status = "error";
			state.getCompanyManagers.errorMessage =
				action.payload ?? "An error occurred";
		},
		[getCompanyManagers.fulfilled]: (state, action) => {
			state.getCompanyManagers.status = "fulfilled";
			state.getCompanyManagers.errorMessage = "";
			state.masterData.allManagers = action.payload ?? [];
		},
		// Setting skill levels state separately as it is needed in
		// details dialog for worker requests
		[fetchSkillLevels.fulfilled]: (state, action) => {
			state.masterData.allSkillLevels = action.payload;
		},
		[locationsWithClusterAsync.pending]: (state) => {
			state.locationsWithClusterAsync.status = "pending";
		},
		[locationsWithClusterAsync.fulfilled]: (state, action) => {
			state.locationsWithClusterAsync.status = "fulfilled";
			state.locationsWithCluster = action.payload;
		},
		[locationsWithClusterAsync.rejected]: (state, action) => {
			state.locationsWithClusterAsync.status = "error";
			state.locationsWithClusterAsync.errorMessage =
				action.payload ?? "An error occurred";
		},
		[getHourlyWorkerCount.pending]: (state) => {
			state.getHourlyWorkerCount.status = "pending";
		},
		[getHourlyWorkerCount.fulfilled]: (state, action) => {
			state.getHourlyWorkerCount.status = "fulfilled";
			state.getHourlyWorkerCount.errorMessage = "";
			if (action.payload.type === "job") {
				state.masterData.availabilityDataByJob = action.payload.data;
			} else {
				state.masterData.availabilityDataBySkill = action.payload.data;
			}
		},
		[getHourlyWorkerCount.rejected]: (state, action) => {
			state.getHourlyWorkerCount.status = "error";
			state.getHourlyWorkerCount.errorMessage =
				action.payload ?? "An error occurred";
		},
	},
});

export const {
	createDraftShift,
	setFilteredShifts,
	setFilteredWeekShifts,
	resetCreateDraftShiftAsyncState,
	resetPublishDraftShiftsState,
	resetDeleteDraftShiftAsync,
	resetBroadcastMessageState,
	goToNextWeek,
	goToPreviousWeek,
	goToThisWeek,
	resetRenotifyWorkers,
	resetAssignWorkers,
	resetAssignWorkersResponse,
	resetMarkAttendance,
	editDraftShiftDialogOpen,
	editDraftShiftDialogClose,
	resetEditDraftShift,
	switchCalendarView,
	setShiftsByFilter,
	resetBroadcastWorkerCount,
} = ShiftSchedulerSlice.actions;

export default ShiftSchedulerSlice.reducer;
