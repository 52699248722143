import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { format } from "date-fns";
import React from "react";

import { ShiftType } from "../../../../store/gat-admin-slice-types";

interface MenuProps {
	handleMenuItemClick: (id: null | number, colorCode: undefined | string) => void;
	allShiftSchedules: ShiftType[];
	open: boolean;
	anchorEl: null | HTMLElement;
	handleClose: () => void;
	selectedShiftTypeId: null | number;
}

const ShiftScheduleMenuList: React.FC<MenuProps> = ({
	handleMenuItemClick,
	allShiftSchedules,
	open,
	anchorEl,
	handleClose,
	selectedShiftTypeId,
}) => {
	return (
		<div>
			<Menu
				id='shift-schedule-menu'
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
			>
				<MenuItem
					selected={selectedShiftTypeId === null}
					onClick={() => {
						handleMenuItemClick(null, undefined);
						handleClose();
					}}
				>
					Off
				</MenuItem>
				{allShiftSchedules.map((shiftSchedule: ShiftType) => {
					return (
						<MenuItem
							key={`shift-schedule-menu-item-${shiftSchedule.id}`}
							id={`shift-schedule-${shiftSchedule.id}`}
							selected={shiftSchedule.id === selectedShiftTypeId}
							onClick={() => {
								handleMenuItemClick(shiftSchedule.id, shiftSchedule.colorCode);
								handleClose();
							}}
						>
							{`${shiftSchedule.name} (${format(
								new Date(`2024-01-01T${shiftSchedule.startTime}`),
								"hh:mm a"
							)} - ${format(
								new Date(`2024-01-01T${shiftSchedule.endTime}`),
								"hh:mm a"
							)})`}
						</MenuItem>
					);
				})}
			</Menu>
		</div>
	);
};

export default ShiftScheduleMenuList;
