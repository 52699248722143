import EventNoteIcon from "@mui/icons-material/EventNote";
import { Box, Typography } from "@mui/material";
import React from "react";

const NoSchedule = () => {
	return (
		<Box
			display='flex'
			flexDirection='column'
			justifyContent='center'
			alignItems='center'
			mt={4}
			mb={2}
		>
			<EventNoteIcon
				sx={{
					color: "rgba(0, 0, 0, 0.6)",
				}}
			/>
			<Typography
				sx={{
					fontFamily: "Roboto",
					fontWeight: 400,
					fontSize: "16px",
					color: "rgba(0, 0, 0, 0.6)",
				}}
			>
				No Shift Schedule Added
			</Typography>
		</Box>
	);
};

export default NoSchedule;
