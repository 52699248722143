import { CheckOutlined } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import format from "date-fns/format";
import { useEffect, useRef, useState } from "react";
import React from "react";

import useViewport from "../../../../../utils/useViewport";
import Loading from "../../../../Loading";
import { BORDER_COLOR } from "../../../constants/colors";
import { SCHEDULED_SHIFTS } from "../../../constants/pages";
import useScheduleController from "../../../controllers/schedule-controller";
import { WeekHeaderRow } from "../../week-header-row";
import { WeeklyScheduleDateRangePicker } from "../../weekly-schedule-date-range-picker";
import { Row } from "./shifts-requirement-table-row";

const ShiftsRequirementTable = ({ changePage, callAlgo }) => {
	const [
		{
			allJobRoles,
			anyDateOfSelectedWeek,
			fetchShiftsRequirementStatus,
			generateScheduleStatus,
			shiftsRequirement,
			updateShiftsRequirementStatus,
		},
		{
			selectWeek,
			updateShiftsRequirementForThisWeek,
			fetchShiftsRequirementForThisWeek,
			changeShiftRequirements,
		},
	] = useScheduleController();
	const { height } = useViewport();
	const boxRef = useRef();

	const [scheduleTableHeight, setScheduleTableHeight] = useState();
	const [loadTimestamp, setLoadTimestamp] = useState(0);

	const [localJobs, setLocalJobs] = useState([]);
	const [searchValue, setSearchValue] = useState("");

	// This state is created to avoid rerender when clicked plus and minus in shift requirements
	// Re-rending is causing hover effect to reset
	let localShiftRequirementsRef = useRef({
		localShiftRequirementsArray: [],
	});
	const [
		loadLocalShiftRequirementsTimestamp,
		setLoadLocalShiftRequirementsTime,
	] = useState(0);

	const changeLocalJobs = (jobs) => {
		setLocalJobs(jobs);
	};

	useEffect(() => {
		// This function calculate X and Y
		const getPosition = () => {
			if (boxRef) {
				try {
					const y = boxRef.current.offsetTop;
					setScheduleTableHeight(height - y - 92);
				} catch (error) {
					// Do nothing
				}
			}
		};
		getPosition();
	}, [height]);

	useEffect(() => {
		setLocalJobs(allJobRoles);
	}, [allJobRoles]);

	useEffect(() => {
		if (
			loadLocalShiftRequirementsTimestamp !== anyDateOfSelectedWeek.getTime() &&
			localJobs.length > 0
		) {
			let datesArray = selectWeek(anyDateOfSelectedWeek);
			setLoadLocalShiftRequirementsTime(anyDateOfSelectedWeek.getTime());
			let newLocalShiftRequirementsArray = [];
			localJobs.forEach((job) => {
				datesArray.forEach((date) => {
					let formattedDate = format(date, "yyyy-MM-dd");
					// Check if shift exists already
					let found = false;
					shiftsRequirement.forEach((req) => {
						if (req.reqDate === formattedDate && req.jobId === job.id) {
							found = true;
							newLocalShiftRequirementsArray.push(req);
						}
					});
					if (!found) {
						newLocalShiftRequirementsArray.push({
							reqDate: formattedDate,
							jobId: job.id,
							workerCount: 0,
							jobHours: 8,
							changed: false,
						});
					}
				});
			});
			localShiftRequirementsRef.current.localShiftRequirementsArray =
				newLocalShiftRequirementsArray;
		}
	}, [
		localJobs,
		anyDateOfSelectedWeek,
		shiftsRequirement,
		loadLocalShiftRequirementsTimestamp,
		selectWeek,
	]);

	useEffect(() => {
		if (loadTimestamp !== anyDateOfSelectedWeek.getTime()) {
			setLoadTimestamp(anyDateOfSelectedWeek.getTime());
			const datesArrayOfSelectedWeek = selectWeek(anyDateOfSelectedWeek);
			// Fetch all requirement on this page load
			fetchShiftsRequirementForThisWeek({
				fromDate: format(datesArrayOfSelectedWeek[0], "yyyy-MM-dd"),
				toDate: format(
					datesArrayOfSelectedWeek[datesArrayOfSelectedWeek.length - 1],
					"yyyy-MM-dd"
				),
			});
		}
	}, [
		anyDateOfSelectedWeek,
		fetchShiftsRequirementForThisWeek,
		selectWeek,
		loadTimestamp,
	]);

	useEffect(() => {
		if (updateShiftsRequirementStatus === "fulfilled") {
			callAlgo(true);
			changePage(SCHEDULED_SHIFTS);
		}
	}, [callAlgo, changePage, updateShiftsRequirementStatus]);

	return (
		<Box>
			<Box
				sx={{
					height: "68px",
					borderBottom: 1,
					borderBottomWidth: 1,
					borderBottomColor: BORDER_COLOR,
					padding: "16px 24px",
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					zIndex: 9999,
				}}
			>
				<WeeklyScheduleDateRangePicker />
			</Box>
			<Box
				sx={{
					overflowY: "auto",
					scrollbarGutter: "stable",
				}}
			>
				<Box
					sx={{
						position: "sticky",
						top: 0,
						boxShadow: "0px 11px 8px -10px rgba(204,204,204,0.5)",
						zIndex: 999,
					}}
				>
					<WeekHeaderRow
						allJobRoles={allJobRoles}
						changeLocalJobs={changeLocalJobs}
						datesArrayOfSelectedWeek={selectWeek(anyDateOfSelectedWeek)}
						searchValue={searchValue}
						setSearchValue={text => setSearchValue(text)}
					/>
				</Box>
				<Box
					ref={boxRef}
					sx={{
						height: scheduleTableHeight,
					}}
				>
					{fetchShiftsRequirementStatus === "pending" ? (
						<Box
							sx={{
								height: scheduleTableHeight,
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<Loading />
						</Box>
					) : (
						<>
							{localJobs.map((job, index) => (
								<Box
									key={`job-week-row-${index}-${job.id}`}
									borderTop={index !== 0 ? 1 : 0}
									borderBottom={index === localJobs.length - 1 ? 1 : 0}
									borderColor={BORDER_COLOR}
								>
									<Row
										jobId={job.id}
										jobName={job.name}
										searchValue={searchValue}
										datesArrayOfSelectedWeek={selectWeek(anyDateOfSelectedWeek)}
										changeRequirements={(requirement) => {
											let changed = false;
											let newShiftRequirements = [];
											localShiftRequirementsRef.current.localShiftRequirementsArray.forEach(
												(oldRequirement) => {
													if (
														oldRequirement.reqDate === requirement.reqDate &&
														oldRequirement.jobId === requirement.jobId
													) {
														changed = true;
														newShiftRequirements.push(requirement);
													} else {
														newShiftRequirements.push(oldRequirement);
													}
												}
											);
											if (changed) {
												// This will not re-render UI
												localShiftRequirementsRef.current.localShiftRequirementsArray =
													newShiftRequirements;
											}
										}}
									/>
								</Box>
							))}
						</>
					)}
				</Box>
			</Box>
			<Box
				sx={{
					height: "72px",
					boxShadow: "0px -11px 8px -10px rgba(204,204,204,0.5)",
				}}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-end",
						alignItems: "center",
					}}
				>
					<Box
						sx={{
							flex: 1,
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-end",
							padding: "17px 24px",
							borderTop: 1,
							borderTopWidth: 1,
							borderTopColor: BORDER_COLOR,
							borderRadius: "0px 0px 15px 15px",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<Button
								onClick={() => changePage(SCHEDULED_SHIFTS)}
								sx={{
									fontFamily: "Roboto",
									fontSize: "12px",
									padding: "4px 0px",
									color: "#2F4D8B",
								}}
							>
								Cancel
							</Button>
						</Box>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "center",
								marginLeft: "16px",
							}}
						>
							<LoadingButton
								fullWidth
								onClick={() => {
									// Check if shift requirements changed
									let requirementChanged = false;

									for (
										let i = 0;
										i <
										localShiftRequirementsRef.current
											.localShiftRequirementsArray.length;
										i++
									) {
										if (
											localShiftRequirementsRef.current
												.localShiftRequirementsArray[i].changed
										) {
											requirementChanged = true;
											break;
										}
									}

									if (requirementChanged) {
										changeShiftRequirements(
											localShiftRequirementsRef.current
												.localShiftRequirementsArray
										);
										updateShiftsRequirementForThisWeek(
											localShiftRequirementsRef.current
												.localShiftRequirementsArray
										);
									} else {
										changePage(SCHEDULED_SHIFTS);
									}
								}}
								loading={
									updateShiftsRequirementStatus === "pending" ||
									generateScheduleStatus === "pending"
								}
								loadingPosition='end'
								loadingIndicator={
									<CircularProgress
										size={16}
										sx={{ color: "rgba(0, 0, 0, 0.38)" }}
									/>
								}
								endIcon={<CheckOutlined />}
								sx={{
									fontFamily: "Roboto",
									fontSize: "12px",
									color: "#FFFFFF",
									borderColor: "#2F4D8B",
								}}
								variant='contained'
							>
								Done
							</LoadingButton>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default ShiftsRequirementTable;
