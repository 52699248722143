// TODO - Type 'any' needs to be fixed.
import DataGrid from "@gigandtake/datagrid";
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { GridOverlay } from "@mui/x-data-grid";
import React from "react";
import { useSelector } from "react-redux";

import { JOB, SKILLS } from "../../../../../assets/constants";
import useViewSummary from "./controllers/use-view-summary";
import {
  CopyShiftsDataBySkillsProps,
  CopyShiftsDataProps,
  Shift,
} from "./types";
import ValidationAlert from "./validation-alert";

interface ViewSummaryProps {
  allWeekShifts: CopyShiftsDataProps[];
  allWeekShiftsBySkills: CopyShiftsDataBySkillsProps[];
  weekShiftsStatus: string;
  noJobSelected: boolean;
  noSkillSelected: boolean;
}

interface InvalidShiftProps extends Shift {
  isValid: boolean;
}

const NoShiftsAvailable = () => {
  return (
    <GridOverlay>
      <Box display="flex" flexDirection="column" alignItems="center">
        <CalendarTodayIcon
          sx={{
            color: "rgba(0,0,0,0.38)",
          }}
          fontSize="large"
        />
        <Typography
          sx={{
            color: "rgba(0,0,0,0.38)",
            fontFamily: "Roboto",
            fontSize: "14px",
            lineHeight: "20px",
            marginTop: "8px",
          }}
        >
          There are no shifts in the selected week
        </Typography>
      </Box>
    </GridOverlay>
  );
};

const ViewSummary = ({
  allWeekShifts,
  allWeekShiftsBySkills,
  weekShiftsStatus,
  noJobSelected,
  noSkillSelected,
}: ViewSummaryProps) => {
  const shiftsByFilter = useSelector(
    (state: any) => state.supervisor?.shiftsByFilter
  );

  const NoFilterSelected = () => {
    let filter = "Job Role";
    let icon = (
      <WorkHistoryOutlinedIcon
        sx={{
          color: "rgba(0,0,0,0.38)",
        }}
        fontSize="large"
      />
    );
    switch (shiftsByFilter) {
      case SKILLS:
        filter = "Skill";
        icon = (
          <BuildOutlinedIcon
            sx={{
              color: "rgba(0,0,0,0.38)",
            }}
            fontSize="large"
          />
        );
        break;
      default:
        break;
    }
    return (
      <GridOverlay>
        <Box display="flex" flexDirection="column" alignItems="center">
          {icon}
          <Typography
            sx={{
              color: "rgba(0,0,0,0.38)",
              fontFamily: "Roboto",
              fontSize: "14px",
              lineHeight: "20px",
              marginTop: "8px",
            }}
          >
            {`Please select a ${filter} to view shifts`}
          </Typography>
        </Box>
      </GridOverlay>
    );
  };

  const noJobOrSkillSelected =
    (shiftsByFilter === JOB && noJobSelected) ||
    (shiftsByFilter === SKILLS && noSkillSelected);
  const [{ minDurationHrs, maxDurationHrs, rows, selectedColumn }] =
    useViewSummary({
      allWeekShifts,
      allWeekShiftsBySkills,
    });

  const numberOfInvalidShifts = rows.reduce((acc: any, row: any) => {
    const count = row.shiftDetails.filter(
      (shift: InvalidShiftProps) => !shift.isValid
    ).length;
    acc += count;
    return acc;
  }, 0);

  return (
    <Box mt={2}>
      <Typography variant="subtitle2">Shifts Summary</Typography>
      {numberOfInvalidShifts > 0 ? (
        <ValidationAlert
          numberOfInvalidShifts={numberOfInvalidShifts}
          minDurationHrs={minDurationHrs}
          maxDurationHrs={maxDurationHrs}
        />
      ) : null}
      <Box width="100%" height={numberOfInvalidShifts > 0 ? 300 : 400} mt={3}>
        <DataGrid
          width="100%"
          height="100%"
          rows={rows ?? []}
          columns={selectedColumn}
          hideFooter={true}
          loading={weekShiftsStatus === "pending"}
          components={{
            NoRowsOverlay: noJobOrSkillSelected
              ? NoFilterSelected
              : NoShiftsAvailable,
          }}
          getRowHeight={() => "auto"}
          sx={{
            border: "none !important",
            "& .MuiDataGrid-columnSeparator": {
              display: "none",
            },
            overflow: "hidden",
          }}
        />
      </Box>
    </Box>
  );
};

export default ViewSummary;
