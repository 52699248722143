// TODO - Type 'any' needs to be fixed.
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { PickersDay } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import addDays from "date-fns/addDays";
import endOfWeek from "date-fns/endOfWeek";
import isSameDay from "date-fns/isSameDay";
import isSunday from "date-fns/isSunday";
import isWithinInterval from "date-fns/isWithinInterval";
import enGB from "date-fns/locale/en-GB";
import startOfWeek from "date-fns/startOfWeek";
import subDays from "date-fns/subDays";
import { useState } from "react";
import React from "react";

const CustomPickersDay = styled(PickersDay, {
	shouldForwardProp: (prop) =>
		prop !== "dayIsBetween" &&
		prop !== "isFirstDay" &&
		prop !== "isLastDay" &&
		prop !== "selected",
})(({ theme, dayIsBetween, isFirstDay, isLastDay, selected }: any) => ({
	...(dayIsBetween && {
		borderRadius: 0,
		backgroundColor: "rgba(47, 77, 139, 0.08)",
		color: theme.palette.common.black,
	}),
	...(isFirstDay && {
		borderTopLeftRadius: "50%",
		borderBottomLeftRadius: "50%",
	}),
	...(isLastDay && {
		borderTopRightRadius: "50%",
		borderBottomRightRadius: "50%",
	}),
	...(selected && {
		backgroundColor: "rgba(47, 77, 139, 0.08)",
	}),
}));

export default function WeekDatePicker({
	anyDateOfSelectedWeek,
	changeDateOfSelectedWeek,
}: any) {

	const [value, setValue] = useState(new Date(anyDateOfSelectedWeek));

	const renderWeekPickerDay = (date: any, selectedDates: any, pickersDayProps: any) => {
		// console.log("pickersDayProps", pickersDayProps);
		if (!value) {
			return <PickersDay {...pickersDayProps} />;
		}

		let start = addDays(startOfWeek(value), 1);
		let end = addDays(endOfWeek(value), 1);

		if (isSunday(value)) {
			const newValue = subDays(value, 7);
			start = addDays(startOfWeek(newValue), 1);
			end = addDays(endOfWeek(newValue), 1);
		}

		const dayIsBetween = isWithinInterval(date, { start, end });
		const isFirstDay = isSameDay(date, start);
		const isLastDay = isSameDay(date, end);

		return (
			<CustomPickersDay
				{...pickersDayProps}
				disableMargin
				dayIsBetween={dayIsBetween}
				isFirstDay={isFirstDay}
				isLastDay={isLastDay}
				// outsideCurrentMonth={false}
				showDaysOutsideCurrentMonth={true}
			/>
		);
	};

	const handleChange = (newValue: any) => {
		setValue(newValue);
		changeDateOfSelectedWeek(new Date(newValue));
	};

	return (
		<Box
			sx={{
				".MuiCalendarPicker-root .MuiTypography-root.MuiTypography-caption": {
					margin: 0,
				},
			}}
		>
			<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
				<StaticDatePicker
					displayStaticWrapperAs='desktop'
					label='Week picker'
					value={value}
					onChange={(newValue) => handleChange(newValue)}
					renderDay={renderWeekPickerDay}
					renderInput={(params) => <TextField {...params} />}
					inputFormat="'Week of' MMM d"
				// disableFuture={disableFuture}
				/>
			</LocalizationProvider>
		</Box>
	);
}
