import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import React from "react";

import useViewport from "../../../../../utils/useViewport";

function NoShiftsAvailableForPublish() {
	return (
		<GridOverlay>
			<Box display='flex' flexDirection='column' alignItems='center'>
				<CalendarTodayIcon fontSize='large' />
				<Typography>No Draft Shifts for this week.</Typography>
				<Typography>Add some Draft Shifts and then try publishing.</Typography>
			</Box>
		</GridOverlay>
	);
}
export const ReviewPublishingNewShiftsTable = ({
	rows,
	columns,
	handleShiftSelection,
}) => {
	const { height } = useViewport();
	return (
		<Box width={1000} height={height - 383} mr='auto' ml='auto'>
			<DataGrid
				rows={rows}
				columns={columns}
				checkboxSelection
				hideFooter={true}
				onSelectionModelChange={handleShiftSelection}
				components={{
					NoRowsOverlay: NoShiftsAvailableForPublish,
				}}
				getRowHeight={() => 74}
				sx={{
					border: "none !important",
					"& .MuiDataGrid-columnSeparator": {
						display: "none",
					},
				}}
			/>
		</Box>
	);
};
