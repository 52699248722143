import PageLayout from "@gigandtake/page-layout";
import {Divider} from "@mui/material";
import React from "react";

import {PageHeader} from "../../../../../export/page-header";
import {
    REQUEST_FLEX_WORKER_SUB_TITLE,
    REQUEST_FLEX_WORKER_TITLE
} from "../../constants/titles";
import RequestNewShiftsTable from "./ui/request-new-shifts-table";

const RequestNewShifts = ({changePage}) => {
    return (
        <PageLayout>
            <PageHeader
                title={REQUEST_FLEX_WORKER_TITLE}
                subtitle={REQUEST_FLEX_WORKER_SUB_TITLE}
            />
            <Divider />
            <RequestNewShiftsTable changePage={changePage}/>
        </PageLayout>
    );
}

export default RequestNewShifts;
