// TODO - Type 'any' needs to be fixed.
import { Box } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";

import { getAllShiftTypes } from "../../../../../../../export/workers";
import LegendsContainer from "./legends-container";
import ShiftPatternDayCellGroup from "./pattern/shift-pattern-day-cell-group";
import ShiftPatternInfo from "./shift-pattern-info";

interface ShiftType {
	id: number;
	name: string;
	companyId: number;
	locationId: number;
	startTime: string;
	endTime: string;
	unpaidBreakMins: number;
	isActive: boolean;
	colorCode: string | undefined;
}
interface Schedule {
	id: number;
	patternId: number;
	dayNo: number;
	shiftTypeId: number;
	isActive: boolean;
	shiftType: ShiftType;
}
interface LocalSchedule {
	dayNo: number;
	shiftTypeId: number | null;
	colorCode: undefined | string;
}

const ShiftPattern = ({ shiftPattern, locationId }: any) => {
	const dispatch = useDispatch();

	const convertToSchedule = (schedules: Schedule[]): LocalSchedule[] => {
		const returnValue: LocalSchedule[] = [];
		try {
			schedules.forEach((sType: Schedule) => {
				returnValue.push({
					dayNo: sType.dayNo,
					shiftTypeId: sType.shiftType.id,
					colorCode: sType.shiftType.colorCode,
				});
			});
			return returnValue;
		} catch (e) {
			return returnValue;
		}
	};

	const pattern = {
		...shiftPattern,
		schedule: convertToSchedule(shiftPattern.schedule),
	};

	const fetchShiftTypes = useCallback(() => {
		if (locationId) dispatch(getAllShiftTypes({ locationId }));
	}, [dispatch, locationId]);

	useEffect(() => {
		fetchShiftTypes();
	}, [fetchShiftTypes]);

	if (Object.keys(shiftPattern).length === 0) return null;
	return (
		<Box width={800} height={200}>
			<ShiftPatternInfo
				name={shiftPattern.name}
				duration={shiftPattern.numberOfDays}
				startDate={shiftPattern.startDate}
				endDate={shiftPattern.endDate}
			/>
			<ShiftPatternDayCellGroup values={pattern} />
			<LegendsContainer values={shiftPattern} />
		</Box>
	);
};

export default ShiftPattern;
