import React, { useEffect, useRef, useState } from "react";

const useShiftPatternMenupropsController = () => {
	const myElementRef = useRef<HTMLDivElement | null>(null);

	const [position, setPosition] = useState<DOMRect | null>(null);

	useEffect(() => {
		const updatePosition = () => {
			const element = myElementRef.current;

			if (element) {
				const rect = element.getBoundingClientRect();
				setPosition(rect);
			}
		};

		// Initial position calculation
		updatePosition();

		// Update position on scroll
		window.addEventListener("scroll", updatePosition);

		// Cleanup
		return () => {
			window.removeEventListener("scroll", updatePosition);
		};
	}, []);
	return {
		myElementRef,
		position,
	};
};

export default useShiftPatternMenupropsController;
