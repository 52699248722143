import Box from "@mui/material/Box";
import { DataGrid} from "@mui/x-data-grid";
import React from "react";


const ShiftDetailsTable = ({rows,columns,isShiftPast,setWorkersSelected, readOnly = false}) => {

    return (
        <Box width={1100} mr="auto" ml="auto">
            <DataGrid
            rows={rows}
            columns={columns}
            isRowSelectable={(params) => !params.row.confirmed && !params.row.interested && !params.row.declined && !readOnly}
            checkboxSelection={!isShiftPast}
            hideFooter={true}
            onSelectionModelChange={(e) =>
                setWorkersSelected(e)
            }
            sx={{
                border: 0,
                paddingLeft: "21px",
                paddingRight: "21px",
                marginBottom: "-20px",
                overflowX: "hidden",
                height: "50vh",
                "& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator": {
                    display: "none"
                },
                "& .MuiDataGrid-columnHeaderTitleContainerContent": {
                    width: "100%",
                }
            }}
            />
        </Box>
    )
}

export default ShiftDetailsTable
