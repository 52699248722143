import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import React, { FC } from "react";

import {
  CrossSkillingLevelsTableHeader,
  CrossSkillingLevelsTableRow,
} from "../../../../../../../../export/gat-admin/cross-skilling/cross-skilling-levels-table/cross-skilling-levels-table-components";
import { SkillLevel } from "../../../../../../cross-skilling/types";

interface CrossSkillingLevelsTableProps {
  openCrossSkillingEditDialog: (open: boolean) => void;
  skillLevels: SkillLevel[];
}

const CrossSkillingLevelsTable: FC<CrossSkillingLevelsTableProps> = ({
  openCrossSkillingEditDialog,
  skillLevels,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        marginTop: "20px",
        marginBottom: "8px",
        borderRadius: "8px",
        border: 1,
        borderWidth: "1px",
        borderColor: "rgba(0,0,0,0.12)",
        background: "#FFF",
        paddingTop: "8px",
        paddingBottom: "8px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          paddingLeft: "28px",
          paddingRight: "36px",
          paddingTop: "8px",
          paddingBottom: "8px",
        }}
      >
        <Typography
          sx={{
            color: "rgba(0, 0, 0, 0.87)",
            fontFamily: "Roboto",
            fontSize: "16px",
            fontWeight: "400",
          }}
        >
          Skill Level Configuration
        </Typography>
        <IconButton
          sx={{
            padding: "12px",
          }}
          onClick={() => openCrossSkillingEditDialog(true)}
        >
          <EditIcon />
        </IconButton>
      </Box>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <CrossSkillingLevelsTableHeader />
        <Divider />
        {skillLevels.map((skillLevel) => (
          <CrossSkillingLevelsTableRow
            key={`skill-level-${skillLevel.id}`}
            skillLevel={skillLevel}
          />
        ))}
      </Box>
    </Box>
  );
};

export default CrossSkillingLevelsTable;
