// TODO - Type 'any' needs to be fixed.
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Tooltip, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import { DataGridPro } from "@mui/x-data-grid-pro";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { markAttendance } from "../../../components/scheduler/store/scheduler-slice";
import {colors} from "../../../config/colors";


const statusObj:any = {
	p: { title: "Present", color: "#017374" },
	a: { title: "Absent", color: "#b00020" },
	t: { title: "Tardy", color: "#01a299" },
	e: { title: "Excused absence", color: "#7d5260" },
	x: { title: "Excused tardiness", color: "#7d5260" },
};

const actionItems = [
	{ title: "Present", status: "p", color: "#017374" },
	{ title: "Absent", status: "a", color: "#b00020" },
	{ title: "Tardy", status: "t", color: "#01a299" },
	{ title: "Excused Absence", status: "e", color: "#7d5260" },
	{ title: "Excused Tardy", status: "x", color: "#7d5260" },
];

const columns:any = [
	{
		field: "workerName",
		headerName: "Worker Name",
		width: 150,
		renderHeader: () => (
			<span style={{ padding: "0px 0x", fontWeight: "bold" }}>
				{"Worker Name"}
			</span>
		),
	},
	{
		field: "status",
		headerName: "Status",
		headerAlign: "center",
		width: 150,
		renderHeader: () => (
			<span style={{ padding: "0px 12px", fontWeight: "bold" }}>
				{"Status"}
			</span>
		),
		renderCell: (params:any) => {
			return (
				<Box
					sx={{
						backgroundColor: statusObj[params.row.status]?.color,
						padding: "2px",
					}}
					borderRadius={1}
					width={130}
				>
					<Tooltip title={statusObj[params.row.status]?.title}>
						<Typography align='center' color='white' variant='subtitle2'>
							{statusObj[params.row.status]?.title}
						</Typography>
					</Tooltip>
				</Box>
			);
		},
	},
	{
		field: "comment",
		headerName: "Comment",
		headerAlign: "center",
		renderHeader: () => (
			<span style={{ padding: "0px 12px", fontWeight: "bold" }}>
				{"Comment"}
			</span>
		),
		minWidth: 150,
		flexGrow: 1,
		flex: 3,
		renderCell: (params:any) => (
			<Tooltip title={params?.row?.comment}>
				<span>{params?.row?.comment}</span>
			</Tooltip>
		),
	},
	{
		field: "action",
		headerName: "Actions",
		width: 100,
		sortable: false,
		filterable: false,
		disableColumnMenu: true,
		headerAlign: "right",
		renderHeader: () => (
			<span style={{ padding: "0px 12px", fontWeight: "bold" }}>
				{"Actions"}
			</span>
		),
		renderCell: (params:any) => (
			<div
				style={{
					display: "flex",
					width: "100%",
					justifyContent: "flex-end",
					alignItems: "center",
				}}
			>
				<ActionDialog item={params.row} />
			</div>
		),
	},
];

const Transition = React.forwardRef(function Transition(props:any, ref:any) {
	return <Slide direction='up' ref={ref} {...props} />;
});

const ActionDialog = ({ item }:any) => {
	const dispatch = useDispatch();

	const [open, setOpen] = useState(false);
	const [comment, setComment] = useState("");

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setComment("");
		setOpen(false);
	};

	const actions = actionItems.filter((action) => action.status !== item.status);

	const handleChange = (e:any) => {
		const char = e.target.value;
		if (char !== "c")
			dispatch(
				markAttendance({
					workerId: item.id,
					status: char,
					shiftId: item.shiftId,
					comment: item.comment,
				})
			);
		else handleClickOpen();
	};

	const handleComment = () => {
		dispatch(
			markAttendance({
				workerId: item.id,
				status: item.status,
				shiftId: item.shiftId,
				comment: comment,
			})
		);
		handleClose();
	};

	return (
		<Box>
			<Select
				id='demo-simple-select'
				IconComponent={MoreVertIcon}
				displayEmpty
				value=''
				variant='filled'
				onChange={handleChange}
				sx={{ backgroundColor: "white" }}
			>
				{actions.map((action) => {
					return (
						<MenuItem value={action.status} key={action.title}>
							{action.title}
						</MenuItem>
					);
				})}
				<MenuItem value='c'>Leave a comment</MenuItem>
			</Select>
			<Dialog
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
				aria-describedby='alert-dialog-slide-description'
			>
				<DialogTitle>{"Add Comments"}</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-slide-description'>
						{`Add comments for ${item.workerName}`}
					</DialogContentText>
					<TextField
						id='outlined-multiline-static'
						// label="Comments"
						multiline
						rows={4}
						fullWidth
						sx={{ marginTop: 2 }}
						defaultValue={item.comment ?? ""}
						onChange={(e) => setComment(e.target.value)}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} sx={{ color: colors.gray }}>
						Cancel
					</Button>
					<Button disabled={comment.length === 0} onClick={handleComment}>
						Done
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
};
function ShiftStatusTable({ rows }:any) {
	const dataGridRef :any= useRef();
	// const temp = rows.map((row)=> {
	//     return {...row,comment:"test comment"}
	// })

	return (
		<Box width={600}>
			<DataGridPro
				ref={dataGridRef}
				rows={rows}
				columns={columns}
				// height
				hideFooter
				disableColumnSelector
				disableDensitySelector
				disableSelectionOnClick
				getRowHeight={() => "auto"}
				sx={{
					position: "sticky",
					"&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
						py: "10px",
					},
				}}
			/>
		</Box>
	);
}

export default ShiftStatusTable;
