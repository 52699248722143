import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import * as React from "react";

// Define types for props
interface SearchJobInputProps {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  searchJob: string;
  setSearchJob: (value: string) => void;
}

const SearchJobInput: React.FC<SearchJobInputProps> = ({
  handleChange,
  searchJob,
  setSearchJob,
}) => {
  return (
    <Box mt={2} maxWidth={900}>
      <OutlinedInput
        sx={{ backgroundColor: "#f0f0f0" }}
        fullWidth
        onChange={handleChange}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
        endAdornment={
          searchJob.length > 0 && (
            <CancelOutlinedIcon
              sx={{ cursor: "pointer", color: "#92918f" }}
              onClick={() => setSearchJob("")}
            />
          )
        }
        value={searchJob}
        placeholder="Search Job Roles"
      />
    </Box>
  );
};

export default SearchJobInput;
