import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import TodayOutlinedIcon from "@mui/icons-material/TodayOutlined";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";
import { Box, MenuItem, TextField, Typography } from "@mui/material";
import React from "react";

import ActionButtons from "./action-buttons";
import useShiftCancelReasonController from "./controllers/use-shift-cancel-reason-controller";

interface IShiftData {
	slotsFilled: number;
	numberOfWorkersNeeded: number;
	jobId: number;
	skills: Array<{ skillName: string }>;
	startDate: string | Date;
	endDate: string | Date;
}

interface IShiftCancelFormProps {
	shiftId: number;
	handleClose: () => void;
}

const ShiftCancelForm = ({ shiftId, handleClose }: IShiftCancelFormProps) => {
	const [
		{
			cancelShiftForm,
			allJobsObj,
			shiftData,
			cancelShiftReasons,
			cancelShiftWithReasonStatus,
		},
		{ setCancelShiftForm, formatedShiftDate, handleCancelShift },
	] = useShiftCancelReasonController(shiftId, handleClose);

	const workersConfirmed = shiftData?.slotsFilled;
	const workerRequired = shiftData?.numberOfWorkersNeeded;

	const job = shiftData?.jobId;
	const skills = shiftData?.skills;

	if (!shiftData) {
		return null;
	}

	return (
		<Box width={600}>
			<Box>
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontSize: "16px",
						fontWeight: "5400",
						lineHeight: "28px",
						letterSpacing: "0.15px",
					}}
				>
					Are you sure you want to cancel this shift?
				</Typography>
			</Box>
			<Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
				<Box sx={{ display: "flex", alignItems: "center" }}>
					<TodayOutlinedIcon sx={{ color: "#00000099" }} />
					<Typography
						sx={{
							ml: 1,
							fontSize: "14px",
							fontWeight: 400,
							fontFamily: "Roboto",
							lineHeight: "20px",
							letterSpacing: "0.17px",
							color: " #000000DE",
						}}
					>
						{formatedShiftDate && formatedShiftDate()}
					</Typography>
				</Box>
				<Box sx={{ display: "flex", alignItems: "center" }}>
					<PersonOutlineOutlinedIcon sx={{ color: "#00000099" }} />
					<Typography
						sx={{
							ml: 1,
							fontSize: "14px",
							fontWeight: 400,
							fontFamily: "Roboto",
							lineHeight: "20px",
							letterSpacing: "0.17px",
						}}
					>
						{`${workersConfirmed}/${workerRequired} Workers Confirmed`}
					</Typography>
				</Box>
			</Box>
			<Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
				<WorkHistoryOutlinedIcon sx={{ color: "#00000099" }} />
				<Typography
					sx={{
						ml: 1,
						fontSize: "14px",
						fontWeight: 400,
						fontFamily: "Roboto",
						lineHeight: "20px",
						letterSpacing: "0.17px",
					}}
				>
					{shiftData?.jobId === 0
						? skills.map((sk: { skillName: string }) => sk.skillName).join(", ")
						: allJobsObj?.[job]}
				</Typography>
			</Box>
			<Box sx={{ mt: 2 }}>
				<TextField
					select
					value={cancelShiftForm?.selectedReason}
					onChange={(e) => {
						cancelShiftForm &&
							setCancelShiftForm &&
							setCancelShiftForm({
								...cancelShiftForm,
								selectedReason: e.target.value,
							});
					}}
					label='Reason for cancellation*'
					placeholder='Select a Reason'
					fullWidth
					variant='filled'
				>
					{cancelShiftReasons &&
						cancelShiftReasons.map(
							(reason: { id: number; reasonText: string }) => (
								<MenuItem key={reason.id} value={reason.id}>
									{reason.reasonText}
								</MenuItem>
							)
						)}
					<MenuItem value={-1}>Other reason</MenuItem>
				</TextField>
			</Box>
			{Number(cancelShiftForm?.selectedReason) === -1 ? (
				<Box sx={{ mt: 2 }}>
					<Box>
						<TextField
							label='Other reason*'
							placeholder='Enter reason here'
							fullWidth
							variant='filled'
							value={cancelShiftForm?.otherReason}
							onChange={(e) => {
								if (e.target.value.length <= 50) {
									setCancelShiftForm &&
										cancelShiftForm &&
										setCancelShiftForm({
											...cancelShiftForm,
											otherReason: e.target.value,
										});
								}
							}}
							error={
								cancelShiftForm && cancelShiftForm?.otherReason?.length > 50
							}
							helperText={
								cancelShiftForm && cancelShiftForm?.otherReason?.length > 50
									? "Other reason must be less than 50 characters"
									: ""
							}
						/>
					</Box>
					<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
						<Typography sx={{ fontSize: "12px", color: "#00000099" }}>
							{cancelShiftForm?.otherReason?.length} / 50
						</Typography>
					</Box>
				</Box>
			) : null}
			<Box sx={{ mt: 2 }}>
				<TextField
					label='Message to confirmed Worker'
					placeholder='Enter here'
					fullWidth
					variant='filled'
					multiline
					rows={2}
					value={cancelShiftForm?.messageToWorker}
					onChange={(e) => {
						if (e.target.value.length <= 150) {
							setCancelShiftForm &&
								cancelShiftForm &&
								setCancelShiftForm({
									...cancelShiftForm,
									messageToWorker: e.target.value,
								});
						}
					}}
					error={
						cancelShiftForm && cancelShiftForm?.messageToWorker?.length > 150
					}
					helperText={
						cancelShiftForm && cancelShiftForm?.messageToWorker?.length > 150
							? "Message to confirmed worker must be less than 150 characters"
							: ""
					}
				/>
			</Box>
			<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
				<Typography sx={{ fontSize: "12px", color: "#00000099" }}>
					{cancelShiftForm?.messageToWorker?.length} / 150
				</Typography>
			</Box>
			<ActionButtons
				handleClose={handleClose}
				cancelShiftWithReasonStatus={cancelShiftWithReasonStatus || ""}
				cancelShiftForm={cancelShiftForm || {}}
				handleCancelShift={
					handleCancelShift ||
					(() => {
						// TODO - Type 'any' needs to be fixed.
					})
				}
				shiftData={shiftData}
			/>
		</Box>
	);
};

export default ShiftCancelForm;
