import { Box, Divider } from "@mui/material";
import { Typography } from "@mui/material";
import React from "react";

const BoxWrapper = ({ title, subtitle, children, divider }:any) => {
	return (
		<Box
			m={3}
			sx={{
				minWidth: "1000px",
				maxWidth: "1312px",
				width: "100%",
				borderRadius: "15px",
				backgroundColor: "#fff",
			}}
		>
			<Box display='flex' justifyContent='space-between' p={3}>
				<Box>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 400,
							fontSize: "24px",
							color: "rgba(0, 0, 0, 0.87)",
						}}
					>
						{title}
					</Typography>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 400,
							fontSize: "16px",
							color: "rgba(0, 0, 0, 0.6)",
						}}
					>
						{subtitle}
					</Typography>
				</Box>
			</Box>
			{!divider ? <Divider /> : null}
			<Box>{children}</Box>
		</Box>
	);
};

export default BoxWrapper;
