/* eslint-disable no-mixed-spaces-and-tabs */
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import { Box, Button, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import Tooltip from "@mui/material/Tooltip";
import format from "date-fns/format";
import { useEffect, useRef, useState } from "react";
import React from "react";
import { useDrop } from "react-dnd";

import { isTooDark } from "../../../../../utils/helpers";
import {
	BORDER_COLOR,
	FLEX_WORKERS_CARD_COLOR,
	FLEX_WORKERS_FULFILLED_COUNT_COLOR,
	FLEX_WORKERS_FULFILLED_ICON_COLOR,
	FLEX_WORKERS_PENDING_COUNT_COLOR,
	FLEX_WORKERS_PENDING_ICON_COLOR,
	FULL_TIME_WORKER_CARD_COLOR,
	OVERTIME_COLOR,
	SHIFT_CELL_FOCUS_COLOR,
	WORKERS_REQUIRED_COUNT_COLOR,
} from "../../../constants/colors";
import { DragAndDropTypes } from "../../../constants/draganddroptypes";
import useScheduleController from "../../../controllers/schedule-controller";

// Other constants
const MAX_WORKERS_IN_SHIFT_CELL = 3;

export const WorkerCell = ({ name, workerTypeId, experienceLevel = 0 }) => {
	let workerColorTag =
		workerTypeId === 3 ? FLEX_WORKERS_CARD_COLOR : FULL_TIME_WORKER_CARD_COLOR;
	if (experienceLevel !== 0) {
		// Code to assign color based on experience level
	}
	return (
		<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
			<Box
				sx={{ width: "4px", height: "14px", backgroundColor: workerColorTag }}
			/>
			<Typography
				sx={{
					marginLeft: "4px",
					fontSize: "12px",
					lineHeight: "16px",
					fontWeight: 400,
					fontFamily: "Roboto",
					color: "rgba(0, 0, 0, 0.6)",
					userSelect: "none",
				}}
			>
				{name}
			</Typography>
		</Box>
	);
};

export const CellItem = ({
	shift,
	index,
	selectedDate,
	openShiftDetailsDialog,
	setShiftIdForShiftDetailsDialog,
}) => {
	const [{ selectedWorker }, { isWeekend, assignWorkerToShift }] =
		useScheduleController();
	const [anchorEl, setAnchorEl] = useState(null);
	const [anchorConfirmationEl, setAnchorConfirmationEl] = useState(null);
	const [selectedShift, setSelectedShift] = useState("");
	const [isDroppable, setIsDroppable] = useState(false);
	const [hover, setHover] = useState(false);
	const open = Boolean(anchorEl);
	const openConfirmation = Boolean(anchorConfirmationEl);
	const spanRef = useRef();

	const handleClick = (event, shiftId) => {
		setAnchorEl(event.currentTarget);
		setSelectedShift(shiftId);
	};
	const handleClose = () => {
		setHover(false);
		setAnchorEl(null);
		setSelectedShift("");
	};

	const viewShiftDetails = (shiftId) => {
		setShiftIdForShiftDetailsDialog(shiftId);
		openShiftDetailsDialog();
	};
	const handleConfirmation = () => {
		setAnchorConfirmationEl(spanRef.current);
		setSelectedShift(shift.id);
	};
	const handleConfirmationClose = () => {
		setAnchorConfirmationEl(null);
		setSelectedShift("");
	};

	// const startTime = format(new Date(shift.startDateTime), "H:mm");
	// const endTime = format(new Date(shift.endDateTime), "H:mm");
	// const rangeString = `${startTime} - ${endTime}`;
	const overtime = shift.overtime;
	let flexWorkersAllowed = shift.flex !== 0;
	const flexShiftId = shift.flex;
	let workers = shift.workers;
	const numberOfWorkersNeeded = shift.numberOfWorkersNeeded;
	const workersSignedForShift = workers.length;
	let backgroundColorForCount = FLEX_WORKERS_FULFILLED_COUNT_COLOR;
	let backgroundColorForIcon = FLEX_WORKERS_FULFILLED_ICON_COLOR;
	const additionalWorkerNeed = numberOfWorkersNeeded - workersSignedForShift;
	if (workersSignedForShift < numberOfWorkersNeeded) {
		backgroundColorForCount = flexWorkersAllowed
			? FLEX_WORKERS_PENDING_COUNT_COLOR
			: WORKERS_REQUIRED_COUNT_COLOR;
		backgroundColorForIcon = FLEX_WORKERS_PENDING_ICON_COLOR;
	}
	const fullTimeWorkers = workers
		? workers.filter((x) => x.workerTypeId === 1)
		: [];
	const flexWorkers = workers
		? workers.filter((x) => x.workerTypeId === 3)
		: [];

	if (
		flexWorkersAllowed &&
		flexWorkers.length === 0 &&
		additionalWorkerNeed <= 0
	) {
		flexWorkersAllowed = false;
	}

	const [{ isOver }, drop] = useDrop(
		() => ({
			accept: DragAndDropTypes.WORKER_CARD,
			drop: () => {
				if (
					selectedWorker.selectedOnDate ===
						format(selectedDate, "yyyy-MM-dd") &&
					additionalWorkerNeed > 0
				) {
					handleConfirmation();
				}
			},
			collect: (monitor) => ({
				isOver: !!monitor.isOver(),
			}),
		}),
		[shift.id, selectedDate, assignWorkerToShift, additionalWorkerNeed]
	);

	useEffect(() => {
		if (isOver) {
			if (
				selectedWorker.selectedOnDate === format(selectedDate, "yyyy-MM-dd") &&
				additionalWorkerNeed > 0
			) {
				setIsDroppable(true);
			}
		} else {
			setIsDroppable(false);
		}
	}, [isOver, selectedDate, selectedWorker, additionalWorkerNeed]);
	return (
		<Box
			ref={spanRef}
			id={`shift-cell-confirmation-button-${shift.id}`}
			aria-controls={
				openConfirmation && shift.id === selectedShift
					? `shift-cell-confirmation-menu-${shift.id}`
					: undefined
			}
			aria-haspopup='true'
			aria-expanded={
				openConfirmation && shift.id === selectedShift ? "true" : undefined
			}
		>
			<Box ref={drop}>
				<Box
					borderTop={index !== 0 ? 1 : 0}
					borderColor={BORDER_COLOR}
					sx={{
						flex: 1,
						alignItems: "center",
						padding: isDroppable ? "4px 0px 4px 0px" : "5px 0px 5px 0px",
						cursor: "pointer",
						minHeight: "74px",
						backgroundColor:
							selectedShift === shift.id || isDroppable
								? SHIFT_CELL_FOCUS_COLOR
								: "rgba(255,255,255,0)",
						borderStyle: isDroppable ? "inset" : null,
						border: isDroppable ? "1px dashed #1E88E5" : null,
					}}
					id={`shift-cell-button-${shift.id}`}
					aria-controls={
						open && shift.id === selectedShift
							? `shift-cell-menu-${shift.id}`
							: undefined
					}
					aria-haspopup='true'
					aria-expanded={
						open && shift.id === selectedShift ? "true" : undefined
					}
					onClick={(e) => handleClick(e, shift.id)}
				>
					{/* Shift time */}
					<Box
						sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
					>
						<Box
							sx={{ width: "4px", height: "14px", backgroundColor: "#D9D9D9" }}
						/>
						<Typography
							sx={{
								fontSize: "12px",
								fontWeight: 500,
								fontFamily: "Roboto",
								color: "#000000",
								marginLeft: "4px",
								lineHeight: "16px",
								whiteSpace: "nowrap",
								userSelect: "none",
							}}
						>
							{/*{rangeString}*/}
							First Shift
						</Typography>
						{flexWorkersAllowed && (
							<Tooltip
								title={`Flex Workers ${
									additionalWorkerNeed <= 0 ? "Confirmed" : "Requested"
								}`}
								sx={{ pointerEvents: "none" }}
							>
								<PersonAddAltOutlinedIcon
									sx={{
										height: "14px",
										color: backgroundColorForIcon,
									}}
								/>
							</Tooltip>
						)}
						{overtime !== 0 && (
							<Typography
								sx={{
									fontSize: "12px",
									fontFamily: "Roboto",
									color: OVERTIME_COLOR,
									textAlign: "right",
									flex: 1,
									marginRight: "4px",
									whiteSpace: "nowrap",
									userSelect: "none",
								}}
							>
								{`+${overtime} Hrs`}
							</Typography>
						)}
					</Box>
					{/* Workers assigned */}
					<Box>
						{workers.map((worker, index) => {
							let showMoreLink = workers.length > MAX_WORKERS_IN_SHIFT_CELL;
							if (showMoreLink) {
								if (index < MAX_WORKERS_IN_SHIFT_CELL - 1) {
									// Show one less than max allowed workers
									return (
										<WorkerCell
											key={`shift-${shift.id}-worker-${worker.id}-${index}`}
											name={worker.name}
											workerTypeId={worker.workerTypeId}
											experienceLevel={0}
										/>
									);
								} else if (index < MAX_WORKERS_IN_SHIFT_CELL) {
									// replace last row with show more text
									return (
										<Typography
											key={`shift-${shift.id}-show-more`}
											sx={{
												color: "#01579B",
												fontFamily: "Roboto",
												fontWeight: 400,
												fontSize: "10px",
												lineHeight: "14px",
												marginLeft: "8px",
												textDecoration: "underline",
												userSelect: "none",
											}}
										>
											{`+ ${workers.length - index} more`}
										</Typography>
									);
								} else {
									return null;
								}
							} else {
								// Show all workers
								return (
									<WorkerCell
										key={`shift-${shift.id}-worker-${worker.id}-${index}`}
										name={worker.name}
										workerTypeId={worker.workerTypeId}
										experienceLevel={0}
									/>
								);
							}
						})}
						{(workersSignedForShift < numberOfWorkersNeeded ||
							flexWorkersAllowed) && (
							<Box
								sx={{
									background: backgroundColorForCount,
									padding: "0px 5px",
									borderRadius: "15px",
									justifyContent: "center",
									alignItems: "center",
									width: "36px",
									marginLeft: "auto",
									marginRight: "4px",
									// Workaround for filling complete cell with background color on focus
									marginTop:
										workersSignedForShift > MAX_WORKERS_IN_SHIFT_CELL
											? "-16px"
											: workersSignedForShift === MAX_WORKERS_IN_SHIFT_CELL
											? "-18px"
											: workersSignedForShift > 1
											? "-2px"
											: workersSignedForShift === 0
											? "28px"
											: "14px",
								}}
							>
								<Typography
									sx={{
										fontFamily: "Roboto",
										textAlign: "center",
										fontWeight: 400,
										fontSize: "12px",
										lineHeight: "16px",
										color: isTooDark(backgroundColorForCount)
											? "#FFFFFF"
											: "rgba(0, 0, 0, 0.6)",
									}}
								>{`${workersSignedForShift}/${numberOfWorkersNeeded}`}</Typography>
							</Box>
						)}
					</Box>
				</Box>
			</Box>
			<Menu
				id={`shift-cell-menu-${shift.id}`}
				anchorEl={anchorEl}
				open={open && selectedShift === shift.id}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": `shift-cell-button-${shift.id}`,
				}}
				anchorOrigin={
					isWeekend(selectedDate)
						? {
								vertical: "center",
								horizontal: "left",
						  }
						: {
								vertical: "center",
								horizontal: "right",
						  }
				}
				transformOrigin={
					isWeekend(selectedDate)
						? {
								vertical: "center",
								horizontal: "right",
						  }
						: {
								vertical: "center",
								horizontal: "left",
						  }
				}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						padding: "8px 16px",
						alignItems: "center",
						justifyItems: "flex-start",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "center",
							width: "28px",
						}}
					>
						<AccountCircleOutlinedIcon />
					</Box>
					<Typography
						sx={{
							color: "rgba(0, 0, 0, 0.87)",
							fontFamily: "Roboto",
							fontWeight: 500,
							fontSize: "14px",
							marginLeft: "8px",
						}}
					>
						{`Full time workers (${fullTimeWorkers.length})`}
					</Typography>
				</Box>
				{fullTimeWorkers.map((worker, index) => {
					return (
						<Box
							key={`signed-up-worker-${worker.id}-${shift.id}-${index}`}
							sx={{
								display: "flex",
								flexDirection: "row",
								padding:
									!flexWorkersAllowed &&
									flexWorkers.length === 0 &&
									index === fullTimeWorkers.length - 1
										? "4px 16px 8px 16px"
										: "4px 16px",
								alignItems: "center",
								justifyItems: "flex-start",
							}}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									justifyContent: "center",
									width: "28px",
								}}
							>
								<CheckCircleTwoToneIcon
									sx={{
										color: "#73ac36",
										width: "18px",
									}}
								/>
							</Box>
							<Typography
								sx={{
									color: "rgba(0, 0, 0, 0.87)",
									fontFamily: "Roboto",
									fontWeight: 400,
									fontSize: "14px",
									marginLeft: "8px",
								}}
							>
								{worker.name}
							</Typography>
						</Box>
					);
				})}
				{(flexWorkersAllowed || flexWorkers.length > 0) && (
					<Divider sx={{ marginTop: "8px", marginBottom: "4px" }} />
				)}
				<Box
					sx={{
						display:
							flexWorkersAllowed || flexWorkers.length > 0 ? "flex" : "none",
						flexDirection: "row",
						padding: "8px 16px",
						alignItems: "center",
						justifyItems: "flex-start",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "center",
							width: "28px",
						}}
					>
						<PersonAddAltOutlinedIcon
							sx={{
								width: "19px",
								marginLeft: "4px",
							}}
						/>
					</Box>
					<Typography
						sx={{
							color: "rgba(0, 0, 0, 0.87)",
							fontFamily: "Roboto",
							fontWeight: 500,
							fontSize: "14px",
							marginLeft: "8px",
						}}
					>
						{`Flex workers (${flexWorkers.length})`}
					</Typography>
				</Box>
				{(flexWorkersAllowed || flexWorkers.length > 0) &&
					flexWorkers.map((worker, index) => {
						return (
							<Box
								key={`signed-up-worker-${worker.id}-${shift.id}-${index}`}
								sx={{
									display: "flex",
									flexDirection: "row",
									padding:
										index === flexWorkers.length - 1
											? "4px 16px 8px 16px"
											: "4px 16px",
									alignItems: "center",
									justifyItems: "flex-start",
								}}
							>
								<Box
									sx={{
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
										justifyContent: "center",
										width: "28px",
									}}
								>
									<CheckCircleTwoToneIcon
										sx={{
											color: "#73ac36",
											width: "18px",
										}}
									/>
								</Box>
								<Typography
									sx={{
										color: "rgba(0, 0, 0, 0.87)",
										fontFamily: "Roboto",
										fontWeight: 400,
										fontSize: "14px",
										marginLeft: "8px",
									}}
								>
									{worker.name}
								</Typography>
							</Box>
						);
					})}
				<Box
					onMouseEnter={() => setHover(true)}
					onMouseLeave={() => setHover(false)}
					onClick={() => {
						if (flexShiftId !== 0) {
							viewShiftDetails(flexShiftId);
						}
						handleClose();
					}}
					sx={{
						display:
							flexWorkersAllowed || flexWorkers.length > 0 ? "flex" : "none",
						flexDirection: "row",
						padding: "8px 16px",
						alignItems: "center",
						justifyItems: "flex-start",
						marginTop: "8px",
						cursor: "pointer",
						backgroundColor: hover
							? "rgba(47, 77, 139, 0.08)"
							: "rgba(0,0,0,0)",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "flex-start",
							width: "28px",
						}}
					>
						<ReadMoreIcon
							sx={{
								color: "#2F4D8B",
								width: "16px",
								marginLeft: "4px",
							}}
						/>
					</Box>
					<Typography
						sx={{
							color: "#2F4D8B",
							fontFamily: "Roboto",
							fontWeight: 500,
							fontSize: "12px",
							marginLeft: "8px",
							textTransform: "uppercase",
						}}
					>
						View Details
					</Typography>
				</Box>
			</Menu>
			<Menu
				id={`shift-cell-confirmation-menu-${shift.id}`}
				anchorEl={anchorConfirmationEl}
				open={openConfirmation && selectedShift === shift.id}
				onClose={handleConfirmationClose}
				MenuListProps={{
					"aria-labelledby": `shift-cell-confirmation-button-${shift.id}`,
				}}
				anchorOrigin={
					isWeekend(selectedDate)
						? {
								vertical: "top",
								horizontal: "center",
						  }
						: {
								vertical: "top",
								horizontal: "center",
						  }
				}
				transformOrigin={
					isWeekend(selectedDate)
						? {
								vertical: "bottom",
								horizontal: "right",
						  }
						: {
								vertical: "bottom",
								horizontal: "center",
						  }
				}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						padding: "4px 0px 0px 8px",
						alignItems: "flex-start",
						justifyItems: "center",
					}}
				>
					<Typography
						sx={{
							color: "rgba(0, 0, 0, 0.87)",
							fontFamily: "Roboto",
							fontWeight: 500,
							fontSize: "14px",
							marginLeft: "8px",
						}}
					>
						Are you sure?
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						padding: "8px 16px 8px 8px",
						alignItems: "flex-start",
						justifyItems: "center",
					}}
				>
					<Typography
						sx={{
							color: "rgba(0, 0, 0, 0.87)",
							fontFamily: "Roboto",
							fontSize: "14px",
							marginLeft: "8px",
						}}
					>
						The worker does not have all the required certifications
					</Typography>
					<Typography
						sx={{
							color: "rgba(0, 0, 0, 0.87)",
							fontFamily: "Roboto",
							fontSize: "14px",
							marginLeft: "8px",
						}}
					>
						for the job. Do you want to proceed?
					</Typography>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "flex-end",
							padding: "8px 0px 0px 0px",
							width: "100%",
						}}
					>
						<Button
							onClick={() => {
								handleConfirmationClose();
							}}
							sx={{
								fontFamily: "Roboto",
								fontSize: "12px",
								padding: "4px 8px",
								color: "#2F4D8B",
							}}
							size='small'
						>
							Cancel
						</Button>
						<Button
							onClick={() => {
								assignWorkerToShift({
									shiftId: shift.id,
									dateString: format(selectedDate, "yyyy-MM-dd"),
									workerId: selectedWorker.worker.workerid,
								});
							}}
							sx={{
								fontFamily: "Roboto",
								fontSize: "12px",
								padding: "4px 8px",
								color: "#FFFFFF",
								marginLeft: "4px",
							}}
							variant='contained'
							size='small'
						>
							Continue
						</Button>
					</Box>
				</Box>
			</Menu>
		</Box>
	);
};
