import React from "react";

import BoxWrapper from "../../box-wrapper";
import WorkCenterTable from "./work-center-table";

const WorkCenters = ({
	locations,
	openDialog,
	setWorkCenterData,
	recentlyUpdatedLocationId,
}:any) => {
	if (!locations || !locations.length) return null;
	return (
		<>
			<BoxWrapper
				title='Workcenters'
				subtitle='All workcenters for each locations'
				openDialog={openDialog}
			>
				<WorkCenterTable
					locations={locations}
					setWorkCenterData={setWorkCenterData}
					recentlyUpdatedLocationId={recentlyUpdatedLocationId}
				/>
			</BoxWrapper>
		</>
	);
};

export default WorkCenters;
