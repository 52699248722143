import { Box, Grid, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import format from "date-fns/format";
import React from "react";

import { BORDER_COLOR, TODAY_TEXT_COLOR } from "../constants/colors";
import { dummyJobs } from "../constants/jobs";
import useScheduleController from "../controllers/schedule-controller";

export const WeekHeaderRow = ({
  allJobRoles,
  workCenters,
  changeLocalJobs,
  datesArrayOfSelectedWeek,
  selectedWC,
  setSelectedWC,
  searchValue,
  setSearchValue,
}) => {
  const [, { isWeekend, isToday }] = useScheduleController();
  const searchJob = (text) => {
    setSearchValue(text);
    let returnValue = [];
    returnValue = allJobRoles.filter((job) =>
      job.name.toLowerCase().includes(text.toLowerCase())
    );
    if (returnValue !== undefined) {
      changeLocalJobs(returnValue);
    }
  };
  const DateHeaderCell = ({ date }) => {
    return (
      <Grid
        borderLeft={1}
        borderColor={BORDER_COLOR}
        item
        xs={1.4}
        sx={{
          minWidth: "123px",
          backgroundColor: isWeekend(date) ? "#FAFAFA" : "#FFFFFF",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
            padding: "4px 8px",
            flexDirection: "column",
            height: "72px",
          }}
        >
          <Typography
            sx={{
              color: "#71717A",
              fontSize: "10px",
              fontFamily: "Roboto",
              fontWeight: 700,
              textTransform: "uppercase",
            }}
          >
            {format(date, "eee")}
          </Typography>
          <Typography
            sx={{
              color: isToday(date) ? TODAY_TEXT_COLOR : "#000000",
              fontSize: "22px",
              fontFamily: "Roboto",
              fontWeight: 500,
            }}
          >
            {format(date, "dd")}
          </Typography>
        </Box>
      </Grid>
    );
  };

  return (
    <Grid
      borderTop={1}
      borderBottom={1}
      borderColor={BORDER_COLOR}
      container
      wrap="nowrap"
      sx={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Grid item xs={2.2}>
        <Box
          sx={{
            height: "72px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          {changeLocalJobs !== undefined && (
            <InputBase
              sx={{ ml: 3, flex: 1, minWidth: "123px" }}
              placeholder="Search job"
              inputProps={{ "aria-label": "Search job" }}
              value={searchValue}
              onChange={(event) => searchJob(event.target.value)}
            />
          )}
          {selectedWC !== undefined && (
            <FormControl
              sx={{ m: 1, width: 194, minWidth: 120, margin: "0px 24px" }}
              size="small"
            >
              <InputLabel id="wc-select-small-label">Work Center</InputLabel>
              <Select
                labelId="wc-select-small-label"
                id="wc-select-small"
                value={selectedWC}
                label="Work Center"
                onChange={(e) => {
                  if (e.target.value !== undefined) {
                    setSelectedWC(e.target.value);
                  }
                }}
              >
                {workCenters.map((wc) => (
                  <MenuItem key={`wc-${wc.id}`} value={wc.id}>
                    {wc.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Box>
      </Grid>
      {/* Designed MAX UPTO 7 Days */}
      {datesArrayOfSelectedWeek.map((date, index) => (
        <DateHeaderCell key={`${date.getDay()}-${index}`} date={date} />
      ))}
    </Grid>
  );
};
