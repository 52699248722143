import BlockIcon from "@mui/icons-material/Block";
import {Box, Grid, Tooltip, Typography} from "@mui/material";
import React from "react";

import {Priority} from "../../../../../../../utils/types";

interface SkillWeightTableRowProps {
    skillPriority: Priority;
}

const SkillPriorityTableRow: React.FC<SkillWeightTableRowProps> = ({ skillPriority }) => {
    return (
        <Grid
            container
            sx={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
                paddingBottom: "8px",
            }}
        >
            <Grid
                item
                xs={1}
                display='flex'
                alignItems='center'
                justifyContent='center'
            >
                <Typography
                    sx={{
                        fontFamily: "Roboto",
                        fontWeight: 400,
                        fontSize: "14px",
                        color: "rgba(0, 0, 0, 0.87)",
                        textTransform: "uppercase",
                    }}
                >
                    {skillPriority.code ?? ""}
                </Typography>
            </Grid>
            <Grid
                item
                xs={1}
                display='flex'
                alignItems='center'
                justifyContent='center'
            >
                {skillPriority.hexColor !== "" &&
                skillPriority.hexColor !== null ? (
                    <Box
                        sx={{
                            width: "18px",
                            height: "18px",
                            backgroundColor: `${skillPriority.hexColor ? `#${skillPriority.hexColor}` : "rgba(0,0,0,0)"}`,
                            border: 1,
                            borderWidth: "1px",
                            borderColor: "rgba(0,0,0,0.4)",
                            borderRadius: "9px",
                        }}
                    />
                ) : (
                    <BlockIcon
                        sx={{
                            width: "18px",
                            height: "18px",
                        }}
                    />
                )}
            </Grid>
            <Grid
                item
                xs={2}
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
            >
                <Tooltip title={skillPriority.name ?? ""} arrow>
                    <Typography
                        noWrap
                        sx={{
                            fontFamily: "Roboto",
                            fontWeight: 400,
                            fontSize: "14px",
                            color: "rgba(0, 0, 0, 0.87)",
                            marginLeft: "12px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            maxWidth: "100%", // Adjust as needed
                        }}
                    >
                        {skillPriority.name ?? ""}
                    </Typography>
                </Tooltip>
            </Grid>
            <Grid
                item
                xs={6}
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
            >
                <Tooltip title={skillPriority.description ?? ""} arrow>
                    <Typography
                        noWrap
                        sx={{
                            fontFamily: "Roboto",
                            fontWeight: 400,
                            fontSize: "14px",
                            color: "rgba(0, 0, 0, 0.87)",
                            width: "100%",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            wordBreak: "break-word",
                        }}
                    >
                        {skillPriority.description ?? ""}
                    </Typography>
                </Tooltip>
            </Grid>
            <Grid
                item
                xs={1}
                display='flex'
                alignItems='center'
                justifyContent='flex-start'
            >
                <Typography
                    sx={{
                        fontFamily: "Roboto",
                        fontWeight: 400,
                        fontSize: "14px",
                        color: "rgba(0, 0, 0, 0.87)",
                        width: "100%",
                        wordBreak: "break-word",
                        marginLeft: "12px",
                    }}
                >
                    {skillPriority.multiplier ?? 0}
                </Typography>
            </Grid>
            <Grid
                item
                xs={1}
                display='flex'
                alignItems='center'
                justifyContent='center'
                pr={1}
                height={"35px"}
            >
                <Typography
                    sx={{
                        fontFamily: "Roboto",
                        fontWeight: 400,
                        fontSize: "14px",
                        color: "rgba(0, 0, 0, 0.87)",
                        wordBreak: "break-word",
                        marginLeft: "8px",
                    }}
                >{`${skillPriority.isDefault ? "Yes" : "-"}`}</Typography>
            </Grid>
        </Grid>
    );
};

export default SkillPriorityTableRow;