import {Box, Tooltip, Typography} from "@mui/material";
import React from "react";

type Props = {
    jobRole: {
        hexColor: string;
        textColor: string,
        name: string;
    };
};

const JobRole = ({ jobRole }: Props) => {
    const { hexColor, textColor, name } = jobRole;
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
        }}>
            <Tooltip title={name}>
                <Box
                    sx={{
                        borderRadius: "8px",
                        paddingTop: "4px",
                        paddingBottom: "4px",
                        paddingLeft: "8px",
                        paddingRight: "8px",
                        backgroundColor: `#${hexColor}`,
                        border: `1px solid #${hexColor}`,
                        marginRight: "8px"
                    }}
                >
                    <Typography variant="body2"
                                sx={{
                        display: "box",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        boxOrient: "vertical",
                        overflow: "hidden",
                        color: textColor,
                        width: "192px"
                    }}>
                        {name}
                    </Typography>
                </Box>
            </Tooltip>
        </Box>
    );
};

export default JobRole;