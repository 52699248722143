// Color constants
export const FLEX_WORKERS_FULFILLED_ICON_COLOR = "#7CB342";
export const FLEX_WORKERS_FULFILLED_COUNT_COLOR = "#C8E6C9";
export const FLEX_WORKERS_PENDING_ICON_COLOR = "#FB8C00";
export const FLEX_WORKERS_PENDING_COUNT_COLOR = "#FFB74D";
export const WORKERS_REQUIRED_COUNT_COLOR = "#E44B48";
export const OVERTIME_COLOR = "#2F4D8B";
export const TODAY_HIGHLIGHT_COLOR = "rgba(229,240,255,0.5)";
export const TODAY_TEXT_COLOR = "#2F4D8B";
export const BORDER_COLOR = "#E0E0E0";
export const DRAG_INDICATOR_ICON_COLOR = "#18253E";
export const WEEKEND_SHIFT_CELL_COLOR = "rgba(245, 245, 245,0.5)";
export const EMPTY_SHIFT_CELL_COLOR = "rgba(255,255,255,0.5)";
export const SHIFT_CELL_FOCUS_COLOR = "rgba(207,217,239,0.5)";
export const FLEX_WORKERS_CARD_COLOR = "#2F4D8B";
export const FULL_TIME_WORKER_CARD_COLOR = "#B0BEC5";
