// TODO - Type 'any' needs to be fixed.
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import React from "react";

type ReviewWorkerAssignShiftCheckboxProps = {
    iAmSure?: boolean;
    setIAmSure?: (value: boolean) => void;
};

const ReviewWorkerAssignShiftCheckbox = ({
  iAmSure,
  setIAmSure,
}: ReviewWorkerAssignShiftCheckboxProps) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={iAmSure}
          onChange={() => setIAmSure && setIAmSure(!iAmSure)}
          name="amISureCheckbox"
          id="amISureCheckbox"
          color="primary"
        />
      }
      label="I am sure that I want to assign selected workers to this shift."
      style={{ marginRight: "auto", marginLeft: "9px" }}
    />
  );
};

export default ReviewWorkerAssignShiftCheckbox;
