import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

interface Skill {
	skillId: string;
	skillLevel: string;
}

interface Job {
	id: string;
}

const initialState = {
	skillsMatrix: {
		status: "idle",
		error: "",
		workers: [],
		workerMap: {},
		jobMap: {},
		skillMap: {},
		skills: [],
		skillLevels: [],
		jobs: [],
		locations: [],
		filters: [],
		managers: [],
		workerTypes: [],
		workcenters: [],
	},
};

export const fetchInitialSkillsMatrixData = createAsyncThunk(
	"skills-matrix/fetchInitialSkillsMatrixData",
	async (payload: number, thunkApi) => {
		try {
			const getFilters = axios.get(
				`${process.env.REACT_APP_API_END_POINT}/SkillsMatrix/GetFilters`
			);
			const getWorkers = axios.get(
				`${process.env.REACT_APP_API_END_POINT}/companies/workers?getAll=true`
			);
			const getSkills = axios.get(
				`${process.env.REACT_APP_API_END_POINT}/Skill/GetSkillsByUser`
			);
			const getJobs = axios.get(
				`${process.env.REACT_APP_API_END_POINT}/Jobs/GetJobsByUser?userid=${payload}`
			);

			const getSkillLevels = axios.get(
				`${process.env.REACT_APP_API_END_POINT}/Skill/Levels`
			);

			// const getLocations = axios.get(
			// 	`${process.env.REACT_APP_API_END_POINT}/Locations/GetLocationsByUser?userid=${payload}`
			// );

			const [
				filters,
				workers,
				skills,
				jobs,
				skillLevels,
				//locations,
			] = await Promise.all([
				getFilters,
				getWorkers,
				getSkills,
				getJobs,
				getSkillLevels,
				//getLocations,
			]);
			const result = {
				filters: filters.data,
				workers: workers.data,
				skills: skills.data,
				jobs: jobs.data,
				skillLevels: skillLevels.data,
				//locations: locations.data,
			};
			return thunkApi.fulfillWithValue(result);
		} catch (error) {
			return thunkApi.rejectWithValue(error);
		}
	}
);

export const SkillsMatrixSlice = createSlice({
	name: "skillsMatrix",
	initialState,
	reducers: {
		resetSkillsMatrix: (state) => {
			state.skillsMatrix = initialState.skillsMatrix;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchInitialSkillsMatrixData.pending, (state) => {
				state.skillsMatrix.status = "pending";
			})
			.addCase(fetchInitialSkillsMatrixData.fulfilled, (state, action) => {
				// console.log("action.payload", action.payload);
				state.skillsMatrix.status = "fulfilled";
				const { workers, skills, jobs, filters, skillLevels } = action.payload;
				state.skillsMatrix.workers = workers;

				const workerMap: Record<string, Worker> = workers.reduce(
					(
						acc: { [x: string]: any },
						worker: { id: string | number; skills: any[] }
					) => {
						acc[worker.id] = {
							...worker,
							skills: worker.skills.reduce((skillAcc, skill) => {
								skillAcc[skill.skillId] = skill;
								return skillAcc;
							}, {} as Record<string, Skill>),
						};
						return acc;
					},
					{} as Record<string, Worker>
				);

				const jobMap = jobs.reduce(
					(acc: { [x: string]: any }, job: { id: string | number }) => {
						acc[job.id] = job;
						return acc;
					},
					{} as Record<string, Job>
				);

				const skillMap = skills.reduce(
					(acc: { [x: string]: any }, skill: { id: string | number }) => {
						acc[skill.id] = skill;
						return acc;
					},
					{} as Record<string, Skill>
				);

				state.skillsMatrix.workerMap = workerMap;
				state.skillsMatrix.jobMap = jobMap;
				state.skillsMatrix.skillMap = skillMap;

				state.skillsMatrix.skills = skills;
				state.skillsMatrix.jobs = jobs;
				state.skillsMatrix.locations = filters?.locations;
				state.skillsMatrix.filters = filters;
				state.skillsMatrix.skillLevels = skillLevels;
				//state.skillsMatrix.locations = locations;

				state.skillsMatrix.managers = filters?.managers;
				state.skillsMatrix.workerTypes = filters?.workerTypes;
				state.skillsMatrix.workcenters = filters?.workcenters;
			})
			.addCase(fetchInitialSkillsMatrixData.rejected, (state, action) => {
				state.skillsMatrix.status = "rejected";
				state.skillsMatrix.error = action.error.message ?? "";
			});
	},
});

export const { resetSkillsMatrix } = SkillsMatrixSlice.actions;

export default SkillsMatrixSlice.reducer;
