import { Grid } from "@mui/material";
import React from "react";

import {
	ShiftAllocationRules,
	ShiftAllocationRulesEmpty,
	//ShiftForceAssignment,
	ShiftScheduleRules,
	Teams,
} from "../../../../../../export/gat-admin";
import SectionWrapper from "../../../section-wrapper";
import SectionActions from "../../section-actions";
import useShiftSettingsController from "./controllers/use-shift-settings-controller";
import JobExpiration from "./job-expiration/job-expiration";
import ShiftByName from "./shift-by-name";
import ShiftCancellation from "./shift-cancellation";
import ShiftSwapRules from "./shift-swap-rules";

interface IShiftSettingsProps {
	locationData: any;
	creatingNewLocation: boolean;
	companySettings?: any;
	setCompanySettings?: any;
	entity: string;
}
const ShiftSettings: React.FC<IShiftSettingsProps> = ({
	locationData,
	creatingNewLocation,
	companySettings,
	setCompanySettings,
	entity,
}) => {
	const [
		{
			shiftSettings,
			newShiftSettings,
			shiftSettingsLocationData,
			newShiftSettingsLocationData,
			loading,
		},
		{
			setNewShiftSettings,
			handleSave,

			setNewShiftSettingsLocationData,
		},
	]: any = useShiftSettingsController(entity, locationData);

	return (
		<SectionWrapper
			title='Shift Settings'
			subtitle='Worker shift related settings.'
		>
			<Grid
				container
				sx={{
					paddingTop: "16px",
					paddingBottom: "16px",
				}}
			>
				<Grid item xs={12}>
					{entity !== "location" ? (
						<>
							<ShiftSwapRules
								newShiftSettings={newShiftSettings}
								setNewShiftSettings={setNewShiftSettings}
							/>
							{/* <ShiftByName 
								newShiftSettings={newShiftSettings}
								setNewShiftSettings={setNewShiftSettings}
							/> */}
							<ShiftCancellation
								newShiftSettings={newShiftSettings}
								setNewShiftSettings={setNewShiftSettings}
							/>
							{/* <ShiftForceAssignment
								newShiftSettings={newShiftSettings}
							// setNewShiftSettings={setNewShiftSettings}
							/> */}
							<JobExpiration
								companySettings={companySettings}
								setCompanySettings={setCompanySettings}
								newShiftSettings={newShiftSettings}
								setNewShiftSettings={setNewShiftSettings}
							/>
						</>
					) : null}
					{!creatingNewLocation && entity === "location" ? (
						<>
							<ShiftScheduleRules locationData={locationData} />
							<Teams />
							{Object.keys(newShiftSettings).length === 0 ? (
								<ShiftAllocationRulesEmpty />
							) : (
								<ShiftAllocationRules
									locationData={newShiftSettingsLocationData}
									setLocationData={setNewShiftSettingsLocationData}
									creatingNewLocation={creatingNewLocation}
								/>
							)}
						</>
					) : null}
				</Grid>
			</Grid>
			<SectionActions
				oldData={{ ...shiftSettings, ...shiftSettingsLocationData }}
				newData={{ ...newShiftSettings, ...newShiftSettingsLocationData }}
				resetChanges={() => {
					setNewShiftSettings(shiftSettings);
					setNewShiftSettingsLocationData(shiftSettingsLocationData);
				}}
				handleSave={handleSave}
				//status={updateCompanySettingsStatus}
				loading={loading}
			/>
		</SectionWrapper>
	);
};

export default ShiftSettings;
