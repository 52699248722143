import { InfoOutlined } from "@mui/icons-material";
import { Box, Grid, Switch, TextField, Typography } from "@mui/material";
import React from "react";

import { colors } from "../../../../../../../ts/config/colors";

const FlexHoursRestriction = ({ newLimitControl, setNewLimitControl }:any) => {
	return (
		<Box
			sx={{
				backgroundColor: "#F5F5F5",
				borderRadius: "8px",
				marginTop: "32px",
				padding: "16px",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 400,
							fontSize: "16px",
							color: "rgba(0, 0, 0, 0.87)",
						}}
					>
						Restriction on flex hours posted on a job
					</Typography>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 400,
							fontSize: "12px",
							color: "rgba(0, 0, 0, 0.6)",
						}}
					>
						Define rules for the maximum flex work hours that can be posted on a
						job.
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Switch
						checked={newLimitControl.restrictFlexHours ?? false}
						onChange={(e) =>
							setNewLimitControl((pre: any) => ({
								...pre,
								restrictFlexHours: e.target.checked,
							}))
						}
					/>
				</Box>
			</Box>
			{newLimitControl.restrictFlexHours && (
				<Box
					sx={{
						marginTop: "32px",
					}}
				>
					<Grid container>
						<Grid item>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
								}}
							>
								<Typography
									sx={{
										fontFamily: "Roboto",
										fontWeight: 400,
										fontSize: "16px",
										color: "rgba(0, 0, 0, 0.87)",
									}}
								>
									Maximum flex hours
								</Typography>
								<Typography
									sx={{
										fontFamily: "Roboto",
										fontWeight: 400,
										fontSize: "12px",
										color: "rgba(0, 0, 0, 0.6)",
										paddingBottom: "8px",
									}}
								>
									Maximum number of flex hours that can be posted for a job.
								</Typography>
								<TextField
									id='maximum-flex-hours-value'
									variant='filled'
									type='number'
									sx={{
										"& .MuiInputBase-input": {
											background: "#FFF",
										},
									}}
									label=''
									onFocus={(event) => {
										event.target.select();
									}}
									onChange={(e) => {
										let value =
											parseInt(e.target.value) >= 1
												? parseInt(e.target.value)
												: 1;

										value = value > 2147483647 ? 2147483647 : value;
										setNewLimitControl((pre: any) => ({
											...pre,
											maxFlexHrsPerMonth: value,
										}));
									}}
									value={
										newLimitControl.maxFlexHrsPerMonth === 2147483647
											? 1
											: newLimitControl.maxFlexHrsPerMonth
									}
									helperText='hours in a month'
								/>
								<Typography
									sx={{
										color: "rgba(0, 0, 0, 0.60)",
										fontFamily: "Roboto",
										fontSize: "14px",
										fontWeight: 400,
										marginTop: "32px",
									}}
								>
									^ shifts that are published via “skills” will be excluded from
									this configuration/feature
								</Typography>
								<Box
									sx={{
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
										justifyContent: "flex-start",
										marginTop: "24px",
										padding: "12px 16px",
										backgroundColor: colors.adminInfoBackgroundColor,
										borderRadius: "4px",
									}}
								>
									<InfoOutlined
										sx={{
											color: colors.adminInfoIconColor,
										}}
									/>
									<Typography
										sx={{
											color: colors.adminInfoTextColor,
											fontFamily: "Roboto",
											fontSize: "14px",
											fontWeight: 400,
											marginLeft: "14px",
										}}
									>
										Once the quota is crossed, the supervisor will not be able
										to post any new shifts for that job in that month
									</Typography>
								</Box>
							</Box>
						</Grid>
					</Grid>
				</Box>
			)}
		</Box>
	);
};

export default FlexHoursRestriction;
