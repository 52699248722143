// TODO - Type 'any' needs to be fixed.
import React, { useCallback, useMemo } from "react";

import useWorkersData from "../../../controllers/use-workers-data";
import { getAllSkills } from "../../helper";

const useCertificationsFormController = () => {
	const {
		workerProfile,
		allCertifications,
		allSkills,
		addWorkerCertificationStatus,
		addWorkerCertificationError,
		obsoleteCertificates,
	} = useWorkersData();

	const [selectedCertifications, setSelectedCertifications] = React.useState<
		number[]
	>([]);

	const jobs = workerProfile?.jobs || [];
	const otherSkills = workerProfile?.otherSkills || [];
	const workerCerts =
		workerProfile?.workerCerts.map((cert: any) => cert.companyCertId) || [];

	const certificationsMap = useMemo(() => {
		return allCertifications.reduce(
			(
				acc: Record<string, any>,
				cert: { id: string | number; isActive: boolean }
			) => {
				if (!cert.isActive) return acc;
				acc[cert.id] = cert;
				return acc;
			},
			{}
		);
	}, [allCertifications]);

	const skillsMap = useMemo(() => {
		return allSkills.reduce(
			(
				acc: Record<string, any>,
				skill: { certificationIds: string; id: string | number }
			) => {
				const certIds = skill?.certificationIds?.split(",") ?? [];
				const certs = certIds
					.map((id: string) => certificationsMap[id])
					.filter((cert: any) => cert !== undefined);
				acc[skill.id] = certs;
				return acc;
			},
			{}
		);
	}, [allSkills, certificationsMap]);

	const workerSkills = getAllSkills(jobs, otherSkills);

	const workerSkillsWithCerts = workerSkills
		.map((skill: { skillId: number }) => {
			const certs =
				skillsMap[skill.skillId]?.filter(
					(c: { id: number }) => !workerCerts.includes(c.id)
				) || [];
			return { ...skill, certs };
		})
		.filter((skill: any) => skill.certs.length > 0);

	// console.log(
	// 	// 	"workerSkillsWithCerts",
	// 	// 	workerSkillsWithCerts,
	// 	"workerSkills",
	// 	workerSkills,
	// 	// 	"workerCerts",
	// 	// 	workerCerts,
	// 	"skillsMap",
	// 	skillsMap
	// );

	const unassignedCertsWithSkill = workerSkillsWithCerts.reduce(
		(acc: Record<string, any>, skill: any) => {
			const certIds = skill.certs.map((c: { id: number }) => c.id);
			acc.push(...certIds);
			return acc;
		},
		[]
	);
	const additionalSkills = allCertifications.filter((cert: any) => {
		const isNotAssigned = !workerCerts.includes(cert.id);
		const isInUnassignedWithSkill = !unassignedCertsWithSkill.includes(cert.id);
		const obsoleteCerts = obsoleteCertificates.includes(cert.id);
		return isNotAssigned && isInUnassignedWithSkill && !obsoleteCerts;
	});

	//console.log("workerSkillsWithCerts", unassignedCertsWithSkill);

	const handleSelection = useCallback((certId: number) => {
		setSelectedCertifications((prevSelectedCertifications) => {
			const index = prevSelectedCertifications.indexOf(certId);
			if (index === -1) {
				return [...prevSelectedCertifications, certId];
			} else {
				return prevSelectedCertifications.filter(
					(selectedId) => selectedId !== certId
				);
			}
		});
	}, []);

	const removeMultipleCerts = useCallback(
		(certIds: number[]) => {
			setSelectedCertifications((prevSelectedCertifications) => {
				return prevSelectedCertifications.filter(
					(selectedId) => !certIds.includes(selectedId)
				);
			});
		},
		[setSelectedCertifications]
	);

	return [
		{
			workerSkillsWithCerts,
			skillsMap,
			selectedCertifications,
			addWorkerCertificationStatus,
			addWorkerCertificationError,
			workerCerts,
			additionalSkills,
			obsoleteCertificates,
			workerSkills,
		},
		{
			handleSelection,
			removeMultipleCerts,
		},
	];
};

export default useCertificationsFormController;
