import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Box, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { TextField } from "formik-mui";
import { filter } from "lodash";
import React, { useState } from "react";

import CheckboxPopover from "../../../../../ts/design/checkbox-popover";
import ColumnSelector from "../../../../../ts/design/column-selector";
import Searchbar from "../../../../../ts/design/search-bar";
import TimeFilterSelect from "../../../../design/time-filter-select";
import { RequestStatus } from "../../store/worker-requests-types";
interface WorkerRequestHeaderProps {
	search?: undefined | ((value: string) => void);
	value: string | undefined;
	columns?: any[]; // Replace with the actual type of your columns
	statusOptions?: RequestStatus[];
	setStatusOptions?: (options: RequestStatus[]) => void;
	handleSelectColumns?: (checked: boolean, field: string) => void;
	// onTimeFilterChange?: (event: SelectChangeEvent<string>) => void;
	setTimeFilterOption?: any;
	timeFilterOption?: any;
}

const WorkerRequestHeader: React.FC<WorkerRequestHeaderProps> = ({
	search,
	value,
	columns,
	handleSelectColumns,
	statusOptions = [],
	setStatusOptions = () => {
		("");
	},
	setTimeFilterOption,
	timeFilterOption,
}) => {
	const [anchorElement, setAnchorElement] = useState(null);
	const timeFilterOptions = [
		{ label: "Last 30 Days", value: "last_30_days" },
		{ label: "Last 90 Days", value: "last_90_days" },
		{ label: "This Year", value: "this_year" },
		{ label: "Till Today", value: "till_today" },
	];

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElement(event.currentTarget as any);
	};

	const handleClose = () => {
		setAnchorElement(null);
	};

	const getStatusText = () => {
		const selectedOptions = statusOptions.filter((option) => option.checked);
		if (selectedOptions.length === 1) {
			return selectedOptions[0].label;
		} else if (selectedOptions.length === statusOptions.length) {
			return "All Status";
		} else if (selectedOptions.length > 1) {
			return `Status(${selectedOptions.length})`;
		}
		return "None";
	};
	// console.log("columns", columns);
	return (
		<Box
			display='flex'
			alignItems='center'
			justifyContent='space-between'
			// my={1}
		>
			<Box display='flex' alignItems={"center"}>
				<ColumnSelector
					columns={columns}
					handleSelectColumns={handleSelectColumns}
				/>
				<Searchbar
					placeholder={"Search"}
					width={"35ch"}
					variant={"filled"}
					setSearch={(value) =>
						search && search(value)
					}
					value={value}
				/>
			</Box>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
				}}
			>
				<Box>
					<Box
						onClick={handleClick}
						sx={{
							padding: "12px",
							background: "#f8f8f8",
							borderRadius: "4px",
							display: "flex",
							gap: "4px",
							cursor: "pointer",
							minWidth: "150px",
							justifyContent: "center",
						}}
					>
						<Typography variant='body1'>{getStatusText()}</Typography>
						{anchorElement ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
					</Box>
					<CheckboxPopover
						options={statusOptions}
						anchorElement={anchorElement}
						onClose={handleClose}
						setOptions={setStatusOptions}
						minWidth={"150px"}
						disableMaxHeight
					/>
				</Box>
				<TimeFilterSelect
					value={timeFilterOption}
					onChange={(event) => setTimeFilterOption(event.target.value)}
					options={timeFilterOptions}
				/>
			</Box>
		</Box>
	);
};

export default WorkerRequestHeader;
