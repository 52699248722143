import addDays from "date-fns/addDays";

import { Skill } from "../../../components/workers/types";
import {
  FLEX_WORKER,
  FULL_TIME_WORKER,
  PART_TIME_WORKER,
} from "../config/constants";
import { SettingsData, Shift } from "../types";

export const getLocationFullAddressString = (locationAddress: {
  name: string;
  address1: string;
  city: string;
  state: string;
}) => {
  let returnValue = "-";
  try {
    let name = "";
    let address = "";
    let city = "";
    let state = "";
    if (locationAddress.name) {
      name = locationAddress.name ?? "";
      returnValue = `${name !== "" ? name : ""}`;
    }
    if (locationAddress.address1) {
      address = locationAddress.address1 ?? "";
      returnValue = `${
        returnValue !== "" && address !== ""
          ? `${returnValue}, ${address}`
          : returnValue
      }`;
    }
    if (locationAddress.city) {
      city = locationAddress.city ?? "";
      returnValue = `${
        returnValue !== "" && city !== ""
          ? `${returnValue}, ${city}`
          : returnValue
      }`;
    }
    if (locationAddress.state) {
      state = locationAddress.state ?? "";
      returnValue = `${
        returnValue !== "" && state !== ""
          ? `${returnValue}, ${state}`
          : `${returnValue === "" ? (state !== "" ? state : "-") : returnValue}`
      }`;
    }
    return returnValue;
  } catch (e) {
    return returnValue;
  }
};

export const isFullTimeWorker = (settingsData: any): boolean => {
  try {
    let returnValue = false;
    if (settingsData) {
      if (
        Object.prototype.hasOwnProperty.call(settingsData, "workerProfileInfo")
      ) {
        if (settingsData.workerProfileInfo) {
          if (
            Object.prototype.hasOwnProperty.call(
              settingsData.workerProfileInfo,
              "workerCompanies"
            )
          ) {
            if (settingsData.workerProfileInfo.workerCompanies.length > 0) {
              const selectedCompany =
                settingsData.workerProfileInfo.workerCompanies.find(
                  (company: any) =>
                    parseInt(company.companyId) ===
                    parseInt(settingsData.companyId)
                );
              if (selectedCompany) {
                if (
                  Object.prototype.hasOwnProperty.call(
                    selectedCompany,
                    "workerTypeName"
                  )
                ) {
                  if (selectedCompany.workerTypeName === FULL_TIME_WORKER) {
                    returnValue = true;
                  }
                }
              }
            }
          }
        }
      }
    }
    return returnValue;
  } catch (e) {
    return false;
  }
};

export const isPartTimeWorker = (settingsData: any): boolean => {
  try {
    let returnValue = false;
    if (settingsData) {
      if (
        Object.prototype.hasOwnProperty.call(settingsData, "workerProfileInfo")
      ) {
        if (settingsData.workerProfileInfo) {
          if (
            Object.prototype.hasOwnProperty.call(
              settingsData.workerProfileInfo,
              "workerCompanies"
            )
          ) {
            if (settingsData.workerProfileInfo.workerCompanies.length > 0) {
              const selectedCompany =
                settingsData.workerProfileInfo.workerCompanies.find(
                  (company: any) =>
                    parseInt(company.companyId) ===
                    parseInt(settingsData.companyId)
                );
              if (selectedCompany) {
                if (
                  Object.prototype.hasOwnProperty.call(
                    selectedCompany,
                    "workerTypeName"
                  )
                ) {
                  if (selectedCompany.workerTypeName === PART_TIME_WORKER) {
                    returnValue = true;
                  }
                }
              }
            }
          }
        }
      }
    }
    return returnValue;
  } catch (e) {
    return false;
  }
};

export const getSelectedCompany = (settingsData: SettingsData): any => {
  let returnValue: any = {};
  try {
    if (settingsData) {
      const companyId = settingsData.companyId;
      let companyFound = false;
      if (
        Object.prototype.hasOwnProperty.call(settingsData, "workerProfileInfo")
      ) {
        if (
          Object.prototype.hasOwnProperty.call(
            settingsData.workerProfileInfo,
            "workerCompanies"
          )
        ) {
          if (settingsData.workerProfileInfo.workerCompanies.length > 0) {
            settingsData.workerProfileInfo.workerCompanies.forEach(
              (company: any, index: number) => {
                if (companyId !== undefined) {
                  if (parseInt(companyId) === parseInt(company.companyId)) {
                    returnValue = company;
                    companyFound = true;
                  }
                }
                if (
                  Object.prototype.hasOwnProperty.call(
                    company,
                    "workerTypeName"
                  ) &&
                  !companyFound
                ) {
                  if (company.workerTypeName === FULL_TIME_WORKER) {
                    returnValue = company;
                    companyFound = true;
                  } else if (
                    settingsData.workerProfileInfo.workerCompanies.length -
                      1 ===
                      index &&
                    !companyFound
                  ) {
                    returnValue = company;
                  }
                }
              }
            );
          }
        }
      }
    }
    return returnValue;
  } catch (e) {
    return returnValue;
  }
};

//Add date time as per the api specification to a bunch of shifts in an array
export const addStartDateEndDate = (allShiftsData: any) => {
  if (allShiftsData) {
    return allShiftsData.map((shift: any) => {
      let startDate;
      let endDate;
      if (
        Object.prototype.hasOwnProperty.call(shift, "startDateTime") &&
        Object.prototype.hasOwnProperty.call(shift, "endDateTime")
      ) {
        // For upcoming and available shift getting these start and end time keys in response
        startDate = shift.startDateTime;
        endDate = shift.endDateTime;
      } else if (
        Object.prototype.hasOwnProperty.call(shift, "shiftStartTime") &&
        Object.prototype.hasOwnProperty.call(shift, "shiftEndTime")
      ) {
        // For regular shift getting these start and end time keys in response
        startDate = shift.shiftStartTime;
        endDate = shift.shiftEndTime;
        // Modifying shift object so that both regular and normal shift array can be used together
        shift.startDateTime = shift.shiftStartTime;
        shift.endDateTime = shift.shiftEndTime;
      }
      shift.startDate = startDate;
      shift.endDate = endDate;
      return shift;
    });
  } else {
    return [];
  }
};

export const getWeeklyHoursLimitMessage = (
  settingsData: SettingsData,
  startDateTime: string,
  endDateTime: string
): string => {
  try {
    let returnValue = "";
    const startDate = new Date(startDateTime);
    const endDate = new Date(endDateTime);
    const shiftDurationInHours = Math.abs(
      Math.ceil((endDate.getTime() - startDate.getTime()) / (60 * 60 * 1000))
    );
    let workerTypeName = FLEX_WORKER;
    let maxWorkHours = 0;
    let remainingWorkHours = 0;

    // Get selected company weekly hours limit and worker type
    if (settingsData) {
      if (
        Object.prototype.hasOwnProperty.call(settingsData, "maxWorkHours") &&
        Object.prototype.hasOwnProperty.call(
          settingsData,
          "remainingWorkHours"
        ) &&
        Object.prototype.hasOwnProperty.call(settingsData, "workerTypeName")
      ) {
        workerTypeName = settingsData.workerTypeName ?? FLEX_WORKER;
        maxWorkHours = settingsData.maxWorkHours ?? 0;
        remainingWorkHours = settingsData.remainingWorkHours ?? 0;
      } else {
        const selectedCompany = getSelectedCompany(settingsData);
        if (
          selectedCompany &&
          Object.prototype.hasOwnProperty.call(
            selectedCompany,
            "maxWorkHours"
          ) &&
          Object.prototype.hasOwnProperty.call(
            selectedCompany,
            "remainingWorkHours"
          ) &&
          Object.prototype.hasOwnProperty.call(
            selectedCompany,
            "workerTypeName"
          )
        ) {
          workerTypeName = selectedCompany.workerTypeName ?? FLEX_WORKER;
          maxWorkHours = selectedCompany.maxWorkHours ?? 0;
          remainingWorkHours = selectedCompany.remainingWorkHours ?? 0;
        }
      }
    }
    const fullTimeOrPartTimeWorker =
      workerTypeName === FULL_TIME_WORKER ||
      workerTypeName === PART_TIME_WORKER;
    if (fullTimeOrPartTimeWorker) {
      if (remainingWorkHours > 0) {
        if (shiftDurationInHours > remainingWorkHours) {
          // If shift exceed remaining work hours
          returnValue = `You will exceed the weekly allowed ${maxWorkHours} overtime hours. Please sign up for a shift less than ${remainingWorkHours} hours.`;
        }
      } else {
        // If there is no remaining work hours
        returnValue = `You’ve reached the weekly allowed ${maxWorkHours} overtime hours.`;
      }
    } else {
      // For flex workers
      if (remainingWorkHours > 0) {
        if (shiftDurationInHours > remainingWorkHours) {
          // If shift exceed remaining work hours
          returnValue = `You will exceed the weekly allowed ${maxWorkHours} flex hours. Please sign up for a shift less than ${remainingWorkHours} hours.`;
        }
      } else {
        // If there is no remaining work hours
        returnValue = `You’ve reached the weekly allowed ${maxWorkHours} flex hours. `;
      }
    }
    return returnValue;
  } catch (e) {
    return "";
  }
};

export const jobExpirationNoticeText = (
  jobName: string | undefined,
  bySupervisor = false,
  days = 5,
  isMyShift = false,
) => {
  let returnValue;
  if (bySupervisor) {
    returnValue =
      "Your job is made inactive by your supervisor." +
      " Please contact your supervisor to be able to pick" +
      " the shifts of \"" +
      jobName +
      "\".";
  } else {
    returnValue =
      "You did not perform a sufficient number of \"" +
      jobName +
      "\"" +
      " shifts in the last " +
      days +
      " days. Please" +
      " contact your supervisor to get re-trained.";
  }
  if (isMyShift) {
    returnValue = returnValue + " You are still expected to attend this shift."
  }
  return returnValue;
};

export const overwriteShiftObjectsWithIds = (
  array: Shift[],
  newDataArray: { id: number; [key: string]: any }[]
) => {
  newDataArray.forEach((newData) => {
    const index = array.findIndex((item) => item.id === newData.id);

    if (index !== -1) {
      array[index] = newData as Shift;
    } else {
      array.push(newData as Shift);
    }
  });

  return array;
};

export const parseLocalDate = (dateString: string): Date => {
  const isoRegex =
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|[+-]\d{2}:\d{2})$/;
  const simpleDateRegex = /^\d{4}-\d{2}-\d{2}$/;

  if (isoRegex.test(dateString)) {
    return new Date(dateString);
  } else if (simpleDateRegex.test(dateString)) {
    const dateParts = dateString.split("-");
    const year = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1; // Month is zero-based
    const day = parseInt(dateParts[2], 10);
    return new Date(year, month, day);
  } else {
    return addDays(new Date(), 1);
  }
};

export const skillExpirationNoticeText = (shiftSkills: Skill[], isMyShift = false): string => {
  let returnValue =
    "One or more skills are expired. Please contact your supervisor for next steps.";

  const expiredSkills = shiftSkills.filter(
    (skill) => skill.isWorkerSkillExpired
  );

  if (expiredSkills.length > 0) {
    const expiredSkillsString = expiredSkills
      .map((skill) => skill.skillName)
      .join(", ");
    returnValue = `The following skill(s) are expired: ${expiredSkillsString}. Please contact your supervisor for next steps.`;
  }

  if (isMyShift) {
    returnValue = returnValue + " You are still expected to attend this shift."
  }
  return returnValue;
};

export const areDatesOneMonthApart = (dateStr1: string, dateStr2: string) => {
  // Extract year and month components from the date strings
  const [year1, month1] = dateStr1.split("-").map(Number);
  const [year2, month2] = dateStr2.split("-").map(Number);

  // Calculate the difference in months
  const monthDiff = (year2 - year1) * 12 + (month2 - month1);

  // Check if the difference is one month or above
  return Math.abs(monthDiff) >= 2;
};

export const getHourMinuteDifference = (
    startDate: Date,
    endDate: Date
) => {
  const returnValue = {
    differenceInText: "0 hour",
    differenceInMinutes: 0,
  };
  if (startDate.getTime() - endDate.getTime() <= 0) {
    return returnValue;
  }
  const timeDifference = Math.abs(startDate.getTime() - endDate.getTime());
  returnValue.differenceInMinutes = Math.ceil(timeDifference / (1000 * 60));

  const hours = Math.floor(timeDifference / (1000 * 60 * 60));
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

  if (hours === 0) {
    returnValue.differenceInText = `${minutes} minute${
        minutes !== 1 ? "s" : ""
    }`;
  } else {
    if (minutes === 0) {
      returnValue.differenceInText = `${hours} hour${hours !== 1 ? "s" : ""}`;
    } else {
      returnValue.differenceInText = `${hours} hour${
          hours !== 1 ? "s" : ""
      } ${minutes} minute${minutes !== 1 ? "s" : ""}`;
    }
  }

  return returnValue;
};
