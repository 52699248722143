// TODO - Type 'any' needs to be fixed.
import DataGrid from "@gigandtake/datagrid";
import SearchIcon from "@mui/icons-material/Search";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button, Grid, Switch, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import useViewport from "../../../../../../utils/use-viewport";
import Highlight from "../../../../../Highlight";

const LocationClusterDialog = ({
  creatingNewLocationCluster,
  onClose,
  locationClusterData,
  setLocationClusterData,
  locations,
  saveLocationCluster,
}: any) => {
  const { height } = useViewport();
  const openLocationClusterDialog = useSelector(
    (state: any) => state.gatAdminConfiguration.openLocationClusterDialog
  );
  const createLocationPoolStatus = useSelector(
    (state: any) => state.gatAdminConfiguration.createLocationPool.status
  );
  const editLocationPoolStatus = useSelector(
    (state: any) => state.gatAdminConfiguration.editLocationPool.status
  );
  const [initialLocationData, setInitialLocationData] = useState<any>({});
  const [selectedLocations, setSelectedLocations] = useState<any>(undefined);
  const [locationsArray, setLocationsArray] = useState([]);
  const [searchedLocationsArray, setSearchedLocationArray] = useState([]);
  const [searchedLocationText, setSearchedLocationText] = useState("");

  const columns: any = useMemo(
    () => [
      {
        field: "name",
        headerName: "Location",
        minWidth: 220,
        flex: 2,
        renderCell: (params: any) => (
          <Highlight
            searchText={searchedLocationText}
            value={params.row.name}
          />
        ),
      },
      {
        field: "address1",
        headerName: "Address",
        minWidth: 240,
        flex: 6,
        renderCell: (params: any) => (
          <Highlight
            searchText={searchedLocationText}
            value={params.row.address1}
          />
        ),
      },
      {
        field: "numOfWorkers",
        headerName: "Workers",
        minWidth: 150,
        flex: 1,
        headerAlign: "center",
        renderCell: (params: any) => {
          const color = params.row.isActive ? "rgba(0,0,0,0.87)" : "#747474";
          return (
            <Typography
              sx={{
                fontFamily: "Roboto",
                color: color,
                textAlign: "center",
                width: "100%",
              }}
            >
              {params.row.numOfWorkers ? params.row.numOfWorkers : "-"}
            </Typography>
          );
        },
      },
      {
        field: "isActive",
        headerName: "Status",
        minWidth: 150,
        flex: 1,
        headerAlign: "center",
        renderCell: (params) => {
          const color = params.row.isActive ? "rgba(0,0,0,0.87)" : "#747474";
          return (
            <Typography
              sx={{
                fontFamily: "Roboto",
                color: color,
                textAlign: "center",
                width: "100%",
              }}
            >
              {params.row.isActive ? "Active" : "Disabled"}
            </Typography>
          );
        },
      },
    ],
    [searchedLocationText]
  );
  const resetLocationDialog = () => {
    setInitialLocationData({});
    setSelectedLocations([]);
    setLocationsArray([]);
    setSearchedLocationArray([]);
    setSearchedLocationText("");
  };
  const closeLocationClusterDialog = () => {
    onClose();
  };

  useEffect(() => {
    resetLocationDialog();
  }, [openLocationClusterDialog]);

  useEffect(() => {
    try {
      if (locations.length > 0) {
        const newLocations: any = [];
        locations.forEach((location: any) => {
          newLocations.push({
            ...location,
            searchValue: `${location.name} ${location.address1}`.toLowerCase(),
            selected: selectedLocations.includes(location.id),
          });
        });
        setLocationsArray(newLocations);
      }
    } catch (e) {
      // Do nothing
    }
  }, [locations, selectedLocations]);

  useEffect(() => {
    try {
      if (searchedLocationText === "") {
        setSearchedLocationArray(locationsArray);
      } else {
        const finalSearchedText = searchedLocationText

          .toLowerCase();
        setSearchedLocationArray(
          locationsArray.filter((x: any) =>
            x.searchValue.includes(finalSearchedText)
          )
        );
      }
    } catch (e) {
      // Do nothing
    }
  }, [searchedLocationText, locationsArray]);

  useEffect(() => {
    try {
      // Only while editing we will get id in locationData
      if (Object.prototype.hasOwnProperty.call(locationClusterData, "id")) {
        if (
          JSON.stringify(initialLocationData) === "{}" &&
          JSON.stringify(locationClusterData) !== "{}"
        ) {
          if (
            Object.prototype.hasOwnProperty.call(
              locationClusterData,
              "locationIds"
            )
          ) {
            const newSelectedLocations = locationClusterData.locationIds
              .split(",")
              .map(Number)
              .sort();
            setSelectedLocations(newSelectedLocations);
            setInitialLocationData(locationClusterData);
          }
        }
      }
    } catch (e) {
      // Do nothing
    }
  }, [initialLocationData, locationClusterData]);
  return (
    <Dialog
      onClose={() => closeLocationClusterDialog()}
      aria-labelledby="add-new-location-cluster"
      open={openLocationClusterDialog}
      maxWidth={false}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <DialogTitle
        id="location-cluster-dialog-title"
        sx={{
          width: "900px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Roboto",
            fontSize: "20px",
            fontWeight: 500,
            color: "rgba(0, 0, 0, 0.87)",
            paddingTop: "29px",
            paddingLeft: "21px",
            paddingBottom: "9px",
            paddingRight: "21px",
          }}
        >
          Location Cluster
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          sx={{
            paddingTop: "12px",
            paddingLeft: "21px",
            paddingRight: "21px",
            paddingBottom: "16px",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Grid item xs={8}>
            <TextField
              label="Cluster Name (required)"
              value={locationClusterData.name ?? ""}
              inputProps={{ maxLength: 100 }}
              onChange={(e) =>
                setLocationClusterData({
                  ...locationClusterData,
                  name: e.target.value,
                })
              }
              variant="filled"
              fullWidth
            />
          </Grid>
          <Grid item xs={1} />
          {Object.prototype.hasOwnProperty.call(locationClusterData, "id") && (
            <Grid item xs={3}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={locationClusterData.isActive ?? true}
                      onChange={(e) =>
                        setLocationClusterData({
                          ...locationClusterData,
                          isActive: e.target.checked,
                          statusChanged:
                            initialLocationData.isActive !== e.target.checked,
                        })
                      }
                    />
                  }
                  label={
                    <Typography
                      sx={{
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        fontWeight: 400,
                        color: "rgba(0,0,0,0.87)",
                      }}
                    >
                      {locationClusterData.isActive ? "Active" : "Disabled"}
                    </Typography>
                  }
                />
              </FormGroup>
            </Grid>
          )}
        </Grid>
        <Grid
          container
          sx={{
            paddingTop: "16px",
            paddingLeft: "21px",
            paddingRight: "21px",
            paddingBottom: "8px",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Grid item xs={12}>
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontSize: "12px",
                fontWeight: 400,
                color: "rgba(0,0,0,0.6)",
              }}
            >
              Select at least 2 locations
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            paddingTop: "8px",
            paddingLeft: "21px",
            paddingRight: "21px",
            paddingBottom: "16px",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Grid item xs={8}>
            <TextField
              size="small"
              sx={{
                width: "100%",
                fontFamily: "Roboto",
                fontSize: "16px",
                fontWeight: 400,
              }}
              InputProps={{
                startAdornment: (
                  <SearchIcon
                    sx={{
                      color: "rgba(0,0,0,0.56)",
                      marginRight: "8px",
                    }}
                  />
                ),
              }}
              placeholder="Search by location or address"
              onChange={(e) => {
                setSearchedLocationText(e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            paddingTop: "12px",
            paddingLeft: "21px",
            paddingRight: "21px",
            paddingBottom: "16px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid item xs={12}>
            <Box
              width={1050}
              height={height - 500 >= 266 ? height - 500 : 266}
              mr="auto"
              ml="auto"
            >
              <DataGrid
                columns={columns}
                rows={searchedLocationsArray}
                keepNonExistentRowsSelected
                // isRowSelectable={(params) => params.row.isActive}
                selectionModel={selectedLocations}
                onSelectionModelChange={(value: any) => {
                  const newSelectedLocations = value.sort();
                  setSelectedLocations(newSelectedLocations);
                  setLocationClusterData({
                    ...locationClusterData,
                    locationIds: newSelectedLocations.toString(),
                  });
                }}
                disableRowSelectionOnClick
                disableColumnSelector
                disableDensitySelector
                disableColumnPinning
                disableColumnReorder
                disableColumnResize
                hideFooter
                checkboxSelection
                getRowHeight={() => 38}
                sx={{
                  border: "none !important",
                  height: "100%",
                  "&>.MuiDataGrid-main": {
                    "& div div div div >.MuiDataGrid-cell": {
                      borderBottom: "none",
                    },
                  },
                  "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within": {
                    outline: "none",
                  },
                  "& .MuiDataGrid-columnSeparator": {
                    display: "none !important",
                  },
                  "& .MuiDataGrid-row": {
                    border: "none !important",
                  },
                  "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                    outline: "none !important",
                  },
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "16px",
            marginBottom: "16px",
            marginRight: "24px",
            marginLeft: "24px",
            width: "100%",
          }}
        >
          <Box />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="outlined"
              onClick={() => closeLocationClusterDialog()}
            >
              Cancel
            </Button>
            <LoadingButton
              disabled={
                JSON.stringify(locationClusterData) ===
                JSON.stringify(initialLocationData) ||
                (Array.isArray(selectedLocations)
                  ? selectedLocations.length < 2
                  : false) ||
                locationClusterData.name === ""
              }
              variant="contained"
              sx={{ marginLeft: "16px" }}
              onClick={() => {
                saveLocationCluster();
              }}
              loading={
                createLocationPoolStatus === "pending" ||
                editLocationPoolStatus === "pending"
              }
              loadingIndicator={
                <CircularProgress
                  size={16}
                  sx={{ color: "rgba(0, 0, 0, 0.38)" }}
                />
              }
            >
              {creatingNewLocationCluster ? "Create" : "Save"}
            </LoadingButton>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
export default LocationClusterDialog;
