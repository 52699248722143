export const AVAILABLE_SHIFTS = "Available shifts";
export const MY_SHIFTS = "My shifts";
export const AVAILABILITY = "Set your availability for new shifts";
export const LEAVE_REQUEST = "Apply for a time off";
export const LEAVE_REQUEST_ICON = "spa";
export const PRESENT = "Present";
export const ABSENT = "Absent";
export const EXCUSED_ABSENT = "Excused Absence";
export const TARDY = "Tardy";
export const EXCUSED_TARDY = "Excused Tardy";
export const ARRIVE_LATE = "Arrive late";
export const LEAVE_EARLY = "Leave early";
export const SENIORITY = "SENIORITY";
export const WORKCENTER = "WORKCENTER";
export const LOCATION = "LOCATION";
export const FASTEST = "FASTEST";
export const NOT_FIRST_TIME = "NOT_FIRST_TIME";
export const DONUT = "donut";
export const CIRCLE = "circle";
export const BOX = "box";
export const ALPHABET = "alphabet";
export const NUMBER = "number";
export const EMPTY = "empty";
export const QUARTER = "quarter";
export const HALF = "half";
export const THREE_QUARTER = "three-quarter"; // three-quarter
export const FULL = "full";
export const OVERTIME = "Overtime shift"; // overtime shift
export const OVERTIME_ICON = "electric-bolt"; // Icon used for overtime shifts
export const REGULAR = "Regular shift"; // regular shift
export const PART_TIME = "Overtime shift"; // Part-time shift are overtime as well
export const PART_TIME_ICON = "electric-bolt"; // Same icon as overtime replaced star-half
export const FULL_TIME_WORKER = "FullTime"; // full time worker type constant
export const FLEX_WORKER = "FlexWorker";// flex worker type constant
export const PART_TIME_WORKER = "PartTime"; // Part-time worker type constant
export const ALL_CONFIRMED_SHIFTS = "ALL_CONFIRMED_SHIFTS";
export const CONFIRMED_SHIFTS_FROM_RANGE = "CONFIRMED_SHIFTS_FROM_RANGE";
export const LARGE = "large";
export const SMALL = "small";
export const LEAVE_TYPE = "Time off type";
export const DATE = "Date";
export const START_TIME = "Start time";
export const END_TIME = "End time";
export const DURATION = "Duration";
export const MESSAGE = "Message";
export const NUMBER_OF_LINES = 4;
export const MAX_NUMBER_OF_CHARACTERS = 255;
export const LEAVE_REQUEST_CODE = "lr";
export const SHIFT_CANCEL_CODE = "sc";
export const SHIFT_SWAP_CODE = "ss";
export const LEAVE = "Leave";
export const PENDING = "p";
export const EXPIRED = "e";
export const APPROVED = "a";
export const REJECTED = "r";
export const WITHDRAWN = "w";
export const ERROR = "Error";
export const WARNING = "Warning";
export const SUCCESS = "Success";
export const HEADER_HEIGHT = 56;
export const TAB_HEADER_HEIGHT = 72;
export const CALENDAR_BAR_HEIGHT = 56;
export const CALENDAR_HEIGHT = 559;
export const CANCEL_SHIFT_REASON = "Select a reason";
export const OTHER_REASON = "Other reason";