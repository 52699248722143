export const MINIMUM_SHIFT_PATTERN_DAYS = 5;
export const MAXIMUM_SHIFT_PATTERN_DAYS = 56;
export const MAXIMUM_SHIFT_PATTERN_PER_LINE = 28;

export const KARMA_SCORE_COLUMNS = [
  "reliabilityScorePercent",
    "shiftAcceptanceRate",
    "experienceLevel",
    // "totalCompletedHours",
    "tierTitle",
];

export const RELIABILITY_SCORE_INFO = "The reliability score is derived from the following factors: No Shows, Late Cancellations, and Other Cancellations within the last 30 days.";
export const CONSISTENCY_SCORE_INFO = "The score is based on consistently accepting at least 4 hours of shifts per week, each month.";
export const EXPERIENCE_SCORE_INFO = "The level is determined by the number of shifts worked.";
