/* eslint-disable no-mixed-spaces-and-tabs */
import { Box, Button, Tooltip, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro-6";
import { differenceInHours, differenceInMinutes, format } from "date-fns";
import React, { FC } from "react";

import Highlight from "../../../../components/Highlight";
import { formatDate, hasPermission } from "../../../../utils/helpers";
import useWorkerRequestsData from "../../controllers/use-worker-requests-data";
import Actions from "../table/actions";
import Badge from "../table/badge";

interface GridColumn {
  field: string;
  type: string;
  minWidth?: number;
  width?: number;
  flex?: number;
  headerName?: string;
  renderHeader?: () => React.ReactNode;
  renderCell?: (params: { row: any }) => React.ReactNode;
  valueGetter?: (params: { row: any }) => any;
  headerAlign?: "left" | "center" | "right";
  sortable?: boolean;
  getActions?: (params: { row: any }) => any[];
}

const useFullTimeRequestsColumns = (searchText: string) => {
  const { attendanceManagement, userRolesWithPermission } =
    useWorkerRequestsData();

  const columns: GridColDef[] = [
    {
      field: "workerName",
      type: "string",
      minWidth: 155,
      flex: 1,
      headerName: "Worker Name",
      renderHeader: () => (
        <span style={{ padding: "0px 4px", fontWeight: "bold" }}>
          {"Worker"}
        </span>
      ),
      renderCell: (params) => {
        const workerName =
          params?.row?.worker["firstName"] +
          " " +
          params?.row?.worker["lastName"];
        return (
          <Tooltip title={workerName}>
            <Typography
              sx={{
                textOverflow: "ellipsis",
                width: "100%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                padding: "0px 4px",
                fontStyle: "roboto",
                fontWeight: "400",
                fontSize: "14px",
              }}
            >
              <Highlight value={workerName} searchText={searchText} />
            </Typography>
          </Tooltip>
        );
      },
      valueGetter: (params) =>
        params?.row?.worker["firstName"] +
        " " +
        params?.row?.worker["lastName"],
    },
    {
      field: "requestType",
      type: "string",
      minWidth: 130,
      flex: 1,
      headerName: "Request Type",
      renderHeader: () => (
        <span style={{ padding: "0px 4px", fontWeight: "bold" }}>
          {"Request Type"}
        </span>
      ),
      valueGetter: (params) => params.row.requestType.name,
      renderCell: (params) => (
        <Box>
          <Tooltip title={params.row.requestType.name}>
            <Typography
              sx={{
                textOverflow: "ellipsis",
                width: "140px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                padding: "0px 4px",
                fontStyle: "roboto",
                fontWeight: "400",
                fontSize: "14px",
              }}
            >
              <Highlight
                value={params.row.requestType.name}
                searchText={searchText}
              />
            </Typography>
          </Tooltip>
          {/* {attendanceManagement ? (
						<Tooltip title={params.row.leaveType.name}>
							<Typography
								sx={{
									textOverflow: "ellipsis",
									width: "140px",
									whiteSpace: "nowrap",
									overflow: "hidden",
									padding: "0px 4px",
									fontStyle: "roboto",
									fontWeight: "400",
									fontSize: "14px",
								}}
							>
								{params.row.leaveType.name}
							</Typography>
						</Tooltip>
					) : null} */}
        </Box>
      ),
    },
    {
      field: "shiftDetails",
      type: "date",
      minWidth: 240,
      flex: 1,
      headerName: "Shift Details",
      renderHeader: () => (
        <span style={{ padding: "0px 4px", fontWeight: "bold" }}>
          {"Shift Details"}
        </span>
      ),
      valueGetter: (params) => new Date(params?.row?.ftShift["startDateTime"]),
      renderCell: (params) => {
        const ftShift = params?.row?.ftShift;
        if (!ftShift) return <Box ml={5}>-</Box>;
        // const date = format(new Date(ftShift["startDateTime"]), "dd MMM yyyy");
        const date = formatDate({
          dateStr: ftShift["startDateTime"],
          formatType: "SHORT_DATE",
        });
        const name = ftShift["name"] ?? "";
        // const time =
        // 	format(new Date(ftShift["startDateTime"]), "hh:mm a") +
        // 	" - " +
        // 	format(new Date(ftShift["endDateTime"]), "hh:mm a");
        const time = formatDate({
          dateStr: ftShift["startDateTime"],
          formatType: "TIME_RANGE",
          endDateStr: ftShift["endDateTime"],
        });
        const value = `${date} ${time}`;

        return (
          <Box sx={{ wordBreak: "break-word" }}>
            <Tooltip title={value}>
              <Typography
                sx={{
                  textOverflow: "ellipsis",
                  width: "100%",
                  overflow: "hidden",
                  padding: "0px 4px",
                  fontStyle: "roboto",
                  fontWeight: "400",
                  fontSize: "14px",
                }}
              >
                <Highlight value={value} searchText={searchText} />{" "}
                <span
                  style={{
                    display: "inline-block",
                  }}
                >
                  <Highlight value={name} searchText={searchText} />
                </span>
              </Typography>
            </Tooltip>
          </Box>
        );
      },
    },
    {
      field: "timeOffDuration",
      type: "string",
      headerName: "Absence Duration",
      flex: 1,
      minWidth: 150,
      renderHeader: () => (
        <span style={{ padding: "0px 12px 0px 8px", fontWeight: "bold" }}>
          {"Absence Duration"}
        </span>
      ),
      renderCell: (params) => {
        const startDate = params.row["startDate"];
        const endDate = params.row["endDate"];
        const timeOff = differenceInHours(
          new Date(endDate),
          new Date(startDate)
        );

        return (
          <Box p={1} sx={{ wordBreak: "break-word" }}>
            <Typography
              sx={{
                textOverflow: "ellipsis",
                width: "100%",
                overflow: "hidden",
                padding: "0px 4px",
                fontStyle: "roboto",
                fontWeight: "400",
                fontSize: "14px",
              }}
            >
              <Highlight
                value={timeOff > 0 ? `${timeOff} hours` : "NA"}
                searchText={searchText}
              />
            </Typography>
          </Box>
        );
      },
      valueGetter: (params) => {
        const startDate = params.row["startDate"];
        const endDate = params.row["endDate"];
        const timeOff = differenceInHours(
          new Date(endDate),
          new Date(startDate)
        );
        return timeOff > 0 ? `${timeOff} hours` : "NA";
      },
    },
    {
      field: "comment",
      type: "string",
      headerName: "Comment",
      flex: 1,
      minWidth: 200,
      renderHeader: () => (
        <span style={{ padding: "0px 12px 0px 8px", fontWeight: "bold" }}>
          {"Comment"}
        </span>
      ),
      renderCell: (params) => (
        <Box p={1} sx={{ wordBreak: "break-word" }}>
          <Tooltip title={params?.row?.workerComment}>
            <Typography
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                whiteSpace: "normal",
                fontStyle: "roboto",
                fontWeight: "400",
                fontSize: "14px",
              }}
            >
              <Highlight
                value={
                  params?.row?.workerComment ? params?.row?.workerComment : "-"
                }
                searchText={searchText}
              />
            </Typography>
          </Tooltip>
        </Box>
      ),
      valueGetter: (params) => params?.row?.workerComment,
    },
    {
      field: "requestDate",
      type: "date",
      minWidth: 130,
      flex: 1,
      headerName: "Requested on",
      renderHeader: () => (
        <span style={{ padding: "0px 4px", fontWeight: "bold" }}>
          {"Requested on"}
        </span>
      ),
      renderCell: (params) => {
        const date = formatDate({
          dateStr: params.row["requestDate"],
          formatType: "NUMERIC_DATE",
        });
        return (
          <Box p={1} sx={{ wordBreak: "break-word" }}>
            <Typography
              sx={{
                textOverflow: "ellipsis",
                width: "100%",
                overflow: "hidden",
                padding: "0px 4px",
                fontStyle: "roboto",
                fontWeight: "400",
                fontSize: "14px",
              }}
            >
              <Highlight value={date} searchText={searchText} />
            </Typography>
          </Box>
        );
      },
      valueGetter: (params) => new Date(params.row["requestDate"]),
    },
    {
      field: "status",
      type: "string",
      minWidth: 80,
      flex: 1,
      headerName: "Status",
      renderHeader: () => (
        <span style={{ padding: "0px 4px", fontWeight: "bold" }}>
          {"Status"}
        </span>
      ),
      renderCell: (params) => <Badge status={params.row.status["name"]} />,
      valueGetter: (params) => params.row.status["name"],
    },
    {
      field: "actions",
      type: "string",
      minWidth: 180,
      headerName: "Action",
      cellClassName: "hover-column-cell",
      headerClassName: "hover-column-header",
      sortable: false,
      renderHeader: () => (
        <span style={{ padding: "0px 4px", fontWeight: "bold" }}>
          {"Action"}
        </span>
      ),
      renderCell: (params) => {
        const openCreateNewShiftDialog = params.row.openCreateNewShiftDialog;
        const openShiftDetailsDialog = params.row.openShiftDetailsDialog;
        const date = params.row["startDate"];
        const formData = {
          requestId: params.row["requestId"],
          ftShiftId: params.row["ftShiftId"],
          comment: params.row["supervisorComment"] ?? "",
        };

        const requestData = {
          name:
            params.row.worker["firstName"] +
            " " +
            params.row.worker["lastName"],
          requestType: params.row.requestType.name,
          requestCode: params.row.requestType.code,
          leaveType: params.row.leaveType.name ?? "",
          startDateTime: params.row["startDate"],
          endDateTime: params.row["endDate"],
          workerComment: params.row.workerComment,
          shiftDetails: params.row["ftShift"].name ?? "",
        };
        const status = params.row.status["name"];
        if (status === "Pending")
          return (
            <Box className="hover-content">
              <Actions
                openCreateNewShiftDialog={openCreateNewShiftDialog}
                date={date}
                formData={formData}
                requestData={requestData}
              />
            </Box>
          );
        else if (status === "Approved" && params.row["flexShiftIds"]) {
          return (
            <Button
              onClick={() => openShiftDetailsDialog(params.row["flexShiftIds"])}
              className="hover-content"
            >
              shift details
            </Button>
          );
        } else if (status === "Approved")
          return (
            <Typography
              className="hover-content"
              sx={{
                fontFamily: "Roboto",
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "20px",
                letterSpacing: "0.4000000059604645px",
                textAlign: "left",
              }}
            >
              No coverage setup
            </Typography>
          );
        else return <></>;
      },
    },
  ];
  const timeOffType: GridColDef = {
    field: "leaveType",
    type: "string",
    minWidth: 130,
    flex: 1,
    headerName: "Time Off Type",
    renderHeader: () => (
      <span style={{ padding: "0px 4px", fontWeight: "bold" }}>
        {"Time Off Type"}
      </span>
    ),
    valueGetter: (params) => params.row.leaveType.name,
    renderCell: (params) => (
      <Box>
        <Tooltip title={params.row.leaveType.name}>
          <Typography
            sx={{
              textOverflow: "ellipsis",
              width: "120px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              padding: "0px 4px",
              fontStyle: "roboto",
              fontWeight: "400",
              fontSize: "14px",
            }}
          >
            <Highlight
              value={
                params.row.leaveType.name ? params.row.leaveType.name : "-"
              }
              searchText={searchText}
            />
          </Typography>
        </Tooltip>
      </Box>
    ),
  };
  if (attendanceManagement) columns.splice(2, 0, timeOffType);
  // Don't show action column for ReadOnly users
  if (
    !hasPermission(
      userRolesWithPermission,
      "WORKER_REQUESTS.ACCEPT_REJECT_REGULAR_REQUEST"
    )
  ) {
    const actionColumnIndex = columns.findIndex(
      (column) => column.field === "actions"
    );
    if (actionColumnIndex !== -1) {
      columns.splice(actionColumnIndex, 1);
    }
  }
  return columns;
};

export default useFullTimeRequestsColumns;
