import AddIcon from "@mui/icons-material/Add";
import { Box, Button } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import {
    resetCompanyData,
    resetCompanySettings,
    resetCompanyUsers,
    resetCurrentCompanyData,
    resetLocations,
} from "../../../../../export/gat-admin";
import Searchbar from "../../../../design/search-bar";

interface IAdminHeaderProps {
    searchText: string
    isBoeing: boolean
    setSearch: React.Dispatch<React.SetStateAction<string>>
    creatingNewCompany: boolean
    setCreatingNewCompany: (value: boolean) => void
}

const AdminHeader: React.FC<IAdminHeaderProps> = ({
    searchText,
    isBoeing,
    setSearch,
    creatingNewCompany,
    setCreatingNewCompany,
}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    alignItems: "center",
                    height: "75px",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Searchbar
                        variant={"filled"}
                        width={"35ch"}
                        placeholder={"Search"}
                        setSearch={(value) =>
                            setSearch(value)
                        }
                        value={searchText}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Box ml={2} mr={5}>
                        <Button
                            startIcon={<AddIcon />}
                            color='primary'
                            variant='contained'
                            onClick={() => {
                                setCreatingNewCompany(true);
                                dispatch(resetCurrentCompanyData());
                                dispatch(resetCompanyData());
                                dispatch(resetCompanySettings());
                                dispatch(resetLocations());
                                dispatch(resetCompanyUsers());
                                if (isBoeing) {
                                    history.push({
                                        pathname: "/factory-settings",
                                        state: {
                                            creatingNewCompany,
                                        },
                                    });
                                } else {
                                    history.push({
                                        pathname: "/admin-settings",
                                        state: {
                                            creatingNewCompany,
                                        },
                                    });
                                }
                            }}
                        >
                            Company
                        </Button>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default AdminHeader;
