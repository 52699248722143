import { Box, Tooltip, Typography } from "@mui/material";
import React from "react";

import Highlight from "../../../../Highlight";

interface ColumnPriorityHeaderProps {
  priority: {
    hexColor?: string;
    name: string;
    code?: string;
  };
  searchText: string;
}

const ColumnPriorityHeader: React.FC<ColumnPriorityHeaderProps> = ({
  priority,
  searchText,
}) => {
  const code = priority.hexColor ?? "FFF";
  const backgroundColor = `#${code}`;

  return (
    <Box
      backgroundColor={backgroundColor}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 60,
        px: 2,
        width: "100%",
        border: 1,
        borderTop: "none",
        borderBottom: "none",
        borderLeft: "none",
        borderRight: "1px solid #ccc",
      }}
    >
      <Tooltip title={priority.name}>
        <Typography
          noWrap
          sx={{
            fontFamily: "Roboto",
            fontSize: "14px",
            lineHeight: "20px",
          }}
        >
          <Highlight value={`${priority.code ?? ""}`} searchText={searchText} />
        </Typography>
      </Tooltip>
    </Box>
  );
};

export default ColumnPriorityHeader;
