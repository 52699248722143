// TODO - Type 'any' needs to be fixed.
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  FULL_TIME_WORKER,
  PART_TIME_WORKER,
} from "../../../../../../assets/constants";
import { getShiftMetaData } from "../../../../../../features/worker-requests/store/worker-requests-slice";
import {
  fetchAllWorkcenters,
  getShiftDetailsV2,
} from "../../../../../scheduler/store/scheduler-slice";
import useSchedulerData from "../../../../controllers/use-scheduler-data";
import {
  APPLIED,
  ASSIGNED,
  CANCELLED,
  CONFIRMED,
  DECLINED,
  NOT_ELIGIBLE,
  NOT_NOTIFIED,
  VIEWED
} from "../constants";
import {
  NOTIFIED,
} from "../constants";
import { WorkersExtendedPropV2, WorkersPropsV2 } from "../types";
import useShiftDetailsDialogColumns from "./use-shift-details-dialog-columns";

const useShiftDetailsDialogController = (
  shiftId: any,
  isWorkerRequest: any,
  search: string,
  activeStep: string
) => {
  const dispatch = useDispatch();
  const shiftDetailsV2 = useSelector(
    (state: any) => state.supervisor?.shiftDetailsV2?.data
  );
  const fetchAllWorkcentersStatus = useSelector(
    (state: any) => state?.supervisor?.fetchAllWorkcenters?.status
  );
  const workers = shiftDetailsV2?.workers;
  const {
    allShiftsMetadata,
    shiftDetailsMetaData,
    allJobRoles,
    allWorkCenters,
    allSkillLevels,
    filterAllLocations,
    filterAllWorkcenters,
    filterJobRoles,
    renotifyWorkersStatus,
  } = useSchedulerData();

  const [shiftMetadata, setShiftMetadata] = useState<any>({});
  const [rowsV2, setRowsV2] = useState<WorkersExtendedPropV2[]>([]);
  const [availableWorkersForShiftRow, setAvailableWorkersForShiftRow] =
    useState<WorkersExtendedPropV2[] | undefined>(undefined);
  const [jobRoleMetadata, setJobRoleMetadata] = useState({});
  const [workCenterMetadata, setWorkCenterMetadata] = useState({});
  const [confirmedWorkersCount, setConfirmedWorkersCount] = useState<number>(0);
  const [statusOptions, setStatusOptions] = useState<any[]>([]);
  const [iAmSure, setIAmSure] = useState(false);

  useEffect(() => {
    // fetch the data
    if (shiftId) {
      dispatch(getShiftDetailsV2({ shiftid: shiftId }));
    }
  }, [dispatch, shiftId]);

  // Refresh workers after renotify
  useEffect(() => {
    if (renotifyWorkersStatus === "fulfilled") {
      dispatch(getShiftDetailsV2({ shiftid: shiftId }));
    }
  }, [dispatch, renotifyWorkersStatus, shiftId]);

  // Set master data for jobs and worcenters
  useEffect(() => {
    if (
      (allJobRoles.length === 0 || allWorkCenters.length === 0) &&
      isWorkerRequest &&
      fetchAllWorkcentersStatus === "idle"
    ) {
      // No need to call job roles since we already call it while loading initial worker requests
      dispatch(fetchAllWorkcenters());
    }
  }, [
    dispatch,
    allJobRoles,
    allWorkCenters,
    isWorkerRequest,
    fetchAllWorkcentersStatus,
  ]);

  useEffect(() => {
    const confirmedCount = rowsV2.filter(
      (row) => row.status === CONFIRMED
    ).length;
    const assignedCount = rowsV2.filter(
      (row) => row.status === ASSIGNED
    ).length;
    const declinedCount = rowsV2.filter(
      (row) => row.status === DECLINED
    ).length;
    const appliedCount = rowsV2.filter(
      (row) => row.status === APPLIED
    ).length;
    const notifiedCount = rowsV2.filter(
      (row) => row.status === NOTIFIED
    ).length;
    const viewedCount = rowsV2.filter((row) => row.status === VIEWED).length;
    const notNotifiedCount = rowsV2.filter(
      (row) => row.status === NOT_NOTIFIED
    ).length;
    const notEligibleCount = rowsV2.filter(
      (row) => row.status === NOT_ELIGIBLE
    ).length;
    const cancelledCount = rowsV2.filter(
      (row) => row.status === CANCELLED
    ).length;
    setStatusOptions([
      {
        label: CONFIRMED,
        checked: false,
        icon: CONFIRMED,
        count: confirmedCount,
      },
      {
        label: ASSIGNED,
        checked: false,
        icon: ASSIGNED,
        count: assignedCount,
      },
      {
        label: APPLIED,
        checked: false,
        icon: APPLIED,
        count: appliedCount,
      },
      {
        label: DECLINED,
        checked: false,
        icon: DECLINED,
        count: declinedCount,
      },
      {
        label: CANCELLED,
        checked: false,
        icon: CANCELLED,
        count: cancelledCount,
      },
      {
        label: VIEWED,
        checked: false,
        icon: VIEWED,
        count: viewedCount,
      },
      {
        label: NOT_ELIGIBLE,
        checked: false,
        icon: NOT_ELIGIBLE,
        count: notEligibleCount,
      },
      {
        label: NOTIFIED,
        checked: false,
        icon: NOTIFIED,
        count: notifiedCount,
      },
      {
        label: NOT_NOTIFIED,
        checked: false,
        icon: NOT_NOTIFIED,
        count: notNotifiedCount,
      },
    ]);
  }, [rowsV2]);

  // for full time worker requests page
  useEffect(() => {
    if (isWorkerRequest) {
      dispatch(getShiftMetaData(shiftId));
    }
  }, [dispatch, shiftId, isWorkerRequest]);

  // Set shift meta data when dialog is opened in flex scheduler
  useEffect(() => {
    if (allShiftsMetadata && !isWorkerRequest) {
      const currentShiftData = allShiftsMetadata.filter(
        (shift: any) => shift.id === shiftId
      );
      setShiftMetadata(currentShiftData[0]);
    }
    // set workcenter name location name and job role
    if (allShiftsMetadata && !isWorkerRequest) {
      const currentShiftData = allShiftsMetadata.filter(
        (shift: any) => shift.id === shiftId
      );
      const workCenterData = filterAllWorkcenters.filter(
        (workCenter: any) => workCenter.id === currentShiftData[0].workCenterId
      );
      const locationData = filterAllLocations.filter(
        (location: any) => location.id === workCenterData[0].locationId
      );
      const jobRoleData = filterJobRoles.filter(
        (jobRole: any) => jobRole.id === currentShiftData[0].jobId
      );
      setShiftMetadata({
        ...currentShiftData[0],
        shiftWorkcenterName: workCenterData[0].name,
        shiftLocationName: locationData[0].name,
        shiftJobRole: jobRoleData[0],
      });
    }
  }, [
    allShiftsMetadata,
    shiftId,
    isWorkerRequest,
    filterAllWorkcenters,
    filterAllLocations,
    filterJobRoles,
  ]);

  // Set shift meta data when dialog is opened in full time worker requests page
  useEffect(() => {
    if (isWorkerRequest && shiftDetailsMetaData) {
      // set workcenter name location name and job role
      const currentWorkCenter = allWorkCenters.find(
        (workCenter: any) => workCenter.id === shiftDetailsMetaData.workCenterId
      );
      const jobRoleData = allJobRoles.find(
        (jobRole: any) => jobRole.id === shiftDetailsMetaData.jobId
      );
      const shiftLocationName = currentWorkCenter?.location?.name;
      const shiftWorkcenterName = currentWorkCenter?.name;
      setShiftMetadata({
        ...shiftDetailsMetaData,
        shiftLocationName,
        shiftWorkcenterName,
        shiftJobRole: jobRoleData,
      });
    }
  }, [isWorkerRequest, shiftDetailsMetaData, allWorkCenters, allJobRoles]);

  useEffect(() => {
    if (allJobRoles) {
      const currentJobRole = allJobRoles.find(
        (jobRole: any) => jobRole.id === shiftMetadata?.jobId
      );
      if (currentJobRole) {
        setJobRoleMetadata(currentJobRole);
      }
    }
  }, [allJobRoles, shiftMetadata?.jobId]);

  useEffect(() => {
    if (allWorkCenters) {
      const currentWorkCenter = allWorkCenters.find(
        (workCenter: any) => workCenter.id === shiftMetadata?.workCenterId
      );
      if (currentWorkCenter) {
        setWorkCenterMetadata(currentWorkCenter);
      }
    }
  }, [allWorkCenters, shiftMetadata?.workCenterId]);

  useEffect(() => {
    // Update confirmed workers count based on worker row data
    try {
      if (Array.isArray(rowsV2)) {
        const totalConfirmedWorkers = rowsV2.filter(
          (worker) => worker.status === CONFIRMED
        );
        const totalAssignedWorkers = rowsV2.filter(
          (worker) => worker.status === ASSIGNED
        );
        if (
          totalConfirmedWorkers !== undefined &&
          totalConfirmedWorkers !== null
        ) {
          setConfirmedWorkersCount(
            totalConfirmedWorkers.length + totalAssignedWorkers.length
          );
        }
      }
    } catch (e) {
      // Do nothing
    }
  }, [rowsV2]);

  useEffect(() => {
    const rowsV2: WorkersExtendedPropV2[] = [];
    if (workers) {
      const order: Map<string, number> = new Map([
        [CONFIRMED, 1],
        [ASSIGNED, 2],
        [APPLIED, 3],
        [DECLINED, 4],
        [CANCELLED, 5],
        [VIEWED, 6],
        [NOT_ELIGIBLE, 7],
        [NOTIFIED, 8],
        [NOT_NOTIFIED, 9],
      ]);
      workers.forEach((obj: WorkersPropsV2) => {
        let newStatus = obj.status;
        if (
          obj.status === "Accepted" &&
          Object.prototype.hasOwnProperty.call(obj, "isAssigned")
        ) {
          if (obj.isAssigned === true) {
            newStatus = ASSIGNED;
          } else {
            newStatus = CONFIRMED;
          }
        }
        if (obj.status === "Interested") {
          newStatus = APPLIED;
          // if(new Date() > new Date(shiftMetadata?.windowClosingTime)) {
          //   newStatus = NOT_ELIGIBLE;
          // }
          // else{
          //   newStatus = APPLIED;
          // }
        }
        const newObj = {
          ...obj,
          reason:
            newStatus === ASSIGNED
              ? ""
              : obj.reason
              ? obj.reason
              : obj.cancelReason
              ? obj.cancelReason
              : "",
          status: newStatus,
          id: obj.workerId,
          overtime: obj.workerFlexTime ? obj.workerFlexTime : "NA",
          overtimeMins: obj.workerFlexMinutes ? obj.workerFlexMinutes : 0,
          errorMessage: "",
          order: order.get(newStatus) ?? 0,
        };
        rowsV2.push(newObj);
      });
    }
    const sortedRows: WorkersExtendedPropV2[] = _.orderBy(rowsV2, "order", [
      "asc",
    ]);
    setRowsV2(sortedRows);

    try {
      const sortedRows: WorkersExtendedPropV2[] = _.orderBy(rowsV2, "order", [
        "asc",
      ]);
      setRowsV2(sortedRows);
      // Allow only notified viewed none or declined workers
      const allowedStatusForForceAssignShift = [
        NOTIFIED,
        VIEWED,
        NOT_NOTIFIED,
        DECLINED,
        CANCELLED,
      ];
      let availableWorkers: WorkersExtendedPropV2[] = sortedRows.filter(
        (row: WorkersExtendedPropV2) =>
          allowedStatusForForceAssignShift.includes(row.status)
      );

      // Allow only full-time and part-time workers for force assign
      availableWorkers = availableWorkers.filter(
        (worker) =>
          worker.workerType === FULL_TIME_WORKER ||
          worker.workerType === PART_TIME_WORKER
      );

      availableWorkers = availableWorkers.map((worker, index) => {
        return {
          ...worker,
          errorMessage: "",
          panelId: index, // Require number only for detailed panel in mui datagrid
        };
      });
      availableWorkers = _.orderBy(availableWorkers, "order", ["asc"]);
      if (availableWorkers.length === 0) {
        setAvailableWorkersForShiftRow(undefined)
      } else {
        setAvailableWorkersForShiftRow(availableWorkers);
      }
    } catch (e) {
      console.log("Error setting worker row view details:", e);
    }
  }, [workers, shiftMetadata]);

  const { columns, setColumns } = useShiftDetailsDialogColumns(
    shiftMetadata,
    rowsV2.length,
    availableWorkersForShiftRow ? availableWorkersForShiftRow.length : 0,
    search,
    activeStep,
    shiftDetailsV2 ? shiftDetailsV2.shiftAllocationType : "",
    shiftDetailsV2 ? shiftDetailsV2.isShiftOpenForOtherLocation : false
  );

  return [
    {
      shiftMetadata,
      jobRoleMetadata,
      workCenterMetadata,
      availableWorkersForShiftRow,
      columns,
      allSkillLevels,
      confirmedWorkersCount,
      iAmSure,
      rowsV2,
      statusOptions,
    },
    {
      setAvailableWorkersForShiftRow,
      setRowsV2,
      setIAmSure,
      setStatusOptions,
      setColumns,
    },
  ];
};

export default useShiftDetailsDialogController;
