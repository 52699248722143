// TODO - Type 'any' needs to be fixed.
import DataGrid from "@gigandtake/datagrid";
import Add from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Stack, Tooltip, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import { useConfirm } from "material-ui-confirm";
import React, { useEffect, useState } from "react";

import { LocationCluster } from "../../../../../../../ts/components/gat-admin/store/gat-admin-slice-types";
import { useIsComponentMounted } from "../../../../../../../ts/utils/helpers";

interface ILocationClusterTable {
	companyLocationClusters: LocationCluster[];
	handleClickAdd: any;
	handleClickEdit: any;
	deleteLocationCluster: any;
	getLocationName: any;
}

const LocationClusterTable = ({
	companyLocationClusters,
	handleClickAdd,
	handleClickEdit,
	deleteLocationCluster,
	getLocationName,
}: ILocationClusterTable) => {
	const confirm = useConfirm();
	const [localCompanyLocationClusters, setLocalCompanyLocationClusters] =
		useState(companyLocationClusters);
	const isComponentMounted = useIsComponentMounted();

	useEffect(() => {
		if (isComponentMounted.current) {
			setLocalCompanyLocationClusters(companyLocationClusters);
		}
	}, [companyLocationClusters, isComponentMounted]);

	const columns: any = [
		{
			field: "name",
			headerName: "Location Cluster",
			minWidth: 220,
			flex: 2,
			renderCell: (params: any) => {
				const color = params.row.isActive ? "rgba(0,0,0,0.87)" : "#747474";
				return (
					<Typography
						sx={{
							fontFamily: "Roboto",
							color: color,
						}}
					>
						{params.row.name}
					</Typography>
				);
			},
		},
		{
			field: "locations",
			headerName: "Locations",
			minWidth: 150,
			flex: 1,
			headerAlign: "center",
			valueGetter: (params: any) =>
				typeof params.row.locationIds === "string"
					? params.row.locationIds.split(",").length ?? 0
					: 0,
			renderCell: (params: any) => {
				const color = params.row.isActive ? "rgba(0,0,0,0.87)" : "#747474";
				return (
					<Typography
						sx={{
							fontFamily: "Roboto",
							color: color,
							textAlign: "center",
							width: "100%",
						}}
					>
						{typeof params.row.locationIds === "string"
							? params.row.locationIds.split(",").length ?? 0
							: 0}
					</Typography>
				);
			},
		},
		{
			field: "locationsList",
			headerName: "Locations list",
			minWidth: 150,
			flex: 6,
			headerAlign: "left",
			sortable: false,
			renderCell: (params: any) => {
				const locationIds = params.row.locationIds.split(",").map(Number);
				return (
					<Stack direction='row' flexWrap='wrap'>
						{locationIds.map((locationId: number | string, index: number) => (
							<Tooltip
								key={`location-cluster-${index}-${locationId}`}
								title={getLocationName(locationId)}
							>
								<Chip
									disabled={!params.row.isActive}
									label={getLocationName(locationId)}
									sx={{
										margin: 0.5,
										maxWidth: "150px",
									}}
								/>
							</Tooltip>
						))}
					</Stack>
				);
			},
		},
		{
			field: "isActive",
			headerName: "Status",
			minWidth: 150,
			flex: 1,
			headerAlign: "center",
			renderCell: (params: any) => {
				const color = params.row.isActive ? "rgba(0,0,0,0.87)" : "#747474";
				return (
					<Typography
						sx={{
							fontFamily: "Roboto",
							color: color,
							textAlign: "center",
							width: "100%",
						}}
					>
						{params.row.isActive ? "Active" : "Inactive"}
					</Typography>
				);
			},
		},
		{
			field: "action",
			headerName: "Action",
			minWidth: 150,
			flex: 1,
			headerAlign: "center",
			sortable: false,
			renderCell: (params: any) => {
				return (
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							width: "100%",
						}}
					>
						<Box
							sx={{
								color: "#6c6c6c",
								"&:hover": {
									color: "#000000",
									transform: "scale(1.2)",
								},
								cursor: "pointer",
							}}
						>
							<Tooltip title='Edit'>
								<EditIcon
									id={`edit-location-cluster-${params.row.id}-enabled`}
									fontSize='small'
									onClick={(e) => {
										handleClickEdit(params.row);
									}}
								/>
							</Tooltip>
						</Box>
						<Box
							sx={{
								color: "#6c6c6c",
								"&:hover": {
									color: "red",
									transform: "scale(1.2)",
								},
								cursor: "pointer",
							}}
						>
							<Tooltip title='Delete'>
								<DeleteIcon
									id={`delete-location-cluster-${params.row.id}-enabled`}
									sx={{ marginLeft: "8px" }}
									fontSize='small'
									onClick={() => {
										confirm({
											title: "Are you sure?",
											description: `You are about delete a location cluster named ${params.row.name}.`,
										})
											.then(() => {
												deleteLocationCluster(params.row.id);
												setLocalCompanyLocationClusters(
													companyLocationClusters.filter(
														(x: any) => parseInt(x.id) !== params.row.id
													)
												);
											})
											.catch(() => {
												/* ... */
											});
									}}
								/>
							</Tooltip>
						</Box>
					</Box>
				);
			},
		},
	];
	return (
		<Box width={"100%"} height={350} mr='auto' ml='auto'>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "flex-end",
					paddingTop: "20px",
					paddingBottom: "20px",
				}}
			>
				<Button
					disableElevation
					sx={{ height: 36 }}
					variant='outlined'
					color='primary'
					onClick={handleClickAdd}
					startIcon={<Add />}
				>
					Location Cluster
				</Button>
			</Box>
			<DataGrid
				columns={columns}
				rows={localCompanyLocationClusters}
				disableRowSelectionOnClick
				disableSelectionOnClick
				disableColumnSelector
				disableDensitySelector
				disableColumnPinning
				disableColumnReorder
				disableColumnResize
				hideFooter
				getRowHeight={() => "auto"}
				sx={{
					border: "none !important",
					height: "100%",
					"&>.MuiDataGrid-main": {
						"& div div div div >.MuiDataGrid-cell": {
							borderBottom: "none",
						},
					},
					"&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within": {
						outline: "none",
					},
					"& .MuiDataGrid-columnSeparator": {
						display: "none !important",
					},
					"& .MuiDataGrid-row": {
						border: "none !important",
					},
					"&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
						outline: "none !important",
					},
					"& .MuiDataGrid-cell": {
						minHeight: "42px !important",
					},
				}}
			/>
		</Box>
	);
};

export default LocationClusterTable;
