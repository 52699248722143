/* eslint-disable no-mixed-spaces-and-tabs */
import { useGridApiRef } from "@mui/x-data-grid-pro-6";
import { useConfirm } from "material-ui-confirm";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import {
  ColumnGroupJobHeader,
  ColumnPriorityHeader,
} from "../../../../export/cross-skilling";
import { updateWorkerSkillLevel } from "../../../../export/workers";
import { hasPermission } from "../../../../ts/utils/helpers";
import {
  addSkillInterested,
  //rejectSkillInterested,
} from "../../../features/ManageWorkers/ManageWorkersSlice";
import {
  filterWorkersBySkillLevel,
  formatSkillsByJobs,
  getLowestLevelObject,
} from "../../../utils/helpers";
import useViewport from "../../../utils/useViewport";
import { skillsWithoutJobs } from "../ui/cross-skill-table/utils";
import useCrossSkillData from "./use-cross-skill-data";

const useCrossSkillTableController = ({
  data,
  filters,
  allSkillLevels,
  allJobs,
  getFilter,
  searchText,
}) => {
  const confirm = useConfirm();
  const dispatch = useDispatch();
  let tempData = [...data];

  const {
    addSkillInterestStatus,
    rejectSkillInterestStatus,
    updateWorkerSkillLevelError,
    updateWorkerSkillLevelStatus,
    allSkills,
    fetchManageWorkersDataStatus,
    skillMatrixFiltersStatus,
    skillMatrixDefaultFiltersStatus,
    skillPriorities,
    isSkillPriorityEnabled,
    userRolesWithPermission,
  } = useCrossSkillData();

  let tempWorkers = [];
  const { height, width } = useViewport();
  let columnGroup = [];
  const dataGridRef = useRef();
  const apiRef = useGridApiRef();
  const allSkillsOfAllLocations = allSkills.map((skill) => {
    return { ...skill, skillId: skill.id };
  });

  const isJobFilter = getFilter("job").jobs.length > 0;
  const isSkillFilter = getFilter("skill").skills.length > 0;
  const isDefaultFilter = !isJobFilter && !isSkillFilter;

  const [isColumnGroup, setIsColumnGroup] = useState(true);

  const isLocationTypeFilter = getFilter("location").locations.length > 0;
  const isWorkerTypeFilter = getFilter("workerType").workerTypes.length > 0;
  const isSkillPriorityFilter =
    getFilter("skillPriority").skillPrioritys.length > 0;
  const isWorkCenterFilter = getFilter("workCenter").workCenters.length > 0;
  const isManagerFilter = getFilter("manager").managers.length > 0;

  const workerTypeIds = isWorkerTypeFilter
    ? getFilter("workerType").workerTypes.map((workerType) => workerType.id)
    : [];
  const skillPriorityIds = isSkillPriorityFilter
    ? getFilter("skillPriority").skillPrioritys.map(
        (skillPriority) => skillPriority.id
      )
    : [];
  const managerIds = isManagerFilter
    ? getFilter("manager").managers.map((manager) => manager.id)
    : [];
  const workCenterIds = isWorkCenterFilter
    ? getFilter("workCenter").workCenters.map((workCenter) => workCenter.id)
    : [];

  if (isWorkerTypeFilter) {
    data.forEach((w) => {
      workerTypeIds.forEach((workerTypeId) => {
        if (w.typeId === parseInt(workerTypeId)) {
          tempWorkers.push(w);
        }
      });
    });
    tempData = tempWorkers;
  }

  if (isManagerFilter) {
    tempWorkers = [];
    tempData.forEach((w) => {
      if (Object.prototype.hasOwnProperty.call(w, "managerId")) {
        if (w.managerId !== "" && w.managerId !== null) {
          managerIds.forEach((managerId) => {
            if (parseInt(managerId) === parseInt(w.managerId)) {
              tempWorkers.push(w);
            }
          });
        }
      }
    });
    tempData = tempWorkers;
  }

  if (isLocationTypeFilter) {
    tempWorkers = [];
    tempData.forEach((w) => {
      if (Object.prototype.hasOwnProperty.call(w, "locationId")) {
        if (w.locationId !== "" && w.locationId !== null) {
          const workerLocationId = w.locationId;
          const locationIds = getFilter("location").locations.map(
            (location) => location.id
          );
          if (locationIds.includes(workerLocationId)) {
            tempWorkers.push(w);
          }
        }
      }
    });
    tempData = tempWorkers;
  }

  if (isWorkCenterFilter) {
    tempWorkers = [];
    tempData.forEach((w) => {
      if (Object.prototype.hasOwnProperty.call(w, "workcenterId")) {
        if (w.workcenterId !== null && w.workcenterId !== "") {
          const workerWorkCenterId = w.workcenterId;
          if (workCenterIds.includes(workerWorkCenterId)) {
            tempWorkers.push(w);
          }
        }
      }
    });
    tempData = tempWorkers;
  }

  // Remove duplicate worker
  tempData = tempData.filter(
    (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
  );

  tempWorkers = tempData;

  const skillIds = isSkillFilter
    ? getFilter("skill").skills.map((skill) => skill.id)
    : [];
  const skillLevelIds = isSkillFilter
    ? getFilter("skill").skills.filter((skill) => skill.levelIds?.length > 0)
    : [];

  const filteredSkills = allSkillsOfAllLocations.filter((skill) =>
    skillIds.includes(skill.skillId)
  );

  const jobIdsSkillsArr =
    isJobFilter || isDefaultFilter
      ? []
      : isSkillFilter
      ? filteredSkills
      : allSkillsOfAllLocations;

  const jobIds = isDefaultFilter
    ? allJobs.map((job) => job.id)
    : isJobFilter
    ? getFilter("job").jobs.map((job) => job.id)
    : [];

  (isJobFilter || isDefaultFilter) &&
    formatSkillsByJobs(
      allJobs,
      jobIdsSkillsArr,
      jobIds,
      isSkillFilter ? filteredSkills : [],
      skillPriorityIds
    );

  const filteredWorkersByLevel = isSkillFilter
    ? filterWorkersBySkillLevel(tempWorkers, filters, skillIds)
    : [];
  const workers = tempWorkers;
  const handleAddSkillInterested = (params, skill, setState) => {
    if (
      hasPermission(userRolesWithPermission, "CROSS_SKILLING.ACCEPT_INTEREST")
    ) {
      const defaultSkillLevel = getLowestLevelObject(allSkillLevels);
      const interestedSkill = params.row.skillInterests
        ? params.row.skillInterests.find(
            (x) => parseInt(x.skillId) === parseInt(skill.skillId)
          )
        : false;
      confirm({
        title: "Are you sure?",
        description: `You are adding ${skill.name} to ${params.row.firstName}'s skill set.`,
      })
        .then(() => {
          dispatch(addSkillInterested(interestedSkill.id));
          setState((state) => ({
            ...state,
            level: defaultSkillLevel.level,
            levelId: defaultSkillLevel.levelId,
            repCode: defaultSkillLevel.repCode,
            backColorCode: defaultSkillLevel.backColorCode,
            foreColorCode: defaultSkillLevel.foreColorCode,
            value: defaultSkillLevel.value,
            isInterest: false,
            skillAssigned: true,
          }));
        })
        .catch(() => {
          /* ... */
        });
    }
  };

  const [skillsUpdatedListInSession, setOnUpdateSkillLevel] = useState([]);

  const onUpdateSkillLevel = (state, obj, level) => {
    const updatedSkillLevels = skillsUpdatedListInSession.some(
      (x) => x.skillId === obj.skillId && x.workerId === obj.workerId
    )
      ? skillsUpdatedListInSession.map((x) =>
          x.skillId === obj.skillId && x.workerId === obj.workerId
            ? {
                ...state,
                name: level.name,
                level: level.level,
                levelId: level.levelId,
                repCode: level.repCode,
                backColorCode: level.backColorCode,
                foreColorCode: level.foreColorCode,
                value: level.value,
                workerId: obj.workerId,
                skillId: obj.skillId,
              }
            : x
        )
      : [
          ...skillsUpdatedListInSession,
          {
            ...state,
            name: level.name,
            level: level.level,
            levelId: level.levelId,
            repCode: level.repCode,
            backColorCode: level.backColorCode,
            foreColorCode: level.foreColorCode,
            value: level.value,
            workerId: obj.workerId,
            skillId: obj.skillId,
          },
        ];

    setOnUpdateSkillLevel(updatedSkillLevels);
  };

  const handleSkillLevels = (params, skill, level, setState, state) => {
    const obj = {
      skillId: skill.skillId,
      levelId: level.levelId,
      presentLevelId: state ? state.levelId : -1,
      workerId: params.id,
    };

    const workerSkill = params.row.skills.find(
      (x) => x.skillId === skill.skillId
    );

    if (workerSkill) {
      if (workerSkill.levelId !== level.levelId) {
        confirm({
          title: "Are you sure?",
          description: `You are updating skill level from ${workerSkill.levelName} to ${level.name} for ${params.row.firstName} in ${workerSkill.skillName}.`,
        })
          .then(() => {
            dispatch(updateWorkerSkillLevel(obj));
            setState((state) => ({
              ...state,
              name: level.name,
              level: level.level,
              levelId: level.levelId,
              repCode: level.repCode,
              backColorCode: level.backColorCode,
              foreColorCode: level.foreColorCode,
              value: level.value,
            }));
            onUpdateSkillLevel(state, obj, level);
          })
          .catch(() => {
            /* ... */
          });
      }
    } else {
      const canConfirm = state ? state.levelId !== level.levelId : true;
      if (canConfirm) {
        if (!state) {
          confirm({
            title: "Are you sure?",
            description: `You are adding ${skill.name} with level ${level.name} for ${params.row.firstName}.`,
          })
            .then(() => {
              dispatch(updateWorkerSkillLevel(obj));
              setState((state) => ({
                ...state,
                name: level.name,
                level: level.level,
                levelId: level.levelId,
                repCode: level.repCode,
                backColorCode: level.backColorCode,
                foreColorCode: level.foreColorCode,
                value: level.value,
              }));
              onUpdateSkillLevel(state, obj, level);
            })
            .catch(() => {
              /* ... */
            });
        } else {
          confirm({
            title: "Are you sure?",
            description: `You are updating skill level from ${state.name} to ${level.name} for ${params.row.firstName} in ${skill.name}.`,
          })
            .then(() => {
              dispatch(updateWorkerSkillLevel(obj));
              setState((state) => ({
                ...state,
                name: level.name,
                level: level.level,
                levelId: level.levelId,
                repCode: level.repCode,
                backColorCode: level.backColorCode,
                foreColorCode: level.foreColorCode,
                value: level.value,
              }));
              onUpdateSkillLevel(state, obj, level);
            })
            .catch(() => {
              /* ... */
            });
        }
      }
    }
  };

	if ((isJobFilter || isDefaultFilter) && jobIds && jobIdsSkillsArr) {
		jobIds.forEach((jobId) => {
			const job = allJobs.find((job) => job.id === jobId);
			const priorities = isSkillPriorityEnabled ? job.priorities : undefined;
			let obj = {};
			let temp = [];

      // when there are priorities
      if (priorities) {
        obj.groupId = `${jobId}`;
        obj.headerName = job.name;
        obj.headerClassName = "job-header";
        obj.children = priorities.map((priority) => {
          const wObj = {};
          wObj.groupId = `${priority.id}`;
          wObj.headerName = priority.name;
          wObj.headerClassName = "priority-header";
          wObj.children = priority.skills.map((skill) => {
            const sObj = {};
            sObj.field = `${skill.id}-${jobId}`;
            return sObj;
          });
          wObj.renderHeaderGroup = () => (
            <ColumnPriorityHeader
              priority={{ ...priority, jobColor: job.hexColor }}
              searchText={searchText}
            />
          );
          return wObj;
        });

        obj.renderHeaderGroup = () => (
          <ColumnGroupJobHeader
            skill={{ ...job, jobName: job.name, jobColor: job.hexColor }}
            searchText={searchText}
          />
        );
      }

      // when there are no priorities
      !priorities &&
        jobIdsSkillsArr.forEach((sk) => {
          if (jobId === sk.jobId) {
            temp.push({ field: `${sk.skillId}-${sk.jobId}` });
            obj.groupId = `${jobId}`;
            obj.headerName = sk.jobName;
            obj.children = temp;
            obj.renderHeaderGroup = () => (
              <ColumnGroupJobHeader skill={sk} searchText={searchText} />
            );
          }
        });
      if (Object.keys(obj).length > 0) columnGroup.push(obj);
      //create column group for other skills
      if (
        allSkillsOfAllLocations.length > 0 &&
        allJobs.length > 0 &&
        !columnGroup.find((x) => x.groupId === "-1") &&
        !isJobFilter &&
        isColumnGroup
      ) {
        const skillsWithNoJobs =
          allJobs.length === 0
            ? allSkillsOfAllLocations
            : skillsWithoutJobs(allSkillsOfAllLocations, allJobs);
        // when there are priorities
        if (skillPriorities.length > 0 && isSkillPriorityEnabled) {
          const prioritiesWithSkills = skillPriorities
            .map((priority) => {
              const skills = [];
              skillsWithNoJobs.forEach((skill) => {
                if (skill.priority.id === priority.id) {
                  skills.push(skill);
                }
              });
              return { ...priority, skills };
            })
            .filter((priority) => priority.skills.length > 0);
          let obj = {};

          if (prioritiesWithSkills.length > 0) {
            obj.groupId = "-1";
            obj.headerName = "Other Skills";

            obj.children = prioritiesWithSkills.map((priority) => {
              const wObj = {};
              wObj.groupId = `wight--${priority.id}`;
              wObj.headerName = priority.name;
              wObj.headerClassName = "priority-header";
              wObj.children = priority.skills.map((skill) => {
                const sObj = {};
                sObj.field = `${skill.id}-${skill.jobId}`;
                return sObj;
              });
              wObj.renderHeaderGroup = () => (
                <ColumnPriorityHeader
                  priority={{ ...priority, jobColor: job.hexColor }}
                  searchText={searchText}
                />
              );
              return wObj;
            });
            obj.renderHeaderGroup = () => (
              <ColumnGroupJobHeader
                skill={{ ...job, jobName: "other Skills" }}
                searchText={searchText}
              />
            );
          }
          if (Object.keys(obj).length > 0) columnGroup.push(obj);
        }
        // when there are no priorities
        else {
          let obj = {};
          let temp = [];
          skillsWithNoJobs.forEach((sk) => {
            temp.push({ field: `${sk.skillId}-${sk.jobId}` });
            obj.groupId = `${sk.jobId}`;
            obj.headerName = "Other Skills";
            obj.children = temp;
            obj.renderHeaderGroup = () => (
              <ColumnGroupJobHeader skill={sk} searchText={searchText} />
            );
          });
          if (Object.keys(obj).length > 0) columnGroup.push(obj);
        }
      }
    });
  }
  // column group for jobs with no skills
  if (jobIds.length > 0) {
    const jobsWithNoSkills = jobIds.filter(
      (jobId) => !columnGroup.find((x) => x.groupId === jobId.toString())
    );
    jobsWithNoSkills.forEach((jobId) => {
      if (!columnGroup.find((x) => x.groupId === jobId.toString())) {
        let obj = {};
        let job = allJobs.find((job) => job.id === jobId);
        if (job) {
          const noSkill = {
            skillId: -2,
            skillName: "No Skill",
            jobName: job.name,
            jobColor: job.hexColor,
          };
          let temp = [{ field: `${jobId}-${noSkill.skillId}` }];
          obj.groupId = `${jobId}`;
          obj.headerName = job.name;
          obj.children = temp;
          obj.noSkill = true;
          obj.renderHeaderGroup = () => (
            <ColumnGroupJobHeader skill={noSkill} searchText={searchText} />
          );
          if (Object.keys(obj).length > 0) columnGroup.push(obj);
        }
      }
    });
  }

  useEffect(() => {
    if (isJobFilter || isDefaultFilter) {
      setIsColumnGroup(true);
    } else {
      setIsColumnGroup(false);
    }
  }, [isJobFilter, isDefaultFilter]);

	return {
		dataGridRef,
		apiRef,
		columnGroup,
		height,
		width,
		addSkillInterestStatus,
		rejectSkillInterestStatus,
		isJobFilter,
		isSkillPriorityFilter,
		skillPriorityIds,
		isSkillFilter,
		isDefaultFilter,
		isColumnGroup,
		skillLevelIds,
		jobIdsSkillsArr,
		jobIds,
		allSkillsOfAllLocations,
		filteredWorkersByLevel,
		filteredSkills,
		workers,
		handleAddSkillInterested,
		handleSkillLevels,
		updateWorkerSkillLevelError,
		updateWorkerSkillLevelStatus,
		fetchManageWorkersDataStatus,
		skillMatrixFiltersStatus,
		skillMatrixDefaultFiltersStatus,
		skillsUpdatedListInSession
	};
};

export default useCrossSkillTableController;
