import { AppointmentModel } from "@devexpress/dx-react-scheduler";
import { Appointments } from "@devexpress/dx-react-scheduler-material-ui";
import { isSunday as checkSunday } from "date-fns";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { JOB, SKILLS } from "../../../../assets/constants";

interface AppointmentProps {
  children: React.ReactNode;
  data: AppointmentModel
}

export default function AppointmentContainer({
  children,
  data,
  ...restProps
}: AppointmentProps): JSX.Element {
  const [style, setStyle] = useState<{
    zIndex?: number;
    transform?: string;
    transformOrigin?: string;
  }>({
    zIndex: -1,
  });
  const shiftByType = parseInt(data.jobId) === 0 ? SKILLS : JOB;

  const isWeekView = useSelector((state: any) => state.supervisor.weekView);
  const isSunday = checkSunday(new Date(data?.startDateTime)) && isWeekView;
  const [borderStyle, setBorderStyle] = useState<{
    border?: string;
  }>({});
  const color = useSelector((state: any) => {
    if (shiftByType === JOB) {
      const jobRole =
        state.supervisor?.filterData?.allJobRoles.length > 0 &&
        state.supervisor?.filterData?.allJobRoles?.filter(
          (jobRole: { id: string }) => jobRole.id === data.jobId
        );
      return `#${jobRole[0]?.hexColor}`;
    } else {
      return "#F0F0F0";
    }
  });
  useEffect(() => {
    if (data.isPublished && data.numberOfWorkersNeeded > data.slotsFilled) {
      setBorderStyle({
        border: "2px solid #F2B8B5",
      });
    } else if (!data.isPublished) {
      setBorderStyle({
        border: "1px dashed #616161",
      });
    } else {
      setBorderStyle({
        border: "0px",
      });
    }
  }, [data]);
  return (
    <Appointments.Appointment
      {...restProps}
      data={data}
      draggable={false}
      resources={[]}
      onMouseEnter={() =>
        setStyle({
          transform: "scale(1.1)",
          transformOrigin: isSunday ? "right center" : "left center",
        })
      }
      onMouseLeave={() =>
        setStyle({
          transform: "scale(1.0)",
          transformOrigin: isSunday ? "right center" : "left center",
        })
      }
      style={{
        ...style,
        ...borderStyle,
        backgroundColor: `${!data.isPublished ?
            "#fff" : color ?? "#F3A7A0"}`,
        background: shiftByType === JOB ?
            !data.isPublished ?
                `${color ?? "#F3A7A0"} conic-gradient(from 90deg at 1px 1px,#0000 90deg,rgba(0,0,0,0.05) 0) 0 0/5px 5px`
                : color ?? "#F3A7A0"
            :!data.isPublished ?
                "#fff" : color ?? "#F3A7A0",
        boxShadow:
          "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)",
        borderRadius: "4px",
        display: "flex",
        justifyContent: "flex-start",
        flex: 1,
        transition: "transform 0.2s ease-in-out",
      }}
    >
      {children}
    </Appointments.Appointment>
  );
}
