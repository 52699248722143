import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Box, Grid, Typography } from "@mui/material";
import format from "date-fns/format";
import { useEffect, useState } from "react";
import React from "react";
import { useDrag } from "react-dnd";

import {
  BORDER_COLOR,
  DRAG_INDICATOR_ICON_COLOR,
} from "../../../constants/colors";
import { DragAndDropTypes } from "../../../constants/draganddroptypes";
import useScheduleController from "../../../controllers/schedule-controller";

const Card = ({ text, selectWorker }) => {
  const [{ opacity, isDragging }, dragRef] = useDrag(
    () => ({
      type: DragAndDropTypes.WORKER_CARD,
      item: { text },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1,
        isDragging: monitor.isDragging(),
      }),
    }),
    []
  );
  useEffect(() => {
    if (isDragging) {
      selectWorker();
    }
  }, [isDragging, selectWorker]);
  return (
    <div ref={dragRef}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          backgroundColor: BORDER_COLOR,
          padding: "4px 10px 4px 2px",
          margin: "0 -2px", // Required for clean drag effect
          borderRadius: "4px",
          opacity: opacity,
          cursor: "pointer",
        }}
      >
        <DragIndicatorIcon
          sx={{
            color: DRAG_INDICATOR_ICON_COLOR,
            height: "18px",
          }}
        />
        <span
          style={{
            color: "#000000",
            fontFamily: "Roboto",
            fontSize: "13px",
            fontWeight: 400,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            lineHeight: "16px",
          }}
        >
          {text}
        </span>
      </div>
    </div>
  );
};

export const UnallocatedWorkersRow = ({
  datesArrayOfSelectedWeek,
}) => {
  const [
    ,{
      getUnallocatedWorkersForDate,
      changeSelectedWorker,
    },
  ] = useScheduleController();

  const WorkersCell = ({ workers, dateString, isPastDate }) => {
    const [selecting, setSelecting] = useState(false);
    const workersLength = workers.filter((worker) => worker.name !== "").length;
    return (
      <Grid
        borderLeft={1}
        borderColor={BORDER_COLOR}
        item
        xs={1.4}
        sx={{
          display: "flex",
          minWidth: "123px",
          height: "74px",
        }}
      >
        {workersLength > 0 && (
            <Box
                onMouseEnter={() => setSelecting(true)}
                onMouseLeave={() => setSelecting(false)}
                sx={[
                    {
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        padding: "8px 4px 4px 8px",
                        minHeight: "72px",
                    },
                    !selecting ? { overflow: "hidden" } : {},
                ]}
            >
                {workers.map((worker) => (
                    <Box
                        key={`unallocated-worker-${dateString}-${worker.workerid}`}
                        sx={{
                            paddingBottom: "4px",
                        }}
                    >
                        {worker.name !== "" && (
                            <Card
                                text={worker.name}
                                selectWorker={() => {
                                    changeSelectedWorker(worker, dateString);
                                    setSelecting(false);
                                }}
                            />
                        )}
                    </Box>
                ))}
            </Box>
        )}
      </Grid>
    );
  };
  return (
    <Grid
      container
      borderBottom={1}
      borderColor={BORDER_COLOR}
      sx={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "#FFFFF6",
        flex: "1 0 auto",
      }}
    >
      <Grid item xs={2.2}>
        <Box
          sx={{
            minHeight: "72px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              marginLeft: "24px",
              fontSize: "16px",
              fontFamily: "Roboto",
              fontWeight: "bold",
            }}
          >
            Unallocated workers
          </Typography>
        </Box>
      </Grid>
      <>
        {datesArrayOfSelectedWeek.map((date, index) => (
          <WorkersCell
            key={`unallocated-worker-${format(date, "yyyy-MM-dd")}-${index}`}
            dateString={format(date, "yyyy-MM-dd")}
            workers={getUnallocatedWorkersForDate(format(date, "yyyy-MM-dd"))}
          />
        ))}
      </>
    </Grid>
  );
};
