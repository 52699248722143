// TODO - Type 'any' needs to be fixed.
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import {styled} from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import * as React from "react";

const StyledSwitch = styled(Switch)(({ theme }) => ({
    padding: 8,
    "& .MuiSwitch-track": {
        borderRadius: 22 / 2,
        "&:before, &:after": {
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            width: 16,
            height: 16,
        },
    },
    "& .MuiSwitch-thumb": {
        boxShadow: "none",
        width: 16,
        height: 16,
        margin: 2,
    },
}));

interface JobStatusSwitchProps {
    jobId: number;
    isExpired: boolean;
    disabled: boolean;
    workerId: number;
    onChange: (checked: boolean, jobId: number, workerId: number) => void;
}

const JobStatusSwitch: React.FC<JobStatusSwitchProps> = ({ jobId, isExpired, disabled, workerId, onChange }) => {
    return (
        <FormGroup>
            <FormControlLabel
                control={
                    <StyledSwitch
                        checked={!isExpired}
                        color={disabled ? "default" : "primary"}
                    />
                }
                style={{
                    width: 120,
                    display: "flex",
                    justifyContent: "space-between",
                }}
                label={isExpired ? "Expired" : "Active"}
                disabled={disabled}
                onChange={(e) => {
                    const eventTarget: EventTarget = e.target;
                    const checked: boolean = (eventTarget as HTMLInputElement).checked;
                    return onChange(checked, jobId, workerId);
                }}
            />
        </FormGroup>
    );
}

export default JobStatusSwitch;