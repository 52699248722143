import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, Button, Typography } from "@mui/material";
import Popover from "@mui/material/Popover";
import { useState } from "react";
import React from "react";

import useScheduleController from "../controllers/schedule-controller";
import WeekDatePicker from "./week-date-picker";

export const WeeklyScheduleDateRangePicker = () => {
	const [
		{ anyDateOfSelectedWeek },
		{ getDateRangeText, changeDateOfSelectedWeek },
	] = useScheduleController();

	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<Button
				onClick={() => {
					changeDateOfSelectedWeek(new Date());
				}}
				sx={{
					fontFamily: "Roboto",
					fontSize: "12px",
					padding: "4px 0px",
					color: "rgba(0,0,0,0.87)",
					borderColor: "rgba(0,0,0,0.57)",
				}}
				variant='outlined'
			>
				Today
			</Button>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "flex-start",
					justifyContent: "center",
					marginLeft: "16px",
					marginRight: "8px",
				}}
			>
				<Box
					onClick={() => {
						const previousWeekAnyDate = new Date(
							anyDateOfSelectedWeek.getTime() - 7 * 24 * 60 * 60 * 1000
						);
						changeDateOfSelectedWeek(previousWeekAnyDate);
					}}
					sx={{
						backgroundColor: "#F4F4F5",
						borderRadius: "4px",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						padding: "4px",
						cursor: "pointer",
					}}
				>
					<ChevronLeftIcon
						sx={{
							height: "24px",
							color: "rgba(0,0,0,0.56)",
						}}
					/>
				</Box>
			</Box>
			<Box>
				<Box
					sx={{
						minWidth: "194px",
						minHeight: "31px",
						backgroundColor: "rgba(47, 77, 139, 0.08)",
						padding: "5px 4px",
						borderRadius: "4px",
						cursor: "pointer",
					}}
					onClick={handleClick}
				>
					<Typography
						sx={{
							textAlign: "center",
							fontSize: "14px",
							fontColor: "#0E1B32",
							fontWeight: 500,
						}}
					>
						{getDateRangeText(anyDateOfSelectedWeek)}
					</Typography>
				</Box>
				<Popover
					id={id}
					open={open}
					anchorEl={anchorEl}
					onClose={handleClose}
					transformOrigin={{
						horizontal: "center",
						vertical: "top",
					}}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "center",
					}}
				>
					<WeekDatePicker
						anyDateOfSelectedWeek={anyDateOfSelectedWeek}
						changeDateOfSelectedWeek={changeDateOfSelectedWeek}
					/>
				</Popover>
			</Box>
			<Box
				onClick={() => {
					const nextWeekAnyDate = new Date(
						anyDateOfSelectedWeek.getTime() + 7 * 24 * 60 * 60 * 1000
					);
					changeDateOfSelectedWeek(nextWeekAnyDate);
				}}
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "flex-start",
					justifyContent: "center",
					marginLeft: "8px",
					cursor: "pointer",
				}}
			>
				<Box
					sx={{
						backgroundColor: "#F4F4F5",
						borderRadius: "4px",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						padding: "4.5px",
					}}
				>
					<ChevronRightIcon
						sx={{
							height: "24px",
							color: "rgba(0,0,0,0.56)",
						}}
					/>
				</Box>
			</Box>
		</Box>
	);
};
