// TODO - Type 'any' needs to be fixed.
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
	deleteWorkerCertificationFile,
	setDeletedFiles,
	setPostDeleteStatus,
	setPostUploadStatus,
	setUploadedFiles,
	uploadWorkerCertification,
} from "../../../../../../../../../export/workers";

interface WorkerCertFile {
	workerCertFileId: number;
	workerCertId: number;
	fileName: string;
	fileExt: string;
	fileSizeKB: number;
	isActive: boolean;
}

interface FormikInitialValuesProps {
	validFrom: any;
	validTo: any;
	workerCertFiles: WorkerCertFile[];
	certName: string;
	isActive: boolean;
	workerCertId: number | null;
	fileExt: string;
}

const useEditCertificationDialogController = () => {
	const initialValues = useSelector(
		(state: any) =>
			state.manageWorkers.editWorkerCertificationDialog.certificationData
	);

	const [formikInitialValues, setFormikInitialValues] =
		useState<FormikInitialValuesProps>({
			validFrom: null,
			validTo: null,
			certName: "",
			workerCertFiles: [],
			isActive: false,
			workerCertId: null,
			fileExt: "",
		});

	// Tracking errors during file uploads
	const [fileUploadErrors, setFileUploadErrors] = useState<{
		[key: string]: string;
	}>({});
	// Tracking errors during file deletions
	const [fileDeletionErrors, setFileDeletionErrors] = useState<{
		[key: string]: string;
	}>({});
	// Tracking files to upload
	const [files, setFiles] = useState<any>([]);
	// Tracking files to delete
	const [filesToDelete, setFilesToDelete] = useState<any>([]);
	const dispatch = useDispatch();

	const successfullyUploadedFiles: any = [];

	const uploadFilesInParallel = async ({
		workerCertId,
		values,
		setFieldValue,
	}: any) => {
		// no files to upload
		if (files.length === 0) return;

		// set upload status to pending
		dispatch(setPostUploadStatus("pending"));
		// calling upload worker certification for each file
		const uploadPromises = files.map((file: any) =>
			dispatch(uploadWorkerCertification({ workerCertId, file }))
		);

		const results = await Promise.all(uploadPromises);
		// track if any errors occurred during file upload
		let hasError = false;

		results.forEach((result, index) => {
			// results have all the objects rejected or resolved
			if ("error" in result) {
				const fileName = result.meta.arg.file.name;
				const errorMessage = result.payload;
				setFileUploadErrors((prev: any) => ({
					...prev,
					[fileName]: errorMessage,
				}));
				hasError = true;
				console.log("in error")
			} else {
				const fileName = result.meta.arg.file.name;
				const fileSizeKB = result.meta.arg.file.size / 1024;
				const workerCertFileId = result.payload;
				const id = result.meta.arg.file.id;
				const fileExt = result.meta.arg.file.name.split(".").pop();
				// update redux state
				dispatch(
					setUploadedFiles({
						id: workerCertFileId,
						workerCertId,
						fileName,
						fileSizeKB,
						workerCertFileId,
						fileExt,
					})
				);
				successfullyUploadedFiles.push({
					id: workerCertFileId,
					workerCertFileId,
					workerCertId,
					fileName,
					fileSizeKB,
					localId: id,
					fileExt,
				});
			}
		});

		if (hasError) {
			dispatch(setPostUploadStatus("error"));
			// update dialog state
			setFieldValue("workerCertFiles", [
				...values.workerCertFiles,
				...successfullyUploadedFiles,
			]);
			// remove files that were successfully uploaded from files
			// so that they are not uploaded again
			setFiles(files.filter((file: any) => {
				const localId = file.id;
				return !successfullyUploadedFiles.some(
					(successfullyUploadedFile: any) => successfullyUploadedFile.localId === localId
				);
			}));
		} else {
			// update dialog state
			// need to update in case of successful upload
			// since there can be another api which fails and dialog remains open
			setFieldValue("workerCertFiles", [
				...values.workerCertFiles,
				...successfullyUploadedFiles,
			]);
			dispatch(setPostUploadStatus("fulfilled"));
		}
	};

	const deleteFilesInParallel = async (key: object) => {
		if(dispatch){
			try {
				if (filesToDelete.length === 0) return;
				dispatch(setPostDeleteStatus("pending"));
			
				const deletePromises = filesToDelete.map((fileId: number) =>
					dispatch(deleteWorkerCertificationFile(fileId))
				);
				const results = await Promise.all(deletePromises);
				let hasError = false;
		
				results.forEach((result, index) => {
					if (result.error) {
						const errorMessage = result.error;
						const fileId = filesToDelete[index];
						setFileDeletionErrors((prev: any) => ({
							...prev,
							[fileId]: errorMessage,
						}));
						hasError = true;
					} else {
						// update redux state
						dispatch(setDeletedFiles({ workerCertFileId: result.payload }));
					}
				});
		
				if (hasError) {
					dispatch(setPostDeleteStatus("error"));
					// remove files that were successfully deleted from filesToDelete
					// so that they are not delted again
					setFilesToDelete(filesToDelete.filter((fileId: number) => {
						return !results.some((result: any) => result.payload === fileId);
					}));
				} else {
					setFilesToDelete([]);
					dispatch(setPostDeleteStatus("fulfilled"));
				}
			} catch (error) {
				console.log(
					"An unexpected error occurred during file deletion:",
					error
				);
				dispatch(setPostDeleteStatus("error"));
			}
		}
		
	};

	useEffect(() => {
		if (initialValues) setFormikInitialValues(initialValues);
	}, [initialValues]);

	useEffect(() => {
		return () => {
			setFiles([]);
		};
	}, []);

	return {
		formikInitialValues,
		fileUploadErrors,
		setFileUploadErrors,
		uploadFilesInParallel,
		files,
		setFiles,
		filesToDelete,
		setFilesToDelete,
		deleteFilesInParallel,
		fileDeletionErrors,
	};
};

export default useEditCertificationDialogController;
