// TODO - Type 'any' needs to be fixed.
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import {useEffect} from "react";
import * as React from "react";

import SkillItem from "../skill-item";

type Skills = {
    id: number;
    name: string;
};

interface SwitchJobSkillsProps {
    skillState: any;
    onSelectSkillCheckbox: ({ id }: { id: number }) => void;
    allSkills: Skills[];
    search: string,
}
const SkillList = (
    {
        skillState,
        onSelectSkillCheckbox,
        allSkills,
        search,
    }: SwitchJobSkillsProps
) => {
  const [checked, setChecked] = React.useState([0]);
  const [skillsList, setSkillsList] = React.useState<Skills[]>([]);
  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

    useEffect(() => {
        if (allSkills && allSkills.length > 0) {
            setSkillsList(allSkills);
        }
    }, [allSkills]);

    useEffect(() => {
      if (search) {
        const filteredSkills = allSkills.filter((skill) => 
          skill.name.toLowerCase().includes(search.toLowerCase())
        );
        setSkillsList(filteredSkills);
      } else {
        setSkillsList(allSkills);
      }
    }, [search, allSkills]);

  return (
    <List sx={{
        width: "100%",
    }}>
      {skillsList.map((skill) => {
          const {id} = skill;
        const labelId = `skill-checkbox-list-label-${id}`;

          return (
              <ListItem
                  key={`shift-skill-${id}`}
                  sx={{ height: "57px" }}
                  disableGutters={true}
                  divider={false}>
                  <ListItemButton
                      role={undefined}
                      disableRipple
                      onClick={handleToggle(id)}
                      dense
                      disableGutters={false}>
                      <ListItemIcon sx={{
                          minWidth: "auto",
                          paddingLeft: "8px" }}>
                          <Checkbox
                              edge="start"
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ "aria-labelledby": labelId }}
                              checked={skillState?.[id] ?? false}
                              onClick={() => onSelectSkillCheckbox({ id })}
                          />
                      </ListItemIcon>
                      <SkillItem skill={skill} search={search}/>
                  </ListItemButton>
              </ListItem>
          );
      })}
    </List>
  );
}

export default SkillList;