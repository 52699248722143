import { Divider, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";

import ShiftScheduleForm from "./form/shift-schedule-form";

interface ShiftScheduleDialogProps {
	locationData: any; // Replace 'any' with the actual type
	isScheduleRulesDialogOpen: boolean;
	closeScheduleRulesDialog: () => void;
	status: any;
	error: any;
	getDuration: (
		startTime: string,
		endTime: string,
		breakDuration: number
	) => string | number;
	isEdit: boolean;
	shiftScheduleData: any; // Replace 'any' with the actual type
}

const ShiftScheduleDialog: React.FC<ShiftScheduleDialogProps> = ({
	locationData,
	isScheduleRulesDialogOpen,
	closeScheduleRulesDialog,
	status,
	error,
	getDuration,
	isEdit,
	shiftScheduleData,
}) => {
	return (
		<Dialog
			onClose={closeScheduleRulesDialog}
			aria-labelledby='add-new-location'
			open={isScheduleRulesDialogOpen}
			maxWidth={false}
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<DialogTitle
				id='location-dialog-title'
				sx={{
					width: "900px",
				}}
			>
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontSize: "20px",
						fontWeight: 500,
						color: "rgba(0, 0, 0, 0.87)",
						paddingTop: "29px",
						paddingBottom: "9px",
						paddingRight: "21px",
					}}
				>
					Shift Schedule
				</Typography>
			</DialogTitle>
			<DialogContent>
				<ShiftScheduleForm
					locationData={locationData}
					closeScheduleRulesDialog={closeScheduleRulesDialog}
					status={status}
					error={error}
					getDuration={getDuration}
					isEdit={isEdit}
					shiftScheduleData={shiftScheduleData}
				/>
			</DialogContent>
			<Divider />
		</Dialog>
	);
};

export default ShiftScheduleDialog;
