import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { changeSelectedMenuItemId } from "../../../export/drawer";
import { NavDrawer } from "../../../export/nav-drawer";
import useViewport from "../../utils/use-viewport";
import ManageFactories from "./manage-factories";

const AdminConfiguration = () => {
    const roles = useSelector((state: any) => state.user?.userData?.userRoles);
    const { height } = useViewport();
    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(() => {
        if (roles.includes("GATAdmin") || roles.includes("Admin")) {
            // To select admin config menu item
            dispatch(changeSelectedMenuItemId(5));
        } else {
            history.push("/");
        }
    }, [dispatch, history, roles]);
    if (roles.includes("GATAdmin") || roles.includes("Admin")) {
        return (
            <Box sx={{ display: "flex", backgroundColor: "#F5F5F5", height: height }}>
                <CssBaseline />
                <NavDrawer keepSettingOpen={false} />
                <ManageFactories />
            </Box>
        );
    } else {
        return <></>
    }
};

export default AdminConfiguration;
