import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { GridRowId } from "@mui/x-data-grid";
import {
  DataGridPro,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import React, { useCallback, useEffect, useState } from "react";

import useViewport from "../../../../utils/use-viewport";
import useSearch from "../../controllers/use-search";
import { ShiftDetailsTableProps } from "../dialog/shift-details-dialog/types";

const AssignShiftTable: React.FC<ShiftDetailsTableProps> = ({
  rows,
  columns,
  workersSelected,
  setWorkersSelected,
  readOnly = false,
  searchValue = "",
  detailPanelExpandedRowIds,
  totalWorkersRequired = 0,
}) => {
  const filteredRows = useSearch({
    data: rows,
    searchValue,
    searchField: "workerName",
  });
  const apiRef = useGridApiRef();
  const { height } = useViewport();

  const getDetailPanelContent = useCallback(({ row }) => {
    return row.errorMessage ? (
      <Alert severity="error">{row.errorMessage}</Alert>
    ) : null;
  }, []);

  const getDetailPanelHeight = useCallback(() => 50, []);
  const [canSelectRow, setCanSelectRow] = useState(true);

  useEffect(() => {
    try {
      // Removing check from disabled rows
      setWorkersSelected((prevState: GridRowId[]) => {
        const newState: GridRowId[] = [];
        prevState.forEach((state: GridRowId) => {
          rows.forEach((row) => {
            if (state === row.workerId && row.errorMessage === "") {
              newState.push(state);
            }
          });
        });
        return newState;
      });
    } catch (e) {
      // Do nothing
    }
  }, [rows, setWorkersSelected, detailPanelExpandedRowIds]);

  useEffect(() => {
    if (totalWorkersRequired > workersSelected.length) {
      setCanSelectRow(true);
    } else {
      setCanSelectRow(false);
    }
  }, [workersSelected, totalWorkersRequired]);

  return (
    <Box width={1155} mr="auto" ml="auto" height={"100%"}
    sx={{
      overflowX: "hidden",
    }}>
      <DataGridPro
        apiRef={apiRef}
        key={`workers-for-assign-shift-${detailPanelExpandedRowIds.length}`}
        components={{
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              No workers found
            </Stack>
          ),
        }}
        rows={filteredRows}
        columns={[
          ...columns.filter((column: any) => column.checked),
          {
            ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
            type: "string",
            renderCell: (params: any) => <></>,
          },
        ]}
        isRowSelectable={(params) =>
          params.row.errorMessage === "" &&
          !readOnly &&
          (workersSelected.includes(params.row.id) || canSelectRow)
        }
        hideFooter={true}
        checkboxSelection={true}
        selectionModel={workersSelected}
        onSelectionModelChange={(e) => {
          setWorkersSelected(e);
        }}
        disableColumnSelector
        sx={{
          border: 0,
          paddingLeft: "21px",
          paddingRight: "21px",
          marginBottom: "-20px",
          overflowX: "hidden",
          height: height - 425,
          "& .MuiDataGrid-columnHeaderTitleContainerContent": {
            width: "100%",
          },
          "& .MuiDataGrid-columnHeader:last-child": {
            width: "0px",
          },
          "& .MuiDataGrid-columnSeparator": {
            opacity: "0 !important",
          },
          "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within":
            {
              outline: "none !important",
            },
        }}
        detailPanelExpandedRowIds={detailPanelExpandedRowIds}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={getDetailPanelHeight}
      />
    </Box>
  );
};

export default AssignShiftTable;
