// TODO - Type 'any' needs to be fixed.
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import React from "react";

import Button from "../../../../../../design/button";
import DialogWrapper from "../../../../../../design/dialog-wrapper";
import ShiftPattern from "./shift-pattern";

type ShiftPatternDialogProps = {
	open: boolean;
	handleClose: () => void;
	shiftPattern: any;
	locationId: number;
};

const ShiftPatternDialog: React.FC<ShiftPatternDialogProps> = ({
	open,
	handleClose,
	shiftPattern,
	locationId,
}) => {
	return (
		<Dialog open={open} onClose={handleClose} maxWidth={false}>
			<DialogWrapper
				dialogTitle='View Shift Pattern'
				dialogContent={
					<ShiftPattern shiftPattern={shiftPattern} locationId={locationId} />
				}
				actionButtons={
					<Box mr={1}>
						<Button onClick={handleClose} variant='outlined'>
							Close
						</Button>
					</Box>
				}
			/>
		</Dialog>
	);
};

export default ShiftPatternDialog;
