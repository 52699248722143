// TODO - Type 'any' needs to be fixed.
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Box, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import React, { useRef } from "react";

import SkillLevelDropdown from "../../../../../../../design/skill-level-dropdown";
import useOnView from "../../../../../../../utils/use-on-view";
import useWorkersData from "../../../../../controllers/use-workers-data";
import { distinctElements } from "../../../../helper";

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

type AssignOtherSkillsProps = {
	workerAssignedSkills: number[];
	handleNewAssignedSkills?: (skillId: number, level: number | string) => void;
	newAssignedSkills?: any[];
	skillsMappedWithJob?: number[];
	removeMultipleSkills?: (skillIds: number[]) => void;
};

const AssignOtherSkills = ({
	workerAssignedSkills,
	handleNewAssignedSkills,
	newAssignedSkills,
	skillsMappedWithJob,
	removeMultipleSkills,
}: AssignOtherSkillsProps) => {
	const [value, setValue] = React.useState<any[] | null>([]);

	const ref = useRef<HTMLDivElement>(null);
	const isIntersecting = useOnView(ref);

	const { allSkills, allSkillLevels, obsoleteSkills } = useWorkersData();

	const unassignedSkills = allSkills.filter(
		(skill: any) =>
			!workerAssignedSkills?.includes(skill.id) &&
			!skillsMappedWithJob?.includes(skill.id) &&
			!obsoleteSkills?.includes(skill.id)
	);

	const removeSkill = (skillId: number) => {
		setValue((val) => (val ? val.filter((item) => item.id !== skillId) : []));
	};

	return (
		<Box mt={2} mb={2}>
			<Box display='flex' alignItems='center'>
				<Box width={200}>
					{" "}
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 500,
							fontSize: "14px",
							color: "#000000",
							lineHeight: "24px",
							letterSpacing: "0.17px",
						}}
					>
						Additional Skills
					</Typography>
				</Box>
				<Box ml={1} ref={ref}>
					<Autocomplete
						value={value ? value : []}
						multiple
						id='tags-filled'
						options={unassignedSkills}
						getOptionLabel={(option: string | { name: string }) => {
							if (typeof option === "string") {
								return option;
							}
							return option.name;
						}}
						renderOption={(props, option: { name: string }, { selected }) => {
							if (!isIntersecting) return null;
							return (
								<li {...props}>
									<Checkbox
										icon={icon}
										checkedIcon={checkedIcon}
										style={{ marginRight: 8 }}
										checked={selected}
									/>
									{option.name}
								</li>
							);
						}}
						onChange={(event, newValue, reason) => {
							if (reason === "selectOption") {
								setValue(newValue);
								return;
							} else if (reason === "removeOption") {
								const removedItem = distinctElements(value, newValue, "id");
								removeMultipleSkills?.(removedItem.map((item) => item.id));
								setValue(newValue);
								return;
							}
						}}
						disableCloseOnSelect
						renderTags={(value: any, getTagProps) => <></>}
						renderInput={(params) => (
							<TextField
								{...params}
								variant='outlined'
								label='Select Skills'
								// placeholder='Select Other Skills'
							/>
						)}
						onInputChange={(e, v, reason) => {
							if (reason === "clear") {
								const commonElements =
									newAssignedSkills?.filter((item1) =>
										value?.some((item2) => item1.skillId === item2.id)
									) || [];
								removeMultipleSkills?.(
									commonElements.map((item) => item.skillId)
								);
								setValue([]);
								return;
							}
						}}
						sx={{ width: 560 }}
						componentsProps={{
							popper: {
								style: {
									width: 560,
									display: isIntersecting ? "block" : "none",
								},
							},
						}}
					/>
				</Box>
			</Box>
			<Box 
			sx={{
				// maxWidth: 840,
				// width: "100%",
				display: "grid",
				gridTemplateColumns: "1fr 1fr",
				// ml: "208px",
				mt: 1,
			}}>
				{value?.map((skill: any) => (
					<Box key={skill.id} mr={1} mt={1} mb={1}>
						<SkillLevelDropdown
							allSkillLevels={allSkillLevels}
							skillName={skill.name}
							skillId={skill.id}
							handleSkill={handleNewAssignedSkills}
							newAssignedSkills={newAssignedSkills}
							removeSkill={removeSkill}
						/>
					</Box>
				))}
			</Box>
		</Box>
	);
};

export default AssignOtherSkills;
