import { Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";

import Highlight from "../../../../Highlight";
import { MIN_CROSS_SKILL_ROW_HEIGHT } from "../../../constants";

interface Params {
    row: {
        firstName: string;
        lastName: string;
        [key: string]: any;
    };
    [key: string]: any;
}

interface ColumnEmployeeCellProps {
    params: Params;
    searchText: string;
}

const ColumnEmployeeCell: React.FC<ColumnEmployeeCellProps> = ({ params, searchText }) => {
    const fullName = `${params.row.firstName} ${params.row.lastName}`;

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                cursor: "pointer",
                padding: "0px 10px 0px 24px",
                textOverflow: "ellipsis",
                width: "100%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                height: MIN_CROSS_SKILL_ROW_HEIGHT,
            }}
        >
            <Tooltip title={fullName}>
                <Typography
                    sx={{
                        fontFamily: "Roboto",
                        fontSize: "14px",
                        fontWeight: 400,
                    }}
                >
                    <Highlight value={fullName} searchText={searchText} />
                </Typography>
            </Tooltip>
        </div>
    );
};

export default ColumnEmployeeCell;
