// TODO - Type 'any' needs to be fixed.
/* eslint-disable simple-import-sort/imports */
import { Box, Button, Tooltip, Typography } from "@mui/material";
import React from "react";
import { generateJobString, getAllSkills } from "../../helper";
import { SkillRepresentationCell } from "../../../../../../export/cross-skilling/index";
import { getLevelObj } from "../../../../../utils/helpers";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { openEditWorkerSkillDialog } from "../../../../../../export/workers";

import { useDispatch, useSelector } from "react-redux";
import { useConfirm } from "material-ui-confirm";
import { deleteWorkerSkills } from "../../../../../../export/workers";
import { isFuture } from "date-fns";
import { format } from "date-fns";
import Highlight from "../../../../Highlight";
import JobColumn from "../ui/table/job-column";

interface GridColumn {
	field: string;
	headerName?: string;
	flex?: number;
	width?: number;
	minWidth?: number | string;
	renderHeader?: (params: any) => JSX.Element;
	renderCell?: (params: any) => JSX.Element;
	sortable?: boolean;
	disableColumnMenu?: boolean;
	cellClassName?: string;
	headerClassName?: string;
	valueGetter?: (params: any) => any;
	filterable?: boolean;
}

const deleteContent = ({ skillName }: { skillName: string }) => (
	<Box>
		<Typography
			sx={{
				fontFamily: "Roboto",
				fontSize: "16px",
				fontWeight: 400,
				lineHeight: "28px",
			}}
		>
			{`Are you sure you want to remove the Skill "${skillName}" for this Worker? Any
			associated Certifications will stay on the Worker’s profile.`}
		</Typography>
	</Box>
);

const SkillValue: React.FC<{ value: number }> = ({ value }) => {
	const skillLevels = useSelector(
		(state: any) => state.manageWorkers.skillLevels
	);
	if (value >= 0) {
		const { levelValue, repLevel, backgroundColor, foregroundColor, repType } =
			getLevelObj(String(value), skillLevels);
		return (
			<SkillRepresentationCell
				level={value}
				value={levelValue}
				representationType={repType}
				representationLevel={repLevel}
				backgroundColor={backgroundColor}
				foregroundColor={foregroundColor}
				border={1}
				height={20}
				width={20}
				backgroundWidth={"30px"}
				backgroundHeight={"30px"}
			/>
		);
	} else {
		return <></>;
	}
};

const useWorkerSkillColumn = (searchText: string) => {
	const confirm = useConfirm();
	const dispatch = useDispatch();

	const workerProfile = useSelector(
		(state: any) => state.manageWorkers.workerProfile.profile
	);

	const workerId = workerProfile?.workerId || null;

	const columns: GridColumn[] = [
		{
			field: "SkillName",
			headerName: "Skill Name",
			minWidth: 185,
			flex: 1,
			renderHeader: () => {
				return (
					<Box>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 500,
								fontSize: "14px",
								color: "#000000",
								letterSpacing: "0.17px",
							}}
						>
							{"Skill Name"}
						</Typography>
					</Box>
				);
			},
			renderCell: (params: any) => {
				return (
					<Box mt={1} mb={1}>
						<Highlight searchText={searchText} value={params.row.skillName} />
					</Box>
				);
			},
			valueGetter: (params: any) => params.row.skillName,
		},
		{
			field: "skillPriority",
			headerName: "Skill Priority",
			minWidth: 185,
			flex: 1,
			renderHeader: () => {
				return (
					<Box>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 500,
								fontSize: "14px",
								color: "#000000",
								letterSpacing: "0.17px",
							}}
						>
							{"Skill Priority"}
						</Typography>
					</Box>
				);
			},
			renderCell: (params: any) => {
				if (params.row.skillPriority) {
					return (
						<Box mt={1} mb={1}>
							<Highlight searchText={searchText} value={params.row.skillPriority} />
						</Box>
					);
				} else return <></>
			},
			valueGetter: (params: any) => params.row.skillPriority,
		},
		{
			field: "status",
			minWidth: 120,
			flex: 1,
			filterable: false,
			disableColumnMenu: true,
			renderHeader: (params: any) => {
				return (
					<Box>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 500,
								fontSize: "14px",
								color: "#000000",
								letterSpacing: "0.17px",
							}}
						>
							Skill Status
						</Typography>
					</Box>
				);
			},
			renderCell: (params: any) => {
				// console.log(params.row);
				const validFrom = params.row.validFrom;
				const validUntil = params.row.validTo;

				if (!validFrom || isFuture(new Date(validFrom))) return <></>;
				if (!validUntil || isFuture(new Date(validUntil)))
					return (
						<Box>
							<Highlight searchText={searchText} value='Active' />
						</Box>
					);

				return (
					<Box>
						<Highlight
							searchText={searchText}
							value='Expired'
							color='#E44B48'
						/>
					</Box>
				);
			},
			valueGetter: (params: any) => {
				const validFrom = params.row.validFrom;
				const validUntil = params.row.validTo;

				if (!validFrom || isFuture(new Date(validFrom))) return "Active";
				if (!validUntil || isFuture(new Date(validUntil))) return "Expired";

				return "Expired";
			},
		},
		{
			field: "skillLevel",
			minWidth: 170,
			flex: 1,
			filterable: false,
			disableColumnMenu: true,
			renderHeader: (params: any) => {
				return (
					<Box>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 500,
								fontSize: "14px",
								color: "#000000",
								letterSpacing: "0.17px",
							}}
						>
							Skill Level
						</Typography>
					</Box>
				);
			},
			renderCell: (params: any) => {
				const level = params.row?.level ?? 0;

				return (
					<Box display='flex' alignItems='center'>
						<SkillValue value={level} />
						<Box ml={1}>
							<Highlight
								searchText={searchText}
								value={params.row?.levelName ?? ""}
							/>
						</Box>
					</Box>
				);
			},
			valueGetter: (params: any) => params.row.level,
		},
		{
			field: "associatedJobs",
			minWidth: 310,
			flex: 1,
			filterable: false,
			disableColumnMenu: true,
			renderHeader: (params: any) => {
				return (
					<Box>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 500,
								fontSize: "14px",
								color: "#000000",
								letterSpacing: "0.17px",
							}}
						>
							{"Worker’s Job Roles"}
						</Typography>
					</Box>
				);
			},
			renderCell: (params: any) => {
				const jobs = params.row?.associatedJobs ?? [];
				// const { jobString, remainig, seeMore } = generateJobString(jobs);
				// const jobsText = jobString + " " + seeMore;

				if (jobs.length === 0) return <Box>-</Box>;
				return (
					<Box maxWidth={260} display='flex'>
						<JobColumn jobs={jobs} searchText={searchText} />
					</Box>
				);
			},
			valueGetter: (params: any) => params.row?.associatedJobs?.length,
		},
		{
			field: "validFrom",
			minWidth: 130,
			flex: 1,
			filterable: false,
			disableColumnMenu: true,
			renderHeader: (params: any) => {
				return (
					<Box>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 500,
								fontSize: "14px",
								color: "#000000",
								letterSpacing: "0.17px",
							}}
						>
							Valid From
						</Typography>
					</Box>
				);
			},
			renderCell: (params: any) => {
				if (!params.row.validFrom) return <>-</>;

				const datetime = params.row.validFrom + "T00:00:00";
				const validFromDate = format(new Date(datetime), "MM/dd/yyyy");

				return (
					<Box>
						<Highlight searchText={searchText} value={validFromDate} />
					</Box>
				);
			},
			valueGetter: (params: any) => params.row.validFrom,
		},
		{
			field: "validUntil",
			minWidth: 130,
			flex: 1,
			filterable: false,
			disableColumnMenu: true,
			renderHeader: (params: any) => {
				return (
					<Box>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 500,
								fontSize: "14px",
								color: "#000000",
								letterSpacing: "0.17px",
							}}
						>
							Valid Until
						</Typography>
					</Box>
				);
			},
			renderCell: (params: any) => {
				if (!params.row.validTo) return <>-</>;
				const datetime = params.row.validTo + "T00:00:00";
				const validToDate = format(new Date(datetime), "MM/dd/yyyy");

				return (
					<Box>
						<Highlight searchText={searchText} value={validToDate} />
					</Box>
				);
			},
			valueGetter: (params: any) => params.row.validTo,
		},
		{
			field: "actions",
			width: 80,
			sortable: false,
			filterable: false,
			disableColumnMenu: true,
			renderHeader: (params: any) => <></>,
			cellClassName: "hover-column-cell",
			//headerClassName: "hover-column-header",
			renderCell: (params: any) => {
				const handleDelete = () => {
					if (!params.row.skillId || !workerId) return;
					confirm({
						title: "Remove Skill for Worker",
						content: deleteContent({ skillName: params.row.skillName }),
						dialogProps: {
							maxWidth: false,
							fullWidth: false,
						},
						contentProps: {
							sx: {
								width: "850px",
							},
						},
						cancellationText: "Cancel",
						confirmationText: (
							<Button variant='contained' color='error'>
								Remove
							</Button>
						),
					})
						.then(() =>
							dispatch(
								deleteWorkerSkills({
									workerId: workerId,
									skillId: params.row.skillId,
								})
							)
						)
						.catch(() => console.log("Deletion cancelled."));
				};

				return (
					<Box
						className='hover-content'
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							minWidth: "100px",
							padding: "12px 16px 8px 16px",
							backgroundColor: "#FFF",
							borderRadius: "4px",
						}}
					>
						<Box
							sx={{
								color: "#6c6c6c",
								"&:hover": {
									color: "#000000",
									transform: "scale(1.2)",
								},
								cursor: "pointer",
							}}
						>
							<Tooltip title='Edit'>
								<EditIcon
									id={`edit-job-${params?.row?.id}-enabled`}
									fontSize='small'
									onClick={(e) => {
										dispatch(openEditWorkerSkillDialog(params.row));
									}}
								/>
							</Tooltip>
						</Box>
						<Box
							sx={{
								color: "#6c6c6c",
								"&:hover": {
									color: "red",
									transform: "scale(1.2)",
								},
								cursor: "pointer",
							}}
						>
							<Tooltip title='Delete'>
								<DeleteIcon
									id={`delete-job-${params?.row?.id}-enabled`}
									sx={{ marginLeft: "8px" }}
									fontSize='small'
									onClick={handleDelete}
								/>
							</Tooltip>
						</Box>
					</Box>
				);
			},
		},
	];

	return columns;
};

export default useWorkerSkillColumn;
