import { Box, Switch, Typography } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import useGatAdminConfigData from "../../../../controllers/use-gat-admin-config-data";
import {
	resetCreateTimeOffType,
	resetDeleteTimeOffType,
	resetEditTimeOffType,
} from "../../../../store/gat-admin-slice";
import LeaveTypeForm, {
	LeaveTypeFormValues,
} from "./ui/dialog/leave-type-form";
import AttendanceManagementTable from "./ui/table";

interface LeaveType extends LeaveTypeFormValues {
	enabledLocations: { id: number }[];
	incrementHours: number;
	applyBeforeHours: number;
	locs: { id: number; name: string }[];
}

type AttendanceManagementProps = {
	newAddOnSettings: any;
	setNewAddOnSettings: any;
};

const AttendanceManagement: React.FC<AttendanceManagementProps> = ({
	newAddOnSettings,
	setNewAddOnSettings,
}) => {
	const [open, setOpen] = useState(false);
	const [mode, setMode] = useState<"add" | "edit">("add");
	const [initialData, setInitialData] = useState<LeaveTypeFormValues | null>(
		null
	);

	const dispatch = useDispatch();

	const {
		createTimeOffTypeStatus,
		editTimeOffTypeStatus,
		createTimeOffTypeErrorMessage,
		editTimeOffTypeErrorMessage,
		deleteTimeOffTypeErrorMessage,
		deleteTimeOffTypeStatus,
	} = useGatAdminConfigData();

	const handleOpenAdd = () => {
		setMode("add");
		setInitialData(null);
		setOpen(true);
	};

	const handleOpenEdit = (leaveTypeData: LeaveType) => {
		setMode("edit");
		setInitialData(leaveTypeData);
		setOpen(true);
	};

	const handleClose = () => setOpen(false);

	return (
		<Box
			sx={{
				backgroundColor: "#F5F5F5",
				borderRadius: "8px",
				marginTop: "32px",
				marginBottom: "16px",
				padding: "16px",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 400,
							fontSize: "16px",
							color: "rgba(0, 0, 0, 0.87)",
						}}
					>
						Attendance Management
					</Typography>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 400,
							fontSize: "12px",
							color: "rgba(0, 0, 0, 0.6)",
						}}
					>
						Setup Time Off Types for Full Time Workers as per Company Policy.
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Switch
						checked={newAddOnSettings.attendanceManagement ?? false}
						onChange={(e) =>
							setNewAddOnSettings((pre: any) => ({
								...pre,
								attendanceManagement: e.target.checked,
							}))
						}
						disabled={!newAddOnSettings.swapAndCancelShift}
					/>
				</Box>
			</Box>
			{newAddOnSettings.attendanceManagement ? (
				<AttendanceManagementTable
					handleOpenEdit={handleOpenEdit}
					handleOpenAdd={handleOpenAdd}
				/>
			) : null}

			<LeaveTypeForm
				open={open}
				onClose={handleClose}
				mode={mode}
				initialData={initialData}
			/>

			{/* Add Skills for worker */}
			<Snackbar
				open={createTimeOffTypeStatus === "fulfilled"}
				autoHideDuration={3000}
				onClose={() => dispatch(resetCreateTimeOffType())}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<MuiAlert elevation={6} variant='filled' severity='success'>
					Time Off added successfully
				</MuiAlert>
			</Snackbar>
			<Snackbar
				open={createTimeOffTypeStatus === "error"}
				autoHideDuration={3000}
				onClose={() => dispatch(resetCreateTimeOffType())}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<MuiAlert elevation={6} variant='filled' severity='error'>
					{createTimeOffTypeErrorMessage ?? "An error occurred"}
				</MuiAlert>
			</Snackbar>

			{/* Edit Skill for worker */}
			<Snackbar
				open={editTimeOffTypeStatus === "fulfilled"}
				autoHideDuration={3000}
				onClose={() => dispatch(resetEditTimeOffType())}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<MuiAlert elevation={6} variant='filled' severity='success'>
					Time Off edited successfully
				</MuiAlert>
			</Snackbar>
			<Snackbar
				open={editTimeOffTypeStatus === "error"}
				autoHideDuration={3000}
				onClose={() => dispatch(resetEditTimeOffType())}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<MuiAlert elevation={6} variant='filled' severity='error'>
					{editTimeOffTypeErrorMessage ?? "An error occurred"}
				</MuiAlert>
			</Snackbar>

			{/* Delete Skills for worker */}
			<Snackbar
				open={deleteTimeOffTypeStatus === "fulfilled"}
				autoHideDuration={3000}
				onClose={() => dispatch(resetDeleteTimeOffType())}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<MuiAlert elevation={6} variant='filled' severity='success'>
					Time Off Deactivated successfully
				</MuiAlert>
			</Snackbar>
			<Snackbar
				open={deleteTimeOffTypeStatus === "error"}
				autoHideDuration={3000}
				onClose={() => dispatch(resetDeleteTimeOffType())}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<MuiAlert elevation={6} variant='filled' severity='error'>
					{deleteTimeOffTypeErrorMessage ?? "An error occurred"}
				</MuiAlert>
			</Snackbar>
		</Box>
	);
};

export default AttendanceManagement;
