import { Box, Divider, Grid, Input, Switch, Typography } from "@mui/material";
import React, { Dispatch } from "react";

interface IKarma {
	newAddOnSettings: any
	setNewAddOnSettings: Dispatch<any>;
}
const Karma: React.FC<IKarma> = ({ newAddOnSettings, setNewAddOnSettings }) => {

	return (
		<Box
			sx={{
				backgroundColor: "#F5F5F5",
				borderRadius: "8px",
				marginTop: "32px",
				marginBottom: "16px",
				padding: "16px",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 400,
							fontSize: "16px",
							color: "rgba(0, 0, 0, 0.87)",
						}}
					>
						Karma score configuration
					</Typography>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 400,
							fontSize: "12px",
							color: "rgba(0, 0, 0, 0.6)",
						}}
					>
						Make the workforce accountable.
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Switch
						checked={newAddOnSettings.workerProfileEnabled ?? false}
						onChange={(e) =>
							setNewAddOnSettings((pre: any) => ({
								...pre,
								workerProfileEnabled: e.target.checked,
							}))
						}
					/>
				</Box>
			</Box>
			{newAddOnSettings.workerProfileEnabled ? (
				<Box
					sx={{
						marginTop: "20px",
					}}
				>
					<Grid
						container
						sx={{
							paddingBottom: "8px",
							paddingTop: "16px",
							paddingLeft: "20px",
							paddingRight: "20px",
						}}
					>
						<Grid item xs={9}>
							<Typography
								sx={{
									fontFamily: "Roboto",
									fontWeight: 500,
									fontSize: "14px",
									lineHeight: "24px",
									color: "rgba(0, 0, 0, 0.87)",
								}}
							>
								Incident
							</Typography>
						</Grid>
						<Grid item xs={3}>
							<Typography
								sx={{
									fontFamily: "Roboto",
									fontWeight: 500,
									fontSize: "14px",
									lineHeight: "24px",
									color: "rgba(0, 0, 0, 0.87)",
								}}
							>
								Reduction in reliability points
							</Typography>
						</Grid>
					</Grid>
					<Divider />
					<Grid
						container
						sx={{
							display: "flex",
							alignItems: "center",
							paddingBottom: "8px",
							paddingTop: "16px",
							paddingLeft: "20px",
							paddingRight: "20px",
						}}
					>
						<Grid item xs={9}>
							<Typography
								sx={{
									fontFamily: "Roboto",
									fontWeight: 400,
									fontSize: "14px",
									lineHeight: "24px",
									color: "rgba(0, 0, 0, 0.87)",
								}}
							>
								Cancelling shift outside acceptable shift dropout time window
							</Typography>
						</Grid>
						<Grid item xs={3}>
							<Input
								disabled
								// variant='filled'
								sx={{
									"& .MuiInputBase-input": {
										background: "#FFF",
										padding: "8px",
									},
								}}
								value={"0.5"}
							/>
						</Grid>
					</Grid>
					<Grid
						container
						sx={{
							display: "flex",
							alignItems: "center",
							paddingBottom: "8px",
							paddingTop: "16px",
							paddingLeft: "20px",
							paddingRight: "20px",
						}}
					>
						<Grid item xs={9}>
							<Typography
								sx={{
									fontFamily: "Roboto",
									fontWeight: 400,
									fontSize: "14px",
									lineHeight: "24px",
									color: "rgba(0, 0, 0, 0.87)",
								}}
							>
								Unscheduled absence / Tardy or leaving early :
							</Typography>
						</Grid>
						<Grid item xs={3}>
							<Input
								disabled
								// variant='filled'
								sx={{
									"& .MuiInputBase-input": {
										background: "#FFF",
										padding: "8px",
									},
								}}
								value={"0.5"}
							/>
						</Grid>
					</Grid>
					<Grid
						container
						sx={{
							display: "flex",
							alignItems: "center",
							paddingBottom: "8px",
							paddingTop: "16px",
							paddingLeft: "20px",
							paddingRight: "20px",
						}}
					>
						<Grid item xs={9}>
							<Typography
								sx={{
									fontFamily: "Roboto",
									fontWeight: 400,
									fontSize: "14px",
									lineHeight: "24px",
									color: "rgba(0, 0, 0, 0.87)",
								}}
							>
								No call No show/Marked absent (if tardiness &gt; 1/2 shift,
								consider absent)
							</Typography>
						</Grid>
						<Grid item xs={3}>
							<Input
								disabled
								// variant='filled'
								sx={{
									"& .MuiInputBase-input": {
										background: "#FFF",
										padding: "8px",
									},
								}}
								value={"1"}
							/>
						</Grid>
					</Grid>
					<Grid
						container
						sx={{
							display: "flex",
							alignItems: "center",
							paddingBottom: "8px",
							paddingTop: "16px",
							paddingLeft: "20px",
							paddingRight: "20px",
						}}
					>
						<Grid item xs={9}>
							<Typography
								sx={{
									fontFamily: "Roboto",
									fontWeight: 400,
									fontSize: "14px",
									lineHeight: "24px",
									color: "rgba(0, 0, 0, 0.87)",
								}}
							>
								For every 5 cancellations (within the acceptable dropout time
								frame) in a month (rolling last 30 days)
							</Typography>
						</Grid>
						<Grid item xs={3}>
							<Input
								disabled
								// variant='filled'
								sx={{
									"& .MuiInputBase-input": {
										background: "#FFF",
										padding: "8px",
									},
								}}
								value={"0.5"}
							/>
						</Grid>
					</Grid>
					<Grid
						container
						sx={{
							display: "flex",
							alignItems: "center",
							paddingBottom: "8px",
							paddingTop: "16px",
							paddingLeft: "20px",
							paddingRight: "20px",
						}}
					>
						<Grid item xs={9}>
							<Typography
								sx={{
									fontFamily: "Roboto",
									fontWeight: 400,
									fontSize: "14px",
									lineHeight: "24px",
									color: "rgba(0, 0, 0, 0.87)",
								}}
							>
								Excused absence/tardy
							</Typography>
						</Grid>
						<Grid item xs={3}>
							<Input
								disabled
								// variant='filled'
								sx={{
									"& .MuiInputBase-input": {
										background: "#FFF",
										padding: "8px",
									},
								}}
								value={"0"}
							/>
						</Grid>
					</Grid>
				</Box>
			) : null}
		</Box>
	);
};

export default Karma;

