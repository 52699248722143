import { differenceInHours } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
	fetchSkillLevels,
	resetCreateDraftShiftAsyncState,
} from "../../../../../export/shift-scheduler";
import { formatDate } from "../../../../utils/helpers";
import useWorkerRequestsData from "../../controllers/use-worker-requests-data";
import {
	getAllRegularRequests,
	getAllRequestsTypes,
} from "../../store/worker-requests-slice";
import {
	RegularShiftRequest,
	RequestStatusTypes,
} from "../../store/worker-requests-types";

const useFullTimeRequestController = () => {
	const dispatch = useDispatch();

	const {
		requestActionStatus,
		allRegularRequests,
		allRequestStatuses,
		attendanceManagement,
	} = useWorkerRequestsData();

	const [isCreateNewShiftDialogOpen, setIsCreateNewShiftDialogOpen] =
		useState(false);
	const [isShiftDialogOpen, setIsShiftDialogOpen] = useState(false);
	const [shiftDetailsId, setShiftDetailsId] = useState<number | null>(null);
	const [draftShiftDate, setDraftShiftDate] = useState("");
	const [searchQuery, setSearchQuery] = useState<string>("");

	const [isShiftCoverage, setIsShiftCoverage] = React.useState(true);

	const handleShiftCoverage = (value: boolean) => {
		setIsShiftCoverage(value);
	};
	const [timeFilterOption, setTimeFilterOption] =
		useState<string>("last_30_days");
	// const filteredRegularRequests = allRegularRequests;
	const openCreateNewShiftDialog = (date: string | null) => {
		if (date) setDraftShiftDate(date);
		dispatch(resetCreateDraftShiftAsyncState());
		setIsCreateNewShiftDialogOpen(true);
	};

	const closeCreateNewShiftDialog = () => {
		setIsCreateNewShiftDialogOpen(false);
	};

	const openShifDetailsDialog = (ids: string) => {
		if (!ids) return;
		const id = Number(ids.split(",")[0]);
		setShiftDetailsId(id);
		setIsShiftDialogOpen(true);
	};

	const closeShiftDetailsDialog = () => {
		setShiftDetailsId(null);
		setIsShiftDialogOpen(false);
	};

	const [filteredRegularRequests, setFilteredRegularRequests] =
		useState<RegularShiftRequest[]>(allRegularRequests);

	const [requestStatus, setRequestStatus] = useState(
		allRequestStatuses.map((item: RequestStatusTypes) => {
			if (item.code === "p") {
				return { ...item, checked: true, label: item.name };
			}
			return {
				...item,
				checked: false,
				label: item.name,
			};
		})
	);

	const fetchRequestsWithFilter = useCallback(() => {
		const currentDate = new Date();
		const thirtyDaysAgo = new Date();
		thirtyDaysAgo.setDate(currentDate.getDate() - 30);
		const ninetyDaysAgo = new Date();
		ninetyDaysAgo.setDate(currentDate.getDate() - 90);
		const startOfYear = new Date(currentDate.getFullYear(), 0, 1);

		switch (timeFilterOption) {
			case "last_30_days":
				dispatch(
					getAllRegularRequests({
						startDate: thirtyDaysAgo.toISOString(),
						endDate: currentDate.toISOString(),
					})
				);
				break;
			case "last_90_days":
				dispatch(
					getAllRegularRequests({
						startDate: ninetyDaysAgo.toISOString(),
						endDate: currentDate.toISOString(),
					})
				);
				break;
			case "this_year":
				dispatch(
					getAllRegularRequests({
						startDate: startOfYear.toISOString(),
						endDate: currentDate.toISOString(),
					})
				);
				break;
			case "till_today":
				dispatch(getAllRegularRequests({ endDate: currentDate.toISOString() }));
				break;
			default:
				dispatch(getAllRegularRequests({}));
				break;
		}
	}, [dispatch, timeFilterOption]);

	useEffect(() => {
		if (requestActionStatus === "fulfilled") {
			closeCreateNewShiftDialog();
			fetchRequestsWithFilter();
		}
	}, [requestActionStatus, dispatch, fetchRequestsWithFilter]);

	useEffect(() => {
		dispatch(getAllRequestsTypes());
		dispatch(fetchSkillLevels());
	}, [dispatch]);

	useEffect(() => {
		// Adding checked property to the request status
		setRequestStatus(
			allRequestStatuses.map((item: RequestStatusTypes) => {
				if (item.code === "p") {
					return { ...item, checked: true, label: item.name };
				}
				return {
					...item,
					checked: false,
					label: item.name,
				};
			})
		);
	}, [allRequestStatuses]);

	useEffect(() => {
		fetchRequestsWithFilter();
	}, [timeFilterOption, fetchRequestsWithFilter]);

	useEffect(() => {
		const filteredRequestsWithStatus = allRegularRequests.filter(
			(item: RegularShiftRequest) => {
				return requestStatus.some((status: RequestStatusTypes) => {
					return status.checked && status.code === item.status.code;
				});
			}
		);

		if (searchQuery === "") {
			setFilteredRegularRequests(filteredRequestsWithStatus);
		} else {
			const filtered = filteredRequestsWithStatus.filter(
				(item: RegularShiftRequest) => {
					const date = formatDate({
						dateStr: item.ftShift.startDateTime,
						formatType: "SHORT_DATE",
					});
					const time = formatDate({
						dateStr: item.ftShift.startDateTime,
						formatType: "TIME_RANGE",
						endDateStr: item.ftShift.endDateTime,
					});
					const startDate = item.startDate;
					const endDate = item.endDate;
					const timeOff = differenceInHours(
						new Date(endDate),
						new Date(startDate)
					);
					const requestedDate = formatDate({
						dateStr: item.requestDate,
						formatType: "NUMERIC_DATE",
					});
					const searchPattern =
						item.worker.firstName +
						item.worker.lastName +
						item.ftShift.name +
						date +
						time +
						`${timeOff} hours` +
						item.workerComment +
						requestedDate +
						item.requestType.name +
						item.leaveType?.name +
						item.status.name;

					return searchPattern
						.toLowerCase()
						.replace(/ /g, "")
						.includes(searchQuery.toLowerCase().replace(/ /g, ""));
				}
			);
			setFilteredRegularRequests(filtered);
		}
	}, [allRegularRequests, requestStatus, searchQuery]);

	return [
		{
			isCreateNewShiftDialogOpen,
			isShiftDialogOpen,
			draftShiftDate,
			searchQuery,
			isShiftCoverage,
			rows: filteredRegularRequests,
			requestStatus,
			timeFilterOption,
			shiftDetailsId,
			attendanceManagement,
		},
		{
			openCreateNewShiftDialog,
			closeCreateNewShiftDialog,
			setIsShiftDialogOpen,
			setDraftShiftDate,
			setSearchQuery,
			handleShiftCoverage,
			setRequestStatus,
			setTimeFilterOption,
			openShifDetailsDialog,
			closeShiftDetailsDialog,
		},
	];
};

export default useFullTimeRequestController;
