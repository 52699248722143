import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";

interface PageHeaderProps {
    title: string;
    subtitle: string;
}

const PageHeader: React.FC<PageHeaderProps> = ({ title, subtitle }) => {
    return (
        <Box sx={{margin: "12px", padding: "12px" }}>
            <Typography variant="h5">{title}</Typography>
            <Typography variant="subtitle1">{subtitle}</Typography>
        </Box>
    );
};

export default PageHeader;
