import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import addDays from "date-fns/addDays";
import React, { useEffect } from "react";

import {
	createArrayUpToNumber,
	getDuration,
} from "../../../../../../utils/helpers";
import { ShiftType } from "../../../../../gat-admin/store/gat-admin-slice-types";
import {
	MAXIMUM_SHIFT_PATTERN_DAYS,
	MAXIMUM_SHIFT_PATTERN_PER_LINE,
	MINIMUM_SHIFT_PATTERN_DAYS,
} from "../../../teams/constants";
import useTeamsData from "../../../teams/controllers/use-teams-data";
import { Schedule, ShiftPatternProps } from "../../types";
import ShiftPatternDayCell from "./shift-pattern-day-cell";
import ShiftScheduleMenuList from "./shift-schedule-menu-list";

const ShiftPatternDayCellGroup = (props: ShiftPatternProps) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [selectedDay, setSelectedDay] = React.useState<number>(1);
	const [selectedShiftTypeId, setSelectedShiftTypeId] = React.useState<
		number | null
	>(null);
	const [numberOfLines, setNumberOfLines] = React.useState<number>(1);
	const [shiftPatternArray, setShiftPatternArray] = React.useState<Schedule[]>(
		props.values.schedule
	);
	const [initialized, setInitialized] = React.useState<boolean>(false);
	const open = Boolean(anchorEl);

	const { allShiftSchedules } = useTeamsData();

	useEffect(() => {
		// Update cells based on number of days
		const numberOfDays =
			Number(props.values["numberOfDays"]) >= MINIMUM_SHIFT_PATTERN_DAYS
				? Number(props.values["numberOfDays"]) <= MAXIMUM_SHIFT_PATTERN_DAYS
					? Number(props.values["numberOfDays"])
					: MAXIMUM_SHIFT_PATTERN_DAYS
				: MINIMUM_SHIFT_PATTERN_DAYS;
		setNumberOfLines(Math.ceil(numberOfDays / MAXIMUM_SHIFT_PATTERN_PER_LINE));
		const newShiftPatternArray = [...props.values.schedule];
		if (!initialized) {
			setInitialized(false);
			const allDaysArray = createArrayUpToNumber(props.values.numberOfDays);
			const newFormattedSchedules: Schedule[] = [];
			allDaysArray.forEach((n: number) => {
				const foundSchedule: Schedule | undefined = props.values.schedule.find(
					(s: Schedule) => s.dayNo === n
				);
				if (foundSchedule) {
					newFormattedSchedules.push(foundSchedule);
				} else {
					newFormattedSchedules.push({
						dayNo: n,
						shiftTypeId: null,
						colorCode: "#E0E0E0",
					});
				}
			});
			setShiftPatternArray(newFormattedSchedules);
		} else if (props.values.schedule.length > numberOfDays) {
			const numberOfDaysToDelete = newShiftPatternArray.length - numberOfDays;
			newShiftPatternArray.splice(-1, numberOfDaysToDelete);
			setShiftPatternArray(newShiftPatternArray);
		} else if (numberOfDays > props.values.schedule.length) {
			let lastDayInShiftPatternArray = newShiftPatternArray.length;
			do {
				lastDayInShiftPatternArray += 1;
				const newDay = {
					dayNo: lastDayInShiftPatternArray,
					shiftTypeId: null,
					colorCode: undefined,
				};
				newShiftPatternArray.push(newDay);
			} while (lastDayInShiftPatternArray < numberOfDays);
			setShiftPatternArray(newShiftPatternArray);
		}
	}, [props, initialized]);

	useEffect(() => {
		if (
			JSON.stringify(props.values.schedule) !==
			JSON.stringify(shiftPatternArray)
		) {
			props.setFieldValue("schedule", shiftPatternArray);
		}
	}, [props, shiftPatternArray]);

	const handleClose = () => {
		setAnchorEl(null);
	};

	const getTotalShiftScheduleHours = () => {
		let returnValue = "0 Hours";
		let totalHours = 0;
		try {
			const copyOfSchedule = [...props.values.schedule];
			const allActiveShiftSchedules = copyOfSchedule.filter(
				(s: Schedule) => s.shiftTypeId !== null
			);
			allActiveShiftSchedules.forEach((activeSchedule: Schedule) => {
				const fullShiftDetail = allShiftSchedules.find(
					(shiftSchedule: ShiftType) =>
						shiftSchedule.id === activeSchedule.shiftTypeId
				);
				if (fullShiftDetail) {
					totalHours += Number(
						getDuration(
							fullShiftDetail.startTime,
							fullShiftDetail.endTime,
							fullShiftDetail.unpaidBreakMins
						)
					);
				}
			});
			const res = Math.round(totalHours * 100) / 100;
			returnValue = `${res} Hours`;
			return returnValue;
		} catch (e) {
			// Do nothing
			return returnValue;
		}
	};
	const updateShiftTypeIdFByDayNo = (
		dayNoToUpdate: number,
		newShiftTypeId: null | number,
		colorCode: undefined | string
	): Schedule[] => {
		const indexToUpdate = shiftPatternArray.findIndex(
			(obj) => obj.dayNo === dayNoToUpdate
		);
		if (indexToUpdate !== -1) {
			// Update existing object
			return shiftPatternArray.map((obj, index) =>
				index === indexToUpdate
					? { ...obj, shiftTypeId: newShiftTypeId, colorCode: colorCode }
					: obj
			);
		} else {
			// Create new object
			return [
				...shiftPatternArray,
				{
					dayNo: dayNoToUpdate,
					shiftTypeId: newShiftTypeId,
					colorCode: colorCode,
				},
			];
		}
	};

	const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
		setAnchorEl(event.currentTarget);
	};

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				alignItems: "flex-end",
				justifyContent: "center",
				width: "850px",
				flex: 1,
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					width: "748px",
					flex: 1,
				}}
			>
				{createArrayUpToNumber(numberOfLines).map((lineNumber) => {
					return (
						<Box
							key={`shift-pattern-group-${lineNumber}`}
							sx={{
								display: "flex",
								flexDirection: "column",
								height: "79px",
								width: "748px",
								marginBottom: "16px",
							}}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									height: "23px",
									alignItems: "center",
									width: "748px",
								}}
							>
								{shiftPatternArray.map((value, index) => {
									if (
										index >=
											MAXIMUM_SHIFT_PATTERN_PER_LINE * (lineNumber - 1) &&
										index < MAXIMUM_SHIFT_PATTERN_PER_LINE * lineNumber
									) {
										if (props.values.startDate) {
											let endDay =
												value.dayNo + (shiftPatternArray.length - index - 1);
											endDay =
												endDay > MAXIMUM_SHIFT_PATTERN_PER_LINE * lineNumber
													? MAXIMUM_SHIFT_PATTERN_PER_LINE * lineNumber
													: endDay;
											endDay =
												shiftPatternArray.length < endDay
													? shiftPatternArray.length
													: endDay;
											const daysHeaderTextValue = `Day ${value.dayNo} - ${endDay}`;

											if (
												index ===
												MAXIMUM_SHIFT_PATTERN_PER_LINE * (lineNumber - 1)
											) {
												return (
													<Box
														key={`days-header-${index}`}
														sx={{
															flex: 1,
															display: "flex",
															justifyContent: "center",
															alignItems: "center",
														}}
													>
														<Typography
															sx={{
																fontSize: "12px",
																color: "rgba(0,0,0,0.38)",
																lineHeight: "20px",
																fontFamily: "Roboto",
																fontWeight: "400",
															}}
															noWrap
														>
															{daysHeaderTextValue}
														</Typography>
													</Box>
												);
											} else {
												return null;
											}
										} else {
											return null;
										}
									} else {
										return null;
									}
								})}
							</Box>
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									height: "56px",
									width: "748px",
								}}
							>
								{shiftPatternArray.map((value, index) => {
									if (
										index >=
											MAXIMUM_SHIFT_PATTERN_PER_LINE * (lineNumber - 1) &&
										index < MAXIMUM_SHIFT_PATTERN_PER_LINE * lineNumber
									) {
										return (
											<ShiftPatternDayCell
												key={`shift-pattern-day-cell-${value.dayNo}`}
												dayNo={value.dayNo}
												colorCode={value.colorCode}
												date={
													props.values.startDate
														? new Date(
																addDays(props.values.startDate, value.dayNo - 1)
																// eslint-disable-next-line no-mixed-spaces-and-tabs
														  )
														: undefined
												}
												handleClick={(e) => {
													if (!props.values.isWorkerAssigned) {
														setSelectedShiftTypeId(value.shiftTypeId);
														setSelectedDay(value.dayNo);
														handleClick(e);
													}
												}}
												disabled={props.values.isWorkerAssigned}
											/>
										);
									} else {
										return null;
									}
								})}
							</Box>
						</Box>
					);
				})}
				<ShiftScheduleMenuList
					allShiftSchedules={allShiftSchedules}
					selectedShiftTypeId={selectedShiftTypeId}
					anchorEl={anchorEl}
					handleClose={handleClose}
					handleMenuItemClick={(id, colorCode) => {
						setShiftPatternArray(
							updateShiftTypeIdFByDayNo(selectedDay, id, colorCode)
						);
					}}
					open={open}
				/>
			</Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					width: "80px",
					height: "100%",
					alignItems: "flex-start",
					justifyContent: "flex-end",
					marginBottom: "14px",
				}}
			>
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontSize: "12px",
						fontWeight: "400",
						lineHeight: "20px",
						letterSpacing: "0.4px",
					}}
				>
					Total
				</Typography>
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontSize: "14px",
						fontWeight: "400",
						lineHeight: "20px",
						color: "rgba(0, 0, 0, 0.87)",
						letterSpacing: "0.17px",
					}}
				>
					{getTotalShiftScheduleHours()}
				</Typography>
			</Box>
		</Box>
	);
};

export default ShiftPatternDayCellGroup;
