export let lastUserActivity = Date.now();

const resetUserActivityTimeout = () => {
    lastUserActivity = Date.now();
};

export const startUserActivityListener = () => {
    window.addEventListener("mousemove", resetUserActivityTimeout);
    window.addEventListener("keydown", resetUserActivityTimeout);
    window.addEventListener("click", resetUserActivityTimeout);
};

export const stopUserActivityListener = () => {
    window.removeEventListener("mousemove", resetUserActivityTimeout);
    window.removeEventListener("keydown", resetUserActivityTimeout);
    window.removeEventListener("click", resetUserActivityTimeout);
};