import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import React from "react";

import Button from "../../../../../design/button";
import Searchbar from "../../../../../design/search-bar";

interface SupervisorHeaderProps {
	openDialog: () => void;
	searchText: string;
	setSearch: (search: string) => void;
}

const SupervisorHeader: React.FC<SupervisorHeaderProps> = ({ openDialog, searchText, setSearch }) => {
	return (
		<>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					flexDirection: "row",
					alignItems: "center",
					height: "75px",
				}}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Searchbar
						variant={"filled"}
						width={"35ch"}
						setSearch={setSearch}
						value={searchText}
					/>
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Box ml={2} mr={5}>
						<Button iconBefore={<AddIcon />} onClick={openDialog}>
							User
						</Button>
					</Box>
				</Box>
			</Box>
		</>
	);
};

export default SupervisorHeader;
