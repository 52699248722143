import { useSelector } from "react-redux";
// import { RootState } from "../../../store/rootReducer";

const useShiftScheduleRulesData = () => {
	const getAllShiftTypesStatus = useSelector(
		(state: any) => state.gatAdminConfiguration.getAllShiftTypes.status
	);

	const getAllShiftTypesData = useSelector(
		(state: any) => state.gatAdminConfiguration.getAllShiftTypes.data
	);

	const getAllShiftTypesError = useSelector(
		(state: any) => state.gatAdminConfiguration.getAllShiftTypes.errorMessage
	);

	const createNewScheduleStatus = useSelector(
		(state: any) => state.gatAdminConfiguration.createNewSchedule.status
	);

	const createNewScheduleError = useSelector(
		(state: any) => state.gatAdminConfiguration.createNewSchedule.errorMessage
	);

	const editShiftSchedule = useSelector(
		(state: any) => state.gatAdminConfiguration.editShiftSchedule
	);

	const editShiftScheduleError = useSelector(
		(state: any) => state.gatAdminConfiguration.editShiftSchedule.errorMessage
	);

	const deleteShiftScheduleStatus = useSelector(
		(state: any) => state.gatAdminConfiguration.deleteShiftSchedule.status
	);

	const deleteShiftScheduleError = useSelector(
		(state: any) => state.gatAdminConfiguration.deleteShiftSchedule.errorMessage
	);

	const newLocationId = useSelector(
		(state: any) => state.gatAdminConfiguration.createNewLocation.locationId
	);

	return {
		getAllShiftTypesStatus,
		editShiftScheduleError,
		getAllShiftTypesData,
		getAllShiftTypesError,
		createNewScheduleStatus,
		createNewScheduleError,
		editShiftSchedule,
		deleteShiftScheduleStatus,
		deleteShiftScheduleError,
		newLocationId,
	};
};

export default useShiftScheduleRulesData;
