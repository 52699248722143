// TODO - Type 'any' needs to be fixed.
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {WorkerCert} from "../../../../../../types"


interface FormikInitialValuesProps {
    levelId: any;
    skillId: any;
    validFrom: any;
    validTo: any;
    skillName: string;
}

const useEditSkillDialogController = () => {
	const initialValues = useSelector(
		(state: any) => state.manageWorkers.editWorkerSkillDialog.skillData
	);
	const workerCerts = useSelector(
		(state: any) => state.manageWorkers.workerProfile.profile.workerCerts
	);
	const [formikInitialValues, setFormikInitialValues] = useState<FormikInitialValuesProps>({
		levelId: null,
		skillId: null,
		validFrom: null,
		validTo: null,
        skillName: "",
	});
	const [requiredCertifications, setRequiredCertifications] = useState<WorkerCert[]>([]);
	
	const getCertificationsBySkillId = (data: WorkerCert[], skillId: number): WorkerCert[] => {
		const result: WorkerCert[] = [];
		
		try {
			if (!data) return result;
			
			data.forEach(workerCert => {
				try {
					if (!workerCert || !workerCert.skillIds) return;
					
					const skillIds = workerCert.skillIds.split(",").map(id => parseInt(id.trim(), 10));
					
					if (skillIds.includes(skillId)) {
						result.push(workerCert);
					}
				} catch (innerError) {
					console.log("Error processing workerCert:", innerError);
				}
			});
		} catch (error) {
			console.log("Error processing data:", error);
		}
		
		return result;
	};
	

	useEffect(() => {
		if(initialValues){
			setFormikInitialValues(initialValues);
			if(workerCerts) setRequiredCertifications(getCertificationsBySkillId(workerCerts, initialValues.skillId));
		}
	}, [initialValues, workerCerts]);
	return {
		formikInitialValues,
		requiredCertifications,
	};
};

export default useEditSkillDialogController;
