// TODO - Type 'any' needs to be fixed.
export function jobReducer(state:any, action:any) {
	const ob:any = {};
	switch (action.type) {
		case "toggleCheckbox":
			return {
				...state,
				[action.payload]: !state[action.payload],
			};
		case "toggleAllCheckbox":
			if (action.payload.checked) {
				action.payload.jobRoles.forEach((job:any) => (ob[job.id] = true));
			} else {
				action.payload.jobRoles.forEach((job:any) => (ob[job.id] = false));
			}
			return {
				...(state = ob),
			};
		case "setInit":
			return {
				...action.payload,
			};
		default:
			return state;
	}
}

export function skillReducer(state:any, action:any) {
	const ob:any = {};
	switch (action.type) {
		case "toggleCheckbox":
			return {
				...state,
				[action.payload]: !state[action.payload],
			};
		case "toggleAllCheckbox":
			if (action.payload.checked) {
				action.payload.allSkills.forEach((skill:any) => (ob[skill.id] = true));
			} else {
				action.payload.allSkills.forEach((skill:any) => (ob[skill.id] = false));
			}
			return {
				...(state = ob),
			};
		case "setInit":
			return {
				...action.payload,
			};
		default:
			return state;
	}
}
