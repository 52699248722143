import { useSelector } from "react-redux";

interface UserData {
  company: string;
  email: string;
  firstName: string;
  lastName: string;
}

// TODO: Root State work is pending in redux
const useUserData = (): UserData => {
  const company = useSelector((state: any) => state.user.userData.company);
  const email = useSelector((state: any) => state.user.userData.username);
  const firstName = useSelector((state: any) => state.user.userData.firstName);
  const lastName = useSelector((state: any) => state.user.userData.lastName);

  return {
    company,
    email,
    firstName,
    lastName,
  };
};

export default useUserData;
