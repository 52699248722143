import { Box, Switch, Typography } from "@mui/material";
import { useState } from "react";
import React from "react";

import SectionWrapper from "../../../section-wrapper";
import SectionActions from "../../section-actions";
import useWorkerAppController from "./controllers/use-worker-app-controller";

const WorkerAppSettings = () => {
    const [enableWorkerApp, setEnableWorkerApp] = useState(true);

    const [
        { workerAppSettings, newWorkerAppSettings, loading },
        { setNewWorkerAppSettings, handleSave },
    ]: any = useWorkerAppController();

    return (
        <SectionWrapper
            title='Worker app settings'
            subtitle='Worker shift related settings at company level.'
        >
            <Box
                sx={{
                    backgroundColor: "#F5F5F5",
                    borderRadius: "8px",
                    marginTop: "32px",
                    marginBottom: "16px",
                    padding: "16px",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: "Roboto",
                                fontWeight: 400,
                                fontSize: "16px",
                                color: "rgba(0, 0, 0, 0.87)",
                            }}
                        >
                            Worker - mobile app enabled
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: "Roboto",
                                fontWeight: 400,
                                fontSize: "12px",
                                color: "rgba(0, 0, 0, 0.6)",
                            }}
                        >
                            Workers can login to the mobile app.
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Switch
                            disabled
                            checked
                            onChange={(e) => setEnableWorkerApp((pre) => !pre)}
                        />
                    </Box>
                </Box>
                {enableWorkerApp ? (
                    <>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginTop: "20px",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: "Roboto",
                                        fontWeight: 400,
                                        fontSize: "16px",
                                        color: "rgba(0, 0, 0, 0.87)",
                                    }}
                                >
                                    Workers can set their availability
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: "Roboto",
                                        fontWeight: 400,
                                        fontSize: "12px",
                                        color: "rgba(0, 0, 0, 0.6)",
                                    }}
                                >
                                    Supervisors can view this availability heat map.
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Switch
                                    checked={
                                        newWorkerAppSettings?.workerAvailabilityEnabled ?? false
                                    }
                                    onChange={(e) =>
                                        setNewWorkerAppSettings((pre: any) => ({
                                            ...pre,
                                            workerAvailabilityEnabled: e.target.checked,
                                        }))
                                    }
                                />
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginTop: "20px",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: "Roboto",
                                        fontWeight: 400,
                                        fontSize: "16px",
                                        color: "rgba(0, 0, 0, 0.87)",
                                    }}
                                >
                                    Workers can auto-accept available shifts
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: "Roboto",
                                        fontWeight: 400,
                                        fontSize: "12px",
                                        color: "rgba(0, 0, 0, 0.6)",
                                    }}
                                >
                                    Once enabled auto-accept card would be visible to workers.
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Switch
                                    checked={newWorkerAppSettings?.autoAcceptEnabled ?? false}
                                    onChange={(e) =>
                                        setNewWorkerAppSettings((pre: any) => ({
                                            ...pre,
                                            autoAcceptEnabled: e.target.checked,
                                        }))
                                    }
                                />
                            </Box>
                        </Box>
                    </>
                ) : null}
            </Box>
            <SectionActions
                oldData={workerAppSettings}
                newData={newWorkerAppSettings}
                resetChanges={() => setNewWorkerAppSettings(workerAppSettings)}
                handleSave={handleSave}
                loading={loading}
            />
        </SectionWrapper>
    );
};

export default WorkerAppSettings;
