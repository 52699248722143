import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, CircularProgress, Divider, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import React from "react";

const ShiftAllocationRulesEmpty = () => {
	return (
		<Box
			sx={{
				backgroundColor: "#F5F5F5",
				borderRadius: "8px",
				padding: "16px",
			}}
		>
			<Box>
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontWeight: 400,
						fontSize: "16px",
						color: "rgba(0, 0, 0, 0.87)",
					}}
				>
					Shift Allocation Rules
				</Typography>
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontWeight: 400,
						fontSize: "14px",
						color: "rgba(0, 0, 0, 0.6)",
					}}
				>
					Configuration around how shifts are allocated to the workers for this
					location
				</Typography>
			</Box>
			<Box mt={2}>
				<Box display='flex'>
					<Box width={250}>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 400,
								fontSize: "16px",
								color: "rgba(0, 0, 0, 0.87)",
							}}
						>
							Rule
						</Typography>
					</Box>
					<Box width={110} display='flex' alignItems='center'>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 400,
								fontSize: "16px",
								color: "rgba(0, 0, 0, 0.87)",
							}}
						>
							Default
						</Typography>
						<Tooltip title='Shifts created by supervisor will follow this allocation rule.'>
							<InfoOutlinedIcon sx={{ marginLeft: "8px", fontSize: "18px" }} />
						</Tooltip>
					</Box>
					<Box width={640}>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 400,
								fontSize: "16px",
								color: "rgba(0, 0, 0, 0.87)",
							}}
						>
							Associated job roles
						</Typography>
					</Box>
					<Box width={110}>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 400,
								fontSize: "16px",
								color: "rgba(0, 0, 0, 0.87)",
							}}
						>
							Configuration
						</Typography>
					</Box>
				</Box>
				<Divider />
				<Box>
					<Box
						height={30}
						display='flex'
						justifyContent='center'
						alignItems='center'
						mt={2}
					>
						<CircularProgress />
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default ShiftAllocationRulesEmpty;
