import { Box, Grid, Input, Switch, TextField, Typography } from "@mui/material";
import { set } from "lodash";
import { useState } from "react";
import React from "react";

// import Divider from "@mui/material/Divider";
// import JobCustomiseTable from "./job-customise-table";
import JobDialog from "./job-dialog";

const JobExpiration = ({
	companyData,
	setCompanyData,
	newShiftSettings,
	setNewShiftSettings,
}: any) => {
	const [jobDialog, setJobDialog] = useState(false);
	
	return (
		<Box
			sx={{
				backgroundColor: "#F5F5F5",
				borderRadius: "8px",
				marginTop: "32px",
				padding: "16px",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 400,
							fontSize: "16px",
							color: "rgba(0, 0, 0, 0.87)",
						}}
					>
						Job Expiration
					</Typography>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 400,
							fontSize: "12px",
							color: "rgba(0, 0, 0, 0.6)",
						}}
					>
						Set rules for a job eligibility of worker to get expired.
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Switch
						checked={newShiftSettings.jobExpiryEnabled ?? false}
						onChange={(e) => {
							setNewShiftSettings((prevSettings: any) => ({
								...prevSettings,
								jobExpiryEnabled: e.target.checked,
							}));
						}}
					/>
				</Box>
			</Box>

			{newShiftSettings.jobExpiryEnabled ? (
				<Box
					sx={{
						marginTop: "32px",
					}}
				>
					<Grid container>
						<Grid
							item
							xs={12}
							sx={{
								display: "flex",
								flexDirection: "row",
							}}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
								}}
							>
								<Input
									id='number-of-hours-work-required'
									// variant='filled'
									type='number'
									onFocus={(event) => {
										event.target.select();
									}}
									sx={{
										"& .MuiInputBase-input": {
											background: "#FFF",
											padding: "8px",
										},
										width: "60px",
									}}
									onChange={(e) => {
										setNewShiftSettings((prevSettings: any) => ({
											...prevSettings,
											minWorkHoursRequired: parseInt(e.target.value),
										}));
									}}
									value={newShiftSettings.minWorkHoursRequired ?? "8"}
								/>
								<Typography
									sx={{
										fontFamily: "Roboto",
										fontWeight: 400,
										fontSize: "16px",
										color: "rgba(0, 0, 0, 0.87)",
										marginLeft: "8px",
									}}
								>
									no. of hours of work required for a worker in last
								</Typography>
							</Box>
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									marginLeft: "8px",
								}}
							>
								<TextField
									id='days-to-accept-shift'
									variant='filled'
									type='number'
									onFocus={(event) => {
										event.target.select();
									}}
									sx={{
										"& .MuiInputBase-input": {
											background: "#FFF",
											padding: "8px",
										},
										width: "60px",
									}}
									onChange={(e) => {
										setNewShiftSettings((prevSettings: any) => ({
											...prevSettings,
											minWorkHoursDays: parseInt(e.target.value),
										}));
									}}
									value={newShiftSettings.minWorkHoursDays ?? "60"}
								/>
								<Typography
									sx={{
										fontFamily: "Roboto",
										fontWeight: 400,
										fontSize: "16px",
										color: "rgba(0, 0, 0, 0.87)",
										marginLeft: "8px",
									}}
								>
									days to accept a shift
								</Typography>
							</Box>
						</Grid>
					</Grid>
					<Typography
						sx={{
							color: "rgba(0, 0, 0, 0.60)",
							fontFamily: "Roboto",
							fontSize: "14px",
							fontWeight: 400,
							marginTop: "32px",
						}}
					>
						^ above values are global and would be applied for all the jobs.
						Customisation will override the global value if applied.
					</Typography>

					{/*TODO: Once Job level expiration API is ready, integrate this */}
					{/*<Divider sx={{ marginTop: "16px" }} />*/}
					{/*<JobCustomiseTable*/}
					{/*	setJobDialog={setJobDialog}*/}
					{/*	setCompanyData={setCompanyData}*/}
					{/*	companyData={companyData}*/}
					{/*/>*/}
					{/* Commenting out above code till API availability */}
					<JobDialog
						open={jobDialog}
						setJobDialog={setJobDialog}
						setCompanyData={setCompanyData}
						companyData={companyData}
					/>
				</Box>
			) : null}
		</Box>
	);
};

export default JobExpiration;
