// TODO - Type 'any' needs to be fixed.
import { useSelector } from "react-redux";

import {colors} from "../../../../export/attendance-reports/index";

const useDayReportData = () => {
	const markAttendanceStatus = useSelector(
		(state:any) => state.supervisor?.markAttendance.status
	);

	const markAttendanceError = useSelector(
		(state:any) => state.supervisor?.markAttendance.errorMessage
	);

	const dayReportStatus = useSelector(
		(state:any) => state.report.getDayReport.status
	);

	const dayReportError = useSelector(
		(state:any) => state.report.getDayReport.error
	);

	const dayReport = useSelector((state:any) => state.report.dayReport);

	const currentDate = useSelector((state:any) => state.report.currentDate);

	const allWorkers = useSelector((state:any) => state.report.allWorkers);

	const fetchAllOrganizationsStatus = useSelector(
		(state:any) => state.manageWorkers.fetchAllOrganizations.status
	);

	const allOrganizations = useSelector(
		(state:any) => state.manageWorkers.allOrganizations
	);

	return {
		markAttendanceStatus,
		dayReportStatus,
		dayReportError,
		dayReport,
		colors,
		currentDate,
		markAttendanceError,
		allWorkers,
		allOrganizations,
		fetchAllOrganizationsStatus,
	};
};

export default useDayReportData;
