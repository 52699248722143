// TODO - Type 'any' needs to be fixed.
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
//import TableCell, { tableCellClasses } from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { getShiftAttendanceDetails } from "../../../components/scheduler/store/scheduler-slice";
import ShiftMetaData from "../shift-details/shift-metaData";
//import Chip from '@mui/material/Chip';
// import ShiftMetaData from "../ShiftDetails/ShiftMetaData";
// import {
// 	getShiftAttendanceDetails,
// 	//  markAttendance
// } from "../ShiftSchedulerSlice";
import ShiftStatusTable from "./ShiftStatusTable";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
	},
}));
const BootstrapDialogTitle = (props:any) => {
	const { children, onClose, ...other } = props;

	return (
		<DialogTitle sx={{ m: 0, p: 2 }} {...other}>
			{children}
		</DialogTitle>
	);
};
BootstrapDialogTitle.propTypes = {
	children: PropTypes.node,
	onClose: PropTypes.func.isRequired,
};

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   [`&.${tableCellClasses.head}`]: {
//     backgroundColor: theme.palette.common.white,
//     color: theme.palette.common.white,
//   },
//   [`&.${tableCellClasses.body}`]: {
//     fontSize: 14,
//   },
// }));

export default function WorkersAttendanceDialog({ open, onClose, shiftId }:any) {
	const dispatch = useDispatch();
	const [rows, setRows] = useState([]);
	const [shiftMetadata, setShiftMetadata] = useState({});

	const allShiftsMetadata = useSelector(
		(state:any) => state.supervisor?.schedulerData?.shiftsData
	);

	const shiftData = useSelector(
		(state:any) => state.supervisor?.shiftDetails?.data?.[shiftId]
	);

	const shiftAttendanceData = useSelector(
		(state:any) => state.supervisor?.shiftAttendanceDetails?.data?.[shiftId]
	);

	const status = useSelector(
		(state:any) => state.supervisor?.shiftAttendanceDetails?.status
	);

	const markAttendanceStatus = useSelector(
		(state:any) => state.supervisor?.markAttendance.status
	);

	const markAttendanceError = useSelector(
		(state:any) => state.supervisor?.markAttendance.errorMessage
	);

	useEffect(() => {
		// fetch the data
		if (shiftId) {
			dispatch(getShiftAttendanceDetails({ shiftid: shiftId }));
		}
	}, [dispatch, shiftId]);

	useEffect(() => {
		if (markAttendanceStatus === "fulfilled") {
			dispatch(getShiftAttendanceDetails({ shiftid: shiftId }));
		}
	}, [dispatch, markAttendanceStatus, shiftId]);

	useEffect(() => {
		if (shiftData && shiftAttendanceData) {
			const rowData :any= [];

			const { accepted } = shiftData;
			accepted.forEach((worker:any) => {
				const workerData:any = shiftAttendanceData.find(
					(w:any) => w.workerId === worker.workerId
				);
				rowData.push({
					id: worker.workerId,
					workerName: worker.workerName,
					status: workerData?.status,
					comment: workerData?.comment,
					shiftId: shiftId,
				});
			});
			setRows(rowData);
		}
	}, [shiftData, shiftId, shiftAttendanceData]);

	useEffect(() => {
		// getting shift details from shiftId
		if (allShiftsMetadata) {
			const currentShiftData = allShiftsMetadata.filter(
				(shift:any) => shift.id === shiftId
			);
			setShiftMetadata(currentShiftData[0]);
		}
	}, [allShiftsMetadata, shiftId]);

	return (
		<BootstrapDialog
			onClose={() => onClose(false)}
			aria-labelledby='customized-dialog-title'
			open={open}
			// maxWidth={900}
		>
			<BootstrapDialogTitle
				// id='customized-dialog-title'
				onClose={() => onClose(false)}
			>
				<ShiftMetaData shiftMetadata={shiftMetadata} handleClose={onClose} />
			</BootstrapDialogTitle>
			{status === "pending" ? (
				<DialogContent dividers>
					<Box
						minWidth={600}
						height={500}
						display='flex'
						alignItems='center'
						justifyContent='center'
						mr='auto'
						ml='auto'
						flexDirection='column'
					>
						<CircularProgress />
						<Typography>Loading Shift Details</Typography>
					</Box>
				</DialogContent>
			) : (
				<>
					<DialogContent dividers>
						<ShiftStatusTable rows={rows} />
						{/* <TableContainer component={Paper}>
        <Table
            height={100}
           sx={{ minWidth: 600 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell align='left'>Worker</TableCell>
              <TableCell align="right" style={{transform: 'translateX(-15px)'}}>Status</TableCell>
              <TableCell align="right" style={{transform: 'translateX(-18px)'}}>Change Status</TableCell>
            </TableRow>
          </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <StyledTableCell component="th" scope="row">
                {console.log()}
                {row.workerName}
              </StyledTableCell>
              <StyledTableCell align="right">
                {!row.status?
                  <Chip label="Present" style={{marginLeft: '10px', backgroundColor:`green`,color:"white"}} />
                  :
                  <Chip label="Absent" style={{backgroundColor:`#eb4f34`,color:"white"}} />
                }
              </StyledTableCell>
              <StyledTableCell align="right">
                <Button  
                  variant="outlined" 
                  color={!row.status?"error":"success"} 
                  disabled={markAttendanceStatus === "pending" ||
                  markAttendanceStatus === "error"}
                  onClick={()=>dispatch(markAttendance({workerId:row.id,status:!row.status,shiftId:shiftId}))}
                >
                    { 
                      !row.status?"Mark Absent":"Mark Present"
                    }
                </Button>
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer> */}
					</DialogContent>
					<DialogActions>
						{markAttendanceStatus === "error" ? (
							<Typography variant='body2' color='error'>
								{markAttendanceError}
							</Typography>
						) : null}
						<Button autoFocus onClick={() => onClose(false)}>
							Close
						</Button>
					</DialogActions>
				</>
			)}
		</BootstrapDialog>
	);
}
