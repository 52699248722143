import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { AdapterDateFns } from "@mui/x-date-pickers-6/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers-pro-6";
import { DesktopDateRangePicker } from "@mui/x-date-pickers-pro-6/DesktopDateRangePicker";
import { endOfDay, parseISO, startOfDay } from "date-fns";
import React from "react";
const DateRangePicker = ({ value, setValue, variant, size }:any) => {
	return (
		<Box>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<DesktopDateRangePicker
					value={[parseISO(value[0]), parseISO(value[1])]}
					onChange={(newValue) => {
						if (newValue[0] === null || newValue[1] === null) {
							return;
						}
						setValue([startOfDay(newValue[0]).toISOString(), endOfDay(newValue[1]).toISOString()]);
					}}
					slotProps={{
						textField: {
							variant: variant,
							size: size,
							InputProps: {
								endAdornment: (
									<IconButton>
										<CalendarMonthIcon />
									</IconButton>
								),
							},
						},
					}}
				/>
			</LocalizationProvider>
		</Box>
	);
};

export default DateRangePicker;
