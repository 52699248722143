import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, Tooltip, Typography } from "@mui/material";
import React, {useEffect, useState} from "react";

import { SkillRepresentationCell } from "../../../../../../export/cross-skilling";
import { getRepLevel, getRepType } from "../../../../../utils/helpers";
import { SkillLevel } from "../../../types";
interface CrossSkillLegendsProps {
  allSkillLevels: SkillLevel[];
  isSkillLevelShownInTableHeader?: boolean;
  isWide?: boolean;
  notAssignedSkillLevel?: SkillLevel;
}

const CrossSkillLegends: React.FC<CrossSkillLegendsProps> = ({
  allSkillLevels,
  isSkillLevelShownInTableHeader = false,
  isWide = false,
  notAssignedSkillLevel = undefined,
}) => {
  const [crossSkillLedendsExpanded, setCrossSkillLegendsExpanded] =
    useState(false);
  const [skillLevelsForLegend, setSkillLevelsForLegend] = useState<SkillLevel[]>([]);

    useEffect(() => {
        try {
            // When we have not assigned skill level, we need to show it first in the legend
            if (notAssignedSkillLevel) {
                setSkillLevelsForLegend([notAssignedSkillLevel, ...allSkillLevels]);
            } else {
                setSkillLevelsForLegend(allSkillLevels);
            }
        } catch (e) {
            // Do nothing
        }

    }, [allSkillLevels, notAssignedSkillLevel]);

  if (Array.isArray(skillLevelsForLegend)) {
    if (isWide) {
      return (
        <>
          {crossSkillLedendsExpanded && (
            <Box
              sx={{
                width: "100vw",
                position: "fixed",
                top: "0",
                left: "0",
                height: "100vh",
                zIndex: 999,
                backgroundColor: "rgba(0,0,0,0)",
              }}
              onClick={() => {
                setCrossSkillLegendsExpanded(false);
              }}
            ></Box>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
              justifyContent: "flex-start",
              flexWrap: "wrap",
            }}
          >
            <Box
              onClick={() =>
                setCrossSkillLegendsExpanded(!crossSkillLedendsExpanded)
              }
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: "4px",
                backgroundColor: "#FCFCFC",
                padding: "4px",
                minWidth: "102px",
                minHeight: "32px",
                cursor: "pointer",
              }}
            >
              <Typography
                sx={{
                  color: "rgba(0,0,0,0.6)",
                  fontFamily: "Roboto",
                  fontSize: "13px",
                  fontWeight: "400",
                  lineHeight: "18px",
                  letterSpacing: "0.16px",
                  paddingLeft: "4px",
                }}
              >
                Skill levels
              </Typography>
              <KeyboardArrowDownIcon
                sx={{
                  color: "rgba(0,0,0,0.6)",
                  fontSize: "24px",
                }}
              />
            </Box>
            <Box
              sx={{
                display: crossSkillLedendsExpanded ? "block" : "none",
                position: "absolute",
                top: "32px",
                right: "0px",
                padding: "8px",
                overflow: "hidden",
                zIndex: 1000,
                backgroundColor: "white",
                borderRadius: "4px !important",
                transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                // inset: "0px auto auto 0px",
                boxShadow:
                  "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px !important",
              }}
            >
              <Box
                pl={1}
                pr={1}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                }}
              >
                {skillLevelsForLegend.map((skillLevel) => (
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    pt={0.8}
                    pb={0.8}
                    key={skillLevel.id}
                  >
                    <SkillRepresentationCell
                      level={skillLevel.level}
                      backgroundColor={skillLevel.backColorCode}
                      foregroundColor={skillLevel.foreColorCode}
                      value={String(skillLevel.value)}
                      representationType={getRepType(
                        String(skillLevel.repCode)
                      )}
                      representationLevel={getRepLevel(
                        skillLevel.repCode,
                        skillLevel.level
                      )}
                      border={1}
                      backgroundWidth={"35px"}
                      backgroundHeight={"35px"}
                    />
                    <Tooltip title={skillLevel.name}>
                      <Typography
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          lineHeight: "24px",
                          letterSpacing: "0.15px",
                          color: "rgba(0,0,0,0.6)",
                          fontWeight: 400,
                        }}
                        ml={2}
                        noWrap
                      >
                        {skillLevel.name}
                      </Typography>
                    </Tooltip>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </>
      );
    } else {
      return (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          flexWrap="wrap"
          sx={{
            minHeight: "50px",
          }}
        >
          {skillLevelsForLegend.map((skillLevel) => (
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              p={0.8}
              pl={isSkillLevelShownInTableHeader ? 0 : 1}
              pr={isSkillLevelShownInTableHeader ? 0 : 1}
              key={skillLevel.id}
            >
              <SkillRepresentationCell
                level={skillLevel.level}
                backgroundColor={skillLevel.backColorCode}
                foregroundColor={skillLevel.foreColorCode}
                value={String(skillLevel.value)}
                representationType={getRepType(skillLevel.repCode)}
                representationLevel={getRepLevel(
                  skillLevel.repCode,
                  skillLevel.level
                )}
                border={1}
                backgroundWidth={"35px"}
                backgroundHeight={"35px"}
              />
              <Tooltip title={skillLevel.name}>
                <Typography
                  sx={{
                    fontFamily: !isSkillLevelShownInTableHeader ? "Roboto" : "",
                    fontSize: isSkillLevelShownInTableHeader ? "14px" : "12px",
                    color: !isSkillLevelShownInTableHeader
                      ? "rgba(0,0,0,0.87)"
                      : "",
                    fontWeight: isSkillLevelShownInTableHeader ? "bold" : "400",
                  }}
                  ml={1}
                  noWrap
                >
                  {skillLevel.name}
                </Typography>
              </Tooltip>
            </Box>
          ))}
        </Box>
      );
    }
  } else {
    return <></>;
  }
};

export default CrossSkillLegends;
