import { Box, Grid, Typography } from "@mui/material";
import format from "date-fns/format";
import React from "react";

import Highlight from "../../../../Highlight";
import {
	BORDER_COLOR,
	EMPTY_SHIFT_CELL_COLOR,
	TODAY_HIGHLIGHT_COLOR,
	WEEKEND_SHIFT_CELL_COLOR,
} from "../../../constants/colors";
import useScheduleController from "../../../controllers/schedule-controller";

export const Row = ({ jobName, datesArrayOfSelectedWeek, scheduledShifts, searchValue }) => {
	const [, { isWeekend, isToday, getShiftsByDate }] = useScheduleController();
	const DayCell = ({
		scheduledWeeklyDraftShifts,
		backgroundColor = "rgba(255,255,255,0.5)",
		selectedDate,
	}) => {
		let cellItems = [];
		if (
			scheduledWeeklyDraftShifts !== undefined &&
			scheduledWeeklyDraftShifts.length > 0
		) {
			scheduledWeeklyDraftShifts.forEach((shift, index) => {
				const startTime = format(new Date(shift.startDateTime), "H:mm");
				const endTime = format(new Date(shift.endDateTime), "H:mm");
				const rangeString = `${startTime} - ${endTime}`;
				const numberOfWorkersNeeded = shift.numberOfWorkersNeeded;
				const cellItem = (
					<Box
						borderTop={index !== 0 ? 1 : 0}
						borderColor={BORDER_COLOR}
						key={`scheduled-shift-${shift.id}`}
						sx={{
							display: "flex",
							alignItems: "center",
							padding: "4px 0px 4px 0px",
						}}
					>
						<Box
							sx={{
								flex: 1,
								borderRadius: "4px",
								border: "1px dashed #9E9E9E",
								backgroundColor: "#F5F5F5",
								padding: "4px",
							}}
						>
							{/* Shift time */}
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
								}}
							>
								<Typography
									sx={{
										fontSize: "12px",
										fontWeight: 500,
										fontFamily: "Roboto",
										color: "#000000",
										marginLeft: "4px",
										lineHeight: "16px",
										whiteSpace: "nowrap",
									}}
								>
									{rangeString}
								</Typography>
							</Box>
							{/* Additional workers required */}
							<Typography
								sx={{
									fontFamily: "Roboto",
									fontWeight: 400,
									fontSize: "12px",
									marginLeft: "4px",
									color: "rgba(0,0,0,0.87)",
								}}
							>
								{`${numberOfWorkersNeeded} ${
									numberOfWorkersNeeded === 1 ? "worker" : "workers"
								} required`}
							</Typography>
						</Box>
					</Box>
				);
				cellItems.push(cellItem);
			});
		}
		return (
			<Grid
				borderLeft={1}
				borderColor={BORDER_COLOR}
				item
				xs={1.4}
				sx={{
					display: "flex",
					minWidth: "123px",
				}}
			>
				{cellItems.length !== 0 && (
					<Box
						sx={{
							backgroundColor: backgroundColor,
							minHeight: "72px",
							flex: 1,
						}}
					>
						{cellItems}
					</Box>
				)}
				{cellItems.length === 0 &&
					isWeekend(selectedDate) &&
					!isToday(selectedDate) && (
						<Box
							sx={{
								backgroundColor: WEEKEND_SHIFT_CELL_COLOR,
								minHeight: "72px",
								flex: 1,
							}}
						/>
					)}
				{cellItems.length === 0 &&
					!isWeekend(selectedDate) &&
					!isToday(selectedDate) && (
						<Box
							sx={{
								backgroundColor: EMPTY_SHIFT_CELL_COLOR,
								minHeight: "72px",
								flex: 1,
							}}
						/>
					)}
				{cellItems.length === 0 && isToday(selectedDate) && (
					<Box
						sx={{
							backgroundColor: TODAY_HIGHLIGHT_COLOR,
							minHeight: "72px",
							flex: 1,
						}}
					/>
				)}
			</Grid>
		);
	};
	return (
		<Grid
			container
			sx={{
				display: "flex",
				flex: "1 0 auto",
			}}
		>
			<Grid
				item
				xs={2.2}
				sx={{
					backgroundColor: "rgba(255,255,255,0.5)",
					display: "flex",
					alignItems: "flex-start",
				}}
			>
				<Box
					sx={{
						minHeight: "72px",
						display: "flex",
						paddingTop: "24px",
					}}
				>
					<Typography
						sx={{
							marginLeft: "24px",
							fontSize: "16px",
							fontFamily: "Roboto",
						}}
					>
						<Highlight value={jobName} searchText={searchValue} />
					</Typography>
				</Box>
			</Grid>
			<>
				{datesArrayOfSelectedWeek.map((date, index) => {
					return (
						<DayCell
							key={`day-${index}`}
							selectedDate={date}
							scheduledWeeklyDraftShifts={
								index < 7 ? getShiftsByDate(date, scheduledShifts) : []
							}
							backgroundColor={
								isToday(date) ? TODAY_HIGHLIGHT_COLOR : "rgba(255,255,255,0.5)"
							}
						/>
					);
				})}
			</>
		</Grid>
	);
};
