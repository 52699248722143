import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import React from "react";

import {colors} from "../config/colors";

export default function Loading() {
	return (
		<Box
			height={"calc(100dvh - 128px)"}
			width={1}
			display='flex'
			justifyContent='center'
			alignItems='center'
			flexDirection='column'
		>
			<CircularProgress color={"success"} sx={{
				color: colors.infoText
			}} />
			<Box mt={2}>
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontSize: "14px",
						fontWeight: 500,
						marginLeft: "8px",
						color: colors.infoText,
					}}
				>Loading...</Typography>
			</Box>
		</Box>
	);
}
