import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    FormControl,
    FormHelperText,
    Typography,
} from "@mui/material";
import { TextField as MUITextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Highlight from "../../../../components/Highlight";
import { setDemoSetupOwnerDialog, updateUserRoleToDemoResetRole } from "../../store/gat-admin-slice";

interface IUser {
    userName: string,
    userId: number,
    isDemoResetRole: boolean
}
interface ISetupDemoOwnerDialogProps {
    setupDemoOwnerCompanyObj: any
    open: boolean
    editUserRoleToDemoResetRoleStatus: string
}
const SetupDemoOwnerDialog = ({ setupDemoOwnerCompanyObj, open, editUserRoleToDemoResetRoleStatus }: ISetupDemoOwnerDialogProps) => {

    const dispatch = useDispatch();

    const [demoUser, setDemoUser] = useState<{ userId: number, userName: string, isDemoResetRole: boolean }>({
        userName: "",
        userId: 0,
        isDemoResetRole: false
    })

    const demoOwner = setupDemoOwnerCompanyObj.users?.find((x: IUser) => x.isDemoResetRole)

    const isAutoCompleteDisabled = setupDemoOwnerCompanyObj.users?.filter((x: IUser) => x.isDemoResetRole === false).length === 0;

    // Function to sort users by userName 
    const sortedUsers = (setupDemoOwnerCompanyObj.users ?? []).slice().sort((a: any, b: any) =>
        a.userName.toLowerCase().localeCompare(b.userName.toLowerCase())
    );

    useEffect(() => {

        if (demoOwner) {
            setDemoUser(demoOwner)
        } else {
            setDemoUser({
                userName: "",
                userId: 0,
                isDemoResetRole: false
            })
        }
    }, [open, demoOwner])

    return (
        <Dialog
            aria-labelledby='add-new-company-user'
            open={open}
            maxWidth={false}
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
            PaperProps={{ sx: { maxWidth: "900px" } }}
            fullWidth
            
        >
            <DialogTitle
                id='company-dialog-title'
            >
                <Typography
                    sx={{
                        fontFamily: "Roboto",
                        fontSize: "20px",
                        fontWeight: 500,
                        color: "rgba(0, 0, 0, 0.87)",
                        paddingTop: "29px",
                        paddingLeft: "21px",
                        paddingBottom: "9px",
                        paddingRight: "21px",
                    }}
                >
                    Setup User with Demo Reset Access
                </Typography>
            </DialogTitle>
            <Divider />

            <DialogContent>

                <Box sx={{ backgroundColor: "#E6F3FA", minHeight: "89px", display: "flex", alignItems: "center", }} mx={"21px"} my={"20px"} px={5}>
                    <Box color="#0288D1" display={"flex"} >
                        <Box pt={0.5}> <InfoOutlinedIcon /></Box>
                        <Typography variant='subtitle1' pl={"10px"} sx={{ paddingTop: "3px", display: "flex", flexWrap: "wrap", whiteSpace: "normal" }} color={"#013654"}>
                            Only 1 User can have Demo Reset access on a Company. Please select a User who can get such a permission on this Company.
                        </Typography>
                    </Box>
                </Box>

                <Box mx={"21px"}>
                    <Typography fontSize={"14px"}>Company Name</Typography>
                    <Typography sx={{
                        fontFamily: "Roboto",
                        fontSize: "18px",
                        fontWeight: 400,
                        color: "rgba(0, 0, 0, 0.87)",
                    }}>{setupDemoOwnerCompanyObj.name}</Typography>
                </Box>

                <Box mx={"21px"} py={5}>
                    <FormControl variant="filled" fullWidth disabled={isAutoCompleteDisabled}>
                        <Autocomplete
                            disabled={isAutoCompleteDisabled}
                            disableClearable
                            renderInput={(params) => (
                                <MUITextField
                                    {...params}
                                    variant='filled'
                                    label='User *'
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            )}
                            value={demoUser}
                            options={sortedUsers}
                            getOptionLabel={(
                                option:
                                    | {
                                        userId: number;
                                        userName: string;
                                        isDemoResetRole: boolean;
                                    }
                                    | undefined
                            ) => {
                                if (option) {
                                    return option.userName;
                                } else {
                                    return "";
                                }
                            }}
                            isOptionEqualToValue={(option, value) => option.userId === value?.userId}
                            // getOptionLabel={(option) => option}
                            onChange={(_event, value) => {
                                console.log("event", _event, "value", value)
                                setDemoUser(value)
                            }}
                            ListboxProps={{
                                style: {
                                    maxHeight: "220px",
                                    // overflow: 'auto',
                                },
                            }}
                            renderOption={(props, option, { inputValue }) => {
                                const isDisabled = option.isDemoResetRole;
                                return (
                                    <li
                                        {...props}
                                        key={option?.userId}
                                        style={{
                                            pointerEvents: isDisabled ? "none" : "auto",
                                            opacity: isDisabled ? 0.5 : 1,
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "flex-start",
                                                minHeight: "32px",
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontFamily: "Roboto",
                                                    fontSize: "14px !important",
                                                    color: "rgba(0,0,0,0.87) !important",
                                                    lineHeight: "20px !important",
                                                    fontWeight: 400,
                                                }}
                                            >
                                                <Highlight
                                                    value={option?.userName}
                                                    searchText={inputValue}
                                                />
                                            </Typography>
                                        </Box>
                                    </li>
                                )
                            }}
                        />
                        {isAutoCompleteDisabled && (
                            <FormHelperText>
                                No users available. Please add more active users for this company and continue.
                            </FormHelperText>
                        )}
                    </FormControl>
                </Box>

                <Divider />

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        marginTop: "16px",
                        marginBottom: "16px",
                        marginRight: "21px",
                    }}
                >
                    <Button variant='outlined' onClick={() => {
                        dispatch(setDemoSetupOwnerDialog(false))
                    }}
                    >
                        CANCEL
                    </Button>
                    <Button
                        variant='contained'
                        sx={{ marginLeft: "16px", width: "100px" }}
                        startIcon={editUserRoleToDemoResetRoleStatus === "pending" && <CircularProgress size={20} />}
                        onClick={() => { dispatch(updateUserRoleToDemoResetRole({ companyId: setupDemoOwnerCompanyObj.id, userId: demoUser.userId })) }}
                        disabled={demoUser.userId === 0 || demoUser.userId === demoOwner?.userId || editUserRoleToDemoResetRoleStatus === "pending"}
                    >
                        APPLY
                    </Button>
                </Box>
            </DialogContent>

        </Dialog >

    )


}
export default SetupDemoOwnerDialog;
