import React from "react";

interface IErrorBoundaryProps {
    children?: React.ReactNode;
}

interface IErrorBoundaryState {
    hasError: boolean
}
class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: any, errorInfo: any) {
        // You can also log the error to an error reporting service
        localStorage.clear();
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <>
                    <div
                        style={{
                            height: "100vh",
                            width: "100vw",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <p>
                            An unexpected error has occurred, kindly refresh and try again.
                        </p>
                    </div>
                </>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
