import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const logo: string = require("../assets/logo_wilya_dark.svg").default;

interface ErrorProps {
	errorMessage?: string;
}

const Error: React.FC<ErrorProps> = ({ errorMessage }) => {
	return (
		<Box
			display='flex'
			flexDirection='column'
			justifyContent='center'
			alignItems='center'
			height='100vh'
			width='100%'
		>
			<img src={logo} alt='' height={90} />
			<Box mt={3} textAlign='center'>
				<Typography variant='h6'>
					{errorMessage ?? "An Error Occurred"}
				</Typography>
				<Typography>Please try again.</Typography>
			</Box>
		</Box>
	);
}

export default Error;
