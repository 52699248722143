import { Box, Divider, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";

import AssignSkillForm from "./assign-skill-form";
// import useAddSkillDialog from "../../../controllers/use-add-skill-dialog";

interface AddWorkerSkillDialogProps {
	isOpen: boolean;
	onClose: () => void;
}

const AddWorkerSkillDialog: React.FC<AddWorkerSkillDialogProps> = ({
	isOpen,
	onClose,
}) => {
	// const [{workerAssignedSkills, skillsMappedWithJob}] = useAddSkillDialog(onClose);
	return (
		<Dialog
			onClose={(e, reason) => {
				if (reason === "backdropClick") {
					return;
				}
				onClose();
			}}
			aria-labelledby='add-edit-certification'
			open={!!isOpen}
			maxWidth={false}
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<DialogTitle
				id='location-dialog-title'
				sx={{
					width: "900px",
					padding: "45px 45px 24px 45px",
				}}
			>
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontSize: "20px",
						fontWeight: 500,
						color: "rgba(0, 0, 0, 0.87)",
					}}
				>
					Add Skills for Worker
				</Typography>
			</DialogTitle>
			<DialogContent>
				<Box
					sx={{
						//	paddingTop: "12px",
						paddingLeft: "21px",
						paddingRight: "21px",
						paddingBottom: "12px",
						//height: 540,
					}}
				>
					<AssignSkillForm closeDialog={onClose} />
				</Box>
			</DialogContent>
		</Dialog>
	);
};

export default AddWorkerSkillDialog;
