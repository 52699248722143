import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useState } from "react";
import React from "react";
import { useDispatch } from "react-redux";

import { sendBroadcastMessage } from "../../../../../../export/scheduler";
import Button from "../../../../../design/button";
import useSchedulerData from "../../../controllers/use-scheduler-data";
import { JobsState } from "./types";

interface ActionButtonsProps {
	handleClose: () => void;
	message: string;
	jobs: string;
	locationIds: string;
	workCenterIds: string;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({
	handleClose,
	message,
	jobs,
	locationIds,
	workCenterIds,
}) => {
	const dispatch = useDispatch();
	const {
		broadcastMessageStatus,
		broadcastWorkerCount,
		broadcastWorkerCountStatus,
		broadcastWorkerCountErrorMessage,
	} = useSchedulerData();
	const [broadcastStatus, setBroadcastStatus] = useState("");

	useEffect(() => {
		if (broadcastMessageStatus) {
			setBroadcastStatus(broadcastMessageStatus);
		}
	}, [broadcastMessageStatus]);

	const isDisabled = () => {
		return (
			broadcastWorkerCountStatus === "pending" ||
			broadcastWorkerCount === 0 ||
			broadcastStatus === "pending" ||
			message.length === 0 ||
			message.length > 160 ||
			broadcastWorkerCountStatus === "rejected"
		);
	};

	return (
		<Box
			display='flex'
			width='100%'
			justifyContent='space-between'
			alignItems='center'
		>
			{broadcastWorkerCountStatus === "rejected" ? (
				<Typography color='error'>
					{broadcastWorkerCountErrorMessage}
				</Typography>
			) : (
				<Box />
			)}

			<Box ml={2} display='flex' justifyContent='space-between' width='220px'>
				<Button color='primary' variant='text' onClick={handleClose}>
					Cancel
				</Button>
				<Button
					variant='contained'
					color='primary'
					type='submit'
					disabled={isDisabled()}
					onClick={() => {
						dispatch(
							sendBroadcastMessage({
								message,
								jobs,
								locationIds,
								workCenterIds,
								reqType: "broadcast",
							})
						);
					}}
				>
					{broadcastStatus === "pending" ? (
						<Box position='relative'>
							<CircularProgress
								size={25}
								style={{ position: "absolute", left: "50%" }}
							/>{" "}
							Sending
						</Box>
					) : (
						"Send Message"
					)}
				</Button>
			</Box>
		</Box>
	);
};

export default ActionButtons;
