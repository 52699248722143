import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import React from "react";
import { useDispatch } from "react-redux";

import BoxWrapper from "../../box-wrapper";
import {
	resetCreateNewTeam,
	resetEditTeam,
	resetRemoveShiftPattern,
} from "../../store/gat-admin-slice";
import useTeamsController from "../teams/controllers/use-teams-controller";
import TeamsDialog from "./ui/dialog/team-dialog";
import TeamsTable from "./ui/table";

const Teams = () => {
	const dispatch = useDispatch();
	const [
		{
			isTeamDialogOpen,
			editTeamData,
			createNewTeamStatus,
			createNewTeamError,
			editTeamStatus,
			editTeamError,
			teams,

			shiftPatternRemoveStatus,
			shiftPatternRemoveErrorMessage,
		},
		{
			setIsTeamDialogOpen,
			closeEditTeam,
			getSchedule,
			openEditTeam,
			deletePattern,
		},
	] = useTeamsController();

	return (
		<>
			<BoxWrapper
				title='Shift Patterns'
				subtitle='Setup team level multi-week Shift Patterns by using Shift Schedules'
				openDialog={() => setIsTeamDialogOpen(true)}
				disabled
			>
				<TeamsTable
					getSchedule={getSchedule}
					openEditTeam={openEditTeam}
					deletePattern={deletePattern}
					teams={teams}
					shiftPatternRemoveStatus={shiftPatternRemoveStatus}
				/>
			</BoxWrapper>

			{isTeamDialogOpen ? (
				<TeamsDialog
					isTeamsDialogOpen={isTeamDialogOpen}
					closeTeamsDialog={() => setIsTeamDialogOpen(false)}
					isEdit={false}
					teamData={null}
				/>
			) : null}

			{editTeamData.isDialogOpen ? (
				<TeamsDialog
					isTeamsDialogOpen={editTeamData.isDialogOpen}
					closeTeamsDialog={closeEditTeam}
					isEdit={true}
					teamData={editTeamData.data}
				/>
			) : null}

			{/* create team */}
			<Snackbar
				open={createNewTeamStatus === "error"}
				autoHideDuration={3000}
				onClose={() => {
					dispatch(resetCreateNewTeam());
				}}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<MuiAlert elevation={6} variant='filled' severity='error'>
					{createNewTeamError ?? "An Error Occurred. Please try again."}
				</MuiAlert>
			</Snackbar>

			<Snackbar
				open={createNewTeamStatus === "fulfilled"}
				autoHideDuration={2000}
				onClose={() => {
					dispatch(resetCreateNewTeam());
				}}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<MuiAlert elevation={6} variant='filled' severity='success'>
					{"Shift Pattern Created Successfully!"}
				</MuiAlert>
			</Snackbar>

			{/* edit team */}
			<Snackbar
				open={editTeamStatus === "error"}
				autoHideDuration={3000}
				onClose={() => {
					dispatch(resetEditTeam());
				}}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<MuiAlert elevation={6} variant='filled' severity='error'>
					{editTeamError ?? "An Error Occurred. Please try again."}
				</MuiAlert>
			</Snackbar>

			<Snackbar
				open={editTeamStatus === "fulfilled"}
				autoHideDuration={2000}
				onClose={() => {
					dispatch(resetEditTeam());
				}}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<MuiAlert elevation={6} variant='filled' severity='success'>
					{"Shift Pattern Updated Successfully!"}
				</MuiAlert>
			</Snackbar>

			{/* remove shift pattern */}
			<Snackbar
				open={shiftPatternRemoveStatus === "error"}
				autoHideDuration={3000}
				onClose={() => {
					dispatch(resetRemoveShiftPattern());
				}}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<MuiAlert elevation={6} variant='filled' severity='error'>
					{shiftPatternRemoveErrorMessage ??
						"An Error Occurred. Please try again."}
				</MuiAlert>
			</Snackbar>

			<Snackbar
				open={shiftPatternRemoveStatus === "fulfilled"}
				autoHideDuration={2000}
				onClose={() => {
					dispatch(resetRemoveShiftPattern());
				}}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<MuiAlert elevation={6} variant='filled' severity='success'>
					{"Shift Pattern Deleted Successfully!"}
				</MuiAlert>
			</Snackbar>
		</>
	);
};
export default Teams;
