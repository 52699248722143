import { Add } from "@mui/icons-material";
import { Search } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import React, {useEffect, useState} from "react";
import { useDispatch } from "react-redux";

import {
	closeEditWorkerSkillDialog,
	resetAddWorkerSkills,
	resetDeleteWorkerSkills,
	resetEditWorkerSkill,
} from "../../../../../export/workers";
import SectionSearch from "../../../../design/section-search";
import useWorkersData from "../../controllers/use-workers-data";
import AddWorkerSkillDialog from "./ui/dialog/add-worker-skill";
import EditWorkerSkillDialog from "./ui/dialog/edit-worker-skill-dialog";
import WorkerSkillsTable from "./ui/table/worker-skills-table";

const WorkerSkills = () => {
	const dispatch = useDispatch();
	const {
		deleteWorkerSkillStatus,
		deleteWorkerSkillError,
		addWorkerSkillStatus,
		addWorkerSkillError,
		editWorkerSkillStatus,
		editWorkerSkillError,
		isEditWorkerSkillDialogOpen,
		workerProfile,
		allSkillsWithPriority,
		skillPriorityEnabled,
		skillExpiryEnabled,
	} = useWorkersData();

	const [searchText, setSearchText] = useState<string>("");

	const [isAddWorkerSkillDialogOpen, setIsAddWorkerSkillDialogOpen] =
		useState<boolean>(false);

	const openAddWorkerSkillDialog = () => {
		dispatch(resetAddWorkerSkills());
		setIsAddWorkerSkillDialogOpen(true);
	};

	return (
		<Box
			sx={{
				borderRadius: "10px",
				border: "3px solid #f4f4f4",
			}}
			mb={5}
			mt={5}
		>
			<Box p={2}>
				<Box>
					<Typography fontWeight='bold'>
						Assign skills and skill level (optional)
					</Typography>
					<Typography variant='caption'>
						Select skills and skill level for each job role assigned. Add any
						additional skills.
					</Typography>
				</Box>
				<Box
					display={"flex"}
					alignItems={"center"}
					justifyContent={"space-between"}
					mt={2}
				>
					<SectionSearch
						value={searchText}
						setSearch={setSearchText}
						placeholder='Search'
					/>
					<Box>
						<Button startIcon={<Add />} onClick={openAddWorkerSkillDialog}>
							Skills
						</Button>
					</Box>
				</Box>
			</Box>
			<Box
				sx={{
					padding: "12px 8px",
				}}
			>
				<WorkerSkillsTable
					workerProfile={workerProfile}
					searchText={searchText}
					skillExpiryEnabled={skillExpiryEnabled}
					allSkillsWithPriority={allSkillsWithPriority}
					skillPriorityEnabled={skillPriorityEnabled}
				/>
			</Box>

			{isAddWorkerSkillDialogOpen && (
				<AddWorkerSkillDialog
					isOpen={isAddWorkerSkillDialogOpen}
					onClose={() => setIsAddWorkerSkillDialogOpen(false)}
				/>
			)}

			{isEditWorkerSkillDialogOpen && (
				<EditWorkerSkillDialog
					open={isEditWorkerSkillDialogOpen}
					closeEditWorkerSkillDialog={closeEditWorkerSkillDialog}
				/>
			)}

			{/* Add Skills for worker */}
			<Snackbar
				open={addWorkerSkillStatus === "fulfilled"}
				autoHideDuration={3000}
				onClose={() => dispatch(resetAddWorkerSkills())}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<MuiAlert elevation={6} variant='filled' severity='success'>
					Skill added successfully
				</MuiAlert>
			</Snackbar>
			<Snackbar
				open={addWorkerSkillStatus === "error"}
				autoHideDuration={3000}
				onClose={() => dispatch(resetAddWorkerSkills())}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<MuiAlert elevation={6} variant='filled' severity='error'>
					{addWorkerSkillError ?? "An error occurred"}
				</MuiAlert>
			</Snackbar>

			{/* Edit Skill for worker */}
			<Snackbar
				open={editWorkerSkillStatus === "fulfilled"}
				autoHideDuration={3000}
				onClose={() => dispatch(resetEditWorkerSkill())}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<MuiAlert elevation={6} variant='filled' severity='success'>
					Skill Edited successfully
				</MuiAlert>
			</Snackbar>
			<Snackbar
				open={editWorkerSkillStatus === "error"}
				autoHideDuration={3000}
				onClose={() => dispatch(resetEditWorkerSkill())}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<MuiAlert elevation={6} variant='filled' severity='error'>
					{editWorkerSkillError ?? "An error occurred"}
				</MuiAlert>
			</Snackbar>

			{/* Delete Skills for worker */}
			<Snackbar
				open={deleteWorkerSkillStatus === "fulfilled"}
				autoHideDuration={3000}
				onClose={() => dispatch(resetDeleteWorkerSkills())}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<MuiAlert elevation={6} variant='filled' severity='success'>
					Skill removed successfully
				</MuiAlert>
			</Snackbar>
			<Snackbar
				open={deleteWorkerSkillStatus === "error"}
				autoHideDuration={3000}
				onClose={() => dispatch(resetDeleteWorkerSkills())}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<MuiAlert elevation={6} variant='filled' severity='error'>
					{deleteWorkerSkillError ?? "An error occurred"}
				</MuiAlert>
			</Snackbar>
		</Box>
	);
};

export default WorkerSkills;
