/* eslint-disable no-mixed-spaces-and-tabs */
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { requestShiftAction } from "../../../../../../export/shift-swaps";
import Button from "../../../../../design/button";
import useSchedulerData from "../../../controllers/use-scheduler-data";

type ApproveProps = {
	handleClose: (() => void) | undefined;
};

const Approve: React.FC<ApproveProps> = ({ handleClose }) => {
	const dispatch = useDispatch();
	const {
		createDraftShiftAsyncErrorMessage,
		requestActionStatus,
		requestActionErrorMessage,
		formData,
		userRoles,
	} = useSchedulerData();

	const handleSubmit = () => {
		const payload = {
			...formData,
			flexShiftDetails: null,
		};
		dispatch(requestShiftAction(payload));
	};
	return (
		<Box
			display='flex'
			mt={4}
			width='100%'
			justifyContent='flex-end'
			alignItems='center'
		>
			{requestActionErrorMessage ? (
				<Typography color='secondary'>{requestActionErrorMessage}</Typography>
			) : null}
			<Box ml={2} display='flex' justifyContent='space-between' width={"220px"}>
				<Button color='primary' variant='text' onClick={handleClose}>
					Cancel
				</Button>
				<LoadingButton
					variant='contained'
					sx={{ marginLeft: "16px" }}
					loading={requestActionStatus === "pending"}
					onClick={handleSubmit}
					loadingIndicator={
						<CircularProgress size={16} sx={{ color: "rgba(0, 0, 0, 0.38)" }} />
					}
				>
					Approve
				</LoadingButton>
			</Box>
		</Box>
	);
};

export default Approve;
