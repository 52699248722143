import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import React from "react";

type PastShiftDialogProps = {
	handleClose: (() => void) | undefined;
	open: boolean;
};

const PastShiftDialog: React.FC<PastShiftDialogProps> = ({
	handleClose,
	open,
}) => {
	return (
		<Dialog
			onClose={handleClose}
			aria-labelledby='add-new-shift'
			open={open}
			maxWidth={false}
		>
			<DialogTitle id='customized-dialog-title'>
				<Box
					display='flex'
					flexDirection='row'
					justifyContent='space-between'
					alignItems='center'
					width={600}
				>
					<Box>
						<Typography variant='h6'>
							Sorry! Can&apos;t create shifts in the past.
						</Typography>
					</Box>
					<IconButton
						edge='start'
						color='inherit'
						aria-label='menu'
						onClick={handleClose}
						size='large'
					>
						<CloseIcon />
					</IconButton>
				</Box>
			</DialogTitle>
		</Dialog>
	);
};

export default PastShiftDialog;
