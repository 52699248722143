// TODO - Type 'any' needs to be fixed.
import { format } from "date-fns";
import { FormikValues } from "formik";

import {setShiftsByFilter} from "../../../../../../../export/shift-scheduler";
import {JOB,SKILLS} from "../../../../../../assets/constants";

type SkillsProps = {
	shiftId?: number;
	skillId: number;
	skillLevelId?: number;
	skillName: string;
	skillLevel?: string;
	level: number;
};

type UseEditShiftDialogForm = {
	state: any;
	skillState: any;
	setToNearestTime: (dateString: string) => string;
	dispatch: (action: any) => void;
	dispatchLocal: (action: any) => void;
	dispatchLocalSkill: (action: any) => void;
	editDraftShift: (arg: any) => void;
	calculateTotalHoursFromStartTime:
		| ((values: any, time: string) => void)
		| undefined;
	calculateTotalHoursFromEndTime:
		| ((values: any, time: string) => void)
		| undefined;
};

type FormValues = {
	setFieldValue: (a: string, b: any) => void;
	values: FormikValues;
};

type Workcenter = {
	location: {
		id: string;
		name: string;
	};
	locationName: string;
	locationId: string;
};

export const useEditShiftDialogForm = ({
	state,
	skillState,
	setToNearestTime,
	dispatch,
	dispatchLocal,
	dispatchLocalSkill,
	editDraftShift,
	calculateTotalHoursFromStartTime,
	calculateTotalHoursFromEndTime,
}: UseEditShiftDialogForm) => {
	const onFormSubmit = (values: any, actions: any) => {
		const { setSubmitting } = actions;
		const id = values.jobId;
		if (id === "" || id === 0) {
			const skillIds = values.shiftSkills.map((skill: SkillsProps) => skill.skillId);
			skillIds.forEach((skillId: number) => {
				if (skillState[skillId] !== true) {
					dispatchLocalSkill({
						type: "toggleCheckbox",
						payload: skillId,
					})
				}
			});
			dispatch(setShiftsByFilter(SKILLS));
		} else {
			if (state[id] !== true) {
				dispatchLocal({
					type: "toggleCheckbox",
					payload: values.jobId,
				});
			}
			dispatch(setShiftsByFilter(JOB));
		}
		dispatch(
			editDraftShift({
				values,
				shiftId: values.shiftId,
				shiftDate: format(new Date(values.shiftDate), "yyyy-MM-dd"),
				shiftPublished: values.shiftPublished,
				currentShift: values.shiftData,
				setSubmitting,
			})
		);
	};

	const onStartTimeChange = (
		dateString: string,
		formValues: FormValues
	) => {
		const { setFieldValue, values } = formValues;
		setFieldValue("startTime", dateString);
		calculateTotalHoursFromStartTime &&
			calculateTotalHoursFromStartTime(values, dateString);
	};

	const onEndTimeChange = (
		dateString: string,
		formValues: FormValues
	) => {
		const { setFieldValue, values } = formValues;
		setFieldValue("endTime", setToNearestTime(dateString));
		calculateTotalHoursFromEndTime &&
			calculateTotalHoursFromEndTime(values, dateString);
	};

	const getLocationWorkcenters = (workcenters: Workcenter[]): Workcenter[] => {
		const locObj: Record<string, Workcenter[]> = {};
		workcenters.forEach((wc) => {
			if (locObj[wc.location.id]) {
				locObj[wc.location.id].push({
					...wc,
					locationName: wc.location.name,
					locationId: wc.location.id,
				});
			} else {
				locObj[wc.location.id] = [
					{ ...wc, locationName: wc.location.name, locationId: wc.location.id },
				];
			}
		});
		const result: Workcenter[] = [];
		Object.values(locObj).forEach((loc: any) =>
			loc.forEach((wc: any) => result.push(wc))
		);
		return result;
	};

	return [
		{},
		{
			onFormSubmit,
			onEndTimeChange,
			onStartTimeChange,
			getLocationWorkcenters,
		},
	];
};
