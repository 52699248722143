import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { resetAddWorkerCertifications } from "../../../../../../export/workers";
import useWorkersData from "../../../controllers/use-workers-data";

const useWorkerCertificationsController = () => {
	const dispatch = useDispatch();
	const [isAddCertificationDialogOpen, setIsAddCertificationDialogOpen] =
		React.useState<boolean>(false);

	const [searchText, setSearchText] = useState<string>("");
	const {
		deleteCertificationError,
		deleteCertificationStatus,
		addWorkerCertificationError,
		addWorkerCertificationStatus,
	} = useWorkersData();

	const openAddCertificationDialog = () => {
		dispatch(resetAddWorkerCertifications());
		setIsAddCertificationDialogOpen(true);
	};

	const closeAddCertificationDialog = () => {
		setIsAddCertificationDialogOpen(false);
	};

	return [
		{
			isAddCertificationDialogOpen,
			deleteCertificationError,
			deleteCertificationStatus,
			addWorkerCertificationError,
			addWorkerCertificationStatus,
			searchText,
		},
		{ openAddCertificationDialog, closeAddCertificationDialog, setSearchText },
	];
};

export default useWorkerCertificationsController;
