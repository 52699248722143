import {Box, Tooltip, Typography} from "@mui/material";
import React from "react";

import {Highlight} from "../../../../../export/helpers";

type Props = {
    skill: {
        name: string;
    };
    search: string,
};

const SkillItem = ({ skill, search }: Props) => {
    const { name } = skill;
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
        }}>
            <Tooltip title={name}>
                <Box
                    sx={{
                        borderRadius: "8px",
                        paddingTop: "3px",
                        paddingBottom: "3px",
                        paddingLeft: "6px",
                        paddingRight: "6px",
                        marginRight: "8px"
                    }}
                >
                    <Typography variant="body2" sx={{
                        display: "box",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        boxOrient: "vertical",
                        overflow: "hidden",
                        maxWidth: "192px",
                        fontFamily: "Roboto",
                        fontSize: "13px",
                        fontWeight: "400",
                        lineHeight: "18px",
                        letterSpacing: "0.16px",
                        color: "rgba(0,0,0,0.87)"
                    }}>
                        <Highlight value={name} searchText={search} />
                    </Typography>
                </Box>
            </Tooltip>
        </Box>
    );
};

export default SkillItem;