import { Box, Grid, Typography } from "@mui/material";
import React from "react";

import {
	BORDER_COLOR,
	EMPTY_SHIFT_CELL_COLOR,
	TODAY_HIGHLIGHT_COLOR,
	WEEKEND_SHIFT_CELL_COLOR,
} from "../../../constants/colors";
import useScheduleController from "../../../controllers/schedule-controller";
import { CellItem } from "./scheduled-shifts-table-row-cell";

export const Row = ({
	jobName,
	scheduledShifts,
	datesArrayOfSelectedWeek,
	openShiftDetailsDialog,
	setShiftIdForShiftDetailsDialog,
}) => {
	const [, { isWeekend, isToday, getShiftsByDate }] = useScheduleController();
	const DayCell = ({
		scheduledShifts,
		backgroundColor = "rgba(255,255,255,0.5)",
		selectedDate,
	}) => {
		let cellItems = [];
		scheduledShifts.forEach((shift, index) => {
			cellItems.push(
				<CellItem
					key={`scheduled-shift-${shift.id}`}
					shift={shift}
					index={index}
					selectedDate={selectedDate}
					openShiftDetailsDialog={() => {
						openShiftDetailsDialog();
					}}
					setShiftIdForShiftDetailsDialog={(shiftId) =>
						setShiftIdForShiftDetailsDialog(shiftId)
					}
				/>
			);
		});
		const renderCell = (items) => {
			// Default view when we have shifts in cell
			let returnValue = (
				<Box
					sx={{
						backgroundColor: backgroundColor,
						minHeight: "74px",
						flex: 1,
					}}
				>
					{items}
				</Box>
			);
			// When there is no shift in cell
			if (items.length === 0) {
				// Its weekend
				if (isWeekend(selectedDate)) {
					if (isToday(selectedDate)) {
						returnValue = (
							<Box
								sx={{
									backgroundColor: TODAY_HIGHLIGHT_COLOR,
									minHeight: "74px",
									flex: 1,
								}}
							/>
						);
					} else {
						returnValue = (
							<Box
								sx={{
									backgroundColor: WEEKEND_SHIFT_CELL_COLOR,
									minHeight: "74px",
									flex: 1,
								}}
							/>
						);
					}
				} else {
					if (isToday(selectedDate)) {
						returnValue = (
							<Box
								sx={{
									backgroundColor: TODAY_HIGHLIGHT_COLOR,
									minHeight: "74px",
									flex: 1,
								}}
							/>
						);
					} else {
						returnValue = (
							<Box
								sx={{
									backgroundColor: EMPTY_SHIFT_CELL_COLOR,
									minHeight: "74px",
									flex: 1,
								}}
							/>
						);
					}
				}
			}
			return returnValue;
		};
		return (
			<Grid
				borderLeft={1}
				borderColor={BORDER_COLOR}
				item
				xs={1.4}
				sx={{
					display: "flex",
					minWidth: "123px",
					minHeight: "74px",
				}}
			>
				{renderCell(cellItems)}
			</Grid>
		);
	};
	return (
		<Grid
			container
			wrap='nowrap'
			sx={{
				display: "flex",
				flex: "1 0 auto",
			}}
		>
			<Grid
				item
				xs={2.2}
				sx={{
					backgroundColor: "rgba(255,255,255,0.5)",
					display: "flex",
					alignItems: "flex-start",
				}}
			>
				<Box
					sx={{
						minHeight: "74px",
						display: "flex",
						paddingTop: "24px",
					}}
				>
					<Typography
						sx={{
							marginLeft: "24px",
							fontSize: "16px",
							fontFamily: "Roboto",
						}}
					>
						{jobName}
					</Typography>
				</Box>
			</Grid>
			<>
				{datesArrayOfSelectedWeek.map((date, index) => {
					return (
						<DayCell
							key={`day-${index}`}
							selectedDate={date}
							scheduledShifts={
								index < 7 ? getShiftsByDate(date, scheduledShifts) : []
							}
							backgroundColor={
								isToday(date) ? TODAY_HIGHLIGHT_COLOR : "rgba(255,255,255,0.5)"
							}
						/>
					);
				})}
			</>
		</Grid>
	);
};
