// TODO - Type 'any' needs to be fixed.
import { useEffect,useState } from "react";

const useSearch = ({data, searchValue, searchField}:any) => {
    const [searchResults, setSearchResults] = useState(data);
    
    useEffect(() => {
        if (searchValue) {
        const results = data.filter((item:any) => {
            return item[searchField].toLowerCase().includes(searchValue.toLowerCase());
        });
        setSearchResults(results);
        } else {
        setSearchResults(data);
        }
    }, [searchValue, data, searchField]);
    
    return searchResults;
}

export default useSearch;
