// TODO - Type 'any' needs to be fixed.
/* eslint-disable no-mixed-spaces-and-tabs */
import CloseIcon from "@mui/icons-material/Close";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { DialogTitle } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
// import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
// import DialogActions from '@mui/material/DialogActions'
import Typography from "@mui/material/Typography";
import format from "date-fns/format";
import isPast from "date-fns/isPast";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getShiftDetails, renotifyWorkers } from "../../../components/scheduler/store/scheduler-slice";

// import { getShiftDetails, renotifyWorkers } from "../ShiftSchedulerSlice";

function MobileShiftDetails({ open, handleClose, shiftId }: any) {
  const dispatch = useDispatch();

  const userRoles = useSelector((state: any) => state.user.userData.userRoles);

  const [shiftMetadata, setShiftMetadata] = useState<any>({});
  const [jobRoleMetadata, setJobRoleMetadata] = useState<any>({});

  const shiftData = useSelector(
    (state: any) => state.supervisor?.shiftDetails?.data?.[shiftId]
  );

  const allShiftsMetadata = useSelector(
    (state: any) => state.supervisor?.schedulerData?.shiftsData
  );

  const allJobRoles = useSelector(
    (state: any) => state.supervisor?.masterData?.allJobRoles
  );

  const allWorkcenters = useSelector(
    (state: any) => state.supervisor?.masterData?.allWorkcenters
  );

  const status = useSelector((state: any) => state.supervisor?.shiftDetails?.status);

  const renotifyWorkersStatus = useSelector(
    (state: any) => state.supervisor.renotifyWorkers.status
  );

  // const renotifyWorkersErrorMessage = useSelector(
  //   (state) => state.supervisor.renotifyWorkers.errorMessage
  // );

  useEffect(() => {
    // fetch the data
    if (shiftId) {
      dispatch(getShiftDetails({ shiftid: shiftId }));
    }
  }, [dispatch, shiftId]);

  const { accepted, declined, viewed, notified, none } = shiftData ?? {
    accepted: null,
    declined: null,
    viewed: null,
    notified: null,
    none: null,
  };

  useEffect(() => {
    // getting shift details from shiftId
    if (allShiftsMetadata) {
      const currentShiftData = allShiftsMetadata.filter(
        (shift: any) => shift.id === shiftId
      );
      const workcent = allWorkcenters.filter(
        (workcenter: any) => workcenter.id === currentShiftData[0].workCenterId
      );
      setShiftMetadata({
        workcenterName: workcent[0]?.name,
        ...currentShiftData[0],
      });
    }
  }, [allShiftsMetadata, allWorkcenters, shiftId]);

  useEffect(() => {
    if (allJobRoles) {
      const currentJobRole = allJobRoles.filter(
        (jobRole: any) => jobRole.id === shiftMetadata.jobId
      );
      setJobRoleMetadata(currentJobRole[0]);
    }
  }, [allJobRoles, shiftMetadata.jobId]);

  const handleNotifyAll = () => {
    const rowData: any = [];
    if (shiftData) {
      const { accepted, declined, viewed, notified, none } = shiftData;

      if (viewed) {
        viewed.forEach((worker: any) => {
          rowData.push(worker.workerId);
        });
      }

      if (notified) {
        const tempNotified = [...notified];
        if (accepted) {
          accepted?.forEach((acceptedWorker: any) => {
            const index = tempNotified?.findIndex(
              (el) => el.workerId === acceptedWorker.workerId
            );
            if (index !== -1) {
              tempNotified.splice(index, 1);
            }
          });
        }
        if (declined) {
          declined?.forEach((declinedWorker: any) => {
            const index = tempNotified?.findIndex(
              (el) => el.workerId === declinedWorker.workerId
            );

            if (index !== -1) tempNotified?.splice(index, 1);
          });
        }

        if (viewed) {
          viewed?.forEach((viewedWorker: any) => {
            const index = tempNotified?.findIndex(
              (el) => el.workerId === viewedWorker.workerId
            );
            if (index !== -1) tempNotified?.splice(index, 1);
          });
        }

        tempNotified.forEach((worker) => {
          rowData.push(worker.workerId);
        });
      }

      if (none) {
        none.forEach((worker: any) => {
          rowData.push(worker.workerId);
        });
      }
    }

    dispatch(renotifyWorkers({ workers: rowData, shiftId }));
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="add-new-shift"
      open={open}
      // width={400}
      fullScreen
    //height={50}
    >
      {status === "pending" ? (
        <DialogContent dividers>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            mr="auto"
            ml="auto"
            flexDirection="column"
          >
            <CircularProgress />
            <Typography>Loading Shift Details</Typography>
          </Box>
        </DialogContent>
      ) : (
        <>
          <DialogTitle>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography> Shift Details</Typography>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleClose}
                size="small"
                style={{ float: "right" }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            <Box
              display="flex"
              // alignItems="center"
              // justifyContent="center"
              mr="auto"
              ml="auto"
              flexDirection="column"
            >
              {/* EEE, MMM do yyyy */}
              <Typography variant="h5">
                {shiftMetadata?.workcenterName}
              </Typography>
              <Typography variant="h6">
                {shiftMetadata.startDate
                  ? format(new Date(shiftMetadata?.startDate), "HH:mm")
                  : null}{" "}
                -{" "}
                {shiftMetadata.endDate
                  ? format(new Date(shiftMetadata?.endDate), "HH:mm")
                  : null}
              </Typography>
              {/* <Button autoFocus
              color="primary"
              variant="contained"
              startIcon={<NotificationsActiveIcon />}
            >
              Notify All
            </Button> */}
              <Button
                autoFocus
                color="primary"
                variant="contained"
                startIcon={<NotificationsActiveIcon />}
                disabled={
                  (Array.isArray(userRoles)
                    ? userRoles.includes("ReadOnly")
                    : false) || isPast(new Date(shiftMetadata.startDateTime))
                    ? true
                    : renotifyWorkersStatus === "pending" ||
                    renotifyWorkersStatus === "error"
                }
                onClick={handleNotifyAll}
              >
                {renotifyWorkersStatus === "pending" ? (
                  <Box position="relative">
                    <CircularProgress
                      size={25}
                      style={{ position: "absolute", left: "50%" }}
                    />
                    Renotifying
                  </Box>
                ) : (
                  "Notify All"
                )}
              </Button>
            </Box>

            {shiftData && (
              <Box
                display="flex"
                justifyContent="space-between"
                width={280}
                mt={2}
                mb={2}
              >
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="subtitle1">
                    ✅{" "}
                    {shiftData?.accepted
                      ? `${Object.keys(shiftData?.accepted).length}`
                      : "0"}
                    /{shiftMetadata?.numberOfWorkersNeeded ?? "0"}{" "}
                  </Typography>
                  {/* <Typography variant="caption"> Confirmed</Typography> */}
                </Box>
                <Box display="flex">
                  <Typography variant="subtitle1">
                    ⛔{" "}
                    {shiftData.declined === null
                      ? 0
                      : shiftData.declined?.length}
                    /
                    {jobRoleMetadata?.maxWorkers ??
                      shiftMetadata?.numberOfWorkersNeeded}{" "}
                  </Typography>
                  {/* <Typography variant="caption"> Declined</Typography> */}
                </Box>
                <Box display="flex">
                  <Typography variant="subtitle1">
                    🔔{" "}
                    {shiftData.notified === null
                      ? 0
                      : shiftData.notified?.length}
                    /
                    {jobRoleMetadata?.maxWorkers ??
                      shiftMetadata?.numberOfWorkersNeeded}{" "}
                  </Typography>
                  {/* <Typography variant="caption">{" "}Notified</Typography> */}
                </Box>
                <Box display="flex">
                  <Typography variant="subtitle1">
                    👀{" "}
                    {shiftData.viewed === null ? 0 : shiftData.viewed?.length}/
                    {jobRoleMetadata?.maxWorkers ??
                      shiftMetadata?.numberOfWorkersNeeded}{" "}
                  </Typography>
                  {/* <Typography variant="caption">{" "}Notified</Typography> */}
                </Box>
              </Box>
            )}
            <Divider />

            {shiftData && (
              <Box>
                {accepted?.length > 0 &&
                  accepted.map((worker: any) => (
                    <Box mt={1} mb={1} key={worker.workerId}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        borderLeft="1px solid green"
                        pl={1}
                      >
                        <Box>
                          <Typography variant="subtitle1" gutterBottom>
                            {worker.workerName}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            color="gray"
                            gutterBottom
                          >
                            {jobRoleMetadata?.name}
                          </Typography>
                        </Box>
                        <Typography
                          variant="caption"
                          display="block"
                          color="gray"
                          gutterBottom
                        >
                          Confirmed ✅
                        </Typography>
                      </Box>
                      <Box>
                        <Divider />
                      </Box>
                    </Box>
                  ))}
                {declined?.length > 0 &&
                  declined.map((worker: any) => (
                    <Box mt={1} mb={1} key={worker.workerId}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        borderLeft="1px solid red"
                        pl={1}
                      >
                        <Box>
                          <Typography variant="subtitle1" gutterBottom>
                            {worker.workerName}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            color="gray"
                            gutterBottom
                          >
                            {jobRoleMetadata?.name}
                          </Typography>
                        </Box>
                        <Typography
                          variant="caption"
                          display="block"
                          color="gray"
                          gutterBottom
                        >
                          Declined ⛔
                        </Typography>
                      </Box>
                      <Box>
                        <Divider />
                      </Box>
                    </Box>
                  ))}
                {notified?.length > 0 &&
                  notified.map((worker: any) => (
                    <Box mt={1} mb={1} key={worker.workerId}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        borderLeft="1px solid orange"
                        pl={1}
                      >
                        <Box>
                          <Typography variant="subtitle1" gutterBottom>
                            {worker.workerName}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            color="gray"
                            gutterBottom
                          >
                            {jobRoleMetadata?.name}
                          </Typography>
                        </Box>
                        <Typography
                          variant="caption"
                          display="block"
                          color="gray"
                          gutterBottom
                        >
                          Notified 🔔
                        </Typography>
                      </Box>
                      <Box>
                        <Divider />
                      </Box>
                    </Box>
                  ))}
                {viewed?.length > 0 &&
                  viewed.map((worker: any) => (
                    <Box mt={1} mb={1} key={worker.workerId}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        borderLeft="1px solid gray"
                        pl={1}
                      >
                        <Box>
                          <Typography variant="subtitle1" gutterBottom>
                            {worker.workerName}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            color="gray"
                            gutterBottom
                          >
                            {jobRoleMetadata?.name}
                          </Typography>
                        </Box>
                        <Typography
                          variant="caption"
                          display="block"
                          color="gray"
                          gutterBottom
                        >
                          Viewed 👀
                        </Typography>
                      </Box>
                      <Box>
                        <Divider />
                      </Box>
                    </Box>
                  ))}

                {none?.length > 0 &&
                  none.map((worker: any) => (
                    <Box mt={1} mb={1} key={worker.workerId}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        borderLeft="1px solid black"
                        pl={1}
                      >
                        <Box>
                          <Typography variant="subtitle1" gutterBottom>
                            {worker.workerName}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            color="gray"
                            gutterBottom
                          >
                            {jobRoleMetadata?.name}
                          </Typography>
                        </Box>
                        <Typography
                          variant="caption"
                          display="block"
                          color="gray"
                          gutterBottom
                        >
                          None ➖
                        </Typography>
                      </Box>
                      <Box>
                        <Divider />
                      </Box>
                    </Box>
                  ))}
              </Box>
            )}
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}

export default MobileShiftDetails;
