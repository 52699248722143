/* eslint-disable no-mixed-spaces-and-tabs */
// TODO - Type 'any' needs to be fixed.
import addDays from "date-fns/addDays";
import startOfWeek from "date-fns/startOfWeek";
import { useMemo, useState } from "react";

import useSchedulerData from "../../../../controllers/use-scheduler-data";
import {
	CopyShiftsDataBySkillsProps,
	CopyShiftsDataProps,
	Job,
	Shift,
	Skill,
	SkillInShift,
	WorkCenter,
} from "../types";

interface UseCopyShiftsDialogProps {
	weekPasteDate: { startDate: Date; endDate: Date };
}

const useCopyShiftsDialog = ({ weekPasteDate }: UseCopyShiftsDialogProps) => {
	const {
		filterJobRoles,
		filterAllSkills,
		userData,
		datesOfTheWeek,
		allWeekShifts,
		allWorkcenters,
		shiftsByFilter,
		createDraftShiftAsyncStatus,
	} = useSchedulerData();

	const [weekCopyDate, setWeekCopyDate] = useState({
		start: addDays(startOfWeek(new Date()), 1),
		end: addDays(startOfWeek(new Date()), 7),
	});
	const [copyToWeekDate, setCopyToWeekDate] = useState({
		start: addDays(startOfWeek(new Date()), 1),
		end: addDays(startOfWeek(new Date()), 7),
	});
	const [checkedUser, setCheckedUser] = useState(true);
	const [checkAll, setCheckAll] = useState(true);
	const [skillCheckAll, setSkillCheckAll] = useState(true);

	//set object for job id to true
	const checkboxes =
		filterJobRoles.length > 0 &&
		filterJobRoles.reduce((acc: any, key: Job) => {
			acc[key.id] = true;
			return acc;
		}, {});

	const skillCheckboxes =
		filterAllSkills.length > 0 &&
		filterAllSkills.reduce((acc: any, key: Skill) => {
			acc[key.id] = true;
			return acc;
		}, {});

	//set object for job id to false

	const checkboxesUnselect =
		filterJobRoles.length > 0 &&
		filterJobRoles.reduce((acc: any, key: Job) => {
			acc[key.id] = false;
			return acc;
		}, {});

	const skillCheckboxesUnselect =
		filterAllSkills.length > 0 &&
		filterAllSkills.reduce((acc: any, key: Job) => {
			acc[key.id] = false;
			return acc;
		}, {});

	const [jobs, setJobs] = useState(checkboxes);
	const [skills, setSkills] = useState(skillCheckboxes);

	const handleClick = ({ id }: { id: number }) => {
		setJobs({ ...jobs, [id]: !jobs[id] });
		setCheckAll(false);
	};

	const handleSkillClick = ({ id }: { id: number }) => {
		setSkills({ ...skills, [id]: !skills[id] });
		setSkillCheckAll(false);
	};

	const handleCheckAll = (e: boolean) => {
		if (e) setJobs(checkboxes);
		else setJobs(checkboxesUnselect);
		setCheckAll((e) => !e);
	};

	const handleSkillCheckAll = (e: boolean) => {
		if (e) setSkills(skillCheckboxes);
		else setSkills(skillCheckboxesUnselect);
		setSkillCheckAll((e) => !e);
	};

	const createFilterCriteria = (state: Record<number, boolean>): number[] =>
		Object.entries(state)
			.filter(([, value]) => value)
			.map(([key]) => Number(key));

	const filterCriteria = useMemo(() => createFilterCriteria(jobs), [jobs]);
	const skillFilterCriteria = useMemo(
		() => createFilterCriteria(skills),
		[skills]
	);

	const copiedShiftData = useMemo(() => {
		if (!weekCopyDate) return [];
		return allWeekShifts
			?.filter((shift: Shift) => filterCriteria.includes(shift.jobId))
			.map((shift: Shift) => {
				const shiftData = filterJobRoles.find(
					(job: Job) => job.id === shift.jobId
				);
				const workcenterData = allWorkcenters.find(
					(workcenter: WorkCenter) => workcenter.id === shift.workCenterId
				);
				return {
					...shift,
					jobName: shiftData?.name,
					workcenter: workcenterData?.name,
					copyDateStart: weekCopyDate.start,
					copyDateEnd: weekCopyDate.end,
					pasteDateStart: weekPasteDate.startDate,
					pasteDateEnd: weekPasteDate.endDate,
				};
			});
	}, [
		weekCopyDate,
		allWeekShifts,
		filterCriteria,
		filterJobRoles,
		allWorkcenters,
		weekPasteDate,
	]);

	const copiedShiftDataBySkills = useMemo(() => {
		if (!weekCopyDate) return [];
		return allWeekShifts
			?.filter((shift: Shift) =>
				shift.skills.some((skill) =>
					skillFilterCriteria.includes(skill.skillId)
				)
			)
			.map((shift: Shift) => {
				const workcenterData = allWorkcenters.find(
					(workcenter: WorkCenter) => workcenter.id === shift.workCenterId
				);
				return {
					...shift,
					shiftSkills: shift.skills.map((skill: SkillInShift) => ({
						...skill,
						id: skill.skillId,
						name: skill.skillName,
					})),
					jobId: null,
					workcenter: workcenterData?.name,
					copyDateStart: weekCopyDate.start,
					copyDateEnd: weekCopyDate.end,
					pasteDateStart: weekPasteDate.startDate,
					pasteDateEnd: weekPasteDate.endDate,
				};
			});
	}, [
		weekCopyDate,
		allWeekShifts,
		skillFilterCriteria,
		allWorkcenters,
		weekPasteDate,
	]);

	const filteredCopiedShiftData = useMemo(
		() =>
			checkedUser
				? copiedShiftData.filter(
						(shift: Shift) => shift.createdBy === parseInt(userData.id ?? "")
				  )
				: copiedShiftData,
		[checkedUser, copiedShiftData, userData.id]
	);

	const filteredCopiedShiftDataBySkills = useMemo(
		() =>
			checkedUser
				? copiedShiftDataBySkills.filter(
						(shift: Shift) => shift.createdBy === parseInt(userData.id ?? "")
				  )
				: copiedShiftDataBySkills,
		[checkedUser, copiedShiftDataBySkills, userData.id]
	);

	return [
		{
			checkAll,
			skillCheckAll,
			jobs,
			skills,
			checkedUser,
			copiedShiftData: filteredCopiedShiftData,
			copiedShiftDataBySkills: filteredCopiedShiftDataBySkills,
			copyToWeekDate,
			datesOfTheWeek,
			weekCopyDate,
			shiftsByFilter,
			createDraftShiftAsyncStatus,
		},
		{
			setWeekCopyDate,
			setCopyToWeekDate,
			handleCheckAll,
			handleSkillCheckAll,
			handleClick,
			handleSkillClick,
			setCheckedUser,
		},
	];
};

export default useCopyShiftsDialog;
