/* eslint-disable no-mixed-spaces-and-tabs */
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
	getCompanyUsers,
	getRolesForAdmin,
	resetCreateCompanyUser,
	resetUpdateCompanyUser,
	setCompanyUserDialog,
} from "../../../../../../export/gat-admin";
import CompanyUserDialog from "./company-user-dialog";
import useSupervisorsData from "./controllers/use-supervisors-data";
import SupervisorHeader from "./supervisor-header";
import SupervisorTable from "./supervisor-table";

const defaultCompanyUserInfo = {
	username: "",
	firstName: "",
	lastName: "",
	email: "",
	isActive: true,
	roleId: 5,
	workCenterIds: "",
	phoneNumber: "",
	companyId: 0,
};

interface SupervisorsProps {
	companyData: any;
	setWorkCenterData: any;
}

const Supervisors: React.FC<SupervisorsProps> = ({
	companyData,
	setWorkCenterData,
}) => {
	const dispatch = useDispatch();

	const {
		createCompanyUserStatus,
		createCompanyUserErrorMessage,
		updateCompanyUserStatus,
		updateCompanyUserErrorMessage,
	} = useSupervisorsData();

	const [companyUserData, setCompanyUserData] = useState<any>({});
	const [creatingNewCompanyUser, setCreatingNewCompanyUser] = useState(false);
	const [newCompanyUserInfo, setNewCompanyUserInfo] = useState(
		defaultCompanyUserInfo
	);
	const [searchText, setSearchText] = useState("");

	const [
		showCreateNewCompanyUserSuccessMessage,
		setShowCreateNewCompanyUserSuccessMessage,
	] = useState(false);

	useEffect(() => {
		if (creatingNewCompanyUser) {
			dispatch(setCompanyUserDialog(true));
		}
	}, [dispatch, creatingNewCompanyUser]);

	useEffect(() => {
		if (companyData.id) dispatch(getCompanyUsers(companyData.id));
		dispatch(getRolesForAdmin());
	}, [dispatch, companyData.id]);

	useEffect(() => {
		if (createCompanyUserStatus === "fulfilled") {
			setShowCreateNewCompanyUserSuccessMessage(true);
			dispatch(resetCreateCompanyUser());
		}
	}, [createCompanyUserStatus, dispatch]);

	return (
		<>
			<SupervisorHeader
				openDialog={() => setCreatingNewCompanyUser(true)}
				setSearch={setSearchText}
				searchText={searchText}
			/>
			<SupervisorTable
				setCompanyUserData={(value: any) => {
					const newCompanyUserData = { ...value };
					delete newCompanyUserData.changeCompanyUserData;
					setCompanyUserData(newCompanyUserData);
					dispatch(setCompanyUserDialog(true));
				}}
				setWorkCenterData={setWorkCenterData}
				searchText={searchText}
			/>

			<CompanyUserDialog
				creatingNewCompanyUser={creatingNewCompanyUser}
				companyUserData={
					creatingNewCompanyUser
						? { ...newCompanyUserInfo, companyId: companyData.id }
						: {
								...companyUserData,
								companyId: companyData.id,
								roleId:
									companyUserData.roleId === undefined
										? Object.prototype.hasOwnProperty.call(
												companyUserData,
												"roles"
										  )
											? companyUserData.roles[0].id
											: "5"
										: companyUserData.roleId,
								workCenterIds:
									companyUserData.workCenterIds === undefined ||
									companyUserData.workCenterIds === ""
										? companyUserData.userWorkCenters
											? companyUserData.userWorkCenters
													.map((wc: any) => wc.id)
													.toString()
											: ""
										: companyUserData.workCenterIds,
						  }
				}
				setCompanyUserData={(newValue: any) =>
					creatingNewCompanyUser
						? setNewCompanyUserInfo(newValue)
						: setCompanyUserData(newValue)
				}
				onClose={() => {
					dispatch(setCompanyUserDialog(false));
					setNewCompanyUserInfo(defaultCompanyUserInfo);
					setCompanyUserData({});

					// To handle UI glitch of showing Create button on cancel
					setTimeout(() => {
						setCreatingNewCompanyUser(false);
					}, 500);
				}}
			/>

			<Snackbar
				open={createCompanyUserStatus === "error"}
				autoHideDuration={3000}
				onClose={() => {
					dispatch(resetCreateCompanyUser());
				}}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<MuiAlert elevation={6} variant='filled' severity='error'>
					{createCompanyUserErrorMessage ??
						"An Error Occurred. Please try again."}
				</MuiAlert>
			</Snackbar>
			<Snackbar
				open={showCreateNewCompanyUserSuccessMessage}
				autoHideDuration={3000}
				onClose={() => {
					setCreatingNewCompanyUser(false);
					setNewCompanyUserInfo(defaultCompanyUserInfo);
					setShowCreateNewCompanyUserSuccessMessage(false);
					dispatch(resetCreateCompanyUser());
					dispatch(setCompanyUserDialog(false));
					//AVOID API CALL on SNACKBAR CLOSE, Unnecessary delay of call, try useEffect instead
					// dispatch(getCompanyUsers(companyData.id));
				}}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<MuiAlert elevation={6} variant='filled' severity='success'>
					{`New supervisor "${newCompanyUserInfo.firstName}" added!`}
				</MuiAlert>
			</Snackbar>
			<Snackbar
				open={updateCompanyUserStatus === "error"}
				autoHideDuration={3000}
				onClose={() => {
					dispatch(resetUpdateCompanyUser());
				}}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<MuiAlert elevation={6} variant='filled' severity='error'>
					{updateCompanyUserErrorMessage ??
						"An Error Occurred. Please try again."}
				</MuiAlert>
			</Snackbar>
			<Snackbar
				open={updateCompanyUserStatus === "fulfilled"}
				autoHideDuration={3000}
				onClose={() => {
					setCompanyUserData({});
					dispatch(resetUpdateCompanyUser());
					dispatch(setCompanyUserDialog(false));
					//AVOID API CALL on SNACKBAR CLOSE, Unnecessary delay of call, try useEffect instead
					// dispatch(getCompanyUsers(companyData.id));
				}}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<MuiAlert elevation={6} variant='filled' severity='success'>
					{"Supervisor info updated!"}
				</MuiAlert>
			</Snackbar>
		</>
	);
};

export default Supervisors;
