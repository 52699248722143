import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    actionBar: {
        marginTop: 8,
        marginBottom: 8,
    },
    btnLabel: {
        position: "relative",
    },
    progressIndicator: {
        position: "absolute",
        left: "50%",
    },
    dataGridColumn: {
        "& .MuiDataGrid-columnSeparator--sideRight": {
            display: "none !important",
        },
        border: "none !important",
    },
}));
