import { Box, Switch, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React from "react";

import { SkillRepresentationCell } from "../../../../../../../export/cross-skilling";
import {
	getLevelObj,
	getRepLevel,
	getRepType,
} from "../../../../../../../export/helpers";

const ShiftBySkills = ({
	skillLevels,
	newAddOnSettings,
	setNewAddOnSettings,
}: any) => {
	const crossSkillingEnabled = newAddOnSettings.crossSkillingEnabled;
	const shiftBySkillsEnabled = newAddOnSettings.shiftBySkillsEnabled;

	// using due to different behavior of skill representation icon in select item
	const SkillLevelSelectItem = ({ value, allSkillLevels }: any) => {
		if (value >= 0) {
			const {
				levelValue,
				repLevel,
				backgroundColor,
				foregroundColor,
				repType,
				name,
			} = getLevelObj(value, allSkillLevels);
			return (
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						gap: "10px",
					}}
				>
					<SkillRepresentationCell
						level={value}
						value={levelValue}
						representationType={repType}
						representationLevel={repLevel}
						backgroundColor={backgroundColor}
						foregroundColor={foregroundColor}
						border={1}
						height={10}
						width={10}
						backgroundWidth={"35px"}
						backgroundHeight={"35px"}
						inSelect={true}
					/>
					<Typography>{name}</Typography>
				</Box>
			);
		} else {
			return <></>;
		}
	};
	return (
		<Box
			sx={{
				marginTop: "20px",
				marginBottom: "8px",
				borderRadius: "8px",
				border: 1,
				borderWidth: "1px",
				borderColor: "rgba(0,0,0,0.12)",
				background: "#FFF",
				padding: "16px",
				opacity: !crossSkillingEnabled || skillLevels.length <= 0 ? 0.5 : 1,
			}}
		>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					padding: "0px 16px",
					alignItems: "center",
				}}
			>
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontWeight: 400,
						fontSize: "16px",
						color: "rgba(0, 0, 0, 0.87)",
					}}
				>
					Allow supervisors to publish flex shifts by skills
				</Typography>
				<Switch
					checked={shiftBySkillsEnabled ?? false}
					disabled={!crossSkillingEnabled || skillLevels.length === 0}
					onChange={(e) => {
						setNewAddOnSettings((pre: any) => {
							return {
								...pre,
								shiftBySkillsEnabled: e.target.checked,
							};
						});
						if(!e.target.checked){
							setNewAddOnSettings((pre: any) => {
								return {
									...pre,
									shiftBySkillsMinSkillLevel: 0,
								};
							});
						}
					}}
				/>
			</Box>
			{shiftBySkillsEnabled && (
				<Box>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 500,
							fontSize: "14px",
							color: "rgba(0, 0, 0, 0.87)",
							padding: "0px 16px",
							marginTop: "8px",
						}}
					>
						Default skill level required for a worker to pick up a shift by
						skill
					</Typography>
					<FormControl
						variant='filled'
						sx={{ margin: "12px", minWidth: 415 }}
					>
						<InputLabel id='default-skill-level'>
							Default skill level*
						</InputLabel>
						<Select
							labelId='default-skill-level'
							id='skill-level'
							disabled={!crossSkillingEnabled || !shiftBySkillsEnabled}
							value={parseInt(newAddOnSettings.shiftBySkillsMinSkillLevel ?? 0)}
							renderValue={(skillLevel: number) => {
								if (skillLevel === -1) {
									return (
										<Typography sx={{ padding: "5px" }}>AnyLevel</Typography>
									);
								} else {
									return (
										<SkillLevelSelectItem
											value={skillLevel}
											allSkillLevels={skillLevels}
										/>
									);
								}
							}}
							onChange={(e) => {
								setNewAddOnSettings({
									...newAddOnSettings,
									shiftBySkillsMinSkillLevel: e.target.value,
								});
							}}
						>
							{/* <MenuItem value={-1}>
							<Typography sx={{ padding: "5px" }}>Any Level</Typography>
						</MenuItem> */}
							{skillLevels.map((skillLevel: any) => {
								return (
									<MenuItem value={skillLevel.level} key={skillLevel.id}>
										<Box
											sx={{
												display: "flex",
												gap: "10px",
												alignItems: "center",
											}}
										>
											<SkillRepresentationCell
												level={skillLevel.level}
												value={skillLevel.value}
												representationType={getRepType(skillLevel.repCode)}
												representationLevel={getRepLevel(
													skillLevel.repCode,
													skillLevel.level
												)}
												backgroundColor={skillLevel.backColorCode}
												foregroundColor={skillLevel.foreColorCode}
												border={1}
												height={20}
												width={20}
												inSelect={true}
											/>
											<Typography>{skillLevel.name}</Typography>
										</Box>
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
				</Box>
			)}
		</Box>
	);
};

export default ShiftBySkills;
