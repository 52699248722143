// TODO - Type 'any' needs to be fixed.
import {Box, Collapse, Grid, LinearProgress} from "@mui/material";
// import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { JOB, SKILLS } from "../../../../assets/constants";
import AvailabilityMap from "../../../../features/Availability/AvailabilityMap";
import AvailabilityFilters from "../../../../features/scheduler/availability/availability-filters";
import { getHourlyWorkerCount } from "../../store/scheduler-slice";
// import { Job } from "../../store/types";

interface IAvailabilityProps {
  shiftBySkills: boolean;
  shiftsByFilter: any;
  changeWeekAvailabilityStatus: string;
  checked: boolean;
  height: number;
}
const Availability: React.FC<IAvailabilityProps> = ({
  shiftBySkills,
  shiftsByFilter,
  changeWeekAvailabilityStatus,
  checked,
  height,
}) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(1);
  // const allJobRoles = useSelector(
  //   (state: any) => state.supervisor.masterData.allJobRoles
  // );
  // const allSkills = useSelector(
  //   (state: any) => state.supervisor.masterData.allSkills
  // );
    const allJobRoles = useSelector(
      (state: any) => state.supervisor.filterData.allJobRoles
    );
    const allSkills = useSelector(
      (state: any) => state.supervisor.filterData.allSkills
    );
  const currentDate = useSelector(
    (state: any) => state.supervisor?.schedulerData?.currentDate
  );
  // const fetchInitialDataStatus = useSelector(
  //   (state: any) => state.supervisor?.fetchInitialData.status
  // );
  const fetchFilterAndShiftDataStatus = useSelector(
    (state: any) => state.supervisor?.fetchFilterAndShiftData.status
  );
  useEffect(() => {
    if (
      // fetchInitialDataStatus === "fulfilled" &&
      fetchFilterAndShiftDataStatus === "fulfilled"
    ) {
      if (shiftsByFilter === JOB) {
        dispatch(
          getHourlyWorkerCount({
            date: currentDate,
            type: "job",
          })
        );
      } else if (shiftsByFilter === SKILLS) {
        dispatch(
          getHourlyWorkerCount({
            date: currentDate,
            type: "skill",
          })
        );
      }
    }
  }, [
    shiftsByFilter,
    dispatch,
    currentDate,
    // fetchInitialDataStatus,
    fetchFilterAndShiftDataStatus,
  ]);

  if (
    // fetchInitialDataStatus === "pending" ||
    fetchFilterAndShiftDataStatus === "pending"
  ) {
    return (
        <Box
            sx={{
                position: "relative",
                width: "100%",
                height: height,
            }}
        >
            <LinearProgress />
      </Box>
    );
  } else {
    return (
        <Grid container wrap="nowrap" spacing={0}>
            <Collapse orientation="horizontal" in={checked}>
                <Grid item xs={3} lg={2} md={4} sm={3}>
                    <Box>
                        <AvailabilityFilters
                            shiftBySkills={shiftBySkills}
                            shiftsByFilter={shiftsByFilter}
                            value={value}
                            setValue={setValue}
                            height={height}
                            allJobRoles={allJobRoles}
                            fetchFilterAndShiftDataStatus={fetchFilterAndShiftDataStatus}
                            allSkills={allSkills}
                        />
                    </Box>
                </Grid>
            </Collapse>
            <Grid
                item
                xs
                lg
                md
                sm
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    height: height,
                }}
            >
                {/* Filler dividers for border Radius - devexpress bug */}
                {!checked && (
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Divider
                            sx={{
                                marginTop: "57px",
                                width: "12px",
                            }}
                        />
                    </Box>
                )}
                {/* End of workaround */}
                <Box
                    sx={{
                        borderRadius: "15px",
                        width: "100%",
                    }}
                >
                    <AvailabilityMap
                        shiftsByFilter={shiftsByFilter}
                        value={value}
                        setValue={setValue}
                        changeWeekAvailabilityStatus={changeWeekAvailabilityStatus}
                        height={height}
                    />
                </Box>
                {/* Filler dividers for border Radius - devexpress bug */}
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Divider
                        sx={{
                            marginTop: "57px",
                            width: "12px",
                        }}
                    />
                </Box>
                {/* End of workaround */}
            </Grid>
        </Grid>
    );
  }
};

export default Availability;
