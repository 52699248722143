import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, Grid, Radio, TextField, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
	createNewWorkCenter,
	updateWorkCenterData,
} from "../../../../../../export/gat-admin";
import { CompanyLocation } from "../../../store/gat-admin-slice-types";

interface IWorkCenterProps {
	creatingNewWorkCenter: boolean
	workCenterData: any
	setWorkCenterData: React.Dispatch<React.SetStateAction<any>>
	onClose: () => void;
}
const WorkCenterDialog: React.FC<IWorkCenterProps> = ({
	creatingNewWorkCenter,
	workCenterData,
	setWorkCenterData,
	onClose,
}) => {

	const dispatch = useDispatch();
	const [initialWorkCenterData, setInitialWorkCenterData] = useState({});
	const createNewWorkCenterStatus = useSelector(
		(state: any) => state.gatAdminConfiguration.createNewWorkCenter.status
	);
	const updateWorkCenterStatus = useSelector(
		(state: any) => state.gatAdminConfiguration.updateWorkCenterData.status
	);
	const openWorkCenterDialog = useSelector(
		(state: any) => state.gatAdminConfiguration.openWorkCenterDialog
	);
	const locations = useSelector(
		(state: any) => state.gatAdminConfiguration.locations
	);

	useEffect(() => {
		if (!Object.prototype.hasOwnProperty.call(workCenterData, "locationId")) {
			if (workCenterData.locationId !== 0 && locations) {
				locations.forEach((location: CompanyLocation) => {
					location.workCenters.forEach((wc) => {
						if (wc.id === workCenterData.id) {
							const workCenterDataWithLocationId = {
								...workCenterData,
								locationId: location.id,
							};
							setWorkCenterData(workCenterDataWithLocationId);
							if (
								JSON.stringify(initialWorkCenterData) === "{}" &&
								JSON.stringify(workCenterDataWithLocationId) !== "{}"
							) {
								setInitialWorkCenterData(workCenterDataWithLocationId);
							}
						}
					});
				});
			}
		}
	}, [workCenterData, locations, setWorkCenterData, initialWorkCenterData]);

	const closeWorkCenterDialog = () => {
		setInitialWorkCenterData({});
		onClose();
	};

	return (
		<Dialog
			onClose={() => closeWorkCenterDialog()}
			aria-labelledby='add-new-work-center'
			open={openWorkCenterDialog}
			maxWidth={false}
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<DialogTitle
				id='work-center-dialog-title'
				sx={{
					width: "900px",
				}}
			>
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontSize: "20px",
						fontWeight: 500,
						color: "rgba(0, 0, 0, 0.87)",
						paddingTop: "29px",
						paddingLeft: "21px",
						paddingBottom: "9px",
						paddingRight: "21px",
					}}
				>
					Workcenter
				</Typography>
			</DialogTitle>
			<Divider />
			<DialogContent>
				<Grid
					container
					sx={{
						paddingTop: "12px",
						paddingLeft: "21px",
						paddingRight: "21px",
						paddingBottom: "16px",
					}}
				>
					<Grid item xs={8}>
						<TextField
							label='Workcenter (required)'
							value={workCenterData.name ?? ""}
							inputProps={{ maxLength: 50 }}
							onChange={(e) =>
								setWorkCenterData({ ...workCenterData, name: e.target.value })
							}
							variant='filled'
							fullWidth
						/>
					</Grid>
				</Grid>
				<Grid
					container
					sx={{
						paddingTop: "16px",
						paddingLeft: "21px",
						paddingRight: "21px",
						paddingBottom: "16px",
					}}
				>
					<Grid item xs={12}>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: "flex-start",
								justifyContent: "center",
							}}
						>
							<Typography
								sx={{
									fontFamily: "Roboto",
									fontWeight: 400,
									fontSize: "16px",
									color: "rgba(0, 0, 0, 0.6)",
								}}
							>
								Locations (required)
							</Typography>
							{locations &&
								locations.map((location: CompanyLocation) => (
									<Box
										key={`company-location-${location.id}`}
										sx={{
											display: "flex",
											flexDirection: "row",
											alignItems: "center",
											justifyContent: "center",
										}}
									>
										<Radio
											checked={
												parseInt(workCenterData.locationId) ===
												(location.id)
											}
											onChange={(e) => {
												setWorkCenterData({
													...workCenterData,
													locationId: e.target.value,
												});
											}}
											value={location.id}
											name={`company-location-radio-buttons-${location.id}`}
											inputProps={{ "aria-label": location.name }}
										/>
										<Typography
											sx={{
												fontFamily: "Roboto",
												fontWeight: 400,
												fontSize: "16px",
												color: "rgba(0, 0, 0, 0.87)",
											}}
										>
											{location.name}
										</Typography>
									</Box>
								))}
						</Box>
					</Grid>
				</Grid>
			</DialogContent>
			<Divider />
			<DialogActions>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-end",
						marginTop: "16px",
						marginBottom: "16px",
						marginRight: "21px",
					}}
				>
					<Button variant='outlined' onClick={() => closeWorkCenterDialog()}>
						Cancel
					</Button>
					<LoadingButton
						variant='contained'
						disabled={
							workCenterData.name === "" ||
							workCenterData.locationId === 0 ||
							JSON.stringify(workCenterData) ===
							JSON.stringify(initialWorkCenterData)
						}
						sx={{ marginLeft: "16px" }}
						loading={
							createNewWorkCenterStatus === "pending" ||
							updateWorkCenterStatus === "pending"
						}
						onClick={() => {
							if (creatingNewWorkCenter) {
								dispatch(createNewWorkCenter(workCenterData));
							} else {
								dispatch(updateWorkCenterData(workCenterData));
							}
						}}
						loadingIndicator={
							<CircularProgress
								size={16}
								sx={{ color: "rgba(0, 0, 0, 0.38)" }}
							/>
						}
					>
						{creatingNewWorkCenter ? "CREATE" : "UPDATE"}
					</LoadingButton>
				</Box>
			</DialogActions>
		</Dialog>
	);
};

export default WorkCenterDialog;
