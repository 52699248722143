import { Box, Tooltip, Typography } from "@mui/material";
import React from "react";

import Highlight from "../../../../Highlight";

interface ColumnSkillHeaderProps {
    skill: {
        name: string;
    };
    searchText: string;
}

const ColumnSkillHeader: React.FC<ColumnSkillHeaderProps> = ({ skill, searchText }) => {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                height: "100%",
                width: "100%",
                paddingTop: "8px",
                paddingBottom: "8px",
            }}
        >
            <Tooltip title={skill.name}>
                <Typography
                    sx={{ wordBreak: "break-word" }}
                    whiteSpace="normal"
                    style={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        padding: "4px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: "2",
                        WebkitBoxOrient: "vertical",
                        marginLeft: "10px",
                    }}
                >
                    <Highlight value={skill.name} searchText={searchText} />
                </Typography>
            </Tooltip>
        </Box>
    );
};

export default ColumnSkillHeader;
