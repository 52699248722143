import { Alert, Box, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { differenceInHours, differenceInMinutes } from "date-fns";
import { format } from "date-fns";
import React from "react";

import useWorkerRequestsData from "../../controllers/use-worker-requests-data";

interface WorkerRequestDetailProps {
	handleShiftCoverage: ((value: boolean) => void) | undefined;
	isShiftCoverage: boolean | undefined;
}

const WorkerRequestDetail = ({
	handleShiftCoverage,
	isShiftCoverage,
}: WorkerRequestDetailProps) => {
	const { workerRequestMetaData, attendanceManagement } =
		useWorkerRequestsData();

	const name = workerRequestMetaData?.name;
	const requestType = workerRequestMetaData?.requestType;
	const requestCode = workerRequestMetaData?.requestCode;
	const leaveType = attendanceManagement
		? ` (${workerRequestMetaData?.leaveType}) `
		: " ";
	const startDateTime = workerRequestMetaData?.startDateTime;
	const endDateTime = workerRequestMetaData?.endDateTime;
	const workerComment = workerRequestMetaData?.workerComment;
	const shiftDetails = workerRequestMetaData?.shiftDetails;

	const date = format(new Date(startDateTime), "d LLL yyyy");
	const endTime = format(new Date(endDateTime), "h.mm a");
	const startTime = format(new Date(startDateTime), "h.mm a");

	const shiftDuration = differenceInMinutes(
		new Date(endDateTime),
		new Date(startDateTime)
	);
	const hours = Math.floor(shiftDuration / 60);
	const minutes = shiftDuration % 60;
	const shiftDurationString =
		minutes > 0 ? `${hours}h ${minutes}m` : `${hours} hours`;
	const isSwapRequest = requestCode === "ss";

	return (
		<Box width='100%' height={!isShiftCoverage ? 400 : "auto"}>
			<Alert severity='info'>
				{name} requested {requestType}
				{leaveType}on {shiftDetails} ({date}, {startTime} - {endTime}) for{" "}
				{shiftDurationString}.
			</Alert>
			<Box mt={3} mb={3}>
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontWeight: 500,
						fontSize: "14px",
						lineHeight: "21px",
						color: "#000000",
					}}
				>
					Worker Comment
				</Typography>
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontWeight: 400,
						fontSize: "14px",
						lineHeight: "21px",
						color: "#000000",
					}}
				>
					{workerComment ?? "-"}
				</Typography>
			</Box>
			<Typography
				sx={{
					fontFamily: "Roboto",
					fontWeight: 500,
					fontSize: "14px",
					lineHeight: "21px",
					color: isSwapRequest ? "#00000061" : "#000000",
				}}
			>
				Do you want to post a shift to get coverage ?
			</Typography>
			{isSwapRequest ? (
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontWeight: 500,
						fontSize: "12px",
						lineHeight: "21px",
						color: " #00000099",
					}}
				>
					Shift swap request will always post a coverage shift.
				</Typography>
			) : null}
			<FormControl>
				<RadioGroup
					aria-labelledby='demo-controlled-radio-buttons-group'
					name='controlled-radio-buttons-group'
					value={isShiftCoverage ? "yes" : "no"}
					onChange={() =>
						handleShiftCoverage && handleShiftCoverage(!isShiftCoverage)
					}
					sx={{ display: "flex", flexDirection: "row" }}
				>
					<FormControlLabel
						value='yes'
						control={<Radio />}
						label='Yes'
						disabled={isSwapRequest}
					/>
					<FormControlLabel
						value='no'
						control={<Radio />}
						label='No'
						disabled={isSwapRequest}
					/>
				</RadioGroup>
			</FormControl>
		</Box>
	);
};

export default WorkerRequestDetail;
