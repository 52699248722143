import { ArrowBack } from "@mui/icons-material";
import { Divider, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";

import { CompanyLocation } from "../../../store/gat-admin-slice-types";

interface ILocationSettingsHeaderProps {
	creatingNewLocation: boolean
	locationData: CompanyLocation
	closeLocationForm: any
}
const LocationSettingsHeader: React.FC<ILocationSettingsHeaderProps> = ({
	creatingNewLocation,
	locationData,
	closeLocationForm,
}) => {

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
			}}
		>
			<Box sx={{ padding: "20px" }}>
				<Box
					onClick={closeLocationForm}
					sx={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						cursor: "pointer",
					}}
				>
					<ArrowBack sx={{ height: "20px" }} />
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 400,
							fontSize: "16px",
							color: "rgba(0, 0, 0, 0.6)",
							marginLeft: "8px",
						}}
					>
						Go back
					</Typography>
				</Box>
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontWeight: 400,
						fontSize: "24px",
						color: "rgba(0, 0, 0, 0.87)",
						marginTop: "8px",
					}}
				>
					{creatingNewLocation ? "New Location" : locationData.name}
				</Typography>
			</Box>
			<Divider />
		</Box>
	);
};

export default LocationSettingsHeader;
