export const PERMISSIONS = {
  FLEX_SCHEDULER: {
    VIEW: "flex_scheduler_view", // true or false
    CREATE_SHIFT: "flex_scheduler_create_shift", // true or false
    MARK_ATTENDANCE: "flex_scheduler_mark_attendance", // true or false
    EDIT_DRAFT_SHIFT: "flex_scheduler_edit_draft_shift", // true of false
    EDIT_PUBLISHED_SHIFT: "flex_scheduler_edit_published_shift", // true or false
    PUBLISH_SHIFT: "flex_scheduler_publish_shift", // true or false
    DELETE_DRAFT_SHIFT: "flex_scheduler_delete_draft_shift", // true or false
    CANCEL_PUBLISHED_SHIFT: "flex_scheduler_cancel_published_shift", // true or false
    COPY_SHIFT: "flex_scheduler_copy_shift", // true or false
    VIEW_SHIFT_DETAILS: "flex_scheduler_view_shift_details", // true or false
    BROADCAST_MESSAGE: "flex_scheduler_broadcast_message", // true or false
    RENOTIFY_WORKERS: "flex_scheduler_renotify_workers", // true or false
    MANUAL_ASSIGN_WORKERS: "flex_scheduler_manual_assign_workers", // true or false
  },
  CROSS_SKILLING: {
    VIEW: "cross_skilling_view", // true or false
    VIEW_SUMMARY: "cross_skilling_view_summary", // true or false
    UPDATE_SKILL_LEVEL: "cross_skilling_update_skill_level", // true or false
    ACCEPT_INTEREST: "cross_skilling_accept_interest", // true or false
  },
  WORKER_REQUESTS: {
    VIEW: "worker_requests_view", // true or false
    REGULAR_SHIFT_REQUEST: "worker_requests_regular_shift_request", // true or false
    OVERTIME_SHIFT_REQUEST: "worker_requests_overtime_shift_request", // true or false
    CREATE_COVERAGE_SHIFT: "worker_requests_create_coverage_shift", // true or false
    ACCEPT_REJECT_REQUEST: "worker_requests_accept_reject_request", // true or false
  },
  REPORTS: {
    VIEW: "reports_view", // true or false
    VIEW_ATTENDANCE_REPORTS: "reports_view_attendance_reports", // true or false
    MARK_ATTENDANCE: "reports_mark_attendance", // true or false
    VIEW_SKILLS_SUMMARY_REPORTS: "reports_view_skills_summary_reports", // true or false
    VIEW_ABSENCE_REPORTS: "reports_view_absence_reports", // true or false
  },
  SETTINGS: {
    VIEW: "settings_view", // true or false
    VIEW_WORKERS: "settings_view_workers", // true or false
    ADD_WORKERS: "settings_add_workers", // true or false
    EDIT_WORKERS: "settings_edit_workers", // true or false
    DELETE_WORKERS: "settings_delete_workers", // true or false
    VIEW_JOB_ROLES: "settings_view_job_roles", // true or false
    ADD_JOB_ROLES: "settings_add_job_roles", // true or false
    EDIT_JOB_ROLES: "settings_edit_job_roles", // true or false
    VIEW_SKILLS: "settings_view_skills", // true or false
    ADD_SKILLS: "settings_add_skills", // true or false
    EDIT_SKILLS: "settings_edit_skills", // true or false
    MARK_SKILL_OBSOLETE: "settings_mark_skill_obsolete", // true or false
    VIEW_CERTIFICATIONS: "settings_view_certifications", // true or false
    ADD_CERTIFICATIONS: "settings_add_certifications", // true or false
    EDIT_CERTIFICATIONS: "settings_edit_certifications", // true or false
    MARK_CERTIFICATION_OBSOLETE: "settings_mark_certification_obsolete", // true or false
    VIEW_WORKCENTER: "settings_view_workcenter", // true or false
  },
  ADMIN: {
    VIEW: "admin_view", // true or false
  },
};

// Role object for ShiftSupervisor with all true except ADMIN
export const ShiftSupervisor = {
    FLEX_SCHEDULER: {
        VIEW: true,
        VIEW_AVAILABILITY: true,
        CREATE_SHIFT: true,
        MARK_ATTENDANCE: true,
        EDIT_DRAFT_SHIFT: true,
        EDIT_PUBLISHED_SHIFT: true,
        PUBLISH_SHIFT: true,
        DELETE_DRAFT_SHIFT: true,
        CANCEL_PUBLISHED_SHIFT: true,
        COPY_SHIFT: true,
        VIEW_SHIFT_DETAILS: true,
        BROADCAST_MESSAGE: true,
        RENOTIFY_WORKERS: true,
        MANUAL_ASSIGN_WORKERS: true,
    },
    CROSS_SKILLING: {
        VIEW: true,
        VIEW_SUMMARY: true,
        UPDATE_SKILL_LEVEL: true,
        ACCEPT_INTEREST: true,
    },
    WORKER_REQUESTS: {
        VIEW: true,
        REGULAR_SHIFT_REQUEST: true,
        OVERTIME_SHIFT_REQUEST: true,
        CREATE_COVERAGE_SHIFT: true,
        ACCEPT_REJECT_REGULAR_REQUEST: true,
        ACCEPT_REJECT_OVERTIME_REQUEST: true,
    },
    REPORTS: {
        VIEW: true,
        VIEW_ATTENDANCE_REPORTS: true,
        MARK_ATTENDANCE: true,
        VIEW_SKILLS_SUMMARY_REPORTS: true,
        VIEW_ABSENCE_REPORTS: true,
    },
    SETTINGS: {
        VIEW: true,
        VIEW_WORKERS: true,
        ADD_WORKERS: true,
        EDIT_WORKERS: true,
        DELETE_WORKERS: true,
        VIEW_JOB_ROLES: true,
        ADD_JOB_ROLES: true,
        EDIT_JOB_ROLES: true,
        VIEW_SKILLS: true,
        ADD_SKILLS: true,
        EDIT_SKILLS: true,
        MARK_SKILL_OBSOLETE: true,
        VIEW_CERTIFICATIONS: true,
        ADD_CERTIFICATIONS: true,
        EDIT_CERTIFICATIONS: true,
        MARK_CERTIFICATION_OBSOLETE: true,
        VIEW_WORKCENTER: true,
    },
    ADMIN: {
        VIEW: false,
    },
};

// Role object for GATAdmin with all permissions set to true
export const GATAdmin = {
    FLEX_SCHEDULER: {
        VIEW: true,
        VIEW_AVAILABILITY: true,
        CREATE_SHIFT: true,
        MARK_ATTENDANCE: true,
        EDIT_DRAFT_SHIFT: true,
        EDIT_PUBLISHED_SHIFT: true,
        PUBLISH_SHIFT: true,
        DELETE_DRAFT_SHIFT: true,
        CANCEL_PUBLISHED_SHIFT: true,
        COPY_SHIFT: true,
        VIEW_SHIFT_DETAILS: true,
        BROADCAST_MESSAGE: true,
        RENOTIFY_WORKERS: true,
        MANUAL_ASSIGN_WORKERS: true,
    },
    CROSS_SKILLING: {
        VIEW: true,
        VIEW_SUMMARY: true,
        UPDATE_SKILL_LEVEL: true,
        ACCEPT_INTEREST: true,
    },
    WORKER_REQUESTS: {
        VIEW: true,
        REGULAR_SHIFT_REQUEST: true,
        OVERTIME_SHIFT_REQUEST: true,
        CREATE_COVERAGE_SHIFT: true,
        ACCEPT_REJECT_REGULAR_REQUEST: true,
        ACCEPT_REJECT_OVERTIME_REQUEST: true,
    },
    REPORTS: {
        VIEW: true,
        VIEW_ATTENDANCE_REPORTS: true,
        MARK_ATTENDANCE: true,
        VIEW_SKILLS_SUMMARY_REPORTS: true,
        VIEW_ABSENCE_REPORTS: true,
    },
    SETTINGS: {
        VIEW: true,
        VIEW_WORKERS: true,
        ADD_WORKERS: true,
        EDIT_WORKERS: true,
        DELETE_WORKERS: true,
        VIEW_JOB_ROLES: true,
        ADD_JOB_ROLES: true,
        EDIT_JOB_ROLES: true,
        VIEW_SKILLS: true,
        ADD_SKILLS: true,
        EDIT_SKILLS: true,
        MARK_SKILL_OBSOLETE: true,
        VIEW_CERTIFICATIONS: true,
        ADD_CERTIFICATIONS: true,
        EDIT_CERTIFICATIONS: true,
        MARK_CERTIFICATION_OBSOLETE: true,
        VIEW_WORKCENTER: true,
    },
    ADMIN: {
        VIEW: true,
    },
};

// Role object for ReadOnly with only view permissions
export const ReadOnly = {
    FLEX_SCHEDULER: {
        VIEW: true,
        VIEW_AVAILABILITY: true,
        CREATE_SHIFT: false,
        MARK_ATTENDANCE: false,
        EDIT_DRAFT_SHIFT: false,
        EDIT_PUBLISHED_SHIFT: false,
        PUBLISH_SHIFT: false,
        DELETE_DRAFT_SHIFT: false,
        CANCEL_PUBLISHED_SHIFT: false,
        COPY_SHIFT: false,
        VIEW_SHIFT_DETAILS: true,
        BROADCAST_MESSAGE: false,
        RENOTIFY_WORKERS: false,
        MANUAL_ASSIGN_WORKERS: false,
    },
    CROSS_SKILLING: {
        VIEW: true,
        VIEW_SUMMARY: true,
        UPDATE_SKILL_LEVEL: false,
        ACCEPT_INTEREST: false,
    },
    WORKER_REQUESTS: {
        VIEW: true,
        REGULAR_SHIFT_REQUEST: false,
        OVERTIME_SHIFT_REQUEST: false,
        CREATE_COVERAGE_SHIFT: false,
        ACCEPT_REJECT_REGULAR_REQUEST: false,
        ACCEPT_REJECT_OVERTIME_REQUEST: false,
    },
    REPORTS: {
        VIEW: true,
        VIEW_ATTENDANCE_REPORTS: true,
        MARK_ATTENDANCE: false,
        VIEW_SKILLS_SUMMARY_REPORTS: true,
        VIEW_ABSENCE_REPORTS: true,
    },
    SETTINGS: {
        VIEW: true,
        VIEW_WORKERS: true,
        ADD_WORKERS: false,
        EDIT_WORKERS: false,
        DELETE_WORKERS: false,
        VIEW_JOB_ROLES: true,
        ADD_JOB_ROLES: false,
        EDIT_JOB_ROLES: false,
        VIEW_SKILLS: true,
        ADD_SKILLS: false,
        EDIT_SKILLS: false,
        MARK_SKILL_OBSOLETE: false,
        VIEW_CERTIFICATIONS: true,
        ADD_CERTIFICATIONS: false,
        EDIT_CERTIFICATIONS: false,
        MARK_CERTIFICATION_OBSOLETE: false,
        VIEW_WORKCENTER: true,
    },
    ADMIN: {
        VIEW: false,
    },
};

// Role object for LimitedAccessSupervisor
export const LimitedAccessSupervisor = {
    FLEX_SCHEDULER: {
        VIEW: true,
        VIEW_AVAILABILITY: true,
        CREATE_SHIFT: true,
        MARK_ATTENDANCE: true,
        EDIT_DRAFT_SHIFT: true,
        EDIT_PUBLISHED_SHIFT: true,
        PUBLISH_SHIFT: true,
        DELETE_DRAFT_SHIFT: true,
        CANCEL_PUBLISHED_SHIFT: true,
        COPY_SHIFT: true,
        VIEW_SHIFT_DETAILS: true,
        BROADCAST_MESSAGE: true,
        RENOTIFY_WORKERS: true,
        MANUAL_ASSIGN_WORKERS: true,
    },
    CROSS_SKILLING: {
        VIEW: true,
        VIEW_SUMMARY: true,
        UPDATE_SKILL_LEVEL: false,
        ACCEPT_INTEREST: false,
    },
    WORKER_REQUESTS: {
        VIEW: true,
        REGULAR_SHIFT_REQUEST: true,
        OVERTIME_SHIFT_REQUEST: true,
        CREATE_COVERAGE_SHIFT: true,
        ACCEPT_REJECT_REGULAR_REQUEST: true,
        ACCEPT_REJECT_OVERTIME_REQUEST: true,
    },
    REPORTS: {
        VIEW: true,
        VIEW_ATTENDANCE_REPORTS: true,
        MARK_ATTENDANCE: true,
        VIEW_SKILLS_SUMMARY_REPORTS: true,
        VIEW_ABSENCE_REPORTS: true,
    },
    SETTINGS: {
        VIEW: true,
        VIEW_WORKERS: true,
        ADD_WORKERS: false,
        EDIT_WORKERS: false,
        DELETE_WORKERS: false,
        VIEW_JOB_ROLES: true,
        ADD_JOB_ROLES: false,
        EDIT_JOB_ROLES: false,
        VIEW_SKILLS: true,
        ADD_SKILLS: false,
        EDIT_SKILLS: false,
        MARK_SKILL_OBSOLETE: false,
        VIEW_CERTIFICATIONS: true,
        ADD_CERTIFICATIONS: false,
        EDIT_CERTIFICATIONS: false,
        MARK_CERTIFICATION_OBSOLETE: false,
        VIEW_WORKCENTER: true,
    },
    ADMIN: {
        VIEW: false,
    },
};