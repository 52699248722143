import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { DataGridPro } from "@mui/x-data-grid-pro";
import React from "react";

import useSchedulerData from "../../../controllers/use-scheduler-data";
import useBmessageColumns from "./controllers/use-bmessage-columns";
import { Data } from "./types";

const BMessageHistory = () => {
	const { broadcastHistoryData, broadcastHistoryStatus } = useSchedulerData();
	const { columns } = useBmessageColumns();

	const rows = broadcastHistoryData.map((item: Data, idx: number) => {
		return { id: idx, ...item };
	});

	return (
		<Box width='100%' sx={{ minHeight: 400 }}>
			{broadcastHistoryStatus === "pending" ? (
				<Box
					display='flex'
					alignItems='center'
					justifyContent='center'
					mr='auto'
					ml='auto'
					flexDirection='column'
				>
					<CircularProgress />
					<Typography>Loading Broadcasting History</Typography>
				</Box>
			) : (
				<DataGridPro
					rows={rows}
					columns={columns}
					hideFooter={true}
					autoHeight
					disableColumnMenu
					sx={{
						pl: 0,
						border: "none !important",
						"& .MuiDataGrid-columnSeparator": {
							display: "none",
						},
						"&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
							py: "15px",
							pl: "20px",
							alignItems: "baseline",
						},
						"&.MuiDataGrid-root--densityStandard .MuiDataGrid-columnHeader": {
							px: "20px",
						},
					}}
					getRowHeight={() => "auto"}
				/>
			)}
		</Box>
	);
};

export default BMessageHistory;
