// TODO - Type 'any' needs to be fixed.
import {
  Autocomplete,
  Box,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro-6";
import { AdapterDayjs } from "@mui/x-date-pickers-pro-6/AdapterDayjs";
import { format } from "date-fns";
import dayjs from "dayjs";
import React from "react";
import { useSelector } from "react-redux";

import { SkillRepresentationCell } from "../../../../../../../../export/cross-skilling";
import {
  getLevelObj,
  getRepLevel,
  getRepType,
} from "../../../../../../../../export/helpers";
import useWorkersData from "../../../../../controllers/use-workers-data";
import RequiredCertificationsTable from "./required-certifications-table";

const EditSkillDialogContent = ({
  values,
  setFieldValue,
  certifications,
  errors,
}: any) => {
  const { allSkillLevels } = useWorkersData();
  const certificationsEnabled = useSelector(
    (state: any) => state.user.userData.certificationsEnabled
  );
  const skillExpiryEnabled = useSelector(
    (state: any) => state.user.userData.skillExpiryEnabled
  );
  const customField = (props: any) => {
    const { levelValue, repLevel, backgroundColor, foregroundColor, repType } =
      getLevelObj(values.level, allSkillLevels);
    return (
      <TextField
        {...props}
        variant="outlined"
        label="Skill Level"
        InputProps={{
          ...props.InputProps,
          startAdornment: values.level !== null && values.level >= 0 && (
            <InputAdornment position="start">
              <SkillRepresentationCell
                level={props.value}
                value={levelValue}
                representationType={repType}
                representationLevel={repLevel}
                backgroundColor={backgroundColor}
                foregroundColor={foregroundColor}
                border={1}
                height={20}
                width={20}
                backgroundWidth={"35px"}
                backgroundHeight={"35px"}
                inSelect={true}
              />
            </InputAdornment>
          ),
        }}
        sx={{ width: certificationsEnabled ? "255px" : "360px", backgroundColor: "#F8F8F8"}}
      />
    );
  };

  const handleValidFromChange = (newValue: any) => {
    if (!newValue) {
      setFieldValue("validFrom", null);
      return;
    }
    if (!dayjs(newValue).isValid()) {
      return;
    }
    const formattedDate = format(new Date(newValue.$d), "yyyy-MM-dd");
    setFieldValue("validFrom", formattedDate);
  };

  const handleValidToChange = (newValue: any) => {
    if (!newValue) {
      setFieldValue("validTo", null);
      return;
    }
    if (!dayjs(newValue).isValid()) {
      return;
    }
    const formattedDate = format(new Date(newValue.$d), "yyyy-MM-dd");
    setFieldValue("validTo", formattedDate);
  };

  return (
    <Box>
      <Box sx={{
        padding: "0 45px",
      }}>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: "12px",
            letterSpacing: "0.15px",
            color: "#00000099",
          }}
        >
          Skill Name
        </Typography>
      <Tooltip
									title={values?.skillName}
									placement='bottom-start'
								>
									<Typography
										sx={{
											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis",
											maxWidth: "600px",
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      fontWeight: 400,
                      lineHeight: "28px",
                      letterSpacing: "0.15px",
										}}
									>
										{values?.skillName}
									</Typography>
								</Tooltip>
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: certificationsEnabled ? "1fr 1fr 1fr" : "1fr",
          gap: 3,
          mb: 3,
          mt: 3,
          padding: "0 45px",
        }}
      >
        <Box>
          {/* <Box>
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "24px",
                letterSpacing: "0.17px",
                textAlign: "left",
              }}
            >
              Skill Level
            </Typography>
          </Box> */}
          <Box>
            <Autocomplete
              options={allSkillLevels}
              getOptionLabel={(option) =>
                allSkillLevels.find((x: any) => x.levelId === values.levelId)
                  ?.name ?? ""
              }
              value={values.levelId ?? null}
              isOptionEqualToValue={(option, value) => {
                return option.levelId === value;
              }}
              onChange={(event, newValue) => {
                if (newValue !== null) {
                  setFieldValue("levelId", newValue.levelId);
                  setFieldValue("level", newValue.level);
                  setFieldValue("levelName", newValue.name);
                } else {
                  setFieldValue("levelId", null);
                  setFieldValue("level", null);
                  setFieldValue("levelName", null);
                }
              }}
              renderInput={(params) => customField(params)}
              renderOption={(props, option) => (
                <Box
                  component={"li"}
                  {...props}
                  key={option.name}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1.5,
                  }}
                >
                  <SkillRepresentationCell
                    level={option.level}
                    value={option.value}
                    representationType={getRepType(option.repCode)}
                    representationLevel={getRepLevel(
                      option.repCode,
                      option.level
                    )}
                    backgroundColor={option.backColorCode}
                    foregroundColor={option.foreColorCode}
                    border={1}
                    height={20}
                    width={20}
                    inSelect={true}
                  />
                  {option.name}
                </Box>
              )}
            />
          </Box>
        </Box>
        {skillExpiryEnabled && (
          <>
            <Box>
              {/* <Box>
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "24px",
                    letterSpacing: "0.17px",
                    textAlign: "left",
                  }}
                >
                  Valid From
                </Typography>
              </Box> */}
              <Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={
                      values.validFrom
                        ? dayjs(values.validFrom, "YYYY-MM-DD")
                        : null
                    }
                    onChange={(newValue) => {
                      handleValidFromChange(newValue);
                    }}
                    slotProps={{
                      field: { clearable: true },
                      textField: {
                        placeholder: "Select Date",
                        error: !!errors.validFrom,
                        helperText: errors.validFrom,
                      },
                    }}
                    format={"MM/DD/YYYY"}
                    sx={{ width: certificationsEnabled ? "255px" : "360px", backgroundColor: "#F8F8F8"}}
                    label="Valid From"
                  />
                </LocalizationProvider>
              </Box>
            </Box>
            <Box>
              {/* <Box>
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "24px",
                    letterSpacing: "0.17px",
                    textAlign: "left",
                  }}
                >
                  Valid Until
                </Typography>
              </Box> */}
              <Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={
                      values.validTo
                        ? dayjs(values.validTo, "YYYY-MM-DD")
                        : null
                    }
                    onChange={(newValue) => {
                      handleValidToChange(newValue);
                    }}
                    slotProps={{
                      field: { clearable: true },
                      textField: {
                        placeholder: "Select Date",
                        error: !!errors.validTo,
                        helperText: errors.validTo,
                      },
                    }}
                    format={"MM/DD/YYYY"}
                    sx={{ width: certificationsEnabled ? "255px" : "360px", backgroundColor: "#F8F8F8"}}
                    minDate={
                      values.validFrom
                        ? dayjs(values.validFrom, "YYYY-MM-DD").add(1, "day")
                        : null
                    }
                    label="Valid Until"
                  />
                </LocalizationProvider>
              </Box>
            </Box>
          </>
        )}
      </Box>
      {certificationsEnabled && <RequiredCertificationsTable certifications={certifications} />}
    </Box>
  )
};

export default EditSkillDialogContent;
