import { Box, Switch, Typography } from "@mui/material";
import React from "react";

const SkillPriorityWorkerApp = ({
                         skillLevels,
                         newAddOnSettings,
                         setNewAddOnSettings,
                     }: any) => {
    const crossSkillingEnabled = newAddOnSettings.crossSkillingEnabled;
    const skillPriorityWorkerAppEnabled = newAddOnSettings.skillPriorityWorkerAppEnabled;

    return (
        <Box
            sx={{
                marginTop: "20px",
                marginBottom: "8px",
                borderRadius: "8px",
                border: 1,
                borderWidth: "1px",
                borderColor: "rgba(0,0,0,0.12)",
                background: "#FFF",
                padding: "16px",
                opacity: !crossSkillingEnabled || skillLevels.length <= 0 ? 0.5 : 1,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0px 16px",
                    alignItems: "center",
                }}
            >
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                }}>
                    <Typography
                        sx={{
                            fontFamily: "Roboto",
                            fontWeight: 400,
                            fontSize: "16px",
                            color: "rgba(0, 0, 0, 0.87)",
                        }}
                    >
                        Show Skill Priority to Workers on mobile app
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: "Roboto",
                            fontWeight: 400,
                            fontSize: "12px",
                            color: "rgba(0, 0, 0, 0.6)",
                        }}
                    >
                        Show Workers their Total Skill Score and the Priority of their Skills transparently
                    </Typography>
                </Box>
                <Switch
                    checked={skillPriorityWorkerAppEnabled ?? false}
                    disabled={!crossSkillingEnabled || skillLevels.length === 0}
                    onChange={(e) => {
                        setNewAddOnSettings((pre: any) => {
                            return {
                                ...pre,
                                skillPriorityWorkerAppEnabled: e.target.checked,
                            };
                        });
                    }}
                />
            </Box>
        </Box>
    );
};

export default SkillPriorityWorkerApp;
