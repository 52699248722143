import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import {
	closeEditWorkerCertificationDialog,
	editWorkerCertification,
} from "../../../../../../../../export/workers";
import useWorkersData from "../../../../../controllers/use-workers-data";
import useEditCertificationDialogController from "./controllers/use-edit-worker-certification-dialog-controller";
import EditCertificationForm from "./edit-certification-form";

const EditWorkerCertificationDialog = () => {
	const {
		isEditWorkerCertificationDialogOpen,
		editWorkerCertificationStatus,
		postUploadFilesStatus,
		postDeleteFilesStatus,
	} = useWorkersData();
	const {
		formikInitialValues,
		setFileUploadErrors,
		fileUploadErrors,
		uploadFilesInParallel,
		files,
		setFiles,
		filesToDelete,
		setFilesToDelete,
		deleteFilesInParallel,
	} = useEditCertificationDialogController();
	const dispatch = useDispatch();
	
	const schema = Yup.object().shape({
		validFrom: Yup.string()
			.nullable(),
		validTo: Yup.string()
			.nullable()
			.test(
				"is-after-valid-from",
				"valid until date must be greater than valid from date",
				function (validTo) {
					const { validFrom } = this.parent;
					if (validFrom && validTo) {
						return new Date(validTo) > new Date(validFrom);
					}
					return true; // If either is null or undefined, the test passes
				}
			),
	});

	// Additional validation for validFrom to ensure it is required if validTo is provided
	schema.fields.validFrom = schema.fields.validFrom.test(
		"is-required-if-valid-to",
		"Please select a valid from date",
		function (validFrom) {
			const { validTo } = this.parent;
			if (validTo && !validFrom) {
				return false; // If validTo is provided and validFrom is not, the test fails
			}
			return true; // Otherwise, the test passes
		}
	);

	return (
		<Dialog
			open={isEditWorkerCertificationDialogOpen}
			onClose={() => dispatch(closeEditWorkerCertificationDialog())}
			sx={{
				padding: "45px 45px 0px 45px",
			}}
		>
			<Formik
				initialValues={formikInitialValues}
				validationSchema={schema}
				onSubmit={(values, { setSubmitting, setFieldValue }) => {
					try {
						setSubmitting(true);
						const filesChanged = files.length > 0;
						const valuesChanged =
							values.validFrom !== formikInitialValues.validFrom ||
							values.validTo !== formikInitialValues.validTo;

						if (valuesChanged) {
							dispatch(editWorkerCertification(values));
						}

						if (filesToDelete.length > 0) {
							deleteFilesInParallel({});
						}

						if (filesChanged) {
							uploadFilesInParallel({
								workerCertId: values.workerCertId,
								values,
								setFieldValue,
							});
						}

						setSubmitting(false);
					} catch (e) {
						console.log(e);
					}
				}}
			>
				{({ values, setFieldValue, errors, isSubmitting, isValid, dirty, validateForm }) => {
					return (
						<Form
						style={{
							overflowY: "auto",
							display: "flex",
							flexDirection: "column",
						}}
						>
							<DialogTitle
								sx={{
									padding: "45px 45px 24px 45px",
								}}
							>
								<Box>
									<Typography variant='h5'>
										Edit Certification for Worker
									</Typography>
									{/* <Typography variant='overline' sx={{pl:0.2}}>
									{values.certName}
									</Typography> */}
								</Box>
							</DialogTitle>
							<DialogContent sx={{ padding: "0 45px 16px 45px" }}>
								<EditCertificationForm
									values={values}
									setFieldValue={setFieldValue}
									files={files}
									setFiles={setFiles}
									setFilesToDelete={setFilesToDelete}
									fileUploadErrors={fileUploadErrors}
									errors={errors}
									validateForm={validateForm}
								/>
							</DialogContent>
							<DialogActions
								sx={{
									padding: "32px 45px 45px 24px",
								}}
							>
								<Button
									onClick={() => dispatch(closeEditWorkerCertificationDialog())}
								>
									Cancel
								</Button>
								<Button
									type='submit'
									variant='contained'
									disabled={
										isSubmitting ||
										!isValid ||
										(!dirty &&
											files.length === 0 &&
											filesToDelete.length === 0) ||
										editWorkerCertificationStatus === "pending" ||
										postUploadFilesStatus === "pending" ||
										postDeleteFilesStatus === "pending" ||
										!!errors.validFrom ||
										!!errors.validTo
									}
									startIcon={
										(isSubmitting ||
											editWorkerCertificationStatus === "pending" ||
											postUploadFilesStatus === "pending" ||
											postDeleteFilesStatus === "pending") && (
											<CircularProgress size={20} />
										)
									}
								>
									{editWorkerCertificationStatus === "pending" ||
									postUploadFilesStatus === "pending" ||
									postDeleteFilesStatus === "pending"
										? "Saving..."
										: "Save"}
								</Button>
							</DialogActions>
						</Form>
					);
				}}
			</Formik>
		</Dialog>
	);
};

export default EditWorkerCertificationDialog;
