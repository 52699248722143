import {
    Box,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers-6/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers-6/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers-6/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers-6/TimePicker";
import format from "date-fns/format";
import dayjs from "dayjs";
import React from "react";

import { MaxSignupIn24Hours } from "../../../../../../export/gat-admin";
import { getTimeForTimePicker } from "../../../../../utils/helpers";
import SectionWrapper from "../../../section-wrapper";
import SectionActions from "../../section-actions";
import useLimitControlController from "./controllers/use-limit-controls-controller";
import FlexHoursRestriction from "./flex-hours-restriction/flex-hours-restriction";
import WeeklyHourLimit from "./weekly-hour-limit/weekly-hour-limit";

const LimitsControls = ({ entity }: any) => {
    const [
        { limitControl, newLimitControl, loading },
        { setNewLimitControl, handleSave },
    ]: any = useLimitControlController(entity);

    return (
        <SectionWrapper
            title='Limits & Controls'
            subtitle='Decide on different checks placed.'
        >
            <Box
                sx={{
                    marginTop: "32px",
                }}
            >
                <Typography
                    sx={{
                        fontFamily: "Roboto",
                        fontWeight: 400,
                        fontSize: "16px",
                        color: "rgba(0, 0, 0, 0.87)",
                        marginBottom: "4px",
                    }}
                >
                    Start time and first day of the standard week
                </Typography>
                <Box display='flex'>
                    <Box
                        sx={{
                            "& .MuiTextField-root": {
                                width: "100px",
                            },
                        }}
                        mr={2}
                    >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["TimePicker"]}>
                                <TimePicker
                                    label='Start Time (required)'
                                    slotProps={{
                                        textField: {
                                            variant: "filled",
                                            // error: startTimeError !== "",
                                            // helperText: startTimeError,
                                            InputLabelProps: {
                                                shrink: true,
                                            },
                                            sx: {
                                                // Hack to match popper
                                                minWidth: "168px !important",
                                            },
                                        },
                                        popper: {
                                            sx: {
                                                // In case we want to change popper width
                                                // "& .MuiPaper-root ": {
                                                //     width: "168px",
                                                // }
                                            },
                                        },
                                    }}
                                    onError={(error) => {/* Do Nothing */ }}
                                    onChange={(newValue) => {
                                        try {
                                            if (JSON.stringify(newValue).length !== 4) {
                                                setNewLimitControl({
                                                    ...newLimitControl,
                                                    weekStartTime: getTimeForTimePicker(newValue),
                                                });
                                            } else if (newValue === null) {
                                                setNewLimitControl({
                                                    ...newLimitControl,
                                                    weekStartTime: "",
                                                });
                                            }
                                        } catch (e) {
                                            console.log("Having error", e);
                                        }
                                    }}
                                    value={
                                        newLimitControl.weekStartTime
                                            ? dayjs(
                                                `${format(new Date(), "yyyy-MM-dd")}T${newLimitControl.weekStartTime
                                                }`
                                                // eslint-disable-next-line no-mixed-spaces-and-tabs
                                            )
                                            : dayjs(
                                                `${format(new Date(), "yyyy-MM-dd")}T${"08:00:00"}`
                                                // eslint-disable-next-line no-mixed-spaces-and-tabs
                                            )
                                    }
                                />
                            </DemoContainer>
                        </LocalizationProvider>

                        <Typography
                            sx={{
                                fontFamily: "Roboto",
                                fontWeight: 400,
                                fontSize: "12px",
                                color: "rgba(0, 0, 0, 0.6)",
                                marginTop: "4px",
                                marginLeft: "16px",
                            }}
                        >
                            Hours:Minutes AM/PM
                        </Typography>
                    </Box>

                    <FormControl
                        variant='filled'
                        sx={{ width: "160px", marginTop: "8px" }}
                    >
                        <InputLabel id='admin-settings-select'>
                            First day of the week
                        </InputLabel>
                        <Select
                            labelId='admin-settings-select'
                            onChange={(e) => {
                                setNewLimitControl({
                                    ...newLimitControl,
                                    weekStartDay: e.target.value,
                                });
                            }}
                            value={newLimitControl.weekStartDay ?? "Monday"}
                            variant='filled'
                        >
                            <MenuItem value='Monday'>Monday</MenuItem>
                            <MenuItem value='Tuesday'>Tuesday</MenuItem>
                            <MenuItem value='Wednesday'>Wednesday</MenuItem>
                            <MenuItem value='Thursday'>Thursday</MenuItem>
                            <MenuItem value='Friday'>Friday</MenuItem>
                            <MenuItem value='Saturday'>Saturday</MenuItem>
                            <MenuItem value='Sunday'>Sunday</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Box>
            <Box
                sx={{
                    backgroundColor: "#F5F5F5",
                    borderRadius: "8px",
                    marginTop: "32px",
                    padding: "16px",
                }}
            >
                <Box mb={2}>
                    <Typography
                        sx={{
                            fontFamily: "Roboto",
                            fontWeight: 400,
                            fontSize: "12px",
                            color: "rgba(0, 0, 0, 0.6)",
                        }}
                    >
                        Below settings are only applicable for flex shifts (flex worker) or
                        overtime shifts (full time worker)
                    </Typography>
                </Box>
                <Grid container>
                    <Grid item xs={4}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: "260px",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: "Roboto",
                                    fontWeight: 400,
                                    fontSize: "16px",
                                    color: "rgba(0, 0, 0, 0.87)",
                                }}
                            >
                                Default shift duration
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: "Roboto",
                                    fontWeight: 400,
                                    fontSize: "12px",
                                    color: "rgba(0, 0, 0, 0.6)",
                                    paddingBottom: "8px",
                                }}
                            >
                                Shift duration when a new shift is created.
                            </Typography>
                            <TextField
                                id='default-shift-duration'
                                variant='filled'
                                type='number'
                                label=''
                                onFocus={(event) => {
                                    event.target.select();
                                }}
                                onChange={(e) => {
                                    setNewLimitControl({
                                        ...newLimitControl,
                                        defaultDuration: parseInt(e.target.value),
                                    });
                                }}
                                onBlur={(e) => {/* Do Nothing */ }}
                                value={newLimitControl.defaultDuration ?? "8"}
                                helperText='Hours'
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={8}>
                        <Grid container>
                            <Grid item xs={4}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "260px",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: "Roboto",
                                            fontWeight: 400,
                                            fontSize: "16px",
                                            color: "rgba(0, 0, 0, 0.87)",
                                        }}
                                    >
                                        Min. Shift duration
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontFamily: "Roboto",
                                            fontWeight: 400,
                                            fontSize: "12px",
                                            color: "rgba(0, 0, 0, 0.6)",
                                            paddingBottom: "8px",
                                        }}
                                    >
                                        Lowest single shift duration.
                                    </Typography>
                                    <TextField
                                        id='min-days-shift-duration'
                                        variant='filled'
                                        label=''
                                        type='number'
                                        onFocus={(event) => {
                                            event.target.select();
                                        }}
                                        onBlur={(e) => {/* Do Nothing */ }}
                                        onChange={(e) => {
                                            setNewLimitControl({
                                                ...newLimitControl,
                                                minDuration: parseInt(e.target.value),
                                            });
                                        }}
                                        value={newLimitControl.minDuration ?? "4"}
                                        helperText='Hours'
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={1} />
                            <Grid item xs={4}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "260px",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: "Roboto",
                                            fontWeight: 400,
                                            fontSize: "16px",
                                            color: "rgba(0, 0, 0, 0.87)",
                                        }}
                                    >
                                        Max. Shift duration
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontFamily: "Roboto",
                                            fontWeight: 400,
                                            fontSize: "12px",
                                            color: "rgba(0, 0, 0, 0.6)",
                                            paddingBottom: "8px",
                                        }}
                                    >
                                        Highest single shift duration.
                                    </Typography>
                                    <TextField
                                        id='max-days-shift-duration'
                                        variant='filled'
                                        label=''
                                        type='number'
                                        onFocus={(event) => {
                                            event.target.select();
                                        }}
                                        onBlur={(e) => {/* Do Nothing */ }}
                                        onChange={(e) => {
                                            setNewLimitControl({
                                                ...newLimitControl,
                                                maxDuration: parseInt(e.target.value),
                                            });
                                        }}
                                        value={newLimitControl.maxDuration ?? "10"}
                                        helperText='Hours'
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>

            <Grid
                container
                sx={{
                    marginTop: "32px",
                    paddingLeft: "16px",
                }}
            >
                <Grid item xs={4}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "260px",
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: "Roboto",
                                fontWeight: 400,
                                fontSize: "16px",
                                color: "rgba(0, 0, 0, 0.87)",
                            }}
                        >
                            Maximum continuous signup hours
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: "Roboto",
                                fontWeight: 400,
                                fontSize: "12px",
                                color: "rgba(0, 0, 0, 0.6)",
                                paddingBottom: "8px",
                            }}
                        >
                            Worker can signup shifts for these many hours back to back.
                        </Typography>
                        <TextField
                            id='max-back-to-back'
                            variant='filled'
                            label=''
                            type='number'
                            onFocus={(event) => {
                                event.target.select();
                            }}
                            onChange={(e) => {
                                setNewLimitControl({
                                    ...newLimitControl,
                                    maxContinuousHours: parseInt(e.target.value),
                                });
                            }}
                            value={newLimitControl.maxContinuousHours ?? "12"}
                            helperText='Hours'
                        />
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <MaxSignupIn24Hours setNewLimitControl={setNewLimitControl} newLimitControl={newLimitControl} />
                </Grid>
            </Grid>
            <FlexHoursRestriction
                newLimitControl={newLimitControl}
                setNewLimitControl={setNewLimitControl}
            />
            <WeeklyHourLimit
                newLimitControl={newLimitControl}
                setNewLimitControl={setNewLimitControl}
            />

            <SectionActions
                oldData={limitControl}
                newData={newLimitControl}
                resetChanges={() => {
                    setNewLimitControl(limitControl);
                }}
                handleSave={handleSave}
                loading={loading}
            />
        </SectionWrapper>
    );
};

export default LimitsControls;
