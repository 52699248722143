import { Box, Divider, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import React from "react";

import { AllocationSettings } from "../../../../../store/gat-admin-slice-types";
import InputButtonGroup from "./input-button-group";

const Title: React.FC<{ children: React.ReactNode }> = ({ children }) => (
	<Typography
		sx={{
			fontFamily: "Roboto",
			fontSize: "12px",
			fontWeight: 400,
			lineHeight: "32px",
			letterSpacing: "1px",
			textAlign: "left",
		}}
	>
		{children}
	</Typography>
);

const Subtitle: React.FC<{ children: React.ReactNode }> = ({ children }) => (
	<Typography
		sx={{
			fontFamily: "Roboto",
			fontSize: "12px",
			fontWeight: 400,
			lineHeight: "20px",
			letterSpacing: "1px",
			textAlign: "left",
		}}
	>
		{children}
	</Typography>
);

type ConfigurationFormProps = {
	shiftAllocationRules: any;
	setShiftAllocationRules: any;
	id?: number;
};

const ConfigurationForm = ({
	shiftAllocationRules,
	setShiftAllocationRules,
	id,
}: ConfigurationFormProps) => {
	const config = shiftAllocationRules.find(
		(rule: { shiftAllocationTypeId: number }) =>
			rule.shiftAllocationTypeId === id
	);

	return (
		<Box width={900}>
			<Divider />
			<Box my={2}>
				<Title>DEFAULT APPLICATION WINDOW</Title>
				<Subtitle>
					Time frame for workers to mark interest in a published shift. After
					this window, shift will be assigned to the senior most among the
					interested workers.
				</Subtitle>
				<Box my={2} display='flex'>
					<Box display='flex' alignItems='center' mr={2}>
						<InputButtonGroup
							// shiftAllocationRules={shiftAllocationRules}
							// setShiftAllocationRules={setShiftAllocationRules}
							// id={id}
							// config={config}
							handleIncrement={() =>
								setShiftAllocationRules(
									shiftAllocationRules.map((rule: AllocationSettings) => {
										if (rule.shiftAllocationTypeId === id) {
											return {
												...rule,
												defaultWindowSizeMins: rule.defaultWindowSizeMins
													? rule.defaultWindowSizeMins + 60
													: 60,
											};
										}
										return rule;
									})
								)
							}
							handleDecrement={() =>
								setShiftAllocationRules(
									shiftAllocationRules.map((rule: AllocationSettings) => {
										if (rule.shiftAllocationTypeId === id) {
											return {
												...rule,
												defaultWindowSizeMins: rule.defaultWindowSizeMins - 60,
											};
										}
										return rule;
									})
								)
							}
							value={
								config?.defaultWindowSizeMins
									? Math.floor(config?.defaultWindowSizeMins / 60)
									: 0
							}
						/>
						<Typography ml={1}>hours</Typography>
					</Box>
					<Box display='flex' alignItems='center' ml={2}>
						<InputButtonGroup
							// shiftAllocationRules={shiftAllocationRules}
							// setShiftAllocationRules={setShiftAllocationRules}
							// id={id}
							// config={config}
							handleIncrement={() =>
								setShiftAllocationRules(
									shiftAllocationRules.map((rule: AllocationSettings) => {
										if (rule.shiftAllocationTypeId === id) {
											return {
												...rule,
												defaultWindowSizeMins: rule.defaultWindowSizeMins
													? rule.defaultWindowSizeMins + 15
													: 15,
											};
										}
										return rule;
									})
								)
							}
							handleDecrement={() =>
								setShiftAllocationRules(
									shiftAllocationRules.map((rule: AllocationSettings) => {
										if (rule.shiftAllocationTypeId === id) {
											return {
												...rule,
												defaultWindowSizeMins: rule.defaultWindowSizeMins - 15,
											};
										}
										return rule;
									})
								)
							}
							value={
								config?.defaultWindowSizeMins
									? config.defaultWindowSizeMins % 60
									: 0
							}
						/>
						<Typography ml={1}>minutes</Typography>
					</Box>
				</Box>
			</Box>
			<Box>
				<Title>APPLICATION WINDOW - URGENT SHIFT</Title>
				<Subtitle>
					An urgent shift is when the default application window is more than
					the time between shift publish and shift start time. The window for an
					urgent shift has to be shorter than the default application window.
					Recommended - 15minutes.
				</Subtitle>
				<Box my={2} display='flex'>
					<Box display='flex' alignItems='center'>
						<InputButtonGroup
							// shiftAllocationRules={shiftAllocationRules}
							// setShiftAllocationRules={setShiftAllocationRules}
							// id={id}
							// config={config}
							handleIncrement={() =>
								setShiftAllocationRules(
									shiftAllocationRules.map((rule: AllocationSettings) => {
										if (rule.shiftAllocationTypeId === id) {
											return {
												...rule,
												urgentWindowSizeMins: rule.urgentWindowSizeMins
													? rule.urgentWindowSizeMins + 1
													: 1,
											};
										}
										return rule;
									})
								)
							}
							handleDecrement={() =>
								setShiftAllocationRules(
									shiftAllocationRules.map((rule: AllocationSettings) => {
										if (rule.shiftAllocationTypeId === id) {
											return {
												...rule,
												urgentWindowSizeMins: rule.urgentWindowSizeMins - 1,
											};
										}
										return rule;
									})
								)
							}
							value={config?.urgentWindowSizeMins ?? 0}
						/>
						<Typography ml={1}>minutes</Typography>
					</Box>
				</Box>
			</Box>
			<Box>
				<Title>TIE BREAKER RULES</Title>
				<Subtitle>
					Seniority is calculated in number of days since worker joining date.
					Select the rule to allocate shift when 2 or more workers have the same
					seniority.
				</Subtitle>
				<Box my={2}>
					<FormControl>
						<RadioGroup
							aria-labelledby='demo-radio-buttons-group-label'
							defaultValue={config?.tieBreakerProp ?? "rscore"}
							name='radio-buttons-group'
						>
							<FormControlLabel
								value='rscore'
								control={<Radio />}
								label='Karma Points'
								onChange={() => {
									setShiftAllocationRules(
										shiftAllocationRules.map((rule: AllocationSettings) => {
											if (rule.shiftAllocationTypeId === id) {
												return {
													...rule,
													tieBreakerProp: "rscore",
												};
											}
											return rule;
										})
									);
								}}
							/>
							<FormControlLabel
								value='totalCompletedHrs'
								control={<Radio />}
								label='Total no. of shifts hours completed'
								onChange={() => {
									setShiftAllocationRules(
										shiftAllocationRules.map((rule: AllocationSettings) => {
											if (rule.shiftAllocationTypeId === id) {
												return {
													...rule,
													tieBreakerProp: "totalCompletedHrs",
												};
											}
											return rule;
										})
									);
								}}
							/>
						</RadioGroup>
					</FormControl>
				</Box>
			</Box>
			<Divider />
		</Box>
	);
};

export default ConfigurationForm;
