/* eslint-disable no-mixed-spaces-and-tabs */
import { Box, Grid, TextField, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import React from "react";

const WeeklyHourLimit = ({ newLimitControl, setNewLimitControl }:any) => {
	return (
		<Box
			sx={{
				backgroundColor: "#F5F5F5",
				borderRadius: "8px",
				marginTop: "32px",
				marginBottom: "16px",
				padding: "16px",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 400,
							fontSize: "16px",
							color: "rgba(0, 0, 0, 0.87)",
						}}
					>
						Weekly hour limit
					</Typography>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 400,
							fontSize: "12px",
							color: "rgba(0, 0, 0, 0.6)",
						}}
					>
						Minimum and maximum no. of hours a worker can work in a week.
					</Typography>
				</Box>
			</Box>
			<Box
				sx={{
					marginTop: "32px",
				}}
			>
				<Grid
					container
					sx={{
						display: "flex",
						alignItems: "center",
						padding: "16px",
					}}
				>
					<Grid item xs={4}>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 500,
								fontSize: "16px",
								color: "rgba(0, 0, 0, 0.87)",
							}}
						>
							Worker
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 500,
								fontSize: "16px",
								color: "rgba(0, 0, 0, 0.87)",
							}}
						>
							Maximum (in hours)
						</Typography>
					</Grid>
				</Grid>
				<Divider
					sx={{
						marginTop: "8px",
						marginBottom: "8px",
						marginLeft: "16px",
						marginRight: "16px",
					}}
				/>
				<Grid
					container
					sx={{
						display: "flex",
						alignItems: "flex-start",
						padding: "16px",
					}}
				>
					<Grid item xs={4}>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 400,
								fontSize: "16px",
								color: "rgba(0, 0, 0, 0.87)",
							}}
						>
							Flex
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<TextField
							id='number-of-maximum-hours'
							variant='filled'
							type='number'
							onFocus={(event) => {
								event.target.select();
							}}
							sx={{
								"& .MuiInputBase-input": {
									background: "#FFF",
									padding: "8px",
								},
								width: "120px",
							}}
							onChange={(event) => {
								if (parseInt(event.target.value) < 0) return;
								if (parseInt(event.target.value) > 168)
									setNewLimitControl({
										...newLimitControl,
										flexMaxWeeklyHours: 168,
									});
								else {
									setNewLimitControl({
										...newLimitControl,
										flexMaxWeeklyHours: parseInt(event.target.value),
									});
								}
							}}
							value={newLimitControl.flexMaxWeeklyHours ?? 168}
							// error={
							// 	newLimitControl.flexMaxWeeklyHours < 0 ||
							// 	newLimitControl.flexMaxWeeklyHours > 168
							// }
							helperText={
								newLimitControl.flexMaxWeeklyHours >= 168
									? "Max. limit is 168"
									: " "
							}
						/>
					</Grid>
				</Grid>
				<Grid
					container
					sx={{
						display: "flex",
						alignItems: "flex-start",
						padding: "16px",
					}}
				>
					<Grid item xs={4}>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 400,
								fontSize: "16px",
								color: "rgba(0, 0, 0, 0.87)",
							}}
						>
							Full time (overtime hours)
						</Typography>
					</Grid>

					<Grid item xs={4}>
						<TextField
							id='number-of-maximum-hours'
							variant='filled'
							type='number'
							onFocus={(event) => {
								event.target.select();
							}}
							sx={{
								"& .MuiInputBase-input": {
									background: "#FFF",
									padding: "8px",
								},
								width: "120px",
							}}
							onChange={(event) => {
								if (parseInt(event.target.value) < 0) return;
								if (parseInt(event.target.value) > 168)
									setNewLimitControl({
										...newLimitControl,
										fullMaxWeeklyHours: 168,
									});
								else
									setNewLimitControl({
										...newLimitControl,
										fullMaxWeeklyHours: parseInt(event.target.value),
									});
							}}
							value={newLimitControl.fullMaxWeeklyHours ?? 168}
							// error={
							// 	newLimitControl.fullMaxWeeklyHours < 0 ||
							// 	newLimitControl.fullMaxWeeklyHours > 168
							// }
							helperText={
								newLimitControl.fullMaxWeeklyHours >= 168
									? "Max. limit is 168"
									: " "
							}
						/>
					</Grid>
				</Grid>
				<Grid
					container
					sx={{
						display: "flex",
						alignItems: "flex-start",
						padding: "16px",
					}}
				>
					<Grid item xs={4}>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 400,
								fontSize: "16px",
								color: "rgba(0, 0, 0, 0.87)",
							}}
						>
							Part Time (overtime hours)
						</Typography>
					</Grid>

					<Grid item xs={4}>
						<TextField
							id='number-of-maximum-hours'
							variant='filled'
							type='number'
							onFocus={(event) => {
								event.target.select();
							}}
							sx={{
								"& .MuiInputBase-input": {
									background: "#FFF",
									padding: "8px",
								},
								width: "120px",
							}}
							onChange={(event) => {
								if (parseInt(event.target.value) < 0) return;
								if (parseInt(event.target.value) > 168)
									setNewLimitControl({
										...newLimitControl,
										partTimeMaxWeeklyHours: 168,
									});
								else
									setNewLimitControl({
										...newLimitControl,
										partTimeMaxWeeklyHours: parseInt(event.target.value),
									});
							}}
							value={newLimitControl.partTimeMaxWeeklyHours ?? 168}
							// error={
							// 	newLimitControl.partTimeMaxWeeklyHours < 0 ||
							// 	newLimitControl.partTimeMaxWeeklyHours > 168
							// }
							helperText={
								newLimitControl.partTimeMaxWeeklyHours >= 168
									? "Max. limit is 168"
									: " "
							}
						/>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
};

export default WeeklyHourLimit;
