import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import React from "react";

export default function Loading() {
	return (
		<Box
			height='100vh'
			width={1}
			display='flex'
			justifyContent='center'
			alignItems='center'
			flexDirection='column'
		>
			<CircularProgress />
			<Box mt={1}>
				<Typography>Loading...</Typography>
			</Box>
		</Box>
	);
}
