import DeleteIcon from "@mui/icons-material/Delete";
import ModeIcon from "@mui/icons-material/Mode";
import { Box, Typography } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import React from "react";
import { useDispatch } from "react-redux";

import {
	deleteShiftSchedule,
	openEditShiftScheduleDialog,
	resetEditShiftScheduleDialog,
} from "../../../../store/gat-admin-slice";

const convertTo12HourFormat = (time24hr: string): string => {
	const [hours, minutes] = time24hr.split(":").map(Number);
	const parsedTime = new Date(2023, 0, 1, hours, minutes);
	const formattedTime = new Intl.DateTimeFormat("en-US", {
		hour: "numeric",
		minute: "numeric",
		hour12: true,
	}).format(parsedTime);
	return formattedTime;
};

interface Data {
	id: number;
	name: string;
	startTime: string;
	endTime: string;
	unpaidBreakMins: number;
}

const useShiftScheduleColumns = (getDuration: any) => {
	const dispatch = useDispatch();
	const confirm = useConfirm();

	const handleDelete = (data: Data) => {
		confirm({
			title: "Are you sure?",
			description: `This will remove ${data.name} from shift schedules for current company.`,
		})
			.then(() => {
				dispatch(deleteShiftSchedule(data.id));
			})
			.catch(() => {
				/* ... */
			});
	};

	const openEditDialog = (data: Data) => {
		dispatch(resetEditShiftScheduleDialog());
		dispatch(openEditShiftScheduleDialog(data));
	};

	const columns = [
		{
			field: "name",
			headerName: "Name",
			minWidth: 210,
			flex: 1,
			renderHeader: () => (
				<span style={{ padding: "0px 4px", fontWeight: "bold" }}>
					{"Schedule Name"}
				</span>
			),
			renderCell: (params: any) => (
				<Typography
					sx={{
						textOverflow: "ellipsis",
						width: "100%",
						whiteSpace: "nowrap",
						overflow: "hidden",
						padding: "0px 4px",
						fontStyle: "roboto",
						fontWeight: "400",
						fontSize: "14px",
					}}
				>
					{params.row.name}
				</Typography>
			),
			valueGetter: (params: any) => params.row.name,
		},
		{
			field: "timings",
			headerName: "timings",
			minWidth: 150,
			flex: 1,
			renderHeader: () => (
				<span style={{ padding: "0px 4px", fontWeight: "bold" }}>
					{"Shift Timings"}
				</span>
			),
			renderCell: (params: any) => {
				const startTime = params.row.startTime.slice(0, 5);
				const endTime = params.row.endTime.slice(0, 5);
				const shiftTiming =
					convertTo12HourFormat(startTime) +
					" - " +
					convertTo12HourFormat(endTime);
				return (
					<Typography
						sx={{
							textOverflow: "ellipsis",
							width: "100%",
							whiteSpace: "nowrap",
							overflow: "hidden",
							padding: "0px 4px",
							fontStyle: "roboto",
							fontWeight: "400",
							fontSize: "14px",
						}}
					>
						{shiftTiming}
					</Typography>
				);
			},
			valueGetter: (params: any) => params.row.startTime.slice(0, 5),
		},
		{
			field: "duration",
			headerName: "duration",
			minWidth: 120,
			flex: 1,
			renderHeader: () => (
				<span style={{ padding: "0px 4px", fontWeight: "bold" }}>
					{"Shift Duration"}
				</span>
			),
			renderCell: (params: any) => {
				const startTime = params.row.startTime.slice(0, 5);
				const endTime = params.row.endTime.slice(0, 5);
				const hours: any = getDuration(
					startTime,
					endTime,
					params.row.unpaidBreakMins
				);

				return (
					<Typography
						sx={{
							textOverflow: "ellipsis",
							width: "100%",
							whiteSpace: "nowrap",
							overflow: "hidden",
							padding: "0px 4px",
							fontStyle: "roboto",
							fontWeight: "400",
							fontSize: "14px",
						}}
					>
						{hours} hours
					</Typography>
				);
			},
			valueGetter: (params: any) => {
				const startTime = params.row.startTime.slice(0, 5);
				const endTime = params.row.endTime.slice(0, 5);
				const hours: any = getDuration(
					startTime,
					endTime,
					params.row.unpaidBreakMins
				);

				return hours * 100;
			},
		},
		{
			field: "unpaidBreak",
			headerName: "unpaidBreak",
			minWidth: 120,
			flex: 1,
			renderHeader: () => (
				<span style={{ padding: "0px 4px", fontWeight: "bold" }}>
					{"Unpaid Break"}
				</span>
			),
			renderCell: (params: any) => (
				<Typography
					sx={{
						textOverflow: "ellipsis",
						width: "100%",
						whiteSpace: "nowrap",
						overflow: "hidden",
						padding: "0px 4px",
						fontStyle: "roboto",
						fontWeight: "400",
						fontSize: "14px",
					}}
				>
					{params.row.unpaidBreakMins} minutes
				</Typography>
			),
			valueGetter: (params: any) => params.row.unpaidBreakMins,
		},
		{
			field: "actions",
			headerName: "actions",
			width: 100,
			renderHeader: () => (
				<span style={{ padding: "0px 4px", fontWeight: "bold" }}>{""}</span>
			),
			cellClassName: "hover-column-cell",
			sortable: false,
			filterable: false,
			disableColumnMenu: true,
			renderCell: (params: any) => (
				<Box
					width={100}
					display='flex'
					justifyContent='space-around'
					alignItems='center'
					className='hover-content'
				>
					<Box onClick={() => openEditDialog(params.row)}>
						<ModeIcon
							sx={{
								color: "rgba(0, 0, 0, 0.6)",
								fontSize: "16px",
								cursor: "pointer",
								"&:hover": {
									color: "#a8a8a8",
									transform: "scale(1.2)",
								},
							}}
						/>
					</Box>

					<Box onClick={() => handleDelete(params.row)}>
						<DeleteIcon
							sx={{
								color: "rgba(0, 0, 0, 0.6)",
								fontSize: "16px",
								cursor: "pointer",
								"&:hover": {
									color: "#a8a8a8",
									transform: "scale(1.2)",
								},
							}}
						/>
					</Box>
				</Box>
			),
			valueGetter: (params: any) => params.row.name,
		},
	];

	return columns;
};

export default useShiftScheduleColumns;
