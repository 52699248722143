import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import React, { FC } from "react";

import {Priority} from "../../../../../../../utils/types";
import SkillPriorityTableHeader
    from "./skill-priority-table-header";
import SkillPriorityTableRow
    from "./skill-priority-table-row";

interface SkillPrioritiesTableProps {
    openCrossSkillingPrioritiesEditDialog: (open: boolean) => void;
    skillPriorities: Priority[];
}

const SkillPriorityTable: FC<SkillPrioritiesTableProps> = ({
                                                           openCrossSkillingPrioritiesEditDialog,
                                                                  skillPriorities,
                                                                     }) => {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                marginTop: "20px",
                marginBottom: "8px",
                borderRadius: "8px",
                border: 1,
                borderWidth: "1px",
                borderColor: "rgba(0,0,0,0.12)",
                background: "#FFF",
                paddingTop: "8px",
                paddingBottom: "8px",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    paddingLeft: "28px",
                    paddingRight: "36px",
                    paddingTop: "8px",
                    paddingBottom: "8px",
                }}
            >
                <Typography
                    sx={{
                        color: "rgba(0, 0, 0, 0.87)",
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        fontWeight: "400",
                    }}
                >
                    Skill Priority Configuration
                </Typography>
                <IconButton
                    sx={{
                        padding: "12px",
                    }}
                    onClick={() => openCrossSkillingPrioritiesEditDialog(true)}
                >
                    <EditIcon />
                </IconButton>
            </Box>
            <Box
                sx={{
                    width: "100%",
                }}
            >
                <SkillPriorityTableHeader />
                <Divider />
                {skillPriorities.map((skillPriority) => (
                    <SkillPriorityTableRow
                        key={`skill-priority-${skillPriority.id}`}
                        skillPriority={skillPriority}
                    />
                ))}
            </Box>
        </Box>
    );
};

export default SkillPriorityTable;
