// TODO - Type 'any' needs to be fixed.
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import { Box, Chip, Divider, Tooltip, Typography } from "@mui/material";
import React from "react";

import useCertificationsFormController from "../../../controllers/use-certifications-form-controller";
import ActionButtons from "./action-buttons";
import AdditionalCertifications from "./additional-certifications";
interface SelectCertificationsFormProps {
	// isOpen: boolean;
	onClose?: () => void;
}

const SelectCertificationsForm: React.FC<SelectCertificationsFormProps> = ({
	onClose,
}) => {
	const [
		{
			workerSkillsWithCerts,
			skillsMap,
			selectedCertifications,
			addWorkerCertificationStatus,
			workerCerts,
			additionalSkills,
			obsoleteCertificates,
			workerSkills,
		},
		{ handleSelection, removeMultipleCerts },
	] = useCertificationsFormController();

	// console.log(
	// 	// 	// 	workerSkills,
	// 	// 	workerCerts,
	// 	// 	// 	"skill",
	// 	// 	//skillsMap
	// 	// 	// 	"cert",
	// 	workerSkillsWithCerts
	// 	// 	//certificationsMap
	// 	// 	"selectedCertifications",
	// 	// 	selectedCertifications
	// );

	return (
		<Box
			width={800}
			display='flex'
			flexDirection='column'
			justifyContent='space-between'
			sx={{
				height: 520,
				overflowY: "hidden",
			}}
		>
			<Box>
				<Box display='flex'>
					<Box width={200}>
						{" "}
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 500,
								fontSize: "14px",
								color: "#000000",
								lineHeight: "24px",
								letterSpacing: "0.17px",
							}}
						>
							Worker&apos;s Skills
						</Typography>
					</Box>
					<Box ml={1}>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 500,
								fontSize: "14px",
								color: "#000000",
								lineHeight: "24px",
								letterSpacing: "0.17px",
							}}
						>
							Certifications to be Added
						</Typography>
					</Box>
				</Box>
				<Divider />
				<Box
					sx={{
						overflowY: "auto",
						height: 420,
					}}
				>
					{workerSkills?.length === 0 ? (
						<Box mt={2}>
							{" "}
							<Typography
								sx={{
									fontFamily: "Roboto",
									fontWeight: 400,
									fontSize: "14px",
									fontStyle: "italic",
									color: "#00000099",
									lineHeight: "24px",
									letterSpacing: "0.17px",
								}}
							>
								No Skills have been added for the Worker. Add Certifications
								from Additional Certifications.
							</Typography>
						</Box>
					) : (
						workerSkillsWithCerts?.length === 0 && (
							<Box mt={2}>
								{" "}
								<Typography
									sx={{
										fontFamily: "Roboto",
										fontWeight: 400,
										fontSize: "14px",
										fontStyle: "italic",
										color: "#00000099",
										lineHeight: "24px",
										letterSpacing: "0.17px",
									}}
								>
									All Certifications for the Worker’s Skills have been added.
									Add Certifications from Additional Certifications.
								</Typography>
							</Box>
						)
					)}

					{workerSkillsWithCerts?.map((skill: any) => (
						<>
							<Box
								key={`skill-${skill.skillId}`}
								display='flex'
								alignItems='center'
								height={68}
							>
								<Box minWidth={200} maxWidth={200}>
									<Tooltip title={skill.skillName}>
										<Typography
											sx={{
												fontFamily: "Roboto",
												fontWeight: 400,
												fontSize: "14px",
												color: "#000000",
												lineHeight: "20px",
												letterSpacing: "0.17px",
												textWrap: "nowrap",
												overflow: "hidden",
												textOverflow: "ellipsis",
											}}
										>
											{skill.skillName}
										</Typography>
									</Tooltip>
								</Box>
								<Box ml={1} display='flex' flexWrap='wrap'>
									{skillsMap[skill.skillId].map((cert: any) => {
										if (
											workerCerts.includes(cert.id) ||
											obsoleteCertificates.includes(cert.id)
										) {
											return null;
										}
										return (
											<Tooltip title={cert.name} key={cert.name}>
												<Chip
													label={cert.name}
													onClick={() =>
														handleSelection && handleSelection(cert.id)
													}
													sx={{
														margin: "8px 4px 8px 0",
														textWrap: "nowrap",
														overflow: "hidden",
														textOverflow: "ellipsis",
														maxWidth: "320px",
													}}
													color={
														selectedCertifications.includes(cert.id)
															? "primary"
															: "default"
													}
													onDelete={() => {
														handleSelection && handleSelection(cert.id);
													}}
													deleteIcon={
														selectedCertifications?.includes(cert.id) ? (
															<CheckIcon />
														) : (
															<></>
														)
													}
												/>
											</Tooltip>
										);
									})}
								</Box>
							</Box>
							<Divider />
						</>
					))}
					<AdditionalCertifications
						selectedCertifications={selectedCertifications}
						handleSelection={handleSelection}
						removeMultipleCerts={removeMultipleCerts}
						additionalSkills={additionalSkills}
					/>
				</Box>
			</Box>
			<Box>
				<Divider />
				<ActionButtons
					closeDialog={onClose}
					selectedCertifications={selectedCertifications}
					status={addWorkerCertificationStatus}
				/>
			</Box>
		</Box>
	);
};

export default SelectCertificationsForm;
