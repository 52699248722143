import { differenceInMinutes } from "date-fns";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
	getAllShiftTypes,
	resetCreateNewSchedule,
} from "../../../../../../../export/gat-admin";
import useShiftScheduleRulesData from "./use-shift-schedule-rules-data";

const useShiftScheduleRulesController = (locationData: any) => {
	const dispatch = useDispatch();

	const {
		getAllShiftTypesStatus,
		getAllShiftTypesData,
		getAllShiftTypesError,
		createNewScheduleStatus,
		createNewScheduleError,
		editShiftSchedule,
		deleteShiftScheduleStatus,
		deleteShiftScheduleError,
		newLocationId,
	} = useShiftScheduleRulesData();

	const [isScheduleRulesDialogOpen, setIsScheduleRulesDialogOpen] =
		useState(false);

	const openScheduleRulesDialog = () => {
		setIsScheduleRulesDialogOpen(true);
	};

	const closeScheduleRulesDialog = () => {
		setIsScheduleRulesDialogOpen(false);
	};

	const getDuration = (
		startTime: string,
		endTime: string,
		breakDuration: number
	) => {
		const [hours1, minutes1] = startTime.split(":").map(Number);
		const [hours2, minutes2] = endTime.split(":").map(Number);

		const date1 = new Date(0, 0, 0, hours1, minutes1);
		const date2 = new Date(0, 0, 0, hours2, minutes2);

		if (date2 < date1) {
			date2.setDate(date2.getDate() + 1);
		}

		const minutes = differenceInMinutes(date2, date1);
		if (breakDuration) {
			const diff = (minutes - breakDuration) / 60;
			return diff > 0 ? diff.toFixed(2) : 0;
		}
		const hours = minutes / 60;

		return hours.toFixed(2);
	};

	useEffect(() => {
		if (
			createNewScheduleStatus === "fulfilled" ||
			editShiftSchedule.status === "fulfilled"
		) {
			if (locationData.id !== undefined)
				dispatch(getAllShiftTypes(locationData.id));
			else if (newLocationId !== undefined)
				dispatch(getAllShiftTypes(newLocationId));
			closeScheduleRulesDialog();
		}
	}, [
		createNewScheduleStatus,
		dispatch,
		editShiftSchedule.status,
		locationData.id,
		newLocationId,
	]);

	useEffect(() => {
		if (locationData.id) dispatch(getAllShiftTypes(locationData.id));
		else if (newLocationId) dispatch(getAllShiftTypes(newLocationId));
	}, [dispatch, locationData.id, newLocationId]);

	return [
		{
			isScheduleRulesDialogOpen,
			getAllShiftTypesStatus,
			getAllShiftTypesData,
			getAllShiftTypesError,
			createNewScheduleStatus,
			createNewScheduleError,
			editShiftSchedule,
			deleteShiftScheduleStatus,
			deleteShiftScheduleError,
		},
		{
			openScheduleRulesDialog,
			closeScheduleRulesDialog,
			resetCreateNewSchedule,
			dispatch,
			getDuration,
		},
	] as const;
};

export default useShiftScheduleRulesController;
