/* eslint-disable no-mixed-spaces-and-tabs */
import { isArray } from "lodash";
import { useSelector } from "react-redux";

type Workcenter = {
	id: number;
	name: string;
	location: {
		id: number;
		name: string;
		isActive: boolean;
	};
};
type Manager = {
	id: number;
	name: string;
	firstName: string;
	lastName: string;
};

const useTeamsData = () => {
	const companyManagers: Manager[] = useSelector(
		(state: any) => state.gatAdminConfiguration.getCompanyManagers.data
	);

	const allManagers = isArray(companyManagers)
		? companyManagers.map((manager: Manager) => ({
				...manager,
				name: manager.firstName + " " + manager.lastName,
		  }))
		: [];

	const allWorkcenters: Workcenter[] = useSelector(
		(state: any) => state.gatAdminConfiguration.allCompanyWorkCenters
	);

	const allShiftSchedules = useSelector(
		(state: any) => state.gatAdminConfiguration.getAllShiftTypes.data
	);

	const getShiftSchedulesStatus = useSelector(
		(state: any) => state.gatAdminConfiguration.getAllShiftTypes.status
	);

	const locationId = useSelector(
		(state: any) => state.gatAdminConfiguration.currentLocationData.id
	);
	const companyId = useSelector(
		(state: any) => state.gatAdminConfiguration.currentCompanyData.id
	);

	const teams = useSelector((state: any) => state.gatAdminConfiguration.teams);

	const createNewTeamStatus = useSelector(
		(state: any) => state.gatAdminConfiguration.createNewTeam.status
	);

	const createNewTeamError = useSelector(
		(state: any) => state.gatAdminConfiguration.createNewTeam.errorMessage
	);

	const editTeamStatus = useSelector(
		(state: any) => state.gatAdminConfiguration.editTeam.status
	);

	const editTeamError = useSelector(
		(state: any) => state.gatAdminConfiguration.editTeam.errorMessage
	);

	const editTeamData = useSelector(
		(state: any) => state.gatAdminConfiguration.editTeam
	);

	const shiftPatternRemoveErrorMessage = useSelector(
		(state: any) => state.gatAdminConfiguration.removeShiftPattern.errorMessage
	);

	const shiftPatternRemoveStatus = useSelector(
		(state: any) => state.gatAdminConfiguration.removeShiftPattern.status
	);

	return {
		allManagers,
		allWorkcenters,
		allShiftSchedules,
		getShiftSchedulesStatus,
		locationId,
		teams,
		companyId,
		createNewTeamStatus,
		createNewTeamError,
		editTeamData,
		editTeamStatus,
		editTeamError,
		shiftPatternRemoveStatus,
		shiftPatternRemoveErrorMessage,
	} as const;
};

export default useTeamsData;
