import { Box, Switch, Typography } from "@mui/material";
import React from "react";

const ShiftSwapRules = ({ newShiftSettings, setNewShiftSettings }:any) => {
	if (Object.keys(newShiftSettings).length === 0) return null;
	return (
		<Box
			sx={{
				backgroundColor: "#F5F5F5",
				borderRadius: "8px",
				padding: "16px",
				marginTop: "32px",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 400,
							fontSize: "16px",
							color: "rgba(0, 0, 0, 0.87)",
						}}
					>
						Shift swap
					</Typography>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 400,
							fontSize: "12px",
							color: "rgba(0, 0, 0, 0.6)",
						}}
					>
						Workers can swap their shifts with other workers.
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Switch
						checked={newShiftSettings.swapEnabled ?? false}
						onChange={(e) =>
							setNewShiftSettings((prevSettings:any) => ({
								...prevSettings,
								swapEnabled: e.target.checked,
							}))
						}
					/>
				</Box>
			</Box>
			{newShiftSettings.swapEnabled ? (
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "space-between",
						marginTop: "20px",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
						}}
					>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 400,
								fontSize: "16px",
								color: "rgba(0, 0, 0, 0.87)",
							}}
						>
							Supervisor approval required for shift swap requests
						</Typography>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 400,
								fontSize: "12px",
								color: "rgba(0, 0, 0, 0.6)",
							}}
						>
							Shift swap request(s) are sent to workers after approval or
							directly.
						</Typography>
					</Box>
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<Switch
							checked={newShiftSettings.swapApprovalRequired ?? false}
							onChange={(e) =>
								setNewShiftSettings((prevSettings:any) => ({
									...prevSettings,
									swapApprovalRequired: e.target.checked,
								}))
							}
						/>
					</Box>
				</Box>
			) : null}
		</Box>
	);
};

export default ShiftSwapRules;
