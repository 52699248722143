import React from "react";
import {useSelector} from "react-redux";

import SkillCell from "../../../../../../ts/design/skill-representaion-cell";

type SkillTagProps = {
	defaultShiftsSkillLevel: number;
};

const SkillTag: React.FC<SkillTagProps> = ({ defaultShiftsSkillLevel }) => {
	const skillLevels = useSelector(
		(state: any) => state.supervisor.masterData.allSkillLevels
	);
	return (
		<SkillCell
			value={defaultShiftsSkillLevel}
			height={20}
			width={20}
			backgroundWidth={"25px"}
			backgroundHeight={"25px"}
			skillLevels={skillLevels}
		/>
	);
};

export default SkillTag;
