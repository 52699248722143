import { Add } from "@mui/icons-material";
import { Box, Button, Snackbar, Typography } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import React from "react";
import { useDispatch } from "react-redux";

import {
	closeEditWorkerCertificationDialog,
	resetAddWorkerCertifications,
	resetDeleteWorkerCertifications,
	resetEditWorkerCertification,
	resetPostDeleteFiles,
	resetPostUploadFiles,
} from "../../../../../export/workers";
import SectionSearch from "../../../../design/section-search";
import useWorkersData from "../../controllers/use-workers-data";
import useWorkerCertificationsController from "./controllers/use-worker-certifications-controller";
import AddWorkerCertificationDialog from "./ui/dialog/add-worker-certifications";
import EditWorkerCertificationDialog from "./ui/dialog/edit-worker-certification";
import WorkerCertificationsTable from "./ui/table/worker-certifications-table";

const WorkerCertifications = () => {
	const dispatch = useDispatch();
	const {
		isEditWorkerCertificationDialogOpen,
		editWorkerCertificationStatus,
		postDeleteFilesStatus,
		postUploadFilesStatus,
		failedFileDeleteCount,
		failedFileUploadCount,
		workerProfile,
	} = useWorkersData();
	const [
		{
			isAddCertificationDialogOpen,
			deleteCertificationError,
			deleteCertificationStatus,
			addWorkerCertificationError,
			addWorkerCertificationStatus,
			searchText,
		},
		{ openAddCertificationDialog, closeAddCertificationDialog, setSearchText },
	] = useWorkerCertificationsController();

	// Check if any operation has failed
	const isAnyOperationError =
		editWorkerCertificationStatus === "error" ||
		postDeleteFilesStatus === "error" ||
		postUploadFilesStatus === "error";

	// Check if any operation is successful
	const isAnyOperationFulfilled =
		editWorkerCertificationStatus === "fulfilled" ||
		postDeleteFilesStatus === "fulfilled" ||
		postUploadFilesStatus === "fulfilled";

	// Check if any operation is pending
	const isAnyOperationPending =
		editWorkerCertificationStatus === "pending" ||
		postDeleteFilesStatus === "pending" ||
		postUploadFilesStatus === "pending";

	// Helper function to close all Snackbars and reset statuses
	const handleCloseSnackbars = () => {
		dispatch(resetEditWorkerCertification());
		dispatch(resetPostDeleteFiles());
		dispatch(resetPostUploadFiles());
	};

	if (!isAnyOperationPending) {
		if (isAnyOperationFulfilled && !isAnyOperationError) {
			dispatch(closeEditWorkerCertificationDialog());
		}
	}
	return (
		<Box
			sx={{
				borderRadius: "10px",
				border: "3px solid #f4f4f4",
			}}
			mb={5}
			mt={5}
		>
			<Box p={2}>
				<Box>
					<Typography fontWeight='bold'>
						Assign certifications (optional)
					</Typography>
					<Typography variant='caption'>
						Add certifications that the worker has and dates they are valid.
					</Typography>
				</Box>
				<Box
					display={"flex"}
					alignItems={"center"}
					justifyContent={"space-between"}
					mt={2}
				>
					<SectionSearch
						placeholder='Search'
						value={searchText ? searchText : ""}
						setSearch={setSearchText}
					/>
					<Box>
						<Button startIcon={<Add />} onClick={openAddCertificationDialog}>
							Certifications
						</Button>
					</Box>
				</Box>
			</Box>

			<Box
				sx={{
					padding: "12px 8px",
				}}
			>
				<WorkerCertificationsTable
					workerProfile={workerProfile}
					searchText={searchText ? searchText : ""}
				/>
			</Box>
			{isAddCertificationDialogOpen && (
				<AddWorkerCertificationDialog
					isOpen={isAddCertificationDialogOpen}
					onClose={closeAddCertificationDialog}
				/>
			)}

			{isEditWorkerCertificationDialogOpen && <EditWorkerCertificationDialog />}
			<Snackbar
				open={addWorkerCertificationStatus === "fulfilled"}
				autoHideDuration={3000}
				onClose={() => dispatch(resetAddWorkerCertifications())}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<MuiAlert elevation={6} variant='filled' severity='success'>
					Certification added successfully
				</MuiAlert>
			</Snackbar>
			<Snackbar
				open={addWorkerCertificationStatus === "error"}
				autoHideDuration={3000}
				onClose={() => dispatch(resetAddWorkerCertifications())}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<MuiAlert elevation={6} variant='filled' severity='error'>
					{addWorkerCertificationError ?? "An error occurred"}
				</MuiAlert>
			</Snackbar>

			{/* Delete Certification for worker */}
			<Snackbar
				open={deleteCertificationStatus === "fulfilled"}
				autoHideDuration={3000}
				onClose={() => dispatch(resetDeleteWorkerCertifications())}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<MuiAlert elevation={6} variant='filled' severity='success'>
					Certification removed successfully
				</MuiAlert>
			</Snackbar>
			<Snackbar
				open={deleteCertificationStatus === "error"}
				autoHideDuration={3000}
				onClose={() => dispatch(resetDeleteWorkerCertifications())}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<MuiAlert elevation={6} variant='filled' severity='error'>
					{deleteCertificationError ?? "An error occurred"}
				</MuiAlert>
			</Snackbar>

			{/* Edit Certification for worker */}
			{!isAnyOperationPending && (
				<>
					{/* Success Snackbar */}
					<Snackbar
						open={!isAnyOperationError && isAnyOperationFulfilled}
						autoHideDuration={3000}
						onClose={() => {
							handleCloseSnackbars();
						}}
						anchorOrigin={{ vertical: "top", horizontal: "center" }}
					>
						<MuiAlert elevation={6} variant='filled' severity='success'>
							Certification edited successfully
						</MuiAlert>
					</Snackbar>

					{/* General Error Snackbar */}
					<Snackbar
						open={isAnyOperationError}
						autoHideDuration={3000}
						onClose={handleCloseSnackbars}
						anchorOrigin={{ vertical: "top", horizontal: "center" }}
					>
						<MuiAlert elevation={6} variant='filled' severity='error'>
							{editWorkerCertificationStatus === "error"
								? deleteCertificationError ?? "An error occurred"
								: postDeleteFilesStatus === "error"
								? `Failed to delete ${failedFileDeleteCount} file(s)`
								: `Failed to upload ${failedFileUploadCount} file(s)`}
						</MuiAlert>
					</Snackbar>
				</>
			)}
		</Box>
	);
};

export default WorkerCertifications;
