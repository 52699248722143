// TODO - Type 'any' needs to be fixed.
/* eslint-disable no-mixed-spaces-and-tabs */
import FactoryOutlinedIcon from "@mui/icons-material/FactoryOutlined";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import TimerOffOutlinedIcon from "@mui/icons-material/TimerOffOutlined";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import format from "date-fns/format";
import React, { useCallback } from "react";

import { SENIORITY } from "../../../assets/constants";
import {getHourMinuteDifference} from "../../../utils/helpers";
const ShiftMetaData = ({
	shiftMetadata,
	jobRoleMetadata,
	workCenterMetadata,
}:any) => {
	const closedForInterest = shiftMetadata?.windowClosingTime
		? new Date().getTime() >=
		  new Date(shiftMetadata.windowClosingTime).getTime()
		: false;

	const remainingHours = shiftMetadata?.windowClosingTime
		? getHourMinuteDifference(new Date(shiftMetadata.windowClosingTime), new Date()).differenceInText + " left"
		: -1;

	const getWorkCenterName = useCallback(() => {
		let returnValue = "-";
		if (workCenterMetadata) {
			returnValue = workCenterMetadata.name;
		}
		return returnValue;
	}, [workCenterMetadata]);

	const getLocationName = useCallback(() => {
		const returnValue = "-";
		if (workCenterMetadata) {
			if (
				Object.prototype.hasOwnProperty.call(workCenterMetadata, "location")
			) {
				if (
					Object.prototype.hasOwnProperty.call(
						workCenterMetadata.location,
						"name"
					)
				) {
					return workCenterMetadata.location.name;
				}
			}
		}
		return returnValue;
	}, [workCenterMetadata]);

	if (!shiftMetadata) return null;
	return (
		<Box
			display='flex'
			flexDirection='row'
			justifyContent='space-between'
			alignItems='center'
			sx={{
				paddingTop: "29px",
				paddingBottom: "11px",
				paddingLeft: "17px",
				paddingRight: "21px",
			}}
		>
			<Box>
				<Box>
					<Typography marginLeft='4px' variant='h6'>
						Shift Details
					</Typography>
				</Box>
				<Box display='flex' alignItems='center'>
					{JSON.stringify(jobRoleMetadata) !== "{}" && (
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
							}}
						>
							<WorkHistoryOutlinedIcon sx={{ height: "15px" }} />
							<Typography variant='subtitle2'>
								{jobRoleMetadata.name ?? ""}
							</Typography>
						</Box>
					)}
					{JSON.stringify(workCenterMetadata) !== "{}" && (
						<>
							<Box
								ml={2}
								sx={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
								}}
							>
								<FmdGoodOutlinedIcon sx={{ height: "15px" }} />
								<Typography variant='subtitle2'>{getLocationName()}</Typography>
							</Box>
							<Box
								ml={2}
								sx={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
								}}
							>
								<FactoryOutlinedIcon sx={{ height: "15px" }} />
								<Typography variant='subtitle2'>
									{getWorkCenterName()}
								</Typography>
							</Box>
						</>
					)}
					<Box ml={2.5}>
						<Typography variant='subtitle2'>
							{shiftMetadata.startDate
								? format(
										new Date(shiftMetadata?.startDate),
										"EEE, MMMM do yyyy h:mm a"
										// eslint-disable-next-line no-mixed-spaces-and-tabs
								  )
								: null}{" "}
							-{" "}
							{shiftMetadata.endDate
								? format(new Date(shiftMetadata?.endDate), "h:mm a")
								: null}{" "}
						</Typography>
					</Box>
					<Box ml={2.5}>
						<Typography variant='subtitle2'>
							{`${shiftMetadata.numberOfWorkersNeeded} worker${
								shiftMetadata.numberOfWorkersNeeded > 1 ? "s" : ""
							} required`}
						</Typography>
					</Box>
					{shiftMetadata.shiftAllocationType === SENIORITY && (
						<Box
							ml={2}
							sx={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
							}}
						>
							{closedForInterest ? (
								<TimerOffOutlinedIcon sx={{ height: "15px" }} />
							) : (
								<TimerOutlinedIcon sx={{ height: "15px" }} />
							)}
							<Typography variant='subtitle2'>
								{`${
									new Date(shiftMetadata.windowClosingTime).getDate() -
										new Date().getDate() ===
									1
										? `Tomorrow ${format(
												new Date(shiftMetadata.windowClosingTime),
												"h:mm a"
										  )}`
										: new Date(shiftMetadata.windowClosingTime).getDate() -
												new Date().getDate() ===
										  0
										? `Today ${format(
												new Date(shiftMetadata.windowClosingTime),
												"h:mm a"
										  )}`
										: new Date(shiftMetadata.windowClosingTime).getDate() -
												new Date().getDate() ===
										  -1
										? `Yesterday ${format(
												new Date(shiftMetadata.windowClosingTime),
												"h:mm a"
										  )}`
										: `${format(
												new Date(shiftMetadata.windowClosingTime),
												"MMMM do yyyy, h:mm a"
										  )}`
								} (${
									closedForInterest
										? "window closed"
										: remainingHours
								})`}
							</Typography>
						</Box>
					)}
				</Box>
			</Box>
		</Box>
	);
};

export default ShiftMetaData;
