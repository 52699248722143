// TODO - Type 'any' needs to be fixed.
import { Alert, Box, Snackbar } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import format from "date-fns/format";
import React from "react";
import { useDispatch } from "react-redux";

import DialogWrapper from "../../../../../design/dialog-wrapper";
import { resetMarkAttendance } from "../../../store/scheduler-slice";
import ActionButtons from "./action-buttons";
import useMarkAttendanceDialog from "./controllers/use-mark-attendance-dialog";
import MarkAttendanceTable from "./mark-attendance-table";

interface MarkAttendanceDialogProps {
	handleClose: any;
	open: boolean;
	shiftId: number;
}

const MarkAttendanceDialog: React.FC<MarkAttendanceDialogProps> = ({
	handleClose,
	open,
	shiftId,
}) => {
	const [
		{
			rows,
			shiftMetadata,
			shiftAttendanceDetailsStatus,
			markAttendanceError,
			markAttendanceStatus,
		},
	] = useMarkAttendanceDialog(shiftId);

	const dispatch = useDispatch();

	return (
		<>
		<Dialog
			onClose={(event, reason) => {
				if (reason !== "backdropClick" && handleClose) {
					handleClose();
				}
			}}
			aria-labelledby='add-new-shift'
			open={open}
			maxWidth='lg'
		>
			<DialogWrapper
				dialogTitle='Mark Attendance'
				dialogSubTitle={`${
					shiftMetadata?.startDate &&
					format(new Date(shiftMetadata?.startDate), "EEE, MMMM do yyyy h:mm a")
				} - ${
					shiftMetadata?.endDate
						? format(new Date(shiftMetadata?.endDate), "h:mm a")
						: null
				}`}
				dialogContent={
					<MarkAttendanceTable
						rows={rows}
						isLoading={shiftAttendanceDetailsStatus === "pending" || markAttendanceStatus === "pending"}
					/>
				}
				actionButtons={
					<ActionButtons
						onClose={handleClose}
						markAttendanceError={markAttendanceError}
						markAttendanceStatus={markAttendanceStatus}
					/>
				}
			/>
		</Dialog>
		<Snackbar
				open={markAttendanceStatus === "fulfilled"}
				autoHideDuration={3000}
				onClose={() => dispatch(resetMarkAttendance())}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<Alert elevation={6} variant='filled' severity='success'>
					Attendance Marked Successfully
				</Alert>
			</Snackbar>

			<Snackbar
				open={markAttendanceStatus === "error"}
				autoHideDuration={3000}
				onClose={handleClose}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<Alert elevation={6} variant="filled" severity="error" onClose={handleClose}>
					<Box display="flex" flexDirection="column" alignItems="center">
						<Box mb={1}>
							{markAttendanceError ?? "An Error occurred. Please try again."}
						</Box>
					</Box>
				</Alert>
			</Snackbar>
		</>
	);
};

export default MarkAttendanceDialog;
