// TODO - Type 'any' needs to be fixed.
import { Box, Divider, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { JobColor } from "../../../../../../../../export/workers";
import SkillLevelDropdown from "../../../../../../../design/skill-level-dropdown";
import useWorkersData from "../../../../../controllers/use-workers-data";
import useAddSkillDialog from "../../../controllers/use-add-skill-dialog";
import ActionButtons from "./action-buttons";
import AssignOtherSkills from "./assign-other-skills";

interface AssignSkillFormProps {
	closeDialog?: () => void;
}

const AssignSkillForm: React.FC<AssignSkillFormProps> = ({ closeDialog }) => {
	const [
		{
			allSkillLevels,
			allJobsObj,
			workerJobs,
			skillsMappedWithJob,
			workerAssignedSkills,
			newAssignedSkills,
			addWorkerSkillStatus,
			obsoleteSkills,
			workerJobWithUnassignedSkills,
		},
		{ handleNewAssignedSkills, removeMultipleSkills },
	] = useAddSkillDialog(closeDialog);

	// console.log("workerJobs", workerJobs, workerJobWithUnassignedSkills);

	return (
		<Box
			width={800}
			display='flex'
			flexDirection='column'
			justifyContent='space-between'
			sx={{
				height: 520,
				overflowY: "hidden",
			}}
		>
			<Box>
				<Box display='flex'>
					<Box width={200}>
						{" "}
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 500,
								fontSize: "14px",
								color: "#000000",
								lineHeight: "24px",
								letterSpacing: "0.17px",
							}}
						>
							Worker&apos;s Job Roles
						</Typography>
					</Box>
					<Box ml={1}>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 500,
								fontSize: "14px",
								color: "#000000",
								lineHeight: "24px",
								letterSpacing: "0.17px",
							}}
						>
							Skills to be Added
						</Typography>
					</Box>
				</Box>
				<Divider />
				<Box
					sx={{
						overflowY: "auto",
						height: 420,
					}}
				>
					{workerJobs.length === 0 ? (
						<Box mt={2}>
							{" "}
							<Typography
								sx={{
									fontFamily: "Roboto",
									fontWeight: 400,
									fontSize: "14px",
									fontStyle: "italic",
									color: "#00000099",
									lineHeight: "24px",
									letterSpacing: "0.17px",
								}}
							>
								No Jobs have been added for the Worker. Add Skills from
								Additional Skills.
							</Typography>
						</Box>
					) : (
						workerJobWithUnassignedSkills.length === 0 && (
							<Box mt={2}>
								{" "}
								<Typography
									sx={{
										fontFamily: "Roboto",
										fontWeight: 400,
										fontSize: "14px",
										fontStyle: "italic",
										color: "#00000099",
										lineHeight: "24px",
										letterSpacing: "0.17px",
									}}
								>
									All Skills for the Worker’s Jobs have been added. Add Skills
									from Additional Skills.
								</Typography>
							</Box>
						)
					)}
					{workerJobWithUnassignedSkills.length > 0 && (
						<Box>
							{workerJobWithUnassignedSkills.map((job: any) => {
								return (
									<>
										<Box
											key={`jobid-${job.jobId}`}
											display='flex'
											mt={1}
											mb={1}
										>
											<Box
												minWidth={200}
												maxWidth={200}
												display='flex'
												//flexWrap='wrap'
												alignItems='center'
												//justifyContent='flex-start'
											>
												<Box height={10} width={10} mr={2} mb={1}>
													<JobColor color={`#${job.color}`} />
												</Box>

												<Typography
													sx={{
														fontFamily: "Roboto",
														fontWeight: 400,
														fontSize: "14px",
														color: "#000000",
														lineHeight: "28px",
														letterSpacing: "0.17px",
														overflow: "hidden",
														textOverflow: "ellipsis",
														textWrap: "wrap",
													}}
												>
													{job.jobName}
												</Typography>
											</Box>
											<Box sx={{
												display: "grid",
												gridTemplateColumns: "1fr 1fr",
												width: "100%",
											}}>
												{allJobsObj[job.jobId].skills
													?.filter(
														(skill: any) =>
															!workerAssignedSkills?.includes(skill.skillId) &&
															!obsoleteSkills?.includes(skill.skillId)
													)
													.map((skill: any) => (
														<Box
															key={`skill${skill.skillId} - job ${job.jobId}`}
															m={1}
															flex={1}
														>
															<SkillLevelDropdown
																allSkillLevels={allSkillLevels}
																skillName={skill.name}
																skillId={skill.skillId}
																handleSkill={handleNewAssignedSkills}
																newAssignedSkills={newAssignedSkills}
															/>
														</Box>
													))}
											</Box>
										</Box>
										<Divider />
									</>
								);
							})}
						</Box>
					)}
					<AssignOtherSkills
						workerAssignedSkills={workerAssignedSkills}
						handleNewAssignedSkills={handleNewAssignedSkills}
						newAssignedSkills={newAssignedSkills}
						skillsMappedWithJob={skillsMappedWithJob}
						removeMultipleSkills={removeMultipleSkills}
					/>
				</Box>
			</Box>
			<Box>
				<Divider />
				<ActionButtons
					newAssignedSkills={newAssignedSkills}
					status={addWorkerSkillStatus}
					closeDialog={closeDialog}
				/>
			</Box>
		</Box>
	);
};

export default AssignSkillForm;
