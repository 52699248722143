import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "../../../../js/utils/api-client"
import { WorkerInsightReportSliceStateTypes } from "./insight-report-types";


const initialState: WorkerInsightReportSliceStateTypes = {

    fetchWorkerInsightReport: {
        status: "idle",
        errorMessage: ""
    },
    workerInsightReportData: [],

    fetchskilllevels: {
        status: "idle",
        errorMessage: ""
    },
    skillsLevelLegendsData: [],

    fetchWorkerInsightDetails: {
        status: "idle",
        errorMessage: ""
    },
    workerInsightDetailsData: {
        interestedSkills: [],
        skills: [],
        workerId: ""
    },
    workerInsightPanelDetails: {
        isOpen: false,
        workerId: "",
        locations: []
    },

    departmentInsightPanelDetails: {
        isOpen: false,
        id: "",
        name: "",
    },
}

//Get all worker insight
export const getWorkerInsightReport = createAsyncThunk(
    "workerInsightReport/getWorkerInsightReport",
    async (payload, thunkAPI) => {
        try {

            const workerInsightResponse = await axios.get(
                `${process.env.REACT_APP_API_END_POINT}/Insight/GetWorkerInsightForUser/workerinsight`
            );

            return thunkAPI.fulfillWithValue(workerInsightResponse.data)
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

//Get all skill levels
export const fetchskilllevels = createAsyncThunk(
    "workerInsightReport/fetchSkillsLevelLegend",
    async (payload, thunkAPI) => {
        try {

            const skillsLevelsResponse = await axios.get(
                `${process.env.REACT_APP_API_END_POINT}/Insight/GetCompanySkillLevels/skilllevels`
            );

            return thunkAPI.fulfillWithValue(skillsLevelsResponse.data)
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

//Get worker insight details by workerId
export const fetchWorkerInsightDetails = createAsyncThunk(
    "workerInsightReport/fetchWorkerInsight",
    async (payload:string, { fulfillWithValue, rejectWithValue }) => {
        try {
            const workerDetailsResponse = await axios.get(
                `${process.env.REACT_APP_API_END_POINT}/Insight/GetWorkerInsightDetails/workerinsight/${payload}`
            );
            return fulfillWithValue({ ...workerDetailsResponse.data, workerId: payload });
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const WorkerInsightReportSlice = createSlice({
    name: "workerInsightReport",
    initialState,
    reducers: {
        openWorkerInsightDetails: (state, action) => {
            state.workerInsightPanelDetails.isOpen = true;
            state.workerInsightPanelDetails.workerId = action.payload.workerId;
            state.workerInsightPanelDetails.locations = action.payload.locations
        },
        closeWorkerInsightDetails: (state) => {
            state.workerInsightPanelDetails.isOpen = false;
            state.workerInsightPanelDetails.workerId = "";
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getWorkerInsightReport.fulfilled, (state, action) => {
                const workerInsightData = action.payload && action.payload.length > 0 ? action.payload : []
                state.fetchWorkerInsightReport.status = "fulfilled";
                state.workerInsightReportData = workerInsightData;
            })
            .addCase(getWorkerInsightReport.rejected, (state, action) => {
                state.fetchWorkerInsightReport.status = "error";
                state.fetchWorkerInsightReport.errorMessage = action.payload ?? "An error occurred";
            })
            .addCase(getWorkerInsightReport.pending, (state) => {
                state.fetchWorkerInsightReport.status = "pending";
            })
            .addCase(fetchskilllevels.fulfilled, (state, action) => {
                state.fetchskilllevels.status = "fulfilled";
                state.skillsLevelLegendsData = action.payload;
            })
            .addCase(fetchskilllevels.rejected, (state, action) => {
                state.fetchskilllevels.status = "error";
                state.fetchskilllevels.errorMessage = action.payload ?? "An error occurred";
            })
            .addCase(fetchskilllevels.pending, (state) => {
                state.fetchskilllevels.status = "pending";
            })
            .addCase(fetchWorkerInsightDetails.fulfilled, (state, action) => {
                state.fetchWorkerInsightDetails.status = "fulfilled";
                state.workerInsightDetailsData = action.payload;
            })
            .addCase(fetchWorkerInsightDetails.rejected, (state, action) => {
                state.fetchWorkerInsightDetails.status = "error";
                state.fetchWorkerInsightDetails.errorMessage = action.payload ?? "An error occurred";
            })
            .addCase(fetchWorkerInsightDetails.pending, (state) => {
                state.fetchWorkerInsightDetails.status = "pending";
            });
    },
})

export const {
    openWorkerInsightDetails,
    closeWorkerInsightDetails
} = WorkerInsightReportSlice.actions;

export default WorkerInsightReportSlice.reducer;