import React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
};

// eslint-disable-next-line react/display-name
const withScrollToTop = (Component: React.ComponentType) => (props: any) => {
  useScrollToTop();
  return <Component {...props} />;
};

export default withScrollToTop;
