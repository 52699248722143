import { Box } from "@mui/material";
import React from "react";

import { FULL, HALF, QUARTER, THREE_QUARTER } from "../constants/constants";
interface BoxSkillRepresentationProps {
	representationLevel?: number | string;
}

function BoxSkillRepresentation({
	representationLevel,
}: BoxSkillRepresentationProps) {
	let styleBox1 = {};
	let styleBox2 = {};
	let styleBox3 = {};
	let styleBox4 = {};
	switch (representationLevel) {
		case 1:
		case QUARTER:
			styleBox2 = { backgroundColor: "#000" };
			break;
		case 2:
		case HALF:
			styleBox2 = { backgroundColor: "#000" };
			styleBox4 = { backgroundColor: "#000" };
			break;
		case 3:
		case THREE_QUARTER:
			styleBox2 = { backgroundColor: "#000" };
			styleBox4 = { backgroundColor: "#000" };
			styleBox3 = { backgroundColor: "#000" };
			break;
		case 4:
		case FULL:
			styleBox1 = { backgroundColor: "#000" };
			styleBox2 = { backgroundColor: "#000" };
			styleBox3 = { backgroundColor: "#000" };
			styleBox4 = { backgroundColor: "#000" };
			break;
		default:
			break;
	}

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				border: 1,
				borderRadius: "3px",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<Box
					sx={{
						// CHANGE to use in skill chip in select skills width/height
						width: "8px",
						height: "8px",
						border: 1,
					}}
					style={{ ...styleBox1 }}
				></Box>
				<Box
					sx={{
						width: "8px",
						height: "8px",
						border: 1,
					}}
					style={{ ...styleBox2 }}
				></Box>
			</Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<Box
					sx={{
						width: "8px",
						height: "8px",
						border: 1,
					}}
					style={{ ...styleBox3 }}
				></Box>
				<Box
					sx={{
						width: "8px",
						height: "8px",
						border: 1,
					}}
					style={{ ...styleBox4 }}
				></Box>
			</Box>
		</Box>
	);
}

export default BoxSkillRepresentation;
