import { useMemo, useState } from "react";

import { CustomColumnDefinition } from "../../../utils/types";

interface ColumnSelectorState {
  selectedColumns: string[];
  pickerColumns: CustomColumnDefinition[];
}

interface ColumnSelectorActions {
  handleSelectColumns: (checked: boolean, column: string) => void;
}

type UseColumnSelectorReturn = [
  ColumnSelectorState,
  ColumnSelectorActions
];

const useColumnSelector = (
  defaultColumns: CustomColumnDefinition[],
  restColumns: CustomColumnDefinition[],
  uncheckedColumns: CustomColumnDefinition[] = []
): UseColumnSelectorReturn => {
  const [rest, setRest] = useState(restColumns.concat(uncheckedColumns));

  const selectedColumns = useMemo(() => {
    const temp = rest.filter((c) => c.checked);
    return [...defaultColumns, ...temp];
  }, [rest, defaultColumns]);

  const handleSelectColumns = (checked: boolean, column: string) => {
    setRest((prevRest) =>
      prevRest.map((c) => (c.field === column ? { ...c, checked } : c))
    );
  };

  return [
    {
      selectedColumns: selectedColumns
        .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
        .filter((c) => c.checked)
        .map((c) => c.field),
      pickerColumns: rest,
    },
    {
      handleSelectColumns,
    },
  ];
};

export default useColumnSelector;
