import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { Box, Tooltip, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro-6";
import React, { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { setCurrentCompanyData } from "../../../../../export/gat-admin";
import Highlight from "../../../../components/Highlight";
import SortedDatagridPro6 from "../../../../design/sorted-datagrid-pro-6";
import useViewport from "../../../../utils/use-viewport";
import { setDemoSetupOwnerDialog } from "../../store/gat-admin-slice";
import { OneCompanyWithSearchField } from "../../store/gat-admin-slice-types";


interface IFactoryTableProps {
    filteredCompanies: OneCompanyWithSearchField[]
    companiesLoadingStatus: string
    isBoeing: boolean
    creatingNewCompany: boolean
    setCreatingNewCompany: (value: boolean) => void;
    searchText: string
    setStateDemoOwnerCompanyObj: Dispatch<SetStateAction<any>>
}

const FactoryTable: React.FC<IFactoryTableProps> = ({
    filteredCompanies,
    companiesLoadingStatus,
    isBoeing,
    creatingNewCompany,
    setCreatingNewCompany,
    searchText,
    setStateDemoOwnerCompanyObj
}) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const { height } = useViewport();
    const boxRef: any = useRef();

    const roles = useSelector((state: any) => state.user?.userData?.userRoles);

    const handleClick = (params: any) => {
        if (companiesLoadingStatus !== "pending") {
            setCreatingNewCompany(false);
            dispatch(setCurrentCompanyData(params.row));
            if (isBoeing) {
                history.push({
                    pathname: "/admin-settings",
                    state: { data: params.row, creatingNewCompany },
                });
            } else {
                history.push({
                    pathname: "/admin-settings",
                    state: { data: params.row, creatingNewCompany },
                });
            }
        }
    };

    const columns: GridColDef[] = [
        { field: "id", headerName: "ID", width: 70 },
        {
            field: "name",
            headerName: "Company Name",
            minWidth: 200,
            flex: 1.5,
            renderHeader: () => (
                <span style={{ fontWeight: "bold" }}>{"Company Name"}</span>
            ), renderCell: (params) => {
                return (
                    <Tooltip title={params.row.name}>
                        <Typography
                            sx={{
                                fontFamily: "Roboto",
                                fontSize: "14px",
                                color: "rgba(0, 0, 0, 0.87)",
                            }}
                        >
                            <Highlight value={params.row.name} searchText={searchText} />
                        </Typography>
                    </Tooltip>
                );
            },
        },
        {
            field: "locationCount",
            headerName: "Locations",
            minWidth: 200,
            flex: 1,
            headerAlign: "center",
            align: "center",
            renderHeader: () => (
                <span style={{ fontWeight: "bold" }}>{"Locations"}</span>
            ), renderCell: (params) => {
                return (
                    <Tooltip title={params.row.locationCount}>
                        <Typography
                            sx={{
                                fontFamily: "Roboto",
                                fontSize: "14px",
                                color: "rgba(0, 0, 0, 0.87)",
                            }}
                        >
                            <Highlight value={params.row.locationCount === 0 ? "0" : params.row.locationCount} searchText={searchText} />
                        </Typography>
                    </Tooltip>
                );
            },
        },
        {
            field: "userCount",
            headerName: "Users",
            minWidth: 100,
            headerAlign: "center",
            align: "center",
            flex: 1,
            renderHeader: () => (
                <span style={{ fontWeight: "bold" }}>{"Users"}</span>
            ), renderCell: (params) => {
                return (
                    <Tooltip title={params.row.userCount}>
                        <Typography
                            sx={{
                                fontFamily: "Roboto",
                                fontSize: "14px",
                                color: "rgba(0, 0, 0, 0.87)",
                            }}
                        >
                            <Highlight value={params.row.userCount === 0 ? "0" : params.row.userCount} searchText={searchText} />
                        </Typography>
                    </Tooltip>
                );
            },

        },
        {
            field: "workerCount",
            headerName: "Workers",
            headerAlign: "center",
            align: "center",
            flex: 1,
            minWidth: 100,
            renderHeader: () => (
                <span style={{ fontWeight: "bold" }}>{"Workers"}</span>
            ), renderCell: (params) => {
                return (
                    <Tooltip title={params.row.workerCount}>
                        <Typography
                            sx={{
                                fontFamily: "Roboto",
                                fontSize: "14px",
                                color: "rgba(0, 0, 0, 0.87)",
                            }}
                        >
                            <Highlight value={params.row.workerCount === 0 ? "0" : params.row.workerCount} searchText={searchText} />
                        </Typography>
                    </Tooltip>
                );
            },

        },
        {
            field: "createdBy",
            headerName: "Created By",
            minWidth: 200,
            flex: 1.5,
            renderHeader: () => (
                <span style={{ fontWeight: "bold" }}>{"Created By"}</span>
            ), renderCell: (params) => {
                return (
                    <Tooltip title={params.row.createdBy}>
                        <Typography
                            sx={{
                                fontFamily: "Roboto",
                                fontSize: "14px",
                                color: "rgba(0, 0, 0, 0.87)",
                            }}
                        >
                            <Highlight value={params.row.createdBy} searchText={searchText} />
                        </Typography>
                    </Tooltip>
                );
            }
        },
        {
            field: "updatedBy",
            headerName: "Updated By",
            minWidth: 200,
            flex: 1.5,
            renderHeader: () => (
                <span style={{ fontWeight: "bold" }}>{"Updated By"}</span>
            ), renderCell: (params) => {
                return (
                    <Tooltip title={params.row.updatedBy}>
                        <Typography
                            sx={{
                                fontFamily: "Roboto",
                                fontSize: "14px",
                                color: "rgba(0, 0, 0, 0.87)",
                            }}
                        >
                            <Highlight value={params.row.updatedBy} searchText={searchText} />
                        </Typography>
                    </Tooltip>
                );
            }
        },
    ];

    //this column is for demo purpose only
    if (process.env.REACT_APP_ENV === "dev" || process.env.REACT_APP_ENV === "local" || process.env.REACT_APP_ENV === "demo") {
        columns.push(
            {
                field: "demoOwner",
                headerName: "Demo Owner",
                minWidth: 200,
                flex: 1,
                renderHeader: () => (
                    <span style={{ fontWeight: "bold" }}>{"Demo Owner"}</span>
                ), renderCell: (params) => {
                    const demoOwner = params.row.users?.find((x: any) => x.isDemoResetRole)
                    return (
                        <Tooltip title={demoOwner !== undefined ? demoOwner.userName : ""}>
                            <Typography
                                sx={{
                                    fontFamily: "Roboto",
                                    fontSize: "14px",
                                    color: "rgba(0, 0, 0, 0.87)",
                                }}
                            >

                                <Highlight value={demoOwner !== undefined ? demoOwner.userName : ""} searchText={searchText} />
                            </Typography>
                        </Tooltip>
                    );
                }
            },
        )
    }

    //if the current logged in user has demo reset access ,then he can change the demo owner

    if ((process.env.REACT_APP_ENV === "dev" || process.env.REACT_APP_ENV === "local" || process.env.REACT_APP_ENV === "demo") && roles.includes("DemoResetRole")) {
        columns.push({
            field: "icon",
            headerName: "Actions",
            headerAlign: "right",
            align: "right",
            width: 100,
            cellClassName: "hover-column-cell",
            headerClassName: "hover-column-header",
            sortable: false,
            flex: 0.5,
            renderHeader: () => (
                <span
                    style={{
                        fontWeight: "bold",
                        width: "74px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    {""}
                </span>
            ),
            renderCell: (params) => (
                <Box
                    className='hover-content'
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100px",
                        padding: "12px 0px 2px 0px",
                        zIndex: 1,
                    }}
                >
                    <Box
                        sx={{
                            color: "#6c6c6c",
                            "&:hover": {
                                color: "#000000",
                                transform: "scale(1.2)",
                            },
                            cursor: "pointer",
                        }}
                    >
                        <Tooltip title='Edit'>
                            <ManageAccountsIcon
                                id={`edit-job-${params?.row?.id}-enabled`}
                                fontSize='small'
                                sx={{ cursor: "pointer" }}
                                onClick={(e) => {
                                    setStateDemoOwnerCompanyObj(params.row)
                                    dispatch(setDemoSetupOwnerDialog(true));
                                    e.stopPropagation();
                                }}
                            />
                        </Tooltip>
                    </Box>
                </Box>
            ),
        })
    }

    return (
        <Box
            ref={boxRef}
            style={{
                overflow: "hidden",
                height: "calc(100vh - 310px)",
                width: "100%",
                minHeight: "200px",
            }}
        >
            <SortedDatagridPro6
                columns={columns}
                rows={filteredCompanies ?? []}
                getRowHeight={() => 52}
                loading={companiesLoadingStatus === "pending"}
                onRowClick={handleClick}
                pagination
                disableRowSelectionOnClick
                disableColumnSelector
                disableDensitySelector
                disableColumnReorder
                disableColumnMenu
                initialState={{
                    // ...companyUsersArray.initialState,
                    pagination: { paginationModel: { pageSize: 10 } },
                }}
                pageSizeOptions={[10, 25, 50, 100]}
                sx={{
                    overflow: "hidden",
                    border: "none !important",
                    "& .MuiDataGrid-selectedRowCount": {
                        display: "none !important",
                    },
                    "& .MuiDataGrid-toolbar": {
                        justifyContent: "flex-end",
                        "& .MuiTablePagination-root": {
                            marginLeft: "auto",
                        },
                    },
                    "& .MuiDataGrid-columnHeader": {
                        "&.hover-column-header": {
                            "& .MuiDataGrid-columnSeparator": {
                                display: "none",
                            },
                            "& .MuiDataGrid-sortIcon": {
                                display: "none",
                            },
                        },
                        "& .MuiDataGrid-columnSeparator": {
                            display: "none",
                        },
                    },
                    ".MuiDataGrid-cell:focus": {
                        outline: "none",
                    },
                    "& .MuiDataGrid-row": {
                        cursor: "pointer",
                    },
                    "& .MuiTablePagination-root": {
                        marginRight: "60px",
                    },
                    ".MuiDataGrid-cell": {
                        "&:focus": {
                            outline: "none",
                        },
                        "&.hover-column-cell": {
                            position: "sticky",
                            right: 0,
                            zIndex: 1,
                        },
                        "& .hover-content": {
                            transition: "opacity 0.3s ease-in-out",
                            opacity: 0,
                        },
                    },
                    ".MuiDataGrid-columnHeader:hover": {
                        "& .MuiDataGrid-columnSeparator": {
                            display: "flex",
                        },
                    },
                    ".MuiDataGrid-row:hover .hover-column-cell .hover-content": {
                        opacity: 1,
                        backgroundColor: "#f5f5f5"
                    },
                    "& .MuiDataGrid-row.Mui-selected .hover-column-cell .hover-content": {
                        backgroundColor: "#e7e9f0",
                    },
                    "& .MuiDataGrid-columnHeader:focus": {
                        outline: "none",
                    },
                }}
            />
        </Box>
    );
};

export default FactoryTable;
