// TODO - Type 'any' needs to be fixed.
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Button, CircularProgress, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";

import CheckboxPopover from "../../../../../design/checkbox-popover";
import { JobRolesList } from "../../../../../design/job-roles";
import useSchedulerData from "../../../controllers/use-scheduler-data";
import { JobsState, LocationOptions } from "./types";
import { isSelectAllTrue } from "./utils";

interface BMessageFormProps {
	jobs: JobsState;
	message: string;
	setMessage: (message: string) => void;
	handleClick: ({ id }: { id: number }) => void;
	handleSelectAll: (checked: boolean) => void;
	options: LocationOptions[];
	setOptions: (options: LocationOptions[]) => void;
	filteredJobs: any[] | undefined;
	workcenters: any[];
	setWorkcenters: (workcenters: any) => void;
	setFilteredJobs: (jobs: any) => void;
	setChangedBy: (changedBy: string) => void;
}

const BMessageForm: React.FC<BMessageFormProps> = ({
	workcenters,
	jobs,
	message,
	setMessage,
	handleClick,
	handleSelectAll,
	options,
	setOptions,
	filteredJobs,
	setWorkcenters,
	setFilteredJobs,
	setChangedBy,
}) => {
	const textErrorMessage = "The text may not be longer than 160 characters.";
	const {
		fetchAllLocations,
		locationsWithClusterAsync,
		fetchInitialDataStatus,
		broadcastWorkerCount,
		broadcastWorkerCountStatus,
	} = useSchedulerData();

	const [locationAnchorElement, setLocationAnchorElement] =
		useState<null | HTMLElement>(null);
	const [workcenterAnchorElement, setWorkcenterAnchorElement] =
		useState<null | HTMLElement>(null);
	const [jobAnchorElement, setJobAnchorElement] = useState<null | HTMLElement>(
		null
	);

	const isOptionsAnyChecked = options.some(
		(option: { checked: boolean }) => option.checked
	);

	const isWorkcentersAnyChecked = workcenters.some(
		(option: { checked: boolean }) => option.checked
	);

	const handleLocationPopover = (event: React.MouseEvent<HTMLDivElement>) => {
		setChangedBy("location");
		setLocationAnchorElement(event.currentTarget);
	};

	const handleWorkcenterPopover = (event: React.MouseEvent<HTMLDivElement>) => {
		if (isOptionsAnyChecked) {
			setChangedBy("workcenter");
			setWorkcenterAnchorElement(event.currentTarget);
		}
	};

	const handleJobPopover = (event: React.MouseEvent<HTMLDivElement>) => {
		if (isWorkcentersAnyChecked) {
			setChangedBy("job");
			setJobAnchorElement(event.currentTarget);
		}
	};

	const getStatusText = (text: string) => {
		const arr =
			text === "location"
				? options
				: text === "workcenter"
				? workcenters
				: filteredJobs;
		const selectedOptions = arr ?
			arr.length > 0
				? arr.filter((option: { checked: boolean }) => option.checked)
				: [] : [];
		if (selectedOptions.length === 1) {
			return selectedOptions[0].label;
		} else if (arr && selectedOptions.length === arr.length && arr.length > 1) {
			return text === "location"
				? `All Locations (${selectedOptions.length})`
				: text === "workcenter"
				? `All Workcenters (${selectedOptions.length})`
				: text === "job"
				? `All Jobs (${selectedOptions.length})`
				: "All";
		} else if (selectedOptions.length > 1) {
			return `${selectedOptions.length} selected`;
		}
		return "Select";
	};

	return (
		<Box
			width='100%'
			display='flex'
			flexDirection='column'
			gap={2}
			height={300}
		>
			<Box>
				<TextField
					id='filled-multiline-static'
					label='Message'
					multiline
					rows={3}
					defaultValue=''
					variant='filled'
					error={message?.length > 160}
					helperText={message?.length > 160 ? textErrorMessage : ""}
					sx={{
						width: "100%",
						"& .Mui-focused": {
							color: "#303030",
						},
					}}
					onChange={(e) => setMessage(e.target.value)}
				/>
				<Box
					display='flex'
					flexDirection='row'
					justifyContent='flex-end'
					mt={0.5}
				>
					<Typography variant='subtitle2' component='h2'>
						<span
							style={{
								color: message?.length > 160 ? "#f44336" : "#303030",
							}}
						>
							{message?.length}
						</span>
						/160
					</Typography>
				</Box>
			</Box>
			{fetchAllLocations.status === "pending" ||
			locationsWithClusterAsync.status === "pending" ||
			fetchInitialDataStatus === "pending" ? (
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						mr: "auto",
						ml: "auto",
						flexDirection: "column",
					}}
				>
					<CircularProgress size={25} />
				</Box>
			) : (
				<>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							width: "100%",
						}}
					>
						<Typography
							sx={{
								fontSize: "16px",
								fontWeight: 500,
								color: "#303030",
								marginRight: "12px",
							}}
						>
							Select Recipients
						</Typography>
						{broadcastWorkerCountStatus === "pending" ? (
							<Box position='relative'>
								<CircularProgress size={16} />
							</Box>
						) : (
							<Typography
								sx={{
									fontSize: "16px",
									fontWeight: 400,
									color: "#303030",
									marginRight: "32px",
								}}
							>
								({broadcastWorkerCount} workers)
							</Typography>
						)}
					</Box>
					<Box display='flex' alignItems='center'>
						<Typography
							sx={{
								fontSize: "16px",
								fontWeight: 400,
								color: "#303030",
								marginRight: "32px",
								width: "120px",
							}}
						>
							Locations
						</Typography>
						<Box
							onClick={handleLocationPopover}
							sx={{
								padding: "8px 12px",
								background: "#f8f8f8",
								borderRadius: "4px",
								display: "flex",
								cursor: "pointer",
								width: "317px",
								justifyContent: "space-between",
							}}
						>
							<Typography variant='body1'>
								{getStatusText("location")}
							</Typography>
							{locationAnchorElement ? (
								<ArrowDropUpIcon />
							) : (
								<ArrowDropDownIcon />
							)}
						</Box>
						<CheckboxPopover
							options={options}
							anchorElement={locationAnchorElement}
							onClose={() => setLocationAnchorElement(null)}
							setOptions={setOptions}
							minWidth='317px'
							allCheckedText='All Locations'
						/>
					</Box>

					<Box display='flex' alignItems='center'>
						<Typography
							sx={{
								fontSize: "16px",
								fontWeight: 400,
								color: "#303030",
								marginRight: "32px",
								width: "120px",
							}}
						>
							Work Centers
						</Typography>
						{workcenters.length === 0 && isOptionsAnyChecked ? (
							<Typography
								sx={{
									fontSize: "16px",
									fontWeight: 400,
									color: "#303030",
								}}
							>
								No Work Centers
							</Typography>
						) : (
							<>
								<Box
									onClick={handleWorkcenterPopover}
									sx={{
										padding: "8px 12px",
										background: isOptionsAnyChecked ? "#f8f8f8" : "#e0e0e0",
										borderRadius: "4px",
										display: "flex",
										cursor: isOptionsAnyChecked ? "pointer" : "default",
										width: "317px",
										justifyContent: "space-between",
										opacity: isOptionsAnyChecked ? 1 : 0.5,
									}}
								>
									<Typography variant='body1'>
										{getStatusText("workcenter")}
									</Typography>
									{workcenterAnchorElement ? (
										<ArrowDropUpIcon />
									) : (
										<ArrowDropDownIcon />
									)}
								</Box>
								<CheckboxPopover
									options={workcenters}
									anchorElement={workcenterAnchorElement}
									onClose={() => setWorkcenterAnchorElement(null)}
									setOptions={setWorkcenters}
									minWidth='317px'
									allCheckedText='All Workcenters'
								/>
							</>
						)}
					</Box>

					<Box display='flex' alignItems='center'>
						<Typography
							sx={{
								fontSize: "16px",
								fontWeight: 400,
								color: "#303030",
								marginRight: "32px",
								width: "120px",
							}}
						>
							Job Roles
						</Typography>
						{filteredJobs && filteredJobs.length === 0 && isWorkcentersAnyChecked ? (
							<Typography
								sx={{
									fontSize: "16px",
									fontWeight: 400,
									color: "#303030",
								}}
							>
								No Job Roles
							</Typography>
						) : (
							<>
								<Box
									onClick={handleJobPopover}
									sx={{
										padding: "8px 12px",
										background: isWorkcentersAnyChecked ? "#f8f8f8" : "#e0e0e0",
										borderRadius: "4px",
										display: "flex",
										cursor: isWorkcentersAnyChecked ? "pointer" : "default",
										width: "317px",
										justifyContent: "space-between",
										opacity: isWorkcentersAnyChecked ? 1 : 0.5,
									}}
								>
									<Typography variant='body1'>
										{getStatusText("job")}
									</Typography>
									{jobAnchorElement ? (
										<ArrowDropUpIcon />
									) : (
										<ArrowDropDownIcon />
									)}
								</Box>
								<CheckboxPopover
									options={filteredJobs ? filteredJobs.length > 0 ? filteredJobs : [] : []}
									anchorElement={jobAnchorElement}
									onClose={() => setJobAnchorElement(null)}
									setOptions={setFilteredJobs}
									minWidth='317px'
									allCheckedText='All Job Roles'
								/>
							</>
						)}
					</Box>
				</>
			)}
		</Box>
	);
};

export default BMessageForm;
