interface Column {
  field: string;
  [key: string]: any;
}

interface DividedColumns {
  defaultColumns: Array<Column & { checked: boolean }>;
  restColumns: Array<Column & { checked: boolean }>;
}

const useColumnDivider = (
  columns: Column[],
  fixedColumns: string[],
  defaultCheckedColumns: string[]
): DividedColumns => {
  const defaultColumns = columns
    .filter((column) => !fixedColumns.includes(column.field))
    .map((column) => {
      return {
        checked: true,
        ...column,
      };
    });

  const restColumns = columns
    .filter((column) => fixedColumns.includes(column.field))
    .map((column) => {
      return {
        checked: defaultCheckedColumns.includes(column.field),
        ...column,
      };
    });

  return {
    defaultColumns,
    restColumns,
  };
};

export default useColumnDivider;
