import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import {useEffect} from "react";
import * as React from "react";

import JobItem from "../job-role-item";

type Skills = {
    id: number;
    jobId: number;
    name: string;
    skillId: number;
};

type JobRoles = {
    maxWorkers: number;
    id: number;
    name: string;
    hexColor: string;
    isActive: boolean;
    workCenterIds: string;
    skills: Array<Skills>;
};

interface SwitchJobSkillsProps {
    state: any;
    onSelectCheckbox: ({ id }: { id: number }) => void;
    jobRoles: JobRoles[];
    search: string,
}
const JobList = (
    {
        state,
        onSelectCheckbox,
        jobRoles,
        search,
    }: SwitchJobSkillsProps
) => {
    const [checked, setChecked] = React.useState([0]);
    const [jobsList, setJobsList] = React.useState<JobRoles[]>([]);
    const handleToggle = (value: number) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    useEffect(() => {
        if (jobRoles && jobRoles.length > 0) {
            setJobsList(jobRoles);
        }
    }, [jobRoles]);

    return (
        <List sx={{
            width: "100%",
        }}>
            {jobsList.map((job) => {
                const {id} = job;
                const labelId = `job-checkbox-list-label-${id}`;

                return (
                    <ListItem
                        key={`shift-job-${id}`}
                        sx={{ height: "57px" }}
                        disableGutters={true}
                        divider={false}>
                        <ListItemButton
                            role={undefined}
                            disableRipple
                            onClick={handleToggle(id)}
                            dense
                            disableGutters={false}>
                            <ListItemIcon sx={{
                                minWidth: "auto",
                                paddingLeft: "8px" }}>
                                <Checkbox
                                    edge="start"
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ "aria-labelledby": labelId }}
                                    checked={state?.[id] ?? false}
                                    onClick={() => onSelectCheckbox({ id })}
                                />
                            </ListItemIcon>
                            <JobItem jobRole={job} search={search}/>
                        </ListItemButton>
                    </ListItem>
                );
            })}
        </List>
    );
}

export default JobList;