import { useMemo, useState } from "react";

const useColumnSelector = (defaultColumns, restColumns) => {
	const [rest, setRest] = useState(restColumns);

	const selectedColumns = useMemo(() => {
		const temp = rest.filter((c) => c.checked);
		return [...defaultColumns, ...temp];
	}, [rest, defaultColumns]);

	const handleSelectColumns = (checked, column) => {
		setRest((prevRest) =>
			prevRest.map((c) => (c.field === column ? { ...c, checked } : c))
		);
	};

	return [
		{
			selectedColumns: selectedColumns
				.sort((a, b) => a.order - b.order)
				.filter((c) => c.checked)
				.map((c) => {
					return c.field;
				}),
			pickerColumns: rest,
		},
		{
			handleSelectColumns,
		},
	];
};

export default useColumnSelector;
