import { createSlice } from "@reduxjs/toolkit";

export const NavigationDrawerSlice = createSlice({
  name: "drawer",
  initialState: {
    isDrawerOpen: false,
    selectedMenuItemId: 1,
  },
  reducers: {
    openNavigationDrawer: (state) => {
      state.isDrawerOpen = true;
    },
    closeNavigationDrawer: (state) => {
      state.isDrawerOpen = false;
    },
    changeSelectedMenuItemId: (state, action) => {
      state.selectedMenuItemId = action.payload;
    }
  },
});

export const { openNavigationDrawer, closeNavigationDrawer, changeSelectedMenuItemId } =
  NavigationDrawerSlice.actions;

export default NavigationDrawerSlice.reducer;
