import FilterListIcon from "@mui/icons-material/FilterList";
import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";

interface CrossSkillFilterButtonProps {
    openFilter: (status: boolean) => void;
    filterStatus: boolean;
}

export default function CrossSkillFilterButton({
                                                   openFilter,
                                                   filterStatus,
                                               }: CrossSkillFilterButtonProps) {
    return (
        <Box display='flex' justifyContent='center' alignItems='center' mx={1}>
            <Box
                width='50px'
                height='50px'
                display='flex'
                alignItems='center'
                justifyContent='center'
                sx={{
                    cursor: "pointer",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#f8f8f8",
                    borderRadius: "4px",
                    ...(filterStatus ? { boxShadow: "inset 0px 0px 2px 2px #CCC" } : {}),
                }}
                onClick={() => {
                    openFilter(!filterStatus);
                }}
            >
                <FilterListIcon />
            </Box>
        </Box>
    );
}