import { Box } from "@mui/material";
import React from "react";

import { FULL, HALF, QUARTER, THREE_QUARTER } from "../constants/constants";

const s1 = {
	background: "conic-gradient(black 0% 25%, white 0% 100%)",
};

const s2 = {
	background: "conic-gradient(black 0% 50%, white 0% 100%)",
};

const s3 = {
	background: "conic-gradient(black 0% 75%, white 0% 100%)",
};
const s4 = {
	background: "#000",
};
const s5 = {
	background: "#fff",
};

function CircleSkillRepresentation({
	representationLevel,
}: {
	representationLevel?: number | string;
}) {
	const ob = {
		width: "20px",
		height: "20px",
		//transform: "rotate(112deg)",
		borderRadius: "100%",
	};
	const container = {
		width: "22px",
		height: "22px",
		// transform: "rotate(112deg)",
		borderRadius: "100%",
		backgroundColor: "#000",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	};

	let style;
	switch (representationLevel) {
		case 1:
		case QUARTER:
			style = s1;
			break;
		case 2:
		case HALF:
			style = s2;
			break;
		case 3:
		case THREE_QUARTER:
			style = s3;
			break;
		case 4:
		case FULL:
			style = s4;
			break;
		default:
			style = s5;
	}

	return (
		<Box style={{ ...container }}>
			<Box style={{ ...ob, ...style }} />
		</Box>
	);
}

export default CircleSkillRepresentation;
