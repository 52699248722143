import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import debounce from "lodash.debounce";
import React, { ChangeEvent, useMemo, useState } from "react";
const BACKGROUND_COLOR = "#fff";
const BORDER_COLOR = "rgba(0,0,0,0.12)";

const Search = ({ value, onChange }: { value: string; onChange: any }) => {
	return (
		<TextField
			hiddenLabel
			value={value}
			onChange={(e: any) => onChange(e.target.value)}
			placeholder={"SEARCH"}
			size='small'
			InputProps={{
				startAdornment: (
					<SearchIcon
						style={{
							height: "24px",
							width: "24px",
							color: "rgba(0,0,0,0.56)",
						}}
					/>
				),
				endAdornment:
					value !== "" ? (
						<ClearIcon
							onClick={() => {
								onChange("");
							}}
							style={{
								height: "24px",
								width: "24px",
								color: "rgba(0,0,0,0.56)",
								cursor: "pointer",
							}}
						/>
					) : (
						<></>
					),
			}}
			sx={{
				"& .MuiOutlinedInput-root": {
					"&:hover fieldset": {
						borderColor: BORDER_COLOR,
					},
					"&.Mui-focused fieldset": {
						borderColor: BORDER_COLOR,
					},
				},
				width: "100%",
				border: 0,
				borderRadius: "4px",
				input: {
					paddingLeft: "8px",
					color: "rgba(0,0,0,0.87)",
					fontFamily: "Roboto",
					fontSize: "12px",
					fontWeight: "400",
					lineHeight: "20px",
					letterSpacing: "0.4px",
					backgroundColor: BACKGROUND_COLOR,
					"&:hover": {
						backgroundColor: BACKGROUND_COLOR,
					},
				},
			}}
		/>
	);
};

export default Search;
