// TODO - Type 'any' needs to be fixed.
// import Button from "@gigandtake/button";

import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, { Dispatch, SetStateAction } from "react";
import { useDispatch, useSelector } from "react-redux";

interface ISchedulerHeaderProps {
	openBMessageDialog: () => void;

	toggleScheduler: boolean;
	setToggleScheduler: Dispatch<SetStateAction<boolean>>;
	isHeatMapVisible: boolean;
}
const SchedulerHeader: React.FC<ISchedulerHeaderProps> = ({
	openBMessageDialog,
	toggleScheduler,
	setToggleScheduler,
	isHeatMapVisible,
}) => {
	const dispatch = useDispatch();
	// const [dateForShift, setDateForShift] = useState(addHours(new Date(), 24));
	const currentDate = useSelector(
		(state: any) => state.supervisor.schedulerData.datesOfTheWeek.monday
	);
	const userRoles = useSelector((state: any) => state.user.userData.userRoles);

	// useEffect(() => {
	// 	if (
	// 		isThisWeek(new Date(currentDate), {
	// 			weekStartsOn: 1,
	// 		})
	// 	) {
	// 		setDateForShift(addHours(new Date(), 24));
	// 	} else setDateForShift(addHours(new Date(), 24));
	// }, [currentDate]);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "flex-start",
				justifyContent: "center",
				background: "#fff",
				padding: "24px 24px 0px 24px",
				borderRadius: "15px 15px 0px 0px",
			}}
		>
			<Typography
				sx={{
					fontFamily: "Roboto",
					fontSize: "24px",
					fontWeight: 400,
					color: "rgba(0,0,0,0.87)",
				}}
			>
				Flex Schedule
			</Typography>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					alignItems: "center",
					textAlign: "center",
					height: "41px",
					width: "100%",
				}}
			>
				{isHeatMapVisible ? (
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							height: "100%",
							alignItems: "flex-end",
							justifyContent: "flex-start",
						}}
					>
						<Box
							sx={{
								display: "flex",
								alignItems: "flex-start",
								justifyContent: "center",
								padding: "8px 16px 8px 0px",
								borderBottom: toggleScheduler ? "" : "1px solid #2F4D8B",
								cursor: "pointer",
							}}
							onClick={() => {
								setToggleScheduler(false);
							}} // False for shift
						>
							<Typography
								sx={{
									fontFamily: "Roboto",
									color: toggleScheduler ? "rgba(0, 0, 0, 0.60)" : "#2F4D8B",
									fontSize: "14px",
									fontWeight: "500",
									lineHeight: "24px",
									letterSpacing: "0.4px",
									textTransform: "uppercase",
								}}
							>
								Shifts
							</Typography>
						</Box>
						<Box
							sx={{
								display: "flex",
								alignItems: "flex-start",
								justifyContent: "center",
								padding: "8px 16px 8px 0px",
								marginLeft: "16px",
								borderBottom: toggleScheduler ? "1px solid #2F4D8B" : "",
								cursor: "pointer",
							}}
							onClick={() => {
								setToggleScheduler(true);
							}} // True for Availability
						>
							<Typography
								sx={{
									fontFamily: "Roboto",
									color: toggleScheduler ? "#2F4D8B" : "rgba(0, 0, 0, 0.60)",
									fontSize: "14px",
									fontWeight: "500",
									lineHeight: "24px",
									letterSpacing: "0.4px",
									textTransform: "uppercase",
								}}
							>
								Availability
							</Typography>
						</Box>
					</Box>
				) : (
					<Box sx={{ width: "1px" }} />
				)}
				{!toggleScheduler && !userRoles.includes("ReadOnly") ? (
					<Box
						sx={{
							display: "flex",
						}}
					>
						<Button
							variant='text'
							startIcon={<MailOutlineIcon />}
							onClick={() => openBMessageDialog()}
							sx={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "center",
								flexWrap: "nowrap",
								height: "30px",
								marginRight: "8px",
								marginLeft: "8px",
								lineHeight: "18px",
							}}
							color='primary'
						>
							Broadcast message
						</Button>
					</Box>
				) : (
					<Box sx={{ width: "1px" }} />
				)}
			</Box>
		</Box>
	);
};

export default SchedulerHeader;
