import { Box, Typography } from "@mui/material";
import React from "react";

type ScheduleLegendProps = {
	title: string;
	colorCode: string;
	subtitle: string;
};

const ScheduleLegend: React.FC<ScheduleLegendProps> = ({
	title,
    colorCode,
	subtitle,
}) => {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "flex-start",
				alignItems: "center",
				marginRight: "20px",
				marginBottom: "10px",
				minWidth: "120px",
			}}
		>
			<Box
				sx={{
					width: "8px",
					height: "40px",
					backgroundColor: colorCode,
					borderRadius: "2px",
					marginRight: "10px",
				}}
			/>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "flex-start",
					marginRight: "20px",
				}}
			>
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontSize: "14px",
						fontWeight: 400,
						lineHeight: "20px",
						letterSpacing: "0.2px",
						color: "#00000099					",
					}}
				>
					{title}
				</Typography>
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontSize: "12px",
						fontWeight: 400,
						lineHeight: "20px",
						letterSpacing: "0.4px",
						color: "#00000061",
					}}
				>
					{subtitle}
				</Typography>
			</Box>
		</Box>
	);
};

export default ScheduleLegend;
