import { StarHalf } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import React, { FC } from "react";

import {colors} from "../../../../../../config/colors";

interface CrossSkillingLevelsPlaceholderProps {
    openCrossSkillingDialog: (open: boolean) => void;
}

const CrossSkillingLevelsPlaceholder: FC<CrossSkillingLevelsPlaceholderProps> = ({ openCrossSkillingDialog }) => {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row",
                marginTop: "20px",
                marginBottom: "8px",
                borderRadius: "3px",
                border: 1,
                borderWidth: "1px",
                borderColor: colors.errorBorder,
                backgroundColor: colors.errorBox,
                padding: "17px",
            }}
        >
            <Typography
                sx={{
                    color: "rgba(0, 0, 0, 0.60)",
                    textAlign: "center",
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontWeight: "400",
                }}
            >
                Skill level missing. Each worker would be marked against a skill level
                under Cross-skilling.
            </Typography>
            <Button
                variant='contained'
                startIcon={<StarHalf />}
                sx={{
                    marginLeft: "12px",
                }}
                onClick={() => openCrossSkillingDialog(true)}
            >
                Configure Skill Levels
            </Button>
        </Box>
    );
};

export default CrossSkillingLevelsPlaceholder;