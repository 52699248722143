import { Box, FormControlLabel, Tooltip } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import {JobColor} from "../../../../../export/jobs";
import Highlight from "../../../../components/Highlight";
import FilterListCheckbox from "./filter-list-checkbox";

export const ListForFilter: React.FC<any> = ({ index, style, data }) => {
  const { items, isChecked, searchedValue, listWidth, applyFilter } = data;
  const selectedItem = items[index];
  const textRef = useRef<HTMLSpanElement>(null);
  const [isEllipsed, setIsEllipsed] = useState(false);
  const isFirst = index === 0;
  const isLast = index === data.length - 1;

  useEffect(() => {
    if (textRef.current) {
      setIsEllipsed(textRef.current.scrollWidth > textRef.current.clientWidth);
    }
  }, [selectedItem.name]);
  return (
    <div
      style={{
        ...style,
        paddingTop: isFirst ? "12px" : "0px",
        paddingBottom: isLast ? "12px" : "0px",
        width: `${listWidth}px`,
        overflowX: "hidden",
      }}
    >
      <FormControlLabel
        key={`filter-${selectedItem.id}`}
        sx={{
          width: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
        control={
          <FilterListCheckbox
            checked={isChecked(selectedItem.id)}
            onChange={(e) => {
                applyFilter(selectedItem.id, e.target.checked);
            }}
          />
        }
        label={
          <Box display="flex" alignItems="center">
              {selectedItem.hexColor && (
                  <JobColor color={`#${selectedItem.hexColor}`} />
              )}
            <Tooltip title={isEllipsed ? selectedItem.name : null}>
              <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  fontSize: "14px",
                  marginLeft: selectedItem.hexColor ? "12px" : "2px",
                  fontFamily: "Roboto",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  maxWidth: `${selectedItem.hexColor ? listWidth-106 : listWidth-80}px`,
                  position: "relative",
                }}
              >
                <Box
                  ref={textRef}
                  component="span"
                  sx={{
                      display: "inline-block",
                    maxWidth: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    transition: "max-width 0.5s ease",
                  }}
                >
                  <Highlight
                    value={selectedItem.name}
                    searchText={searchedValue}
                  />
                </Box>
              </Box>
            </Tooltip>
          </Box>
        }
      />
    </div>
  );
};
