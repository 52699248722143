/* eslint-disable simple-import-sort/imports */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "../../../utils/api-client";
import {
	RegularShiftRequest,
	WorkerRequestsStateTypes,
} from "./worker-requests-types";
import{
	ftRequestSwapProps,
	updateSwapRequestsTypes,
} from "./worker-requests-types";

const initialState: WorkerRequestsStateTypes = {
	allRegularRequests: [],
	allShiftSwapRequests: [],
	allRequestStatuses: [],
	getAllRegularRequests: {
		status: "idle",
		errorMessage: "",
	},
	getAllRequestStatuses: {
		status: "idle",
		errorMessage: "",
	},
	requestShiftAction: {
		status: "idle",
		errorMessage: "",
	},
	shiftFormData: {},
	workerRequestMetaData: {},
	updateSwapRequests: {
		status: "idle",
		errorMessage: "",
	},
	shiftSwapRequests: {
		status: "idle",
		errorMessage: "",
	},
	getShiftMetaData: {
		status: "idle",
		errorMessage: "",
		data: {},
	},
};

// API to get all regular requests
export const getAllRegularRequests = createAsyncThunk(
	"worker-requests/get-all-regular-requests",
	async ({startDate=null, endDate=null} : ftRequestSwapProps, { fulfillWithValue, rejectWithValue }) => {
		try {
			const urlExtension = endDate ? `?endDate=${endDate}${startDate ? `&startDate=${startDate}` : ""}` : "";
			const res = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/workers/requestsForUser${urlExtension}`
			);
			return fulfillWithValue(res.data);
		} catch (error: any) {
			try {
				return rejectWithValue(error.response.data.message);
			} catch (e) {
				return rejectWithValue("An error occurred");
			}
		}
	}
);

// API to get all regular requests
export const getAllRequestsTypes = createAsyncThunk(
	"worker-requests/get-all-requests-types",
	async (payload, { fulfillWithValue, rejectWithValue }) => {
		try {
			const res = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/workers/requestStatuses`
			);
			return fulfillWithValue(res.data);
		} catch (error: any) {
			try {
				return rejectWithValue(error.response.data.message);
			} catch (e) {
				return rejectWithValue("An error occurred");
			}
		}
	}
);

// API to perform action on a request
export const requestShiftAction = createAsyncThunk(
	"gat-admin/request-shift-action",
	async (payload: any, { fulfillWithValue, rejectWithValue }) => {
		try {
			const res = await axios.post(
				`${process.env.REACT_APP_API_END_POINT}/workers/requestAction`,
				payload
			);
			if (res.status === 200 || res.status === 201) {
				return fulfillWithValue(payload);
			}
			return rejectWithValue("An error occurred");
		} catch (error: any) {
			const { setSubmitting } = payload;
			if (setSubmitting) {
				setSubmitting(false);
			}
			try {
				return rejectWithValue(error.response.data.message);
			} catch (e) {
				return rejectWithValue("An error occurred");
			}
		}
	}
);

// API to get all swap requests
export const fetchAllSwapRequests = createAsyncThunk(
	"scheduler/fetchAllSwapRequests",
	async (payload, thunkAPI) => {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/ShiftSwap/GetAllForUser`
			);

			if (response?.status === 200) {
				return thunkAPI.fulfillWithValue(response.data);
			}
			return thunkAPI.rejectWithValue([]);
		} catch (error) {
			console.error(error);
			return thunkAPI.rejectWithValue(error);
		}
	}
);
// API to update swap requests
export const updateSwapRequests = createAsyncThunk(
	"scheduler/updateSwapRequests",
	async (payload: updateSwapRequestsTypes, thunkAPI) => {
		// console.log("payload", payload);
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_API_END_POINT}/ShiftSwap/Approve`,
				payload
			);
			// console.log("--res---", response);
			if (response.status === 200) {
				return thunkAPI.fulfillWithValue(payload);
			}
			return thunkAPI.fulfillWithValue(payload);
		} catch (error) {
			// console.error(error);
			return thunkAPI.rejectWithValue(error);
		}
	}
);

// Api to get shift meta data
export const getShiftMetaData = createAsyncThunk(
	"scheduler/get-shift-meta-data",
	async (payload: number, thunkAPI) => {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/Shifts/SignUpTracking?id=${payload}`
			);
			if (response?.status === 200) {
				return thunkAPI.fulfillWithValue(response.data);
			}
			return thunkAPI.rejectWithValue({});
		} catch (error) {
			console.error(error);
			return thunkAPI.rejectWithValue(error);
		}
	}
);

export const WorkerRequestsSlice = createSlice({
	name: "workerRequests",
	initialState,
	reducers: {
		resetGetAllWorkerRequestsAsyncState: (state) => {
			state.getAllRegularRequests = {
				status: "idle",
				errorMessage: "",
			};
		},
		resetShiftActions: (state) => {
			state.requestShiftAction = {
				status: "idle",
				errorMessage: "",
			};
		},
		resetShiftFormData: (state) => {
			state.shiftFormData = {};
		},
		setShiftFormData: (state, action) => {
			state.shiftFormData = action.payload;
		},
		setWorkerRequestMetaData: (state, action) => {
			state.workerRequestMetaData = action.payload;
		},
		resetWorkerRequestMetaData: (state) => {
			state.workerRequestMetaData = {};
		},
		resetUpdateRequestsStatus: (state) => {
			state.updateSwapRequests.status = "idle";
			state.updateSwapRequests.errorMessage = "";
		},
		setRequestData: (state, action) => {
			state.shiftFormData = action.payload;
		},
	},
	extraReducers: (builder) => {
		// getAllRegularRequests
		builder.addCase(
			getAllRegularRequests.pending,
			(state: WorkerRequestsStateTypes) => {
				state.getAllRegularRequests.status = "pending";
			}
		);
		builder.addCase(
			getAllRegularRequests.rejected,
			(state: WorkerRequestsStateTypes, action) => {
				state.getAllRegularRequests.status = "error";
				state.getAllRegularRequests.errorMessage =
					action.payload ?? "An error occurred";
			}
		);
		builder.addCase(getAllRegularRequests.fulfilled, (state, action) => {
			const temp = action.payload.map(
				(it: RegularShiftRequest, idx: number) => ({
					...it,
					id: it.workerId + idx,
				})
			);
			// const filterRegularRequests = temp.filter(
			// 	(it: RegularShiftRequest) => it.requestType["code"] !== "lr"
			// );
			state.allRegularRequests = temp;
			state.getAllRegularRequests.status = "fulfilled";
		});
		// requestShiftAction
		builder.addCase(
			requestShiftAction.pending,
			(state: WorkerRequestsStateTypes) => {
				state.requestShiftAction.status = "pending";
			}
		);
		builder.addCase(
			requestShiftAction.rejected,
			(state: WorkerRequestsStateTypes, action) => {
				state.requestShiftAction.status = "error";
				state.requestShiftAction.errorMessage =
					action.payload ?? "An error occurred";
			}
		);
		builder.addCase(requestShiftAction.fulfilled, (state, action) => {
			state.requestShiftAction.status = "fulfilled";
		});
		// getAllRequestsTypes
		builder.addCase(
			getAllRequestsTypes.pending,
			(state: WorkerRequestsStateTypes) => {
				state.getAllRequestStatuses.status = "pending";
			}
		);
		builder.addCase(
			getAllRequestsTypes.rejected,
			(state: WorkerRequestsStateTypes, action) => {
				state.getAllRequestStatuses.status = "error";
				state.getAllRequestStatuses.errorMessage =
					action.payload ?? "An error occurred";
			}
		);
		builder.addCase(getAllRequestsTypes.fulfilled, (state, action) => {
			state.allRequestStatuses = action.payload;
			state.getAllRequestStatuses.status = "fulfilled";
		});

		// updateSwapRequests
		builder.addCase(updateSwapRequests.fulfilled, (state: any, action) => {
			state.updateSwapRequests.status = "fulfilled";
			//state.shiftSwapRequests.data =
			const id = action.payload.Id;
			const updatedShiftSwapRequests = state.allShiftSwapRequests.map(
				(item: any) => {
					if (item.id === id) {
						return {
							...item,
							approvalStatus: action.payload.status,
						};
					}
					return item;
				}
			);

			state.allShiftSwapRequests = updatedShiftSwapRequests;
		});
		builder.addCase(updateSwapRequests.pending, (state) => {
			state.updateSwapRequests.status = "pending";
		});
		builder.addCase(updateSwapRequests.rejected, (state: any, action) => {
			state.updateSwapRequests.status = "error";
			state.updateSwapRequests.errorMessage =
				action.payload ?? "An error occurred";
		});

		// shift swap requests

		builder.addCase(fetchAllSwapRequests.fulfilled, (state, action) => {
			state.shiftSwapRequests.status = "fulfilled";
			state.allShiftSwapRequests = action.payload;
		});
		builder.addCase(fetchAllSwapRequests.pending, (state) => {
			state.shiftSwapRequests.status = "pending";
		});
		builder.addCase(fetchAllSwapRequests.rejected, (state, action) => {
			state.shiftSwapRequests.status = "error";
			state.shiftSwapRequests.errorMessage =
				action.payload ?? "An error occurred";
		});

		// getShiftMetaData
		builder.addCase(
			getShiftMetaData.pending,
			(state: WorkerRequestsStateTypes) => {
				state.getShiftMetaData.status = "pending";
			}
		);
		builder.addCase(
			getShiftMetaData.rejected,
			(state: WorkerRequestsStateTypes, action) => {
				state.getShiftMetaData.status = "error";
				state.getShiftMetaData.errorMessage =
					action.payload ?? "An error occurred";
			}
		);
		builder.addCase(getShiftMetaData.fulfilled, (state, action) => {
			state.getShiftMetaData.status = "fulfilled";
			state.getShiftMetaData.data = action.payload;
		});
	},
});

export const {
	resetGetAllWorkerRequestsAsyncState,
	resetShiftFormData,
	setShiftFormData,
	resetShiftActions,
	resetUpdateRequestsStatus,
	setWorkerRequestMetaData,
	resetWorkerRequestMetaData,
} = WorkerRequestsSlice.actions;

export default WorkerRequestsSlice.reducer;
