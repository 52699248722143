import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React from "react";

interface TimeFilterSelectProps<T> {
    value: T;
    onChange: (event: SelectChangeEvent<T>) => void;
    options: { value: string; label: string }[];
}

const TimeFilterSelect = <T,>({ value, onChange, options }: TimeFilterSelectProps<T>) => {
    return (
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <Select
                labelId="time-filter-select-label"
                id="time-filter-select"
                value={value}
                onChange={onChange}
                variant="filled"
                sx={{
                    "& .MuiSelect-root": {
                        "&:focus": {
                            backgroundColor: "transparent"
                        },
                    },
                    "& .MuiSelect-select": {
                        padding: "12px"
                    },
                    "&:before": {
                        borderBottom: "none"
                    },
                    "&:after": {
                        borderBottom: "none" 
                    },
                    background: "#f8f8f8",
                }}
            >
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default TimeFilterSelect;
