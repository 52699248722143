// TODO - Type 'any' needs to be fixed.
import { Box } from "@mui/material";
import { format, parse } from "date-fns";
import React, { useEffect, useState } from "react";

import { ShiftType } from "../../../../../../gat-admin/store/gat-admin-slice-types";
import useWorkersData from "../../../../../controllers/use-workers-data";
import ScheduleLegend from "./schedule-legend";

type LegendsContainerProps = {
	values: any;
};

type Schedule = {
	dayNo: number;
	shiftTypeId: number | null;
	colorCode: undefined | string;
};

const LegendsContainer = (props: LegendsContainerProps) => {
	const { allShiftSchedules } = useWorkersData();
	const { values } = props;

	const [selectedShiftSchedules, setSelectedShiftSchedule] = useState<
		Schedule[]
	>([]);

	useEffect(() => {
		try {
			if (allShiftSchedules.length > 0) {
				const uniqueShiftTypeIds = [
					...new Set(
						values.schedule.map(
							(obj: { shiftTypeId: number }) => obj.shiftTypeId
						)
					),
				];
				const filteredSchedules = allShiftSchedules.filter((obj: ShiftType) =>
					uniqueShiftTypeIds.includes(obj.id)
				);
				setSelectedShiftSchedule(filteredSchedules);
			}
		} catch (e) {
			// Do nothing
		}
	}, [allShiftSchedules, values]);
	const getTime = (time: string) => {
		const parsedTime = parse(time, "HH:mm:ss", new Date());
		const formattedTime = format(parsedTime, "h:mm a");
		return formattedTime;
	};

	return (
		<Box
			display='flex'
			alignItems='center'
			mt={2}
			flexWrap={"wrap"}
			width='820px'
		>
			<ScheduleLegend title='OFF' colorCode='#E0E0E0' subtitle='No shift' />
			{selectedShiftSchedules.map((shiftSchedule: any) => {
				const startTime = getTime(shiftSchedule.startTime);
				const endTime = getTime(shiftSchedule.endTime);
				return (
					<ScheduleLegend
						key={shiftSchedule.id}
						title={shiftSchedule.name}
						colorCode={shiftSchedule.colorCode ?? "#01579B"}
						subtitle={startTime + " - " + endTime}
					/>
				);
			})}
		</Box>
	);
};

export default LegendsContainer;
