/*
This component is developed only for demo
Not in production use, however we have to maintain in order for demo
*  */
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import {changeSelectedMenuItemId} from "../../../export/drawer";
import {NavDrawer} from "../../../export/nav-drawer";
import useViewport from "../../utils/useViewport";
import {REQUEST_NEW_SHIFTS, REQUIRED_WORKERS, SCHEDULED_SHIFTS, WORKERS_AVAILABILITY} from "./constants/pages";
import RequestNewShifts from "./ui/request-new-shifts";
import ScheduledShifts from "./ui/scheduled-shifts";
import ShiftsRequirement from "./ui/shifts-requirement";
import WorkersAvailability from "./ui/workers-availability";

const WeeklySchedule = () => {
	const {height} = useViewport();
	const [page, setPage] = useState(SCHEDULED_SHIFTS);
	const [regenerateShifts, setRegenerateShifts] = useState(false);

	const dispatch = useDispatch();
	useEffect(() => {
		// To select weeky schedule menu item
		dispatch(changeSelectedMenuItemId(1));
	}, [dispatch]);
	return (
		<Box sx={{
			display: "flex",
			backgroundColor: "#F5F5F5",
			height: height,
			minWidth: "1200px",
		}}>
			<CssBaseline />
			<NavDrawer keepSettingOpen={false} />
			{page === SCHEDULED_SHIFTS && (<ScheduledShifts changePage={(page) => setPage(page)} regenerateShifts={regenerateShifts} resetCallAlgo={() => setRegenerateShifts(false)}/>)}
			{page === REQUIRED_WORKERS && (<ShiftsRequirement changePage={(page) => setPage(page)} callAlgo={(call) => setRegenerateShifts(call)}/>)}
			{page === WORKERS_AVAILABILITY && (<WorkersAvailability changePage={(page) => setPage(page)} callAlgo={(call) => setRegenerateShifts(call)}/>)}
			{page === REQUEST_NEW_SHIFTS && (<RequestNewShifts changePage={(page) => setPage(page)}/>)}
		</Box>
	);
};

export default WeeklySchedule;
