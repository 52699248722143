import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
// import isThisWeek from "date-fns/isThisWeek";
import format from "date-fns/format";
import { useEffect, useReducer, useState } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { publishDraftShiftsAsync } from "../../../components/scheduler/store/scheduler-slice";
import { calculateShiftHoursAndMinutes } from "../../../utils/helpers";
import MobileReviewShiftCard from "./mobile-review-shiftCard";
import ReviewCheckbox from "./review-checkbox";

// { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },

const useStyles = makeStyles((theme) => ({
	actionBar: {
		marginTop: 8,
		marginBottom: 8,
	},
	btnLabel: {
		position: "relative",
	},
	progressIndicator: {
		position: "absolute",
		left: "50%",
	},
}));

function reducer(state:any, action:any) {
	switch (action.type) {
		case "selectShiftForPublish":
			return [...state, action.payload];
		case "deselectShiftForPublish":
			return state.filter((item:any) => {
				if (item === action.payload) return false;
				return true;
			});
		case "selectAllShiftForPublish":
			return [...action.payload];
		case "unselectAllShifts":
			return [];
		default:
			return state;
	}
}

function MobileReviewShifts({ open, handleClose }:any) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [state, dispatchLocal] = useReducer(reducer, []);

	const [rows, setRows] = useState([]);

	const [amISure, setAmISure] = useState(false);

	const shiftsData = useSelector(
		(state:any) => state.supervisor?.schedulerData?.shiftsData
	);

	const allJobRoles = useSelector(
		(state:any) => state.supervisor?.masterData?.allJobRoles
	);

	const publishDraftShiftsAsyncStatus = useSelector(
		(state:any) => state.supervisor?.publishDraftShiftsAsync?.status
	);

	// const publishDraftShiftsAsyncErrorMessage = useSelector(
	//   (state) => state.supervisor?.publishDraftShiftsAsync?.errorMessage
	// );



	useEffect(() => {
		// get draft(unpublished) shifts
		if (shiftsData) {
			const rowData:any = [];

			const drafts = shiftsData.filter((shift:any) => shift.isPublished === false);

			drafts.forEach((shift:any) => {
				const shiftTime = `${format(
					new Date(shift?.startDate),
					"EEE, MMMM do yyyy HH:mm"
				)} - ${format(new Date(shift?.endDate), "HH:mm")}`;

				const workersRequired = shift?.numberOfWorkersNeeded;

				const { hours, minutes } = calculateShiftHoursAndMinutes(
					shift?.startDate,
					shift?.endDate,
					workersRequired
				);

				const hoursScheduled = `${hours} hours ${minutes} mins`;

				const id = shift.id;

				let currentJobRole = allJobRoles.filter(
					(jobRole:any) => jobRole.id === shift.jobId
				);

				currentJobRole = currentJobRole[0];
				const jobRole = currentJobRole.name;
				const jobRoleColor = currentJobRole.hexColor;

				rowData.push({
					shiftTime,
					workersRequired,
					hoursScheduled,
					id,
					jobRole,
					jobRoleColor,
				});
			});
			// console.log(rowData);

			// console.log(drafts);
			setRows(rowData);
		}
	}, [shiftsData, allJobRoles]);

	const handleShiftSelection = (e:any, item:any) => {
		if (state.includes(item.id)) {
			dispatchLocal({ type: "deselectShiftForPublish", payload: item.id });
		} else dispatchLocal({ type: "selectShiftForPublish", payload: item.id });
	};

	const handleShiftSelectionAll = (e:any) => {
		if (e.target.checked) {
			const temp = rows.map((item:any) => item.id);
			dispatchLocal({ type: "selectAllShiftForPublish", payload: temp });
		} else {
			dispatchLocal({ type: "unselectAllShifts" });
		}
	};

	return (
		<Dialog
			onClose={handleClose}
			aria-labelledby='add-new-shift'
			open={open}
			// width={400}
			fullScreen
			//height={50}
		>
			<DialogTitle>
				<Box
					display='flex'
					flexDirection='row'
					justifyContent='space-between'
					alignItems='center'
				>
					<Box>
						<Typography>Review Publishing Shifts</Typography>
						<Typography variant='subtitle2'>
							Select shifts you want to publish
						</Typography>
					</Box>
					<IconButton
						edge='start'
						color='inherit'
						aria-label='menu'
						onClick={handleClose}
						size='small'
						style={{ float: "right" }}
					>
						<CloseIcon />
					</IconButton>
				</Box>
			</DialogTitle>
			<DialogContent dividers>
				<Box
					display='flex'
					alignItems='center'
					justifyContent='center'
					mr='auto'
					ml='auto'
					flexDirection='column'
				>
					<Box>
						<FormControl>
							<FormGroup>
								<FormControlLabel
									control={<Checkbox />}
									label='All Shifts'
									labelPlacement='end'
									onChange={handleShiftSelectionAll}
									checked={state.length === rows.length}
								/>
							</FormGroup>
						</FormControl>
						{rows.length &&
							rows.map((row:any) => {
								return (
									<MobileReviewShiftCard
										key={row.id}
										item={row}
										state={state}
										handleShiftSelection={handleShiftSelection}
									/>
								);
							})}
					</Box>
				</Box>
			</DialogContent>
			<DialogActions>
				<ReviewCheckbox amISure={amISure} setAmISure={setAmISure} />
				<Button
					onClick={() => {
						dispatch(publishDraftShiftsAsync(state));
					}}
					color='error'
					variant='contained'
					disabled={
						publishDraftShiftsAsyncStatus === "pending" ||
						publishDraftShiftsAsyncStatus === "error" ||
						amISure === false ||
						state.length < 1
					}
				>
					{publishDraftShiftsAsyncStatus === "pending" ? (
						<div className={classes.btnLabel}>
							<CircularProgress
								size={25}
								className={classes.progressIndicator}
							/>
							Publishing Shifts
						</div>
					) : (
						"Publish & Notify"
					)}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default MobileReviewShifts;
