// TODO - Type 'any' needs to be fixed.
import { Button, Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";

const LocationClusterErrorDialog = ({ open, onClose }:any) => {
  return (
    <Dialog
      onClose={() => onClose()}
      aria-labelledby="add-new-location-cluster"
      open={open}
      maxWidth={false}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <DialogTitle
        id="location-cluster-dialog-title"
        sx={{
          width: "900px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Roboto",
            fontSize: "20px",
            fontWeight: 500,
            color: "rgba(0, 0, 0, 0.87)",
            paddingTop: "29px",
            paddingLeft: "21px",
            paddingRight: "21px",
          }}
        >
          Error!
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          sx={{
            paddingLeft: "21px",
            paddingRight: "21px",
            paddingBottom: "12px",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Grid item xs={8}>
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "20px",
                color: "#49454F",
              }}
            >
              Location cluster can be created only when there are 2 or more
              locations available to form cluster in the company.
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            paddingTop: "12px",
            paddingLeft: "21px",
            paddingRight: "21px",
            paddingBottom: "16px",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Grid item xs={8}>
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "20px",
                color: "#49454F",
              }}
            >
              Please add few locations and return back here to create location
              clusters.
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "16px",
            marginBottom: "16px",
            marginRight: "24px",
            marginLeft: "24px",
            width: "100%",
          }}
        >
          <Box />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button variant="contained" onClick={() => onClose()}>
              Got it
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default LocationClusterErrorDialog;
