// TODO - Type 'any' needs to be fixed.
import { Box, Button, Typography } from "@mui/material";
import React from "react";

type WorkcenterFiltersProps = {
	roles: any[];
};

const WorkcenterFilters = ({ roles }: WorkcenterFiltersProps) => {
	const [workcenterExpand, setWorkcenterExpand] =
		React.useState<boolean>(false);

	if (roles.length === 0) return null;
	return (
		<Box display='flex' flexWrap='wrap' alignItems='center' mt={1} mb={1}>
			<Typography
				sx={{
					fontWeight: 400,
					fontSize: 14,
					fontFamily: "Roboto",
					color: "#000000",
					flexWrap: "wrap",
					marginRight: 1,
				}}
			>
				Work Centers -
			</Typography>
			{roles.length > 0 ? (
				roles
					.slice(0, workcenterExpand ? undefined : 5)
					.map((role: any, index: number) => (
						<Box key={`role-${role}-${index}`} mr={0.8}>
							<Typography
								sx={{
									fontWeight: 400,
									fontSize: 14,
									fontFamily: "Roboto",
									color: "#000000",
								}}
							>
								{`${role.name}${index === roles.length - 1 ? "" : ","}`}
							</Typography>
						</Box>
					))
			) : (
				<></>
			)}
			{roles.length >= 5 ? (
				<Button
					onClick={(e) => setWorkcenterExpand(!workcenterExpand)}
					size='small'
				>
					{workcenterExpand ? "Show Less" : "Show More"}
				</Button>
			) : null}
		</Box>
	);
};

export default WorkcenterFilters;
