/* eslint-disable no-mixed-spaces-and-tabs */
import { AddSharp, RemoveSharp } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import format from "date-fns/format";
import { useState } from "react";
import React from "react";

import Highlight from "../../../../Highlight";
import {
	BORDER_COLOR,
	TODAY_HIGHLIGHT_COLOR,
	WEEKEND_SHIFT_CELL_COLOR,
} from "../../../constants/colors";
import useScheduleController from "../../../controllers/schedule-controller";

const MAX_ALLOWED_REQUIREMENT_VALUE = 10000;

export const Row = ({
	jobId,
	jobName,
	datesArrayOfSelectedWeek,
	changeRequirements,
	searchValue,
}) => {
	const [
		,
		{ isWeekend, isToday, isPreviousDate, getDefaultRequirementForJobInDay },
	] = useScheduleController();

	const DayCell = ({
		backgroundColor = "rgba(255,255,255,0.5)",
		defaultRequirement,
		weekend,
		previousDate,
		changeRequirementsFromCell,
	}) => {
		const [workerCount, setWorkerCount] = useState(
			defaultRequirement.workerCount
		);
		const [isHovering, setIsHovering] = useState(false);
		const changeRequirementForDayAndJob = (newValue) => {
			let changed = false;
			if (newValue !== defaultRequirement.workerCount) {
				changed = true;
			}
			const requirement = {
				...defaultRequirement,
				workerCount: newValue ?? 0,
				jobHours: defaultRequirement.jobHours ?? 8,
				changed: changed,
			};
			// Silent state update in parent (do not re-render)
			changeRequirementsFromCell(requirement);
			// Local state
			setWorkerCount(newValue);
		};
		return (
			<Grid
				// key={`shift-req-${defaultRequirement.jobId}-${defaultRequirement.reqDate}-${init}`}
				borderLeft={1}
				borderColor={BORDER_COLOR}
				item
				xs={1.4}
				sx={{
					display: "flex",
					minWidth: "123px",
					minHeight: "72px",
				}}
				onMouseEnter={() => {
					setIsHovering(true);
				}}
				onMouseLeave={() => {
					setIsHovering(false);
				}}
			>
				<Box
					sx={{
						backgroundColor: weekend
							? WEEKEND_SHIFT_CELL_COLOR
							: backgroundColor,
						minHeight: "72px",
						flex: 1,
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<Box
						sx={{
							flex: 1,
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						{!previousDate && isHovering && (
							<RemoveSharp
								sx={{ marginRight: "12px", cursor: "pointer" }}
								onClick={() =>
									workerCount > 0
										? changeRequirementForDayAndJob(workerCount - 1)
										: {}
								}
							/>
						)}
						<Box
							sx={[
								{
									width: "36px",
								},
								isHovering
									? {
											borderBottom: 1,
											borderBottomWidth: "2px",
											borderBottomColor: previousDate
												? "rgba(255,255,255,0)"
												: "#2F4D8B",
									  }
									: {},
							]}
						>
							<InputBase
								//key={`worker-count-${defaultRequirement.jobId}-${defaultRequirement.reqDate}-${workerCount}`}
								disabled={previousDate}
								placeholder=''
								type='number'
								inputProps={{
									"aria-label": "Search job",
									style: {
										textAlign: "center",
										color: "rgba(0, 0, 0, 0.87)",
										fontSize: "16px",
										fontFamily: "Roboto",
										lineHeight: "24px",
										letterSpacing: "0.15px",
									},
								}}
								onFocus={(event) => {
									event.target.select();
								}}
								onBlur={() => {
									changeRequirementForDayAndJob(workerCount);
								}}
								value={workerCount}
								onChange={(event) => {
									const newValue = parseInt(event.target.value);
									if (event.target.value === "") {
										changeRequirementForDayAndJob(0);
									} else if (newValue <= 0) {
										changeRequirementForDayAndJob(0);
									} else if (newValue <= MAX_ALLOWED_REQUIREMENT_VALUE) {
										changeRequirementForDayAndJob(newValue);
									}
								}}
							/>
						</Box>
						{!previousDate && isHovering && (
							<AddSharp
								sx={{
									marginLeft: "12px",
									cursor: "pointer",
									color: "#2F4D8B",
								}}
								onClick={() =>
									workerCount <= MAX_ALLOWED_REQUIREMENT_VALUE
										? changeRequirementForDayAndJob(workerCount + 1)
										: {}
								}
							/>
						)}
					</Box>
				</Box>
			</Grid>
		);
	};
	return (
		<Grid
			wrap='nowrap'
			container
			sx={{
				display: "flex",
				flex: "1 0 auto",
			}}
		>
			<Grid
				item
				xs={2.2}
				sx={{
					backgroundColor: "rgba(255,255,255,0.5)",
					display: "flex",
					alignItems: "flex-start",
					minWidth: "123px",
				}}
			>
				<Box
					sx={{
						minHeight: "72px",
						display: "flex",
						paddingTop: "24px",
					}}
				>
					<Typography
						sx={{
							marginLeft: "24px",
							fontSize: "16px",
							fontFamily: "Roboto",
						}}
					>
						<Highlight value={jobName} searchText={searchValue} />
					</Typography>
				</Box>
			</Grid>
			<>
				{datesArrayOfSelectedWeek.map((date, index) => {
					return (
						<DayCell
							key={`day-${index}`}
							defaultRequirement={{
								jobId: jobId,
								reqDate: format(date, "yyyy-MM-dd"),
								workerCount: getDefaultRequirementForJobInDay(
									jobId,
									format(date, "yyyy-MM-dd")
								),
								changed: false,
							}}
							weekend={isWeekend(date)}
							changeRequirementsFromCell={changeRequirements}
							previousDate={isPreviousDate(date)}
							backgroundColor={
								isToday(date) ? TODAY_HIGHLIGHT_COLOR : "rgba(255,255,255,0.5)"
							}
						/>
					);
				})}
			</>
		</Grid>
	);
};
