import CreateIcon from "@mui/icons-material/Create";
import { Box, Chip, Tooltip, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import React, { useState } from "react";

import Highlight from "../../../../../Highlight";
import MyChip from "../../../supervisors/ui/my-chip";

interface useLocationColumnsProps {
	searchText: string;
}

const useLocationColumns = ({ searchText }: useLocationColumnsProps) => {
	const [expandWorkcenters, setExpandWorkcenters] = useState<number[]>([]);

	const onClickViewMoreWorkCenters = (id: number) => {
		const selectedWorkcentersList = expandWorkcenters.includes(id)
			? expandWorkcenters.filter((item: any) => item !== id)
			: [...expandWorkcenters, id];

		setExpandWorkcenters(selectedWorkcentersList);
	};

	const columns: GridColDef[] = [
		// { field: "id", headerName: "ID", width: 70, hide: true },
		{
			field: "name",
			headerName: "Location",
			minWidth: 160,
			flex: 1.5,
			valueGetter: (params) => {
				return params.row.name;
			},
			renderCell: (params) => (
				<Tooltip title={params.row.name}>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontSize: "14px",
							color: "rgba(0, 0, 0, 0.87)",
						}}
					>
						<Highlight value={params.row.name} searchText={searchText} />
					</Typography>
				</Tooltip>
			),
		},
		{
			field: "address",
			headerName: "Address",
			flex: 2,
			minWidth: 220,
			valueGetter: (params) => {
				return params.row.address1;
			},
			renderCell: (params) => (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						paddingTop: "4px",
						paddingBottom: "4px",
						minHeight: "45px",
						margin: "0",
					}}
				>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontSize: "14px",
							color: "rgba(0, 0, 0, 0.87)",
						}}
					>
						<Highlight value={params.row.address1} searchText={searchText} />
					</Typography>
					{params.row.address2 && (
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontSize: "14px",
								color: "rgba(0, 0, 0, 0.87)",
							}}
						>
							<Highlight value={params.row.address2} searchText={searchText} />
						</Typography>
					)}
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontSize: "14px",
							color: "rgba(0, 0, 0, 0.87)",
							display: "flex",
							flexWrap: "wrap",
						}}
					>
						{params.row.city && (
							<>
								<span>City:&nbsp;</span>
								<Highlight value={params.row.city} searchText={searchText} />
								<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
							</>
						)}
						{params.row.state && (
							<>
								<span>State:&nbsp; </span>
								<Highlight value={params.row.state} searchText={searchText} />
								<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
							</>
						)}

						{params.row.country && (
							<>
								<span>Country:&nbsp; </span>
								<Highlight value={params.row.country} searchText={searchText} />
								<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
							</>
						)}

						{params.row.zip && (
							<>
								<span>Zip:&nbsp; </span>
								<Highlight value={params.row.zip} searchText={searchText} />
								<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
							</>
						)}
					</Typography>
				</Box>
			),
		},
		{
			field: "numOfWorkers",
			headerName: "Workers",
			minWidth: 100,
			headerAlign: "center",
			align: "center",
			flex: 1,
			valueGetter: (params) => {
				return `${params.row.numOfWorkers}`;
			},
			renderCell: (params) => {
				return (
					<Tooltip title={params.row.numOfWorkers}>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontSize: "14px",
								color: "rgba(0, 0, 0, 0.87)",
							}}
						>
							<Highlight
								value={params.row.numOfWorkers}
								searchText={searchText}
							/>
						</Typography>
					</Tooltip>
				);
			},
		},
		{
			field: "workcenters",
			headerName: "Workcenters",
			minWidth: 110,
			headerAlign: "center",
			align: "center",
			flex: 1,
			valueGetter: (params) => {
				return `${params.row.workCenters.length}`;
			},
			renderCell: (params) => {
				return (
					<Tooltip title={params.row.workCenters.length}>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontSize: "14px",
								color: "rgba(0, 0, 0, 0.87)",
							}}
						>
							<Highlight
								value={params.row.workCenters.length}
								searchText={searchText}
							/>
						</Typography>
					</Tooltip>
				);
			},
		},
		{
			field: "userWorkCenters",
			headerName: "Workcenter Names",
			minWidth: 280,
			flex: 2,
			renderCell: (params) => {
				return (
					<Box>
						<MyChip
							searchText={searchText}
							userWorkCenters={params.row.workCenters}
							rowId={params.row.id}
							expandWorkcenters={expandWorkcenters}
							onClickViewMoreWorkCenters={onClickViewMoreWorkCenters}
						/>
					</Box>
				);
			},
			valueGetter: (params) => params.row.workCenters.length,
		},

		{
			field: "contactForWorkers",
			headerName: "Point of Contact",
			minWidth: 160,
			flex: 1.5,
			renderCell: (params) => {
				return (
					<Tooltip title={params.row.contactForWorkers}>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								width: "100%",
								overflow: "hidden",
							}}
						>
							<Typography
								sx={{
									fontFamily: "Roboto",
									fontSize: "14px",
									color: "rgba(0, 0, 0, 0.87)",
									whiteSpace: "normal",
									wordWrap: "break-word",
								}}
							>
								<Highlight
									value={params.row.contactForWorkers}
									searchText={searchText}
								/>
							</Typography>
						</Box>
					</Tooltip>
				);
			},
		},
		{
			field: "isActive",
			headerName: "Status",
			minWidth: 100,
			flex: 1,
			renderCell: (params) => {
				return (
					<Chip
						size='small'
						label={
							<Highlight
								value={params.row.isActive ? "Active" : "Inactive"}
								searchText={searchText}
							/>
						}
						variant='filled'
						style={{
							marginTop: "4px",
							marginBottom: "4px",
							marginRight: "4px",
						}}
						sx={{
							paddingRight: "8px",
							paddingLeft: "8px",
							background: params.row.isActive ? "#E8F5E9" : "#FEEBEE",
						}}
					/>
				);
			},
		},
		{
			field: "icon",
			headerName: "Actions",
			headerAlign: "right",
			align: "right",
			width: 100,
			cellClassName: "hover-column-cell",
			headerClassName: "hover-column-header",
			sortable: false,
			flex: 1,
			renderHeader: () => (
				<span
					style={{
						fontWeight: "bold",
						width: "74px",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					{""}
				</span>
			),
			renderCell: (params) => (
				<Box
					className='hover-content'
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						width: "100px",
						padding: "6px 0px 2px 0px",
						borderRadius: "4px",
						zIndex: 1,
					}}
				>
					<Box
						sx={{
							color: "#6c6c6c",
							"&:hover": {
								color: "#000000",
								transform: "scale(1.2)",
							},
							cursor: "pointer",
						}}
					>
						<Tooltip title='Edit'>
							<CreateIcon
								id={`edit-job-${params?.row?.id}-enabled`}
								fontSize='small'
								sx={{ cursor: "pointer" }}
								onClick={(e) => {
									params.row.handleClick(params.row);
									e.stopPropagation();
								}}
							/>
						</Tooltip>
					</Box>
				</Box>
			),
		},
	];

	return columns;
};

export default useLocationColumns;
