import { GridColDef } from "@mui/x-data-grid-pro-6";
interface TableColumnConfig {
	field: string;
	type: string;
	minWidth?: number;
	width?: number;
	flex?: number;
	headerName?: string;
}

const useColumnDivider = (
	columns: GridColDef[],
	fixedColumns: string[],
	showByDefault: string[]
) => {
	const defaultColumns = columns
		.filter((column) => fixedColumns.includes(column.field))
		.map((column) => {
			return {
				checked: true,
				...column,
			};
		});

	const restColumns = columns
		.filter((column) => !fixedColumns.includes(column.field))
		.map((column) => {
			if (showByDefault?.includes(column.field)) {
				return {
					checked: true,
					...column,
				};
			}
			return {
				checked: false,
				...column,
			};
		});

	// console.log("defaultColumns", defaultColumns, restColumns);

	return {
		defaultColumns,
		restColumns,
	};
};

export default useColumnDivider;
