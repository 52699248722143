import { Box, Typography } from "@mui/material";
import { DataGridPro, GridValidRowModel } from "@mui/x-data-grid-pro";
import React from "react";

import {WorkerCert} from "../../../../../types"
import useRequiredCertificationsColumns from "./controllers/use-required-certification-columns";

interface RequiredCertificationsTableProps {
	certifications: WorkerCert[];
}

const RequiredCertificationsTable:React.FC<RequiredCertificationsTableProps> = ({certifications}) => {
	const columns = useRequiredCertificationsColumns();
	const rows = certifications?.map((cert) => ({
		...cert,
		id: cert.certName,
	}));
	return (
		<>
			<Box sx={{mb:1.5, padding: "0 45px"}}>
				<Typography
				sx={{
					fontFamily: "Roboto",
					fontSize: "14px",
					fontStyle: "normal",
					fontWeight: 400,
					lineHeight: "20px",
					letterSpacing: "0.17px",
				}}
				>Required Certifications ({rows.length})</Typography>
			</Box>
			<Box
				sx={{
					height: 300,
					width: "100%",
					padding: "0 30px",
				}}
			>
				<DataGridPro
					columns={columns}
					rows={rows ?? []}
					hideFooter
					sx={{
						"& .MuiDataGrid-row": {
							borderBottom: "none",
						},
						"& .MuiDataGrid-columnsContainer": {
							borderRight: "none",
						},
                        "& .MuiDataGrid-columnSeparator": {
                            display: "none",
                        },
						"& .MuiDataGrid-cell": {
							borderBottom: "none",
						},
						"&.MuiDataGrid-root": {
							border: "none !important",
						},
						"& .MuiDataGrid-root--densityStandard":{
							border: "none !important",
						},
						"& .MuiDataGrid-columnHeaders":{
							border: "none !important",
						},
					}}
					getRowHeight={() => 52}
                    disableColumnFilter
                    disableColumnMenu
                    disableColumnSelector
                    disableDensitySelector
                    disableColumnResize
					disableSelectionOnClick
				/>
			</Box>
		</>
	);
};

export default RequiredCertificationsTable;
