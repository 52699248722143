import { Box, Switch, Typography } from "@mui/material";
import React, { Dispatch } from "react";
interface IFlexScheduleProps {
	newAddOnSettings: any
	setNewAddOnSettings: Dispatch<any>;
}

const FlexSchedule: React.FC<IFlexScheduleProps> = ({ newAddOnSettings, setNewAddOnSettings }) => {
	return (
		<Box
			sx={{
				backgroundColor: "#F5F5F5",
				borderRadius: "8px",
				marginTop: "32px",
				padding: "16px",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 400,
							fontSize: "16px",
							color: "rgba(0, 0, 0, 0.87)",
						}}
					>
						Flex Scheduling
					</Typography>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 400,
							fontSize: "12px",
							color: "rgba(0, 0, 0, 0.6)",
						}}
					>
						Manage scheduling for flex workers at your locations.
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Switch
						disabled
						checked={newAddOnSettings.flexSchedulingEnabled ?? true}
						onChange={(e) => {
							setNewAddOnSettings((pre: any) => ({
								...pre,
								flexSchedulingEnabled: e.target.checked,
							}));
						}}
					/>
				</Box>
			</Box>
		</Box>
	);
};

export default FlexSchedule;
