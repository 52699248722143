import MuiAlert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import PropTypes from "prop-types";
import * as React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { updateCreateNewCompany } from "../../../../../export/gat-admin";
import { setDemoSetupOwnerDialog } from "../../store/gat-admin-slice";
import useManageFactoriesController from "../controllers/manage-factories-controller";
import AdminHeader from "./admin-header";
import FactoryTable from "./factory-table";
import SetupDemoOwnerDialog from "./setup-demo-owner";

function TabPanel(props: any) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box sx={{ p: 3, paddingBottom: 1 }}>{children}</Box>}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const ManageFactoriesTabs = () => {
	const [
		{
			filteredCompanies,
			companiesLoadingStatus,
			isBoeing,
			searchQuery,
			creatingNewCompany,
			setupDemoOwnerCompanyObj,
			editUserRoleToDemoResetRoleStatus,
			openSetUpDemoOwnerDialog
		},
		{ getAllCompaniesErrorAlert, setSearchQuery, setStateDemoOwnerCompanyObj },
	]: any = useManageFactoriesController();

	const dispatch = useDispatch();

	const [value, setValue] = React.useState(0);

	const handleChange = (event: any, newValue: any) => {
		setValue(newValue);
	};

	useEffect(() => {
		return () => {
			dispatch(setDemoSetupOwnerDialog(false));
		};
	}, [dispatch]);

	return (
		<Box sx={{ width: "100%" }}>
			<Box sx={{ borderBottom: 1, borderColor: "divider" }} pl={1}>
				<Tabs
					value={value}
					onChange={handleChange}
					aria-label='basic tabs example'
				>
					<Tab label='Companies' {...a11yProps(0)} />
				</Tabs>
			</Box>
			<TabPanel value={value} index={0}>
				<AdminHeader
					isBoeing={isBoeing}
					searchText={searchQuery}
					setSearch={setSearchQuery}
					creatingNewCompany={creatingNewCompany}
					setCreatingNewCompany={(value: any) =>
						dispatch(updateCreateNewCompany(value))
					}
				/>
				<FactoryTable
					isBoeing={isBoeing}
					filteredCompanies={filteredCompanies}
					companiesLoadingStatus={companiesLoadingStatus}
					creatingNewCompany={creatingNewCompany}
					setCreatingNewCompany={(value: any) =>
						dispatch(updateCreateNewCompany(value))
					}
					searchText={searchQuery}
					setStateDemoOwnerCompanyObj={setStateDemoOwnerCompanyObj}
				/>
				{openSetUpDemoOwnerDialog && <SetupDemoOwnerDialog open={openSetUpDemoOwnerDialog} editUserRoleToDemoResetRoleStatus={editUserRoleToDemoResetRoleStatus} setupDemoOwnerCompanyObj={setupDemoOwnerCompanyObj} />}
				{companiesLoadingStatus === "error" && getAllCompaniesErrorAlert}
				{editUserRoleToDemoResetRoleStatus === "fulfilled" ? (
					<Snackbar
						open={editUserRoleToDemoResetRoleStatus === "fulfilled"}
						autoHideDuration={3000}
						onClose={() => dispatch(setDemoSetupOwnerDialog(false))}
						anchorOrigin={{ vertical: "top", horizontal: "center" }}
					>
						<MuiAlert elevation={6} variant='filled' severity='success'>
							Demo Reset Access Applied
						</MuiAlert>
					</Snackbar>
				) : null}
			</TabPanel>
		</Box>
	);
};

export default ManageFactoriesTabs;
