// TODO - Type 'any' needs to be fixed.
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box, Button, Skeleton, TextField, Typography } from "@mui/material";
import React from "react";

const ContractuallyAllowedLocation = ({
	locations,
	newCoreSettings,
	setNewCoreSettings,
	getCompanySettingsStatus,
}: any) => {
	return (
		<Box
			sx={{
				backgroundColor: "#F5F5F5",
				borderRadius: "8px",
				marginTop: "32px",
				padding: "16px",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 400,
							fontSize: "16px",
							color: "rgba(0, 0, 0, 0.87)",
						}}
					>
						Number of locations allowed
					</Typography>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 400,
							fontSize: "12px",
							color: "rgba(0, 0, 0, 0.6)",
						}}
					>
						Maximum number of locations that can be created under this company.
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Button
						disabled={
							newCoreSettings.maxLocationsByContract === locations?.length ||
							newCoreSettings.maxLocationsByContract === 0 ||
							getCompanySettingsStatus === "pending"
						}
						onClick={() => {
							setNewCoreSettings({
								...newCoreSettings,
								maxLocationsByContract:
									Number(newCoreSettings.maxLocationsByContract) - 1,
							});
						}}
					>
						<RemoveIcon />
					</Button>
					{getCompanySettingsStatus === "pending" ? (
						<Skeleton variant='rectangular' width={40} height={40} />
					) : (
						<Box mr={1} ml={1}>
							<TextField
								id='number-of-minimum-hours'
								variant='filled'
								type='number'
								onFocus={(event) => {
									event.target.select();
								}}
								sx={{
									"& .MuiInputBase-input": {
										background: "#FFF",
										padding: "8px",
									},
									width: "40px",
								}}
								onBlur={() => {
									/* Do Nothing */
								}}
								onChange={(event) => {
									if (parseInt(event.target.value) < 0) {
										return;
									} else {
										setNewCoreSettings({
											...newCoreSettings,
											maxLocationsByContract: parseInt(event.target.value),
										});
									}
								}}
								value={newCoreSettings.maxLocationsByContract ?? 0}
								error={newCoreSettings.maxLocationsByContract < 0}
								helperText={""}
							/>
						</Box>
					)}
					<Button
						onClick={() =>
							setNewCoreSettings({
								...newCoreSettings,
								maxLocationsByContract:
									Number(newCoreSettings.maxLocationsByContract) + 1,
							})
						}
						disabled={getCompanySettingsStatus === "pending"}
					>
						<AddIcon />
					</Button>
				</Box>
			</Box>
		</Box>
	);
};

export default ContractuallyAllowedLocation;
