// import Button from "@gigandtake/button";
import { Box, Typography } from "@mui/material";
import React from "react";

import Button from "../../../../../design/button";

interface ActionButtonsProps {
  markAttendanceStatus: "pending" | "success" | "error";
  markAttendanceError: string;
  onClose: () => void;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({
  markAttendanceStatus,
  markAttendanceError,
  onClose,
}) => {
  return (
    <Box>
      {markAttendanceStatus === "error" ? (
        <Typography variant="body2" color="error">
          {markAttendanceError}
        </Typography>
      ) : null}
      <Button onClick={onClose}>
        Close
      </Button>
    </Box>
  );
};

export default ActionButtons;
