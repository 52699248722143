// TODO - Type 'any' needs to be fixed.
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import {
	Box,
	Button,
	Chip,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { formatDate } from "../../../../utils/helpers";
import { markAttendance } from "../../../scheduler/store/scheduler-slice";
import useDayReportData from "../../controllers/use-day-report-data";

interface workerProps {
	workerId: number;
	comment: string;
	status: string;
	shiftId: number;
	fullName: string;
	startDateTime: string;
	endDateTime: string;
}

interface ICommentDialog {
	worker: workerProps;
}

const AttendanceCommentDialog: React.FC<ICommentDialog> = ({ worker }) => {
	const dispatch = useDispatch();
	const { markAttendanceStatus } = useDayReportData();
	const [activeChip, setActiveChip] = useState("");
	const [isDialogOpen, setIsDialogOpen] = useState(false);

	const [comment, setComment] = useState(worker.comment ?? "");

	// useEffect(() => {
	// 	setActiveChip(worker.status);
	// }, [worker]);

	useEffect(() => {
		if (markAttendanceStatus === "fulfilled") {
			handleClose();
		}
	}, [markAttendanceStatus]);

	const handleOpen = () => {
		setActiveChip(worker.status);
		setComment(worker.comment ?? "");
		setIsDialogOpen(true);
	};

	const handleClose = () => {
		setIsDialogOpen(false);
	};

	const handleSubmit = () => {
		dispatch(
			markAttendance({
				workerId: worker.workerId,
				status: activeChip,
				shiftId: worker.shiftId,
				comment: comment,
			})
		);
	};

	const Divider = () => {
		return (
			<Box
				sx={{
					minWidth: "1px",
					minHeight: "8px",
					backgroundColor: "rgba(0, 0, 0, 0.38)",
				}}
			/>
		);
	};

	const disableSave = () => {
		return comment.length > 250 || markAttendanceStatus === "pending" || (activeChip === worker.status && comment === worker.comment);
	}

	return (
		<>
			<FactCheckOutlinedIcon
				onClick={handleOpen}
				sx={{
					cursor: "pointer",
					color: "#2F4D8B",
				}}
			/>
			{isDialogOpen && (
				<Dialog
					open={isDialogOpen}
					onClose={handleClose}
					aria-describedby='alert-dialog-slide-description'
					sx={{
						"& .MuiDialog-paper": {
							width: "900px",
							maxWidth: "900px",
						},
					}}
				>
					<DialogTitle sx={{ padding: "45px 45px 24px" }}>
						Mark Attendance
					</DialogTitle>
					<DialogContent
						sx={{
							padding: "0px 45px 24px",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								gap: "12px",
							}}
						>
							<Box display={"flex"} gap={1} alignItems={"center"}>
								<PersonOutlineOutlinedIcon />
								<Typography
									sx={{
										fontSize: "14px",
										fontWeight: 700,
										lineHeight: "20px",
									}}
								>
									{worker.fullName}
								</Typography>
							</Box>
							<Box>
								<Typography
									sx={{
										fontSize: "14px",
										fontWeight: 400,
										lineHeight: "20px",
									}}
								>
									Shift Details:
								</Typography>
								<Box display={"flex"} gap={1} alignItems={"center"}>
									<AccessTimeIcon />
									<Typography
										sx={{
											fontSize: "14px",
											fontWeight: 400,
											lineHeight: "20px",
										}}
									>
										{formatDate(
											{
												dateStr: worker.startDateTime,
												formatType: "SHORT_DATE",
											}
										)}
										{", "}
										{
											formatDate({
												dateStr: worker.startDateTime,
												formatType: "TIME_RANGE",
												endDateStr: worker.endDateTime,
											})
										}
									</Typography>
								</Box>
							</Box>
						</Box>
						<Box
							mt={"20px"}
							display={"flex"}
							flexDirection={"column"}
							gap={"10px"}
						>
							<Typography
								sx={{
									fontSize: "12px",
									fontWeight: 400,
									lineHeight: "12px",
									color: "#00000099",
								}}
							>
								Attendance Status
							</Typography>
							<Box
								sx={{
									display: "flex",
									gap: "12px",
									alignItems: "center",
								}}
							>
								<Chip
									label={"Present"}
									color={activeChip === "p" ? "primary" : "default"}
									size='medium'
									onClick={() => setActiveChip("p")}
								/>
								<Divider />
								<Chip
									label={"Excused Absence"}
									color={
										activeChip === "e" ? "primary" : "default"
									}
									size='medium'
									onClick={() => setActiveChip("e")}
								/>
								<Chip
									label={"Excused Tardy"}
									color={activeChip === "x" ? "primary" : "default"}
									size='medium'
									onClick={() => setActiveChip("x")}
								/>
								<Divider />
								<Chip
									label={"Tardy"}
									color={activeChip === "t" ? "primary" : "default"}
									size='medium'
									onClick={() => setActiveChip("t")}
								/>
								<Divider />
								<Chip
									label={"No Show"}
									color={activeChip === "a" ? "primary" : "default"}
									size='medium'
									onClick={() => setActiveChip("a")}
								/>
							</Box>
						</Box>
						<Box
							sx={{
								marginTop: "18px",
							}}
						>
							<TextField
								id='outlined-multiline-static'
								multiline
								rows={3}
								fullWidth
								defaultValue={worker.comment ?? ""}
								onChange={(e) => setComment(e.target.value)}
								error={comment.length > 250}
								helperText={
									comment.length > 250
										? "Comment should be less than 250 characters."
										: ""
								}
								variant='filled'
								placeholder='This comment will be visible to the worker'
								label='Supervisors Comment (optional)'
							/>
							<Typography
								sx={{
									fontSize: "12px",
									color: comment.length > 250 ? "red" : "#00000099",
									display: "flex",
									justifyContent: "flex-end",
									fontWeight: 400,
								}}
							>
								{comment.length} / 250
							</Typography>
						</Box>
					</DialogContent>
					<DialogActions
						sx={{
							padding: "33px 45px",
						}}
					>
						<Box
							sx={{
								display: "flex",
								gap: "12px",
							}}
						>
							<Box>
								<Button onClick={handleClose}>Cancel</Button>
							</Box>
							<Button
								disabled={disableSave()}
								onClick={handleSubmit}
								variant='contained'
								startIcon={markAttendanceStatus === "pending" && <CircularProgress size={20} />}
							>
								{markAttendanceStatus === "pending" ? "Saving" : "Save"}
							</Button>
						</Box>
					</DialogActions>
				</Dialog>
			)}
		</>
	);
};

export default AttendanceCommentDialog;
