import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import useGatAdminConfigData from "../../../../controllers/use-gat-admin-config-data";
import {
	getWorkerNotificationTypes,
	updateCompanySettings,
	updateWorkerNotificationTypes,
} from "../../../../store/gat-admin-slice";
import { findDifferentKeys } from "../../../../utils";

const notificationsSubCategoryWorker = [
	"workerLocationComapanyAnnouncements",
	"workerLocationShiftUpdates",
	"workerLocationAttendanceUpdates",
	"workerLocationprofileUpdates",
];

const commsObj: any = {
	dailyReportToUser: "comm.dailyReportToUser",
	dailySmsToWorker: "comm.dailySmsToWorker",
	dailySmsToWorkerTime: "comm.dailySmsToWorkerTime",
	dailyReportToUserTimeCompany: "commCompany.dailyReportToUserTime",

	companyCommTimezone: "commCompany.timeZone",
	allowSupervisorNotificationsCompany: "supEmailNotify.Enabled",
	allowSupervisorNotificationsLocation: "supEmailNotify.Enabled",
	allowWorkerNotificationsCompany: "workerNotify.Enabled",
	allowWorkerNotificationsLocation: "workerNotify.Enabled",

	workerLocationComapanyAnnouncements: "1",
	workerLocationShiftUpdates: "2",
	workerLocationAttendanceUpdates: "3",
	workerLocationprofileUpdates: "4",
};

const initialCommunicationSettings: any = {
	dailyReportToUser: false,
	dailyReportToUserTimeCompany: "09:00:00",

	companyCommTimezone: "",
	allowSupervisorNotificationsCompany: false,
	allowWorkerNotificationsCompany: false,

	allowSupervisorNotificationsLocation: false,

	allowWorkerNotificationsLocation: false,
	workerLocationComapanyAnnouncements: false,
	workerLocationShiftUpdates: false,
	workerLocationAttendanceUpdates: false,
	workerLocationprofileUpdates: false,
	dailySmsToWorker: false,
	dailySmsToWorkerTime: "09:00:00",
};

const useCommunicationController = (entity: any) => {
	const dispatch = useDispatch();
	const {
		updateCompanySettingsStatus,
		companySettingsObj,
		locationSettingsObj,
		allSettings,
		workerNotifyCats,
		getLocationSettingsStatus,
		locationId,
	} = useGatAdminConfigData();

	const [loading, setLoading] = useState(false);
	const setting =
		entity === "company" ? companySettingsObj : locationSettingsObj;

	const [communicationSettings, setCommunicationSettings] = useState(
		initialCommunicationSettings
	);
	const [newCommunicationSettings, setNewCommunicationSettings] = useState<{
		[key: string]: any;
	}>({});

	const handleSave = () => {
		try {
			setLoading(true);
			const diffKeys: any = findDifferentKeys(
				communicationSettings,
				newCommunicationSettings
			);
			const subCategoryNotifs: any = [];
			const changedSettings: any = [];

			diffKeys.forEach((key: string) => {
				if (
					entity === "location" &&
					notificationsSubCategoryWorker.includes(key)
				) {
					const obj = workerNotifyCats.filter(
						(cat: { categoryId: number }) =>
							cat.categoryId === parseInt(commsObj[key])
					)[0];

					subCategoryNotifs.push({
						categoryId: obj.categoryId,
						entity: "location",
						entityId: locationId,
						enabled: newCommunicationSettings[key],
					});
				} else {
					const obj = setting[commsObj[key]]
						? setting[commsObj[key]]
						: allSettings[commsObj[key]];
					changedSettings.push({
						...obj,
						value: newCommunicationSettings[key],
					});
				}
			});

			if (entity === "location" && subCategoryNotifs.length > 0)
				dispatch(updateWorkerNotificationTypes(subCategoryNotifs));
			if (changedSettings.length > 0)
				dispatch(updateCompanySettings(changedSettings));
		} catch (error) {
			console.log("Error in handleSave", error);
		}
	};

	useEffect(() => {
		try {
			if (Object.keys(companySettingsObj).length > 0 && entity === "company") {
				const obj = {
					dailyReportToUserTimeCompany:
						companySettingsObj["commCompany.dailyReportToUserTime"].value,
					companyCommTimezone: companySettingsObj["commCompany.timeZone"].value,
					allowSupervisorNotificationsCompany:
						companySettingsObj["supEmailNotify.Enabled"].value,
					allowWorkerNotificationsCompany:
						companySettingsObj["workerNotify.Enabled"].value,
				};
				setCommunicationSettings(obj);
				setNewCommunicationSettings(obj);
			}
		} catch (error) {
			console.log("Error in communication settings", error);
		}
	}, [companySettingsObj, entity]);

	useEffect(() => {
		try {
			if (
				Object.keys(locationSettingsObj).length > 0 &&
				workerNotifyCats.length > 0 &&
				entity === "location"
			) {
				const obj = {
					dailySmsToWorker: locationSettingsObj["comm.dailySmsToWorker"].value,
					dailySmsToWorkerTime:
						locationSettingsObj["comm.dailySmsToWorkerTime"].value,

					allowSupervisorNotificationsLocation:
						locationSettingsObj["supEmailNotify.Enabled"].value,
					allowWorkerNotificationsLocation:
						locationSettingsObj["workerNotify.Enabled"].value,

					workerLocationComapanyAnnouncements: workerNotifyCats.find(
						(cat: { categoryId: number }) => cat.categoryId === 1
					).enabled,
					workerLocationShiftUpdates: workerNotifyCats.find(
						(cat: { categoryId: number }) => cat.categoryId === 2
					).enabled,
					workerLocationAttendanceUpdates: workerNotifyCats.find(
						(cat: { categoryId: number }) => cat.categoryId === 3
					).enabled,
					workerLocationprofileUpdates: workerNotifyCats.find(
						(cat: { categoryId: number }) => cat.categoryId === 4
					).enabled,
				};
				setCommunicationSettings(obj);
				setNewCommunicationSettings(obj);
			}
		} catch (error) {
			console.log("Error in communication settings", error);
		}
	}, [locationSettingsObj, workerNotifyCats, entity]);

	useEffect(() => {
		if (updateCompanySettingsStatus === "fulfilled") {
			if (entity === "location")
				dispatch(getWorkerNotificationTypes(locationId));
			setCommunicationSettings(newCommunicationSettings);
			setLoading(false);
		}
	}, [
		updateCompanySettingsStatus,
		newCommunicationSettings,
		entity,
		locationId,
		dispatch,
	]);

	return [
		{
			communicationSettings,
			newCommunicationSettings,
			updateCompanySettingsStatus,
			loading,
			getLocationSettingsStatus,
		},
		{
			newCommunicationSettings,
			setNewCommunicationSettings,
			handleSave,
			setLoading,
		},
	];
};

export default useCommunicationController;
