// TODO - Type 'any' needs to be fixed.
import { Box, Button, Typography } from "@mui/material";
import React from "react";

import Highlight from "../../../../../Highlight";

type SkillProps = {
	skills: any[];
	searchText: string;
};

const SkillColumn = ({ skills, searchText }: SkillProps) => {
	const [expand, setExpand] = React.useState<boolean>(false);

	const totalChars =
		skills.length > 2 && skills[0].name.length + skills[1].name.length;

	const limit = totalChars > 16 ? 1 : 2;

	if (skills.length === 0) return null;
	return (
		<Box display='flex' flexWrap='wrap' mt={1} mb={1} alignItems='center'>
			{skills.length > 0 ? (
				skills
					.slice(0, expand || searchText ? undefined : limit)
					.map((role: { name: string; id: number }, index: number) => (
						<Box key={role.id} mr={0.8}>
							<Highlight
								value={`${role.name} ${
									index === skills.length - 1 ? " " : ","
								}`}
								searchText={searchText}
							/>
						</Box>
					))
			) : (
				<></>
			)}
			{skills.length > 2 && !searchText ? (
				<Button onClick={(e) => setExpand(!expand)} size='small'>
					{expand ? "Show Less" : "Show More"}
				</Button>
			) : null}
		</Box>
	);
};

export default SkillColumn;
