import addDays from "date-fns/addDays";
import differenceInMinutes from "date-fns/differenceInMinutes";
import startOfWeek from "date-fns/startOfWeek";
import { parsePhoneNumber } from "libphonenumber-js";
import sortBy from "lodash/sortBy";
import { useEffect, useRef } from "react";

import {
  ALPHABET,
  BOX,
  CIRCLE,
  DONUT,
  EMPTY,
  FULL,
  HALF,
  NUMBER,
  QUARTER,
  THREE_QUARTER,
} from "../components/cross-skilling/constants/constants";

// Adding date time to a bunch of shifts in an array
// export const addDateTimeToShifts = (allShiftsData) => {
//   return allShiftsData.map((shift) => {
//     let startDate = `${shift?.shiftDate?.split("T")[0]}T${shift?.startTime}`;
//     let endDate = `${shift?.shiftDate?.split("T")[0]}T${shift?.endTime}`;
//     shift.startDate = startDate;
//     shift.endDate = endDate;
//     return shift;
//   });
// };

// 🆕
//Add date time as per the api specification to a bunch of shifts in an array
export const addStartDateEndDate = (allShiftsData) => {
  if (allShiftsData) {
    return allShiftsData.map((shift) => {
      /*
      TODO: To activate timezones
      let startDate = `${shift.startDateTime}.000Z`;
      let endDate = `${shift.endDateTime}.000Z`;
      */
      let startDate = shift.startDateTime;
      let endDate = shift.endDateTime;
      shift.startDate = startDate;
      shift.endDate = endDate;
      return shift;
    });
  } else {
    return [];
  }
};

export const addSkillsToShifts = (allShiftData) => {
  try {
    // Shift by skills related changes
    // appending skills array object to shift
    let shiftsData = [];
    const shiftsBySkills = allShiftData.skills ?? [];
    const allShiftsArray = allShiftData.signupTracking ?? [];
    allShiftsArray.forEach((shiftObject) => {
      shiftsData.push({
        ...shiftObject,
        skills: shiftsBySkills.filter(
          (shiftBySkill) => shiftBySkill.shiftId === shiftObject.id
        ),
      });
    });
    return shiftsData;
  } catch (e) {
    return [];
  }
};

// 🆕
// Add date time as per the api specification to a single shifts
export const addStartDateEndDateToAShift = (shift) => {
  let startDate = shift.startDateTime;
  let endDate = shift.endDateTime;
  shift.startDate = startDate;
  shift.endDate = endDate;
  return shift;
};

// Adding date time to single shift object
// export const addDateTimeToShift = (shift) => {
//   const newShift = { ...shift };
//   let startDate = `${shift?.shiftDate?.split("T")[0]}T${shift?.startTime}`;
//   let endDate = `${shift?.shiftDate?.split("T")[0]}T${shift?.endTime}`;
//   newShift.startDate = startDate;
//   newShift.endDate = endDate;
//   return newShift;
// };

/*
    Takes a date and returns all the days of the week's date based
    on that date in the format:
    {
        monday: Date,
        tuesday: Date,
        ...
        sunday: Date,
    }

    Week starts on Monday
*/

export const generateDaysOfTheWeek = (seedDate) => {
  let monday = startOfWeek(seedDate, {
    weekStartsOn: 1,
  });
  let tuesday = addDays(monday, 1);
  let wednesday = addDays(monday, 2);
  let thursday = addDays(monday, 3);
  let friday = addDays(monday, 4);
  let saturday = addDays(monday, 5);
  let sunday = addDays(monday, 6);

  return {
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
    sunday,
  };
};

export const generateDaysOfTheWeekSerialized = (seedDate) => {
  const daysOfTheWeek = generateDaysOfTheWeek(seedDate);
  return Object.keys(daysOfTheWeek).reduce((acc, key) => {
    acc[key] = daysOfTheWeek[key].toISOString();
    return acc;
  }, {});
};

// export const addStartDateEndDateToShifts = (shiftsData) => {
//   return shiftsData.map((shift) => {
//     let startDate = `${shift?.shiftDate?.split("T")[0]}T${shift?.startTime}`;
//     let endDate = `${shift?.shiftDate?.split("T")[0]}T${shift?.endTime}`;
//     shift.startDate = startDate;
//     shift.endDate = endDate;
//     return shift;
//   });
// };

export const calculateShiftHoursAndMinutes = (
  startTime,
  endTime,
  numberOfWorkersNeeded
) => {
  let diffInMins = differenceInMinutes(new Date(endTime), new Date(startTime));

  diffInMins *= numberOfWorkersNeeded;

  const hours = Number.parseInt(diffInMins / 60);
  const minutes = diffInMins % 60;

  return { hours, minutes };
};

// export const generateWorkersData = (allWorkers,allJobRoles) => {
//   //console.log(allJobRoles)
//   return Object.values(allWorkers).reduce((acc, key) => {
//     let {
//       id,
//       company,
//       firstName,
//       lastName,
//       mobilePhoneNumber: phoneNumber,
//       type,
//       jobIds: jobs,
//     } = key;
//
//     // Setting Job Roles column
//     let jobsString = "";
//     jobs = jobs.split(",");
//     jobs.forEach((job) => {
//       let jobName = allJobRoles[job]?.name ?? "";
//       jobsString += `${jobName}, `;
//     });
//
//     // Removing trailing commas
//     jobsString = jobsString.replace(/,\s*$/, "");
//
//     let worker = {
//       id,
//       company,
//       firstName,
//       lastName,
//       phoneNumber,
//       type,
//       jobRole: jobsString,
//     };
//
//     acc.push(worker);
//     return acc;
//   }, []);
// }

export const generateWorkersRoleData = (allWorkers, allJobRoles) => {
  // console.log(allWorkers);
  return Object.values(allWorkers).reduce((acc, key) => {
    // console.log(key);
    if (key) {
      let {
        id,
        company,
        companyEmployeeId,
        firstName,
        lastName,
        mobilePhoneNumber: phoneNumber,
        type,
        typeText,
        joiningDate,
        location,
        managerName,
        jobIds: jobs,
        experienceLevel,
        reliabilityScore,
        skills,
        skillInterests,
        otherSkills,
        workerJobs,
      } = key;

      // Setting Job Roles column
      let jobsString = "";
      let colString = "";
      jobs = jobs && jobs.split(",");
      jobs &&
        jobs.forEach((job) => {
          let jobName = allJobRoles[job]?.name ?? "";
          let jobColor = allJobRoles[job]?.hexColor ?? "";
          jobsString += `${jobName}, `;
          colString += `${jobColor}, `;
        });

      // Removing trailing commas
      jobsString = jobsString.replace(/,\s*$/, "");
      colString = colString.replace(/,\s*$/, "");

      let worker = {
        id,
        company,
        firstName,
        lastName,
        phoneNumber,
        type,
        typeText,
        companyEmployeeId,
        joiningDate,
        location,
        managerName,
        jobIds: jobs,
        jobRole: { jobsString, colString },
        experienceLevel,
        reliabilityScore,
        skills,
        skillInterests,
        otherSkills,
        workerJobs,
        //    jobColor: colString
      };

      acc.push(worker);
    }
    return acc;
  }, []);
};

// export const filterJobRoles = (filters,shiftsData) => {
//
//       const filterCriteria = Object.keys(filters).reduce((acc, key) => {
//         if (filters[key] === true) acc.push(Number.parseInt(key));
//
//         return acc;
//       }, []);
//
//       // console.log(filterCriteria);
//
//       // let filteredShiftData;
//       let filteredShiftData = [];
//
//       shiftsData.forEach((shift) => {
//         if (filterCriteria.includes(shift.jobId)) {
//           filteredShiftData.push(shift);
//         }
//       });
//
//       return filteredShiftData;
// }

export const getColor = (arr1, arr2) => {
  let res = [];
  for (let i = 0; i < arr1.length; i++) {
    let flag = 0;
    for (let j = 0; j < arr2.length; j++) {
      if (arr1[i].code === arr2[j].hexColor) {
        flag = 1;
        break;
      }
      //  console.log("res",res,arr1[i].code,arr2[j].hexColor)
    }
    if (flag === 0) {
      res.push(arr1[i]);
    }
  }
  return res;
};

// convert MM - Mon
// export const formatDate = d => {
//   let day = d.toString().split("-")
//   let months = {"01":"Jan","02":"Feb","03":"Mar","04":"Apr","05":"May","06":"Jun","07":"Jul","08":"Aug","09":"Sep","10":"Oct","11":"Nov","12":"Dec"}
//   let a = Object.keys(months).find(key => key === day[1] )
//   return `ab ${months[a]} ${day[2]} ${day[0]}`
// }

export const isTooDark = (color) => {
  let r, g, b, hsp;
  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If HEX --> store the red, green, blue values in separate variables
    color = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    );

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If RGB --> Convert it to HEX
    color = +("0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&"));

    r = color >> 16;
    // eslint-disable-next-line no-mixed-operators
    g = (color >> 8) & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  return hsp < 139.5;
};

export const addId = (arr) =>
  arr.map((obj, index) => ({ ...obj, idx: index + 1 }));

export const findOtherSkills = (allWorkers, skillsWithjob) => {
  const workers = allWorkers.map((worker) =>
    worker.skills.map((skill) => skill.skillId)
  );
  const skills = skillsWithjob.map((worker) =>
    worker.map((skill) => skill.skillId)
  );

  const uncommonSkillId = workers.map((first, idx) => {
    const res = [];
    const second = skills[idx];
    for (let i = 0; i < first.length; i++) {
      if (second.indexOf(first[i]) === -1) {
        res.push(first[i]);
      }
    }
    for (let j = 0; j < second.length; j++) {
      if (first.indexOf(second[j]) === -1) {
        res.push(second[j]);
      }
    }
    return res;
  });

  return allWorkers.map((worker, idx) => {
    const temp = uncommonSkillId[idx].map((skill) => {
      const f = [];
      worker.skills.forEach((s) => {
        if (s.skillId === skill) f.push(s);
      });
      return f;
    });
    return { ...worker, otherSkills: temp };
  });
};

export const mapWorkerSkillsByJob = (allWorkers, allJobs) => {
  return allWorkers.map((worker) => {
    let t = [];
    let c = [];
    if (worker.jobIds !== null) {
      c = worker.jobIds.map((job) => {
        let t1 = [];
        allJobs.forEach((jobrole) => {
          if (parseInt(job) === jobrole.id) {
            worker.skills.forEach((ws) => {
              jobrole.skills.forEach((js) => {
                if (ws.skillId === js.skillId) {
                  t1.push(ws);
                  //sj.push(ws)
                }
              });
            });
          }
        });
        return t1;
      });
    }
    t.push(c);
    return t;
  });
};

export const generateSearchString = (workersList) => {
  return workersList.map((worker) => {
    // split and join because if fn or ln has string with space, the code may misbehave
    const name =
      worker.firstName.split(" ").join("") +
      worker.lastName.split(" ").join("");
    const typeText = worker.typeText;
    const jobs = worker.jobRole.jobsString.replace(/[, ]+/g, "").trim();
    const finalString = (name + typeText + jobs).toLowerCase();
    return { ...worker, searchString: finalString };
  });
};

export const filterWorkersBySkillLevel = (data, filters, skillIds) => {
  const getFilter = (type) => {
    let returnValue = {
      type: type,
    };
    returnValue[`${type}s`] = [];
    filters.forEach((filter) => {
      if (type === filter.type) {
        returnValue = filter;
      }
    });
    return returnValue;
  };
  return data.filter((w) => {
    //filter by skill level object
    let skillObj;
    skillObj = getFilter("skill").skills.reduce((acc, val) => {
      acc[val.id] = val;
      return acc;
    }, {});
    w.skillsByFilter = w.skills.filter((s) => {
      return skillIds.includes(s.skillId);
    });
    w.filterByLevel = w.skillsByFilter.filter((sk) => {
      return (
        skillObj[sk.skillId] &&
        skillObj[sk.skillId].levelIds.includes(sk.levelId)
      );
    });
    return w.filterByLevel.length;
  });
};

export const formatWorkersBySkillColumn = (data, jobIdsSkillsArr) => {
  return data.map((worker) => {
    const obj = worker.skills.reduce((acc, val) => {
      acc[val.skillId] = val;
      return acc;
    }, {});
    //condition for otherskills
    worker?.otherSkills.forEach((sk) => {
      obj[sk.skillId] = {
        ...sk,
        isOtherSkill: true,
        skillAssigned: true,
      };
    });
    jobIdsSkillsArr.forEach((sk) => {
      worker[sk.skillId] = {
        ...obj[sk.skillId],
        isInterest: false,
      };
    });
    worker.skillInterests?.forEach((skill) => {
      worker[skill.skillId] = {
        ...skill,
        isInterest: true,
      };
    });
    return worker;
  });
};

export const formatSkillsByJobs = (
  allJobs,
  jobIdsSkillsArr,
  jobIds,
  filteredSkills = [],
  skillPrioritiesIds = []
) => {
  allJobs.forEach((job) => {
    const skills = job.skills;
    const sortedSkills =
      [...skills].sort((a, b) => b.priority.multiplier - a.priority.multiplier) ??
      [];
    if (jobIds.includes(job.id)) {
      sortedSkills.forEach((sk) => {
        try {
          if (filteredSkills.length > 0) {
            filteredSkills.forEach((filteredSkill) => {
              const filteredSkillId = filteredSkill.skillId ?? filteredSkill.id;
              const jobSkillId = sk.skillId ?? sk.id;
              if (parseInt(filteredSkillId) === parseInt(jobSkillId)) {
                jobIdsSkillsArr.push({
                  ...sk,
                  jobName: job.name,
                  jobColor: job.hexColor,
                  jobId: job.id,
                });
              }
            });
          } else {
            if (skillPrioritiesIds.length > 0) {
              if (skillPrioritiesIds.includes(sk.priority.id)) {
                jobIdsSkillsArr.push({
                  ...sk,
                  jobName: job.name,
                  jobColor: job.hexColor,
                  jobId: job.id,
                });
              }
            } else {
              jobIdsSkillsArr.push({
                ...sk,
                jobName: job.name,
                jobColor: job.hexColor,
                jobId: job.id,
              });
            }
          }
        } catch (e) {
          jobIdsSkillsArr.push({
            ...sk,
            jobName: job.name,
            jobColor: job.hexColor,
            jobId: job.id,
          });
        }
      });
    }
  });
};

export const deCamelCase = (text) => {
  try {
    const result = text.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
  } catch (e) {
    return text;
  }
};

export const getTimeForTimePicker = (newValue) => {
  try {
    let UTCDateString = JSON.stringify(newValue);
    UTCDateString = UTCDateString.slice(1, UTCDateString.length - 1);
    const newLocalDate = new Date(UTCDateString);
    let hours = `${newLocalDate.getHours()}`;
    let minutes = `${newLocalDate.getMinutes()}`;
    let seconds = `${newLocalDate.getSeconds()}`;
    if (hours.length === 1) {
      hours = `0${hours}`;
    }
    if (minutes.length === 1) {
      minutes = `0${minutes}`;
    }
    if (seconds.length === 1) {
      seconds = `0${seconds}`;
    }
    return `${hours}:${minutes}:${seconds}`;
  } catch (e) {
    // Do nothing
    return "08:00";
  }
};

export default function isValidPhoneNumberForCountry(
  phoneNumberString,
  country
) {
  try {
    if (phoneNumberString.length > 1) {
      const phoneNumber = parsePhoneNumber(phoneNumberString, {
        defaultCountry: country,
        // Demand that the entire input string must be a phone number.
        // Otherwise, it would "extract" a phone number from an input string.
        extract: false,
      });
      if (!phoneNumber) {
        return false;
      }
      if (phoneNumber.country !== country) {
        return false;
      }
      return phoneNumber.isValid();
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
}

export const mergeObjects = (obj1, obj2) => {
  const merged = { ...obj1 };
  // Loop through the keys of the second object
  for (const key in obj2) {
    if (Object.prototype.hasOwnProperty.call(obj2, key)) {
      if (Object.prototype.hasOwnProperty.call(merged, key)) {
        merged[key] = obj2[key];
      } else {
        merged[key] = obj2[key];
      }
    }
  }

  return merged;
};

export const removeDuplicatesById = (arr) => {
  const uniqueObjects = {};
  const result = [];

  for (const obj of arr) {
    const id = obj.id;

    if (!uniqueObjects[id]) {
      uniqueObjects[id] = true;
      result.push(obj);
    }
  }

  return result;
};

export const generateUsername = (firstName, lastName) => {
  try {
    if (firstName && lastName) {
      // If both first and last names are available, return the first letters of both names
      return (
        firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase()
      );
    } else if (firstName) {
      // If only the first name is available, return the first two letters of the first name
      return firstName.slice(0, 2);
    }
  } catch {
    return "";
  }
};

export const getRepType = (code) => {
  try {
    const codeArr = code.split("-");
    let returnValue = DONUT;
    if (codeArr.length > 0) {
      switch (codeArr[0]) {
        case CIRCLE:
          returnValue = CIRCLE;
          break;
        case BOX:
          returnValue = BOX;
          break;
        case ALPHABET:
          returnValue = ALPHABET;
          break;
        case NUMBER:
          returnValue = NUMBER;
          break;
        default:
          break;
      }
      return returnValue;
    } else {
      return DONUT;
    }
  } catch (e) {
    return DONUT;
  }
};
export const getRepLevel = (code, level = 1) => {
  try {
    let checkLevel = level;
    let returnValue = EMPTY;
    if (code) {
      const codeArr = code.split("-");
      if (codeArr.length > 2) {
        // To handle three-quarter string
        checkLevel = `${codeArr[1]}-${codeArr[2]}`;
      } else if (codeArr.length > 0) {
        checkLevel = codeArr[1];
      }
    }
    switch (checkLevel) {
      case 1:
      case QUARTER:
        returnValue = QUARTER;
        break;
      case 2:
      case HALF:
        returnValue = HALF;
        break;
      case 3:
      case THREE_QUARTER:
        returnValue = THREE_QUARTER;
        break;
      case 4:
      case FULL:
        returnValue = FULL;
        break;
      default:
        break;
    }
    return returnValue;
  } catch (e) {
    return EMPTY;
  }
};

export const getLowestLevelObject = (allSkillLevels) => {
  let returnValue = {
    levelId: 1,
    level: 0,
    levelName: "No Training",
    skillAssigned: false,
    backColorCode: null,
    desc: null,
    foreColorCode: null,
    icon: "",
    repName: "",
    repCode: "",
    value: "",
  };
  if (allSkillLevels) {
    if (allSkillLevels.length !== 0) {
      let tempAllSkillLevels = [...allSkillLevels];
      if (tempAllSkillLevels) {
        returnValue = sortBy(tempAllSkillLevels, (sLevel) => sLevel.level)[0];
      }
    }
  }
  return returnValue;
};

export const getLevelObj = (value = "0", allSkillLevels = []) => {
  // Based on skill levels provided return info about level based on level value
  let returnValue = {
    repType: DONUT,
    repLevel: EMPTY,
    levelValue: value,
    backgroundColor: "",
    foregroundColor: "",
    name: "",
  };
  const level = allSkillLevels.find(
    (sLevel) => parseInt(sLevel.level) === parseInt(value)
  );
  if (level) {
    returnValue.repType = getRepType(level.repCode);
    returnValue.repLevel = getRepLevel(level.repCode, level.level);
    returnValue.levelValue = level.value;
    returnValue.backgroundColor = level.backColorCode;
    returnValue.foregroundColor = level.foreColorCode;
    returnValue.name = level.name;
  }
  return returnValue;
};

// get locations from workcenters
export const getLocationWorkcenters = (workcenters) => {
  const locObj = {};
  workcenters.forEach((wc) => {
    if (locObj[wc.location.id]) {
      locObj[wc.location.id].push({
        ...wc,
        locationName: wc.location.name,
        locationId: wc.location.id,
      });
    } else {
      locObj[wc.location.id] = [
        { ...wc, locationName: wc.location.name, locationId: wc.location.id },
      ];
    }
  });
  const result = [];
  Object.values(locObj).forEach((loc) => loc.forEach((wc) => result.push(wc)));
  return result;
};

export const getUpdatedReportingOptions = (filteredReportingOptions, id) => {
  let newFilteredReportingOptions = [];
  let idsToShow = [];
  let idsToHide = [];
  let expanded = false;
  const selectedOrganization = filteredReportingOptions.find(
    (x) => x.id === id
  );
  if (selectedOrganization) {
    expanded = !selectedOrganization.expanded;
    if (expanded) {
      idsToShow.push(id);
    } else {
      idsToHide.push(id);
    }
  }
  if (idsToShow.length > 0 && filteredReportingOptions) {
    filteredReportingOptions.forEach((filteredReportingOption) => {
      let newFilteredReportingOption = { ...filteredReportingOption };
      if (
        newFilteredReportingOption.id === id &&
        idsToShow.includes(newFilteredReportingOption.id)
      ) {
        newFilteredReportingOption.expanded = true;
        //newFilteredReportingOption.selected = true;
        if (newFilteredReportingOption.children) {
          newFilteredReportingOption.children.forEach((child) => {
            idsToShow.push(child.id);
          });
        }
      } else if (idsToShow.includes(newFilteredReportingOption.id)) {
        newFilteredReportingOption.visible = true;
      }
      newFilteredReportingOptions.push(newFilteredReportingOption);
    });
  } else if (idsToHide.length > 0) {
    filteredReportingOptions.forEach((filteredReportingOption) => {
      let newFilteredReportingOption = { ...filteredReportingOption };
      if (
        newFilteredReportingOption.id === id &&
        idsToHide.includes(newFilteredReportingOption.id)
      ) {
        newFilteredReportingOption.expanded = false;
        //newFilteredReportingOption.selected = false;
        if (newFilteredReportingOption.children) {
          newFilteredReportingOption.children.forEach((child) => {
            idsToHide.push(child.id);
            // Level 2 hierarchy
            if (child.children) {
              child.children.forEach((level2Child) => {
                idsToHide.push(level2Child.id);
                // Level 3 hierarchy
                if (level2Child.children) {
                  level2Child.children.forEach((level3Child) => {
                    idsToHide.push(level3Child.id);
                    // Level 4 hierarchy
                    if (level3Child.children) {
                      level3Child.children.forEach((level4Child) => {
                        idsToHide.push(level4Child.id);
                        // Level 5 hierarchy
                        if (level4Child.children) {
                          level4Child.children.forEach((level5Child) => {
                            idsToHide.push(level5Child.id);
                          });
                        }
                      });
                    }
                  });
                }
              });
            }
          });
        }
      } else if (idsToHide.includes(newFilteredReportingOption.id)) {
        newFilteredReportingOption.expanded = false;
        newFilteredReportingOption.visible = false;
        //newFilteredReportingOption.selected = false;
      }
      newFilteredReportingOptions.push(newFilteredReportingOption);
    });
  }
  return newFilteredReportingOptions;
};

export const useIsComponentMounted = () => {
  const isComponentMounted = useRef(false);

  useEffect(() => {
    isComponentMounted.current = true;
    return () => {
      isComponentMounted.current = false;
    };
  }, []);

  return isComponentMounted;
};
