// TODO - Type 'any' needs to be fixed.
/* eslint-disable simple-import-sort/imports */
import {
	Box,
	Button,
	CircularProgress,
	Tooltip,
	Typography,
} from "@mui/material";
import React from "react";
import { generateSkillString, truncateFileName } from "../../helper";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import { useDispatch, useSelector } from "react-redux";
import { useConfirm } from "material-ui-confirm";
import { format, isFuture } from "date-fns";
import useWorkersData from "../../../../../../ts/components/workers/controllers/use-workers-data";
import {
	deleteWorkerCertifications,
	downloadWorkerCertification,
	openEditWorkerCertificationDialog,
} from "../../../../../../export/workers";
import CircularProgressWithLabel from "../../../../../design/circular-progress-label";
import Highlight from "../../../../Highlight";
import SkillColumn from "../ui/table/skill-column";

interface GridColumn {
	field: string;
	headerName?: string;
	// minWidth: number | string;
	renderHeader?: (params: any) => JSX.Element;
	renderCell?: (params: any) => JSX.Element;
	sortable?: boolean;
	disableColumnMenu?: boolean;
	cellClassName?: string;
	headerClassName?: string;
	minWidth?: number;
	flex?: number;
	valueGetter?: (params: any) => any;
	filterable?: boolean;
}

const deleteContent = ({ certName }: { certName: string }) => (
	<Box>
		<Typography
			sx={{
				fontFamily: "Roboto",
				fontSize: "16px",
				fontWeight: 400,
				lineHeight: "28px",
			}}
		>
			{`Are you sure you want to revoke the Certification "${certName}" for the Worker?`}
		</Typography>
	</Box>
);

const useWorkerCertificationColumn = (searchText: string) => {
	const confirm = useConfirm();
	const dispatch = useDispatch();
	const { allSkills, downloadProgress } = useWorkersData();

	const handleDelete = (workerCertId: string, certName: string) => {
		if (!workerCertId) return;
		confirm({
			title: "Revoke Certification for Worker",
			content: deleteContent({ certName: certName }),
			cancellationText: "Cancel",
			confirmationText: (
				<Button variant='contained' color='error'>
					Revoke
				</Button>
			),
		})
			.then(() =>
				dispatch(
					deleteWorkerCertifications({
						workerCertId: workerCertId,
					})
				)
			)
			.catch(() => console.log("Deletion cancelled."));
	};

	const columns: GridColumn[] = [
		{
			field: "certificationName",
			headerName: "Certification Name",
			minWidth: 185,
			flex: 1,
			renderHeader: () => {
				return (
					<Box>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 500,
								fontSize: "14px",
								color: "#000000",
								letterSpacing: "0.17px",
							}}
						>
							{"Certification Name"}
						</Typography>
					</Box>
				);
			},
			renderCell: (params: any) => {
				return (
					<Box mt={1} mb={1}>
						<Highlight value={params.row.certName} searchText={searchText} />
					</Box>
				);
			},
			valueGetter: (params: any) => {
				return params.row.certName;
			},
		},
		{
			field: "status",
			minWidth: 130,
			flex: 1,
			filterable: false,
			disableColumnMenu: true,
			renderHeader: (params: any) => {
				return (
					<Box>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 500,
								fontSize: "14px",
								color: "#000000",
								letterSpacing: "0.17px",
							}}
						>
							Status
						</Typography>
					</Box>
				);
			},
			renderCell: (params: any) => {
				// console.log(params.row);
				const validFrom = params.row.validFrom;
				const validUntil = params.row.validTo;

				if (!validFrom || isFuture(new Date(validFrom))) return <></>;
				if (!validUntil || isFuture(new Date(validUntil)))
					return (
						<Box>
							<Highlight searchText={searchText} value='Active' />
						</Box>
					);

				return (
					<Box>
						<Highlight
							searchText={searchText}
							value='Expired'
							color='#E44B48'
						/>
					</Box>
				);
			},
			valueGetter: (params: any) => {
				const validFrom = params.row.validFrom;
				const validUntil = params.row.validTo;

				if (!validFrom || isFuture(new Date(validFrom))) return "";
				if (!validUntil || isFuture(new Date(validUntil))) return "Active";
				return "Expired";
			},
		},
		{
			field: "associatedSkills",
			minWidth: 300,
			flex: 1,
			filterable: false,
			disableColumnMenu: true,
			renderHeader: (params: any) => {
				return (
					<Box>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 500,
								fontSize: "14px",
								color: "#000000",
								letterSpacing: "0.17px",
							}}
						>
							Worker’s Skills
						</Typography>
					</Box>
				);
			},
			renderCell: (params: any) => {
				if (!params.row.skillIds) return <>-</>;
				const skillIds = params.row.skillIds
					?.split(",")
					?.map((id: string) => parseInt(id));
				const skills = allSkills.filter((skill: { id: number }) =>
					skillIds.includes(skill.id)
				);
				// const { skillString, remainig, seeMore } = generateSkillString(skills);
				// const skillsText = skillString + remainig;

				if (skills.length === 0) return <Box>-</Box>;
				return (
					<Box width={260} display='flex'>
						<SkillColumn skills={skills} searchText={searchText} />
					</Box>
				);
			},
			valueGetter: (params: any) => {
				return params.row.skillIds?.split(",")?.length;
			},
		},
		{
			field: "validFrom",
			minWidth: 130,
			flex: 1,
			filterable: false,
			disableColumnMenu: true,
			renderHeader: (params: any) => {
				return (
					<Box>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 500,
								fontSize: "14px",
								color: "#000000",
								letterSpacing: "0.17px",
							}}
						>
							Valid From
						</Typography>
					</Box>
				);
			},
			renderCell: (params: any) => {
				if (!params.row.validFrom) return <>-</>;
				const datetime = params.row.validFrom + "T00:00:00";
				const validFromDate = format(new Date(datetime), "MM/dd/yyyy");

				return (
					<Box>
						<Highlight searchText={searchText} value={validFromDate} />
					</Box>
				);
			},
			valueGetter: (params: any) => {
				return params.row.validFrom;
			},
		},
		{
			field: "validUntil",
			minWidth: 130,
			flex: 1,
			filterable: false,
			disableColumnMenu: true,
			renderHeader: (params: any) => {
				return (
					<Box>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 500,
								fontSize: "14px",
								color: "#000000",
								letterSpacing: "0.17px",
							}}
						>
							Valid Until
						</Typography>
					</Box>
				);
			},
			renderCell: (params: any) => {
				if (!params.row.validTo) return <>-</>;

				const datetime = params.row.validTo + "T00:00:00";
				const validToDate = format(new Date(datetime), "MM/dd/yyyy");

				return (
					<Box>
						<Highlight searchText={searchText} value={validToDate} />
					</Box>
				);
			},
			valueGetter: (params: any) => {
				return params.row.validTo;
			},
		},
		{
			field: "attachments",
			minWidth: 195,
			flex: 1,
			sortable: false,
			filterable: false,
			disableColumnMenu: true,
			renderHeader: (params: any) => {
				return (
					<Box>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 500,
								fontSize: "14px",
								color: "#000000",
								letterSpacing: "0.17px",
							}}
						>
							Attachments
						</Typography>
					</Box>
				);
			},
			renderCell: (params: any) => {
				const workerCertFiles = params.row.workerCertFiles;
				return (
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: 1,
						}}
						mt={1}
						mb={1}
					>
						{workerCertFiles
							? workerCertFiles?.map((file: any) => {
									return (
										<Box
											key={file.workerCertFileId}
											sx={{
												display: "flex",
												gap: 1,
												alignItems: "center",
											}}
										>
											<Tooltip title={file.fileName} placement='bottom-start'>
												<Typography
													sx={{
														fontFamily: "Roboto",
														fontWeight: 400,
														fontSize: "14px",
														lineHeight: "18px",
														color: "#2F4D8B",
														letterSpacing: "0.17px",
														cursor: "pointer",
														textDecoration: "underline",
														// textOverflow: "ellipsis",
														// maxWidth: "180px",
														// whiteSpace: "nowrap",
														// overflow: "hidden",
													}}
													onClick={(e) => {
														e.preventDefault();
														dispatch(
															downloadWorkerCertification({
																workerCertFileId: file.workerCertFileId,
																fileName: file.fileName,
															})
														);
													}}
												>
													{truncateFileName(file.fileName, 15)}
												</Typography>
											</Tooltip>
											{downloadProgress[file.workerCertFileId] !==
												undefined && (
												<CircularProgress
													value={downloadProgress[file.workerCertFileId]}
													size={10}
													variant='determinate'
												/>
											)}
										</Box>
									);
									// eslint-disable-next-line no-mixed-spaces-and-tabs
							  })
							: "-"}
					</Box>
				);
			},
		},
		{
			field: "actions",
			minWidth: 80,
			sortable: false,
			filterable: false,
			disableColumnMenu: true,
			cellClassName: "hover-column-cell",
			headerClassName: "hover-column-header",
			renderHeader: (params: any) => <></>,
			renderCell: (params: any) => {
				return (
					<Box
						className='hover-content'
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							minWidth: "80px",
							padding: "12px 16px 8px 16px",
							backgroundColor: "#FFF",
							borderRadius: "4px",
						}}
					>
						<Box
							sx={{
								color: "#6c6c6c",
								"&:hover": {
									color: "#000000",
									transform: "scale(1.2)",
								},
								cursor: "pointer",
							}}
						>
							<Tooltip title='Edit'>
								<EditIcon
									id={`edit-job-${params?.row?.id}-enabled`}
									fontSize='small'
									onClick={(e) => {
										dispatch(openEditWorkerCertificationDialog(params.row));
										e.stopPropagation();
									}}
								/>
							</Tooltip>
						</Box>
						<Box
							sx={{
								color: "#6c6c6c",
								"&:hover": {
									color: "red",
									transform: "scale(1.2)",
								},
								cursor: "pointer",
							}}
						>
							<Tooltip title='Delete'>
								<DeleteIcon
									id={`delete-job-${params?.row?.id}-enabled`}
									sx={{
										marginLeft: "8px",
									}}
									fontSize='small'
									onClick={(e) => {
										handleDelete(params.row.workerCertId, params.row.certName);
										e.stopPropagation();
									}}
								/>
							</Tooltip>
						</Box>
					</Box>
				);
			},
		},
	];

	return columns;
};

export default useWorkerCertificationColumn;
