import { Box, Button, Chip, Divider, Tooltip, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";

import colors from "../../config/colors";
import { AppliedFilter, FilterContext, FilterData } from "./filter-context";

interface FilterGroup {
  type: string;
  filters: AppliedFilter[];
}

const AppliedFilters: React.FC = () => {
  const filterContext = useContext(FilterContext);
  if (!filterContext) {
    throw new Error("FilterAccordion must be used within a FilterProvider");
  }
  const { appliedFilters, setAppliedFilters, open } = filterContext;

  const filterChips = appliedFilters.reduce((acc: FilterGroup[], item) => {
    let group: FilterGroup | undefined = acc.find((g) => g.type === item.type);
    if (!group) {
      group = { type: item.type, filters: [] };
      if (group) {
        acc.push(group);
        group.filters.push(item);
      }
    }
    return acc;
  }, []);

  const resetFilters = (type: string) => {
    setAppliedFilters((prevFilters) => {
      return prevFilters.filter((filter) => filter.type !== type);
    });
  };

  const getTooltipTextForAppliedFilter = (
    filterArray: FilterData[] = [],
    type = ""
  ) => {
    if (filterArray.length > 2) {
      return (
        <>
          {filterArray.map((filter, index) => (
            <Typography
              key={`tooltip-${type}-${index}`}
              sx={{
                fontFamily: "Roboto",
                fontSize: "12px",
                fontWeight: 500,
                color: "#FFFFFF",
              }}
              display="block"
            >
              {filter.name}
            </Typography>
          ))}
        </>
      );
    }
    return null;
  };

  return (
    <Box
      sx={{
        "@media (max-width: 1312px)": {
          width: "calc(100vw - 112px)",
        },
        "@media (min-width: 1312px)": {
          width: open ? "calc(100vw - 408px)" : "calc(100vw - 112px)",
        },
      }}
    >
      <Box
        sx={{
          minHeight: "50px",
          padding: "8px 16px",
          height: 57,
          flexWrap: "no-wrap",
          display: "flex",
          alignItems: "center",
            justifyContent: "space-between",
          flexDirection: "row",
          width: "100%",
        }}
      >
        {appliedFilters.length > 0 && [
          <Box
            key={"applied-filters"}
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              alignItems: "center",
              justifyContent: "flex-start",
              overflowX: "scroll",
              /* Hide scrollbar for Chrome, Safari and Opera */
              "&::-webkit-scrollbar": {
                display: "none",
              },
              /* Hide scrollbar for IE, Edge and Firefox */
              msOverflowStyle: "none" /* IE and Edge */,
              scrollbarWidth: "none" /* Firefox */,
            }}
          >
            {filterChips.map((chipFilterGroup: FilterGroup, index) => (
              <Box
                key={`filter-group-${index}`}
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                sx={{
                  flexWrap: "wrap",
                }}
              >
                {chipFilterGroup.filters.map((filter: AppliedFilter) => (
                  <Chip
                    key={`applied-filter-${index}`}
                    style={{ margin: 4 }}
                    onDelete={() => {
                      resetFilters(filter.type);
                    }}
                    label={
                      <Tooltip
                        title={getTooltipTextForAppliedFilter(
                          filter.data,
                          filter.type
                        )}
                        placement="bottom"
                        arrow
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "nowrap",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {filter.data.length === 1 && (
                            <Typography
                              key={filter.data[0].id}
                              sx={{
                                fontFamily: "Roboto",
                                fontSize: "13px",
                                color: colors.title,
                                fontWeight: 400,
                                marginLeft: "4px",
                              }}
                            >
                              {`${filter.type} : ${filter.data[0].name}`}
                            </Typography>
                          )}
                          {filter.data.length === 2 && (
                            <Typography
                              key={filter.data[0].id}
                              sx={{
                                fontFamily: "Roboto",
                                fontSize: "13px",
                                color: colors.title,
                                fontWeight: 400,
                                marginLeft: "4px",
                              }}
                            >
                              {`${filter.type} : ${filter.data[0].name}, ${filter.data[1].name}`}
                            </Typography>
                          )}
                          {filter.data.length > 2 && [
                            <Typography
                              key={filter.data[0].id}
                              sx={{
                                fontFamily: "Roboto",
                                fontSize: "13px",
                                color: colors.title,
                                fontWeight: 400,
                                marginLeft: "4px",
                              }}
                            >
                              {`${filter.type} : `}
                            </Typography>,
                            <Typography
                              key={`total-${filter.data
                                .map((data) => data.id)
                                .join("-")}`}
                              sx={{
                                fontFamily: "Roboto",
                                fontSize: "15px",
                                color: colors.title,
                                fontWeight: 500,
                                marginLeft: "4px",
                              }}
                            >
                              {`${filter.data.length}`}
                            </Typography>,
                          ]}
                          <Typography
                            sx={{
                              fontFamily: "Roboto",
                              fontSize: "13px",
                              color: colors.title,
                              fontWeight: 400,
                            }}
                          ></Typography>
                        </Box>
                      </Tooltip>
                    }
                  />
                ))}
              </Box>
            ))}
          </Box>,
          <Button
            key={"clear-filter-button"}
            sx={{
              textTransform: "none",
              fontFamily: "Roboto",
              fontSize: "14px",
              color: "rgba(0,0,0,0.56)",
              whiteSpace: "nowrap",
              minWidth: "fit-content",
              flexShrink: 0,
              marginRight: "-8px",
              marginLeft: "8px",
            }}
            onClick={() => {
              setAppliedFilters([]);
            }}
          >
            Clear
          </Button>,
        ]}
        {appliedFilters.length === 0 && (
          <Typography
            key={"no-filters-applied"}
            sx={{
              fontFamily: "Roboto",
              fontSize: "12px",
              color: colors.gray,
              marginLeft: "8px",
            }}
          >
            No filters applied
          </Typography>
        )}
      </Box>
      <Divider />
    </Box>
  );
};

export default AppliedFilters;
