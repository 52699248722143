import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React, { useState } from "react";

interface ISectionWrapperProps {
    title: string
    subtitle: string | undefined
    children: any
}
const SectionWrapper: React.FC<ISectionWrapperProps> = ({ title, subtitle, children }) => {
    const [expanded, setExpanded] = useState(true);
    return (
        <Box
            mt={4}
            ml={8}
            mr={8}
            mb={4}
            sx={{
                padding: "20px",
                border: "1px solid rgba(0, 0, 0, 0.12)",
                borderRadius: "15px",
                minWidth: "1004px",
                maxWidth: "1152px",
            }}
        >
            <Box
                onClick={() => setExpanded((prevState) => !prevState)}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    cursor: "pointer",
                }}
            >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                        sx={{
                            fontFamily: "Roboto",
                            fontWeight: 400,
                            fontSize: "16px",
                            color: "rgba(0, 0, 0, 0.87)",
                        }}
                    >
                        {title}
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: "Roboto",
                            fontWeight: 400,
                            fontSize: "16px",
                            color: "rgba(0, 0, 0, 0.6)",
                        }}
                    >
                        {subtitle}
                    </Typography>
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    {expanded ? <ExpandLess /> : <ExpandMore />}
                </Box>
            </Box>
            {expanded && children}
        </Box>
    );
};

export default SectionWrapper;
