import {
	ALPHABET,
	BOX,
	CIRCLE,
	DONUT,
	EMPTY,
	FULL,
	HALF,
	NUMBER,
	QUARTER,
	THREE_QUARTER,
} from "../../design/skill-representaion-cell/constants/constants";

interface SkillLevel {
	id: number;
	level: number;
	repLevel: string;
	repCode: string;
	backColorCode: string;
	foreColorCode: string;
	value: string | number;
	icon: string | number;
	name: string;
	desc: string;
	toBeDeleted: boolean;
	existing: boolean;
}

export const getRepType = (code: string) => {
	try {
		const codeArr = code.split("-");
		let returnValue = DONUT;
		if (codeArr.length > 0) {
			switch (codeArr[0]) {
				case CIRCLE:
					returnValue = CIRCLE;
					break;
				case BOX:
					returnValue = BOX;
					break;
				case ALPHABET:
					returnValue = ALPHABET;
					break;
				case NUMBER:
					returnValue = NUMBER;
					break;
				default:
					break;
			}
			return returnValue;
		} else {
			return DONUT;
		}
	} catch (e) {
		return DONUT;
	}
};
export const getRepLevel = (code: string, level = 1) => {
	try {
		let checkLevel: string | number = level;
		let returnValue = EMPTY;
		if (code) {
			const codeArr = code.split("-");
			if (codeArr.length > 2) {
				// To handle three-quarter string
				checkLevel = `${codeArr[1]}-${codeArr[2]}`;
			} else if (codeArr.length > 0) {
				checkLevel = codeArr[1];
			}
		}
		switch (checkLevel) {
			case 1:
			case QUARTER:
				returnValue = QUARTER;
				break;
			case 2:
			case HALF:
				returnValue = HALF;
				break;
			case 3:
			case THREE_QUARTER:
				returnValue = THREE_QUARTER;
				break;
			case 4:
			case FULL:
				returnValue = FULL;
				break;
			default:
				break;
		}
		return returnValue;
	} catch (e) {
		return EMPTY;
	}
};

export const getLevelObj = (value = "0", allSkillLevels: SkillLevel[] = []) => {
	// Based on skill levels provided return info about level based on level value
	const returnValue = {
		repType: DONUT,
		repLevel: EMPTY,
		levelValue: value,
		backgroundColor: "",
		foregroundColor: "",
		name: "",
	};

	const level = allSkillLevels.find(
		(sLevel) => sLevel.level === parseInt(value)
	);

	if (level) {
		returnValue.repType = getRepType(level.repCode);
		returnValue.repLevel = getRepLevel(level.repCode, level.level);
		returnValue.levelValue = String(level.value);
		returnValue.backgroundColor = level.backColorCode;
		returnValue.foregroundColor = level.foreColorCode;
		returnValue.name = level.name;
	}

	return returnValue;
};
