import { Box, Grid, Typography } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import format from "date-fns/format";
import React from "react";

import { BORDER_COLOR, TODAY_TEXT_COLOR } from "../../../constants/colors";
import useScheduleController from "../../../controllers/schedule-controller";

export const AvailabilityHeaderRow = ({
  sortedWorkers,
  changeLocalWorkers,
  datesArrayOfSelectedWeek,
  searchValue,
  setSearchValue,
}) => {
  const [, { isWeekend, isToday }] = useScheduleController();
  const searchWorkers = (text) => {
      setSearchValue(text);
      if (text !== "") {
          let returnValue = [];
          returnValue = sortedWorkers.filter((worker) =>
              (`${worker.firstName} ${worker.lastName}`)
                  .toLowerCase()
                  .includes(text?.trimStart("").toLowerCase())
          ) ?? [];
          if (returnValue !== undefined) {
              changeLocalWorkers(returnValue);
          }
      } else {
          changeLocalWorkers(sortedWorkers);
      }
  };
  const DateHeaderCell = ({ date }) => {
    return (
      <Grid
        borderLeft={1}
        borderColor={BORDER_COLOR}
        item
        xs={1.4}
        sx={{
          minWidth: "123px",
          backgroundColor: isWeekend(date) ? "#FAFAFA" : "#FFFFFF",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
            padding: "4px 8px",
            flexDirection: "column",
            height: "72px",
          }}
        >
          <Typography
            sx={{
              color: "#71717A",
              fontSize: "10px",
              fontFamily: "Roboto",
              fontWeight: 700,
              textTransform: "uppercase",
            }}
          >
            {format(date, "eee")}
          </Typography>
          <Typography
            sx={{
              color: isToday(date) ? TODAY_TEXT_COLOR : "#000000",
              fontSize: "22px",
              fontFamily: "Roboto",
              fontWeight: 500,
            }}
          >
            {format(date, "dd")}
          </Typography>
        </Box>
      </Grid>
    );
  };

  return (
    <Grid
      borderTop={1}
      borderBottom={1}
      borderColor={BORDER_COLOR}
      container
      wrap="nowrap"
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Grid item xs={2.2} sx={{ minWidth: "123px" }}>
        <Box
          sx={{
            height: "72px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#FFFFFF",
          }}
        >
          <InputBase
            sx={{ ml: 3, flex: 1 }}
            placeholder="Search worker"
            inputProps={{ "aria-label": "Search worker" }}
            value={searchValue}
            onChange={(event) => searchWorkers(event.target.value)}
          />
        </Box>
      </Grid>
      {/* Designed MAX UPTO 7 Days */}
      {datesArrayOfSelectedWeek.map((date, index) => (
        <DateHeaderCell key={`${date.getDay()}-${index}`} date={date} />
      ))}
    </Grid>
  );
};
