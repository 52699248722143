import React from "react";

import BoxWrapper from "./ui/box-wrapper";
import ManageFactoriesTabs from "./ui/manage-factories-tabs";

const ManageFactories = () => {
	return (
		<>
			<BoxWrapper
				title='Admin Configuration'
				subtitle='Manage (create,view, update and delete) companies and their configurations all from one place.'
				divider={true}
			>
				<ManageFactoriesTabs />
			</BoxWrapper>
		</>
	);
};

export default ManageFactories;
