import { Add, Remove } from "@mui/icons-material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import {
	Box,
	IconButton,
	InputAdornment,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import React from "react";

interface NumberInputProps {
	value: number;
	onChange: (value: number) => void;
	min?: number;
	max?: number;
	label?: string;
}

const NumberInput: React.FC<NumberInputProps> = ({
	value,
	onChange,
	min,
	max,
	label,
}) => {
	const handleIncrement = () => {
		if (max === undefined || value < max) {
			onChange(value + 1);
		}
	};

	const handleDecrement = () => {
		if (min === undefined || value > min) {
			onChange(value - 1);
		}
	};

	return (
		<TextField
			value={value}
			onChange={(e) => {
				const newValue = parseInt(e.target.value, 10);
				if (!isNaN(newValue)) {
					onChange(newValue);
				}
			}}
			label={label}
			variant='filled'
			InputProps={{
				endAdornment: (
					<Stack direction='row' alignItems='center'>
						<IconButton onClick={handleDecrement} title='Clear'>
							<Remove />
						</IconButton>
						<Typography
							sx={{
								color: "rgba(0,0,0,0.2)",
								fontSize: "24px",
								fontWeight: "200",
							}}
						>
							|
						</Typography>
						<IconButton onClick={handleIncrement} title='Clear'>
							<Add />
						</IconButton>
					</Stack>
				),
			}}
		/>
	);
};

export default NumberInput;
