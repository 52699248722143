// TODO - Type 'any' needs to be fixed.
import Dialog from "@mui/material/Dialog";
import React from "react";

import DialogWrapper from "../../../../../design/dialog-wrapper";
import EditShiftDialogForm from "./edit-shift-dialog-form";

export interface EditShiftDialogProps {
	handleClose: (() => void) | undefined;
	open: boolean;
	// draftShiftDate: Date;
	dispatchLocal: (action: any) => void;
	dispatchLocalSkill: (action: any) => void;
	state: any;
	skillState: any;
}

const EditShiftDialog: React.FC<EditShiftDialogProps> = ({
	handleClose,
	open,
	// draftShiftDate,
	dispatchLocal,
	dispatchLocalSkill,
	state,
	skillState,
}) => {
	return (
		<Dialog
			onClose={(event, reason) => {
				if (reason !== "backdropClick" && handleClose) {
					handleClose();
				}
			}}
			aria-labelledby='add-new-shift'
			open={open}
			fullWidth
			PaperProps={{ sx: { maxWidth: "700px" } }}
		>
			<DialogWrapper
				dialogTitle='Edit shift'
				dialogContent={
					<EditShiftDialogForm
						dispatchLocal={dispatchLocal}
						dispatchLocalSkill={dispatchLocalSkill}
						state={state}
						skillState={skillState}
						handleClose={handleClose}
					/>
				}
			/>
		</Dialog>
	);
};
export default EditShiftDialog;
