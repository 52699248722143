import { LoadingButton } from "@mui/lab";
import { Box, Button, CircularProgress } from "@mui/material";
import React from "react";

import useTeamsData from "../../../controllers/use-teams-data";

interface ActionButtonsProps {
	closeTeamsDialog: () => void;
	isValid: boolean;
	isSubmitting: boolean;
	dirty: boolean;
	status: string;
	teamFormRef: any;
	scheduleEnabledAndEmpty: boolean;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({
	closeTeamsDialog,
	isValid,
	isSubmitting,
	dirty,
	status,
	scheduleEnabledAndEmpty,
	teamFormRef,
}) => {
	const { allShiftSchedules } = useTeamsData();
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "flex-end",
				marginTop: "16px",
				marginBottom: "16px",
				marginRight: "21px",
			}}
		>
			<Button variant='outlined' onClick={closeTeamsDialog}>
				Cancel
			</Button>
			<LoadingButton
				variant='contained'
				disabled={
					!isValid ||
					!dirty ||
					isSubmitting ||
					scheduleEnabledAndEmpty ||
					allShiftSchedules.length === 0
				}
				sx={{ marginLeft: "16px" }}
				loading={status === "pending"}
				type='submit'
				onClick={() => teamFormRef?.current?.handleSubmit()}
				loadingIndicator={
					<CircularProgress size={16} sx={{ color: "rgba(0, 0, 0, 0.38)" }} />
				}
			>
				Save
			</LoadingButton>
		</Box>
	);
};

export default ActionButtons;
