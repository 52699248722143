import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/material";
import { Button, Typography } from "@mui/material";
import React, { ReactNode } from "react";

interface BoxWrapperProps {
	title: string;
	subtitle: string;
	children: ReactNode;
	openDialog: () => void;
	disabled?: boolean;
}

const BoxWrapper: React.FC<BoxWrapperProps> = ({
	title,
	subtitle,
	children,
	openDialog,
	disabled = false,
}) => {
	return (
		<Box
			mt={4}
			ml={disabled ? 0 : 8}
			mr={disabled ? 0 : 8}
			mb={4}
			sx={{
				minWidth: "1000px",
				maxWidth: "1152px",
				border: disabled ? "none" : "1px solid rgba(0, 0, 0, 0.12)",
				borderRadius: disabled ? "0px" : "15px",
			}}
			p={disabled ? 0 : 2.5}
		>
			<Box display='flex' justifyContent='space-between'>
				<Box>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 400,
							fontSize: "16px",
							color: "rgba(0, 0, 0, 0.87)",
						}}
					>
						{title}
					</Typography>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 400,
							fontSize: "16px",
							color: "rgba(0, 0, 0, 0.6)",
						}}
					>
						{subtitle}
					</Typography>
				</Box>
				<Box>
					<Button
						variant='outlined'
						startIcon={<AddIcon />}
						onClick={() => openDialog()}
					>
						{title.slice(0, -1)}
					</Button>
				</Box>
			</Box>
			<Box>{children}</Box>
		</Box>
	);
};

export default BoxWrapper;
