import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import SearchIcon from "@mui/icons-material/Search";
import LoadingButton from "@mui/lab/LoadingButton";
import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	InputAdornment,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import React, { useEffect, useMemo, useState } from "react";

import { AllocationSettings } from "../../../../../store/gat-admin-slice-types";
import LocationPlaceholderBackground from "../../../assets/location_placeholder_background.png";
import NoJobRolesAvailablePlaceholder from "../../../assets/no_job_roles_available_placeholder.png";
import NoJobRolesCreatedPlaceholder from "../../../assets/no_job_roles_created_placeholder.png";

interface JobRole {
	id: number;
	name: string;
	checked: boolean;
  }

type SelectJobsDialogProps = {
	open: boolean;
	handleClose: () => void;
	allJobsByLocation: JobRole[];
	allAssigned: boolean;
	shiftAllocationRules?: any;
	setShiftAllocationRules?: (rules: any) => void;
	allJobsByLocationObject: any;
	id?: number;
	setLocationData: (data: any) => void;
	locationData: any;
};

const SelectJobsDialog = ({
	open,
	handleClose,
	allJobsByLocation,
	allAssigned,
	shiftAllocationRules,
	setShiftAllocationRules,
	allJobsByLocationObject,
	id,
	setLocationData,
	locationData,
}: SelectJobsDialogProps) => {
	const [selectedJobRoles, setSelectedJobRoles] = useState<JobRole[]>([]);
	const [searchTerm, setSearchTerm] = useState("");
	const [selectAll, setSelectAll] = useState(false);
	const [filteredJobRoles, setFilteredJobRoles] = useState<JobRole[]>([]);
	const [jobRoles, setJobRoles] = useState<JobRole[]>([]);
	
	
	useEffect(() => {
		if (allJobsByLocation.length > 0)
			setJobRoles(
				allJobsByLocation.map((job:JobRole) => ({
					...job,
					checked: false,
				}))
			);
	}, [allJobsByLocation]);

	

	const current = useMemo(
		() => shiftAllocationRules.find((rule:{shiftAllocationTypeId:number}) => rule.shiftAllocationTypeId === id),
		[shiftAllocationRules, id]
	);
	const currentJobs = useMemo(
		() => (current?.jobIds ? current.jobIds.split(",") : []),
		[current]
	);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(event.target.value);
	};

	useEffect(() => {
		const newSelectedJobRoles = currentJobs.length
		? currentJobs.map((jobId:number) => allJobsByLocationObject[jobId])
		: [];
	
		jobRoles.forEach((job) => {
		if (job.checked) {
			newSelectedJobRoles.push(job);
		}
		});
	
		if (JSON.stringify(newSelectedJobRoles) !== JSON.stringify(selectedJobRoles)) {
		setSelectedJobRoles(newSelectedJobRoles);
		}
	}, [jobRoles, selectedJobRoles, currentJobs, allJobsByLocationObject]);

	useEffect(() => {
		setFilteredJobRoles(
			searchTerm.trim()
			? jobRoles.filter((job) =>
				job.name.toLowerCase().includes(searchTerm.toLowerCase())
			)
		: jobRoles
	);
	}, [searchTerm, jobRoles]);

	const handleSelectAllChange = () => {
		setSelectAll((prevState) => !prevState);
		setJobRoles((prevJobRoles) =>
			prevJobRoles.map((job) => ({ ...job, checked: !selectAll }))
		);
	};
	
	const handleJobRoleChange = (jobRole: JobRole, checked: boolean) => {
		setJobRoles((prevJobRoles) =>
			prevJobRoles.map((job) =>
				job.id === jobRole.id ? { ...job, checked } : job
		)
	);
	
	if (!checked) {
		const removeFromCurrent = currentJobs.filter(
		(jobId:string) => jobId !== jobRole.id.toString()
		);
		const newShiftAllocationRules = shiftAllocationRules.map((rule:{shiftAllocationTypeId:number,jobIds:string}) =>
			rule.shiftAllocationTypeId === id
				? { ...rule, jobIds: removeFromCurrent.join(",") }
				: rule
			);
		setShiftAllocationRules && setShiftAllocationRules(newShiftAllocationRules);
		}
	};
	
	const handleOkClick = () => {
		const newJobs = Array.from(
			new Set(selectedJobRoles.map((job) => job.id))
		);
		const newShiftAllocationRules = shiftAllocationRules.map((rule:{shiftAllocationTypeId:number,jobIds:string}) =>
			rule.shiftAllocationTypeId === id
			? { ...rule, jobIds: newJobs.length ? newJobs.join(",") : null }
			: rule
		);
		setLocationData({
			...locationData,
			allocationSettings: newShiftAllocationRules,
		});
		setShiftAllocationRules && setShiftAllocationRules(newShiftAllocationRules);
		handleClose();
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-describedby='job-role-selection-for-seniority'
			maxWidth={false}
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<DialogTitle
				id='location-dialog-title'
				sx={{
					width: "400px",
				}}
			>
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontSize: "12px",
						fontWeight: 400,
						color: "rgba(0, 0, 0, 0.60)",
						lineHeight: "32px",
						textTransform: "uppercase",
					}}
				>
					{`Job Roles (${jobRoles.length})`}
				</Typography>
				<Grid container mt={1}>
					<Grid item xs={12}>
						<TextField
							id='search'
							type='search'
							label={searchTerm.trim() === "" ? "" : "Search"}
							placeholder='Search for a job role'
							value={searchTerm}
							onChange={handleChange}
							sx={{ width: "100%" }}
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<SearchIcon />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
				</Grid>
				<Grid container mt={0.5} mb={-1.5}>
					<Grid item xs={12}>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "flex-start",
							}}
						>
							<Checkbox
								icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
								checkedIcon={<CheckBoxIcon fontSize='small' />}
								checked={selectAll}
								onChange={handleSelectAllChange}
							/>
							<Typography
								sx={{
									color: "rgba(0,0,0,0.87)",
									fontFamily: "Roboto",
									fontSize: "16px",
									fontWeight: 400,
								}}
							>
								Select all
							</Typography>
						</Box>
					</Grid>
				</Grid>
			</DialogTitle>
			<Divider />
			<DialogContent>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						minHeight: "332px",
					}}
				>
					{filteredJobRoles.length > 0 &&
						filteredJobRoles.map((jobRole) => (
							<Box
								key={`shift-allocation-job-role-${jobRole.id}`}
								sx={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									justifyContent: "flex-start",
								}}
							>
								<Checkbox
									icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
									checkedIcon={<CheckBoxIcon fontSize='small' />}
									checked={selectedJobRoles.some(
										(selectedJobRole) => selectedJobRole.id === jobRole.id
									)}
									onChange={(e) =>
									handleJobRoleChange(jobRole, e.target.checked)
									}
									
								/>
								<Typography
									sx={{
										color: "rgba(0,0,0,0.87)",
										fontFamily: "Roboto",
										fontSize: "16px",
										fontWeight: 400,
									}}
								>
									{jobRole.name}
								</Typography>
							</Box>
						))}
					{jobRoles.length === 0 && (
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								flex: 1,
							}}
						>
							<Stack height='100%' alignItems='center' justifyContent='center'>
								<Box
									sx={{
										position: "relative",
										height: "100px",
										width: "100px",
									}}
								>
									<img
										src={LocationPlaceholderBackground}
										alt=''
										height={100}
										width={100}
										style={{
											position: "absolute",
											opacity: 0.25,
										}}
									/>
									<img
										src={
											allAssigned
												? NoJobRolesAvailablePlaceholder
												: NoJobRolesCreatedPlaceholder
										}
										alt=''
										height={70}
										width={70}
										style={{
											position: "absolute",
											top: 15,
											left: 15,
										}}
									/>
								</Box>
								<Typography
									sx={{
										fontFamily: "Roboto",
										fontSize: "14px",
										fontWeight: 400,
										color: "rgba(0, 0, 0, 0.38)",
										marginTop: "4px",
										textAlign: "center",
									}}
								>
									{`No job roles ${allAssigned ? "available" : "created"} !`}
								</Typography>
								<Typography
									sx={{
										fontFamily: "Roboto",
										fontSize: "12px",
										fontWeight: 400,
										color: "rgba(0, 0, 0, 0.38)",
										marginTop: "4px",
										textAlign: "center",
									}}
								>
									{allAssigned
										? "All existing job roles are already"
										: "You can return here once"}
								</Typography>
								<Typography
									sx={{
										fontFamily: "Roboto",
										fontSize: "12px",
										fontWeight: 400,
										color: "rgba(0, 0, 0, 0.38)",
										marginTop: "4px",
										textAlign: "center",
									}}
								>
									{allAssigned
										? "associated with other rules"
										: "Supervisors add job roles for this location"}
								</Typography>
							</Stack>
						</Box>
					)}
				</Box>
			</DialogContent>
			<Divider />
			<DialogActions>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-end",
						marginTop: "16px",
						marginBottom: "16px",
						marginRight: "21px",
					}}
				>
					<Button variant='outlined' size='small' onClick={() => handleClose()}>
						Cancel
					</Button>
					{/* Name, Country, Time Zone and isActive are required */}
					<LoadingButton
						variant='contained'
						size='small'
						disabled={selectedJobRoles.length === 0}
						sx={{ marginLeft: "16px" }}
						loading={false}
						onClick={handleOkClick}
						loadingIndicator={
							<CircularProgress
								size={16}
								sx={{ color: "rgba(0, 0, 0, 0.38)" }}
							/>
						}
					>
						OK
					</LoadingButton>
				</Box>
			</DialogActions>
		</Dialog>
	);
};

export default SelectJobsDialog;
