import React from "react";

import { deCamelCase } from "../../../../../../utils/helpers";
import useGatAdminConfigData from "../../../../controllers/use-gat-admin-config-data";

interface CompanyUserData {
	roleId?: string;
	roles?: { id: string }[];
	workCenterIds: string;
	userWorkCenters?: string[];
}
interface CompanyUserControllerProps {
	companyUserData: CompanyUserData;
	setCompanyUserData: React.Dispatch<React.SetStateAction<CompanyUserData>>;
}

const useCompanyUserController = ({
	companyUserData,
	setCompanyUserData,
}: CompanyUserControllerProps) => {
	const {
		createNewCompanyUserStatus,
		updateCompanyUserStatus,
		openCompanyUserDialog,
		allCompanyUserRoles,
		allCompanyWorkCenters,
	} = useGatAdminConfigData();

	const checkRoleId = (id: string): boolean => {
		if ("roleId" in companyUserData) {
			return companyUserData.roleId === id;
		} else if ("roles" in companyUserData && companyUserData.roles?.length) {
			return companyUserData.roles[0].id === id;
		}
		return false;
	};

	const getUserRole = (roleId: number): string => {
		if (!allCompanyUserRoles) return "";
		const role = allCompanyUserRoles.find(
			(role: { id: number }) => role.id === roleId
		);
		return role ? deCamelCase(role.name) : "";
	};

	const workCenterIncluded = (id: string): boolean => {
		const workCentersIdsArray = companyUserData.workCenterIds.split(",");
		return workCentersIdsArray.includes(id);
	};

	const updateWorkCenters = (workCenterIdsArray: string[]) => {
		setCompanyUserData({
			...companyUserData,
			workCenterIds: workCenterIdsArray.join(","),
			userWorkCenters: workCenterIdsArray,
		});
	};

	const addWorkCenterFromCompany = (id: string): void => {
		const workCenterIdsArray = companyUserData.workCenterIds
			.split(",")
			.filter(Boolean);
		if (!workCenterIdsArray.includes(id)) {
			updateWorkCenters([...workCenterIdsArray, id]);
		}
	};

	const removeWorkCenterFromCompany = (id: string): void => {
		const workCenterIdsArray = companyUserData.workCenterIds.split(",");
		updateWorkCenters(workCenterIdsArray.filter((wcId) => wcId !== id));
	};

	return [
		{
			createNewCompanyUserStatus,
			updateCompanyUserStatus,
			openCompanyUserDialog,
			allCompanyUserRoles,
			allCompanyWorkCenters,
		},
		{
			checkRoleId,
			getUserRole,
			workCenterIncluded,
			addWorkCenterFromCompany,
			removeWorkCenterFromCompany,
		},
	] as const;
};

export default useCompanyUserController;
