import { Box, Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import React from "react";

const ObsoleteTag = () => {
	return (
		<Box
			sx={{
				backgroundColor: red[100],
        borderRadius: "4px",
        opacity: 1,
			}}
		>
			<Typography
				sx={{
					color: red["A700"],
          padding: "2px 4px",
          fontSize: "0.8rem",
          fontWeight: "500",
          opacity: 1,
				}}
			>
				Obsolete
			</Typography>
		</Box>
	);
};

export default ObsoleteTag;
