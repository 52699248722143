import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { resetBroadcastMessageState } from "../store/scheduler-slice";
import useSelectorData from "./use-scheduler-data";

const useScheduleHeader = () => {
	const dispatch = useDispatch();
	const {
		publishDraftShiftsAsyncStatus,
		broadcastMessageStatus,
		isEditShiftDialogOpen,
	} = useSelectorData();

	const [
		isReviewPublishingShiftsDialogOpen,
		setIsReviewPublishingShiftsDialogOpen,
	] = useState(false);
	const [isBMessageDialogOpen, setIsBMessageDialogOpen] = useState(false);

	useEffect(() => {
		if (publishDraftShiftsAsyncStatus === "fulfilled")
			setIsReviewPublishingShiftsDialogOpen(false);
	}, [publishDraftShiftsAsyncStatus]);

	useEffect(() => {
		if (broadcastMessageStatus === "fulfilled") {
			setIsBMessageDialogOpen(false);
		}
	}, [broadcastMessageStatus]);

	const openBMessageDialog = () => {
		setIsBMessageDialogOpen(true);
		dispatch(resetBroadcastMessageState());
	};

	const closeBMessageDialog = () => {
		setIsBMessageDialogOpen(false);
	};

	return [
		{
			isReviewPublishingShiftsDialogOpen,
			isBMessageDialogOpen,
			isEditShiftDialogOpen,
		},
		{
			setIsReviewPublishingShiftsDialogOpen,
			closeBMessageDialog,
			openBMessageDialog,
		},
	];
};

export default useScheduleHeader;
