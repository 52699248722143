import PageLayout from "@gigandtake/page-layout";
import {Divider} from "@mui/material";
import React from "react";

import {PageHeader} from "../../../../../export/page-header";
import {
    WORKERS_AVAILABILITY_SUB_TITLE,
    WORKERS_AVAILABILITY_TITLE
} from "../../constants/titles";
import WorkersAvailabilityTable from "./ui/workers-availability-table";

const WorkersAvailability = ({changePage, callAlgo}) => {
    return (
        <PageLayout>
            <PageHeader
                title={WORKERS_AVAILABILITY_TITLE}
                subtitle={WORKERS_AVAILABILITY_SUB_TITLE}
            />
            <Divider />
            <WorkersAvailabilityTable changePage={changePage} callAlgo={callAlgo}/>
        </PageLayout>
    );
}

export default WorkersAvailability;
