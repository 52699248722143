import { Box, Divider, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";

import SelectCertificationsForm from "./select-certifications-form";

interface AddWorkerCertificationDialogProps {
	isOpen: boolean;
	onClose?: () => void;
}

const AddWorkerCertificationDialog: React.FC<
	AddWorkerCertificationDialogProps
> = ({ isOpen, onClose }) => {
	return (
		<Dialog
			onClose={(e, reason) => {
				if (reason !== "backdropClick" && onClose) {
					onClose();
					return;
				}
				return;
			}}
			aria-labelledby='add-edit-certification'
			open={!!isOpen}
			maxWidth={false}
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<DialogTitle
				id='location-dialog-title'
				sx={{
					width: "900px",
					padding: "45px 45px 24px 45px",
				}}
			>
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontSize: "20px",
						fontWeight: 500,
						color: "rgba(0, 0, 0, 0.87)",
					}}
				>
					Add Certifications for Worker
				</Typography>
			</DialogTitle>
			<DialogContent>
				<Box
					sx={{
						paddingLeft: "21px",
						paddingRight: "12px",
						paddingBottom: "12px",
					}}
				>
					<SelectCertificationsForm onClose={onClose} />
				</Box>
			</DialogContent>
		</Dialog>
	);
};

export default AddWorkerCertificationDialog;
