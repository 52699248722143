import { Box, Switch, Typography } from "@mui/material";
import React from "react";

type ShiftSwapAndCalloutsProps = {
	newAddOnSettings: any;
	setNewAddOnSettings: any;
};

const ShiftSwapAndCallouts: React.FC<ShiftSwapAndCalloutsProps> = ({
	newAddOnSettings,
	setNewAddOnSettings,
}) => {
	return (
		<Box
			sx={{
				backgroundColor: "#F5F5F5",
				borderRadius: "8px",
				marginTop: "32px",
				marginBottom: "16px",
				padding: "16px",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 400,
							fontSize: "16px",
							color: "rgba(0, 0, 0, 0.87)",
						}}
					>
						Shift Swap and Shift Callout - Regular worker
					</Typography>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 400,
							fontSize: "12px",
							color: "rgba(0, 0, 0, 0.6)",
						}}
					>
						Allow full time and part time workers to request shift swap and
						shift callout via mobile app.
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Switch
						checked={newAddOnSettings.swapAndCancelShift ?? false}
						onChange={(e) => {
							setNewAddOnSettings((pre: any) => ({
								...pre,
								swapAndCancelShift: e.target.checked,
							}));
							if (!e.target.checked) {
								setNewAddOnSettings((pre: any) => ({
									...pre,
									attendanceManagement: false,
								}));
							}
						}}
					/>
				</Box>
			</Box>
		</Box>
	);
};

export default ShiftSwapAndCallouts;
