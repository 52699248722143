import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { updateCompanySettings } from "../../../../../../../export/gat-admin";
import { findDifferentKeys } from "../../../../../../components/gat-admin/utils";
import useGatAdminConfigData from "../../../../controllers/use-gat-admin-config-data";

const settingsObj: any = {
    workerAvailabilityEnabled: "clientApp.workerAvailabilityEnabled",
    autoAcceptEnabled: "shift.autoAcceptEnabled",
};

const initialWorkerAppSettings = {
    workerAvailabilityEnabled: false,
    autoAcceptEnabled: false,
};

const useWorkerAppController = () => {
    const dispatch = useDispatch();

    const { updateCompanySettingsStatus, companySettingsObj } =
        useGatAdminConfigData();

    const [loading, setLoading] = useState(false);

    const [workerAppSettings, setWorkerAppSettings] = useState(
        initialWorkerAppSettings
    );
    const [newWorkerAppSettings, setNewWorkerAppSettings] = useState<any>({});

    const handleSave = () => {
        setLoading(true);
        const diffKeys: any = findDifferentKeys(workerAppSettings, newWorkerAppSettings);
        const changedSettings = diffKeys.map((key: any) => {
            return {
                ...companySettingsObj[settingsObj[key]],
                value: newWorkerAppSettings[key],
            };
        });
        dispatch(updateCompanySettings(changedSettings));
    };

    useEffect(() => {
        if (Object.keys(companySettingsObj).length > 0) {
            const obj = {
                workerAvailabilityEnabled:
                    companySettingsObj["clientApp.workerAvailabilityEnabled"].value,
                autoAcceptEnabled: companySettingsObj["shift.autoAcceptEnabled"].value,
            };
            setWorkerAppSettings(obj);
            setNewWorkerAppSettings(obj);
        }
    }, [companySettingsObj]);

    useEffect(() => {
        if (updateCompanySettingsStatus === "fulfilled") {
            setWorkerAppSettings(newWorkerAppSettings);
            setLoading(false);
        }
    }, [updateCompanySettingsStatus, newWorkerAppSettings]);

    return [
        {
            workerAppSettings,
            newWorkerAppSettings,
            loading,
        },
        {
            setNewWorkerAppSettings,
            handleSave,
        },
    ];
};

export default useWorkerAppController;
