export const light = {
	palette: {
		mode: "light",
		primary: {
			main: "#2F4D8B",
		},
	},
	spacing: 8,
	typography: {
		fontFamily: "Roboto",
	},
};
