// TODO - Type 'any' needs to be fixed.
import { Box, Tooltip, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import React from "react";

import { isTooDark } from "../../../utils/helpers";
import Highlight from "../../Highlight";

interface Item {
    id: string;
    name: string;
    hexColor?: string;
}

interface RadioListProps {
    value: string;
    setValue: React.Dispatch<React.SetStateAction<string>>;
    items: Item[];
    search: string;
}

function RadioList({
                         value,
                         setValue,
                         items,
                         search,
                     }: RadioListProps) {

    let itemStyle = {
        fontFamily: "Roboto",
        fontSize: "13px",
        fontWeight: "400",
        lineHeight: "18px",
        letterSpacing: "0.16px",
        borderRadius: "4px",
        paddingTop: "3px",
        paddingBottom: "3px",
        paddingLeft: "6px",
        paddingRight: "6px",
        marginRight: "8px",
        display: "box",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        boxOrient: "vertical",
        overflow: "hidden",
        maxWidth: "200px",
    };

    return (
        <Box ml={1} mt={1}>
            {items.map((item: Item) => {
                if (item.hexColor) {
                    const itemWithHexColor = {
                        color: isTooDark(`#${item.hexColor}`)
                            ? "#FFFFFF"
                            : "rgba(0,0,0,0.87)",
                        backgroundColor: `#${item.hexColor}`,
                        border: `1px solid #${item.hexColor}`,
                    }
                    itemStyle = {
                        ...itemStyle,
                        ...itemWithHexColor,
                    }
                }
                return (
                    <Box key={item.id}>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="item-controlled-radio-buttons-group"
                                name="item-controlled-radio-buttons-group"
                                value={value}
                                onChange={(e) => setValue(e.target.value)}
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    padding: "8px 0px 8px 16px",
                                }}
                            >
                                <FormControlLabel
                                    value={item.id}
                                    control={
                                        <Radio
                                            sx={{
                                                color: "rgba(0, 0, 0, 0.6)",
                                                "&.Mui-checked": { color: "black" },
                                            }}
                                        />
                                    }
                                    label={
                                        <Tooltip title={item.name}>
                                            <Typography
                                                sx={itemStyle}
                                            >
                                                <Highlight value={item.name} searchText={search} />
                                            </Typography>
                                        </Tooltip>
                                    }
                                />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                )
            })}
        </Box>
    );
}

export default RadioList;
