export const COUNTRIES = [
    {
        name: "United States",
        code: "1",
        iso2: "us"
    },
    {
        name: "Mexico",
        code: "52",
        iso2: "mx"
    },
    {
        name: "India",
        code: "91",
        iso2: "in"
    },
    {
        name: "Oman",
        code: "968",
        iso2: "om"
    },
    {
        name: "United Kingdom",
        code: "44",
        iso2: "gb"
    },
    {
        name: "Brazil",
        code: "55",
        iso2: "br"
    },
    {
        name: "Nigeria",
        code: "234",
        iso2: "ng"
    },
];
