import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { format } from "date-fns";
import React from "react";

type DayCellProps = {
	dayNo: number;
	date: undefined | Date;
	colorCode: string | undefined;
	handleClick: (event: React.MouseEvent<HTMLDivElement>) => void;
	disabled: boolean;
};
const ShiftPatternDayCell = (props: DayCellProps) => {
	const isSunday = props.date
		? props.date.getDay() === 0
			? true
			: false
		: false;
	const isWeekend = props.date
		? props.date.getDay() === 0 || props.date.getDay() === 6
			? true
			: false
		: false;
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				marginRight: isSunday ? "8px" : "2px",
				flex: 1,
			}}
		>
			<Typography
				sx={{
					fontSize: "12px",
					color: isWeekend ? "#E46962" : "rgba(0,0,0,0.38)",
					lineHeight: "20px",
					fontFamily: "Roboto",
					fontWeight: "400",
				}}
			>
				{props.date ? format(props.date, "eeeee") : ""}
			</Typography>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "center",
					height: "32px",
					width: "100%",
				}}
			>
				<Box
					sx={{
						flex: 1,
						height: "32px",
						backgroundColor: props.colorCode ?? "#E0E0E0",
						borderRadius: "2px",
						cursor: props.disabled ? "default" : "pointer",
						"&:hover": {
							opacity: props.disabled ? 1 : 0.8,
						},
					}}
					onClick={(e) => props.handleClick(e)}
				/>
			</Box>
		</Box>
	);
};

export default ShiftPatternDayCell;
