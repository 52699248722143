import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {isWeekend} from "date-fns";
import format from "date-fns/format";

import axios from "../../../utils/api-client";

const selectWeek = (date) => {
    try {
        let returnValue = Array(8)
            .fill(new Date(date))
            .map((el, idx) => new Date(el.setDate(el.getDate() - el.getDay() + idx)));
        return returnValue.slice(1, 8);
    } catch (e) {
        return [];
    }
};

/* API functions */
// Fetch all shifts
export const fetchShifts = createAsyncThunk(
	"WeeklySchedule/fetchShifts",
	async (payload, thunkAPI) => {
		try {
			let userId = thunkAPI.getState().user.userData.id;
			const { startDate, endDate } = payload;
			const getShiftsForThisWeek = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/ftshifts/user`,
				{
					params: {
						userId,
						startDate,
						endDate,
					},
				}
			);
			if (getShiftsForThisWeek.data) {
				return thunkAPI.fulfillWithValue(getShiftsForThisWeek.data);
			} else {
				return thunkAPI.rejectWithValue("An error occurred while fetching shifts for this week.");
			}
		} catch (e) {
            let errorMessage = "An error occurred while fetching shifts for this week."
            if (e.response.data.message !== undefined && e.response.data.message !== "") {
                errorMessage = e.response.data.message;
            }
            return thunkAPI.rejectWithValue(errorMessage);
			// Do nothing
		}
	}
);

export const getShiftDetails = createAsyncThunk(
    "WeeklySchedule/getShiftDetails",
    async (payload, thunkAPI) => {
        // console.log(payload);
        const shiftid = payload.shiftid;
        try {
            let response = await axios.get(
                `${process.env.REACT_APP_API_END_POINT}/Shifts/Workers`,
                {
                    params: {
                        shiftid,
                    },
                }
            );
            let data = response.data;
            if (response.status === 200) {
                return thunkAPI.fulfillWithValue({ data, shiftid });
            } else {
                return thunkAPI.rejectWithValue(response.error);
            }
        } catch (e) {
            // if it is not found then we are fulfilling the request
            let errorMessage = "An error occurred while fetching shifts details for this week."
            if (e.response.data.message !== undefined && e.response.data.message !== "") {
                errorMessage = e.response.data.message;
            }
            return thunkAPI.rejectWithValue(errorMessage);
        }
    }
);

// Call this API after calling requirement and availability
export const generateSchedule = createAsyncThunk(
	"WeeklySchedule/generateSchedule",
	async (payload, thunkAPI) => {
		try {
			const { fromDate, toDate } = payload;
			const scheduleShifts = await axios.post(
				`${process.env.REACT_APP_API_END_POINT}/ftworkers/schedule`,
				null,
				{
					params: {
						fromDate,
						toDate,
					},
				}
			);
			if (scheduleShifts.data) {
				return thunkAPI.fulfillWithValue(scheduleShifts.data);
			} else {
				return thunkAPI.rejectWithValue("An error occurred while during shifts generation.");
			}
		} catch (e) {
            let errorMessage = "An error occurred while during shifts generation."
            if (e.response.data.message !== undefined && e.response.data.message !== "") {
                errorMessage = e.response.data.message;
            }
            return thunkAPI.rejectWithValue(errorMessage);
		}
	}
);

// Create shift for flexible workers
// [{ create draft shifts req body with id renamed to ftShiftId }]
export const allowFlexibleWorkersForShifts = createAsyncThunk(
	"WeeklySchedule/allowFlexibleWorkersForShifts",
	async (payload, thunkAPI) => {
		try {
			const createFlexShift = await axios.post(
				`${process.env.REACT_APP_API_END_POINT}/ftshifts/createflexshift`,
				payload
			);
			if (createFlexShift.data) {
				return thunkAPI.fulfillWithValue(createFlexShift.data);
			} else {
				return thunkAPI.rejectWithValue("An error occurred while requesting flex worker for this shift.");
			}
		} catch (e) {
            let errorMessage = "An error occurred while requesting flex worker for this shift."
            if (e.response.data.message !== undefined && e.response.data.message !== "") {
                errorMessage = e.response.data.message;
            }
            return thunkAPI.rejectWithValue(errorMessage);
		}
	}
);

export const fetchWorkCenters = createAsyncThunk(
	"WeeklySchedule/fetchWorkCenters",
	async (payload, thunkAPI) => {
		try {
			const getWorkCenter = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/WorkCenters/GetWorkcentersByUser`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: localStorage.getItem("token"),
					},
				}
			);
			if (getWorkCenter.data) {
				return thunkAPI.fulfillWithValue(getWorkCenter.data);
			} else {
				return thunkAPI.rejectWithValue("An error occurred while fetching all work centers details.");
			}
		} catch (e) {
            let errorMessage = "An error occurred while fetching all work centers details."
            if (e.response.data.message !== undefined && e.response.data.message !== "") {
                errorMessage = e.response.data.message;
            }
            return thunkAPI.rejectWithValue(errorMessage);
		}
	}
);

export const fetchJobs = createAsyncThunk(
	"WeeklySchedule/fetchJobs",
	async (payload, thunkAPI) => {
		try {
			let userId = thunkAPI.getState().user.userData.id;
			const getJobs = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/Jobs/GetJobsByUser`,
				{
					params: {
						userid: userId,
					},
				}
			);
			if (getJobs.data) {
				return thunkAPI.fulfillWithValue(getJobs.data);
			} else {
				return thunkAPI.rejectWithValue("An error occurred while fetching all jobs details.");
			}
		} catch (e) {
            let errorMessage = "An error occurred while fetching all jobs details."
            if (e.response.data.message !== undefined && e.response.data.message !== "") {
                errorMessage = e.response.data.message;
            }
            return thunkAPI.rejectWithValue(errorMessage);
			// Do nothing
		}
	}
);

// Fetch shifts requirement of number of workers
export const fetchShiftsRequirement = createAsyncThunk(
	"WeeklySchedule/fetchShiftsRequirement",
	async (payload, thunkAPI) => {
		const { fromDate, toDate } = payload;
		try {
			const getShiftsRequirement = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/ftworkers/requirement`,
				{
					params: {
						fromDate,
						toDate,
					},
				}
			);
			if (getShiftsRequirement.data) {
				return thunkAPI.fulfillWithValue(getShiftsRequirement.data);
			} else {
				return thunkAPI.rejectWithValue("An error occurred while fetching shifts requirements.");
			}
		} catch (e) {
            let errorMessage = "An error occurred while fetching shifts requirements."
            if (e.response.data.message !== undefined && e.response.data.message !== "") {
                errorMessage = e.response.data.message;
            }
            return thunkAPI.rejectWithValue(errorMessage);
		}
	}
);

// Set shifts requirement for number of workers
/*
Example:
[requirements: [{reqDate: "2023-06-14", jobId: 14257, workerCount: 2}]]
*/
export const updateShiftsRequirement = createAsyncThunk(
	"WeeklySchedule/updateShiftsRequirement",
	async (payload, thunkAPI) => {
		try {
			const shiftRequirements = payload ?? [];
            let changedRequirements = [];
			shiftRequirements.forEach((req) => {
				if (req.changed) {
					changedRequirements.push(req);
				}
			});
            const updateShiftsRequirement = await axios.post(
				`${process.env.REACT_APP_API_END_POINT}/ftworkers/requirement/`,
				{ requirements: changedRequirements }
			);
			if (
				updateShiftsRequirement.status === 200 ||
				updateShiftsRequirement.status === 201
			) {
				return thunkAPI.fulfillWithValue(shiftRequirements);
			}
			return thunkAPI.rejectWithValue("An error occurred while updating shifts requirements.");
		} catch (e) {
            let errorMessage = "An error occurred while updating shifts requirements."
            if (e.response.data.message !== undefined && e.response.data.message !== "") {
                errorMessage = e.response.data.message;
            }
            return thunkAPI.rejectWithValue(errorMessage);
		}
	}
);

export const fetchAvailabilityTypes = createAsyncThunk(
	"WeeklySchedule/fetchAvailabilityTypes",
	async (payload, thunkAPI) => {
		try {
			const getAvailabilityTypes = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/ftworkers/availabilitytypes/`
			);
			if (getAvailabilityTypes.data) {
				return thunkAPI.fulfillWithValue(getAvailabilityTypes.data);
			} else {
				return thunkAPI.rejectWithValue("An error occurred while fetching availability types.");
			}
		} catch (e) {
            let errorMessage = "An error occurred while fetching availability types."
            if (e.response.data.message !== undefined && e.response.data.message !== "") {
                errorMessage = e.response.data.message;
            }
            return thunkAPI.rejectWithValue(errorMessage);
		}
	}
);

export const assignWorker = createAsyncThunk(
    "WeeklySchedule/assignWorker",
    async (payload, thunkAPI) => {
        try {
            const {shiftId, workerId} = payload;
            const assignWorkerToShift = await axios.post(
                `${process.env.REACT_APP_API_END_POINT}/ftshifts/assignworkers/`,
                {shiftId, workerId}
            );
            if (
                assignWorkerToShift.status === 200 ||
                assignWorkerToShift.status === 201
            ) {
                return thunkAPI.fulfillWithValue(payload);
            }
            return thunkAPI.rejectWithValue("An error occurred while assigning the worker to a job.");
        } catch (e) {
            console.log("Error")
            let errorMessage = "An error occurred while assigning the worker to a job."
            if (e.response.data.message !== undefined && e.response.data.message !== "") {
                errorMessage = e.response.data.message;
            }
            console.log("Error 2", errorMessage);
            return thunkAPI.rejectWithValue(errorMessage);
        }
    }
)

export const fetchAvailability = createAsyncThunk(
	"WeeklySchedule/fetchAvailability",
	async (payload, thunkAPI) => {
		try {
			const { fromDate, toDate } = payload;
			const getAvailability = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/ftworkers/availability`,
				{
					params: {
						fromDate,
						toDate,
					},
				}
			);
			if (getAvailability.data) {
				return thunkAPI.fulfillWithValue(getAvailability.data);
			} else {
				return thunkAPI.rejectWithValue("An error occurred while fetching workers availability.");
			}
		} catch (e) {
            let errorMessage = "An error occurred while fetching workers availability."
            if (e.response.data.message !== undefined && e.response.data.message !== "") {
                errorMessage = e.response.data.message;
            }
            return thunkAPI.rejectWithValue(errorMessage);
		}
	}
);

export const fetchAllWorkers = createAsyncThunk(
    "WeeklySchedule/fetchAllWorkers",
    async (payload, thunkAPI) => {
        try {
            const getAllWorkers = await axios.get(
                `${process.env.REACT_APP_API_END_POINT}/companies/workers`
            );
            if (getAllWorkers.data) {
                return thunkAPI.fulfillWithValue(getAllWorkers.data);
            } else {
                return thunkAPI.rejectWithValue("An error occurred while fetching all workers details.");
            }
        } catch (e) {
            let errorMessage = "An error occurred while fetching all workers details."
            if (e.response.data.message !== undefined && e.response.data.message !== "") {
                errorMessage = e.response.data.message;
            }
            return thunkAPI.rejectWithValue(errorMessage);
        }
    }
);

export const renotifyWorkers = createAsyncThunk(
    "WeeklySchedule/renotifyWorkers",
    async ({ workers, shiftId }, thunkAPI) => {
        // let r = JSON.stringify({ workerids: workers, shiftId })
        // console.log(r);

        workers = workers.toString();
        shiftId = shiftId.toString();

        try {
            let response = await axios({
                method: "post",
                url: `${process.env.REACT_APP_API_END_POINT}/Workers/Renotify`,
                data: JSON.stringify({ workerids: workers, shiftId }),
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.status === 200) return thunkAPI.fulfillWithValue(workers);
            else return thunkAPI.rejectWithValue(response.message);
        } catch (e) {
            let errorMessage = "An error occurred while re-notifying all workers."
            if (e.response.data.message !== undefined && e.response.data.message !== "") {
                errorMessage = e.response.data.message;
            }
            return thunkAPI.rejectWithValue(errorMessage);
        }
    }
);

// Set availability for workers
/*
Example:
[availabilities: [{availabilityDate: "2023-06-14", workerId: "6ac1cf80-762a-4a10-922-4cbcf19ba466", typeId: 2}]]
*/
export const updateAvailability = createAsyncThunk(
	"WeeklySchedule/updateAvailability",
	async (payload, thunkAPI) => {
		try {
			const availability = payload ?? [];
			let changedAvailability = [];
			availability.forEach((req) => {
				if (req.changed) {
					changedAvailability.push(req);
				}
			});
			const updateAvailability = await axios.post(
				`${process.env.REACT_APP_API_END_POINT}/ftworkers/availability/`,
				{ availabilities: changedAvailability }
			);
			if (
				updateAvailability.status === 200 ||
				updateAvailability.status === 201
			) {
				return thunkAPI.fulfillWithValue(availability);
			}
			return thunkAPI.rejectWithValue("An error occurred while updating workers availability.");
		} catch (e) {
            let errorMessage = "An error occurred while updating workers availability."
            if (e.response.data.message !== undefined && e.response.data.message !== "") {
                errorMessage = e.response.data.message;
            }
            return thunkAPI.rejectWithValue(errorMessage);
		}
	}
);

export const fetchUnallocatedWorkers = createAsyncThunk(
    "WeeklySchedule/fetchUnallocatedWorkers",
    async (payload, thunkAPI) => {
        try {
            const { fromDate, toDate } = payload;
            const getUnallocatedWorkers = await axios.get(
                `${process.env.REACT_APP_API_END_POINT}/ftworkers/assignedjobs`,
                {
                    params: {
                        fromDate: fromDate,
                        toDate: toDate,
                    }
                }
            );
            if (getUnallocatedWorkers.data) {
                return thunkAPI.fulfillWithValue(getUnallocatedWorkers.data);
            } else {
                return thunkAPI.rejectWithValue("An error occurred while fetching unallocated workers.");
            }
        } catch (e) {
            let errorMessage = "An error occurred while fetching unallocated workers."
            if (e.response.data.message !== undefined && e.response.data.message !== "") {
                errorMessage = e.response.data.message;
            }
            return thunkAPI.rejectWithValue(errorMessage);
        }
    }
)
export const WeeklyScheduleSlice = createSlice({
    name:"WeeklySchedule",
    initialState:{
        anyDateOfSelectedWeek: new Date().toISOString(),
        shifts: [],
        jobs: [],
        workCenters: [],
        allWorkers: [],
        fullTimeWorkers: [],
        flexWorkers: [],
        unallocatedWorkers: [],
        shiftsRequirement: [],
        totalRequirementHours: [],
        totalAvailabilityHours: [],
        availabilityTypes: [],
        availability: [],
        generatedSchedule: [],
        weeklyDraftShifts: [],
        activeAvailability: {},
        selectedWorker: "",
        fetchShifts: {
            status: "idle",
            errorMessage: "",
        },
        fetchShiftsRequirement: {
            status: "idle",
            errorMessage: "",
        },
        updateShiftsRequirement: {
            status: "idle",
            errorMessage: "",
        },
        fetchAvailabilityTypes: {
            status: "idle",
            errorMessage: "",
        },
        fetchAvailability: {
            status: "idle",
            errorMessage: "",
        },
        updateAvailability: {
            status: "idle",
            errorMessage: "",
        },
        fetchWorkCenters: {
            status: "idle",
            errorMessage: "",
        },
        fetchJobs: {
            status: "idle",
            errorMessage: "",
        },
        generateSchedule: {
            status: "idle",
            errorMessage: "",
        },
        allowFlexibleWorkersForShifts: {
            status: "idle",
            errorMessage: "",
        },
        fetchAllWorkers: {
            status: "idle",
            errorMessage: "",
        },
        fetchUnallocatedWorkers: {
            status: "idle",
            errorMessage: "",
        },
        assignWorker: {
            status: "idle",
            errorMessage: "",
        },
        shiftDetails: {},
        getShiftDetails: {
            status: "idle",
            errorMessage: "",
        },
        renotifyWorkers: {
            status: "idle",
            errorMessage: "",
        },
    },
    reducers:{
        setAnyDateOfSelectedWeek: (state, action) => {
            state.anyDateOfSelectedWeek = action.payload;
        },
        resetShiftRequirement: (state) => {
            state.shiftsRequirement = [];
        },
        setShiftRequirement: (state, action) => {
            state.shiftsRequirement = action.payload;
        },
        setAvailability: (state, action) => {
            state.availability = action.payload;
        },
        lastAvailabilityChanged: (state, action) => {
            state.activeAvailability = action.payload;
        },
        resetAvailability: (state ) => {
            state.availability = [];
        },
        resetUpdateAvailability: (state) => {
          state.updateAvailability = {
            status: "idle",
            errorMessage: "",
          };
        },
        resetUpdateShiftsRequirement: (state) => {
          state.updateShiftsRequirement = {
            status: "idle",
            errorMessage: "",
          };
        },
        resetShifts: (state) => {
            state.fetchShifts = {
                status: "idle",
                errorMessage: "",
            }
            state.fetchUnallocatedWorkers = {
                status: "idle",
                errorMessage: "",
            };
            state.generateSchedule = {
                status: "idle",
                errorMessage: "",
            };
            state.assignWorker = {
                status: "idle",
                errorMessage: "",
            };
            state.fetchShiftsRequirement = {
                status: "idle",
                errorMessage: "",
            };
            state.fetchAvailability = {
                status: "idle",
                errorMessage: "",
            };
        },
        resetWorkCenters: (state) => {
            state.fetchWorkCenters = {
                status: "idle",
                errorMessage: "",
            }
        },
        resetJobs: (state) => {
            state.fetchJobs = {
                status: "idle",
                errorMessage: "",
            }
        },
        resetAllWorkers: (state) => {
            state.fetchAllWorkers = {
                status: "idle",
                errorMessage: "",
            }
        },
        resetGenerateSchedule: (state) => {
            state.generateSchedule = {
                status: "idle",
                errorMessage: "",
            }
        },
        resetAllowFlexibleWorkersForShifts: (state) => {
            state.allowFlexibleWorkersForShifts = {
                status: "idle",
                errorMessage: "",
            }
        },
        setSelectedWorker: (state, action) => {
            try {
                const {worker, selectedOnDate} = action.payload;
                state.selectedWorker = {
                    worker: worker,
                    selectedOnDate: selectedOnDate,
                };
            } catch (e) {
                // Do nothing
            }
        },
        updateWeeklyDraftShift: (state, action) => {
            try {
                state.weeklyDraftShifts = action.payload;
            } catch (e) {
                // Do nothing
            }
        },
        resetRenotifyWorkers: (state) => {
            state.renotifyWorkers.status = "idle";
            state.renotifyWorkers.errorMessage = "";
        },
    },
    extraReducers:{
        [fetchShifts.pending]: (state) => {
            state.fetchShifts.status = "pending";
            state.fetchShifts.errorMessage = "";
            state.fetchUnallocatedWorkers.status = "idle";
            state.generateSchedule.status = "idle";
            state.assignWorker.status = "idle";
            state.fetchShiftsRequirement.status = "idle";
            state.fetchAvailability.status = "idle";
        },
        [fetchShifts.rejected]: (state, action) => {
            state.fetchShifts.status = "error";
            state.fetchShifts.errorMessage = action.payload ?? "An error occurred";
            state.shifts = [];
        },
        [fetchShifts.fulfilled]: (state, action) => {
            state.fetchShifts.status = "fulfilled";
            state.fetchShifts.errorMessage = "";
            if (action.payload) {
                const allShifts =  action.payload.signupTracking;
                state.shifts = allShifts ?? [];
                let tempDraftShifts = [];
                if (allShifts) {
                    allShifts.forEach(shift => {
                        if (shift.numberOfWorkersNeeded > shift.workers.length &&
                            new Date(shift.startDateTime).getTime() > new Date().getTime() &&
                            shift.flex === 0
                        ) {
                            let tempDraftShift = {
                                ...shift,
                                ftShiftId: shift.id,
                                numberOfWorkersNeeded: shift.numberOfWorkersNeeded - shift.workers.length
                            };
                            tempDraftShifts.push(tempDraftShift);
                        }
                    });
                }
                state.weeklyDraftShifts = tempDraftShifts;
            }
        },
        [generateSchedule.pending]: (state) => {
            state.generateSchedule.status = "pending";
            state.generateSchedule.errorMessage = "";
        },
        [generateSchedule.rejected]: (state, action) => {
            state.generateSchedule.status = "error";
            state.generateSchedule.errorMessage = action.payload ?? "An error occurred";
            state.generatedSchedule = [];
        },
        [generateSchedule.fulfilled]: (state, action) => {
            state.fetchUnallocatedWorkers.status = "idle";
            state.generateSchedule.status = "fulfilled";
            state.generateSchedule.errorMessage = "";
            if (action.payload) {
                state.generatedSchedule = action.payload.output ?? [];
            }
        },
        [fetchWorkCenters.pending]: (state) => {
            state.fetchWorkCenters.status = "pending";
            state.fetchWorkCenters.errorMessage = "";
        },
        [fetchWorkCenters.rejected]: (state, action) => {
            state.fetchWorkCenters.status = "error";
            state.fetchWorkCenters.errorMessage = action.payload ?? "An error occurred";
            state.shifts = [];
        },
        [fetchWorkCenters.fulfilled]: (state, action) => {
            state.fetchWorkCenters.status = "fulfilled";
            state.fetchWorkCenters.errorMessage = "";
            state.workCenters = action.payload ?? [];
        },
        [fetchJobs.pending]: (state) => {
            state.fetchJobs.status = "pending";
            state.fetchJobs.errorMessage = "";
        },
        [fetchJobs.rejected]: (state, action) => {
            state.fetchJobs.status = "error";
            state.fetchJobs.errorMessage = action.payload ?? "An error occurred";
            state.shifts = [];
        },
        [fetchJobs.fulfilled]: (state, action) => {
            state.fetchJobs.status = "fulfilled";
            state.fetchJobs.errorMessage = "";
            state.jobs = action.payload ?? [];
        },
        [fetchShiftsRequirement.pending]: (state) => {
            state.fetchShiftsRequirement.status = "pending";
            state.fetchShiftsRequirement.errorMessage = "";
        },
        [fetchShiftsRequirement.rejected]: (state, action) => {
            state.fetchShiftsRequirement.status = "error";
            state.fetchShiftsRequirement.errorMessage = action.payload ?? "An error occurred";
        },
        [fetchShiftsRequirement.fulfilled]: (state, action) => {
            let response = action.payload;
            let tempRequirement = [];
            let tempTotalRequirementHours = 0;
            let startDateForTotalRequiredHours = "";
            let endDateForForTotalRequiredHours = "";

            if (response) {
                response.forEach((req, index) => {
                    let jobHours = req.jobHours ?? 8; // For some jobs jobHours is undefined
                    // Set start date and end date for total required hours
                    if (index === 0) {
                        let allDatesOfSelectedWeek = selectWeek(req.reqDate);
                        startDateForTotalRequiredHours = format(allDatesOfSelectedWeek[0], "yyyy-MM-dd");
                        endDateForForTotalRequiredHours = format(allDatesOfSelectedWeek[allDatesOfSelectedWeek.length-1], "yyyy-MM-dd");
                    }
                    let obj = {...req};
                    obj.reqDate = format(new Date(req.reqDate), "yyyy-MM-dd");
                    obj.changed = false;
                    obj.jobHours = jobHours;
                    if (obj.workerCount) {
                        tempTotalRequirementHours += (jobHours * obj.workerCount);
                    }
                    tempRequirement.push(obj);
                });
            }
            state.fetchShiftsRequirement.status = "fulfilled";
            state.fetchShiftsRequirement.errorMessage = "";
            state.shiftsRequirement = tempRequirement;
            if (startDateForTotalRequiredHours !== "") {
                let newTotalRequirementHours =
                    state.totalRequirementHours.filter(req => req.startDate !== startDateForTotalRequiredHours) ?? [];
                newTotalRequirementHours.push(
                    {
                        startDate: startDateForTotalRequiredHours,
                        endDate: endDateForForTotalRequiredHours,
                        requirementHours: tempTotalRequirementHours
                    }
                );
                state.totalRequirementHours = newTotalRequirementHours;
            }
        },
        [updateShiftsRequirement.pending]: (state) => {
            state.updateShiftsRequirement.status = "pending";
            state.updateShiftsRequirement.errorMessage = "";
        },
        [updateShiftsRequirement.rejected]: (state, action) => {
            state.updateShiftsRequirement.status = "error";
            state.updateShiftsRequirement.errorMessage = action.payload ?? "An error occurred";
        },
        [updateShiftsRequirement.fulfilled]: (state, action) => {
            state.updateShiftsRequirement.status = "fulfilled";
            state.updateShiftsRequirement.errorMessage = "";
            state.shiftsRequirement = action.payload;

            const changesInRequirement = action.payload;
            let tempTotalRequirementHours = 0;
            let startDateForTotalRequiredHours = "";
            let endDateForForTotalRequiredHours = "";

            if (changesInRequirement) {
                changesInRequirement.forEach((req, index) => {
                    let jobHours = req.jobHours ?? 8; // For some jobs jobHours is undefined
                    // Set start date and end date for total required hours
                    if (index === 0) {
                        let allDatesOfSelectedWeek = selectWeek(req.reqDate + "T00:00:00Z");
                        startDateForTotalRequiredHours = format(allDatesOfSelectedWeek[0], "yyyy-MM-dd");
                        endDateForForTotalRequiredHours = format(allDatesOfSelectedWeek[allDatesOfSelectedWeek.length-1], "yyyy-MM-dd");
                    }
                    if (req.workerCount) {
                        tempTotalRequirementHours += (jobHours * req.workerCount);
                    }
                });
            }
            if (startDateForTotalRequiredHours !== "") {
                let newTotalRequirementHours =
                    state.totalRequirementHours.filter(req => req.startDate !== startDateForTotalRequiredHours) ?? [];
                newTotalRequirementHours.push(
                    {
                        startDate: startDateForTotalRequiredHours,
                        endDate: endDateForForTotalRequiredHours,
                        requirementHours: tempTotalRequirementHours
                    }
                );
                state.totalRequirementHours = newTotalRequirementHours;
            }
        },
        [fetchAvailabilityTypes.pending]: (state) => {
            state.fetchAvailabilityTypes.status = "pending";
            state.fetchAvailabilityTypes.errorMessage = "";
        },
        [fetchAvailabilityTypes.rejected]: (state, action) => {
            state.fetchAvailabilityTypes.status = "error";
            state.fetchAvailabilityTypes.errorMessage = action.payload ?? "An error occurred";
        },
        [fetchAvailabilityTypes.fulfilled]: (state, action) => {
            state.fetchAvailabilityTypes.status = "idle";
            state.fetchAvailabilityTypes.errorMessage = "";
            state.availabilityTypes = action.payload;
        },
        [fetchAvailability.pending]: (state) => {
            state.fetchAvailability.status = "pending";
            state.fetchAvailability.errorMessage = "";
        },
        [fetchAvailability.rejected]: (state, action) => {
            state.fetchAvailability.status = "error";
            state.fetchAvailability.errorMessage = action.payload ?? "An error occurred";
        },
        [fetchAvailability.fulfilled]: (state, action) => {
            let fetchedAvailability = action.payload;
            let tempAvailability = [];
            try {
                let allDatesOfSelectedWeek = selectWeek(state.anyDateOfSelectedWeek);
                let startDateForTotalAvailabilityHours = format(
                    allDatesOfSelectedWeek[0],
                    "yyyy-MM-dd"
                );
                let endDateForTotalAvailabilityHours = format(
                    allDatesOfSelectedWeek[allDatesOfSelectedWeek.length-1],
                    "yyyy-MM-dd"
                );
                // Default 560 assuming 10 workers working 5 days a week 8 hours

                let totalHours = state.fullTimeWorkers.length * 8 * 5 ?? 400;
                if (fetchedAvailability) {
                    fetchedAvailability.forEach((req, index) => {
                        if (index === 0) {
                            let allDatesOfSelectedWeek = selectWeek(req.availabilityDate);
                            startDateForTotalAvailabilityHours = format(
                                allDatesOfSelectedWeek[0],
                                "yyyy-MM-dd"
                            );
                            endDateForTotalAvailabilityHours = format(
                                allDatesOfSelectedWeek[allDatesOfSelectedWeek.length-1],
                                "yyyy-MM-dd"
                            );
                        }
                        let obj = {...req};
                        obj.availabilityDate = format(
                            new Date(req.availabilityDate),
                            "yyyy-MM-dd"
                        );
                        obj.changed = false;
                        tempAvailability.push(obj);

                        // Check if its weekend
                        if (isWeekend(new Date(req.availabilityDate))) {
                            if (req.typeId === 1) {
                                totalHours += 8;
                            } else if (req.typeId === 4) {
                                totalHours += 10;
                            }
                        } else {
                            if (req.typeId === 2 || req.typeId === 3) {
                                totalHours -= 8;
                            } else if (req.typeId === 4) {
                                totalHours += 2;
                            }
                        }
                    });
                }
                state.fetchAvailability.status = "fulfilled";
                state.fetchAvailability.errorMessage = "";
                state.availability = tempAvailability;
                let newTotalAvailabilityHours = state.totalAvailabilityHours.filter(
                    av => av.startDate !== startDateForTotalAvailabilityHours) ?? [];
                newTotalAvailabilityHours.push({
                    startDate: startDateForTotalAvailabilityHours,
                    endDate: endDateForTotalAvailabilityHours,
                    availabilityHours: totalHours,
                });
                state.totalAvailabilityHours = newTotalAvailabilityHours;
            } catch (e) {
                console.log("Error while setting availability");
                // Do nothing
            }
        },
        [updateAvailability.pending]: (state) => {
            state.updateAvailability.status = "pending";
            state.updateAvailability.errorMessage = "";
        },
        [updateAvailability.rejected]: (state, action) => {
            state.updateAvailability.status = "error";
            state.updateAvailability.errorMessage =
            action.payload ?? "An error occurred";
        },
        [updateAvailability.fulfilled]: (state, action) => {
            state.updateAvailability.status = "fulfilled";
            state.updateAvailability.errorMessage = "";
            state.availability = action.payload;
            try {
                let allDatesOfSelectedWeek = selectWeek(state.anyDateOfSelectedWeek);
                const updatedAvailability = action.payload;
                let startDateForTotalAvailabilityHours = format(
                    allDatesOfSelectedWeek[0],
                    "yyyy-MM-dd"
                );
                let endDateForTotalAvailabilityHours = format(
                    allDatesOfSelectedWeek[allDatesOfSelectedWeek.length-1],
                    "yyyy-MM-dd"
                );
                // Default 560 assuming 10 workers working 5 days a week 8 hours
                let totalHours = state.fullTimeWorkers.length * 8 * 5 ?? 400;
                if (updatedAvailability) {
                    updatedAvailability.forEach((req, index) => {
                        if (index === 0) {
                            allDatesOfSelectedWeek = selectWeek(req.availabilityDate);
                            startDateForTotalAvailabilityHours = format(
                                allDatesOfSelectedWeek[0],
                                "yyyy-MM-dd"
                            );
                            endDateForTotalAvailabilityHours = format(
                                allDatesOfSelectedWeek[allDatesOfSelectedWeek.length-1],
                                "yyyy-MM-dd"
                            );
                        }
                        if (isWeekend(new Date(req.availabilityDate))) {
                            if (req.typeId === 1) {
                                totalHours += 8;
                            } else if (req.typeId === 4) {
                                totalHours += 10;
                            }
                        } else {
                            if (req.typeId === 2 || req.typeId === 3) {
                                totalHours -= 8;
                            } else if (req.typeId === 4) {
                                totalHours += 2;
                            }
                        }
                    });
                }
                let newTotalAvailabilityHours = state.totalAvailabilityHours.filter(
                    av => av.startDate !== startDateForTotalAvailabilityHours) ?? [];
                newTotalAvailabilityHours.push({
                    startDate: startDateForTotalAvailabilityHours,
                    endDate: endDateForTotalAvailabilityHours,
                    availabilityHours: totalHours,
                });
                state.totalAvailabilityHours = newTotalAvailabilityHours;
            } catch (e) {
                console.log("Error with availability");
                // Do nothing
            }
        },
        [allowFlexibleWorkersForShifts.pending]: (state) => {
            state.allowFlexibleWorkersForShifts.status = "pending";
            state.allowFlexibleWorkersForShifts.errorMessage = "";
        },
        [allowFlexibleWorkersForShifts.rejected]: (state, action) => {
            state.allowFlexibleWorkersForShifts.status = "error";
            state.allowFlexibleWorkersForShifts.errorMessage = action.payload ?? "An error occurred";
        },
        [allowFlexibleWorkersForShifts.fulfilled]: (state) => {
            state.allowFlexibleWorkersForShifts.status = "fulfilled";
            state.allowFlexibleWorkersForShifts.errorMessage = "";
        },
        [fetchAllWorkers.pending]: (state) => {
            state.fetchAllWorkers.status = "pending";
            state.fetchAllWorkers.errorMessage = "";
        },
        [fetchAllWorkers.rejected]: (state, action) => {
            state.fetchAllWorkers.status = "error";
            state.fetchAllWorkers.errorMessage = action.payload ?? "An error occurred";
        },
        [fetchAllWorkers.fulfilled]: (state, action) => {
            state.fetchAllWorkers.status = "fulfilled";
            state.fetchAllWorkers.errorMessage = "";
            state.allWorkers = action.payload;
            state.fullTimeWorkers = action.payload.filter(worker => worker.type === "FullTime");
            state.flexWorkers = action.payload.filter(worker => worker.type === "FlexWorker")
        },
        [fetchUnallocatedWorkers.pending]: (state) => {
            state.fetchUnallocatedWorkers.status = "pending";
            state.fetchUnallocatedWorkers.errorMessage = "";
        },
        [fetchUnallocatedWorkers.rejected]: (state, action) => {
            state.fetchUnallocatedWorkers.status = "error";
            state.fetchUnallocatedWorkers.errorMessage = action.payload ?? "An error occurred";
        },
        [fetchUnallocatedWorkers.fulfilled]: (state, action) => {
            state.fetchUnallocatedWorkers.status = "fulfilled";
            state.fetchUnallocatedWorkers.errorMessage = "";
            state.unallocatedWorkers = action.payload;
        },
        [assignWorker.pending]: (state) => {
            state.assignWorker.status = "pending";
            state.assignWorker.errorMessage = "";
        },
        [assignWorker.rejected]: (state, action) => {
            state.assignWorker.status = "error";
            state.assignWorker.errorMessage = action.payload ?? "An error occurred";
        },
        [assignWorker.fulfilled]: (state) => {
            state.assignWorker.status = "fulfilled";
            state.assignWorker.errorMessage = "";
        },
        [getShiftDetails.pending]: (state) => {
            state.getShiftDetails.status = "pending";
            state.getShiftDetails.errorMessage = "";
        },
        [getShiftDetails.rejected]: (state, action) => {
            state.getShiftDetails.status = "rejected";
            state.getShiftDetails.errorMessage = action.payload ?? "An error occurred";
        },
        [getShiftDetails.fulfilled]: (state, action) => {
            state.getShiftDetails.status = "idle";
            state.getShiftDetails.errorMessage = "";
            state.shiftDetails = action.payload.data ?? {};
        },
        [renotifyWorkers.pending]: (state) => {
            state.renotifyWorkers.status = "pending";
        },
        [renotifyWorkers.rejected]: (state, action) => {
            state.renotifyWorkers.status = "error";
            state.renotifyWorkers.errorMessage = action.payload ?? "An error occurred";
        },
        [renotifyWorkers.fulfilled]: (state) => {
            state.renotifyWorkers.status = "fulfilled";
        },
    }
});

export const {
    resetShifts,
	setAnyDateOfSelectedWeek,
	resetShiftRequirement,
	setShiftRequirement,
	setAvailability,
    lastAvailabilityChanged,
	resetAvailability,
	resetUpdateAvailability,
	resetUpdateShiftsRequirement,
	resetGenerateSchedule,
	resetAllowFlexibleWorkersForShifts,
    setSelectedWorker,
    updateWeeklyDraftShift,
    resetAllWorkers,
    resetJobs,
    resetWorkCenters,
    resetRenotifyWorkers,
} = WeeklyScheduleSlice.actions;

export default WeeklyScheduleSlice.reducer;
