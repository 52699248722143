// import Button from "@gigandtake/button";
import ButtonGroup from "@mui/material/ButtonGroup";
import React from "react";

import Button from "../../../../../../../design/button";

type InputButtonGroupProps = {
	value: number;
	handleIncrement: () => void;
	handleDecrement: () => void;
};

const InputButtonGroup = ({
	value = 0,
	handleIncrement,
	handleDecrement,
}: InputButtonGroupProps) => {
	return (
		<ButtonGroup variant='outlined' aria-label='outlined button group'>
			<Button
				variant='outlined'
				onClick={handleDecrement}
				disabled={value <= 0}
			>
				-
			</Button>
			<Button
				variant='outlined'
				onClick={() => {
					/* Do Nothing */
				}}
			>
				{value >= 0 ? value : 0}
			</Button>
			<Button variant='outlined' onClick={handleIncrement}>
				+
			</Button>
		</ButtonGroup>
	);
};

export default InputButtonGroup;
