// TODO - Type 'any' needs to be fixed.
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {getShiftAttendanceDetails} from "../../../../../../../export/scheduler";
import useSchedulerData from "../../../../controllers/use-scheduler-data";
import { getConfirmedWorkersForShift } from "../../../../store/scheduler-slice";


const useMarkAttendanceDialog = (shiftId: number) => {
	const dispatch = useDispatch();
	const [rows, setRows] = useState([]);
	const [shiftMetadata, setShiftMetadata] = useState({
		startDate : "",
		endDate: "",
	});

	const {
		allShiftsMetadata,
		shiftsAttendanceData,
		shiftAttendanceDetailsStatus,
		markAttendanceStatus,
		markAttendanceError,
	} = useSchedulerData();

	const confirmedWorkers = useSelector(
		(state: any) => state.supervisor?.confirmedWorkersForShift?.data?.data
	);
	const shiftAttendanceData = shiftsAttendanceData?.[shiftId];

	useEffect(() => {
		// fetch the data
		if (shiftId) {
			dispatch(getShiftAttendanceDetails({ shiftid: shiftId }));
			dispatch(getConfirmedWorkersForShift({ shiftid: shiftId }));
		}
	}, [dispatch, shiftId]);

	useEffect(() => {
		if (markAttendanceStatus === "fulfilled") {
			dispatch(getShiftAttendanceDetails({ shiftid: shiftId }));
		}
	}, [dispatch, markAttendanceStatus, shiftId]);

	useEffect(() => {
		if (confirmedWorkers && shiftAttendanceData) {
			const rowData: any = [];

			confirmedWorkers.forEach((worker: any) => {
				const workerData = shiftAttendanceData?.find(
					(w:any) => w?.workerId === worker?.id
				);
				rowData.push({
					id: worker.id,
					workerId: worker.id,
					fullName: `${worker?.firstName} ${worker?.lastName}`,
					status: workerData?.status,
					comment: workerData?.comment,
					shiftId: shiftId,
					startDateTime: shiftMetadata.startDate,
					endDateTime: shiftMetadata.endDate,
				});
			});
			setRows(rowData);
		}
	}, [confirmedWorkers, shiftId, shiftAttendanceData, shiftMetadata]);

	useEffect(() => {
		// getting shift details from shiftId
		if (allShiftsMetadata) {
			const currentShiftData = allShiftsMetadata.filter(
				(shift:any) => shift.id === shiftId
			);
			setShiftMetadata(currentShiftData[0]);
		}
	}, [allShiftsMetadata, shiftId]);
	console.log("shiftMetadata", shiftMetadata);

	return [
		{
			rows,
			shiftMetadata,
			shiftAttendanceDetailsStatus,
			markAttendanceStatus,
			markAttendanceError,
		},
	];
};

export default useMarkAttendanceDialog;
