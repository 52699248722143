import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import React from "react";

const ReviewCheckbox = ({ amISure, setAmISure }:any) => {
	return (
		<FormControlLabel
			control={
				<Checkbox
					checked={amISure}
					onChange={() => setAmISure(!amISure)}
					name='amISureCheckbox'
					id='amISureCheckbox'
					color='primary'
				/>
			}
			label='I am sure that I want to publish these shifts'
			style={{ marginRight: "auto", marginLeft: "9px" }}
		/>
	);
};

export default ReviewCheckbox;
