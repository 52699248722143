import { LoadingButton } from "@mui/lab";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import {
	addWorkerCertifications,
	fetchWorkerProfile,
} from "../../../../../../../../export/workers";
import useWorkersData from "../../../../../controllers/use-workers-data";

interface Skill {
	skillId: number;
	levelId: number | string;
	workerId: string;
}
interface ActionButtonsProps {
	closeDialog?: () => void;
	status: string;
	selectedCertifications?: number[];
}

const ActionButtons: React.FC<ActionButtonsProps> = ({
	closeDialog,
	status,
	selectedCertifications,
}) => {
	const dispatch = useDispatch();

	const { workerId } = useWorkersData();

	const certs =
		selectedCertifications?.map((certId) => ({
			workerId,
			CompanyCertId: certId,
		})) || [];

	useEffect(() => {
		if (status === "fulfilled" && closeDialog && workerId) {
			dispatch(fetchWorkerProfile(workerId));
			closeDialog();
		}
	}, [status, closeDialog, dispatch, workerId]);

	const certificationCount = selectedCertifications ? selectedCertifications.length : 0;
	const certificationText = certificationCount > 1 ? "Certifications" : "Certification";
	const additionMessage = certificationCount > 0 ? `${certificationCount} ${certificationText} selected for addition` : "";


	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
				padding: "32px 0px 45px 0px",
			}}
		>
			<Box>
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontSize: "16px",
						fontWeight: 400,
						color: "#000000DE",
						lineHeight: "24px",
					}}
				>
					{additionMessage}
				</Typography>
			</Box>
			<Box>
				<Button variant='outlined' onClick={closeDialog}>
					Cancel
				</Button>
				<LoadingButton
					variant='contained'
					disabled={selectedCertifications?.length === 0}
					sx={{ marginLeft: "16px", width: "100px" }}
					loading={status === "pending"}
					type='submit'
					onClick={() => dispatch(addWorkerCertifications(certs))}
					loadingIndicator={
						<Box display='flex'>
							<CircularProgress
								size={16}
								sx={{ color: "rgba(0, 0, 0, 0.38)" }}
							/>
							<Typography variant='body2' sx={{ marginLeft: "8px" }}>
								{"Adding"}
							</Typography>
						</Box>
					}
				>
					{"Add"}
				</LoadingButton>
			</Box>
		</Box>
	);
};

export default ActionButtons;
