import PageLayout from "@gigandtake/page-layout";
import {Divider} from "@mui/material";
import React from "react";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";

import {PageHeader} from "../../../../../export/page-header";
import {FULL_TIME_SCHEDULE_SUB_TITLE, FULL_TIME_SCHEDULE_TITLE} from "../../constants/titles";
import ScheduledShiftsTable from "./ui/scheduled-shifts-table";

const ScheduledShifts = ({changePage, regenerateShifts, resetCallAlgo}) => {
    return (
        <PageLayout>
            <DndProvider backend={HTML5Backend}>
                <PageHeader
                    title={FULL_TIME_SCHEDULE_TITLE}
                    subtitle={FULL_TIME_SCHEDULE_SUB_TITLE}
                />
                <Divider />
                <ScheduledShiftsTable
                    changePage={changePage}
                    regenerateShifts={regenerateShifts}
                    resetCallAlgo={resetCallAlgo}
                />
            </DndProvider>
        </PageLayout>
    );
}

export default ScheduledShifts;
