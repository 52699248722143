

export const closeAddWorkerDialogReducer = (state) => {
    state.addWorkerDialog.isOpen = false;
    state.editWorkerDialog.isOpen = false;
    state.createNewWorker.errorMessage = "";
    state.createNewWorker.status = "idle";
    state.editWorker.status = "idle";
    state.editWorker.errorMessage = "";
}
