import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import { Box, Typography } from "@mui/material";
import React from "react";

const NoTeams = () => {
	return (
		<Box
			display='flex'
			flexDirection='column'
			justifyContent='center'
			alignItems='center'
			mt={4}
			mb={2}
		>
			<PeopleAltOutlinedIcon
				sx={{
					color: "rgba(0, 0, 0, 0.6)",
				}}
			/>
			<Typography
				sx={{
					fontFamily: "Roboto",
					fontWeight: 400,
					fontSize: "16px",
					color: "rgba(0, 0, 0, 0.6)",
				}}
			>
				No shift pattern added
			</Typography>
		</Box>
	);
};

export default NoTeams;
