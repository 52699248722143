import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";

import { createArrayUpToNumber } from "../../../../../../utils/helpers";
import { Schedule } from "../../../teams/types";

type ShiftPatternMiniProps = {
	schedule: Schedule[];
	numberOfDays: number;
	teamId: number;
};
const ShiftPatternMini = (props: ShiftPatternMiniProps) => {
	const [formattedSchedules, setFormattedSchedules] = useState<Schedule[]>([]);
	useEffect(() => {
		try {
			if (props.schedule.length > 0) {
				const allDaysArray = createArrayUpToNumber(props.numberOfDays);
				const newFormattedSchedules: Schedule[] = [];
				allDaysArray.forEach((n: number) => {
					const foundSchedule: Schedule | undefined = props.schedule.find(
						(s: Schedule) => s.dayNo === n
					);
					if (foundSchedule) {
						newFormattedSchedules.push(foundSchedule);
					} else {
						newFormattedSchedules.push({
							dayNo: n,
							shiftTypeId: null,
							colorCode: "#E0E0E0",
						});
					}
				});
				setFormattedSchedules(newFormattedSchedules);
			}
		} catch (e) {
			// Do nothing
		}
	}, [props]);
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				justifyContent: "flex-start",
				minWidth: "170px",

				flexWrap: "wrap",
			}}
		>
			{formattedSchedules.map((value) => (
				<Box
					key={`schedule-${props.teamId}-${value.dayNo}`}
					sx={{
						height: "18px",
						width: "4px",
						backgroundColor: value.colorCode ? value.colorCode : "#E0E0E0",
						borderRadius: "2px",
						marginRight: "2px",
					}}
				/>
			))}
			{formattedSchedules && (
				<>
					{createArrayUpToNumber(
						props.numberOfDays - formattedSchedules.length
					).map((value: number) => {
						return (
							<Box
								key={`schedule-${props.teamId}-${value}-empty`}
								sx={{
									height: "18px",
									width: "4px",
									backgroundColor: "#E0E0E0",
									borderRadius: "2px",
									marginRight: "2px",
								}}
							/>
						);
					})}
				</>
			)}
		</Box>
	);
};

export default ShiftPatternMini;
