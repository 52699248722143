import escapeRegExp from "lodash/escapeRegExp";
import React from "react";

interface HighlightProps {
	value: string | number | null | undefined;
	searchText: string | number | null | undefined;
	color?: string;
}

const Highlight: React.FC<HighlightProps> = ({ value, searchText, color }) => {
	value = value ? value.toString() : "";
	searchText = searchText ? escapeRegExp(searchText.toString()) : "";

	if (!searchText || searchText.trim() === "" || searchText === "+") {
		return <span style={{ color: color ? "#E44B48" : "" }}>{value}</span>;
	}

	const regex = new RegExp(`(${searchText})`, "gi");
	const parts = value.split(regex);

	return (
		<span style={{ color: color ? "#E44B48" : "" }}>
			{parts.map((part, index) =>
				regex.test(part) ? (
					<span key={index} style={{ backgroundColor: "yellow" }}>
						{part}
					</span>
				) : (
					part
				)
			)}
		</span>
	);
};

export default Highlight;
