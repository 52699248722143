import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Box, Chip, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import React, { useRef } from "react";

import useOnView from "../../../../../../../utils/use-on-view";
import { distinctElements } from "../../../../helper";

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

interface AdditionalCertificationsProps {
	selectedCertifications?: number[];
	handleSelection?: (certId: number) => void;
	removeMultipleCerts?: (certIds: number[]) => void;
	additionalSkills?: any;
}

const AdditionalCertifications = ({
	selectedCertifications,
	removeMultipleCerts,
	handleSelection,
	additionalSkills,
}: AdditionalCertificationsProps) => {
	const [value, setValue] = React.useState<any[] | null>([]);

	const ref = useRef<HTMLDivElement>(null);
	const isIntersecting = useOnView(ref);

	// console.log("selectedCertifications", selectedCertifications, "value", value);
	return (
		<Box mt={2} mb={2}>
			<Box display='flex' alignItems='center'>
				<Box width={200}>
					{" "}
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 500,
							fontSize: "14px",
							color: "#000000",
							lineHeight: "24px",
							letterSpacing: "0.17px",
						}}
					>
						Additional Certifications
					</Typography>
				</Box>

				<Box ml={1} ref={ref}>
					<Autocomplete
						multiple
						id='tags-filled'
						options={additionalSkills}
						value={value ? value : []}
						getOptionLabel={(option: string | { name: string }) => {
							if (typeof option === "string") {
								return option;
							}
							return option.name;
						}}
						renderOption={(
							props,
							option: {
								id: number;
								name: string;
							},
							{ selected }
						) => {
							if (!isIntersecting) return null;
							return (
								<li {...props}>
									<Checkbox
										icon={icon}
										checkedIcon={checkedIcon}
										checked={selected}
									/>
									{option.name}
								</li>
							);
						}}
						onChange={(event, newValue, reason) => {
							const handleSelectionChange = (newSelectedValue: {
								id: number;
							}) => {
								if (handleSelection && newSelectedValue) {
									handleSelection(newSelectedValue.id);
								}
							};

							if (reason === "selectOption") {
								const selectedOption = distinctElements(
									value || [],
									newValue,
									"id"
								).pop();
								if (selectedOption) {
									handleSelectionChange(selectedOption);
									setValue([...(value || []), selectedOption]);
								}
								return;
							} else if (reason === "removeOption") {
								const removedItem = distinctElements(value, newValue, "id")[0];
								if (
									removedItem &&
									handleSelection &&
									selectedCertifications?.includes(removedItem.id)
								) {
									handleSelectionChange(removedItem);
									const filteredValue = (value || []).filter(
										(item) => item.id !== removedItem.id
									);
									setValue(filteredValue);
								}
								return;
							}
						}}
						onInputChange={(e, v, reason) => {
							if (reason === "clear") {
								const commonElements = selectedCertifications?.filter((cert) =>
									value?.map((item) => item.id).includes(cert)
								);
								if (removeMultipleCerts && commonElements) {
									removeMultipleCerts(commonElements || []);
								}
								setValue([]);
								return;
							}
						}}
						renderTags={(value: any, getTagProps) => <></>}
						disableCloseOnSelect
						renderInput={(params) => (
							<TextField
								{...params}
								variant='outlined'
								label='Select Certifications'
								// placeholder='Select Other Certifications'
							/>
						)}
						sx={{ width: 560 }}
						componentsProps={{
							popper: {
								style: {
									width: 560,
									display: isIntersecting ? "block" : "none",
								},
							},
						}}
					/>
				</Box>
			</Box>
			<Box maxWidth={840} display='flex' flexWrap='wrap' mt={1}>
				{value?.map((cert: any) => (
					<Box key={cert.id} mr={1} mt={1} mb={1}>
						<Chip
							key={cert.name}
							label={cert.name}
							onClick={() => handleSelection && handleSelection(cert.id)}
							sx={{
								margin: "8px 4px 8px 0",
								textWrap: "nowrap",
								overflow: "hidden",
								textOverflow: "ellipsis",
								maxWidth: "320px",
							}}
							color={
								selectedCertifications?.includes(cert.id)
									? "primary"
									: "default"
							}
							onDelete={() => {
								if (selectedCertifications?.includes(cert.id)) return;

								const newValue = value.filter(
									(item: any) => item.id !== cert.id
								);
								const removedItem = distinctElements(value, newValue, "id");
								if (
									removedItem &&
									handleSelection &&
									selectedCertifications?.includes(removedItem[0].id)
								) {
									handleSelection(removedItem[0].id);
								}
								setValue(newValue);
								return;
							}}
							deleteIcon={
								selectedCertifications?.includes(cert.id) ? (
									<CheckIcon />
								) : (
									<CancelIcon />
								)
							}
						/>
					</Box>
				))}
			</Box>
		</Box>
	);
};

export default AdditionalCertifications;
