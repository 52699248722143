import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Menu, Tooltip, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import * as React from "react";

import SkillValue from "../../design/skill-value";
import { truncateStringToFitBox } from "../../utils/helpers";

interface Skill {
	levelId: number;
	skillId: number;
	workerId: string;
}

interface SkillLevelDropdownProps {
	allSkillLevels: any[];
	skillName: string;
	skillId: number;
	handleSkill?: (skillId: number, level: number | string) => void;
	newAssignedSkills?: Skill[];
	removeSkill?: (skillId: number) => void;
}

const ITEM_HEIGHT = 60;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 180,
		},
	},
};

const SkillLevelDropdown: React.FC<SkillLevelDropdownProps> = ({
	skillName,
	skillId,
	allSkillLevels,
	handleSkill,
	newAssignedSkills,
	removeSkill,
}) => {
	const [level, setLevel] = React.useState<string | number>("");
	const [open, setOpen] = React.useState(false);

	const handleChange = (event: SelectChangeEvent<typeof level>) => {
		setLevel(event.target.value);
		handleSkill && handleSkill(skillId, event.target.value);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		setOpen(true);
	};

	const selectedSkill = allSkillLevels.find(
		(skillLevel: { id: number }) => skillLevel.id === level
	);

	React.useEffect(() => {
		const newSkill = newAssignedSkills?.find(
			(skill: { skillId: number }) => skill.skillId === skillId
		);
		if (newSkill) {
			setLevel(newSkill.levelId);
		}
	}, [newAssignedSkills, skillId]);

	return (
		<div>
			{" "}
			{open ? (
				<FormControl sx={{ width: "100%", height: "50px" }}>
					<InputLabel id='demo-controlled-open-select-label'>
						{truncateStringToFitBox(skillName, 25)}
					</InputLabel>
					<Select
						id='demo-controlled-open-select'
						open={open}
						onClose={handleClose}
						onOpen={handleOpen}
						value={level}
						label='level'
						onChange={handleChange}
						input={<OutlinedInput label={truncateStringToFitBox(skillName, 25)} />}
						sx={{ height: "50px" }}
						MenuProps={MenuProps}
						renderValue={(selected) => {
							const selectedSkillLevel = allSkillLevels.find(
								(skillLevel: { id: number }) => skillLevel.id === selected
							);
							return (
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
									}}
								>
									<Box>
										<SkillValue
											value={selectedSkillLevel.level}
											skillLevels={allSkillLevels}
											size='sm'
										/>
									</Box>
									<Typography
										ml={1}
										sx={{
											paddingLeft: "5px",
											fontSize: "14px",
											fontWeight: 400,
											color: "#00000099",
											fontFamily: "Roboto",
											lineHeight: "24px",
											letterSpacing: "0.17px",
											overflow: "hidden",
											textOverflow: "ellipsis",
											width: level ? "110px" : "125px",
										}}
									>
										{selectedSkillLevel.name}
									</Typography>
								</Box>
							);
						}}
					>
						{allSkillLevels.map((skillLevel: any) => (
							<MenuItem key={skillLevel.id} value={skillLevel.id}>
								<SkillValue
									value={skillLevel.level}
									skillLevels={allSkillLevels}
									size='lg'
								/>
								<Typography ml={1}>{skillLevel.name}</Typography>
							</MenuItem>
						))}
					</Select>
				</FormControl>
			) : (
				<Tooltip title={skillName}>
					<Box
						sx={{
							display: "flex",
							// width: "200px",
							width: "100%",
							height: "50px",
							backgroundColor: "#F8F8F8",
							alignItems: "center",
							cursor: "pointer",
							padding: "0px 5px 0px 5px",
							justifyContent: "space-between",
						}}
						onClick={handleOpen}
					>
						<Box display='flex' alignItems='center'>
							{level ? (
								<Box>
									<SkillValue
										value={selectedSkill.level}
										skillLevels={allSkillLevels}
										size='lg'
									/>
								</Box>
							) : (
								<Typography
									ml={1}
									sx={{
										paddingLeft: "5px",
										fontSize: "14px",
										fontWeight: 400,
										color: "#00000099",
									}}
								>
									--
								</Typography>
							)}
							<Typography
								sx={{
									paddingLeft: "5px",
									fontSize: "14px",
									fontWeight: 400,
									color: "#00000099",
									fontFamily: "Roboto",
									lineHeight: "40px",
									letterSpacing: "0.17px",
									overflow: "hidden",
									textOverflow: "ellipsis",
									width: level ? "195px" : "210px",
									height: "40px",
								}}
							>
								{truncateStringToFitBox(skillName, 25)}
							</Typography>
						</Box>
						<Box display='flex' alignItems='center'>
							{level && (<CloseIcon
								sx={{
									height: "20px",
									width: "20px",
									color: "#0000008F",
									"&:hover": {
										color: "#000000",
									},
								}}
								onClick={(e) => {
									if (handleSkill) {
										setLevel("");
										handleSkill(skillId, "");
										removeSkill && removeSkill(skillId);
										e.stopPropagation();
									}
								}}
							/>
							)}
							<ArrowDropDownIcon
								sx={{
									height: "20px",
									width: "20px",
									color: "#0000008F",
									"&:hover": {
										color: "#000000",
									},
								}}
							/>
						</Box>
					</Box>
				</Tooltip>
			)}
		</div>
	);
};

export default SkillLevelDropdown;
