import PageLayout from "@gigandtake/page-layout";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import React, {useEffect} from "react";
import {useDispatch} from "react-redux";

import {Calendar, NavDrawer} from "../../../export/drawer";
import {changeSelectedMenuItemId} from "../../../export/drawer";

const MiniDrawer = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        // To select Flex schedule menu item
        dispatch(changeSelectedMenuItemId(2));
    }, [dispatch]);

  return (
    <Box sx={{ display: "flex", backgroundColor: "#F5F5F5" }}>
      <CssBaseline />
      <NavDrawer keepSettingOpen={false} />
      <PageLayout>
        <Calendar />
      </PageLayout>
    </Box>
  );
};

export default MiniDrawer;
