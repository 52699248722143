export const colors = [
    {
        colorCode: "",
        colorName: "No color",
    },
    {
        colorCode: "EEEEEE",
        colorName: "Graphite",
    },
    { colorCode: "FCE4EC", colorName: "Flamingo" },
    {
        colorCode: "D1C4E9",
        colorName: "Grape",
    },
    {
        colorCode: "BBDEFB",
        colorName: "Blueberry",
    },
    {
        colorCode: "C8E6C9",
        colorName: "Sage",
    },
    {
        colorCode: "FFF9C4",
        colorName: "Banana",
    },
    {
        colorCode: "FFE0B2",
        colorName: "Tangerine",
    },
    {
        colorCode: "FECDD2",
        colorName: "Tomato",
    },
    {
        colorCode: "DCEDC8",
        colorName: "Peacock",
    },
    {
        colorCode: "B2DFDB",
        colorName: "Basil",
    },
];