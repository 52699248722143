/* eslint-disable no-mixed-spaces-and-tabs */
import { LoadingButton } from "@mui/lab";
import { Box, Button } from "@mui/material";
import React from "react";

interface ActionButtonsProps {
	handleClose: () => void;
	cancelShiftWithReasonStatus: string;
	cancelShiftForm: any;
	handleCancelShift: (shiftId: number) => void;
	shiftData: {
		id: number;
	};
}

const ActionButtons = ({
	handleClose,
	cancelShiftWithReasonStatus,
	cancelShiftForm,
	handleCancelShift,
	shiftData,
}: ActionButtonsProps) => {
	return (
		<Box mt={2} display='flex' justifyContent='flex-end'>
			<Button
				variant='text'
				color='primary'
				onClick={handleClose}
				sx={{ marginRight: "16px" }}
			>
				No
			</Button>
			<LoadingButton
				variant='contained'
				color='primary'
				loading={cancelShiftWithReasonStatus === "pending"}
				disabled={
					cancelShiftForm
						? cancelShiftForm.messageToWorker.length > 150 ||
						  cancelShiftForm.selectedReason === "" ||
						  (Number(cancelShiftForm.selectedReason) === -1 &&
								cancelShiftForm.otherReason === "")
						: true
				}
				onClick={() => {
					handleCancelShift && handleCancelShift(shiftData?.id);
				}}
			>
				Yes
			</LoadingButton>
		</Box>
	);
};

export default ActionButtons;
