import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Typography from "@mui/material/Typography";
import * as React from "react";


export default function MobileReviewShiftCard({item,state,handleShiftSelection}:any) {
  
  return (
    <Card sx={{ minWidth: 275,marginTop:2, borderLeft:`2px solid #${item.jobRoleColor}` }}>
      <CardContent>
        <FormControl>
            <FormGroup>
                <FormControlLabel
                    value={item.id}
                    control={<Checkbox />}
                    label={item.jobRole}
                    labelPlacement={item.jobRole}
                    checked={state.includes(item.id)}
                    onChange={(e)=>handleShiftSelection(e,item)}
                />
            </FormGroup>
        </FormControl>
        <Typography sx={{ mb: 1 }} color="text.secondary">
        {`${item.workersRequired} workers required`}
        </Typography>
        <Typography variant="body2">
        {item.shiftTime}
          <br />
          {`(${item.hoursScheduled})`}
        </Typography>
      </CardContent>
    </Card>
  );
}
