import { useEffect, useRef, useState } from "react";

import useViewport from "./use-viewport";

interface TableHeightResult {
	height: number;
	boxRef: React.MutableRefObject<HTMLDivElement | null>;
}

const useTableHeight = (): TableHeightResult => {
	const { height } = useViewport();
	const boxRef = useRef<HTMLDivElement | null>(null);
	const [y, setY] = useState(0);
	// This function calculate X and Y
	const getPosition = () => {
		try {
			const element = boxRef.current;
			if (element) {
				const y = element.offsetTop;
				setY(y);
			}
		} catch (e) {
			// Do nothing
		}
	};
	useEffect(() => {
		getPosition();
	}, []);
	// Re-calculate X and Y
	useEffect(() => {
		window.addEventListener("resize", getPosition);
	}, []);

	return {
		height: height ? height - y - 24 : 0,
		boxRef,
	};
};

export default useTableHeight;
