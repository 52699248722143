export const MIN_CROSS_SKILL_ROW_HEIGHT = "48px";
export const DONUT = "donut";
export const CIRCLE = "circle";
export const BOX = "box";
export const ALPHABET = "alphabet";
export const NUMBER = "number";
export const EMPTY = "empty";
export const QUARTER = "quarter";
export const HALF = "half";
export const THREE_QUARTER = "three-quarter"; // three-quarter
export const FULL = "full";
export const MANAGEMENT_REPORTING = "management-reporting";
export const COST_CENTER_REPORTING = "cost-center-reporting";
export const LOCATION_REPORTING = "location-reporting";