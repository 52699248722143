import React, { FC } from "react";

interface BadgeProps {
	status: string;
}

const Badge: FC<BadgeProps> = ({ status }) => {
	const statusColor = {
		Pending: "#70491C",
		Approved: "#002D9C",
		Rejected: "#750E13",
	};
	const backgroundColor = {
		Pending: "#FAF6CF",
		Approved: "#D0E2FF",
		Rejected: "#FFD7D9",
	};
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				width: "70px",
				height: "20px",
				padding: "2px 4px 2px 4px",
				borderRadius: "4px",
				color: statusColor[status as keyof typeof statusColor],
				fontSize: "12px",
				fontWeight: "bold",
				backgroundColor: backgroundColor[status as keyof typeof backgroundColor],
			}}
		>
			{status}
		</div>
	);
};

export default Badge;
