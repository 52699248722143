import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { updateCompanySettings } from "../../../../../../../export/gat-admin";
import useGatAdminConfigData from "../../../../../../../ts/components/gat-admin/controllers/use-gat-admin-config-data";
import { findDifferentKeys } from "../../../../utils";

const addOnObj: any = {
    flexSchedulingEnabled: "clientApp.flexSchedulingEnabled",
    crossSkillingEnabled: "clientApp.crossSkillingEnabled",
    skillPriorityEnabled: "skillPriority.Enabled",
    skillPriorityWorkerAppEnabled: "skillPriority.showOnWorkerApp",
    skillPriorityObsoleteSkillEnabled: "skillPriority.workerScore.useObsoleteSkill",
    certificationsEnabled: "clientApp.certificationsEnabled",
    skillExpiryEnabled: "clientApp.skillExpiryEnabled",
    workerProfileEnabled: "clientApp.workerProfileEnabled",
    swapAndCancelShift: "ftShift.swapAndCancelEnabled",
    attendanceManagement: "attendMgmt.enabled",
    shiftBySkillsEnabled: "shiftBySkills.enabled",
    shiftBySkillsMinSkillLevel: "shiftBySkills.minSkillLevel",
};

const initialAddOnFeatures = {
    flexSchedulingEnabled: false,
    crossSkillingEnabled: false,
    skillPriorityEnabled: false,
    skillPriorityWorkerAppEnabled: false,
    skillPriorityObsoleteSkillEnabled: false,
    certificationsEnabled: false,
    skillExpiryEnabled: false,
    shiftBySkillsEnabled: false,
    shiftBySkillsMinSkillLevel: 0,
    workerProfileEnabled: false,
    swapAndCancelShift: false,
    attendanceManagement: false,
};

const useAddOnController = () => {
    const dispatch = useDispatch();
    const {
        updateCompanySettingsLevelStatus,
        companySettingsObj,
        companyDataValue,
        updateCompanySettingsStatus,
    } = useGatAdminConfigData();

    const [addOnSettings, setAddOnSettings] = useState(initialAddOnFeatures);
    const [newAddOnSettings, setNewAddOnSettings] = useState<any>({});
    const [loading, setLoading] = useState(false);

    const handleSave = () => {
        setLoading(true);
        const diffKeys: any = findDifferentKeys(addOnSettings, newAddOnSettings);
        const changedSettings: any = diffKeys.map((key: any) => {
            return {
                ...companySettingsObj[addOnObj[key]],
                value: newAddOnSettings[key],
            };
        });
        if (changedSettings) {
            if (changedSettings.length > 0) {
                dispatch(updateCompanySettings(changedSettings));
            }
        }
    };

    useEffect(() => {
        if (Object.keys(companySettingsObj).length > 0) {
            const obj = {
                flexSchedulingEnabled: false,
                crossSkillingEnabled:
                    companySettingsObj["clientApp.crossSkillingEnabled"].value,
                skillPriorityEnabled:
                    companySettingsObj["skillPriority.Enabled"]?.value,
                skillPriorityWorkerAppEnabled: companySettingsObj["skillPriority.showOnWorkerApp"]?.value,
                skillPriorityObsoleteSkillEnabled: companySettingsObj["skillPriority.workerScore.useObsoleteSkill"]?.value,
                shiftBySkillsEnabled:
                    companySettingsObj["shiftBySkills.enabled"].value,
                shiftBySkillsMinSkillLevel:
                    companySettingsObj["shiftBySkills.minSkillLevel"].value,
                certificationsEnabled:
                    companySettingsObj["clientApp.certificationsEnabled"].value,
                skillExpiryEnabled:
                    companySettingsObj["clientApp.skillExpiryEnabled"].value,
                workerProfileEnabled:
                    companySettingsObj["clientApp.workerProfileEnabled"].value,
                swapAndCancelShift:
                    companySettingsObj["ftShift.swapAndCancelEnabled"].value,
                attendanceManagement: companySettingsObj["attendMgmt.enabled"].value,
            };
            setAddOnSettings(obj);
            setNewAddOnSettings(obj);
        }
    }, [companySettingsObj]);

    useEffect(() => {
        if (updateCompanySettingsStatus === "fulfilled") {
            setLoading(false);
        }
    }, [updateCompanySettingsStatus]);

    useEffect(() => {
        if (updateCompanySettingsLevelStatus === "fulfilled") {
            setLoading(false);
        }
    }, [updateCompanySettingsLevelStatus]);

    return [
        {
            addOnSettings,
            newAddOnSettings,
            companyDataValue,
            updateCompanySettingsLevelStatus,
            updateCompanySettingsStatus,
            loading,
        },
        {
            setNewAddOnSettings,
            handleSave,
        },
    ];
};

export default useAddOnController;
