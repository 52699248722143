/* eslint-disable no-mixed-spaces-and-tabs */
import { Box, Chip, TextField, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers-6/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers-6/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers-6/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers-6/TimePicker";
import { format } from "date-fns";
import dayjs from "dayjs";
import { Field, Form, Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import {
	createNewSchedule,
	editShiftSchedule,
} from "../../../../../../../../export/gat-admin";
import { getTimeForTimePicker } from "../../../../../../../../js/utils/helpers";
import ActionButtons from "./action-buttons";

interface ShiftScheduleFormProps {
	locationData: any; // Replace 'any' with the actual type
	closeScheduleRulesDialog: () => void;
	status: string;
	error: string;
	getDuration: (
		start: string,
		end: string,
		breakDuration: number
	) => string | number;
	isEdit: boolean;
	shiftScheduleData: any; // Replace 'any' with the actual type
}

const ShiftScheduleForm: React.FC<ShiftScheduleFormProps> = ({
	locationData,
	closeScheduleRulesDialog,
	status,
	error,
	getDuration,
	isEdit,
	shiftScheduleData,
}) => {
	const dispatch = useDispatch();

	let initialValues = {
		scheduleName: "",
		startTime: "",
		endTime: "",
		breakDuration: 0,
		companyId: locationData.companyId,
		locationId: locationData?.id,
		id: "",
	};
	if (isEdit && shiftScheduleData) {
		initialValues = {
			scheduleName: shiftScheduleData.name,
			startTime: shiftScheduleData.startTime.slice(0, 5),
			endTime: shiftScheduleData.endTime.slice(0, 5),
			breakDuration: shiftScheduleData.unpaidBreakMins,
			companyId: locationData.companyId,
			id: shiftScheduleData.id,
			locationId: locationData?.id,
		};
	}

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={Yup.object({
				scheduleName: Yup.string().required("Required"),
				startTime: Yup.string().required("Required"),
				endTime: Yup.string().required("Required"),
				breakDuration: Yup.number()
					.typeError("Break duration must be a number")
					.min(0, "Must be positive or zero")
					.max(120, "Must be less than 120"),
			})}
			onSubmit={(values: any, { setSubmitting }) => {
				if (isEdit) dispatch(editShiftSchedule(values));
				else dispatch(createNewSchedule(values));
				setSubmitting(false);
			}}
		>
			{({ isSubmitting, values, dirty, isValid, errors, setFieldValue }) => (
				<Form>
					<Box width={860} mb={4}>
						<Box width={270} mb={2} mt={1}>
							<Field
								component={TextField}
								name='scheduleName'
								label='Schedule Name'
								type='text'
								variant='filled'
								fullWidth
								value={values.scheduleName}
								onChange={(e: { target: { value: string } }) =>
									setFieldValue("scheduleName", e.target.value)
								}
							/>
						</Box>
						<Box mb={1} display='flex' alignItems='center'>
							<Box width={270}>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DemoContainer components={["TimePicker"]}>
										<TimePicker
											label='Start Time'
											format='hh:mm a'
											slotProps={{
												textField: {
													variant: "filled",
													InputLabelProps: {
														shrink: true,
													},
													sx: {
														minWidth: "270px !important",
													},
												},
												popper: {
													sx: {
														"& .MuiMultiSectionDigitalClockSection-root": {
															width: "90px",
														},
													},
												},
											}}
											onChange={(newValue) => {
												try {
													if (JSON.stringify(newValue).length !== 4) {
														setFieldValue(
															"startTime",
															getTimeForTimePicker(newValue).slice(0, 5)
														);
													} else if (newValue === null) {
														setFieldValue("startTime", "");
													}
												} catch (e) {
													console.log("Having error", e);
												}
											}}
											value={
												values.startTime
													? dayjs(
															`${format(new Date(), "yyyy-MM-dd")}T${
																values.startTime
															}`
															// eslint-disable-next-line no-mixed-spaces-and-tabs
													  )
													: undefined
											}
											disabled={isEdit}
										/>
									</DemoContainer>
								</LocalizationProvider>
							</Box>

							{/* <Typography>-</Typography> */}
							<Box ml={2} width={270}>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DemoContainer components={["TimePicker"]}>
										<TimePicker
											label='End Time'
											format='hh:mm a'
											slotProps={{
												textField: {
													variant: "filled",
													InputLabelProps: {
														shrink: true,
													},
													sx: {
														minWidth: "270px !important",
													},
												},
												popper: {
													sx: {
														"& .MuiMultiSectionDigitalClockSection-root": {
															width: "90px",
														},
													},
												},
											}}
											onChange={(newValue) => {
												try {
													if (JSON.stringify(newValue).length !== 4) {
														setFieldValue(
															"endTime",
															getTimeForTimePicker(newValue).slice(0, 5)
														);
													} else if (newValue === null) {
														setFieldValue("endTime", "");
													}
												} catch (e) {
													console.log("Having error", e);
												}
											}}
											value={
												values.endTime
													? dayjs(
															`${format(new Date(), "yyyy-MM-dd")}T${
																values.endTime
															}`
															// eslint-disable-next-line no-mixed-spaces-and-tabs
													  )
													: undefined
											}
											disabled={isEdit}
										/>
									</DemoContainer>
								</LocalizationProvider>
							</Box>
							<Box ml={1}>
								<Typography
									sx={{
										fontFamily: "Roboto",
										fontWeight: 400,
										fontSize: "12px",
									}}
								>
									Shift Duration
								</Typography>
								<Typography
									sx={{
										fontFamily: "Roboto",
										fontWeight: 400,
										fontSize: "16px",
									}}
								>
									{values.startTime && values.endTime
										? getDuration(
												values.startTime,
												values.endTime,
												values.breakDuration
										  )
										: 0}{" "}
									hours
								</Typography>
							</Box>
						</Box>
					</Box>

					<Box display='flex' alignItems='center'>
						<Box width={270}>
							<Field
								component={TextField}
								name='breakDuration'
								label='Unpaid Break in mins (optional)'
								type='number'
								variant='filled'
								onChange={(e: { target: { value: number } }) =>
									setFieldValue(
										"breakDuration",
										parseInt(`${e.target.value}`) ?? 0
									)
								}
								value={values.breakDuration}
								fullWidth
								helperText={
									errors.breakDuration ||
									"This time would be included within the shift"
								}
								error={!!errors.breakDuration}
								sx={{
									"& .MuiFormHelperText-root": {
										marginLeft: 0,
									},
								}}
							/>
						</Box>
					</Box>
					<ActionButtons
						closeScheduleRulesDialog={closeScheduleRulesDialog}
						isSubmitting={isSubmitting}
						//onSubmit={onSubmit}
						isValid={isValid}
						dirty={dirty}
						status={status}
					/>
				</Form>
			)}
		</Formik>
	);
};

export default ShiftScheduleForm;
