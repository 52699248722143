import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import { useDispatch } from "react-redux";

import { createDraftShiftAsync } from "../../../../../../export/scheduler";
import { JOB, SKILLS } from "../../../../../assets/constants";
import Button from "../../../../../design/button";
import { CopyShiftsDataBySkillsProps, CopyShiftsDataProps } from "./types";

interface ActionButtonsProps {
	handleClose: () => void;
	copiedShiftData: CopyShiftsDataProps[];
	copiedShiftDataBySkills: CopyShiftsDataBySkillsProps[];
	shiftsByFilter: string;
	createDraftShiftAsyncStatus: string;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({
	handleClose,
	copiedShiftData,
	copiedShiftDataBySkills,
	shiftsByFilter,
	createDraftShiftAsyncStatus,
}) => {
	const dispatch = useDispatch();
	const disabled =
		(shiftsByFilter === JOB && copiedShiftData.length === 0) ||
		(shiftsByFilter === SKILLS && copiedShiftDataBySkills.length === 0) ||
		createDraftShiftAsyncStatus === "pending";
	return (
		<Box
			display='flex'
			width='100%'
			justifyContent='flex-end'
			alignItems='center'
		>
			{" "}
			<Box ml={2} display='flex' justifyContent='space-between' width='220px'>
				<Button color='primary' variant='text' onClick={handleClose}>
					Cancel
				</Button>
				{/* <LoadingButton
					loading={createDraftShiftAsyncStatus === "pending"}
					variant='contained'
					color='primary'
					type='submit'
					disabled={disabled}
					onClick={() => {
						let data: CopyShiftsDataProps[] | CopyShiftsDataBySkillsProps[] =
							[];
						if (shiftsByFilter === JOB) {
							data = copiedShiftData;
						} else if (shiftsByFilter === SKILLS) {
							data = copiedShiftDataBySkills;
						}
						dispatch(
							createDraftShiftAsync({ copiedShiftData: data, copiedOn: true })
						);
					}}
					sx={{ letterSpacing: "0.02857em" }}
				>
					Copy Shifts
				</LoadingButton> */}
				<Button
					color='primary'
					type='submit'
					disabled={disabled}
					onClick={() => {
						let data: CopyShiftsDataProps[] | CopyShiftsDataBySkillsProps[] =
							[];
						if (shiftsByFilter === JOB) {
							data = copiedShiftData;
						} else if (shiftsByFilter === SKILLS) {
							data = copiedShiftDataBySkills;
						}
						dispatch(
							createDraftShiftAsync({ copiedShiftData: data, copiedOn: true })
						);
					}}
				>
					{createDraftShiftAsyncStatus === "pending" ? (
						<div style={{ position: "relative" }}>
							<CircularProgress
								size={25}
								style={{ position: "absolute", left: "50%" }}
							/>{" "}
							Copying Shifts
						</div>
					) : (
						"Copy Shifts"
					)}
				</Button>
			</Box>
		</Box>
	);
};

export default ActionButtons;
