import {useConfirm} from "material-ui-confirm";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
	closeEditTeamDialog,
	openEditTeamDialog, removeShiftPattern,
} from "../../../store/gat-admin-slice";
import { getAllTeamsByLocation } from "../../../store/gat-admin-slice";
import {Schedule, Team} from "../../../store/gat-admin-slice-types";
import { Schedule as LocalSchedule } from "../types";
import useTeamsData from "./use-teams-data";

const useTeamsController = () => {
	const dispatch = useDispatch();
	const confirm = useConfirm();

	const [isTeamDialogOpen, setIsTeamDialogOpen] = useState(false);
	const {
		teams,
		locationId,
		companyId,
		createNewTeamStatus,
		createNewTeamError,
		editTeamData,
		editTeamError,
		editTeamStatus,
		allShiftSchedules,
		getShiftSchedulesStatus,
		shiftPatternRemoveStatus,
		shiftPatternRemoveErrorMessage,
	} = useTeamsData();

	const openEditTeam = (team: Team) => {
		setIsTeamDialogOpen(false);
		dispatch(openEditTeamDialog(team));
	};

	const deletePattern = (id: number, hasWorkersAssigned: boolean) => {
		if (hasWorkersAssigned) {
			// Block admin to delete pattern when this flag is true
			confirm({
				title: "Sorry",
				description: "",
				content: "This shift pattern cannot be deleted as workers are assigned to it.",
			})
				.then(() => {
					/* Do nothing */
				})
				.catch(() => {
					/* Do nothing */
				});
		} else {
			// Confirm delete pattern
			confirm({
				title: "Are you sure?",
				description: "",
				content: "Are you sure you want to delete this shift pattern?",
			})
				.then(() => {
					// Delete pattern
					if (shiftPatternRemoveStatus !== "pending") {
						dispatch(removeShiftPattern(id));
					}
				})
				.catch(() => {
					/* Do nothing */
				});
		}

	};

	const closeEditTeam = () => {
		dispatch(closeEditTeamDialog());
	};

	const getSchedule = (apiSchedules: Schedule[]) => {
		try {
			const returnValue: LocalSchedule[] = [];
			apiSchedules.forEach((value) => {
				const selectedShiftSchedule = allShiftSchedules.find(
					(shiftSchedule: Schedule) => shiftSchedule.id === value.shiftTypeId
				);
				const colorCode = selectedShiftSchedule
					? selectedShiftSchedule.colorCode
					: undefined;
				returnValue.push({
					dayNo: value.dayNo,
					shiftTypeId: value.shiftTypeId,
					colorCode: colorCode,
				});
			});
			return returnValue;
		} catch (e) {
			return [];
		}
	};

	useEffect(() => {
		if (locationId) dispatch(getAllTeamsByLocation(locationId));
	}, [dispatch, locationId]);

	useEffect(() => {
		if (
			(createNewTeamStatus === "fulfilled" || editTeamStatus === "fulfilled") &&
			locationId
		) {
			setIsTeamDialogOpen(false);
			dispatch(getAllTeamsByLocation(locationId));
		}
	}, [createNewTeamStatus, editTeamStatus, dispatch, locationId]);

	return [
		{
			isTeamDialogOpen,
			teams,
			createNewTeamStatus,
			createNewTeamError,
			editTeamData,
			editTeamError,
			editTeamStatus,
			getShiftSchedulesStatus,
			shiftPatternRemoveStatus,
			shiftPatternRemoveErrorMessage,
		},
		{ setIsTeamDialogOpen, openEditTeam, closeEditTeam, getSchedule, deletePattern },
	] as const;
};

export default useTeamsController;
