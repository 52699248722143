import { Box } from "@mui/material";
import React from "react";

import SortedDatagridPro6 from "../../../../../../design/sorted-datagrid-pro-6";
import useTeamsColumn from "../../controllers/use-teams-column";
import { TeamsColumnProps } from "../../types";
import NoTeams from "./no-teams";

interface TeamsTableProps extends TeamsColumnProps {
	teams: any;

	shiftPatternRemoveStatus: string;
}

const TeamsTable = ({
	getSchedule,
	openEditTeam,
	deletePattern,
	teams,

	shiftPatternRemoveStatus,
}: TeamsTableProps) => {
	const { columns } = useTeamsColumn({
		openEditTeam,
		getSchedule,
		deletePattern,
	});
	return (
		<Box mt={3} style={{ height: teams.length > 0 ? "auto" : 300 }}>
			{teams.length === 0 ? (
				<NoTeams />
			) : (
				<SortedDatagridPro6
					columns={columns}
					rows={teams}
					disableRowSelectionOnClick
					disableColumnSelector
					disableDensitySelector
					disableColumnPinning
					disableColumnReorder
					disableColumnMenu
					pagination
					initialState={{
						...teams,
						pagination: { paginationModel: { pageSize: 10 } },
						filter: {},
					}}
					pageSizeOptions={[10, 25, 50]}
					getRowHeight={() => 40}
					loading={shiftPatternRemoveStatus === "pending"}
					sx={{
						border: "none !important",

						"&>.MuiDataGrid-main": {
							"& div div div div >.MuiDataGrid-cell": {
								borderBottom: "none",
							},
						},

						"& .MuiDataGrid-row": {
							border: "none !important",
						},

						".MuiDataGrid-cell": {
							minHeight: "42px !important",
							"&:focus": {
								outline: "none",
							},
							"&.hover-column-cell": {
								position: "sticky",
								right: 24,
								zIndex: 1,
							},
							"& .hover-content": {
								transition: "opacity 0.3s ease-in-out",
								opacity: 0,
							},
						},
						".MuiDataGrid-cell:focus-within": {
							outline: "none",
						},
						"& .MuiDataGrid-columnHeader:focus-within": {
							outline: "none",
						},
						".MuiDataGrid-row:hover .hover-column-cell .hover-content": {
							opacity: 1,
							backgroundColor: "#f5f5f5",
						},
					}}
				/>
			)}
		</Box>
	);
};

export default TeamsTable;
