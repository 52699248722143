import { Box, Typography } from "@mui/material";
import { format } from "date-fns";
import React from "react";

const ShiftPatternInfo = ({
	name,
	duration,
	startDate,
	endDate,
}: {
	name: string;
	duration: number;
	startDate: string;
	endDate: string;
}) => {
	return (
		<Box display='flex'>
			<Box width={200} display='flex' flexDirection='column'>
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontStyle: "normal",
						fontWeight: 400,
						fontSize: "12px",
						lineHeight: "12px",
						letterSpacing: "0.15000000596046448px",
						textAlign: "left",
						color: "#00000099",
					}}
				>
					Shift pattern name
				</Typography>
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontStyle: "normal",
						fontWeight: 400,
						fontSize: "16px",
						lineHeight: "24px",
						letterSpacing: "0.15000000596046448px",
						textAlign: "left",
						color: " #000000DE",
					}}
				>
					{name}
				</Typography>
			</Box>
			<Box width={200} display='flex' flexDirection='column'>
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontStyle: "normal",
						fontWeight: 400,
						fontSize: "12px",
						lineHeight: "12px",
						letterSpacing: "0.15000000596046448px",
						textAlign: "left",
						color: "#00000099",
					}}
				>
					Pattern duration
				</Typography>
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontStyle: "normal",
						fontWeight: 400,
						fontSize: "16px",
						lineHeight: "24px",
						letterSpacing: "0.15000000596046448px",
						textAlign: "left",
					}}
				>
					{duration} days
				</Typography>
			</Box>
			<Box width={200} display='flex' flexDirection='column'>
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontStyle: "normal",
						fontWeight: 400,
						fontSize: "12px",
						lineHeight: "12px",
						letterSpacing: "0.15000000596046448px",
						textAlign: "left",
						color: "#00000099",
					}}
				>
					Start date
				</Typography>
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontStyle: "normal",
						fontWeight: 400,
						fontSize: "16px",
						lineHeight: "24px",
						letterSpacing: "0.15000000596046448px",
						textAlign: "left",
					}}
				>
					{format(new Date(startDate), "d MMM yyyy")}
				</Typography>
			</Box>
			<Box width={200} display='flex' flexDirection='column'>
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontStyle: "normal",
						fontWeight: 400,
						fontSize: "12px",
						lineHeight: "12px",
						letterSpacing: "0.15000000596046448px",
						textAlign: "left",
						color: "#00000099",
					}}
				>
					End date
				</Typography>
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontStyle: "normal",
						fontWeight: 400,
						fontSize: "16px",
						lineHeight: "24px",
						letterSpacing: "0.15000000596046448px",
						textAlign: "left",
					}}
				>
					{endDate ? format(new Date(endDate), "d MMM yyyy") : "-"}
				</Typography>
			</Box>
		</Box>
	);
};

export default ShiftPatternInfo;
