// TODO - Type 'any' needs to be fixed.
import Box from "@mui/material/Box";
//import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
//import { PickersDay } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import enGB from "date-fns/locale/en-GB";
import React from "react";

// const CustomPickersDay = styled(PickersDay, {
// 	shouldForwardProp: (prop) => prop !== "selected",
// })(({ selected }) => ({
// 	...(selected && {
// 		backgroundColor: "rgba(47, 77, 139, 0.08)",
// 	}),
// }));

function DayDatePicker({ currentDate, handleCalendarClick }:any) {
	const [value, setValue] = React.useState(new Date(currentDate));

	// console.log("currentDate", currentDate, value);

	// const renderPickerDay = (date, selectedDates, pickersDayProps) => {
	// 	// console.log("pickersDayProps", pickersDayProps);
	// 	if (!value) {
	// 		return <PickersDay {...pickersDayProps} />;
	// 	}

	// 	return <CustomPickersDay {...pickersDayProps} disableMargin />;
	// };

	const handleChange = (newValue:any) => {
		setValue(newValue);
		handleCalendarClick(new Date(newValue));
	};

	return (
		<Box
			sx={{
				".MuiCalendarPicker-root": {
					margin: 0,
				},
				".MuiButtonBase-root-MuiPickersDay-root.Mui-selected": {
					backgroundColor: "red",
				},
			}}
		>
			<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
				<StaticDatePicker
					displayStaticWrapperAs='desktop'
					openTo='day'
					value={value}
					onChange={(newValue) => handleChange(newValue)}
					renderInput={(params) => <TextField {...params} />}
				/>
			</LocalizationProvider>
		</Box>
	);
}

export default DayDatePicker;
