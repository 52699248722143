import Button from "@gigandtake/button";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Papa from "papaparse";
import React from "react";

const buttonProps: any = {
    color: "primary",
    onClick: () => {/* Do Nothing */ },
    //   customStyles: { textTransform: "none" },
    variant: "contained",
};

const convertToCSV = (data: any) => {
    const csv = Papa.unparse(data);
    return csv;
};

const ExportButton = ({ data }: any) => {
    const handleExport = () => {
        const csv = convertToCSV(data);

        // Create a Blob with the CSV data and set it as the URL for a temporary anchor element
        const blob = new Blob([csv], { type: "text/csv" });
        const url = URL.createObjectURL(blob);

        // Create a temporary anchor element and trigger a click to download the file
        const a = document.createElement("a");
        a.href = url;
        a.download = "data.csv";
        a.click();

        // Clean up the temporary URL
        URL.revokeObjectURL(url);
    };

    return (
        <Button
            {...{
                ...buttonProps,
                variant: "outlined",
                iconBefore: <FileDownloadOutlinedIcon />,
                disabled: data.length === 0,
                onClick: () => handleExport(),
            }}
        >
            export
        </Button>
    );
};

export default ExportButton;
