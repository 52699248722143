import WorkOffOutlinedIcon from "@mui/icons-material/WorkOffOutlined";
import { Box, Stack, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { DataGridPro } from "@mui/x-data-grid-pro";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import MyChip from "./my-chip";


interface GridColumn {
    field: string;
    type?: string;
    width?: number;
    minWidth?: number;
    flex?: number;
    headerName?: string;
    renderHeader?: () => React.ReactNode;
    renderCell?: (params: { row: any }) => React.ReactNode;
    valueGetter?: (params: { row: any }) => any;
    headerAlign?: "left" | "center" | "right";
    sortable?: boolean;
    getActions?: (params: { row: any }) => any[];
    hide?: boolean
}

const columns: GridColumn[] = [
    { field: "id", headerName: "ID", width: 70, hide: true },
    {
        field: "name",
        headerName: "Location",
        minWidth: 240,
        renderCell: (params) => (
            <Tooltip title={params.row.name}>
                <Typography
                    sx={{
                        fontFamily: "Roboto",
                        fontSize: "14px",
                        color: "rgba(0, 0, 0, 0.87)",
                    }}
                >
                    {params.row.name}
                </Typography>
            </Tooltip>
        ),
    },
    {
        field: "wcCount",
        headerName: "WC Count",
        minWidth: 100,
        headerAlign: "center",
        renderCell: (params) => {
            return (
                <span
                    style={{
                        fontFamily: "Roboto",
                        fontSize: "14px",
                        textAlign: "center",
                        width: "100%",
                    }}
                >
                    {params.row.wcCount}
                </span>
            );
        },
    },
    {
        field: "workCenters",
        headerName: "Workcenters associated with the locations",
        flex: 1,
        minWidth: 600,
        renderCell: (params) => {
            return (
                <Stack
                    direction='row'
                    spacing={1}
                    paddingTop={1}
                    paddingBottom={1}
                    minHeight='45px'
                    alignItems='center'
                    style={{ flexWrap: "wrap" }}
                >
                    {params.row.workCenters.map((wc: any, index: number) => (
                        <Box
                            key={`work-center-${wc.id}-${params.row.id}-${index}`}
                            onClick={() => params.row.changeWorkCenterData(wc)}
                            sx={{ cursor: "pointer" }}
                        >
                            <MyChip label={wc.name} />
                        </Box>
                    ))}
                </Stack>
            );
        },
    },
];

const useStyles = makeStyles(() => ({
    dataGridRoot: {
        border: "none",
    },
    dataGridTable: {
        border: "none",
    },
    dataGridCell: {
        border: "none",
    },
}));

const NUMBER_OF_ROWS_PER_PAGE = 5;

interface IWorkCenterTableProps {
    locations: any
    setWorkCenterData: React.Dispatch<React.SetStateAction<any>>
    recentlyUpdatedLocationId: any
}

const WorkCenterTable: React.FC<IWorkCenterTableProps> = ({
    locations,
    setWorkCenterData,
    recentlyUpdatedLocationId,
}) => {
    // const locations = useSelector(
    // 	(state) => state.gatAdminConfiguration.locations
    // );

    const classes = useStyles();
    const [pageSize, setPageSize] = useState(NUMBER_OF_ROWS_PER_PAGE);
    const getLocationsStatus = useSelector(
        (state: any) => state.gatAdminConfiguration.getLocations.status
    );
    const getLocationsErrorMessage = useSelector(
        (state: any) => state.gatAdminConfiguration.getLocations.errorMessage
    );
    const [locationsArray, setLocationsArray] = useState<any>([]);

    const [page, setPage] = useState(0);

    useEffect(() => {
        if (recentlyUpdatedLocationId !== 0) {
            locationsArray.forEach((location: any, index: number) => {
                if (parseInt(location.id) === parseInt(recentlyUpdatedLocationId)) {
                    setPage(Math.floor(index / NUMBER_OF_ROWS_PER_PAGE));
                }
            });
        }
    }, [recentlyUpdatedLocationId, locationsArray]);

    useEffect(() => {
        try {
            // Injecting setLocationData into data
            const newLocations: any = [];
            if (locations.length > 0) {
                locations.forEach((location: any) => {
                    newLocations.push({
                        ...location,
                        wcCount: location.workCenters.length,
                        changeWorkCenterData: (value: any) => setWorkCenterData(value),
                    });
                });
                newLocations.sort((a: any, b: any) => {
                    return -(a.id - b.id); // Latest id first
                });
                setLocationsArray(newLocations);
            } else {
                setLocationsArray([]);
            }
        } catch (e) {
            // Do nothing
        }
    }, [locations, setWorkCenterData]);

    return (
        <Box sx={{ width: "100%" }} mt={2}>
            <DataGridPro
                getRowId={(rows: any) => rows.id}
                disableColumnMenu
                columns={columns}
                rows={locationsArray}
                loading={getLocationsStatus === "pending"}
                classes={{
                    root: classes.dataGridRoot,
                    // table: classes.dataGridTable,
                    cell: classes.dataGridCell,
                }}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                pagination
                rowsPerPageOptions={[NUMBER_OF_ROWS_PER_PAGE]}
                getRowHeight={() => "auto"}
                page={page}
                onPageChange={(newPage) => {
                    setPage(newPage);
                }}
                sx={{
                    height: "387px",
                    "& .MuiDataGrid-virtualScroller": {
                        overflowX: "hidden",
                    },
                }}
                components={{
                    NoRowsOverlay: () => (
                        <Stack height='100%' alignItems='center' justifyContent='center'>
                            <Box
                                sx={{
                                    position: "relative",
                                    height: "32px",
                                    width: "32px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <WorkOffOutlinedIcon
                                    sx={{
                                        width: "24px",
                                        height: "24px",
                                        color: "rgba(0, 0, 0, 0.38)",
                                    }}
                                />
                            </Box>
                            <Typography
                                sx={{
                                    fontFamily: "Roboto",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    color: "rgba(0, 0, 0, 0.38)",
                                    marginTop: "4px",
                                    textAlign: "center",
                                }}
                            >
                                {getLocationsErrorMessage !== ""
                                    ? "Error: " + getLocationsErrorMessage
                                    : "No Workcenter added"}
                            </Typography>
                        </Stack>
                    ),
                }}
            />
        </Box>
    );
};

export default WorkCenterTable;
