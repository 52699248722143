// import Button from "@gigandtake/button";
import { LoadingButton } from "@mui/lab";
import { Box, Button, CircularProgress } from "@mui/material";
import React from "react";

const CreateLocationButton = ({ data, closeLocationForm, onClick, status }:any) => {

	return (
		<Box display='flex' justifyContent='flex-end' mt={2} mb={1.75}>
			<Box mr={1}>
				<Button variant='outlined' onClick={closeLocationForm}>
					Cancel
				</Button>
			</Box>
			<Box ml={1}>
				<LoadingButton
					variant='contained'
					onClick={onClick}
					disabled={!data.name || !data.timeZone}
					loading={status === "pending"}
					loadingIndicator={
						<CircularProgress size={16} sx={{ color: "rgba(0, 0, 0, 0.38)" }} />
					}
				>
					Create & Continue
				</LoadingButton>
			</Box>
		</Box>
	);
};

export default CreateLocationButton;
