import {
	Box,
	Grid,
	MenuItem,
	Select,
	Switch,
	TextField,
	Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import React from "react";

import { COUNTRIES } from "../../../constants/countries";
import SectionWrapper from "../../../section-wrapper";
import CreateLocationButton from "../../location/location-settings/create-location-button";
import SectionActions from "../../section-actions";
import useEssentialLocationController from "./controllers/use-essential-location-controller";

interface IEssentialLocationDetailsProps {
	locationData: any
	creatingNewLocation: boolean
	closeLocationForm: any
}
const EssentialLocationDetails: React.FC<IEssentialLocationDetailsProps> = ({
	locationData,
	creatingNewLocation,
	closeLocationForm,
}) => {
	const [
		{
			essentialLocationData,
			newEssentialLocationData,
			timeZones,
			pocError,
			selectedCountry,
			countryInputValue,
			loading,
			createNewLocationStatus,
		},
		{
			setNewEssentialLocationData,
			validateContacts,
			setSelectedCountry,
			setCountryInputValue,
			handleLocationSave,
			setLoading,
		},
	]: any = useEssentialLocationController(locationData, creatingNewLocation);

	if (Object.keys(newEssentialLocationData).length === 0) return null;
	return (
		<SectionWrapper title='Essential Location Details' subtitle={undefined} >
			<Grid
				container
				sx={{
					paddingTop: "12px",
					paddingBottom: "16px",
				}}
			>
				<Grid item xs={8} pr={1}>
					<TextField
						label='Location Name (required)'
						value={newEssentialLocationData.name ?? ""}
						inputProps={{ maxLength: 100 }}
						onChange={(e) =>
							setNewEssentialLocationData({
								...newEssentialLocationData,
								name: e.target.value,
							})
						}
						variant='filled'
						fullWidth
					/>
				</Grid>

				<Grid item xs={4} pl={0.5}>
					{timeZones?.length > 0 && (
						<FormControl variant='filled' fullWidth>
							<InputLabel id='location-timezone-select'>
								Select a time zone (required)
							</InputLabel>
							<Select
								labelId='location-timezone-select'
								onChange={(e) =>
									setNewEssentialLocationData({
										...newEssentialLocationData,
										timeZone: e.target.value,
									})
								}
								value={newEssentialLocationData.timeZone ?? ""}
								variant='filled'
							>
								{timeZones.map((timeZone: any) => (
									<MenuItem key={`timezone-${timeZone.id}`} value={timeZone.id}>
										{timeZone.displayName}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}
				</Grid>
			</Grid>
			<Grid
				container
				sx={{
					paddingTop: "16px",
					//paddingLeft: "21px",
					//paddingRight: "21px",
					paddingBottom: "16px",
				}}
			>
				<Grid item xs={12}>
					<TextField
						label='Address Line 1'
						value={newEssentialLocationData.address1 ?? ""}
						inputProps={{ maxLength: 100 }}
						onChange={(e) =>
							setNewEssentialLocationData({
								...newEssentialLocationData,
								address1: e.target.value,
							})
						}
						variant='filled'
						fullWidth
					/>
				</Grid>
			</Grid>
			<Grid
				container
				sx={{
					paddingTop: "16px",
					//paddingLeft: "21px",
					//paddingRight: "21px",
					paddingBottom: "16px",
				}}
			>
				<Grid item xs={4} pr={1}>
					<TextField
						label='City'
						value={newEssentialLocationData.city ?? ""}
						inputProps={{ maxLength: 50 }}
						onChange={(e) =>
							setNewEssentialLocationData({
								...newEssentialLocationData,
								city: e.target.value,
							})
						}
						variant='filled'
						fullWidth
					/>
				</Grid>
				<Grid item xs={4} pr={0.5} pl={0.5}>
					<TextField
						label='State'
						value={newEssentialLocationData.state ?? ""}
						inputProps={{ maxLength: 25 }}
						onChange={(e) =>
							setNewEssentialLocationData({
								...newEssentialLocationData,
								state: e.target.value,
							})
						}
						variant='filled'
						fullWidth
					/>
				</Grid>
				<Grid item xs={4} pl={1}>
					<TextField
						label='Zip'
						value={newEssentialLocationData.zip ?? ""}
						inputProps={{ maxLength: 10 }}
						onChange={(e) =>
							setNewEssentialLocationData({
								...newEssentialLocationData,
								zip: e.target.value,
							})
						}
						variant='filled'
						fullWidth
					/>
				</Grid>
			</Grid>
			<Grid
				container
				sx={{
					paddingTop: "16px",
					//paddingLeft: "21px",
					//paddingRight: "21px",
					paddingBottom: "16px",
				}}
			>
				<Grid item xs={6} pr={0.75}>
					<TextField
						label='County'
						value={newEssentialLocationData.county ?? ""}
						inputProps={{ maxLength: 100 }}
						onChange={(e) =>
							setNewEssentialLocationData({
								...newEssentialLocationData,
								county: e.target.value,
							})
						}
						variant='filled'
						fullWidth
					/>
				</Grid>
				<Grid item xs={6} pl={0.75}>
					<Autocomplete
						disablePortal
						id='location-country-select'
						options={COUNTRIES}
						getOptionLabel={(option) => option.name}
						isOptionEqualToValue={(option, value) => option.name === value.name}
						renderInput={(params) => (
							<TextField
								{...params}
								label='Country (required)'
								variant='filled'
								fullWidth
							/>
						)}
						value={selectedCountry}
						// inita
						onChange={(event, newValue) => {
							setSelectedCountry(newValue);
							if (newValue) {
								setNewEssentialLocationData({
									...newEssentialLocationData,
									country: newValue.name,
								});
							} else {
								setNewEssentialLocationData({
									...newEssentialLocationData,
									country: "",
								});
							}
							validateContacts(
								newEssentialLocationData.companyContactForWorker,
								newValue
							);
						}}
						inputValue={countryInputValue}
						onInputChange={(event, newInputValue) => {
							setCountryInputValue(newInputValue);
							const country = COUNTRIES.find(
								(country: any) => country.name === newInputValue
							);
							if (country) {
								setSelectedCountry(country);
								setNewEssentialLocationData({
									...newEssentialLocationData,
									country: country.name,
								});
							} else {
								setSelectedCountry({});
								setNewEssentialLocationData({
									...newEssentialLocationData,
									country: "",
								});
							}
							validateContacts(
								newEssentialLocationData.companyContactForWorker,
								country
							);
						}}
					/>
				</Grid>
			</Grid>
			<Grid
				container
				sx={{
					paddingTop: "16px",
					//	paddingLeft: "21px",
					//paddingRight: "21px",
					paddingBottom: "16px",
				}}
			>
				<Grid item xs={12}>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "flex-start",
						}}
					>
						<Switch
							checked={newEssentialLocationData.isActive ?? true}
							onChange={(e) =>
								setNewEssentialLocationData({
									...newEssentialLocationData,
									isActive: e.target.checked,
								})
							}
						/>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 400,
								fontSize: "16px",
								color: "rgba(0, 0, 0, 0.87)",
							}}
						>
							Location is active (required)
						</Typography>
					</Box>
				</Grid>
			</Grid>
			<Grid
				container
				sx={{
					paddingTop: "16px",
					//paddingLeft: "21px",
					//paddingRight: "21px",
					paddingBottom: "16px",
				}}
			>
				<Grid item xs={12}>
					<TextField
						label='Point of contact for worker (enter mobile numbers separated by a comma)'
						value={newEssentialLocationData.companyContactForWorker ?? ""}
						onChange={(e) => {
							const inputValue = e.target.value;

							// Allow only numbers, commas, parentheses, spaces, and hyphens
							const filteredValue = inputValue.replace(/[^0-9,()\-\s]/g, "");

							setNewEssentialLocationData({
								...newEssentialLocationData,
								companyContactForWorker: filteredValue,
							});
							validateContacts(filteredValue, selectedCountry);
						}}
						variant='filled'
						error={pocError !== ""}
						helperText={pocError}
						fullWidth
					/>
				</Grid>
			</Grid>
			{creatingNewLocation ? (
				<CreateLocationButton
					data={newEssentialLocationData}
					closeLocationForm={closeLocationForm}
					onClick={handleLocationSave}
					status={createNewLocationStatus}
				/>
			) : (
				<SectionActions
					oldData={essentialLocationData}
					newData={newEssentialLocationData}
					resetChanges={() => {
						setNewEssentialLocationData(essentialLocationData);
						setLoading(false);
					}}
					handleSave={handleLocationSave}
					//status={updateLocationDataStatus}
					loading={loading}
				/>
			)}
		</SectionWrapper>
	);
};

export default EssentialLocationDetails;
