/* eslint-disable sort-imports */
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import {
	getLocationSettings,
	resetUpdateCompanySettings,
	resetUpdateLocationData,
} from "../../../../../../export/gat-admin";
import useGatAdminConfigData from "../../../controllers/use-gat-admin-config-data";
import LocationFormPage from "../location-form-page";
import LocationSettingsHeader from "./location-settings-header";

const LocationSettings = ({
	closeLocationForm,
	openDialog,
	setWorkCenterData,
	recentlyUpdatedLocationId,
}: any) => {
	const dispatch = useDispatch();

	const {
		updateCompanySettingsStatus,
		updateCompanySettingsErrorMessage,
		updateLocationStatus,
		updateLocationErrorMessage,
		createNewLocationStatus,
		createNewLocationErrorMessage,
		creatingNewLocation,
		newLocationId,
		companyDataValue,
		currentLocationData,
		locations,
	} = useGatAdminConfigData();

	useEffect(() => {
		if (currentLocationData.id && updateCompanySettingsStatus === "fulfilled") {
			dispatch(getLocationSettings(currentLocationData.id));
		}
	}, [currentLocationData.id, updateCompanySettingsStatus, dispatch]);
	return (
		<>
			<LocationSettingsHeader
				creatingNewLocation={creatingNewLocation}
				locationData={currentLocationData}
				closeLocationForm={closeLocationForm}
			/>
			<LocationFormPage
				creatingNewLocation={creatingNewLocation}
				locationData={currentLocationData}
				createNewLocationStatus={createNewLocationStatus}
				newLocationId={newLocationId}
				companyDataValue={companyDataValue}
				openDialog={openDialog}
				setWorkCenterData={setWorkCenterData}
				recentlyUpdatedLocationId={recentlyUpdatedLocationId}
				locations={locations}
				closeLocationForm={closeLocationForm}
			/>

			{/* update company setings */}

			<Snackbar
				open={updateCompanySettingsStatus === "error"}
				autoHideDuration={3000}
				onClose={() => {
					dispatch(resetUpdateCompanySettings());
				}}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<MuiAlert elevation={6} variant='filled' severity='error'>
					{updateCompanySettingsErrorMessage ??
						"An Error Occurred. Please try again."}
				</MuiAlert>
			</Snackbar>
			<Snackbar
				open={updateCompanySettingsStatus === "fulfilled"}
				autoHideDuration={3000}
				onClose={() => {
					dispatch(resetUpdateCompanySettings());
				}}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<MuiAlert elevation={6} variant='filled' severity='success'>
					{"Settings Updated Successfully!"}
				</MuiAlert>
			</Snackbar>

			{/* Location error and success */}
			<Snackbar
				open={updateLocationStatus === "error"}
				autoHideDuration={2000}
				onClose={() => {
					dispatch(resetUpdateLocationData());
				}}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<MuiAlert elevation={6} variant='filled' severity='error'>
					{updateLocationErrorMessage ?? "An Error Occurred. Please try again."}
				</MuiAlert>
			</Snackbar>
			<Snackbar
				open={updateLocationStatus === "fulfilled"}
				autoHideDuration={1000}
				onClose={() => {
					dispatch(resetUpdateLocationData());
					if (currentLocationData.id)
						dispatch(getLocationSettings(currentLocationData.id));
				}}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<MuiAlert elevation={6} variant='filled' severity='success'>
					{"Location updated"}
				</MuiAlert>
			</Snackbar>
		</>
	);
};

export default LocationSettings;
