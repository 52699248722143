import { Box, Divider, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FormikProps } from "formik";
import React, { useRef, useState } from "react";

import ActionButtons from "./action-buttons";
import TeamForm from "./team-form";

interface TeamsDialogProps {
	isTeamsDialogOpen: boolean;
	closeTeamsDialog: () => void;
	isEdit: boolean;
	teamData: any;
}

const TeamsDialog: React.FC<TeamsDialogProps> = ({
	isTeamsDialogOpen,
	closeTeamsDialog,
	isEdit,
	teamData,
}) => {
	const teamFormRef = useRef<FormikProps<any>>(null);
	const [isValid, setIsValid] = useState<boolean>(false);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [dirty, setDirty] = useState<boolean>(false);
	const [status, setStatus] = useState<any>();
	const [scheduleEnabledAndEmpty, setScheduleEnabledAndEmpty] =
		useState<boolean>(false);

	return (
		<Dialog
			onClose={closeTeamsDialog}
			aria-labelledby='add-edit-teams'
			open={isTeamsDialogOpen}
			maxWidth={false}
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<DialogTitle
				id='location-dialog-title'
				sx={{
					width: "900px",
				}}
			>
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontSize: "20px",
						fontWeight: 500,
						color: "rgba(0, 0, 0, 0.87)",
						paddingTop: "29px",
						paddingLeft: "21px",
						paddingBottom: "9px",
						paddingRight: "21px",
					}}
				>
					{isEdit ? "Edit Shift pattern" : "Create Shift pattern"}
				</Typography>
			</DialogTitle>
			<DialogContent>
				<Box
					sx={{
						paddingTop: "12px",
						paddingLeft: "21px",
						paddingRight: "21px",
						paddingBottom: "16px",
					}}
				>
					<TeamForm
						closeTeamsDialog={closeTeamsDialog}
						isEdit={isEdit}
						teamData={teamData}
						setIsValid={setIsValid}
						setIsSubmitting={setIsSubmitting}
						setDirty={setDirty}
						setStatus={setStatus}
						teamFormRef={teamFormRef}
						setScheduleEnabledAndEmpty={setScheduleEnabledAndEmpty}
					/>
				</Box>
			</DialogContent>
			<Divider />
			<DialogActions>
				<ActionButtons
					closeTeamsDialog={closeTeamsDialog}
					isValid={isValid}
					isSubmitting={isSubmitting}
					dirty={dirty}
					status={status}
					teamFormRef={teamFormRef}
					scheduleEnabledAndEmpty={scheduleEnabledAndEmpty}
				/>
			</DialogActions>
		</Dialog>
	);
};

export default TeamsDialog;
