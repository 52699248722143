import { Appointments } from "@devexpress/dx-react-scheduler-material-ui";
import { alpha } from "@mui/material/styles";
import React from "react";

import { isTooDark } from "../../utils/helpers";

export default function AppointmentContainer({
	children,
	style,
	data,
	...restProps
}:any) {
	const color = data.hexColor ? `#${data.hexColor}` : "#808080";
	const ratio = data.maxWorkers ? isTooDark(color)
		? data.numberOfWorkers / data.maxWorkers
		: 0.2 + data.numberOfWorkers / data.maxWorkers / 1.25 : 1;
	return (
		<Appointments.Appointment
			{...restProps}
			data={data}
			style={{
				backgroundColor: alpha(color, ratio),
			}}
		>
			{children}
		</Appointments.Appointment>
	);
}
