/* eslint-disable no-mixed-spaces-and-tabs */
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import { Box, Typography } from "@mui/material";
import { DataGridPro, GridValidRowModel } from "@mui/x-data-grid-pro-6";
import React from "react";
import { useSelector } from "react-redux";

import useWorkersData from "../../../../controllers/use-workers-data";
import { getAllSkills, getSkillTableRows } from "../../../helper";
import useWorkerSkillColumn from "../../controllers/use-worker-skill-column";

const NoSkillsAvailable = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <BuildOutlinedIcon sx={{ color: "#00000061", height: 30, width: 30 }} />
        <Typography
          sx={{
            fontFamily: "Roboto",
            fontWeight: 400,
            fontSize: "14px",
            color: "#00000061",
            lineHeight: "20px",
            letterSpacing: "0.17px",
          }}
        >
          No Skills added for the Worker
        </Typography>
      </Box>
    </div>
  );
};

interface Job {
  jobId: string;
  jobName: string;
  color: string;
  isExpired: boolean;
  skills: any[];
}

interface WorkerProfile {
  jobs: Job[];
  otherSkills: any[];
}

interface WorkerSkillsTableProps {
  workerProfile: WorkerProfile;
  searchText: string;
  skillExpiryEnabled: boolean;
  skillPriorityEnabled: boolean;
  allSkillsWithPriority?: any[];
}

const WorkerSkillsTable: React.FC<WorkerSkillsTableProps> = ({
  workerProfile,
  searchText,
  skillExpiryEnabled,
  skillPriorityEnabled,
  allSkillsWithPriority = [],
}) => {
  const columns: any = useWorkerSkillColumn(searchText);
  const { deleteWorkerSkillStatus } = useWorkersData();

  const jobs = workerProfile?.jobs || [];
  const otherSkills = workerProfile?.otherSkills || [];

  const rows = getAllSkills(jobs, otherSkills, skillPriorityEnabled, allSkillsWithPriority);
  const isPagination = rows.length > 0;

  const filteredRows = getSkillTableRows(rows, searchText);

  return (
    <Box style={{ height: rows.length > 0 ? "auto" : 300 }}>
      <DataGridPro
        rows={(filteredRows as GridValidRowModel[]) ?? []}
        columnVisibilityModel={{
          // Hide certifications based on company settings
          status: skillExpiryEnabled,
          validFrom: skillExpiryEnabled,
          validUntil: skillExpiryEnabled,
          skillPriority: skillPriorityEnabled,
        }}
        columns={columns}
        pagination={isPagination}
        disableColumnSelector
        disableDensitySelector
        disableRowSelectionOnClick
        disableColumnPinning
        loading={deleteWorkerSkillStatus === "pending"}
        slots={{
          noRowsOverlay: NoSkillsAvailable,
        }}
        initialState={{
          ...rows,
          pagination: { paginationModel: { pageSize: 10 } },
          filter: {},
        }}
        pageSizeOptions={[10, 25, 50]}
        autoHeight
        getRowHeight={() => "auto"}
        sx={{
          position: "sticky",
          border: "none !important",
          ".MuiDataGrid-cell": {
            "&:focus": {
              outline: "none",
            },
            "&.hover-column-cell": {
              position: "sticky",
              right: 24,
              zIndex: 1,
            },
            "& .hover-content": {
              transition: "opacity 0.3s ease-in-out",
              opacity: 0,
            },
          },
          ".MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
          "& .MuiDataGrid-columnHeader:focus-within": {
            outline: "none",
          },
          ".MuiDataGrid-row:hover .hover-column-cell .hover-content": {
            opacity: 1,
            backgroundColor: "#f5f5f5",
          },
        }}
      />
    </Box>
  );
};

export default WorkerSkillsTable;
