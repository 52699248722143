import BlockIcon from "@mui/icons-material/Block";
import { Box, Grid,Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import React from "react";

import {SkillRepresentationCell} from "../../../../../../../../export/cross-skilling";
import { getRepLevel, getRepType } from "../../../../../../../utils/helpers";
import {
    SkillLevel
} from "../../../../../../cross-skilling/types";

interface CrossSkillingLevelsTableRowProps {
    skillLevel: SkillLevel;
}

const CrossSkillingLevelsTableRow: React.FC<CrossSkillingLevelsTableRowProps> = ({ skillLevel }) => {
    return (
        <Grid
            container
            sx={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
                paddingBottom: "8px",
            }}
        >
            <Grid
                item
                xs={1.1}
                display='flex'
                alignItems='center'
                justifyContent='center'
            >
                <SkillRepresentationCell
                    level={skillLevel.level}
                    value={String(skillLevel.value)}
                    representationType={getRepType(skillLevel.repCode)}
                    representationLevel={getRepLevel(
                        skillLevel.repCode,
                        skillLevel.level
                    )}
                    border={1}
                    backgroundWidth={"35px"}
                    backgroundHeight={"35px"}
                />
            </Grid>
            <Grid
                item
                xs={1.1}
                display='flex'
                alignItems='center'
                justifyContent='center'
            >
                {skillLevel.backColorCode !== "" &&
                skillLevel.backColorCode !== null ? (
                    <Box
                        sx={{
                            width: "18px",
                            height: "18px",
                            backgroundColor: `#${skillLevel.backColorCode}`,
                            border: 1,
                            borderWidth: "1px",
                            borderColor: "rgba(0,0,0,0.4)",
                            borderRadius: "9px",
                        }}
                    />
                ) : (
                    <BlockIcon
                        sx={{
                            width: "18px",
                            height: "18px",
                        }}
                    />
                )}
            </Grid>
            <Grid
                item
                xs={1.06}
                display='flex'
                alignItems='center'
                justifyContent='center'
            >
                <Typography
                    sx={{
                        fontFamily: "Roboto",
                        fontWeight: 400,
                        fontSize: "14px",
                        color: "rgba(0, 0, 0, 0.87)",
                        textTransform: "uppercase",
                    }}
                >
                    {skillLevel.value ?? ""}
                </Typography>
            </Grid>
            <Grid
                item
                xs={2.5}
                display='flex'
                alignItems='center'
                justifyContent='flex-start'
            >
                <Typography
                    sx={{
                        fontFamily: "Roboto",
                        fontWeight: 400,
                        fontSize: "14px",
                        color: "rgba(0, 0, 0, 0.87)",
                        marginLeft: "12px",
                    }}
                >
                    {skillLevel.name ?? ""}
                </Typography>
            </Grid>
            <Grid
                item
                xs={3}
                display='flex'
                alignItems='center'
                justifyContent='flex-start'
            >
                <Typography
                    sx={{
                        fontFamily: "Roboto",
                        fontWeight: 400,
                        fontSize: "14px",
                        color: "rgba(0, 0, 0, 0.87)",
                        width: "100%",
                        wordBreak: "break-word",
                    }}
                >
                    {skillLevel.desc ?? ""}
                </Typography>
            </Grid>
            <Grid
                item
                xs={1}
                display='flex'
                alignItems='center'
                justifyContent='center'
                pr={1}
                height={"35px"}
            >
                <SkillRepresentationCell
                    level={skillLevel.level}
                    value={String(skillLevel.value)}
                    representationType={getRepType(skillLevel.repCode)}
                    representationLevel={getRepLevel(
                        skillLevel.repCode,
                        skillLevel.level
                    )}
                    backgroundColor={skillLevel.backColorCode}
                    foregroundColor={skillLevel.foreColorCode}
                    border={1}
                    width={20}
                    height={20}
                    backgroundWidth={"100%"}
                    backgroundHeight={"100%"}
                />
            </Grid>
            <Grid
                item
                xs={2.12}
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                    height: "100%",
                }}
            >
                <Typography
                    sx={{
                        fontFamily: "Roboto",
                        fontWeight: 400,
                        fontSize: "14px",
                        color: "rgba(0, 0, 0, 0.87)",
                        marginLeft: "12px",
                    }}
                >
                    {skillLevel.isQualifiedAsSkilled ? "Yes" : "No"}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default CrossSkillingLevelsTableRow;