import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "../../../utils/api-client";
import { SkillMatrix_JobRole } from "../../workers/store/manage-worker-types";
import { JobSliceStateTypes, Skill } from "./jobs-types";
interface createNewJobPayload {
	name: string;
	color: string;
	skills: Array<any>;
	workcenters: Array<any>;
}

export const createNewJob = createAsyncThunk(
	"manageJobs/createNewJob",
	async (
		payload: createNewJobPayload,
		{ fulfillWithValue, rejectWithValue, getState }
	) => {
		const { name, color, skills, workcenters } = payload;

		// console.log("Skills : ", skills,payload);

		let workcenterIds = workcenters.reduce((acc, key) => {
			acc.push(key.id);
			return acc;
		}, []);

		let skillIds = skills.reduce((acc, key) => {
			acc.push(key.skillId);
			return acc;
		}, []);

		if (workcenterIds.length === 0)
			return rejectWithValue("Please select at least one workcenter.");

		if (color === "")
			return rejectWithValue("Please select a color for the job role.");
		// if (skillIds.length === 0)
		//     return rejectWithValue("Please select at least one skill.");

		workcenterIds = workcenterIds.toString();
		skillIds = skillIds.toString();

		const state = getState() as any;
		const userId = Number.parseInt(state.user.userData.id);

		const jobData = {
			name: name,
			hexColor: color,
			locationId: 1,
			workCenterIds: workcenterIds,
			skillIds,
			createdBy: userId,
		};

		try {
			const res = await axios.post(
				`${process.env.REACT_APP_API_END_POINT}/Jobs/Create`,
				jobData
			);
			return fulfillWithValue(res.data);
		} catch (error: any) {
			if (error.response.status === 404) {
				return rejectWithValue(error.response.data.message);
			}
			return rejectWithValue(error.response.data.message);
		}
	}
);

interface editJobPayload {
	id: number;
	name: string;
	color: string;
	skills: Array<any>;
	workcenters: Array<any>;
}

export const editJob = createAsyncThunk(
	"manageJobs/editJob",
	async (
		payload: editJobPayload,
		{ fulfillWithValue, rejectWithValue, getState }
	) => {
		const { id, name, color, skills, workcenters } = payload;
		const jobId = id;
		const state = getState() as any;
		const allJobs = state.manageJobs.allJobs;
		let oldJobData = allJobs.filter(
			(job: SkillMatrix_JobRole) => job.id === jobId
		);
		let workcenterIds = workcenters.reduce((acc, key) => {
			acc.push(key.id);
			return acc;
		}, []);
		let skillIds = skills.reduce((acc, key) => {
			acc.push(key.skillId);
			return acc;
		}, []);
		if (workcenterIds.length === 0)
			return rejectWithValue("Please select at least one workcenter.");
		skillIds = skillIds.toString();
		workcenterIds = workcenterIds.toString();
		const userId = Number.parseInt(state.user.userData.id);
		const jobData = {
			id,
			name,
			hexColor: color,
			skillIds,
			locationId: 1,
			userId,
			createdBy: userId,
			workCenterIds: workcenterIds,
			skills,
		};
		oldJobData = oldJobData[0];
		const requestPayload = {
			...oldJobData,
			...jobData,
		};
		delete requestPayload.workcenterIds;
		delete requestPayload.dispatch;

		try {
			const editedJob = await axios.put(
				`${process.env.REACT_APP_API_END_POINT}/Jobs/Put`,
				requestPayload
			);

			if (editedJob.status === 204)
				return fulfillWithValue({ jobId, data: requestPayload });
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

export const fetchJobsByUserId = createAsyncThunk(
	"manageJobs/fetchJobsByUserId",
	async (payload, { fulfillWithValue, rejectWithValue, getState }) => {
		const state = getState() as any;
		const userId = state.user.userData.id;
		try {
			const jobsResponse = axios.get(
				`${process.env.REACT_APP_API_END_POINT}/Jobs/GetJobsByUser?userid=${userId}`
			);

			const skillsResponse = axios.get(
				`${process.env.REACT_APP_API_END_POINT}/Skill/GetSkillsByUser`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: localStorage.getItem("token"),
					},
				}
			);

			const [jobs, skills] = await Promise.allSettled([
				jobsResponse,
				skillsResponse,
			]);

			let jobsList = [];
			let skillsList = [];
			if (jobs.status === "fulfilled" && skills.status === "fulfilled") {
				jobsList = jobs.value.data;
				skillsList = skills.value.data;
			}
			return fulfillWithValue({
				jobs: jobsList,
				skills: skillsList,
			});
		} catch (error: any) {
			//console.log(error)
			try {
				return rejectWithValue(error.response.data.message);
			} catch (e) {
				return rejectWithValue("Error fetching jobs and skills");
			}
		}
	}
);

export const editSkill = createAsyncThunk(
	"manageJob/editSkill",
	async (
		payload: { name: string; id: string },
		{ fulfillWithValue, rejectWithValue }
	) => {
		try {
			const res = await axios.put(
				`${process.env.REACT_APP_API_END_POINT}/Skill/Update`,
				payload
			);
			return fulfillWithValue(res.data);
		} catch (error: any) {
			if (error.response.status === 404) {
				return rejectWithValue(error.response.data.message);
			}
			return rejectWithValue(error.response.data.message);
		}
	}
);

export const createNewSkill = createAsyncThunk(
	"manageJobs/createNewSkill",
	async (payload: any, { fulfillWithValue, rejectWithValue }) => {
		try {
			const res = await axios.post(
				`${process.env.REACT_APP_API_END_POINT}/Skill/Create`,
				{ name: payload }
			);
			return fulfillWithValue(res.data);
		} catch (error: any) {
			if (error.response.status === 404) {
				return rejectWithValue(error.response.data.message);
			}
			return rejectWithValue(error.response.data.message);
		}
	}
);

export const fetchMaxWorkersByLocationJob = createAsyncThunk(
	"scheduler/fetchMaxWorkersByLocationJob",
	/**  @param payload {locationId: string; jobId: string; startDateTime: string; endDateTime: string, shiftSkills  } */
	async (payload, thunkAPI) => {
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_API_END_POINT}/Shifts/GetMaxWorkersForShift`,
				payload
			);
			if (response.status === 200) {
				return thunkAPI.fulfillWithValue(response.data);
			}
			return thunkAPI.rejectWithValue(response.data.error);
		} catch (error) {
			// console.error(error);
			return thunkAPI.rejectWithValue(error);
		}
	}
);

export const getWorkersCountByShift = createAsyncThunk(
	"scheduler/getWorkersCountByShift",
	/**  @param payload {locationId: string; jobId: string; startDateTime: string; endDateTime: string, shiftSkills,shiftId:number  } */
	async (
		payload: {
			locationId: string;
			jobId: string;
			startDateTime: string;
			endDateTime: string;
			shiftSkills: any;
			shiftId: number;
		},
		thunkAPI
	) => {
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_API_END_POINT}/Shifts/GetMaxWorkersForShift`,
				payload
			);
			if (response.status === 200) {
				return thunkAPI.fulfillWithValue(
					Object.assign({}, payload, { count: response.data })
				);
			}
			return thunkAPI.rejectWithValue(response.data.error);
		} catch (error) {
			// console.error(error);
			return thunkAPI.rejectWithValue(error);
		}
	}
);

export const ManageJobsSlice = createSlice({
	name: "manageJobs",
	initialState: {
		addJobDialog: {
			isOpen: false,
		},

		editJobDialog: {
			isOpen: false,
			job: undefined,
		},

		editSkillDialog: {
			isOpen: false,
			skill: undefined,
		},

		createSkillDialog: {
			isOpen: false,
		},

		allJobs: [],
		allSkills: [],

		fetchJobsByUserId: {
			status: "idle",
			errorMessage: "",
		},

		createNewJob: {
			status: "idle",
			errorMessage: "",
		},
		editJob: {
			status: "idle",
			errorMessage: "",
		},
		editSkill: {
			status: "idle",
			errorMessage: "",
		},
		createNewSkill: {
			status: "idle",
			errorMessage: "",
		},
		fetchMaxWorkersByLocationJob: {
			status: "idle",
			errorMessage: "",
			data: {},
		},
		getWorkersCountByShift: {
			status: "idle",
			errorMessage: "",
			data: {},
		},
	} as JobSliceStateTypes,

	reducers: {
		openAddJobDialog: (state, action) => {
			state.addJobDialog.isOpen = true;
			state.createNewJob.status = "idle";
			state.createNewJob.errorMessage = "";
		},
		closeAddJobDialog: (state) => {
			state.addJobDialog.isOpen = false;
		},
		openEditJobDialog: (state, action) => {
			state.editJobDialog.isOpen = true;
			state.editJobDialog.job = action.payload;
			state.editJob.status = "idle";
			state.editJob.errorMessage = "";
		},
		openEditSkillDialog: (state, action) => {
			state.editSkillDialog.isOpen = true;
			state.editSkillDialog.skill = action.payload;
			state.editSkill.status = "idle";
			state.editSkill.errorMessage = "";
		},
		closeEditJobDialog: (state) => {
			state.editJobDialog.isOpen = false;
			state.editJobDialog.job = undefined;
		},
		closeEditSkillDialog: (state) => {
			state.editSkillDialog.isOpen = false;
			state.editSkillDialog.skill = undefined;
		},
		openCreateSkillDialog: (state) => {
			state.createSkillDialog.isOpen = true;
			state.createNewSkill.status = "idle";
			state.createNewSkill.errorMessage = "";
		},
		closeCreateSkillDialog: (state) => {
			state.createSkillDialog.isOpen = false;
			state.createNewSkill.status = "idle";
			state.createNewSkill.errorMessage = "";
		},
		resetCreateNewJob: (state) => {
			state.createNewJob.status = "idle";
			state.createNewJob.errorMessage = "";
		},
		resetCreateNewSkill: (state) => {
			state.createNewSkill.status = "idle";
			state.createNewSkill.errorMessage = "";
		},
		resetEditJob: (state) => {
			state.editJob.status = "idle";
			state.editJob.errorMessage = "";
		},
		resetEditSkill: (state) => {
			state.editSkill.status = "idle";
			state.editSkill.errorMessage = "";
			state.editSkillDialog.isOpen = false;
			state.editSkillDialog.skill = undefined;
		},
		resetMaxWorkersByLocationJob: (state) => {
			state.fetchMaxWorkersByLocationJob.status = "idle";
			state.fetchMaxWorkersByLocationJob.errorMessage = "";
			state.fetchMaxWorkersByLocationJob.data = {};
		},
		resetFetchJobsByUserId: (state) => {
			state.fetchJobsByUserId.status = "idle";
			state.fetchJobsByUserId.errorMessage = "";
			state.allJobs = [];
			state.allSkills = [];
		},
		resetWorkersCountByShift: (state) => {
			state.getWorkersCountByShift.status = "idle";
			state.getWorkersCountByShift.errorMessage = "";
			state.getWorkersCountByShift.data = {};
		},
	},

	extraReducers: (builder) => {
		builder.addCase(createNewJob.pending, (state) => {
			state.createNewJob.status = "pending";
		});
		builder.addCase(createNewJob.fulfilled, (state, action) => {
			state.allJobs = [...state.allJobs, action.payload];
			state.addJobDialog.isOpen = false;
			state.createNewJob.status = "fulfilled";
		});
		builder.addCase(createNewJob.rejected, (state, action) => {
			state.createNewJob.status = "error";
			state.createNewJob.errorMessage =
				`${action.payload}` ?? "An error occurred";
		});
		builder.addCase(fetchJobsByUserId.pending, (state) => {
			state.fetchJobsByUserId.status = "pending";
		});
		builder.addCase(fetchJobsByUserId.fulfilled, (state, action) => {
			const { jobs, skills } = action.payload;
			state.allJobs = jobs;
			const allSkills = skills.map((skill: Skill) => ({
				...skill,
				isActive: !skill.isObsolete,
			}));

			state.allSkills = allSkills;
			state.fetchJobsByUserId.status = "fulfilled";
		});
		builder.addCase(fetchJobsByUserId.rejected, (state, action) => {
			state.fetchJobsByUserId.status = "error";
			state.fetchJobsByUserId.errorMessage =
				`${action.payload}` ?? "An error occurred";
		});
		builder.addCase(editJob.pending, (state) => {
			state.editJob.status = "pending";
		});
		builder.addCase(editJob.fulfilled, (state, action: any) => {
			const jobId = action.payload?.jobId;
			const newWorkerData = action.payload?.data;
			const index = state.allJobs.findIndex(
				(el: SkillMatrix_JobRole) => el.id == jobId
			);
			state.allJobs[index] = newWorkerData;
			state.editJobDialog.isOpen = false;
			state.editJob.status = "fulfilled";
		});
		builder.addCase(editJob.rejected, (state, action) => {
			state.editJob.status = "error";
			state.editJob.errorMessage = `${action.payload}` ?? "An error occurred";
		});
		builder.addCase(createNewSkill.pending, (state) => {
			state.createNewSkill.status = "pending";
		});
		builder.addCase(createNewSkill.fulfilled, (state, action) => {
			state.allSkills = [...state.allSkills, action.payload];
			state.createSkillDialog.isOpen = false;
			state.createNewSkill.status = "fulfilled";
		});
		builder.addCase(createNewSkill.rejected, (state, action) => {
			state.createNewSkill.status = "error";
			state.createNewSkill.errorMessage =
				`${action.payload}` ?? "An error occurred";
		});
		builder.addCase(editSkill.pending, (state) => {
			state.editSkill.status = "pending";
		});
		builder.addCase(editSkill.fulfilled, (state) => {
			state.editJobDialog.isOpen = false;
			state.editSkill.status = "fulfilled";
		});
		builder.addCase(editSkill.rejected, (state, action) => {
			state.editSkill.status = "error";
			state.editSkill.errorMessage = `${action.payload}` ?? "An error occurred";
		});
		builder.addCase(fetchMaxWorkersByLocationJob.pending, (state) => {
			state.fetchMaxWorkersByLocationJob.status = "pending";
		});
		builder.addCase(fetchMaxWorkersByLocationJob.fulfilled, (state, action) => {
			state.fetchMaxWorkersByLocationJob.status = "fulfilled";
			state.fetchMaxWorkersByLocationJob.data = action.payload;
		});
		builder.addCase(fetchMaxWorkersByLocationJob.rejected, (state, action) => {
			state.fetchMaxWorkersByLocationJob.status = "error";
			state.fetchMaxWorkersByLocationJob.errorMessage =
				`${action.payload}` ?? "An error occurred";
		});
		builder.addCase(getWorkersCountByShift.pending, (state) => {
			state.getWorkersCountByShift.status = "pending";
		});
		builder.addCase(getWorkersCountByShift.fulfilled, (state, action) => {
			const shiftId = action.payload.shiftId;
			const count = action.payload.count;
			state.getWorkersCountByShift.data[shiftId] = count;
			state.getWorkersCountByShift.status = "fulfilled";
		});
		builder.addCase(getWorkersCountByShift.rejected, (state, action) => {
			state.getWorkersCountByShift.status = "error";
			state.getWorkersCountByShift.errorMessage =
				`${action.payload}` ?? "An error occurred";
		});
		// [createNewJob.fulfilled]: (state, action) => {
		// 	state.allJobs = [...state.allJobs, action.payload];
		// 	state.addJobDialog.isOpen = false;
		// 	state.createNewJob.status = "fulfilled";
		// },
		// [createNewJob.pending]: (state) => {
		// 	state.createNewJob.status = "pending";
		// },
		// [createNewJob.rejected]: (state, action) => {
		// 	state.createNewJob.status = "error";
		// 	state.createNewJob.errorMessage = action.payload ?? "An error occurred";
		// },
		// [fetchJobsByUserId.fulfilled]: (state, action) => {
		// 	state.allJobs = action.payload.jobs;
		// 	state.allSkills = action.payload.skills;
		// 	state.fetchJobsByUserId.status = "fulfilled";
		// },
		// [fetchJobsByUserId.pending]: (state) => {
		// 	state.fetchJobsByUserId.status = "pending";
		// },
		// [fetchJobsByUserId.rejected]: (state, action) => {
		// 	state.fetchJobsByUserId.status = "error";
		// 	state.fetchJobsByUserId.errorMessage =
		// 		action.payload ?? "An error occurred";
		// },

		// [editJob.fulfilled]: (state, action) => {
		// 	const jobId = action.payload?.jobId;
		// 	const newWorkerData = action.payload?.data;
		// 	let index = state.allJobs.findIndex((el) => el.id === jobId);
		// 	state.allJobs[index] = newWorkerData;
		// 	state.editJobDialog.isOpen = false;
		// 	state.editJob.status = "fulfilled";
		// },
		// [editJob.pending]: (state) => {
		// 	state.editJob.status = "pending";
		// },
		// [editJob.rejected]: (state, action) => {
		// 	state.editJob.status = "error";
		// 	state.editJob.errorMessage = action.payload ?? "An error occurred";
		// },

		// [createNewSkill.fulfilled]: (state, action) => {
		// 	state.allSkills = [...state.allSkills, action.payload];
		// 	state.createSkillDialog.isOpen = false;
		// 	state.createNewSkill.status = "fulfilled";
		// },
		// [createNewSkill.pending]: (state) => {
		// 	state.createNewSkill.status = "pending";
		// },
		// [createNewSkill.rejected]: (state, action) => {
		// 	state.createNewSkill.status = "error";
		// 	state.createNewSkill.errorMessage = action.payload ?? "An error occurred";
		// },

		// [editSkill.fulfilled]: (state) => {
		// 	state.editJobDialog.isOpen = false;
		// 	state.editSkill.status = "fulfilled";
		// },
		// [editSkill.pending]: (state) => {
		// 	state.editSkill.status = "pending";
		// },
		// [editSkill.rejected]: (state, action) => {
		// 	state.editSkill.status = "error";
		// 	state.editSkill.errorMessage = action.payload ?? "An error occurred";
		// },

		// [fetchMaxWorkersByLocationJob.fulfilled]: (state, action) => {
		// 	state.fetchMaxWorkersByLocationJob.status = "fulfilled";
		// 	state.fetchMaxWorkersByLocationJob.data = action.payload;
		// },
		// [fetchMaxWorkersByLocationJob.pending]: (state) => {
		// 	state.fetchMaxWorkersByLocationJob.status = "pending";
		// },
		// [fetchMaxWorkersByLocationJob.rejected]: (state, action) => {
		// 	state.fetchMaxWorkersByLocationJob.status = "error";
		// 	state.fetchMaxWorkersByLocationJob.errorMessage =
		// 		action.payload ?? "An error occurred";
		// },
	},
});

export const {
	openAddJobDialog,
	closeAddJobDialog,
	openEditSkillDialog,
	openEditJobDialog,
	closeEditJobDialog,
	closeEditSkillDialog,
	openCreateSkillDialog,
	closeCreateSkillDialog,
	resetEditJob,
	resetCreateNewJob,
	resetCreateNewSkill,
	resetEditSkill,
	resetMaxWorkersByLocationJob,
	resetFetchJobsByUserId,
	resetWorkersCountByShift,
} = ManageJobsSlice.actions;

export default ManageJobsSlice.reducer;
