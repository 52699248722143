// TODO - Type 'any' needs to be fixed.
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import DoDisturbOutlinedIcon from "@mui/icons-material/DoDisturbOutlined";
import ModeCommentOutlinedIcon from "@mui/icons-material/ModeCommentOutlined";
import PauseCircleOutlinedIcon from "@mui/icons-material/PauseCircleOutlined";
import { Box, Tooltip, Typography } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-pro-6";
import { GridColDef } from "@mui/x-data-grid-pro-6";
import React from "react";
import { useDispatch } from "react-redux";

import { markAttendance } from "../../../../../../../export/scheduler";
import AttendanceCommentDialog from "../../../../../attendance-reports/ui/daily-report/attendance-comment-dialog";

type StatusKey = "p" | "a" | "t" | "e" | "x";
const useMarkAttendanceColumns = () => {

  const statusObj: Record<
    StatusKey,
    { title: string; color: string; icon: JSX.Element }
  > = {
    p: {
      title: "Present",
      color: "#017374",
      icon: <CheckCircleOutlinedIcon color="success" />,
    },
    a: {
      title: "No Show",
      color: "#b00020",
      icon: <DoDisturbOutlinedIcon color="error" />,
    },
    t: {
      title: "Tardy",
      color: "#01a299",
      icon: <AccessTimeOutlinedIcon color="warning" />,
    },
    e: {
      title: "Excused Absence",
      color: "#7d5260",
      icon: <PauseCircleOutlinedIcon color="info" />,
    },
    x: {
      title: "Excused Tardy",
      color: "#7d5260",
      icon: <PauseCircleOutlinedIcon color="info" />,
    },
  };

  const actionItems = [
    { title: "Present", status: "p", color: "#017374" },
    { title: "No Show", status: "a", color: "#b00020" },
    { title: "Tardy", status: "t", color: "#01a299" },
    { title: "Excused Absence", status: "e", color: "#7d5260" },
    { title: "Excused Tardy", status: "x", color: "#7d5260" },
  ];
  const columns: GridColDef[] = [
    {
      field: "workerName",
      headerName: "Worker",
      width: 150,
      renderHeader: () => (
        <span style={{ padding: "0px 12x", fontWeight: "bold" }}>
          {"Worker"}
        </span>
      ),
      renderCell: (params) => (
        <Tooltip title={params?.row?.fullName} placement="bottom-start">
          <Typography
            variant="body2"
            sx={{
              textOverflow: "ellipsis",
              width: "100%",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {params?.row?.fullName}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 160,
      valueGetter: (params) => statusObj[params.row.status as StatusKey]?.title,
      renderHeader: () => (
        <span style={{ padding: "0px 12px", fontWeight: "bold" }}>
          {"Status"}
        </span>
      ),
      renderCell: (params) => {
        return (
          <Box display="flex" alignItems="center" p={"0 12px"}>
            {statusObj[params.row.status as StatusKey]?.icon}
            <Typography
              align="center"
              variant="subtitle2"
              ml={1}
              flexWrap="wrap"
            >
              {statusObj[params.row.status as StatusKey]?.title}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "comment",
      headerName: "Comment",
      flex: 1,
      renderHeader: () => (
        <span style={{ padding: "0px 12px", fontWeight: "bold" }}>
          {"Comment"}
        </span>
      ),

      renderCell: (params) => (
        <Tooltip title={params?.row?.comment} placement="bottom-start">
          <Typography
            variant="body2"
            sx={{
              padding: "0 12px",
              textOverflow: "ellipsis",
              width: "100%",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {params?.row?.comment}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: "attendance",
      headerName: "Actions",
      width: 100,
      sortable: false,
      renderHeader: () => (
        <span style={{ padding: "0px 12px", fontWeight: "bold" }}>
          {"Actions"}
        </span>
      ),
      renderCell: (params) => {
        return (
          <Box sx={{
            padding: "0px 12px",
          }}>
            <AttendanceCommentDialog
              worker={params.row}
            />
          </Box>
        );
      },

    }
  ];

  return columns;
};

export default useMarkAttendanceColumns;
