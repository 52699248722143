import { useDispatch } from "react-redux";

import { closeNavigationDrawer, openNavigationDrawer } from "../../../../export/drawer";

interface MouseEventsController {
  handleMouseEnter: () => void;
  handleMouseLeave: () => void;
}

export default function useMouseEventsController(): MouseEventsController {
  const dispatch = useDispatch();
  let mouseOut = false;

  const handleMouseEnter = () => {
    setTimeout(() => {
      if (!mouseOut) {
        dispatch(openNavigationDrawer());
      }
    }, 500);
    mouseOut = false;
  };

  const handleMouseLeave = () => {
    mouseOut = true;
    dispatch(closeNavigationDrawer());
  };

  return { handleMouseEnter, handleMouseLeave };
}
