import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import React, { useEffect, useState } from "react";

import type { JobRoles, Props } from "../types";
import JobRole from "./job-role";

const JobRolesList = ({
  state,
  onSelectCheckbox,
  customStyles,
  jobRoles,
  disableGutters = false,
  enableDivider = false
}: Props) => {
  const styles = customStyles
    ? customStyles
    : {
        width: "100%",
        minWidth: "67px",
        maxWidth: 360,
        bgcolor: "background.paper"
      };

  const [checked, setChecked] = useState([0]);
  const [jobRolesList, setJobRolesList] = useState<JobRoles[]>([]);

  const isTooDark = (color: string): boolean => {
    let r: number, g: number, b: number;
    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {
      // If HEX --> store the red, green, blue values in separate variables
      const colorRegExpArr: RegExpMatchArray | null = color.match(
          /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
      );

      if (colorRegExpArr) {
        r = parseInt(colorRegExpArr[1]);
        g = parseInt(colorRegExpArr[2]);
        b = parseInt(colorRegExpArr[3]);
      } else {
        r = 0;
        g = 0;
        b = 0;
      }

    } else {
      // If RGB --> Convert it to HEX
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const colorNumber = +("0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&"));

      r = colorNumber >> 16;
      g = (colorNumber >> 8) & 255;
      b = colorNumber & 255;
    }

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

    // Using the HSP value, determine whether the color is light or dark
    return hsp < 139.5;
  };

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  useEffect(() => {
    if (jobRoles && jobRoles.length > 0) {
      setJobRolesList(jobRoles);
    }
    else {
      setJobRolesList([]);
    }
  }, [jobRoles]);

  return (
    <List sx={styles}>
      {jobRolesList.map((jobRole, index) => {
        const { id } = jobRole;
        const updatedJobRole = {...jobRole,
          textColor: isTooDark(jobRole.hexColor) ? "#FFFFFF" : "#030303",
        }
        const labelId = `checkbox-list-label-${id}`;
        return (
          <ListItem
            key={id}
            sx={{ height: "57px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              flex: 1,
          }}
            disableGutters={disableGutters}
            divider={enableDivider}>
            <Checkbox
                edge="start"
                onClick={() => {
                  handleToggle(id);
                  onSelectCheckbox({ id });
                }}
                tabIndex={-1}
                inputProps={{ "aria-labelledby": labelId }}
                checked={state?.[id] ?? false}
            />
            <JobRole jobRole={updatedJobRole} />
          </ListItem>
        );
      })}
    </List>
  );
};

export default JobRolesList;