// TODO - Type 'any' needs to be fixed.
import { Box, Button, Typography } from "@mui/material";
import React from "react";

type LocationFiltersProps = {
	locations: any[];
};

const LocationFilters = ({ locations }: LocationFiltersProps) => {
	const [locationExpand, setLocationExpand] = React.useState<boolean>(false);

	if (locations.length === 0) return null;
	return (
		<Box display='flex' flexWrap='wrap' mb={1} alignItems='center'>
			<Typography
				sx={{
					fontWeight: 400,
					fontSize: 14,
					fontFamily: "Roboto",
					color: "#000000",
					marginRight: 1,
				}}
			>
				Locations -
			</Typography>
			{locations.length > 0 ? (
				locations
					.slice(0, locationExpand ? undefined : 5)
					.map((role: { name: string; id: number }, index: number) => (
						<Box key={role.id} mr={0.8}>
							<Typography
								sx={{
									fontWeight: 400,
									fontSize: 14,
									fontFamily: "Roboto",
									color: "#000000",
								}}
							>
								{role.name} {index === locations.length - 1 ? " " : ","}{" "}
							</Typography>
						</Box>
					))
			) : (
				<></>
			)}
			{locations.length >= 5 ? (
				<Button
					onClick={(e) => setLocationExpand(!locationExpand)}
					size='small'
				>
					{locationExpand ? "Show Less" : "Show More"}
				</Button>
			) : null}
		</Box>
	);
};

export default LocationFilters;
