/* eslint-disable no-mixed-spaces-and-tabs */
import DeleteIcon from "@mui/icons-material/Delete";
import ModeIcon from "@mui/icons-material/Mode";
import { Box, Tooltip, Typography } from "@mui/material";
import { render } from "@testing-library/react";
import { format } from "date-fns";
import React from "react";

import { formatDate, getDuration } from "../../../../../utils/helpers";
import { TeamsColumnProps } from "../types";
import ShiftPatternMini from "../ui/table/shift-pattern-mini";
import useTeamsData from "./use-teams-data";

interface Schedule {
	dayNo: number;
	shiftTypeId: number | null;
	colorCode: undefined | string;
}

interface ShiftType {
	id: number;
	name: string;
	companyId: number;
	locationId: number;
	startTime: string;
	endTime: string;
	unpaidBreakMins: number;
	isActive: boolean;
	colorCode: string | undefined;
}

const getTotalShiftScheduleHours = (schedule: any, allShiftSchedules: any) => {
	let returnValue = "0 Hours";
	let totalHours = 0;
	try {
		const copyOfSchedule = [...schedule];
		const allActiveShiftSchedules = copyOfSchedule.filter(
			(s: Schedule) => s.shiftTypeId !== null
		);
		allActiveShiftSchedules.forEach((activeSchedule: Schedule) => {
			const fullShiftDetail = allShiftSchedules.find(
				(shiftSchedule: ShiftType) =>
					shiftSchedule.id === activeSchedule.shiftTypeId
			);
			//	console.log(fullShiftDetail);
			if (fullShiftDetail) {
				totalHours += Number(
					getDuration(
						fullShiftDetail.startTime,
						fullShiftDetail.endTime,
						fullShiftDetail.unpaidBreakMins
					)
				);
			}
		});
		const res = Math.round(totalHours * 100) / 100;
		returnValue = `${res} Hours`;
		return returnValue;
	} catch (e) {
		// Do nothing
		return returnValue;
	}
};

const useTeamsColumn = ({
	openEditTeam,
	getSchedule,
	deletePattern,
}: TeamsColumnProps) => {
	const { allShiftSchedules } = useTeamsData();

	const columns = [
		{
			field: "name",
			headerName: "Name",
			width: 180,
			renderHeader: () => (
				<span style={{ padding: "0px 4px", fontWeight: "bold" }}>
					{"Pattern Name"}
				</span>
			),
			renderCell: (params: any) => {
				const name = params.row.name ?? "";
				return (
					<Tooltip title={name.length > 25 ? name : ""} placement='top-start'>
						<Typography
							sx={{
								textOverflow: "ellipsis",
								width: "100%",
								whiteSpace: "nowrap",
								overflow: "hidden",
								padding: "0px 4px",
								fontStyle: "roboto",
								fontWeight: "400",
								fontSize: "14px",
							}}
						>
							{params.row.name}
						</Typography>
					</Tooltip>
				);
			},
			valueGetter: (params: any) => params.row.name,
		},
		// {
		// 	field: "workcenter",
		// 	headerName: "Workcenter",
		// 	width: 140,
		// 	renderHeader: () => (
		// 		<span style={{ padding: "0px 4px", fontWeight: "bold" }}>
		// 			{"Workcenter"}
		// 		</span>
		// 	),
		// 	renderCell: (params: any) => (
		// 		<Typography
		// 			sx={{
		// 				textOverflow: "ellipsis",
		// 				width: "100%",
		// 				whiteSpace: "nowrap",
		// 				overflow: "hidden",
		// 				padding: "0px 4px",
		// 				fontStyle: "roboto",
		// 				fontWeight: "400",
		// 				fontSize: "14px",
		// 			}}
		// 		>
		// 			{params.row?.workcenter["name"]}
		// 		</Typography>
		// 	),
		// 	valueGetter: (params: any) => params.row.workcenter["name"],
		// },
		// {
		// 	field: "reportingManager",
		// 	headerName: "Reporting Manager",
		// 	width: 156,
		// 	renderHeader: () => (
		// 		<span style={{ padding: "0px 4px", fontWeight: "bold" }}>
		// 			{"Reporting Manager"}
		// 		</span>
		// 	),
		// 	renderCell: (params: any) => (
		// 		<Typography
		// 			sx={{
		// 				textOverflow: "ellipsis",
		// 				width: "100%",
		// 				whiteSpace: "nowrap",
		// 				overflow: "hidden",
		// 				padding: "0px 4px",
		// 				fontStyle: "roboto",
		// 				fontWeight: "400",
		// 				fontSize: "14px",
		// 			}}
		// 		>
		// 			{params.row.manager["firstName"] +
		// 				" " +
		// 				params.row.manager["lastName"]}
		// 		</Typography>
		// 	),
		// 	valueGetter: (params: any) => {
		// 		return (
		// 			params.row.manager["firstName"] + " " + params.row.manager["lastName"]
		// 		);
		// 	},
		// },
		{
			field: "patternDuration",
			headerName: "Pattern Duration",
			width: 140,
			renderHeader: () => (
				<span style={{ padding: "0px 4px", fontWeight: "bold" }}>
					{"Pattern Duration"}
				</span>
			),
			renderCell: (params: any) => {
				const duration = params.row.numberOfDays ?? 0;

				return (
					<Typography
						sx={{
							textOverflow: "ellipsis",
							width: "100%",
							whiteSpace: "nowrap",
							overflow: "hidden",
							padding: "0px 4px",
							fontStyle: "roboto",
							fontWeight: "400",
							fontSize: "14px",
						}}
					>
						{!duration ? "-" : duration + " Days"}
					</Typography>
				);
			},
			valueGetter: (params: any) => params.row.numberOfDays,
		},
		{
			field: "isShiftPattern",
			headerName: "Shift Pattern",
			width: 190,
			renderCell: (params: any) => {
				return (
					<Box width='170px' display='flex' flexWrap='wrap'>
						{params.row?.schedule ? (
							<ShiftPatternMini
								teamId={params.row.id}
								schedule={getSchedule(params.row.schedule)}
								numberOfDays={params.row.numberOfDays}
							/>
						) : null}
					</Box>
				);
			},
		},
		{
			field: "totalHours",
			headerName: "Total Hours",
			width: 126,
			renderHeader: () => (
				<span style={{ padding: "0px 4px", fontWeight: "bold" }}>
					{"Total Hours"}
				</span>
			),
			renderCell: (params: any) => {
				const hours = getTotalShiftScheduleHours(
					params.row.schedule,
					allShiftSchedules
				);

				return (
					<Typography
						sx={{
							textOverflow: "ellipsis",
							width: "100%",
							whiteSpace: "nowrap",
							overflow: "hidden",
							padding: "0px 4px",
							fontStyle: "roboto",
							fontWeight: "400",
							fontSize: "14px",
						}}
					>
						{hours}
					</Typography>
				);
			},
			valueGetter: (params: any) => {
				const hours = getTotalShiftScheduleHours(
					params.row.schedule,
					allShiftSchedules
				);
				return parseInt(hours);
			},
		},
		{
			field: "workersAssigned",
			headerName: "Workers Assigned",
			width: 160,
			renderHeader: () => (
				<span style={{ padding: "0px 4px", fontWeight: "bold" }}>
					{"Workers Assigned"}
				</span>
			),
			renderCell: (params: any) => {
				return (
					<Typography
						sx={{
							textOverflow: "ellipsis",
							width: "100%",
							whiteSpace: "nowrap",
							overflow: "hidden",
							padding: "0px 4px",
							fontStyle: "roboto",
							fontWeight: "400",
							fontSize: "14px",
							textAlign: "center",
						}}
					>
						{params.row.numberOfWorkersAssigned ?? 0}
					</Typography>
				);
			},
			valueGetter: (params: any) => params.row.numberOfWorkersAssigned,
		},
		{
			field: "startDate",
			headerName: "Start Date",
			width: 120,
			renderHeader: () => (
				<span style={{ padding: "0px 4px", fontWeight: "bold" }}>
					{"Start Date"}
				</span>
			),
			renderCell: (params: any) => {
				const startDate = params.row.startDate
					? formatDate({
							dateStr: params.row.startDate,
							formatType: "NUMERIC_DATE",
					  })
					: "NA";
				return (
					<Typography
						sx={{
							textOverflow: "ellipsis",
							width: "100%",
							whiteSpace: "nowrap",
							overflow: "hidden",
							padding: "0px 4px",
							fontStyle: "roboto",
							fontWeight: "400",
							fontSize: "14px",
						}}
					>
						{startDate}
					</Typography>
				);
			},
			valueGetter: (params: any) => params.row.startDate,
		},
		{
			field: "endDate",
			headerName: "End Date",
			width: 120,
			renderHeader: () => (
				<span style={{ padding: "0px 4px", fontWeight: "bold" }}>
					{"End Date"}
				</span>
			),
			renderCell: (params: any) => {
				const endDate = params.row.endDate
					? formatDate({
							dateStr: params.row.endDate,
							formatType: "NUMERIC_DATE",
					  })
					: "NA";
				return (
					<Typography
						sx={{
							textOverflow: "ellipsis",
							width: "100%",
							whiteSpace: "nowrap",
							overflow: "hidden",
							padding: "0px 4px",
							fontStyle: "roboto",
							fontWeight: "400",
							fontSize: "14px",
						}}
					>
						{endDate}
					</Typography>
				);
			},
			valueGetter: (params: any) => params.row.endDate,
		},
		{
			field: "actions",
			headerName: "Actions",
			width: 70,
			sortable: false,
			cellClassName: "hover-column-cell",
			renderHeader: () => <></>,
			renderCell: (params: any) => (
				<Box
					width={40}
					display='flex'
					justifyContent='space-between'
					alignItems='center'
					className='hover-content'
				>
					<ModeIcon
						onClick={() => openEditTeam(params.row)}
						sx={{
							color: "rgba(0, 0, 0, 0.56)",
							fontSize: "16px",
							cursor: "pointer",
						}}
					/>
					<DeleteIcon
						sx={{
							color: "rgba(0, 0, 0, 0.56)",
							fontSize: "16px",
							cursor: "pointer",
						}}
						onClick={() =>
							deletePattern(params.row.id, params.row.hasWorkersAssigned)
						}
					/>
				</Box>
			),
		},
	];

	return {
		columns,
	};
};

export default useTeamsColumn;
