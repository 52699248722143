import CreateIcon from "@mui/icons-material/Create";
import Chip from "@mui/material/Chip";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles(() => ({
	chipRoot: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},
	chipIcon: {
		order: 2,
		marginLeft: "0.5rem",
	},
}));

const MyChip = ({ label }:any) => {
	const classes = useStyles();
	// console.log("MyChip label:", label);
	return (
		<Chip
			classes={{
				root: classes.chipRoot,
				icon: classes.chipIcon,
			}}
			size='small'
			label={label}
			variant='filled'
			//color='primary'
			icon={<CreateIcon sx={{ cursor: "pointer" }} />}
			style={{
				marginTop: "4px",
				marginBottom: "4px",
				marginRight: "4px",
				marginLeft: "4px",
			}}
			sx={{ paddingRight: "12px" }}
		/>
	);
};

export default MyChip;
