import { Box, Divider, Grid } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import Snackbar from "@mui/material/Snackbar";
import React from "react";

import {
	CrossSkillAppliedFilters,
	CrossSkillHeader,
	CrossSkillingFilter,
	CrossSkillingTable,
	useCrossSkillController,
} from "../../../../export/cross-skilling";

const ManageCrossSkilling = () => {
	const [
		{
			containerBox,
			data,
			allData,
			filters,
			allJobRoles,
			allSkills,
			allSkillLevels,
			notAssignedSkillLevel,
			allWorkers,
			allOrganizations,
			allOrganizationTypes,
			allManagers,
			allLocations,
			filterStatus,
			selectedWorkCenter,
			selectedLocation,
			allWorkCenters,
			updateWorkerSkillLevelStatus,
			workerTypes,
			dataProcessed,
			fetchManageWorkersDataStatus,
			fetchAllLocationsStatus,
			fetchAllManagersStatus,
			fetchAllOrganizationsStatus,
			fetchSkillLevelsStatus,
			fetchJobsByUserIdStatus,
			searchText,
			filterControllerEvents,
			filterControllerProps,
			filteredJobs,
			selectedColumns,
			columns,
			finalPickerColumn,
			skillMatrixFiltersStatus,
			skillMatrixAppliedFilters,
			totalWorkers,
			pageSize,
			selectedPage,
			skillInsightsEnabled,
			isSkillPriorityEnabled,
		},
		{
			setFilters,
			setData,
			setFilterStatus,
			dispatch,
			resetSkillLevelStatus,
			setSelectedWorkCenter,
			setSelectedLocation,
			setSearchText,
			handleSelectColumns,
			getFilter,
			setSelectedPage,
			setPageSize,
		},
	] = useCrossSkillController();

	return (
		<Box
			sx={{
				margin: "-25px",
				marginBottom: skillInsightsEnabled ? "-25px" : "24px",
			}}
		>
			<Grid ref={containerBox} container>
				<Grid item xs md lg>
					<CrossSkillHeader
						allSkillLevels={allSkillLevels}
						notAssignedSkillLevel={notAssignedSkillLevel}
						allJobRoles={allJobRoles}
						allSkills={allSkills}
						allData={allData}
						allLocations={allLocations}
						changeData={(data) => setData(data)}
						data={data}
						openFilter={(value) => setFilterStatus(value)}
						filterStatus={filterStatus}
						selectedWorkCenter={selectedWorkCenter}
						setSelectedWorkCenter={setSelectedWorkCenter}
						selectedLocation={selectedLocation}
						columns={finalPickerColumn}
						allWorkCenters={allWorkCenters}
						setFilterStatus={setFilterStatus}
						handleSelectColumns={handleSelectColumns}
						searchText={searchText}
						search={(value) => setSearchText(value)}
						fetchManageWorkersDataStatus={fetchManageWorkersDataStatus}
						fetchAllLocationsStatus={fetchAllLocationsStatus}
						fetchAllManagersStatus={fetchAllManagersStatus}
						fetchAllOrganizationsStatus={fetchAllOrganizationsStatus}
						fetchSkillLevelsStatus={fetchSkillLevelsStatus}
						fetchJobsByUserIdStatus={fetchJobsByUserIdStatus}
						skillMatrixFiltersStatus={skillMatrixFiltersStatus}
						resetFilter={filterControllerEvents.resetFilters}
						isSkillPriorityEnabled={isSkillPriorityEnabled}
					/>
					<Divider />
				</Grid>
				<Box sx={{ flexGrow: 1 }}>
					<Grid container wrap='nowrap' spacing={0}>
						<Collapse orientation='horizontal' in={filterStatus}>
							<Grid item xs={3} lg={2} md={4} sm={3}>
								<CrossSkillingFilter
									filterControllerProps={filterControllerProps}
									filterControllerEvents={filterControllerEvents}
									filterStatus={filterStatus}
									allWorkers={allWorkers}
									allSkillLevels={allSkillLevels}
									allManagers={allManagers}
									changeData={(data) => setData(data)}
									allWorkCenters={allWorkCenters}
									allLocations={allLocations}
									selectedLocation={selectedLocation}
									setSelectedWorkCenter={setSelectedWorkCenter}
									selectedWorkCenter={selectedWorkCenter}
									skillMatrixAppliedFilters={skillMatrixAppliedFilters}
								/>
							</Grid>
						</Collapse>
						<Grid item xs lg md sm>
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									alignItems: "flex-start",
									flex: 1,
								}}
							>
								<CrossSkillAppliedFilters
									filters={filters}
									filterStatus={filterStatus}
									allOrganizations={allOrganizations}
									allOrganizationTypes={allOrganizationTypes}
									allJobRoles={allJobRoles}
									allSkills={allSkills}
									allSkillLevels={allSkillLevels}
									workerTypes={workerTypes}
									allManagers={allManagers}
									resetFilters={filterControllerEvents.resetFilters}
								/>
							</Box>
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									alignItems: "flex-start",
									flex: 1,
								}}
							>
								<CrossSkillingTable
									data={data}
									filters={filters}
									allJobs={filteredJobs}
									allSkillLevels={allSkillLevels}
									notAssignedSkillLevel={notAssignedSkillLevel}
									allOrganizations={allOrganizations}
									filterStatus={filterStatus}
									dataProcessed={dataProcessed}
									fetchManageWorkersDataStatus={fetchManageWorkersDataStatus}
									fetchAllLocationsStatus={fetchAllLocationsStatus}
									selectedColumns={columns.filter((x) =>
										selectedColumns.includes(x.field)
									)}
									searchText={searchText}
									getFilter={getFilter}
									allOrganizationTypes={allOrganizationTypes}
									totalWorkers={totalWorkers}
									pageSize={pageSize}
									setPageSize={setPageSize}
									selectedPage={selectedPage}
									setSelectedPage={setSelectedPage}
								/>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Grid>
			{updateWorkerSkillLevelStatus === "fulfilled" ? (
				<Snackbar
					open={updateWorkerSkillLevelStatus === "fulfilled"}
					autoHideDuration={3000}
					onClose={() => dispatch(resetSkillLevelStatus())}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
				>
					<MuiAlert elevation={6} variant='filled' severity='success'>
						Skill Level Updated Successfully
					</MuiAlert>
				</Snackbar>
			) : null}
		</Box>
	);
};

export default ManageCrossSkilling;
