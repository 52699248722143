/* eslint-disable no-prototype-builtins */
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import React from "react";

import Button from "../../../../../../../design/button";
import DialogWrapper from "../../../../../../../design/dialog-wrapper";
import ConfigurationForm from "./configuration-form";

type WorkcenterBasedConfigurationDialogProps = {
	open: boolean;
	handleClose: () => void;
	shiftAllocationRules: any;
	setShiftAllocationRules: any;
	id?: number;
	locationData: any;
	setLocationData: any;
};

const WorkcenterBasedConfigurationDialog = ({
	open,
	handleClose,
	shiftAllocationRules,
	setShiftAllocationRules,
	id,
	locationData,
	setLocationData,
}: WorkcenterBasedConfigurationDialogProps) => {
	const config = shiftAllocationRules.find(
		(rule: { shiftAllocationTypeId: number }) =>
			rule.shiftAllocationTypeId === id
	);
const [initialValues, setInitialValues] = React.useState(config);

	const onDialogClose = () => {
		setInitialValues(config);
		handleClose();
	}

	return (
		<Dialog
			open={open}
			onClose={onDialogClose}
			aria-describedby='alert-dialog-slide-description'
			maxWidth={false}
		>
			<DialogWrapper
				dialogTitle='Workcenter based allocation - configuration'
				dialogSubTitle={
					"Configure the rules that you want to apply for the shift allocation"
				}
				dialogContent={
					<ConfigurationForm
						shiftAllocationRules={shiftAllocationRules}
						setShiftAllocationRules={setShiftAllocationRules}
						id={id}
					/>
				}
				actionButtons={
					<Box
						display='flex'
						justifyContent='space-between'
						width='160px'
						mt={-5}
					>
						<Button color='primary' variant='text' onClick={onDialogClose}>
							Cancel
						</Button>
						<Button
							variant='contained'
							color='primary'
							type='submit'
							onClick={() => {
								setLocationData({
									...locationData,
									allocationSettings: shiftAllocationRules,
								});

								onDialogClose();
							}}
							disabled={ JSON.stringify(initialValues) === JSON.stringify(config)}
						>
							Done
						</Button>
					</Box>
				}
			/>
		</Dialog>
	);
};

export default WorkcenterBasedConfigurationDialog;
