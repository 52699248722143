import { useSelector } from "react-redux";

const useLocationData = () => {
  const locations = useSelector(
    (state: any) => state.gatAdminConfiguration.locations
  );

  const companySettingsObj = useSelector(
    (state: any) => state.gatAdminConfiguration.companySettings
  );

  const getLocationsStatus = useSelector(
    (state: any) => state.gatAdminConfiguration.getLocations.status
  );
  const getLocationsErrorMessage = useSelector(
    (state: any) => state.gatAdminConfiguration.getLocations.errorMessage
  );

  return {
    locations,
    getLocationsErrorMessage,
    companySettingsObj,
    getLocationsStatus,
  };
};

export default useLocationData;
