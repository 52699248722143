// TODO - Type 'any' needs to be fixed.
import { Box, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import format from "date-fns/format";
import React from "react";

// import { JobRole } from "../../../../../design/job-roles";
import { Job, JobsState } from "./types";

export const useStyles = makeStyles((theme) => ({
	cancelButton: {
		marginRight: "auto",
	},
	dialogContentRoot: {
		maxWidth: "70%",
	},
	formControlRoot: {
		width: "100%",
	},
	btnLabel: {
		position: "relative",
	},
	progressIndicator: {
		position: "absolute",
		left: "50%",
	},
	formRoot: {
		width: "100%",
	},
}));

//set object for job id to false
export const checkboxes = ({ allJobRoles }: { allJobRoles: Job[] }) =>
	allJobRoles.length > 0 &&
	allJobRoles.reduce((acc: any, key: Job) => {
		acc[key.id] = false;
		return acc;
	}, {});

//set object for job id to true eg: {1:true,5:true}
export const checkboxesAllTrue = ({ allJobRoles }: { allJobRoles: Job[] }) =>
	allJobRoles.length > 0 &&
	allJobRoles.reduce((acc: any, key: Job) => {
		acc[key.id] = true;
		return acc;
	}, {});

export const isSelectAllTrue = ({ jobs }: { jobs: JobsState }) => {
	const keys: any = Object.keys(jobs);
	if (keys.length > 0) {
		for (let i = 0; i < keys.length; i++) {
			if (!jobs[keys[i]]) {
				return false;
			}
		}
		return true;
	}
	return false;
};
