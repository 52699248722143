import React from "react";
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";

import WeeklySchedule from "../../js/components/weekly-schedule";
import MiniDrawer from "../components/drawer";

export default function DetectHome({ ...rest }) {
	const userRoles = useSelector(
		(state:any) => state.user?.userData?.userRoles ?? ["ShiftSupervisor"]
	);
	const fullTimeSchedule = useSelector(
		(state:any) => state.user.userData.fullTimeSchedule
	);
	if (userRoles.includes("ShiftSupervisor")) {
		if (fullTimeSchedule) {
			return <Route render={({ ...rest }) => <WeeklySchedule />} />;
		} else {
			return <Route render={({ ...rest }) => <MiniDrawer />} />;
		}
	}
	if (userRoles.includes("LocationAdmin"))
		return <Route render={({ ...rest }) => <MiniDrawer />} />;
	else return <Route {...rest} render={(props) => <MiniDrawer />} />;
}
