// TODO - Type 'any' needs to be fixed.
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import BackHandIcon from "@mui/icons-material/BackHand";
import CampaignIcon from "@mui/icons-material/Campaign";
import CancelIcon from "@mui/icons-material/Cancel";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { Chip, Tooltip } from "@mui/material";
import {
	deepPurple,
	green,
	grey,
	lightBlue,
	lime,
	red,
	teal,
} from "@mui/material/colors";
import React from "react";

interface ShiftStatusProps {
	label:
		| "Applied"
		| "Confirmed"
		| "Declined"
		| "Viewed"
		| "Notified"
		| "Assigned"
		| "Not Eligible"
		| "Not Notified"
		| "Cancelled";
	reason?: string;
}

const ShiftStatus: React.FC<ShiftStatusProps> = ({ label, reason }) => {
	const iconColor = {
		Applied: lightBlue[800],
		Confirmed: green[700],
		Declined: red[800],
		Viewed: lime[900],
		Notified: teal[900],
		Assigned: deepPurple[800],
		"Not Eligible": undefined,
		"Not Notified": undefined,
		Cancelled: red[300],
	};

	const backColor = {
		Applied: lightBlue[50],
		Confirmed: green[50],
		Declined: red[50],
		Viewed: lime[50],
		Notified: teal[50],
		Assigned: deepPurple[50],
		"Not Eligible": grey[50],
		"Not Notified": grey[50],
		Cancelled: grey[100],
	};

	const icons = {
		Applied: (
			<BackHandIcon
				sx={{
					color: `${iconColor[label]} !important`,
                    fontSize: "18px"
				}}
			/>
		),
		Confirmed: (
			<ThumbUpIcon
				sx={{
					color: `${iconColor[label]} !important`,
                    fontSize: "18px"
				}}
			/>
		),
		Declined: (
			<CancelIcon
				sx={{
					color: `${iconColor[label]} !important`,
                    fontSize: "18px"
				}}
			/>
		),
		Viewed: (
			<RemoveRedEyeIcon
				sx={{
					color: `${iconColor[label]} !important`,
                    fontSize: "18px"
				}}
			/>
		),
		Notified: (
			<CampaignIcon
				sx={{
					color: `${iconColor[label]} !important`,
                    fontSize: "18px"
				}}
			/>
		),
		Assigned: (
			<AssignmentTurnedInIcon
				sx={{
					color: `${iconColor[label]} !important`,
                    fontSize: "18px"
				}}
			/>
		),
		"Not Eligible": (
			undefined
		),
		"Not Notified": (
			undefined
		),
		Cancelled: (
			<DoDisturbIcon
				sx={{
					color: `${iconColor[label]} !important`,
					fontSize: "18px"
				}}
			/>
		),
	};
	return (
		!reason ?
		<Chip
			label={label}
			icon={icons[label]}
			size="small"
			sx={{
				backgroundColor: backColor[label],
                padding: "3px 4px"
			}}
		/> 
		: 
		<Chip
			label={label}
			icon={icons[label]}
			deleteIcon={
				<Tooltip title = {reason ?? ""} placement="bottom-start">
					<InfoOutlinedIcon
						sx={{
							fontSize: "16px !important",
							color: "#00000099 !important",
						}}
					/>
				</Tooltip>
			}
			onDelete={() => {
				// Do nothing
			}}
			size="small"
			sx={{
				backgroundColor: backColor[label],
				padding: "3px 4px"
			}}
		/>
	);
};

export default ShiftStatus;
