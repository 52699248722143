/* eslint-disable no-mixed-spaces-and-tabs */
// TODO - Type 'any' needs to be fixed.
import { format, isFuture } from "date-fns";

interface Job {
	jobId: string;
	jobName: string;
	color: string;
	isExpired: boolean;
	skills: any[];
}

export const getAllSkills = (
	jobs: Job[],
	otherSkills: any[],
	skillPriorityEnabled: boolean,
	allSkillsWithPriority: any[] = []
) => {
	const skillsObj: any = {};
	jobs.forEach((job) => {
		const currentJob = {
			id: job.jobId,
			jobName: job.jobName,
		};
		job.skills.forEach((skill) => {
			if (skillsObj[skill.skillId]) {
				skillsObj[skill.skillId] = {
					id: skill.skillId,
					skillName: skill.skillName,
					associatedJobs: [
						...skillsObj[skill.skillId].associatedJobs,
						currentJob,
					],
					...skill,
				};
			} else {
				skillsObj[skill.skillId] = {
					id: skill.skillId,
					skillName: skill.skillName,
					associatedJobs: [currentJob],
					...skill,
				};
			}
		});
	});
	const oSKills = otherSkills.map((skill) => ({ ...skill, id: skill.skillId }));
	let result = [...Object.values(skillsObj), ...oSKills];

	if (skillPriorityEnabled && allSkillsWithPriority) {
		result = result.map((skill) => {
			const skillWithPriority = allSkillsWithPriority.find(
				(s: any) => s.id === skill.skillId
			);
			if (skillWithPriority) {
				return {
					...skill,
					skillPriority: skillWithPriority.priority,
				};
			}
			return skill;
		});
	}
	return result;
};

export const generateJobString = (jobs: string | any[]) => {
	const MAX_JOBS_DISPLAYED = 2;
	let jobString = "";
	let seeMore = "";
	let remainig = "";

	for (let i = 0; i < jobs.length && i < MAX_JOBS_DISPLAYED; i++) {
		if (i > 0) {
			jobString += ", ";
		}
		jobString += jobs[i].jobName;
	}

	if (jobs.length > MAX_JOBS_DISPLAYED) {
		remainig += `, +${jobs.length - MAX_JOBS_DISPLAYED} more`;
		for (let i = MAX_JOBS_DISPLAYED; i < jobs.length; i++) {
			seeMore += jobs[i].jobName + ", ";
		}
	}
	seeMore = seeMore.slice(0, -2);

	return { jobString, remainig, seeMore };
};

export const generateSkillString = (skills: any[]) => {
	const MAX_SKILLS_DISPLAYED = 2;
	let skillString = "";
	let seeMore = "";
	let remainig = "";

	for (let i = 0; i < skills.length && i < MAX_SKILLS_DISPLAYED; i++) {
		if (i > 0) {
			skillString += ", ";
		}
		skillString += skills[i].name;
	}

	if (skills.length > MAX_SKILLS_DISPLAYED) {
		remainig += `, +${skills.length - MAX_SKILLS_DISPLAYED} more`;
		for (let i = MAX_SKILLS_DISPLAYED; i < skills.length; i++) {
			seeMore += skills[i].name + ", ";
		}
	}
	seeMore = seeMore.slice(0, -2);

	return { skillString, remainig, seeMore };
};

export const distinctElements = (
	array1: any[] | null,
	array2: any[],
	key = "id"
) => {
	if (!array1 || !array2) {
		return [];
	}

	const onlyInArray1 =
		array1?.filter(
			(item1: { [x: string]: any }) =>
				!array2.some((item2: { [x: string]: any }) => item1[key] === item2[key])
		) || [];

	// Find elements in array2 not in array1
	const onlyInArray2 = array2.filter(
		(item2: { [x: string]: any }) =>
			!array1?.some((item1: { [x: string]: any }) => item1[key] === item2[key])
	);

	// Combine both results to get distinct elements
	return [...onlyInArray1, ...onlyInArray2];
};

export const truncateFileName = (fileName: string, nameLength: number) => {
	const ext = fileName.substring(fileName.lastIndexOf("."));
	const name = fileName.substring(0, fileName.lastIndexOf("."));
	if (name.length > nameLength) {
		return `${name.substring(0, nameLength)}...${ext}`;
	}
	return fileName;
};

export const getSkillTableRows = (rows: any[], text: string) => {
	const searchText = text.toLowerCase();
	const temp = rows.map((row) => {
		const skillName = row.skillName;
		const status =
			!row.validFrom || isFuture(new Date(row.validFrom))
				? ""
				: !row.validTo || isFuture(new Date(row.validTo))
				? "Active"
				: "Expired";
		const skillLevel = row.levelName;
		const jobs = row?.associatedJobs ?? [];
		const { jobString, remainig, seeMore } = generateJobString(jobs);
		const associatedJobs = jobs.length === 0 ? "-" : `${jobString} ${seeMore}`;

		const validFromDate = row.validFrom
			? format(new Date(row.validFrom + "T00:00:00"), "MM/dd/yyyy") +
			  "T00:00:00"
			: "-";

		const validToDate = row.validTo
			? format(new Date(row.validTo + "T00:00:00"), "MM/dd/yyyy") + "T00:00:00"
			: "-";
		const skillPriority = row.skillPriority ? `${row.skillPriority.code} (${row.skillPriority.name})` : "";

		return {
			...row,
			rowSkillName: skillName,
			rowStatus: status,
			rowSkillLevel: skillLevel,
			rowJobs: associatedJobs,
			rowValidFrom: validFromDate,
			rowValidTo: validToDate,
			skillPriority: skillPriority,
		};
	});

	if (searchText) {
		const searchTextLower = searchText.toLowerCase();
		return temp.filter((row) => {
			return (
				row.skillName.toLowerCase().includes(searchTextLower) ||
				row.rowJobs.toLowerCase().includes(searchTextLower) ||
				row.rowSkillLevel.toLowerCase().includes(searchTextLower) ||
				row.rowStatus.toLowerCase().includes(searchTextLower) ||
				row.rowJobs.toLowerCase().includes(searchTextLower) ||
				row.rowValidFrom.toLowerCase().includes(searchTextLower) ||
				row.rowValidTo.toLowerCase().includes(searchTextLower) ||
				row.skillPriority.toLowerCase().includes(searchTextLower)
			);
		});
	}

	return temp;
};

export const getCertificationTableRows = (
	rows: any[],
	text: string,
	allSkills: any[]
) => {
	if (!text) return rows;
	const searchText = text.toLowerCase();
	const temp = rows.map((row) => {
		const certName = row.certName ?? "";
		const status =
			!row.validFrom || isFuture(new Date(row.validFrom))
				? "-"
				: !row.validTo || isFuture(new Date(row.validTo))
				? "Active"
				: "Expired";

		const skillIds =
			row.skillIds?.split(",")?.map((id: string) => parseInt(id)) ?? [];
		const skills = allSkills.filter((skill: { id: number }) =>
			skillIds.includes(skill.id)
		);
		const { skillString, remainig, seeMore } = generateSkillString(skills);
		const associatedSkills =
			skills.length === 0 ? "-" : `${skillString} ${seeMore}`;

		const validFromDate = row.validFrom
			? format(new Date(row.validFrom + "T00:00:00"), "MM/dd/yyyy") +
			  "T00:00:00"
			: "-";

		const validToDate = row.validTo
			? format(new Date(row.validTo + "T00:00:00"), "MM/dd/yyyy") + "T00:00:00"
			: "-";

		return {
			...row,
			rowCertName: certName,
			rowStatus: status,
			rowSkills: associatedSkills,
			rowValidFrom: validFromDate,
			rowValidTo: validToDate,
		};
	});

	if (searchText) {
		const searchTextLower = searchText.toLowerCase();
		return temp.filter((row) => {
			return (
				row.rowCertName.toLowerCase().includes(searchTextLower) ||
				row.rowStatus.toLowerCase().includes(searchTextLower) ||
				row.rowSkills.toLowerCase().includes(searchTextLower) ||
				row.rowValidFrom.toLowerCase().includes(searchTextLower) ||
				row.rowValidTo.toLowerCase().includes(searchTextLower)
			);
		});
	}

	return temp;
};
