import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import React from "react";
import {useDispatch} from "react-redux";

import {setShiftsByFilter} from "../../../../../js/features/ShiftScheduler/ShiftSchedulerSlice";
import { JOB, SKILLS } from "../../../../assets/constants";

interface SwitchJobSkillsProps {
    shiftsByFilter: string;
}

const SwitchShiftsFilter: React.FC<SwitchJobSkillsProps> = (
    {
        shiftsByFilter,
    }: SwitchJobSkillsProps
) => {
    const dispatch = useDispatch();
    const handleToggle = (changeTo: string) => {
        dispatch(setShiftsByFilter(changeTo));
    };

    const shiftsAvailableFilters = [JOB, SKILLS];

    return (
        <ButtonGroup
            aria-label='outlined primary button group'
            sx={{
                display: "flex",
                height: "32px",
                justifyContent: "center",
                alignItems: "center",
                margin: "0px 24px",
                border: "none",
                flex: 1,
            }}
        >
            {shiftsAvailableFilters.map((filter: string, index) => {
                let borderRadius = "0px";
                if (index === 0) {
                    borderRadius = "4px 0px 0px 4px";
                } else if (index === shiftsAvailableFilters.length -1) {
                    borderRadius = "0px 4px 4px 0px";
                }
                return (
                    <Box
                        key={`shifts-by-filter-${filter}`}
                        sx={{
                            flex: 1,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Button
                            sx={{
                                flex: 1,
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: borderRadius,
                                height: "32px",
                                fontFamily: "Roboto",
                                fontSize: "13px",
                                fontWeight: "500",
                                lineHeight: "18px",
                                letterSpacing: "0.46px"
                            }}
                            variant={shiftsByFilter === filter ? "contained" : "outlined"}
                            onClick={() => handleToggle(filter)}
                        >
                            {filter}
                        </Button>
                    </Box>
                )
            })}
        </ButtonGroup>
    );
};

export default SwitchShiftsFilter;
