/* eslint-disable no-mixed-spaces-and-tabs */
import { AddSharp, RemoveSharp } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import format from "date-fns/format";
import { useEffect, useState } from "react";
import React from "react";

import {Highlight} from "../../../../../../export/helpers";
import {
	BORDER_COLOR,
	TODAY_HIGHLIGHT_COLOR,
	WEEKEND_SHIFT_CELL_COLOR,
} from "../../../constants/colors";
import useScheduleController from "../../../controllers/schedule-controller";

export const Row = ({
	workerId,
	workerName,
	datesArrayOfSelectedWeek,
	availabilityChanged,
	searchValue,
}) => {
	const [
		{ activeAvailability },
		{
			isWeekend,
			isToday,
			isPreviousDate,
			getAvailabilityIcon,
			updateAvailabilityForWorkerAtDate,
			getDefaultAvailabilityForWorkerInDay,
		},
	] = useScheduleController();
	const DayCell = ({
		backgroundColor = "rgba(255,255,255,0.5)",
		selectedDate,
	}) => {
		const [isHovering, setIsHovering] = useState(false);
		useEffect(() => {
			const availabilityDate = format(selectedDate, "yyyy-MM-dd");
			if (
				Object.prototype.hasOwnProperty.call(
					activeAvailability,
					"availabilityDate"
				) &&
				Object.prototype.hasOwnProperty.call(activeAvailability, "workerId")
			) {
				if (
					activeAvailability.availabilityDate === availabilityDate &&
					activeAvailability.workerId === workerId
				) {
					setIsHovering(true);
				}
			}
		}, [selectedDate]);
		return (
			<Grid
				borderLeft={1}
				borderColor={BORDER_COLOR}
				item
				xs={1.4}
				sx={{
					display: "flex",
					minHeight: "72px",
					maxHeight: "72px",
					minWidth: "123px",
				}}
				onMouseEnter={() => setIsHovering(true)}
				onMouseLeave={() => setIsHovering(false)}
			>
				<Box
					sx={{
						backgroundColor: isWeekend(selectedDate)
							? WEEKEND_SHIFT_CELL_COLOR
							: backgroundColor,
						minWidth: "136px",
						flex: 1,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<FormControl
						sx={{
							flex: 1,
							display: "flex",
							width: "100%",
						}}
					>
						<Select
							disabled={isPreviousDate(selectedDate)}
							onChange={(e) => {
								availabilityChanged();
								const availability = {
									availabilityDate: format(selectedDate, "yyyy-MM-dd"),
									workerId: workerId,
									typeId: e.target.value ?? 0,
									changed: true,
								};
								updateAvailabilityForWorkerAtDate(availability);
							}}
							value={
								getDefaultAvailabilityForWorkerInDay(
									workerId,
									format(selectedDate, "yyyy-MM-dd")
								) === 4
									? 1
									: getDefaultAvailabilityForWorkerInDay(
											workerId,
											format(selectedDate, "yyyy-MM-dd")
									  )
							}
							renderValue={(value) => {
								return (
									<Box
										sx={{
											display: "flex",
											justifyContent: "flex-start",
											alignItems: "center",
											cursor: "pointer",
										}}
									>
										{getAvailabilityIcon(value)}
									</Box>
								);
							}}
							sx={{
								flex: 1,
								boxShadow: "none",
								".MuiOutlinedInput-notchedOutline": { border: 0 },
								"&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
									{
										border: 0,
									},
								"&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
									{
										border: 0,
									},
							}}
						>
							<MenuItem value={1}>
								{getAvailabilityIcon(1)}
								<Typography
									sx={{
										color: "rgba(0,0,0,0.87)",
										fontFamily: "Roboto",
										fontWeight: 400,
										fontSize: "16px",
										marginLeft: "4px",
									}}
								>
									Available (8 hours)
								</Typography>
							</MenuItem>
							<MenuItem value={2}>
								{getAvailabilityIcon(2)}
								<Typography
									sx={{
										color: "rgba(0,0,0,0.87)",
										fontFamily: "Roboto",
										fontWeight: 400,
										fontSize: "16px",
										marginLeft: "4px",
									}}
								>
									Absent
								</Typography>
							</MenuItem>
							<MenuItem value={3}>
								{getAvailabilityIcon(3)}
								<Typography
									sx={{
										color: "rgba(0,0,0,0.87)",
										fontFamily: "Roboto",
										fontWeight: 400,
										fontSize: "16px",
										marginLeft: "4px",
									}}
								>
									In Training
								</Typography>
							</MenuItem>
						</Select>
					</FormControl>
					{(getDefaultAvailabilityForWorkerInDay(
						workerId,
						format(selectedDate, "yyyy-MM-dd")
					) === 1 ||
						getDefaultAvailabilityForWorkerInDay(
							workerId,
							format(selectedDate, "yyyy-MM-dd")
						) === 4) && (
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								marginTop: "-24px",
							}}
						>
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									marginBottom: "-20px",
									zIndex: 99,
								}}
							>
								<Box
									sx={{
										backgroundColor: "#F8F8F8",
										borderRadius: "15px",
										width: "24px",
										padding: "2px",
										border: 1,
										borderWidth: "0.5px",
										borderColor: "#F8F8F8",
									}}
								>
									<Typography
										sx={{
											textAlign: "center",
											color: "rgba(0,0,0,0.87)",
											fontSize: "10px",
											fontFamily: "Roboto",
										}}
									>
										OT
									</Typography>
								</Box>
							</Box>
							<Box
								sx={{
									flex: 1,
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									justifyContent: "center",
									padding: "12px",
								}}
							>
								<Box
									sx={{
										width: "32px",
										height: "32px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
									}}
								>
									{isHovering &&
										!isPreviousDate(selectedDate) &&
										getDefaultAvailabilityForWorkerInDay(
											workerId,
											format(selectedDate, "yyyy-MM-dd")
										) === 4 && (
											<Box
												sx={{
													flex: 1,
													cursor: "pointer",
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
													marginRight: "8px",
												}}
												onClick={() => {
													availabilityChanged();
													const availability = {
														availabilityDate: format(
															selectedDate,
															"yyyy-MM-dd"
														),
														workerId: workerId,
														typeId: 1,
														changed: true,
													};
													updateAvailabilityForWorkerAtDate(availability);
												}}
											>
												<RemoveSharp
													sx={{
														fontSize: "16px",
														color: "#2F4D8B",
													}}
												/>
											</Box>
										)}
								</Box>
								<Box
									sx={{
										display: "flex",
										width: "48px",
										backgroundColor: "#F8F8F8",
										borderRadius: "4px",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<InputBase
										disabled={isPreviousDate(selectedDate)}
										placeholder=''
										type='number'
										inputProps={{
											"aria-label": "Search job",
											style: {
												textAlign: "center",
												color: "rgba(0, 0, 0, 0.6)",
												fontSize: "12px",
												fontFamily: "Roboto",
												lineHeight: "20px",
												letterSpacing: "0.15px",
											},
										}}
										onBlur={() => {
											// changeRequirementForDayAndJob(requirement.workerCount, false)
										}}
										value={
											getDefaultAvailabilityForWorkerInDay(
												workerId,
												format(selectedDate, "yyyy-MM-dd")
											) === 4
												? 2
												: 0
										}
										onChange={(event) => {
											// Do nothing
										}}
									/>
								</Box>
								<Box
									sx={{
										width: "32px",
										height: "32px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
									}}
								>
									{isHovering &&
										!isPreviousDate(selectedDate) &&
										getDefaultAvailabilityForWorkerInDay(
											workerId,
											format(selectedDate, "yyyy-MM-dd")
										) === 1 && (
											<Box
												sx={{
													flex: 1,
													cursor: "pointer",
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
													marginLeft: "8px",
												}}
												onClick={() => {
													availabilityChanged();
													const availability = {
														availabilityDate: format(
															selectedDate,
															"yyyy-MM-dd"
														),
														workerId: workerId,
														typeId: 4,
														changed: true,
													};
													updateAvailabilityForWorkerAtDate(availability);
												}}
											>
												<AddSharp
													sx={{
														fontSize: "16px",
														color: "#2F4D8B",
													}}
												/>
											</Box>
										)}
								</Box>
							</Box>
						</Box>
					)}
				</Box>
			</Grid>
		);
	};
	return (
		<Grid
			container
			wrap='nowrap'
			sx={{
				display: "flex",
				flex: "1 0 auto",
			}}
		>
			<Grid
				item
				xs={2.2}
				sx={{
					backgroundColor: "rgba(255,255,255,0.5)",
					display: "flex",
					alignItems: "flex-start",
					minWidth: "123px",
				}}
			>
				<Box
					sx={{
						minHeight: "72px",
						display: "flex",
						paddingTop: "24px",
					}}
				>
					<Typography
						sx={{
							marginLeft: "24px",
							fontSize: "16px",
							fontFamily: "Roboto",
						}}
					>
						<Highlight searchText={searchValue} value={workerName} />
					</Typography>
				</Box>
			</Grid>
			<>
				{datesArrayOfSelectedWeek.map((date, index) => {
					return (
						<DayCell
							key={`day-${index}`}
							// No shifts in weekend for demo TODO: Demo code
							selectedDate={date}
							backgroundColor={
								isToday(date) ? TODAY_HIGHLIGHT_COLOR : "rgba(255,255,255,0.5)"
							}
						/>
					);
				})}
			</>
		</Grid>
	);
};
