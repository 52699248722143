import TextField from "@gigandtake/textfield";
import { Box, Button, Divider, Typography } from "@mui/material";
//import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import DialogWrapper from "../../../../../../design/dialog-wrapper";
import JobRolesList from "../../../../../../design/job-roles/ui/job-roles-list";

//import FormControlLabel from "@mui/material/FormControlLabel";
// import JobColor from "../../../../../design/ui/job-color";

const JobDialog = ({ open, setJobDialog, companyData, setCompanyData }:any) => {
	const jobExpirySettings = companyData?.jobExpirySettings ?? [];

	const allJobRoles = useSelector(
		(state:any) => state.supervisor?.masterData?.allJobRoles
	);

	const checkboxes =
		allJobRoles.length > 0 &&
		allJobRoles.reduce((acc:any, key:any) => {
			const isExist = jobExpirySettings.find((job:any) => job.jobId === key.id);
			acc[key.id] = isExist && jobExpirySettings.length > 0 ? true : false;
			return acc;
		}, {/* Do Nothing */});

	const [jobs, setJobs] = useState(checkboxes);
	// console.log("jobs", companyData, jobExpirySettings, jobs);

	const handleClick = ({ id }:any) => {
		const job = allJobRoles.find((job:any) => job.id === id);
		const { id: jobId, name } = job;
		if (jobs[id]) {
			const filterJob = jobExpirySettings.filter((job:any) => job.jobId !== jobId);
			setCompanyData({
				...companyData,
				jobExpirySettings: filterJob,
			});
		} else {
			setCompanyData({
				...companyData,
				jobExpirySettings: [
					...jobExpirySettings,
					{ jobId: jobId, jobName: name, hours: 8, days: 60 },
				],
			});
		}
		setJobs({ ...jobs, [id]: !jobs[id] });
	};

	return (
		<Dialog
			onClose={() => {/* Do Nothing */}}
			aria-labelledby='add-jobs-customisation'
			open={open}
			maxWidth={false}
		>
			<DialogWrapper
				dialogTitle='Add jobs for customisation'
				dialogSubTitle={""}
				dialogContent={
					<Box height={300} width={600}>
						<Box
							sx={{
								"& .MuiTextField-root": { width: "35ch" },
							}}
						>
							<TextField
								label='Search'
								hiddenLabel={false}
								placeholder='Search'
								variant='filled'
								onChange={(e:any) => {/* Do Nothing */}}
							/>
						</Box>
						<Typography
							sx={{
								color: "rgba(0, 0, 0, 0.60)",
								fontFamily: "Roboto",
								fontSize: "14px",
								fontWeight: 400,
								marginTop: "16px",
								marginLeft: "8px",
							}}
						>
							Jobs ({allJobRoles.length})
						</Typography>
						<Divider sx={{ marginTop: "8px" }} />
						{/* <Box height={100}>
							{allJobRoles?.map((job) => (
								<Box
									sx={{
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
										padding: "8px",
									}}
									key={job.id}
								>
									<JobColor color={`#${job.hexColor}`} />
									<Typography ml={1}>{job.name}</Typography>
								</Box>
							))}
						</Box> */}
						<Box
							mt={5}
							height={250}
							sx={{ overflowY: "scroll", border: "none" }}
						>
							<Box display='flex' width='100%' height='auto' mb={2}>
								<Box
									// display='flex'
									// flexWrap='wrap'
									height='auto'
									borderRadius={2}
									width='100%'
								>
									{/* {allJobRoles.length > 3 && (
										<Box width='100%'>
											<FormControlLabel
												control={
													<Checkbox
														checked={checkAll}
														//onClick={(e) => handleCheckAll(e.target.checked)}
														inputProps={{ "aria-label": "uncontrolled" }}
													/>
												}
												label={`Select Job roles (${allJobRoles.length})`}
											/>
										</Box>
									)} */}
									<JobRolesList
										onSelectCheckbox={handleClick}
										state={jobs}
										enableDivider={true}
										customStyles={{
											width: "100%",
											minWidth: "67px",
											bgcolor: "background.paper",
											// display: "flex",
											// flexWrap: "wrap",
										}}
										disableGutters={true}
										jobRoles={allJobRoles}
									/>
								</Box>
							</Box>
						</Box>
					</Box>
				}
				actionButtons={
					<Box display='flex' justifyContent='space-between' width='160px'>
						<Button
							color='primary'
							variant='text'
							onClick={() => setJobDialog(false)}
						>
							Cancel
						</Button>
						<Button
							variant='contained'
							color='primary'
							type='submit'
							onClick={() => {
								setJobDialog(false);
							}}
						>
							Done
						</Button>
					</Box>
				}
			/>
		</Dialog>
	);
};

export default JobDialog;
