// TODO - Type 'any' needs to be fixed.
import { compareAsc, differenceInHours } from "date-fns";
import format from "date-fns/format";
import { useEffect, useState } from "react";

import {JOB, SKILLS} from "../../../../../../assets/constants";
import useSchedulerData from "../../../../controllers/use-scheduler-data";
import {Shift, SkillInShift} from "../types";
import {columns, columnsBySkills} from "../utils";

interface UseViewSummaryProps {
    allWeekShifts: Shift[];
	allWeekShiftsBySkills: Shift[];
}

const useViewSummary = ({ allWeekShifts, allWeekShiftsBySkills }: UseViewSummaryProps) => {
	const { minDurationHrs, maxDurationHrs, filterJobRoles, shiftsByFilter } = useSchedulerData();
	const [rows, setRows] = useState<any>([]);
	const [selectedColumn, setSelectedColumns] = useState<any>(columns);

	useEffect(() => {
		const rowData = [];
		const getPropsForRow = (shift: Shift): {
			shiftTime: string;
			time: { start: string; end: string };
			shiftDateTime: string;
			shiftId: number;
			isValid: boolean;
		} => {
			const time = {
				start: `${format(new Date(shift?.startDateTime), "h:mm a")}`,
				end: `${format(new Date(shift?.endDateTime), "h:mm a")}`,
			};
			const hrsDifference = differenceInHours(
				new Date(shift?.endDateTime),
				new Date(shift?.startDateTime)
			);
			const shiftTime = `${format(
				new Date(shift?.startDateTime),
				"EEE, MMM d, yyyy"
			)}`;
			const shiftDateTime = shift?.startDateTime;
			const shiftId = shift?.id;
			const isValid = hrsDifference >= minDurationHrs && hrsDifference <= maxDurationHrs;

			return { shiftTime, time, shiftDateTime, shiftId, isValid };
		}
		if (shiftsByFilter === JOB) {
			if (allWeekShifts) {
				const jobRoleShiftMapping :any = {};
				allWeekShifts.forEach((shift) => {
					const jobRole = shift.jobName;
					const {
						shiftTime,
						time,
						shiftDateTime,
						shiftId,
						isValid,
					} = getPropsForRow(shift);

					if (!Object.hasOwn(jobRoleShiftMapping, jobRole)) {
						jobRoleShiftMapping[jobRole] = [];
					}
					jobRoleShiftMapping[jobRole].push({
						shiftTime,
						time,
						shiftDateTime,
						shiftId,
						isValid
					});
				});

				const jobRoles = Object.keys(jobRoleShiftMapping);

				for (let i = 0; i < jobRoles.length; i++) {
					const jobRole = jobRoles[i];
					const currentJobRole = filterJobRoles.filter(({ name }: {name: string}) => {
						return name === jobRole;
					})[0];
					rowData.push({
						jobRole: currentJobRole,
						shiftDetails: jobRoleShiftMapping[jobRole],
						id: jobRole,
					});
				}
			}
			setSelectedColumns(columns);
		} else if (shiftsByFilter === SKILLS) {
			const skillsShiftMapping :any = {};
			allWeekShiftsBySkills.forEach((shift) => {
				const skillsUniqueLabelArray = shift.skills.map(
					(skill: SkillInShift) => skill.skillId
				);
				const skillsUniqueLabel = skillsUniqueLabelArray.sort().join(",");
				const {
					shiftTime,
					time,
					shiftDateTime,
					shiftId,
					isValid,
				} = getPropsForRow(shift);

				if (!Object.hasOwn(skillsShiftMapping, skillsUniqueLabel)) {
					skillsShiftMapping[skillsUniqueLabel] = [];
				}
				skillsShiftMapping[skillsUniqueLabel].push({
					shiftTime,
					time,
					shiftDateTime,
					shiftId,
					isValid,
					skills: shift.skills
				});
			});

			const skills = Object.keys(skillsShiftMapping);

			for (let i = 0; i < skills.length; i++) {
				const rowSkills = skills[i];
				rowData.push({
					skills:  skillsShiftMapping[rowSkills][0].skills,
					shiftDetails: skillsShiftMapping[rowSkills],
					id: rowSkills,
				});
			}
			setSelectedColumns(columnsBySkills);
		}

		const sortRows = rowData.map((row) => {
			const { shiftDetails } = row;
			const sortedDates = [...shiftDetails].sort((date1, date2) => {
				const startOfWeekDate1 = new Date(date1.shiftDateTime);
				const startOfWeekDate2 = new Date(date2.shiftDateTime);

				return compareAsc(startOfWeekDate1, startOfWeekDate2);
			});
			return { ...row, shiftSortedDetails: sortedDates };
		});
		setRows(sortRows);
	}, [allWeekShiftsBySkills, filterJobRoles, allWeekShifts, maxDurationHrs, minDurationHrs, shiftsByFilter]);

	return [{ minDurationHrs, maxDurationHrs, rows, selectedColumn }, { setRows }];
};

export default useViewSummary;
