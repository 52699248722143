import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Tooltip,
	Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import * as Yup from "yup";

import { modifyWorkerSkill } from "../../../../../../../../export/workers";
import DialogWrapper from "../../../../../../../design/dialog-wrapper";
import useEditSkillDialogController from "./controllers/use-edit-skill-dialog-controller";
import EditSkillDialogContent from "./edit-skill-dialog-content";

interface EditWorkerSkillDialogProps {
	open: boolean;
	closeEditWorkerSkillDialog: () => void;
}

const EditWorkerSkillDialog: React.FC<EditWorkerSkillDialogProps> = ({
	open,
	closeEditWorkerSkillDialog,
}) => {
	const dispatch = useDispatch();
	const { formikInitialValues, requiredCertifications } =
		useEditSkillDialogController();
	const schema = Yup.object().shape({
		validFrom: Yup.string()
			.nullable(),
		validTo: Yup.string()
			.nullable()
			.test(
				"is-after-valid-from",
				"valid until date must be greater than valid from date",
				function (validTo) {
					const { validFrom } = this.parent;
					if (validFrom && validTo) {
						return new Date(validTo) > new Date(validFrom);
					}
					return true; // If either is null or undefined, the test passes
				}
			),
	});

	// Additional validation for validFrom to ensure it is required if validTo is provided
	schema.fields.validFrom = schema.fields.validFrom.test(
		"is-required-if-valid-to",
		"Please select a valid from date",
		function (validFrom) {
			const { validTo } = this.parent;
			if (validTo && !validFrom) {
				return false; // If validTo is provided and validFrom is not, the test fails
			}
			return true; // Otherwise, the test passes
		}
	);
	return (
		<Dialog open={open} onClose={() => dispatch(closeEditWorkerSkillDialog())} maxWidth={false}>
			<DialogTitle
				sx={{
					padding: "45px 45px 24px 45px",
					// width: "900px",
				}}
			>
				Edit Skill for Worker
			</DialogTitle>
			<Formik
				initialValues={formikInitialValues}
				validationSchema={schema}
				onSubmit={(values) => {
					dispatch(modifyWorkerSkill(values));
				}}
			>
				{({ values, setFieldValue, errors, isSubmitting, isValid, dirty }) => {
					return (
						<Form>
							<DialogContent
								sx={{
									padding: "0",
								}}
							>
								{/* <Tooltip
									title={formikInitialValues?.skillName}
									placement='bottom-start'
								>
									<Typography
										sx={{
											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis",
											maxWidth: "600px",
											fontSize: "12px",
											fontWeight: 400,
											lineHeight: "12px",
											letterSpacing: "0.15px",
											color: "#00000099",
										}}
									>
										{formikInitialValues?.skillName}
									</Typography>
								</Tooltip> */}
								<EditSkillDialogContent
									values={values}
									setFieldValue={setFieldValue}
									certifications={requiredCertifications}
									errors={errors}
								/>
							</DialogContent>
							<DialogActions sx={{p: 0}}>
								<Box
									sx={{
										padding: "45px",
									}}
								>
									<Button
										variant={"text"}
										sx={{ px: 2, mr: 1 }}
										onClick={() => dispatch(closeEditWorkerSkillDialog())}
									>
										Cancel
									</Button>
									<Button
										variant='contained'
										color='primary'
										type='submit'
										disabled={
											isSubmitting ||
											!isValid ||
											!dirty ||
											formikInitialValues === values ||
											!values.levelId ||
											!!errors.validFrom ||
											!!errors.validTo
										}
										startIcon={isSubmitting && <CircularProgress size={20} />}
									>
										{isSubmitting ? "Saving..." : "Save"}
									</Button>
								</Box>
							</DialogActions>
						</Form>
					);
				}}
			</Formik>
		</Dialog>
	);
};

export default EditWorkerSkillDialog;
