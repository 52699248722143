// TODO - Type 'any' needs to be fixed.
/* eslint-disable no-mixed-spaces-and-tabs */
import { Label } from "@mui/icons-material";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import {
	fetchAllLocations,
	fetchBroadcastHistory,
	locationsWithClusterAsync,
	resetBroadcastWorkerCount,
	sendBroadcastMessage,
} from "../../../../../../../export/scheduler";
import useSchedulerData from "../../../../controllers/use-scheduler-data";
import { JobsState, LocationOptions, WorkCenter } from "../types";
import { checkboxes, checkboxesAllTrue } from "../utils";

const useBmessageDialog = () => {
	const dispatch = useDispatch();
	const { allJobRoles, locationsWithCluster, companyId, allLocations } =
		useSchedulerData();

	const [options, setOptions] = useState<LocationOptions[]>([]);
	const [message, setMessage] = useState<string>("");
	const [jobs, setJobs] = useState<JobsState>({});
	const [isHistoryOpen, setIsHistoryOpen] = useState<boolean>(false);
	const [filteredJobs, setFilteredJobs] = useState<any[]>([]);
	const [filteredWorkcenters, setFilteredWorkcenters] = useState<WorkCenter[]>(
		[]
	);
	const [changedBy, setChangedBy] = useState<string>("");

	// Initialize options based on locationsWithCluster
	useEffect(() => {
		setOptions(
			locationsWithCluster.map(({ name, id }: LocationOptions) => ({
				label: name,
				checked: false,
				id,
			}))
		);
	}, [locationsWithCluster]);

	useEffect(() => {
		dispatch(locationsWithClusterAsync());
		dispatch(fetchAllLocations(companyId));
	}, [companyId, dispatch]);

	useEffect(() => {
		if (changedBy !== "location") return;

		const selectedLocationIds = options
			.filter((option) => option.checked)
			.map((option) => option.id);
		const workCenters = selectedLocationIds.flatMap((id) => {
			const location = allLocations?.find(
				(obj: { id: number }) => obj.id === id
			);
			return (
				location?.workCenters.map((wc: { name: string }) => ({
					...wc,
					label: wc.name,
					checked: false,
				})) || []
			);
		});

		const jobIds = new Set(
			workCenters.flatMap((wc) => wc.jobIds?.split(",") || [])
		);
		const finalJobs = allJobRoles.filter((job: { id: number }) =>
			jobIds.has(job.id.toString())
		);
		const jobsWithLabels = finalJobs.map((job: { name: any }) => ({
			...job,
			label: job.name,
			checked: false,
		}));
		setJobs(checkboxes({ allJobRoles: finalJobs }));
		setFilteredJobs(jobsWithLabels);
		setFilteredWorkcenters(workCenters);
	}, [changedBy, allJobRoles, allLocations, options]);

	// Filter jobs based on workcenter changes
	useEffect(() => {
		if (changedBy !== "workcenter") return;
		const selectedWorkcenterJobIds = new Set(
			filteredWorkcenters
				.filter((wc) => wc.checked)
				.flatMap((wc) => wc.jobIds?.split(",") || [])
		);
		const finalJobs = allJobRoles.filter((job: { id: number }) =>
			selectedWorkcenterJobIds.has(job.id.toString())
		);
		const jobsWithLabels = finalJobs.map((job: { name: any }) => ({
			...job,
			label: job.name,
			checked: false,
		}));
		setFilteredJobs(jobsWithLabels);
	}, [changedBy, filteredWorkcenters, allJobRoles]);

	const handleClick = ({ id }: { id: number }) => {
		setJobs({ ...jobs, [id]: !jobs[id] });
	};

	const handleSelectAll = (checked: boolean) => {
		if (checked) {
			setJobs(checkboxesAllTrue({ allJobRoles: filteredJobs }));
		} else {
			setJobs(checkboxes({ allJobRoles: filteredJobs }));
		}
	};

	useEffect(() => {
		const anyJobChecked =
			filteredJobs.length > 0
				? filteredJobs.some((job: { checked: boolean }) => job.checked)
				: false;
		const anyLocationSelected = options?.some((option) => option.checked);
		const anyWorkcenterSelected = filteredWorkcenters.some(
			(workcenter) => workcenter.checked
		);

		if (
			(anyLocationSelected &&
				(changedBy === "location" || changedBy === "workcenter")) ||
			(anyWorkcenterSelected &&
				(changedBy === "workcenter" || changedBy === "job")) ||
			(anyJobChecked && changedBy === "job")
		) {
			const jobIds = new Set(
				filteredWorkcenters.flatMap((wc) => wc.jobIds?.split(",") || [])
			);

			const finalJobs = allJobRoles.filter((job: { id: number }) =>
					jobIds.has(job.id.toString())
			);
			const checkedJobs = filteredJobs
				.filter((job: { checked: boolean }) => job.checked) ?? [];

			dispatch(
				sendBroadcastMessage({
					jobs:
						finalJobs.length === checkedJobs.length
							? ""
							: filteredJobs
									.filter((job: { checked: boolean }) => job.checked)
									.map((job: { id: number }) => job.id)
									.join(",") ?? "",
					locationIds:
						options
							?.filter((option) => option.checked)
							?.map((option) => option.id)
							.join(",") ?? "",
					workCenterIds:
						filteredWorkcenters
							.filter((workcenter) => workcenter.checked)
							.map((workcenter) => workcenter.id)
							.join(",") ?? "",
					reqType: "workercount",
				})
			);
		} else dispatch(resetBroadcastWorkerCount({}));
	}, [
		dispatch,
		changedBy,
		options,
		filteredJobs,
		filteredWorkcenters,
		filteredJobs.length,
		allJobRoles,
	]);

	const handleBroadcastHistory = () => {
		dispatch(fetchBroadcastHistory());
		setIsHistoryOpen((prev) => !prev);
	};

	return [
		{
			message,
			jobs,
			isHistoryOpen,
			options,
			filteredJobs,
			filteredWorkcenters,
		},
		{
			setMessage,
			setJobs,
			setIsHistoryOpen,
			setFilteredWorkcenters,
			handleClick,
			handleSelectAll,
			handleBroadcastHistory,
			setOptions,
			setFilteredJobs,
			setChangedBy,
		},
	];
};

export default useBmessageDialog;
