import { Box } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { makeStyles } from "@mui/styles";
import React, { MouseEventHandler } from "react";

import { getRepLevel, getRepType } from "../../../../utils/helpers";
import { ALPHABET, NUMBER } from "../../../cross-skilling/constants";
import SkillRepresentationCell from "../../../cross-skilling/ui/cross-skill-table/ui/column-skill-representations-cell";

interface FilterListCheckboxProps {
    checked: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    level?: number;
    onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
    repCode?: string;
    backColorCode?: string;
    foreColorCode?: string;
    value?: string;
}

const useStyles = makeStyles({
    input: {
        height: "30px",
        boxSizing: "border-box",
    },
});

const FilterListCheckbox: React.FC<FilterListCheckboxProps> = ({
                                                                               checked,
                                                                               onChange,
                                                                               level,
                                                                               onClick,
                                                                               repCode,
                                                                               backColorCode,
                                                                               foreColorCode,
                                                                               value,
                                                                           }) => {
    const classes = useStyles();

    return (
        <Box display={"flex"} alignItems="center" pl={3} height={30}>
            {level !== undefined && level >= 0 ? (
                <SkillRepresentationCell
                    level={level}
                    value={value || ""}
                    backgroundColor={backColorCode}
                    foregroundColor={foreColorCode}
                    representationType={getRepType(repCode)}
                    representationLevel={getRepLevel(repCode, level)}
                    border={1}
                    width={
                        getRepType(repCode) === ALPHABET || getRepType(repCode) === NUMBER
                            ? 10
                            : 20
                    }
                    height={
                        getRepType(repCode) === ALPHABET || getRepType(repCode) === NUMBER
                            ? 10
                            : 20
                    }
                    backgroundWidth={
                        getRepType(repCode) === ALPHABET || getRepType(repCode) === NUMBER
                            ? "25px"
                            : "28px"
                    }
                    backgroundHeight={
                        getRepType(repCode) === ALPHABET || getRepType(repCode) === NUMBER
                            ? "25px"
                            : "28px"
                    }
                />
            ) : null}
            <Checkbox
                classes={{ root: classes.input }}
                checked={checked}
                onChange={onChange}
                onClick={onClick}
            />
        </Box>
    );
};

export default FilterListCheckbox;
